import { CachedCRUDService } from '../../../types/cached-c-r-u-d.service';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { lastValueFrom } from 'rxjs';
import { ServerResponse } from '../../../types/http.interfaces';
import { environment } from '../../../../../environments/environment';
import { ParametricsInterface } from '../../../types/parametrics.interface';

import { Sites, SitesCreateInput } from '@smartsoft-types/sisem-incidents';
import { SitesChangeStatusInput } from '@smartsoft-types/sisem-incidents/dist/src/entities/schemas/types/history-directories/sites.schema.entity';

@Injectable({
  providedIn: 'root',
})
export class IncidentsSitesService extends CachedCRUDService<
  ParametricsInterface,
  Pick<SitesCreateInput, 'data'>,
  never
> {
  constructor(private http: HttpClient) {
    super();
    this.options.cacheExpiration = -1; // should almost never change, set don't expire
  }

  protected override async _fetchNewData(): Promise<ParametricsInterface[]> {
    const response = await lastValueFrom(
      this.http.get<ServerResponse<ParametricsInterface[]>>(
        `${environment.api}/incidents-ms/api/v1/sites`
      )
    );
    return response.data;
  }

  protected override async _getByIdData(id: number): Promise<ParametricsInterface | undefined> {
    const res = await lastValueFrom(
      this.http.get<ServerResponse<ParametricsInterface>>(
        `${environment.api}/incidents-ms/api/v1/sites/${id}`
      )
    );
    return res.data;
  }

  protected override async _postData(input: Pick<SitesCreateInput, 'data'>) {
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'multipart/form-data');
    const res = await lastValueFrom(
      this.http.post<ServerResponse<Sites>>(
        `${environment.api}/incidents-ms/api/v1/sites`,
        input.data,
        { headers }
      )
    );
    const fixedRes = await this.getById(res.data.id, true);
    if (!fixedRes) {
      throw new Error('Could not fetch updated data, strange case, this should not happen');
    }
    this.removeId(res.data.id);
    return fixedRes;
  }

  protected override async _deleteData(_id: number): Promise<void> {
    await lastValueFrom(this.http.delete(`${environment.api}/incidents-ms/api/v1/sites/${_id}`));
  }

  async changeSiteStatus(id: number, body: Pick<SitesChangeStatusInput, 'data'>) {
    try {
      await lastValueFrom(
        this.http.post(`${environment.api}/incidents-ms/api/v1/sites/${id}/changeStatus`, body.data)
      );
    } catch (e) {
      console.error(e);
      throw e;
    }
  }
}
