import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { lastValueFrom } from 'rxjs';
import { TokenContent } from '../../types/keycloak.interfaces';
import jwt_decode from 'jwt-decode';

let tempAccessToken = '';
let tempRefreshToken = '';

export const tempLogin = async (
  httpClient: HttpClient,
  username: string = 'IAADMIN',
  password: string = 'lel123'
) => {
  const body = new HttpParams()
    .set('grant_type', 'password')
    .set('client_id', 'sisem_real_operations_front')
    .set('client_secret', '')
    .set('username', username)
    .set('password', password)
    .set('scope', 'offline_access');
  http = httpClient;
  const httpOptions = {
    headers: new HttpHeaders({
      skip: 'true',
      'Content-Type': 'application/x-www-form-urlencoded',
    }),
  };
  const result = await lastValueFrom(
    httpClient.post<any>(
      'https://admin.qa.sisembogota.com/auth/realms/sisem/protocol/openid-connect/token',
      body.toString(),
      httpOptions
    )
  );
  tempAccessToken = result.access_token;
  tempRefreshToken = result.refresh_token;
};
const getToken = (type: 'ACCESS' | 'REFRESH'): string => {
  if (type === 'ACCESS') {
    return tempAccessToken || '';
  } else if (type === 'REFRESH') {
    return tempRefreshToken || '';
  }
  return '';
};

const getDecodedToken = (type: 'ACCESS' | 'REFRESH'): TokenContent | false => {
  try {
    const token = getToken(type);
    return jwt_decode(token);
  } catch (error) {
    return false;
  }
};

const checkToken = (type: 'ACCESS' | 'REFRESH'): boolean => {
  const token: any = getDecodedToken(type);
  if (!token) {
    return false;
  }
  if (type === 'ACCESS') {
    if (token) {
      return Date.now() < token.exp * 1000 - 30000;
    }
  } else if (type === 'REFRESH') {
    if (token) {
      if (!token.exp) {
        return true;
      }
      return Date.now() < token.exp * 1000;
    }
  }
  return false;
};

let _requestingAccessToken: Promise<boolean> | null;
/**
 * refreshToken generate new access token from refresh token
 */
const refreshToken = async () => {
  if (_requestingAccessToken) {
    return _requestingAccessToken;
  }
  _requestingAccessToken = doRefreshToken();
  _requestingAccessToken.then((_value) => {
    _requestingAccessToken = null; // DONT DELETE
  });
  _requestingAccessToken.catch((_value) => {
    _requestingAccessToken = null; // DONT DELETE
  });
  return _requestingAccessToken;
};

let http: HttpClient;

const doRefreshToken = async (attempt = 0): Promise<boolean> => {
  try {
    await new Promise((resolve) => setTimeout(resolve, attempt * 100 + 1));
    const refreshToken = getToken('REFRESH');
    if (refreshToken) {
      const body = new HttpParams()
        .set('grant_type', 'refresh_token')
        .set('client_id', 'sisem_real_operations_front')
        .set('client_secret', '')
        .set('refresh_token', refreshToken);
      const httpOptions = {
        headers: new HttpHeaders({
          skip: 'true',
          'Content-Type': 'application/x-www-form-urlencoded',
        }),
      };
      const result = await lastValueFrom(
        http.post<any>(
          'https://admin.qa.sisembogota.com/auth/realms/sisem/protocol/openid-connect/token',
          body.toString(),
          httpOptions
        )
      );
      tempAccessToken = result.access_token;
      tempRefreshToken = result.refresh_token;
      return true;
    } else {
      console.warn(`[${Date.now()}]`, 'Invalid refresh token');
      return false;
    }
  } catch (e: any) {
    if (
      e.error &&
      e.error?.error === 'invalid_grant' &&
      (e.error?.error_description === 'Invalid refresh token' ||
        e.error?.error_description === 'Offline user session not found' ||
        e.error?.error_description === 'Stale token')
    ) {
      console.warn(`[${Date.now()}]`, 'Expired/revoked session');
      return false;
    } else {
      if (attempt < 5) {
        return doRefreshToken(attempt + 1);
      } else {
        console.error(`[${Date.now()}]`, e);
        return false;
      }
    }
  }
};

export const tempGetAccessToken = async (httpClient: HttpClient) => {
  if (!tempAccessToken) {
    await tempLogin(httpClient);
  }
  if (checkToken('ACCESS')) {
    return getToken('ACCESS');
  } else if (checkToken('REFRESH')) {
    const result = await refreshToken();
    if (result) {
      return getToken('ACCESS');
    } else {
      return '';
    }
  }
  return '';
};
