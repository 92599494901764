import { CachedCRUDService } from '../../../types/cached-c-r-u-d.service';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { lastValueFrom } from 'rxjs';
import { ServerResponse } from '../../../types/http.interfaces';
import { environment } from '../../../../../environments/environment';
import { IncidentType } from '../../../types/incident.interface';

@Injectable({
  providedIn: 'root',
})
export class IncidentsTypesService extends CachedCRUDService<IncidentType, never, never> {
  constructor(private http: HttpClient) {
    super();
    this.options.cacheExpiration = -1; // should almost never change, set don't expire
  }

  protected override async _fetchNewData(): Promise<IncidentType[]> {
    const response = await lastValueFrom(
      this.http.get<ServerResponse<IncidentType[]>>(
        `${environment.api}/incidents-ms/api/v1/incidentTypes`
      )
    );
    return response.data;
  }

  async getModifyingCircumstance(incidentTypeId: number) {
    const modifyingCircumstances = (await this.getAll()).find(
      (incidentType) => incidentType.id === incidentTypeId
    );
    const modifierC = modifyingCircumstances
      ? modifyingCircumstances.modCircumstances.filter((modC) => modC.name !== ' ')
      : undefined;
    if (modifierC) return modifierC;
    return [];
  }

  async getModifyingCircumstanceAsObject(incidentTypeId: number) {
    const output: any = {};
    for (const item of await this.getModifyingCircumstance(incidentTypeId)) {
      output[(item as any).id] = item;
    }
    return output;
  }
}
