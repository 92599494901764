import { Injectable } from '@angular/core';
import { CachedCRUDService } from '../types/cached-c-r-u-d.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ServerResponse } from '../types/http.interfaces';
import { lastValueFrom } from 'rxjs';
import { environment } from '../../../environments/environment';
import { TableKeyItemWithCode } from '../types/table';

@Injectable({
  providedIn: 'root',
})
export class DocumentTypeService extends CachedCRUDService<DocumentType, never, never> {
  constructor(private http: HttpClient) {
    super();
    this.options.cacheExpiration = -1;
  }

  protected override async _fetchNewData(): Promise<DocumentType[]> {
    const headers = new HttpHeaders({ skip: 'skip' });

    const res: ServerResponse<DocumentType[]> = await lastValueFrom(
      this.http.get<ServerResponse<DocumentType[]>>(
        `${environment.api}/actors-ms/api/v1/documentTypes`,
        { headers }
      )
    );
    return res.data;
  }
  protected override async _getByIdData(id: number): Promise<DocumentType | undefined> {
    const res: ServerResponse<DocumentType> = await lastValueFrom(
      this.http.get<ServerResponse<DocumentType>>(
        `${environment.api}/actors-ms/api/v1/documentTypes/${id}`
      )
    );
    return res.data;
  }

  async getPersonDocumentTypes(
    notAllowedDocumentCodes: string[] = ['NIT']
  ): Promise<TableKeyItemWithCode[]> {
    const types = await this._fetchNewData();
    const documents = types.filter((type) => !notAllowedDocumentCodes.includes(type.code));
    documents.forEach((doc) => {
      doc.description = this.formatName(doc.description);
    });
    documents.sort((a, b) =>
      a.description.toLowerCase().localeCompare(b.description.toLowerCase())
    );
    return documents.map((type) => {
      return {
        id: type.id,
        title: type.description,
        code: type.code,
      };
    });
  }

  private formatName(name: string) {
    const firstLetter = name.charAt(0).toUpperCase();
    const content = name.slice(1).toLowerCase();

    return `${firstLetter}${content}`;
  }
}

export interface DocumentType {
  id: number;
  description: string;
  code: string;
  createdAt: Date;
  updatedAt: Date;
}
