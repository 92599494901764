import { GeneralKnownRouteValidator, NavItem } from './types-and-helpers.known.routes';

/**
 * DON'T EVER CHANGE NUMERATION
 */
export enum AdministrationKnownItemId {
  APH_RESOURCE = '1',
  PROVIDERS = '2',
  OTHERS = '3',
  LOCATIONS = '5',
  SUB_LOCATIONS = '6',
  TARIFF_GROUPS = '7',
  USERS = '8',
  CONTRACTS = '9',
  SUB_PARAMETRICS = '10',
  ADMINISTRATION = '11',
  SUPPORT = '12',
  HELP = '13',
  INTEROPERABILITY = '14',
  PAYMENT_RESPONSIBLE = '15',
  ROLES_AND_PERMISSIONS = '17',
  NOTIFICATION = '18',
  ALERT = '19',
  ALERT_NOTIFICATIONS = '20',
  ADMIN_PAGE = '21',
}

const simpleTransformer = (input: NavItem): NavItem & { type: 'sub-parametrics' } => {
  return {
    ...input,
    type: 'sub-parametrics',
  };
};

export const AdministrationKnownRoutes = (k: AdministrationKnownItemId): NavItem => {
  k = GeneralKnownRouteValidator(k);
  return JSON.parse(JSON.stringify(options[k]));
};
const options: {
  [k in AdministrationKnownItemId]: NavItem & { type: 'sub-parametrics' };
} = {
  [AdministrationKnownItemId.APH_RESOURCE]: simpleTransformer({
    id: AdministrationKnownItemId.APH_RESOURCE,
    displayName: 'Recurso APH',
    iconName: 'ambulance',
    route: '/home/administration/aph-resources',
    parentModule: 'parametrics',
    permissions: ['vehicles_r'],
    visibleOnSidebar: true,
  }),
  [AdministrationKnownItemId.PROVIDERS]: simpleTransformer({
    id: AdministrationKnownItemId.PROVIDERS,
    displayName: 'Prestadores',
    iconName: 'providers',
    route: '/home/administration/providers',
    parentModule: 'parametrics',
    permissions: ['providers_r'],
    visibleOnSidebar: true,
  }),
  [AdministrationKnownItemId.OTHERS]: simpleTransformer({
    id: AdministrationKnownItemId.OTHERS,
    displayName: 'Otras Paramétricas',
    iconName: 'agencies',
    route: '/home/administration/other-parametrics',
    parentModule: 'parametrics',
    permissions: ['dependencies_w', 'agencies_r'],
    visibleOnSidebar: true,
  }),
  [AdministrationKnownItemId.LOCATIONS]: simpleTransformer({
    id: AdministrationKnownItemId.LOCATIONS,
    displayName: 'Localidades',
    iconName: 'location',
    route: '',
    permissions: ['location_r'],
    visibleOnSidebar: false,
  }),
  [AdministrationKnownItemId.SUB_LOCATIONS]: simpleTransformer({
    id: AdministrationKnownItemId.SUB_LOCATIONS,
    displayName: 'Barrios',
    iconName: 'neighborhoods',
    route: '',
    permissions: ['location_r'],
    visibleOnSidebar: false,
  }),
  [AdministrationKnownItemId.TARIFF_GROUPS]: simpleTransformer({
    id: AdministrationKnownItemId.TARIFF_GROUPS,
    displayName: 'Grupos tarifarios',
    iconName: 'tariff-groups',
    route: '',
    permissions: [],
    visibleOnSidebar: false,
  }),
  [AdministrationKnownItemId.USERS]: simpleTransformer({
    id: AdministrationKnownItemId.USERS,
    displayName: 'Usuarios',
    iconName: 'user-outline',
    route: '/home/administration/users',
    parentModule: 'parametrics',
    permissions: ['user_management_r'],
    visibleOnSidebar: true,
  }),
  [AdministrationKnownItemId.CONTRACTS]: simpleTransformer({
    id: AdministrationKnownItemId.CONTRACTS,
    displayName: 'Contratos y Convenios',
    iconName: 'contracts',
    route: '/home/administration/contracts/list',
    parentModule: 'parametrics',
    permissions: ['contracts_r'],
    visibleOnSidebar: true,
  }),
  [AdministrationKnownItemId.ADMINISTRATION]: simpleTransformer({
    id: AdministrationKnownItemId.ADMINISTRATION,
    displayName: 'Administración',
    iconName: 'parametrics',
    route: '/home/administration/general-admin',
    parentModule: 'parametrics',
  } as NavItem),
  [AdministrationKnownItemId.SUPPORT]: simpleTransformer({ visibleOnSidebar: false } as NavItem),
  [AdministrationKnownItemId.HELP]: simpleTransformer({ visibleOnSidebar: false } as NavItem),
  [AdministrationKnownItemId.SUB_PARAMETRICS]: simpleTransformer({
    id: AdministrationKnownItemId.SUB_PARAMETRICS,
    displayName: 'Administración',
    iconName: 'parametrics',
    route: '/home/administration',
    parentModule: 'parametrics',
    permissions: [],
    visibleOnSidebar: false,
  }),
  [AdministrationKnownItemId.INTEROPERABILITY]: simpleTransformer({
    id: AdministrationKnownItemId.INTEROPERABILITY,
    displayName: 'Interoperabilidad',
    iconName: 'sync',
    route: '/home/administration/interoperability',
    parentModule: 'parametrics',
    permissions: ['active_directory_w', 'right_validators_w', 'sirc_q'],
    visibleOnSidebar: true,
  }),
  [AdministrationKnownItemId.PAYMENT_RESPONSIBLE]: simpleTransformer({
    id: AdministrationKnownItemId.PAYMENT_RESPONSIBLE,
    displayName: 'Directorio de Pagadores',
    tabName: 'DIRECTORIO DE PAGADORES',
    iconName: 'hospital-care',
    route: '/home/administration/payment-responsible',
    parentModule: 'parametrics',
    permissions: ['payment_responsible_management_w', 'payment_responsible_contact_data_u'],
    visibleOnSidebar: true,
  }),
  [AdministrationKnownItemId.ADMIN_PAGE]: simpleTransformer({
    id: AdministrationKnownItemId.ADMIN_PAGE,
    displayName: 'ADMINISTRACIÓN',
    iconName: 'settings',
    route: '/home/administration/general-admin',
    parentModule: 'parametrics',
    permissions: [],
    visibleOnSidebar: false,
  }),
  [AdministrationKnownItemId.ALERT_NOTIFICATIONS]: simpleTransformer({
    id: AdministrationKnownItemId.ALERT_NOTIFICATIONS,
    displayName: 'Alertas y Notificaciones',
    tabName: 'ALERTAS Y NOTIFICACIONES',
    iconName: 'warning-black',
    route: '/home/administration/alert-notifications',
    parentModule: 'parametrics',
    permissions: ['alert_management_w', 'notification_management_w'],
    visibleOnSidebar: true,
  }),
  [AdministrationKnownItemId.ALERT]: simpleTransformer({
    id: AdministrationKnownItemId.ALERT,
    displayName: 'ALERTAS Y NOTIFICACIONES',
    iconName: 'alert',
    route: '/home/administration/alert-notifications',
    parentModule: 'parametrics',
    permissions: ['alert_management_w'],
    visibleOnSidebar: false,
  }),
  [AdministrationKnownItemId.NOTIFICATION]: simpleTransformer({
    id: AdministrationKnownItemId.NOTIFICATION,
    displayName: 'ALERTAS Y NOTIFICACIONES',
    iconName: 'notifications',
    route: '/home/administration/alert-notifications',
    parentModule: 'parametrics',
    permissions: ['notification_management_w'],
    visibleOnSidebar: false,
  }),
  [AdministrationKnownItemId.ROLES_AND_PERMISSIONS]: simpleTransformer({
    id: AdministrationKnownItemId.ROLES_AND_PERMISSIONS,
    displayName: 'Roles y permisos',
    tabName: 'ROLES Y PERMISOS',
    iconName: 'default-icon',
    route: '/home/administration/roles-and-permissions',
    parentModule: 'parametrics',
    permissions: ['roles_r', 'roles_w', 'roles_management_w'],
    visibleOnSidebar: true,
  }),
};
