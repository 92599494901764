import { ChangeDetectorRef, Component, Inject, Input } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TableDialogInterface } from '../../types/dialog.interface';

@Component({
  selector: 'app-table-dialog',
  templateUrl: './table-dialog.component.html',
  styleUrls: ['./table-dialog.component.scss'],
})
export class TableDialogComponent {
  @Input() txtHeaderClass: string;
  @Input() boldBodyText: boolean;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: TableDialogInterface,
    public dialogRef: MatDialogRef<TableDialogComponent>,
    private changeDetectorRef: ChangeDetectorRef
  ) {
    this.txtHeaderClass = this.data.txtHeaderClass;
    this.boldBodyText = this.data.boldBodyText;
  }

  close(data?: string | boolean) {
    this.dialogRef.close(data);
    this.forceRefresh();
  }

  private forceRefresh() {
    this.changeDetectorRef.detectChanges();
    setTimeout(() => {
      this.changeDetectorRef.detectChanges();
    }, 1);
  }
}
