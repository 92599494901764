import { EventEmitter, Injectable } from '@angular/core';
import { AlertService } from '../../services/alert.service';

@Injectable({
  providedIn: 'root',
})
export class PlacesAutocompleteService {
  private autocompleteService: google.maps.places.AutocompleteService;
  public lastValueSearched: string = '';
  public placeDetailsService: google.maps.places.PlacesService;
  public predictions: google.maps.places.AutocompletePrediction[] | [] = [];
  public selectPlace: EventEmitter<google.maps.places.PlaceResult> =
    new EventEmitter<google.maps.places.PlaceResult>();
  public showAutocomplete: boolean = false;

  constructor(private alertService: AlertService) {
    try {
      this.autocompleteService = new google.maps.places.AutocompleteService();
      // Create a temporary div element to initialize the Google Maps PlacesService
      const div = document.createElement('div');
      this.placeDetailsService = new google.maps.places.PlacesService(div);
    } catch (error) {
      console.error(error);
    }
  }

  /**
   * Fetches place predictions based on the input value and
   * filters them by country.
   *
   * @param value - The input value for the prediction search.
   */
  getCustomPlacePredictions(value: string) {
    // Sanitize the input value
    value.replace('"', '\\"').replace(/^\s+|\s+$/g, '');
    if (value !== '') {
      if (this.lastValueSearched !== value && this.predictions.length === 0) {
        // Request place predictions based on the input value
        this.autocompleteService?.getPlacePredictions(
          {
            input: value,
            componentRestrictions: { country: 'co' },
          },
          (
            predictions: google.maps.places.AutocompletePrediction[] | null,
            status: google.maps.places.PlacesServiceStatus
          ) => this.displaySuggestions(predictions || [], status, value)
        );
      }
      this.lastValueSearched = value;
      this.showAutocomplete = true;
    } else {
      // If the input value is empty, clear the predictions
      this.predictions = [];
      this.lastValueSearched = '';
      this.showAutocomplete = false;
    }
  }

  /**
   * Displays suggestions based on the fetched predictions.
   *
   * @param predictions - The fetched predictions.
   * @param status - The status of the PlacesService.
   * @param searchValue - The search value used.
   */
  public displaySuggestions(
    predictions: google.maps.places.AutocompletePrediction[] | [],
    status: google.maps.places.PlacesServiceStatus,
    searchValue: string
  ) {
    if (status === google.maps.places.PlacesServiceStatus.OK) {
      // If the response is successful, store the predictions
      this.predictions = predictions;
    } else {
      // If the response is not successful, clear the predictions and show an error message
      this.predictions = [];
      const errorMsg =
        status === google.maps.places.PlacesServiceStatus.OVER_QUERY_LIMIT
          ? 'Se ha excedido el límite de consultas permitidas para este servicio'
          : 'No se encontraron resultados para la dirección: ' + searchValue;
      this.alertService.showError(errorMsg);
    }
  }

  /**
   * Fetches details of a specific place based on its ID.
   *
   * @param place_id - The ID of the place to fetch details for.
   */
  getPlaceDetails(place_id: string) {
    const request = {
      placeId: place_id,
      fields: ['name', 'geometry'],
    };

    // Request place details from the PlacesService
    this.placeDetailsService?.getDetails(request, (place, status) => {
      if (status === google.maps.places.PlacesServiceStatus.OK) {
        if (place && place.geometry) {
          // If the response is successful and the place has geometry, emit the selected place
          this.selectPlace.emit(place);
        } else {
          // If no results are found, show an error message
          this.alertService.showError('No se encontraron resultados');
          console.error('Returned place contains no geometry');
        }
      }
    });
  }

  /**
   * Selects an item from the autocomplete predictions and fetches its details.
   *
   * @param place - The selected prediction.
   */
  selectPlaceAutoCompleteItem(place: google.maps.places.AutocompletePrediction) {
    this.getPlaceDetails(place.place_id);
    this.predictions = [];
  }

  /**
   * Clears the place predictions.
   */
  hideAutocomplete() {
    this.showAutocomplete = false;
  }
}
