<div class="patient-interoperability-modal" [appLoading]="isLoading">
  <div class="modal-header">
    <div class="flex-row-start message-dialog dialog-title">
      <app-svg [name]="dialogData.icon" class="header-icon mr-1"></app-svg>
      <span class="text-b-14-white mr-1">{{ dialogData.modalTitle }}</span>
    </div>
    <app-svg name="cancel" class="header-icon mr-0-5 cursor-pointer" (click)="close()"></app-svg>
  </div>
  <div class="patient-interoperability-content">
    <div class="patient-interoperability-body">
      <div
        class="patient-interoperability-title"
        [class]="activeInteroperability.length === 1 ? 'one-column' : ''">
        <div
          class="title-line"
          (click)="changeSelectedValidator(data.selector)"
          *ngFor="let data of activeInteroperability; let i = index"
          [class]="validateClass(data.selector, i)">
          <span class="title" [class]="data.selector === selectedValidator ? 'active-title' : ''">{{
            data.title
          }}</span>
        </div>
      </div>
      <div class="patient-interoperability-header">
        <div class="mobile-header"><span></span></div>
        <app-svg name="cancel" class="responsive-close-icon" (click)="close()"></app-svg>
        <div class="auto-columns">
          <span class="line-title">COMPROBAR DATOS</span>
          <div class="middle-line"></div>
        </div>
        <div class="capsule-field">
          <div
            class="capsule"
            *ngFor="let data of activeInteroperability; let i = index"
            [class]="selectedValidator === data.selector ? 'active-capsule' : ''"
            (click)="changeSelectedValidator(data.selector)">
            {{ data.mobileTitle }}
          </div>
        </div>
      </div>
      <div *ngIf="selectedValidator === 'adres'" class="validator-data">
        <div class="grid-tow-columns" *ngIf="adresValidatorField.length > 0">
          <div class="patient-data" *ngFor="let data of adresValidatorField">
            <span class="text-info">{{ data.info }}</span>
            <span class="title-info">{{ data.title }}</span>
          </div>
        </div>
      </div>
      <div *ngIf="selectedValidator === 'rightsChecker'" class="validator-data">
        <div class="grid-tow-columns" *ngIf="checkerValidatorField.length > 0">
          <div class="patient-data" *ngFor="let data of checkerValidatorField">
            <span class="text-info">{{ data.info }}</span>
            <span class="title-info">{{ data.title }}</span>
          </div>
        </div>
      </div>
      <div class="buttons-field">
        <button mat-button class="btn-accept ph-1" (click)="close()">CANCELAR</button>
        <button mat-button class="btn-accept ph-1" (click)="save()">CONSERVAR DATOS</button>
      </div>
    </div>
  </div>
</div>
