import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { lastValueFrom } from 'rxjs';
import { environment } from '../../../../../environments/environment';
import { ServerResponse } from '../../../types/http.interfaces';
import {
  UpdateAphResourceAdminState,
  UpdateAphResourceState,
} from '../../../types/aph-resource.interface';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import * as moment from 'moment/moment';
import { ValidatorsService } from '../../validators.service';
import { Router } from '@angular/router';
import {
  ParametricService,
  TabActionType,
  TabData,
} from '../../../types/parametric.service.abstract.class';
import { ParametricsInterface } from '../../../types/parametrics.interface';
import { KeyPressedHandlerService } from '../../key-pressed-handler.service';
import {
  APHProvider,
  Contract,
  ResourceCreateInput,
  ResourceManagementHistoryRecord,
  ResourceOperativeStatusChange,
  ResourceRegisterEventInput,
  ResourceSearchManagementHistoryInput,
  ResourceUpdateInput,
} from '@smartsoft-types/sisem-resources';
import { ServiceType } from '@smartsoft-types/sisem-resources/dist/src/entities/models/parametrics/service-type.model.entity';
import { ProfileService } from '../../profile/profile.service';
import { Resource } from '@smartsoft-types/sisem-resources/dist/src/entities/models/resource/resource.model.entity';
import { CachedCRUDService } from '../../../types/cached-c-r-u-d.service';
import { ProviderTypeService } from '../provider/provider.type.service';
import { ResourcesZoneService } from './resources.zone.service';
import { APHBase } from '@smartsoft-types/sisem-resources/dist/src/entities/models/parametrics/aph-base.model.entity';
import { ContractsService } from '../contracts/contracts.service';
import { ResourcesMobileUnitService } from './resources.mobile.unit.service';
import { ResourcesAssociationTypesService } from './resources.association.types.service';
import { ResourcesVehicleModalityService } from './resources.vehicle.modality.service';
import { ResourcesServiceTypeService } from './resources.service.type.service';
import { ResourcesMinistryCdaService } from './resources.ministry.cda.service';
import { ResourcesSoaEntityService } from './resources.soa.entity.service';
import { ResourcesTechnicalMechanicalStateService } from './resources.technical.mechanical.state.service';
import { ResourcesVehicleFuelTypeService } from './resources.vehicle.fuel.type.service';
import { StatusChangeCause } from '@smartsoft-types/sisem-resources/dist/src/entities/models/parametrics/status-change-cause.model.entity';
import { SearchResult } from '../../../types/actor/actor.model.entity';
import { ResourcesAssociatedPatient } from '../../hospitals/manage-patients/manage-patients.service';
import { ResourceOpStatusChanges } from '../../providers/search-and-report-status/search-and-report-status.service';

export interface ResourcesTabData {
  step1FormTouched: boolean;
  step2FormTouched: boolean;
  step3FormTouched: boolean;
  step4FormTouched: boolean;
  step1Form: FormGroup;
  step2Form: FormGroup;
  step3Form: FormGroup;
  step4Form: FormGroup;
  dateNow: string;
  endDateYear: string;
  endDateSoat: string;
  selectedService: boolean;
  validTechnoMechanicalState: boolean;
  selectedBase: number;
  selectedResource?: Resource;
  aphMobileUnit: ParametricsInterface[];
  aphBase: APHBase[];
  contracts: Contract[];
  ministryCda: ParametricsInterface[];
  serviceType: ParametricsInterface[] | Array<ServiceType>;
  technicalMechanicalState: ParametricsInterface[];
  vehicleFuelType: ParametricsInterface[];
  associationTypes: ParametricsInterface[];
  vehicleModality: ParametricsInterface[];
  aphProvider: APHProvider[];
  aphProviderType: ParametricsInterface[];
  aphZones: ParametricsInterface[];
  selectedContract?: Contract;
  renamedContracts?: { id: number; title: string }[];
  selectedIndex: number;
  locationId: number;
  isEditing: boolean;
}

export interface OpenTabOptions {
  id: number;
  icon?: string;
  extraData?: {
    name: string;
    isAgency?: string;
  };
}

class ResourcesServiceTabManager extends ParametricService<ResourcesTabData, OpenTabOptions> {
  constructor(
    private keyPressHandleService: KeyPressedHandlerService,
    private validatorsService: ValidatorsService,
    private router: Router,
    private resourcesService: ResourcesService,
    public override profileService: ProfileService,
    private providerTypeService: ProviderTypeService,
    private resourcesZoneService: ResourcesZoneService,
    private contractsService: ContractsService,
    private resourcesMobileUnitService: ResourcesMobileUnitService,
    private resourcesAssociationTypesService: ResourcesAssociationTypesService,
    private resourcesVehicleModalityService: ResourcesVehicleModalityService,
    private resourcesMinistryCdaService: ResourcesMinistryCdaService,
    private resourcesServiceTypeService: ResourcesServiceTypeService,
    private resourcesSoaEntityService: ResourcesSoaEntityService,
    private resourcesTechnicalMechanicalStateService: ResourcesTechnicalMechanicalStateService,
    private resourcesVehicleFuelTypeService: ResourcesVehicleFuelTypeService
  ) {
    super(keyPressHandleService, profileService);
    super.newTabAction = {
      icon: '',
      toolTip: 'Nuevo Contrato o Convenio',
    };
  }

  /**
   *
   * @param actionType action to be performed register or edit
   * @param options id record
   */
  override async newTab(actionType: TabActionType, options?: OpenTabOptions) {
    let text = '';
    // validate if already open
    if (options?.id) {
      const result = this.tabs.find((value) => value.tabData.selectedResource?.id === options.id);
      if (result) {
        this.selectTab(result.id);
        return;
      }
    }
    //
    const resource = options?.id
      ? await this.resourcesService.getById(options.id, true)
      : undefined;
    const tabId = ++this._tabIdCounter;
    if (actionType === TabActionType.CREATE) {
      text = `NUEVO APH (${tabId})`;
    } else if (actionType === TabActionType.EDIT) {
      text = 'EDITAR APH ' + resource?.code;
    }

    const vehicleModalityList = await this.resourcesVehicleModalityService.getAll();

    const vehicleModality: ParametricsInterface[] = [];

    vehicleModalityList.forEach((modality) => {
      if (modality.title.toUpperCase() !== 'ADICIONAL') {
        vehicleModality.push(modality);
      }
    });

    const tabData: TabData<ResourcesTabData> = {
      id: tabId,
      edition: false,
      createdBy: '',
      createdByIp: '',
      createdDate: [],
      creationInfoExpanded: false,
      tabData: {
        step1FormTouched: false,
        step2FormTouched: false,
        step3FormTouched: false,
        step4FormTouched: false,
        step1Form: new FormGroup({}),
        step2Form: new FormGroup({}),
        step3Form: new FormGroup({}),
        step4Form: new FormGroup({}),
        dateNow: '',
        endDateSoat: '',
        endDateYear: '',
        selectedService: false,
        validTechnoMechanicalState: false,
        selectedBase: -1,
        selectedResource: resource,
        aphMobileUnit: await this.resourcesMobileUnitService.getAll(),
        contracts: (await this.contractsService.getAll()).map((value) => value.ContractDB),
        associationTypes: await this.resourcesAssociationTypesService.getAll(),
        ministryCda: await this.resourcesMinistryCdaService.getAll(),
        serviceType: await this.resourcesServiceTypeService.getAll(),
        technicalMechanicalState: await this.resourcesTechnicalMechanicalStateService.getAll(),
        vehicleFuelType: [],
        vehicleModality,
        aphProviderType: await this.providerTypeService.getAll(),
        aphZones: await this.resourcesZoneService.getAll(),
        aphBase: [],
        aphProvider: [],
        renamedContracts: undefined,
        selectedContract: undefined,
        selectedIndex: 0,
        locationId: -1,
        isEditing: false,
      },
      keysSubscriptionName: '',
      actionType: actionType,
      title: text,
    };
    this.generateForms(tabData);
    this._tabs.push(tabData);
    this.selectTab(tabData.id);
  }

  /**
   *
   * @param id
   */
  override selectTab(id: number) {
    super.selectTab(id);
    // the first navigation is to trick router to force reload component
    this.router
      .navigateByUrl('/home/administration/aph-resources/', {
        skipLocationChange: true,
      })
      .then(() => {
        if (this._selectedTab?.actionType === TabActionType.CREATE) {
          this.router.navigateByUrl('/home/administration/aph-resources/create/' + id);
        } else if (this._selectedTab?.actionType === TabActionType.EDIT) {
          this.router.navigateByUrl(
            '/home/administration/aph-resources/edit/' +
              this._selectedTab.tabData.selectedResource?.id
          );
        }
      });
  }

  override isSafeToCloseTab(id: number): boolean {
    const tab = this.getTabData(id);
    if (tab) {
      // validate if is safe to close
      if (
        tab.tabData.step1Form.dirty ||
        tab.tabData.step2Form.dirty ||
        tab.tabData.step3Form.dirty ||
        tab.tabData.step4Form.dirty
      ) {
        return false;
      }
    }
    return true;
  }

  override getTabData(id: number, resourceId?: number): TabData<ResourcesTabData> {
    if (resourceId)
      return this._tabs.find((value) => value.tabData.selectedResource?.id === resourceId)!;
    return this._tabs.find((value) => value.id === id)!;
  }

  /**
   * GENERATES ALL FORMS
   **/
  private async generateForms(tabData: TabData<ResourcesTabData>) {
    tabData.tabData.dateNow = moment().format('YYYY-MM-DD');
    tabData.tabData.endDateYear = moment(tabData.tabData.dateNow)
      .add(1, 'years')
      .format('YYYY-MM-DD');
    tabData.tabData.endDateSoat = moment(tabData.tabData.dateNow)
      .add(1, 'years')
      .subtract(1, 'days')
      .format('YYYY-MM-DD');

    tabData.tabData.step1Form = new FormGroup({
      codeAPH: new FormControl('', [
        Validators.required,
        Validators.pattern(this.validatorsService.alphanumericValidator),
        Validators.maxLength(4),
        Validators.minLength(4),
      ]),
      providerClass: new FormControl('', [Validators.required]),
      provider: new FormControl({ value: '', disabled: true }, [Validators.required]),
      bondingType: new FormControl(3, []),
      contract: new FormControl({ value: '', disabled: true }, [Validators.required]),
      serviceType: new FormControl({ value: '', disabled: true }, [Validators.required]),
      hourValue: new FormControl({ value: '', disabled: true }, [Validators.required]),
      workingHoursPerDay: new FormControl({ value: '', disabled: true }, [
        Validators.pattern(this.validatorsService.numberValidator),
        Validators.required,
        Validators.maxLength(2),
      ]),
      location: new FormControl('', [Validators.required]),
      base: new FormControl({ value: '', disabled: true }, [Validators.required]),
      baseAddress: new FormControl({ value: '', disabled: true }),
      APHZone: new FormControl('', [Validators.required]),
      APH: new FormControl('', [Validators.required]),
      vehicleModality: new FormControl('', []),
    });
    tabData.tabData.step2Form = new FormGroup({
      vehiclePlate: new FormControl('', [
        Validators.required,
        Validators.pattern(this.validatorsService.plateValidator),
        Validators.maxLength(6),
      ]),
      plateDepartment: new FormControl({ value: '', disabled: true }, [Validators.required]),
      plateCity: new FormControl({ value: '', disabled: true }, [Validators.required]),
      propertyCardNumber: new FormControl('', [
        Validators.required,
        Validators.pattern(this.validatorsService.numberValidator),
        Validators.maxLength(11),
      ]),
      modelYear: new FormControl('', [
        Validators.required,
        Validators.pattern(this.validatorsService.numberValidator),
        Validators.maxLength(4),
      ]),
      ambulanceType: new FormControl(1, [Validators.required]),
    });
    tabData.tabData.step3Form = new FormGroup({
      vehicleBrand: new FormControl('', [
        Validators.maxLength(15),
        Validators.pattern(this.validatorsService.alphanumericWithAccentsValidator),
      ]),
      vehicleLine: new FormControl('', [
        Validators.maxLength(15),
        Validators.pattern(this.validatorsService.alphanumericWithAccentsValidator),
      ]),
      vehicleClass: new FormControl('', [
        Validators.maxLength(15),
        Validators.pattern(this.validatorsService.alphanumericWithAccentsValidator),
      ]),
      vehicleCylinderCapacity: new FormControl('', [
        Validators.pattern(this.validatorsService.numberValidator),
        Validators.maxLength(4),
      ]),
      power: new FormControl('', [
        Validators.pattern(this.validatorsService.numberValidator),
        Validators.maxLength(4),
      ]),
      vehicleRim: new FormControl('', [Validators.maxLength(5)]),
      traction: new FormControl('', [Validators.maxLength(4)]),
      fuelType: new FormControl('', []),
    });
    tabData.tabData.step4Form = new FormGroup({
      SOATNumber: new FormControl('', [
        Validators.pattern(this.validatorsService.alphanumericValidator),
        Validators.maxLength(25),
      ]),
      SOATExpeditionEntity: new FormControl({ value: '', disabled: true }, [Validators.required]),
      startDateSOAT: new FormControl({ value: '', disabled: true }, [Validators.required]),
      startHourSOAT: new FormControl({
        value: '00:00:00',
        disabled: true,
      }),
      finalDateSOAT: new FormControl({ value: '', disabled: true }),
      finalHourSOAT: new FormControl({
        value: '23:59:59',
        disabled: true,
      }),
      technoMechanicReview: new FormControl('', []),
      technoMechNumber: new FormControl({ value: '', disabled: true }, [
        Validators.required,
        Validators.pattern(this.validatorsService.numberValidator),
      ]),
      reviewedByCDA: new FormControl({ value: '', disabled: true }, [Validators.required]),
      startDateTechnoMechanic: new FormControl({ value: '', disabled: true }, [
        Validators.required,
      ]),
      startHourTechnoMechanic: new FormControl({
        value: '00:00:00',
        disabled: true,
      }),
      finalDateTechnoMechanic: new FormControl({
        value: '',
        disabled: true,
      }),
      finalHourTechnoMechanic: new FormControl({
        value: '23:59:59',
        disabled: true,
      }),
      finalComments: new FormControl('', [Validators.maxLength(200)]),
    });
    tabData.tabData.selectedService = false;
    tabData.tabData.validTechnoMechanicalState = false;
    tabData.tabData.selectedBase = -1;
  }
}

@Injectable({
  providedIn: 'root',
})
export class ResourcesService extends CachedCRUDService<
  Resource,
  ResourceCreateInput['data'],
  ResourceUpdateInput['data']
> {
  readonly operativeStatusCodes = ['508', 'CR508'];
  readonly onIncidentStatusCodes = ['COMM', '518', '523', '518B', '523B'];
  readonly unavailableStatusCodes = ['507', 'CR507'];

  public tabs: ResourcesServiceTabManager;

  constructor(
    keyPressedHandlerService: KeyPressedHandlerService,
    private http: HttpClient,
    private validatorsService: ValidatorsService,
    private router: Router,
    private profileService: ProfileService,
    private providerTypeService: ProviderTypeService,
    private resourcesZoneService: ResourcesZoneService,
    private contractsService: ContractsService,
    private resourcesMobileUnitService: ResourcesMobileUnitService,
    private resourcesAssociationTypesService: ResourcesAssociationTypesService,
    private resourcesVehicleModalityService: ResourcesVehicleModalityService,
    private resourcesMinistryCdaService: ResourcesMinistryCdaService,
    private resourcesServiceTypeService: ResourcesServiceTypeService,
    private resourcesSoaEntityService: ResourcesSoaEntityService,
    private resourcesTechnicalMechanicalStateService: ResourcesTechnicalMechanicalStateService,
    private resourcesVehicleFuelTypeService: ResourcesVehicleFuelTypeService
  ) {
    super();
    this.tabs = new ResourcesServiceTabManager(
      keyPressedHandlerService,
      validatorsService,
      router,
      this,
      profileService,
      providerTypeService,
      resourcesZoneService,
      contractsService,
      resourcesMobileUnitService,
      resourcesAssociationTypesService,
      resourcesVehicleModalityService,
      resourcesMinistryCdaService,
      resourcesServiceTypeService,
      resourcesSoaEntityService,
      resourcesTechnicalMechanicalStateService,
      resourcesVehicleFuelTypeService
    );
    this.tabs.newTabAction = {
      icon: '',
      toolTip: 'Agregar Recurso APH',
    };
    this.options.cacheExpiration = 1000 * 60 * 10; // in ms
  }

  //TODO TO DEPRECATE AFTER IMPLEMENTING PARAMS TO CRUD SERVICE
  async getAllResources(): Promise<Resource[]> {
    let params = new HttpParams();
    params = params.set('fetchStatusChangeCause', true);
    const res: any = await lastValueFrom(
      this.http.get<ServerResponse<Resource[]>>(
        `${environment.api}/resources-ms/api/v1/resources/aphResources`,
        {
          params,
          headers: new HttpHeaders(),
        }
      )
    );
    return res.data;
  }

  protected override async _fetchNewData(): Promise<Resource[]> {
    const res: ServerResponse<Resource[]> = await lastValueFrom(
      this.http.get<ServerResponse<Resource[]>>(
        `${environment.api}/resources-ms/api/v1/resources/aphResources`,
        { headers: new HttpHeaders() }
      )
    );
    return res.data;
  }

  protected override async _getByIdData(id: number): Promise<Resource | undefined> {
    const res = await lastValueFrom(
      this.http.get<ServerResponse<Resource | undefined>>(
        `${environment.api}/resources-ms/api/v1/resources/aphResources/${id}`
      )
    );
    return res.data;
  }

  protected override async _postData(input: ResourceCreateInput['data']): Promise<Resource> {
    const data = {
      data: input,
    };
    const result = await lastValueFrom(
      this.http.post<ServerResponse<Resource>>(
        `${environment.api}/resources-ms/api/v1/resources/aphResources`,
        data
      )
    );
    const fixedRes = await this.getById(result.data.id, true);
    if (!fixedRes) {
      throw new Error('Could not fetch updated data, strange case, this should not happen');
    }
    this.removeId(result.data.id); // get by id adds to cache, do this to avoid duplication
    return fixedRes;
  }

  protected override async _putData(id: number, input: ResourceUpdateInput['data']) {
    const data = {
      data: input,
    };
    await lastValueFrom(
      this.http.patch<ServerResponse<Resource>>(
        `${environment.api}/resources-ms/api/v1/resources/aphResources/${id}`,
        data
      )
    );
    const fixedRes = await this.getById(id, true);
    if (!fixedRes) {
      throw new Error('Could not fetch updated data, strange case, this should not happen');
    }
    return fixedRes;
  }

  public async updateAphResourceStateById(
    id: number,
    body: UpdateAphResourceAdminState | UpdateAphResourceState,
    notAdministrative: boolean = true
  ): Promise<void> {
    const data = {
      auditData: {
        roleId: this.profileService.selectedRole?.id ?? 1,
      },
      data: body,
    };
    await lastValueFrom(
      this.http.post(
        `${environment.api}/resources-ms/api/v1/resources/aphResources/${id}/${
          notAdministrative ? 'changeAdministrativeStatus' : 'changeStatus'
        }`,
        data
      )
    );
    const fixedRes = await this.getById(id, true); // update cached data
    if (!fixedRes) {
      throw new Error('Could not fetch updated data, strange case, this should not happen');
    }
  }

  public async getResourceChangeCauses(): Promise<StatusChangeCause[]> {
    let params = new HttpParams();
    params = params.set('fetchDetails', true);
    const res = await lastValueFrom(
      this.http.get<ServerResponse<StatusChangeCause[]>>(
        `${environment.api}/resources-ms/api/v1/parametrics/statusChangeCauses`,
        { params }
      )
    );
    return res.data;
  }

  public async getResourceByCode(code: string): Promise<Resource[]> {
    let params = new HttpParams();
    params = params.set('code', code);
    const res = await lastValueFrom(
      this.http.get<ServerResponse<Resource[]>>(
        `${environment.api}/resources-ms/api/v1/resources/aphResources`,
        { params }
      )
    );
    return res.data;
  }

  public async onCancelEvent(data: ResourceRegisterEventInput['data']) {
    await lastValueFrom(
      this.http.post(
        `${environment.api}/resources-ms/api/v1/resources/aphResources/registerActorRelatedEvent`,
        {
          data,
        }
      )
    );
  }

  public async getResourceManagementHistory(
    input?: ResourceSearchManagementHistoryInput
  ): Promise<SearchResult<ResourceManagementHistoryRecord[]>> {
    let params = new HttpParams();

    if (input) {
      const func = (obj: any) => {
        Object.keys(obj).forEach((key) => {
          if (obj[key] && typeof obj[key] === 'object') func(obj[key]);
          else if (obj[key] === undefined) delete obj[key];
        });
        return obj;
      };

      const queryObject = {
        ...func(input.searchBy),
        ...input.options,
        ...(input.searchBy?.eventDateFrom
          ? { eventDateFrom: input.searchBy.eventDateFrom.toJSON() }
          : {}),
        ...(input.searchBy?.eventDateTo
          ? { eventDateTo: input.searchBy.eventDateTo.toJSON() }
          : {}),
      };
      params = new HttpParams({
        fromObject: queryObject,
      });
    }

    const url = `${environment.api}/resources-ms/api/v1/resources/aphResources/managementHistory`; //
    const res = await lastValueFrom(
      this.http.get<ServerResponse<SearchResult<ResourceManagementHistoryRecord[]>>>(url, {
        params: params,
      })
    );
    return res.data;
  }

  public async resourceHistoryIncident(resourceId: number) {
    let params = new HttpParams();
    params = params.set('last12hourIncidentsAssignedOnly', true);
    params = params.set('resourceId', resourceId);

    const res = await lastValueFrom(
      this.http.get<ServerResponse<ResourceOperativeStatusChange[]>>(
        `${environment.api}/resources-ms/api/v1/resources/aphResources/last12hourIncidentsAssignedOnly`,
        { params }
      )
    );

    return res.data;
  }

  async getIncidentByResource(resourceId: number): Promise<ResourcesAssociatedPatient[]> {
    try {
      const params = { resourceId };
      const res = await lastValueFrom(
        this.http.get<ServerResponse<ResourcesAssociatedPatient[]>>(
          `${environment.api}/resources-ms/api/v1/resourcePatientRelations`,
          { params }
        )
      );
      return res.data;
    } catch (e) {
      console.error(e);
      throw e;
    }
  }

  async checkResourceExists(code: string) {
    const result = await lastValueFrom(
      this.http.get<ServerResponse<boolean>>(
        `${environment.api}/resources-ms/api/v1//resources/aphResources/existsCode?code=${code}`
      )
    );
    return result.data;
  }

  public async getResourceByIncidentStatus(param: {
    resourceId?: number;
    currentStatusId?: number;
    incidentId?: number;
  }): Promise<ResourceOpStatusChanges[]> {
    let params = new HttpParams();
    if (param.resourceId) params = params.append('resourceId', param.resourceId);
    if (param.currentStatusId) params = params.append('currentStatusId', param.currentStatusId);
    if (param.incidentId) params = params.append('incidentId', param.incidentId);
    const res = await lastValueFrom(
      this.http.get<ServerResponse<ResourceOpStatusChanges[]>>(
        `${environment.api}/resources-ms/api/v1/resourceOpStatusChanges`,
        { params }
      )
    );
    return res.data;
  }
}
