import { Injectable } from '@angular/core';
import { PaginatedCrudService } from '../paginated-crud.service.abstract.class';
import { HttpClient } from '@angular/common/http';
import { KeyPressedHandlerService } from '../key-pressed-handler.service';
import { ProfileService } from '../profile/profile.service';
import { TablePaginationData } from '../../types/table';
import { BubbleData } from '../../types/parametrics.interface';
import { OfficeStatus } from '@smartsoft-types/sisem-resources/dist/src/entities/models/office/office.model.entity';
import { TabActionType, TabData } from '../../types/parametric.service.abstract.class';
import { Router } from '@angular/router';
import { lastValueFrom } from 'rxjs';
import { LayerDataDB } from '../../types/layers.interface';
import { environment } from '../../../../environments/environment';
import { FormControl, FormGroup } from '@angular/forms';
import { DialogService } from '../dialog.service';

@Injectable({
  providedIn: 'root',
})
export class MapsModuleService extends PaginatedCrudService<any, any, any, any, any, any> {
  private DATA_URL = environment.api + '/maps-ms/api/v1';

  public readonly translateLayerType: { [k: string]: string } = {
    point: 'Punto',
    polygon: 'Polígono',
    linestring: 'Línea',
    multipoint: 'Punto',
    multipolygon: 'Polígono',
    multilinestring: 'Línea',
  };

  public readonly translateUnits: { [k: string]: string } = {
    meter: 'Metros',
    degree: 'Grados',
  };

  constructor(
    public router: Router,
    private http: HttpClient,
    private dialogService: DialogService,
    private keypressHandleService: KeyPressedHandlerService,
    public override profileService: ProfileService
  ) {
    super(http, keypressHandleService, profileService);
  }

  async getCategories() {
    return await lastValueFrom(this.http.get<string[]>(`${this.DATA_URL}/layers/names`));
  }

  async getLayers(queryParams: LayersParamsSearchBy) {
    return await this.search(`${this.DATA_URL}/layers`, queryParams);
  }

  async getHistory(id: number, queryParams: LayersParamsSearchBy) {
    return await this.search(`${this.DATA_URL}/layers/${id}/history`, queryParams);
  }

  async createLayer(data: FormData) {
    return await lastValueFrom(this.http.post(`${this.DATA_URL}/layers`, data));
  }

  async editLayer(id: string, data: FormData) {
    return await lastValueFrom(this.http.put(`${this.DATA_URL}/layers/${id}`, data));
  }

  async changeLayerStatus(id: string | number, reason: string) {
    await lastValueFrom(this.http.put(`${this.DATA_URL}/layers/${id}/deactivate`, { reason }));
  }

  async changeLayerHistoryStatus(id: string | number, reason: string) {
    await lastValueFrom(this.http.put(`${this.DATA_URL}/layers/${id}/activate`, { reason }));
  }

  async getLayerById(id: string) {
    return await lastValueFrom(this.http.get<LayerDataDB>(`${this.DATA_URL}/layers/${id}`));
  }

  async findActiveLayer(category: string, geometry: string = 'MultiPolygon') {
    const queryParams = {
      searchBy: { 'layerNames[]': category, 'geometries[]': geometry, isActive: true },
    };
    const response = await this.search(`${this.DATA_URL}/layers`, queryParams);
    return response.result;
  }

  // ===== TABS FUNCTIONALITIES =====

  canChangeSection() {
    return true;
  }

  override async newTab(actionType: TabActionType, options?: number, iconName?: string) {
    let title = '';
    const tabId = ++this._tabIdCounter;
    if (actionType === TabActionType.CREATE) {
      title = 'CREAR CAPA';
    } else if (actionType === TabActionType.UPDATE) {
      title = 'HISTORIAL DE CAPA';
    } else if (actionType === TabActionType.EDIT) {
      title = 'EDITAR CAPA';
    }
    const tabData: TabData<MapsModuleTabs> = {
      id: tabId,
      edition: false,
      createdByIp: '',
      title,
      createdDate: [''],
      creationInfoExpanded: false,
      createdBy: '',
      actionType: actionType,
      keysSubscriptionName: '',
      tabData: {
        mapDataForm: new FormGroup({}),
        layerId: options,
      },
      icon: iconName,
    };
    this.initializeForms(tabData);
    this._tabs.push(tabData);
    this.selectTab(tabData.id);
  }

  initializeForms(tabData: TabData<MapsModuleTabs>) {
    tabData.tabData.mapDataForm = new FormGroup({
      category: new FormControl('', []),
      data: new FormControl('', []),
      dbfFile: new FormControl('', []),
      extension: new FormControl('', []),
      iconColor: new FormControl('', []),
      iconName: new FormControl('', []),
      layerName: new FormControl('', []),
      layerType: new FormControl('', []),
      prjFile: new FormControl('', []),
      shpFile: new FormControl('', []),
      srcData: new FormControl('', []),
    });
  }

  override selectTab(id: number) {
    super.selectTab(id);
    this.router.navigateByUrl('home/maps/manage-layers', { skipLocationChange: true }).then(() => {
      if (this._selectedTab?.actionType === TabActionType.CREATE) {
        this.router.navigateByUrl('home/maps/manage-layers/add');
      } else if (this._selectedTab?.actionType === TabActionType.UPDATE) {
        const layerId = this._selectedTab?.tabData?.layerId;
        this.router.navigateByUrl(`home/maps/manage-layers/history/${layerId}`);
      } else if (this._selectedTab?.actionType === TabActionType.EDIT) {
        const layerId = this._selectedTab?.tabData?.layerId;
        this.router.navigateByUrl(`home/maps/manage-layers/edit/${layerId}`);
      }
    });
  }

  override isSafeToCloseTab(event: number): boolean {
    const tab = this.tabs.find((tab) => tab.id == event);
    return !tab?.tabData.mapDataForm?.dirty;
  }

  override async closeTab(id: number, noConfirm?: boolean) {
    if (!noConfirm && (!this.isSafeToCloseTab(id) || this.selectedTab?.confirmClose)) {
      const message = '¿Está seguro de cerrar la pestaña? Los cambios realizados serán descartados';
      const tabData = this.getTabData(id);
      const title = tabData?.title || 'CREAR CAPA';
      const icon = 'map';
      const result = await this.dialogService.yesNoOptionModal(title, message, icon);
      if (result == 'cancel') {
        if (this.selectedTab?.id == id) {
          super.closeTab(id);
          this.router.navigateByUrl('/home/maps/manage-layers');
        } else {
          super.closeTab(id);
        }
      }
    } else {
      if (this.selectedTab?.id == id) {
        super.closeTab(id);
        this.router.navigateByUrl('/home/maps/manage-layers');
      } else {
        super.closeTab(id);
      }
    }
  }
}

export interface MapsModuleTabs {
  mapDataForm: FormGroup;
  layerId?: number;
}

export interface LayersParamsSearchBy {
  searchBy: Record<string, any>;
}

export interface LayersParamsSearch extends TablePaginationData {
  isActive?: string;
  geometry?: string[];
  layerNames?: string[];
  createdAt?: string;
  dynamic?: string;
}

export interface MapsModuleBubbles {
  category: BubbleData[];
  resourceType: BubbleData[];
  status: BubbleData[];
}

export interface HistoryFilterBubbles {
  createdAt: BubbleData;
  inactivateAt: BubbleData;
}

export interface LayerStatusBubble {
  id: number;
  title: string;
  control: OfficeStatus;
  status: boolean;
}

export interface LayerResourceBubble {
  id: number;
  title: string;
  control: string;
  status: boolean;
}

export interface TransformedLayers {
  id: number;
  parentId: number;
  status: string;
  resourceType: string;
  category: string;
  createdAt: string;
  actionDisable?: string[];
  overrideTooltip?: {
    action: string;
    message: string;
  };
  stateColor?: string;
  properties: LayerProperties;
}

export interface HistoryLayer {
  id: number;
  status: string;
  resourceType: string;
  category: string;
  createdAt: string;
  inactivatedAt: string;
  actionDisable?: string[];
  overrideTooltip?: {
    action: string;
    message: string;
  };
  stateColor?: string;
  properties: LayerProperties;
}

export interface HistoryLayerParamsSearch extends TablePaginationData {
  createdAt?: string;
  inactivatedAt?: string;
  dynamic?: string;
}

export interface Layer {
  id: number;
  icon: string;
  parentId: number;
  name: string;
  isActive: boolean;
  type: string;
  createdAt: Date;
  inactivatedAt?: Date | null;
  properties: LayerProperties;
}

export interface LayerProperties {
  units: string;
  srcName: string;
  category: string;
  fileName: string;
  geometry: string;
  extension: string;
  featureCount: number;
}
