import { GeneralKnownRouteValidator, NavItem } from './types-and-helpers.known.routes';

/**
 * DON'T EVER CHANGE NUMERATION
 */
export enum ProviderKnownItemId {
  UPLOAD_REPS = '1',
  CONFIRM_REPS = '2',
  BASES = '4',
  ASSIGN_VEHICLE = '5',
  USER = '8',
  CONTRACTS = '9',
  PROVIDERS = '10',
  AMBULANCES = '12',
  OPERATION_COSTS = '13',
  MANAGE_VEHICLE = '14',
  VALIDATE_AVL = '15',
  SEND_VEHICLES_TO_ACTIVATE = '16',
  VEHICLES_TO_VALIDATE = '17',
  MANAGE_QR = '18',
  SEARCH_AND_REPORT_STATUS = '19',
  SECONDARY_TRANSFERS = '20',
  RETAINED_VEHICLE = '21',
  MANAGE_DEVICE_RESOURCE = '22',
  CONSULT_VEHICLE_TOOL_STOCK = '23',
  CONSULT_BIOMEDICAL_EQUIPMENT_STOCK = '24',
  CONSULT_MEDICINES_SUPPLIES = '25',
  PROVIDER_LIST = '26',
  APH_LOG = '27',
  PROVIDERS_LIST = '28',
}

const simpleTransformer = (input: NavItem): NavItem & { type: 'provider' } => {
  return {
    ...input,
    type: 'provider',
  };
};

export const ProvidersKnownRoutes = (k: ProviderKnownItemId): NavItem => {
  k = GeneralKnownRouteValidator(k);
  return JSON.parse(JSON.stringify(options[k]));
};

/**
 * DON'T EXPORT
 */
const options: {
  [k in ProviderKnownItemId]: NavItem & { type: 'provider' };
} = {
  [ProviderKnownItemId.UPLOAD_REPS]: simpleTransformer({
    id: ProviderKnownItemId.UPLOAD_REPS,
    displayName: 'Cargar Información REPS',
    route: '/home/providers/reps/upload',
    iconName: 'upload-file',
    parentModule: 'providers',
    allowFetchWithInclude: true,
    permissions: ['reps_ips_w', 'reps_resources_w'],
    visibleOnSidebar: true,
  }),
  [ProviderKnownItemId.PROVIDERS]: simpleTransformer({
    id: ProviderKnownItemId.PROVIDERS,
    displayName: 'Prestadores',
    route: '/home/providers',
    iconName: 'providers',
    expanded: false,
    allowFetchWithInclude: false, // don't change
    permissions: [],
    visibleOnSidebar: false,
  }),
  [ProviderKnownItemId.PROVIDER_LIST]: simpleTransformer({
    id: ProviderKnownItemId.PROVIDER_LIST,
    displayName: 'Prestadores',
    iconName: 'providers',
    route: '/home/providers/list',
    parentModule: 'providers',
    allowFetchWithInclude: true,
    permissions: [],
    visibleOnSidebar: false,
  }),
  [ProviderKnownItemId.CONFIRM_REPS]: simpleTransformer({
    id: ProviderKnownItemId.CONFIRM_REPS,
    displayName: 'Confirmar Datos REPS',
    route: '/home/providers/confirm-data',
    iconName: 'task',
    parentModule: 'providers',
    allowFetchWithInclude: true,
    permissions: ['reps_resources_confirm_w'],
    visibleOnSidebar: true,
  }),
  [ProviderKnownItemId.MANAGE_VEHICLE]: simpleTransformer({
    id: ProviderKnownItemId.MANAGE_VEHICLE,
    displayName: 'Gestionar Vehículos',
    route: '/home/providers/manageVehicle',
    iconName: 'ambulance',
    parentModule: 'providers',
    allowFetchWithInclude: true,
    permissions: ['resources_q', 'resources_caracterization_validation_w'],
    visibleOnSidebar: true,
  }),
  [ProviderKnownItemId.BASES]: simpleTransformer({
    id: ProviderKnownItemId.BASES,
    displayName: 'Gestionar Bases',
    route: '/home/providers/manageBases',
    iconName: 'bases',
    parentModule: 'providers',
    allowFetchWithInclude: true,
    permissions: ['bases_aph_q', 'bases_provider_q'],
    visibleOnSidebar: true,
  }),
  [ProviderKnownItemId.ASSIGN_VEHICLE]: simpleTransformer({
    id: ProviderKnownItemId.ASSIGN_VEHICLE,
    route: '/home/providers/assignBases',
    iconName: 'bases',
    displayName: 'Asignar y trasladar vehículos',
    allowFetchWithInclude: true,
    permissions: ['asign_resource_base', 'asign_resource_provider'],
    visibleOnSidebar: false,
  }),
  [ProviderKnownItemId.SEND_VEHICLES_TO_ACTIVATE]: simpleTransformer({
    id: ProviderKnownItemId.SEND_VEHICLES_TO_ACTIVATE,
    route: '/home/providers/sendVehiclesToActivate',
    iconName: 'send',
    displayName: 'Enviar vehículos a C4',
    allowFetchWithInclude: true,
    permissions: ['resources_caracterization_c4_w'],
    visibleOnSidebar: true,
  }),
  [ProviderKnownItemId.VEHICLES_TO_VALIDATE]: simpleTransformer({
    id: ProviderKnownItemId.VEHICLES_TO_VALIDATE,
    route: '/home/providers/vehiclesToValidate',
    iconName: 'validate-vehicle',
    displayName: 'Validar vehículos visibles',
    allowFetchWithInclude: true,
    permissions: ['resources_caracterization_dispatch_w'],
    visibleOnSidebar: true,
  }),
  [ProviderKnownItemId.USER]: simpleTransformer({} as NavItem),
  [ProviderKnownItemId.CONTRACTS]: simpleTransformer({} as NavItem),
  [ProviderKnownItemId.AMBULANCES]: simpleTransformer({
    id: ProviderKnownItemId.AMBULANCES,
    displayName: 'Ambulancias',
    route: '',
    iconName: 'card',
    permissions: ['card'],
    visibleOnSidebar: false,
  }),
  [ProviderKnownItemId.OPERATION_COSTS]: simpleTransformer({
    id: ProviderKnownItemId.OPERATION_COSTS,
    displayName: 'Costos de operación',
    route: '',
    iconName: 'tariff-groups',
    permissions: ['tariff-groups'],
    visibleOnSidebar: false,
  }),
  [ProviderKnownItemId.VALIDATE_AVL]: simpleTransformer({
    id: ProviderKnownItemId.VALIDATE_AVL,
    route: '/home/providers/validateAVL',
    iconName: 'radio-com',
    displayName: 'Validar Radio y AVL',
    allowFetchWithInclude: true,
    parentModule: 'providers',
    permissions: ['resources_caracterization_alv_radio_w', 'resources_caracterization_radio_w'],
    visibleOnSidebar: true,
  }),
  [ProviderKnownItemId.MANAGE_QR]: simpleTransformer({
    id: ProviderKnownItemId.MANAGE_QR,
    route: '/home/providers/manageQRCode',
    iconName: 'qr-code',
    displayName: 'Certificaciones de Servicios',
    allowFetchWithInclude: true,
    parentModule: 'providers',
    permissions: [
      'transfer_valorations_certs_particular_q',
      'transfer_valorations_certs_general_q',
    ],
    visibleOnSidebar: true,
  }),
  [ProviderKnownItemId.SEARCH_AND_REPORT_STATUS]: simpleTransformer({
    id: ProviderKnownItemId.SEARCH_AND_REPORT_STATUS,
    route: '/home/providers/searchAndReportStatus',
    iconName: 'ambulance',
    displayName: 'Reportar Estados de los Vehículos',
    allowFetchWithInclude: true,
    parentModule: 'providers',
    permissions: ['resources_status_general_q', 'resources_status_particular_q'],
    visibleOnSidebar: true,
  }),
  [ProviderKnownItemId.SECONDARY_TRANSFERS]: simpleTransformer({
    id: ProviderKnownItemId.SECONDARY_TRANSFERS,
    route: '/home/providers/secondary-transfers',
    iconName: 'social-distance',
    displayName: 'Traslados Secundarios',
    allowFetchWithInclude: true,
    parentModule: 'providers',
    permissions: ['record_secondary_transfer_w'],
    visibleOnSidebar: true,
  }),
  [ProviderKnownItemId.RETAINED_VEHICLE]: simpleTransformer({
    id: ProviderKnownItemId.RETAINED_VEHICLE,
    route: '/home/providers/manageStretcher',
    iconName: 'ambulance',
    displayName: 'Consultar vehículos retenidos en IPS',
    allowFetchWithInclude: true,
    parentModule: 'providers',
    permissions: ['resources_retention_particular'],
    visibleOnSidebar: true,
  }),
  [ProviderKnownItemId.PROVIDERS_LIST]: simpleTransformer({
    id: ProviderKnownItemId.PROVIDERS_LIST,
    route: '/home/providers/providers-list',
    iconName: 'providers',
    displayName: 'Prestadores',
    allowFetchWithInclude: true,
    parentModule: 'providers',
    permissions: [],
    visibleOnSidebar: false,
  }),
  [ProviderKnownItemId.MANAGE_DEVICE_RESOURCE]: simpleTransformer({
    id: ProviderKnownItemId.MANAGE_DEVICE_RESOURCE,
    route: '/home/providers/manage-device-resource',
    iconName: 'burger-menu',
    displayName: 'Administrar dispositivos por recursos',
    allowFetchWithInclude: true,
    parentModule: 'providers',
    permissions: ['manage_devices'],
    visibleOnSidebar: true, //here
  }),
  [ProviderKnownItemId.CONSULT_VEHICLE_TOOL_STOCK]: simpleTransformer({
    id: ProviderKnownItemId.CONSULT_VEHICLE_TOOL_STOCK,
    route: '/home/providers/vehicle-tool-stock',
    iconName: 'burger-menu',
    displayName: 'Consultar stock herramientas del vehículo',
    allowFetchWithInclude: true,
    parentModule: 'providers',
    permissions: ['preop_inventory_vehicle_tools', 'restore_vehicle_tools', 'manage_vehicle_tools'],
    visibleOnSidebar: true,
  }),
  [ProviderKnownItemId.CONSULT_BIOMEDICAL_EQUIPMENT_STOCK]: simpleTransformer({
    id: ProviderKnownItemId.CONSULT_BIOMEDICAL_EQUIPMENT_STOCK,
    route: '/home/providers/biomedical-equipment-stock',
    iconName: 'burger-menu',
    displayName: 'Consultar stock equipos biomédicos',
    allowFetchWithInclude: true,
    parentModule: 'providers',
    permissions: [
      'preop_inventory_biomedic_equipments',
      'restore_biomedic_equipments',
      'manage_biomedic_equipments',
    ],
    visibleOnSidebar: true,
  }),
  [ProviderKnownItemId.CONSULT_MEDICINES_SUPPLIES]: simpleTransformer({
    id: ProviderKnownItemId.CONSULT_MEDICINES_SUPPLIES,
    route: '/home/providers/medicines-and-supplies-stock',
    iconName: 'burger-menu',
    displayName: 'Consultar stock insumos y medicamentos',
    allowFetchWithInclude: true,
    parentModule: 'providers',
    permissions: [
      'preop_inventory_supplies_equipment',
      'restore_supplies_equipment',
      'manage_supplies_equipment',
    ],
    visibleOnSidebar: true,
  }),
  [ProviderKnownItemId.APH_LOG]: simpleTransformer({
    id: ProviderKnownItemId.APH_LOG,
    route: '/home/providers/aph-log',
    iconName: 'group-dots',
    displayName: 'Registro APH',
    allowFetchWithInclude: true,
    parentModule: 'providers',
    permissions: ['query_aph_record'],
    visibleOnSidebar: true,
  }),
};
