<div mat-dialog-content *ngIf="!loadingData">
  <div class="flex-row-space-between">
    <div class="flex-row-start message-dialog dialog-title pl-0-5 pt-0-5 pb-0-5">
      <app-svg class="dialog-title-icon color-secondary-200" [name]="'personal-injury'"></app-svg>
      <p class="text-b-14-white pr-2">{{ title }}</p>
    </div>
    <mat-icon
      translate="no"
      class="dialog-title-icon button-close-dialog material-icons-tone"
      (click)="cancelEdition()">
      highlight_off
    </mat-icon>
  </div>
  <div class="dialog-content-container">
    <div class="main-dialog-container">
      <div class="dialog-grid">
        <div class="display-flex width-100 justify-content-end overflow-hidden mb-0-5 height-3">
          <div class="v-scrollbar-field">
            <div class="alert-resources-grid">
              <div
                (click)="changeSection('patient')"
                class="resource-field direction-l-r width-100 overflow-hidden"
                [class]="'blue-step'">
                <app-svg
                  name="account-box"
                  class="small-header-icon mr-0-5"
                  [matTooltip]="'Datos del paciente'"></app-svg>
                <span class="medium-text-12">Datos del paciente</span>
              </div>
              <div
                (click)="changeSection('valoration')"
                class="resource-field direction-l-r width-100 overflow-hidden"
                [class]="'blue-step'">
                <app-svg
                  name="monitor-heart"
                  class="small-header-icon mr-0-5"
                  [matTooltip]="'Valoración'"></app-svg>
                <span class="medium-text-12 mr-2">Valoración</span>
              </div>
              <div
                (click)="changeSection('dispatchData')"
                class="resource-field direction-l-r width-100 overflow-hidden"
                [class]="'blue-step'">
                <app-svg
                  name="medical-service"
                  class="small-header-icon mr-0-5"
                  [matTooltip]="'Datos de despacho'"></app-svg>
                <span class="medium-text-12">Datos de despacho</span>
              </div>
            </div>
          </div>
        </div>
        <div class="width-100 middle-line-blue"></div>
        <form
          class="input-form scrollbar overflow-auto"
          [formGroup]="registerPatientForm"
          autocomplete="off">
          <button
            class="btn-accept"
            *ngIf="
              environment === 'https://api.dev.sisembogota.com' ||
              environment === 'https://api.qa.sisembogota.com'
            "
            (click)="autofillForm()"
            >AUTOCOMPLETAR</button
          >
          <div class="title-separator width-100 mv-1 special-title-separator">
            <div class="pr-1 display-flex flex-row gap-0-5 align-items-center">
              <app-svg name="medical-service" class="color-secondary-200 icon-size"></app-svg>
              <span class="text-b-16-blue" #dispatchData>DATOS DE DESPACHO</span>
            </div>
          </div>
          <div class="mb-1">
            <app-autocomplete-input
              #assignmentResourceId
              [isDisabled]="registerPatientForm.get('assignmentResourceId')!.disabled"
              [valuesToFilter]="incidentsResourceRelations"
              [defaultValue]="
                findElement(
                  registerPatientForm.get('assignmentResourceId')!.value,
                  incidentsResourceRelations
                )
              "
              [isInputEmpty]="registerPatientForm.get('assignmentResourceId')!.invalid"
              (sendValueSelected)="setAssignedResource($event)"
              [wasFormTouched]="patientFormTouched"
              [showIndex]="false"
              [isInputRequired]="inputRequired[0].required"
              [inputLabel]="'Recurso que valora'"></app-autocomplete-input>
          </div>
          <div
            class="radio-options display-flex flex-row justify-content-space-between overflow-hidden mv-1">
            <label
              [class]="
                registerPatientForm.get('dispatchType')!.invalid && registerPatientForm.touched
                  ? 'text-r-16-red'
                  : 'text-r-16-white'
              "
              >¿Paciente permite traslado? *</label
            >
            <mat-radio-group
              #dispatchType
              (change)="setDispatchType()"
              formControlName="dispatchType"
              class="radio-group text-r-16-white gap-2">
              <mat-radio-button value="patientAllowTransfer">Si</mat-radio-button>
              <mat-radio-button value="patientDoesNotAllowTransfer">No</mat-radio-button>
            </mat-radio-group>
          </div>
          <div
            class="mb-1"
            *ngIf="
              registerPatientForm.get('dispatchType')!.value === 'patientDoesNotAllowTransfer'
            ">
            <app-autocomplete-input
              #causeFailed
              [isDisabled]="registerPatientForm.get('causeFailed')!.disabled"
              [valuesToFilter]="data.tabData.noTransferCauses"
              [defaultValue]="
                findElement(
                  registerPatientForm.get('causeFailed')?.value,
                  data.tabData.noTransferCauses
                )
              "
              [isInputEmpty]="registerPatientForm.get('causeFailed')!.invalid"
              (sendValueSelected)="setCauseFailed($event)"
              [wasFormTouched]="patientFormTouched"
              [showIndex]="false"
              [isInputRequired]="inputRequired[1].required"
              [inputLabel]="'Motivo de no traslado'"
              [useSort]="true"></app-autocomplete-input>
          </div>
          <div class="mb-1" #gender>
            <app-autocomplete-input
              inputLabel="Género"
              [isDisabled]="registerPatientForm.get('gender')!.disabled"
              [isInputRequired]="true"
              [valuesToFilter]="genderList"
              [defaultValue]="findElement(registerPatientForm.get('gender')!.value, genderList)"
              [wasFormTouched]="registerPatientForm.get('gender')!.touched"
              [isInputEmpty]="registerPatientForm.get('gender')!.invalid"
              (sendValueSelected)="setGender($event)"></app-autocomplete-input>
          </div>
          <div class="width-100 display-grid input-container mb-1">
            <mat-form-field class="input-control width-100" appearance="outline">
              <mat-label>Edad</mat-label>
              <input
                #age
                type="text"
                (change)="validateAge()"
                (blur)="setAgeValidation(true)"
                (focusout)="onChangeAgeTypeAndValue()"
                maxlength="3"
                formControlName="age"
                (paste)="validatorsService.numberOnly($event)"
                (keydown)="validatorsService.numberOnly($event)"
                matInput />
            </mat-form-field>
            <div
              class="radio-options display-flex flex-row justify-content-space-between overflow-hidden mv-1">
              <mat-radio-group
                #time
                [disabled]="
                  registerPatientForm.get('age')?.value === '' ||
                  registerPatientForm.get('time')!.disabled
                "
                (change)="setAgeValidation(true)"
                formControlName="time"
                class="radio-group mt-0-5 text-r-16-white gap-2">
                <mat-radio-button value="days">Días</mat-radio-button>
                <mat-radio-button value="months">Meses</mat-radio-button>
                <mat-radio-button value="years">Años</mat-radio-button>
              </mat-radio-group>
            </div>
          </div>
          <div class="mb-0-5">
            <mat-form-field class="input-control width-100" appearance="outline">
              <mat-label>Tiempo de evolución</mat-label>
              <textarea
                #evolutionTimeObservations
                class="background-transparent"
                cdkTextareaAutosize
                cdkAutosizeMinRows="4"
                cdkAutosizeMaxRows="4"
                formControlName="evolutionTimeObservations"
                matInput
                trim></textarea>
            </mat-form-field>
          </div>
          <div class="width-100 display-flex overflow-hidden justify-content-end">
            <div
              (click)="
                registerPatientForm.get('evolutionTimeObservationsAsPriority')!.disabled
                  ? false
                  : priorityComment($event, 0)
              "
              class="text-r-16-white pv-0-5 pr-1 radio-button-container mb-0-5 special-radio-button-2">
              <span>Comentario prioritario</span>
              <div
                class="radio-button-selector"
                [class]="hasPriority[0].priority ? 'radio-button-checked' : ''"></div>
            </div>
          </div>
          <div class="mb-0-5">
            <mat-form-field class="input-control width-100" appearance="outline">
              <mat-label>Síntomas</mat-label>
              <textarea
                #symptomObservations
                class="background-transparent"
                cdkTextareaAutosize
                cdkAutosizeMinRows="4"
                cdkAutosizeMaxRows="4"
                formControlName="symptomObservations"
                matInput
                trim></textarea>
            </mat-form-field>
          </div>
          <div class="width-100 display-flex overflow-hidden justify-content-end">
            <div
              (click)="
                registerPatientForm.get('symptomObservationsAsPriority')!.disabled
                  ? false
                  : priorityComment($event, 1)
              "
              class="text-r-16-white pv-0-5 pr-1 radio-button-container mb-0-5 special-radio-button-2">
              <span>Comentario prioritario</span>
              <div
                class="radio-button-selector"
                [class]="hasPriority[1].priority ? 'radio-button-checked' : ''"></div>
            </div>
          </div>
          <div class="mb-0-5">
            <mat-form-field class="input-control width-100" appearance="outline">
              <mat-label>Antecedentes</mat-label>
              <textarea
                #backgroundObservations
                class="background-transparent"
                cdkTextareaAutosize
                cdkAutosizeMinRows="4"
                cdkAutosizeMaxRows="4"
                formControlName="backgroundObservations"
                matInput
                trim></textarea>
            </mat-form-field>
          </div>
          <div class="width-100 display-flex overflow-hidden justify-content-end">
            <div
              (click)="
                registerPatientForm.get('backgroundObservationsAsPriority')!.disabled
                  ? false
                  : priorityComment($event, 2)
              "
              class="text-r-16-white pv-0-5 pr-1 radio-button-container mb-0-5 special-radio-button-2">
              <span>Comentario prioritario</span>
              <div
                class="radio-button-selector"
                [class]="hasPriority[2].priority ? 'radio-button-checked' : ''"></div>
            </div>
          </div>
          <div class="title-separator width-100 mv-1 special-title-separator">
            <div class="pr-1 display-flex flex-row gap-0-5 align-items-center">
              <app-svg name="monitor-heart" class="color-secondary-200 icon-size"></app-svg>
              <span class="text-b-16-blue" #valoration>VALORACIÓN</span>
            </div>
          </div>
          <div
            class="radio-options display-flex flex-row justify-content-space-between overflow-hidden mv-1">
            <div
              [class]="
                registerPatientForm.get('traumaVictim')?.invalid &&
                registerPatientForm.get('traumaVictim')?.touched
                  ? 'text-r-16-red'
                  : 'text-r-16-white'
              ">
              Víctima de trauma*
            </div>
            <mat-radio-group
              #traumaVictim
              (change)="validateTraumaVictim()"
              formControlName="traumaVictim"
              class="radio-group text-r-16-white gap-2">
              <mat-radio-button value="true">Si</mat-radio-button>
              <mat-radio-button value="false">No</mat-radio-button>
            </mat-radio-group>
          </div>
          <div class="width-100 display-grid input-container mb-0-5">
            <mat-form-field class="input-control" appearance="outline">
              <mat-label>Frecuencia cardíaca (entre 0 y 300)</mat-label>
              <input
                #heartRate
                type="text"
                (paste)="validatorsService.numberOnly($event)"
                (keydown)="validatorsService.numberOnly($event)"
                maxLength="3"
                formControlName="heartRate"
                matInput />
            </mat-form-field>
            <mat-form-field class="input-control" appearance="outline">
              <mat-label>TA sistólica (entre 0 y 300)</mat-label>
              <input
                #systolicBP
                (change)="calculateTAM()"
                type="text"
                maxLength="3"
                (paste)="validatorsService.numberOnly($event)"
                (keydown)="validatorsService.numberOnly($event)"
                formControlName="systolicBP"
                matInput />
            </mat-form-field>
            <mat-form-field class="input-control" appearance="outline">
              <mat-label>TA diastólica (entre 0 y 150)</mat-label>
              <input
                #diastolicBP
                (change)="calculateTAM()"
                type="text"
                maxLength="3"
                (paste)="validatorsService.numberOnly($event)"
                (keydown)="validatorsService.numberOnly($event)"
                formControlName="diastolicBP"
                matInput />
            </mat-form-field>
            <mat-form-field class="input-control" appearance="outline">
              <mat-label>TAM*</mat-label>
              <input #MAT type="text" formControlName="MAT" matInput />
            </mat-form-field>
            <mat-form-field class="input-control" appearance="outline">
              <mat-label>Frecuencia respiratoria (entre 0 y 99)</mat-label>
              <input
                #breathingFrequency
                type="text"
                (change)="calculateRTS()"
                (paste)="validatorsService.numberOnly($event)"
                (keydown)="validatorsService.numberOnly($event)"
                maxLength="3"
                formControlName="breathingFrequency"
                matInput />
            </mat-form-field>
            <mat-form-field class="input-control" appearance="outline">
              <mat-label>Temperatura (entre 10 y 45)</mat-label>
              <input
                #temperature
                type="text"
                maxlength="4"
                (keydown)="validatorsService.numberWithDecimalOnly($event)"
                formControlName="temperature"
                matInput />
            </mat-form-field>
            <mat-form-field class="input-control" appearance="outline">
              <mat-label>Oximetría (entre 0 y 100)</mat-label>
              <input
                #oximetry
                type="text"
                (paste)="validatorsService.numberOnly($event)"
                (keydown)="validatorsService.numberOnly($event)"
                maxLength="3"
                formControlName="oximetry"
                matInput />
            </mat-form-field>
            <mat-form-field class="input-control" appearance="outline">
              <mat-label>Glasgow MRO (entre 1 y 4)</mat-label>
              <input
                #glasgowBER
                type="text"
                (change)="calculateGlasglow()"
                (paste)="validatorsService.numberOnly($event)"
                (keydown)="validatorsService.numberOnly($event)"
                maxLength="1"
                formControlName="glasgowBER"
                matInput />
            </mat-form-field>
            <mat-form-field class="input-control" appearance="outline">
              <mat-label>Glasgow MRV (entre 1 y 5)</mat-label>
              <input
                #glasgowBVB
                type="text"
                (change)="calculateGlasglow()"
                (paste)="validatorsService.numberOnly($event)"
                (keydown)="validatorsService.numberOnly($event)"
                maxLength="1"
                formControlName="glasgowBVB"
                matInput />
            </mat-form-field>
            <mat-form-field class="input-control" appearance="outline">
              <mat-label>Glasgow MRM (entre 1 y 6)</mat-label>
              <input
                #glasgowBMB
                type="text"
                (change)="calculateGlasglow()"
                (paste)="validatorsService.numberOnly($event)"
                (keydown)="validatorsService.numberOnly($event)"
                maxLength="1"
                formControlName="glasgowBMB"
                matInput />
            </mat-form-field>
            <mat-form-field class="input-control" appearance="outline">
              <mat-label>Valor Glasgow: formula*</mat-label>
              <input
                #glasglowValue
                type="text"
                (change)="calculateRTS()"
                formControlName="glasglowValue"
                matInput />
            </mat-form-field>
            <mat-form-field class="input-control" appearance="outline">
              <mat-label>Glucometría</mat-label>
              <input
                #glucometer
                type="text"
                (change)="validateGlucometer()"
                (keydown)="validatorsService.alphanumericOnly($event)"
                maxLength="4"
                formControlName="glucometer"
                matInput />
            </mat-form-field>
            <mat-form-field
              *ngIf="registerPatientForm.get('traumaVictim')!.value === 'true'"
              class="input-control"
              appearance="outline">
              <mat-label>RTS: formula</mat-label>
              <input #RTS type="text" formControlName="RTS" matInput />
            </mat-form-field>
            <mat-form-field class="input-control" appearance="outline">
              <mat-label>Tamaño pupila (entre 1 y 10) mm</mat-label>
              <input
                #pupilSize
                type="text"
                maxLength="2"
                (paste)="validatorsService.numberOnly($event)"
                (keydown)="validatorsService.numberOnly($event)"
                formControlName="pupilSize"
                matInput />
            </mat-form-field>
          </div>
          <div class="mb-1">
            <app-autocomplete-input
              #pupilShapeInput
              [isDisabled]="registerPatientForm.get('pupilShape')!.disabled"
              [valuesToFilter]="pupilShape"
              [defaultValue]="findElement(registerPatientForm.get('pupilShape')!.value, pupilShape)"
              [isInputEmpty]="registerPatientForm.get('pupilShape')!.invalid"
              (sendValueSelected)="setPupilShape($event)"
              [wasFormTouched]="patientFormTouched"
              [showIndex]="false"
              [isInputRequired]="
                registerPatientForm.get('dispatchType')!.value === 'patientAllowTransfer' ||
                registerPatientForm.get('pupilShape')!.touched
              "
              [inputLabel]="'Forma pupila'"></app-autocomplete-input>
          </div>
          <div class="mb-0-5">
            <mat-form-field class="input-control width-100" appearance="outline">
              <mat-label>Hallazgo de valoración</mat-label>
              <textarea
                #assessmentFindings
                class="background-transparent"
                cdkTextareaAutosize
                cdkAutosizeMinRows="4"
                cdkAutosizeMaxRows="4"
                formControlName="assessmentFindings"
                matInput
                trim></textarea>
            </mat-form-field>
          </div>
          <div class="width-100 display-flex overflow-hidden justify-content-end">
            <div
              (click)="
                registerPatientForm.get('assessmentFindingsObservationsAsPriority')!.disabled
                  ? false
                  : priorityComment($event, 3)
              "
              class="text-r-16-white pv-0-5 pr-1 radio-button-container mb-0-5 special-radio-button-2">
              <span>Comentario prioritario</span>
              <div
                class="radio-button-selector"
                [class]="hasPriority[3].priority ? 'radio-button-checked' : ''"></div>
            </div>
          </div>
          <div class="mb-1">
            <app-autocomplete-input
              #dx
              [isDisabled]="registerPatientForm.get('dx')!.disabled"
              [valuesToFilter]="diagnosis"
              [defaultValue]="findElement(registerPatientForm.get('dx')!.value, diagnosis)"
              [isInputEmpty]="registerPatientForm.get('dx')!.invalid"
              (sendValueSelected)="setDx($event)"
              [wasFormTouched]="patientFormTouched"
              [showIndex]="false"
              [isInputRequired]="
                registerPatientForm.get('dispatchType')!.value === 'patientAllowTransfer' ||
                registerPatientForm.get('dispatchType')!.value === ''
              "
              [inputLabel]="'Impresión DX'"></app-autocomplete-input>
          </div>
          <div class="mb-1">
            <app-autocomplete-input
              #secondDiagnosis
              [isDisabled]="registerPatientForm.get('secondDiagnosis')!.disabled"
              [valuesToFilter]="diagnosis"
              [defaultValue]="
                findElement(registerPatientForm.get('secondDiagnosis')!.value, diagnosis)
              "
              [isInputEmpty]="registerPatientForm.get('secondDiagnosis')!.invalid"
              (sendValueSelected)="setSecondDiagnosis($event)"
              [wasFormTouched]="patientFormTouched"
              [showIndex]="false"
              [isInputRequired]="inputRequired[4].required"
              [inputLabel]="'Segundo diagnóstico'"></app-autocomplete-input>
          </div>
          <div class="mb-1">
            <app-autocomplete-input
              #thirdDiagnosis
              [isDisabled]="registerPatientForm.get('thirdDiagnosis')!.disabled"
              [valuesToFilter]="diagnosis"
              [defaultValue]="
                findElement(registerPatientForm.get('thirdDiagnosis')!.value, diagnosis)
              "
              [isInputEmpty]="registerPatientForm.get('thirdDiagnosis')!.invalid"
              (sendValueSelected)="setThirdDiagnosis($event)"
              [wasFormTouched]="patientFormTouched"
              [showIndex]="false"
              [isInputRequired]="inputRequired[5].required"
              [inputLabel]="'Tercer diagnóstico'"></app-autocomplete-input>
          </div>
          <div class="mb-1">
            <app-autocomplete-input
              #causality
              [isDisabled]="registerPatientForm.get('causality')!.disabled"
              [valuesToFilter]="data.tabData.causalities"
              [defaultValue]="
                findElement(registerPatientForm.get('causality')!.value, data.tabData.causalities)
              "
              [isInputEmpty]="registerPatientForm.get('causality')!.invalid"
              (sendValueSelected)="setCausality($event)"
              [wasFormTouched]="patientFormTouched"
              [showIndex]="false"
              [isInputRequired]="inputRequired[6].required"
              [inputLabel]="'Causalidad'"
              data-cy="patient-causality-input"></app-autocomplete-input>
          </div>
          <div class="title-separator width-100 mv-1 special-title-separator">
            <div id="patient" class="pr-1 display-flex flex-row gap-0-5 align-items-center">
              <app-svg name="account-box" class="color-secondary-200 icon-size"></app-svg>
              <span class="text-b-16-blue" #patient>DATOS DEL PACIENTE</span>
            </div>
          </div>
          <div class="width-100 display-flex overflow-hidden justify-content-end">
            <div
              class="text-r-16-white pv-0-5 pr-1 radio-button-container mb-1 special-radio-button-1"
              (click)="
                registerPatientForm.get('NN')!.disabled ? false : setAnonymous($event, true)
              ">
              <span>Anónimo</span>
              <div
                class="radio-button-selector"
                [class]="isAnonymous ? 'radio-button-checked' : ''"></div>
            </div>
          </div>
          <div class="width-100 display-grid input-container mb-0-5">
            <div class="mb-1">
              <app-autocomplete-input
                #idTypeId
                [isDisabled]="registerPatientForm.get('idTypeId')!.disabled"
                [valuesToFilter]="!isAnonymous ? filteredDocuments : documents"
                [defaultValue]="findElement(registerPatientForm.get('idTypeId')!.value, documents)"
                [isInputEmpty]="registerPatientForm.get('idTypeId')!.invalid"
                (sendValueSelected)="setTypeDocument($event)"
                [wasFormTouched]="
                  patientFormTouched || registerPatientForm.get('idTypeId')!.touched
                "
                [showIndex]="false"
                [isInputRequired]="
                  registerPatientForm.get('dispatchType')!.value === 'patientAllowTransfer' ||
                  registerPatientForm.get('dispatchType')!.value === ''
                "
                [inputLabel]="'Tipo de identificación'"></app-autocomplete-input>
            </div>
            <mat-form-field class="input-control" appearance="outline">
              <mat-label>Número de identificación</mat-label>
              <input
                #idNumber
                type="text"
                maxlength="20"
                (keydown)="validatorsService.alphanumericOnly($event)"
                formControlName="idNumber"
                matInput />
            </mat-form-field>
          </div>
          <div class="custom-button-field">
            <button
              (click)="checkPatientData()"
              type="button"
              [class]="
                interoperabilityIsActive &&
                !isAnonymous &&
                registerPatientForm.get('idNumber')!.value &&
                registerPatientForm.get('idNumber')?.value !== '' &&
                registerPatientForm.get('idTypeId')?.value &&
                registerPatientForm.get('idTypeId')?.value !== ''
                  ? 'btn-accept'
                  : 'btn-disabled'
              "
              mat-button
              >COMPROBAR DATOS</button
            >
          </div>
          <div class="mb-0-5">
            <mat-form-field class="input-control width-100" appearance="outline">
              <mat-label>Primer nombre</mat-label>
              <input
                #firstName
                type="text"
                maxLength="50"
                formControlName="firstName"
                matInput
                trim />
            </mat-form-field>
          </div>
          <div *ngIf="!isAnonymous">
            <div class="mb-0-5">
              <mat-form-field class="input-control width-100" appearance="outline">
                <mat-label>Segundo nombre</mat-label>
                <input
                  #secondName
                  type="text"
                  maxLength="50"
                  formControlName="secondName"
                  matInput
                  trim />
              </mat-form-field>
            </div>
            <div class="mb-0-5">
              <mat-form-field class="input-control width-100" appearance="outline">
                <mat-label>Primer apellido</mat-label>
                <input
                  #firstLastName
                  type="text"
                  maxLength="50"
                  formControlName="firstLastName"
                  matInput
                  trim />
              </mat-form-field>
            </div>
            <div class="mb-0-5">
              <mat-form-field class="input-control width-100" appearance="outline">
                <mat-label>Segundo apellido</mat-label>
                <input
                  #secondLastName
                  type="text"
                  maxLength="50"
                  formControlName="secondLastName"
                  matInput
                  trim />
              </mat-form-field>
            </div>
          </div>
          <div class="mb-1">
            <app-autocomplete-input
              #paymentResponsibleType
              [isDisabled]="registerPatientForm.get('paymentResponsibleType')!.disabled"
              [valuesToFilter]="typePaymentEntity"
              [defaultValue]="
                findElement(
                  registerPatientForm.get('paymentResponsibleType')!.value,
                  typePaymentEntity
                )
              "
              [isInputEmpty]="registerPatientForm.get('paymentResponsibleType')!.invalid"
              (sendValueSelected)="setPaymentResponsibleType($event)"
              [wasFormTouched]="patientFormTouched"
              [showIndex]="false"
              [isInputRequired]="
                registerPatientForm.get('dispatchType')!.value === 'patientAllowTransfer' ||
                registerPatientForm.get('dispatchType')!.value === ''
              "
              [inputLabel]="'Tipo de entidad responsable del pago'"></app-autocomplete-input>
          </div>
          <div class="payer-field">
            <div class="mb-1 width-100">
              <app-autocomplete-input
                #paymentResponsible
                [isDisabled]="
                  registerPatientForm.get('paymentResponsible')!.disabled ||
                  registerPatientForm.get('paymentResponsibleType')!.value === ''
                "
                [valuesToFilter]="filteredPayers"
                [defaultValue]="
                  findElement(registerPatientForm.get('paymentResponsible')!.value, payers)
                "
                [isInputEmpty]="registerPatientForm.get('paymentResponsible')!.invalid"
                (sendValueSelected)="setPaymentResponsible($event)"
                [wasFormTouched]="patientFormTouched"
                [showIndex]="false"
                [isInputRequired]="false"
                [inputLabel]="'Pagador*'"></app-autocomplete-input>
            </div>
            <button mat-button class="btn-accept" (click)="addPaymentResponsible()">AGREGAR</button>
          </div>
          <div class="payer-capsules">
            <div class="payer-capsule" *ngFor="let data of payerCapsules">
              <span class="text-r-14-white">{{ data.title }}</span>
              <app-svg
                class="payer-icon"
                name="incident-close"
                (click)="removePayer(data.id)"></app-svg>
            </div>
          </div>
          <div class="mb-1">
            <app-autocomplete-input
              #nationalityId
              [isDisabled]="registerPatientForm.get('nationalityId')!.disabled"
              [valuesToFilter]="country"
              [defaultValue]="findElement(registerPatientForm.get('nationalityId')!.value, country)"
              [isInputEmpty]="registerPatientForm.get('nationalityId')!.invalid"
              (sendValueSelected)="setNationalityId($event)"
              [wasFormTouched]="patientFormTouched"
              [showIndex]="false"
              [isInputRequired]="
                registerPatientForm.get('dispatchType')!.value === 'patientAllowTransfer' ||
                registerPatientForm.get('dispatchType')!.value === ''
              "
              [inputLabel]="'Nacionalidad'"
              data-cy="patient-nationality-auto">
            </app-autocomplete-input>
          </div>
          <div class="mb-1">
            <app-autocomplete-input
              #populationInterest
              [isDisabled]="registerPatientForm.get('populationInterest')!.disabled"
              [valuesToFilter]="interestPopulation"
              [defaultValue]="
                findElement(
                  registerPatientForm.get('populationInterest')!.value,
                  interestPopulation
                )
              "
              [isInputEmpty]="registerPatientForm.get('populationInterest')!.invalid"
              (sendValueSelected)="setPopulationInterest($event)"
              [wasFormTouched]="patientFormTouched"
              [showIndex]="false"
              [isInputRequired]="true"
              [inputLabel]="'Población de interés'"
              data-cy="patient-population-input">
            </app-autocomplete-input>
          </div>
          <div class="mb-1">
            <app-autocomplete-input
              #ethnicity
              [isDisabled]="registerPatientForm.get('ethnicity')!.disabled"
              [valuesToFilter]="ethnicityList"
              [defaultValue]="
                findElement(registerPatientForm.get('ethnicity')!.value, ethnicityList)
              "
              [isInputEmpty]="registerPatientForm.get('ethnicity')!.invalid"
              (sendValueSelected)="setEthnicity($event)"
              [wasFormTouched]="patientFormTouched"
              [showIndex]="false"
              [isInputRequired]="inputRequired[6].required"
              [inputLabel]="'Pertenencia étnica'">
            </app-autocomplete-input>
          </div>
          <div
            class="radio-options three-radio-options display-flex flex-row justify-content-space-between overflow-hidden mv-0-5">
            <div
              [class]="
                registerPatientForm.get('clinicalHistoryIn')?.invalid &&
                registerPatientForm.get('clinicalHistoryIn')?.touched
                  ? 'text-r-16-red'
                  : 'text-r-16-white'
              ">
              Historia Clínica en*
            </div>
            <mat-radio-group
              (change)="changeMedicalValidation()"
              #clinicalHistoryIn
              formControlName="clinicalHistoryIn"
              class="radio-group gap-2">
              <mat-radio-button value="IPSOfBogota">IPS de Bogotá</mat-radio-button>
              <mat-radio-button value="IPSOutsideBogota">IPS fuera de Bogotá</mat-radio-button>
            </mat-radio-group>
          </div>
          <div
            class="mb-1"
            *ngIf="registerPatientForm.get('clinicalHistoryIn')?.value === 'IPSOfBogota'">
            <app-autocomplete-input
              #IPSOfBogotaListId
              [isDisabled]="registerPatientForm.get('IPSOfBogotaListId')!.disabled"
              [valuesToFilter]="data.tabData.providerIPS"
              [defaultValue]="
                findElement(
                  registerPatientForm.get('IPSOfBogotaListId')!.value,
                  data.tabData.providerIPS
                )
              "
              [isInputEmpty]="registerPatientForm.get('IPSOfBogotaListId')!.invalid"
              (sendValueSelected)="setIPSOfBogotaListId($event)"
              [wasFormTouched]="patientFormTouched"
              [showIndex]="false"
              [inputLabel]="'Lista de IPS de Bogotá'">
            </app-autocomplete-input>
            <!--[isInputRequired]="registerPatientForm.get('clinicalHistoryIn')?.value === 'IPSOfBogota'"-->
          </div>
          <div
            class="radio-options three-radio-options display-flex flex-row justify-content-space-between overflow-hidden mv-0-5">
            <div
              [class]="
                registerPatientForm.get('consultedInADRES')?.invalid &&
                registerPatientForm.get('consultedInADRES')?.touched
                  ? 'text-r-16-red'
                  : 'text-r-16-white'
              ">
              Consultado en ADRES*
            </div>
            <mat-radio-group
              #consultedInADRES
              (change)="setConsultedOnAddres($event)"
              formControlName="consultedInADRES"
              class="radio-group gap-2">
              <mat-radio-button value="true">Si</mat-radio-button>
              <mat-radio-button value="false">No</mat-radio-button>
            </mat-radio-group>
          </div>
          <div
            class="radio-options three-radio-options display-flex flex-row justify-content-space-between overflow-hidden mv-0-5">
            <div
              [class]="
                registerPatientForm.get('consultedInRightsChecker')?.invalid &&
                registerPatientForm.get('consultedInRightsChecker')?.touched
                  ? 'text-r-16-red'
                  : 'text-r-16-white'
              ">
              Consultado en comprobador de derechos*
            </div>
            <mat-radio-group
              #consultedInRightsChecker
              (change)="setConsultedOnRightsChecker($event)"
              formControlName="consultedInRightsChecker"
              class="radio-group gap-2">
              <mat-radio-button value="true">Si</mat-radio-button>
              <mat-radio-button value="false">No</mat-radio-button>
            </mat-radio-group>
          </div>
          <div
            class="radio-options three-radio-options display-flex flex-row justify-content-space-between overflow-hidden mv-0-5">
            <div
              [class]="
                registerPatientForm.get('consultedInDPNOrSISBEN')?.invalid &&
                registerPatientForm.get('consultedInDPNOrSISBEN')?.touched
                  ? 'text-r-16-red'
                  : 'text-r-16-white'
              ">
              Consultado en DNP o SISBEN*
            </div>
            <mat-radio-group
              #consultedInDPNOrSISBEN
              formControlName="consultedInDPNOrSISBEN"
              class="radio-group gap-2">
              <mat-radio-button value="true">Si</mat-radio-button>
              <mat-radio-button value="false">No</mat-radio-button>
            </mat-radio-group>
          </div>
          <div class="mb-0-5">
            <mat-form-field class="input-control width-100" appearance="outline">
              <mat-label>Observaciones</mat-label>
              <textarea
                #observations
                class="background-transparent"
                cdkTextareaAutosize
                cdkAutosizeMinRows="4"
                cdkAutosizeMaxRows="4"
                formControlName="observations"
                matInput
                trim></textarea>
            </mat-form-field>
          </div>
          <div class="width-100 display-flex overflow-hidden justify-content-end">
            <div
              (click)="
                registerPatientForm.get('observations')!.disabled
                  ? false
                  : priorityComment($event, 4)
              "
              class="text-r-16-white pv-0-5 pr-1 radio-button-container mb-0-5 special-radio-button-2">
              <span>Comentario prioritario</span>
              <div
                class="radio-button-selector"
                [class]="hasPriority[4].priority ? 'radio-button-checked' : ''"></div>
            </div>
          </div>
        </form>
        <div class="middle-line-blue"></div>
        <div class="width-100 distribute-incidents-edition pb-0-5">
          <button
            mat-button
            type="button"
            *ngIf="data.edit || patientSaved"
            (click)="copyCloseIncidentConfirm(true)"
            class="btn-accept min-width-100">
            GENERAR TEXTO
          </button>
          <button
            mat-button
            type="button"
            (click)="cancelRegistration()"
            class="btn-accept column-position-2 min-width-100">
            CANCELAR
          </button>
          <button
            mat-button
            type="submit"
            class="btn-accept column-position-3 min-width-100"
            (click)="registerPatient()"
            [disabled]="patientSaved">
            GUARDAR
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
