import { Injectable } from '@angular/core';
import { io, Socket } from 'socket.io-client';
import { AlertService } from '../alert.service';
import { environment } from 'src/environments/environment';

interface AuthOptions {
  actorId: number;
}

export interface WSMessage {
  subject: string;
  textBody: string;
  sentAt: Date;
}

@Injectable({
  providedIn: 'root',
})
export class SocketService {
  private socket: Socket | null = null;

  constructor(private readonly alertService: AlertService) {}

  // Call this method when the user logs in
  public connect(authOptions: AuthOptions): void {
    if (environment.api.includes('localhost') || environment.api.includes('10.8')) {
      console.info('Disabling websockets for localhost');
      return;
    }
    // If already connected, do nothing
    if (this.isConnected()) {
      console.info('Socket is already connected.');
      return;
    }

    // Initialize the socket connection
    this.socket = io(environment.socketServer, {
      auth: authOptions,
      transports: ['websocket'],
    });

    // Handle connection events
    this.socket.on('connect', () => {
      console.info('Connected to the socket server.');
    });

    this.socket.on('disconnect', () => {
      console.info('Disconnected from the socket server.');
    });

    this.socket.on('message', (data: any) => {
      console.info('Received a message from the socket server:', data);
    });

    // this.socket.on('notificationMessage', (data: WSMessage) => {
    //   console.info('Received a notification message from the socket server:', data);
    //   this.alertService.showNotification(data.textBody, data.subject, () => {
    //     console.info('Notification clicked');
    //   });
    // });
  }

  // Call this method to check if the socket is connected
  public isConnected(): boolean {
    return !!this.socket && this.socket.connected;
  }

  // Call this method when the user logs out
  public disconnect(): void {
    if (this.socket) {
      this.socket.disconnect();
      this.socket = null;
    }
  }

  // Use this method to send messages
  public sendMessage(event: string, message: any): void {
    if (this.isConnected()) {
      this.socket?.emit(event, message);
    }
  }

  // Set up a listener for specific events
  public onEvent(event: string, action: (data: any) => void): void {
    this.socket?.on(event, action);
  }

  // Remove a listener for specific events
  public offEvent(event: string): void {
    this.socket?.off(event);
  }
}
