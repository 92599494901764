import { GeneralKnownRouteValidator, NavItem } from './types-and-helpers.known.routes';

export enum MapsKnownItemId {
  MAPS_MODULE = '0',
  MANAGE_LAYERS = '1',
}

const simpleTransformer = (input: NavItem): NavItem & { type: 'maps' } => {
  return {
    ...input,
    type: 'maps',
  };
};

export const MapsKnownRoutes = (k: MapsKnownItemId): NavItem => {
  k = GeneralKnownRouteValidator(k);
  return JSON.parse(JSON.stringify(options[k]));
};

const options: {
  [k in MapsKnownItemId]: NavItem & { type: 'maps' };
} = {
  [MapsKnownItemId.MAPS_MODULE]: simpleTransformer({
    id: MapsKnownItemId.MAPS_MODULE,
    displayName: 'Parámetros módulo de mapas',
    iconName: 'map-black',
    route: '/home/maps/manage-layers',
    visibleOnSidebar: false,
    permissions: [],
  }),
  [MapsKnownItemId.MANAGE_LAYERS]: simpleTransformer({
    id: MapsKnownItemId.MANAGE_LAYERS,
    displayName: 'Administrar capas del sistema',
    iconName: 'layers-administration',
    route: '/home/maps/manage-layers',
    parentModule: '/home/maps',
    visibleOnSidebar: false,
    permissions: [],
  }),
};
