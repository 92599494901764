import { GeneralKnownRouteValidator, NavItem } from './types-and-helpers.known.routes';

export enum HumanTalentTrainingKnownItemId {
  HUMAN_TALENT_TRAINING = '0',
  UPDATE_DATA = '1',
  HUMAN_TALENT_TRAINING_LIST = '2',
  DROP_OUT = '3',
  DROP_OUT_VALIDATE_CODE = '4',
}

const simpleTransformer = (input: NavItem): NavItem & { type: 'human-talent-training' } => {
  return {
    ...input,
    type: 'human-talent-training',
  };
};

export const HumanTalentTrainingKnownRoutes = (k: HumanTalentTrainingKnownItemId): NavItem => {
  k = GeneralKnownRouteValidator(k);
  return JSON.parse(JSON.stringify(options[k]));
};

const options: {
  [k in HumanTalentTrainingKnownItemId]: NavItem & { type: 'human-talent-training' };
} = {
  [HumanTalentTrainingKnownItemId.HUMAN_TALENT_TRAINING]: simpleTransformer({
    id: HumanTalentTrainingKnownItemId.HUMAN_TALENT_TRAINING,
    route: '/home/human-talent-training',
    iconName: 'academic-cap',
    displayName: 'Formación al Talento Humano',
    visibleOnSidebar: false,
    permissions: [],
  }),
  [HumanTalentTrainingKnownItemId.UPDATE_DATA]: simpleTransformer({
    id: HumanTalentTrainingKnownItemId.UPDATE_DATA,
    route: '/home/human-talent-training/update-data',
    iconName: 'account-box-pencil',
    displayName: 'Actualizar datos',
    parentModule: 'human-talent',
    visibleOnSidebar: true,
    permissions: ['course_attendee_personal_data_u'],
  }),
  [HumanTalentTrainingKnownItemId.HUMAN_TALENT_TRAINING_LIST]: simpleTransformer({
    id: HumanTalentTrainingKnownItemId.HUMAN_TALENT_TRAINING_LIST,
    route: '/home/human-talent-training/list',
    iconName: 'academic-cap',
    displayName: 'FORMACIÓN AL TALENTO HUMANO',
    parentModule: 'human-talent',
    visibleOnSidebar: false,
    permissions: [],
  }),
  [HumanTalentTrainingKnownItemId.DROP_OUT]: simpleTransformer({
    id: HumanTalentTrainingKnownItemId.DROP_OUT,
    route: '/home/human-talent-training/update-data/drop-out',
    iconName: 'incident-close',
    displayName: 'DAR DE BAJA EN EL SISTEMA',
    parentModule: 'human-talent',
    visibleOnSidebar: false,
    permissions: ['course_participants_withdraw'],
  }),
  [HumanTalentTrainingKnownItemId.DROP_OUT_VALIDATE_CODE]: simpleTransformer({
    id: HumanTalentTrainingKnownItemId.DROP_OUT_VALIDATE_CODE,
    route: '/home/human-talent-training/update-data/drop-out/validate-code',
    iconName: 'incident-close',
    displayName: 'DAR DE BAJA EN EL SISTEMA',
    parentModule: 'human-talent',
    visibleOnSidebar: false,
    permissions: [],
  }),
};
