import {
  ParametricService,
  TabActionType,
  TabData,
} from '../../types/parametric.service.abstract.class';
import { IncidentOpenTabOptions, IncidentTabData, OfficeKeyItem } from '../../types/tabs.interface';
import { HttpClient } from '@angular/common/http';
import { DatePipe } from '@angular/common';
import { BehaviorSubjectService } from '../behaviorSubject.service';
import { ProfileService } from '../profile/profile.service';
import { LocationsService } from '../locations.service';
import { AlertService } from '../alert.service';
import { DialogService } from '../dialog.service';
import { CustomValidators, ValidatorsService } from '../validators.service';
import { Router } from '@angular/router';
import { KeyPressedHandlerService } from '../key-pressed-handler.service';
import { IncidentDispatchService } from './incident-dispatch.service';
import { LoadingService } from '../loading.service';
import { ParametricsSupportRequestedInterface } from '../../types/parametrics.interface';
import { PromisedProperties } from '../../utils/promisedProperties';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { IncidentsService } from './incidents.service';
import { CreateIncident, CreateIncidentData, IncidentDB } from '../../types/incident.interface';
import { lastValueFrom } from 'rxjs';
import { ServerResponse } from '../../types/http.interfaces';
import { environment } from '../../../../environments/environment';
import { IncidentsConsciousnessStatesService } from './incidents-sub-resources/incidents.consciousness.states.service';
import { ResourcesTypesService } from '../administration/aph-resource/resources.types.service';
import { IncidentsDiagnosisService } from './incidents-sub-resources/incidents.diagnosis.service';
import { IncidentsClosingCodesService } from './incidents-sub-resources/incidents.closing.codes.service';
import { IncidentsSitesService } from './incidents-sub-resources/incidents.sites.service';
import { IncidentsNoTransferCausesService } from './incidents-sub-resources/incidents.no.transfer.causes.service';
import { IncidentsPriorityService } from './incidents-sub-resources/incidents.priority.service';
import { IncidentsZonesService } from './incidents-sub-resources/incidents.zones.service';
import { IncidentsAttentionsService } from './incidents-sub-resources/incidents.attentions.service';
import { IncidentsTypesService } from './incidents-sub-resources/incidents.types.service';
import { IncidentsCausalitiesService } from './incidents-sub-resources/incidents.causalities.service';
import { KnownRoles } from '../roles-and-permissions/known-roles.data';
import { OfficeService } from '../office.service';
import { APHProvider } from '@smartsoft-types/sisem-resources';
import { PermissionsValidatorService } from '../roles-and-permissions/permissions-validator.service';
import { ClosingCode, IncidentResourceRelation, Patient } from '@smartsoft-types/sisem-incidents';
import { RoleTabService } from '../administration/roles/role-tab.service';

export class IncidentsTabManagerService extends ParametricService<
  IncidentTabData,
  IncidentOpenTabOptions
> {
  public openingNewTab: boolean = false;

  constructor(
    private http: HttpClient,
    private datePipe: DatePipe,
    public bSubjectService: BehaviorSubjectService,
    public override profileService: ProfileService,
    private locationService: LocationsService,
    private alertService: AlertService,
    private dialogService: DialogService,
    private validatorsService: ValidatorsService,
    private router: Router,
    keyPressedHandlerService: KeyPressedHandlerService,
    private incidentDispatchService: IncidentDispatchService,
    private loadingService: LoadingService,
    private incidentsService: IncidentsService,
    private incidentsConsciousnessStatesService: IncidentsConsciousnessStatesService,
    private incidentsDiagnosisService: IncidentsDiagnosisService,
    private incidentsClosingCodesService: IncidentsClosingCodesService,
    private incidentsSitesService: IncidentsSitesService,
    private incidentsNoTransferCausesService: IncidentsNoTransferCausesService,
    private incidentsPriorityService: IncidentsPriorityService,
    private incidentsZonesService: IncidentsZonesService,
    private incidentsAttentionsService: IncidentsAttentionsService,
    private incidentsTypesService: IncidentsTypesService,
    private resourcesTypesService: ResourcesTypesService,
    private incidentsCausalitiesService: IncidentsCausalitiesService,
    private officeService: OfficeService,
    private permissionsValidatorService: PermissionsValidatorService,
    private roleService: RoleTabService
  ) {
    super(keyPressedHandlerService, profileService);
    super.newTabAction = {
      icon: '',
      toolTip: 'Agregar Incidente',
    };
  }

  override isSafeToCloseTab(id: number): boolean {
    const tab = this.getTabData(id);
    if (tab) {
      // validate if is safe to close
      if (
        tab.tabData.formAction.dirty ||
        tab.tabData.formAction2.dirty ||
        tab.tabData.formAction3.dirty ||
        tab.tabData.formAction4.dirty ||
        tab.tabData.alertForm.dirty
      ) {
        return false;
      }
    }
    return true;
  }

  /**
   *
   * @param actionType action to be performed register or edit
   * @param options id record
   */
  override async newTab(actionType: TabActionType, options?: IncidentOpenTabOptions) {
    let text = '';
    let isEditing: boolean = false;
    let open = true;
    // validate if already open
    if (options?.incidentId) {
      const result = this.tabs.find(
        (value) => value.tabData.selectedIncident?.id === options.incidentId
      );
      if (result) {
        await this.selectTab(result.id);
        return;
      }
    }
    this.openingNewTab = true;
    this.loadingService.setIncidentLoading(true);
    //
    const resource = options?.incidentId
      ? await this.incidentsService.getIncidentById(
          { incidentId: options.incidentId, isFirstFetch: true },
          options.resentSearch ? options.resentSearch : false
        )
      : undefined;
    let modalTitle: string = 'CREAR';
    const tabId = ++this._tabIdCounter;
    if (actionType === TabActionType.CREATE) {
      const yearNow = new Date().getFullYear().toString().substring(2);
      text = `CRU----------${yearNow}`;
    } else if (actionType === TabActionType.EDIT) {
      if (!resource) return;
      const incidentYear = new Date(resource.incidentDB.premierOneDate).getUTCFullYear().toString();
      text = 'CRU-' + resource.incidentDB.code + '-' + incidentYear.substring(2);
      isEditing = true;
      modalTitle = 'CONFIRMAR';
      switch (options?.checkStatus) {
        case 'tarm':
          if (resource.incidentDB.status?.title.toLowerCase() === 'inicial') {
            options.step = 1;
          } else {
            this.loadingService.setLoading(false);
            const returnValue = await this.dialogService.yesNoOptionModal(
              'GESTIONAR RECURSOS',
              `El incidente se encuentra en estado ${resource.incidentDB.status?.title.toLowerCase()}.
                ¿Desea gestionarlo?`,
              'info'
            );
            if (returnValue && returnValue !== 'cancelled') {
              options.step = 1;
            } else {
              open = false;
            }
            this.loadingService.setIncidentLoading(false);
          }
          break;
        case 'medic_regulation':
          options.step = 1;
          break;
        case 'delivery':
          if (
            resource.incidentDB.status?.title.toLowerCase() === 'pendiente' ||
            resource.incidentDB.status?.title.toLowerCase() === 'activo'
          ) {
            options.step = 2;
          } else if (resource.incidentDB.status?.title.toLowerCase() === 'inicial') {
            this.loadingService.setLoading(false);
            const returnValue = await this.dialogService.yesNoOptionModal(
              'GESTIONAR RECURSOS',
              'El incidente se encuentra en estado Inicial. ¿Desea gestionarlo?',
              'info'
            );

            if (returnValue && returnValue !== 'cancelled') {
              options.step = 2;
            } else {
              open = false;
            }
            this.loadingService.setIncidentLoading(false);
          }
          break;
      }
    }
    const sort = (
      a: ClosingCode | APHProvider | OfficeKeyItem,
      b: ClosingCode | APHProvider | OfficeKeyItem
    ) => {
      const valueA: string = a.title.toLowerCase();
      const valueB: string = b.title.toLowerCase();
      return valueA.localeCompare(valueB, navigator.languages[0] || navigator.language, {
        numeric: true,
        ignorePunctuation: true,
      });
    };
    // parallelize operations
    const promisesResults = await PromisedProperties({
      consciousnessStates: this.incidentsConsciousnessStatesService.getAll(),
      aphResourceType: this.resourcesTypesService.getAll(),
      diagnosis: this.incidentsDiagnosisService.getAll(),
      supportFrom: this.getSupportFrom(),
      closingCodes: this.incidentsClosingCodesService.getClosingCodes(),
      providerIPS: await this.officeService.getIpsOffice(undefined, undefined, true),
      site: this.incidentsSitesService.getAll(),
      noTransferCauses: this.incidentsNoTransferCausesService.getAll(),
      causalities: this.incidentsCausalitiesService.getAll(),
      patient: [] as Patient[],
      priorities: this.incidentsPriorityService.getAll(),
      zones: this.incidentsZonesService.getAll(),
      attention: this.incidentsAttentionsService.getAll(),
      southRoomIncidencesType: this.incidentsTypesService.getAll(),
      resourceRelations: [] as IncidentResourceRelation[],
      modifyingCircumstances: resource?.incidentDB.incidentType?.id
        ? await this.incidentsTypesService.getModifyingCircumstance(
            resource?.incidentDB.incidentType?.id
          )
        : [],
    });

    // here we validate the roles to add the data depending if it has the correct permission or not
    if (resource?.incidentDB) {
      if (
        await this.permissionsValidatorService.hasAccessToSection([
          'incident_assigned_patients_q',
          'patients_general_q',
        ])
      ) {
        promisesResults['patient'] = await this.incidentDispatchService.getAllPatients(
          resource?.incidentDB.id
        );
      }
    }
    if (
      options?.incidentId &&
      (await this.permissionsValidatorService.hasAccessToSection(['incident_assigned_resources_q']))
    ) {
      promisesResults['resourceRelations'] =
        await this.incidentsService.getIncidentsResourceRelations(options.incidentId);
    }

    const providersIps: OfficeKeyItem[] = promisesResults['providerIPS'].map((value) => {
      return { ...value, title: value.businessName };
    });

    promisesResults.site = promisesResults.site.filter((item) => item.active);
    //
    const tabData: TabData<IncidentTabData> = {
      id: tabId,
      edition: false,
      createdDate: [],
      creationInfoExpanded: false,
      createdBy: '',
      createdByIp: '',
      tabData: {
        tabColor: 'red-tab-circle',
        alertIpsObservationsPriority: false,
        consciousnessStates: promisesResults['consciousnessStates'],
        wasAlreadyCreated: isEditing,
        showLog: await this.permissionsValidatorService.hasAccessToSection([
          'incident_log_record_q',
        ]),
        openDispatchModal: options?.checkTMModal,
        focusRegulationElement: options?.focusRegulation,
        wasAlreadyConfirmed: false,
        wasAlreadyRegulated: false,
        aphResourceType: promisesResults['aphResourceType'],
        priorityColor: 'circle-error',
        activeResources: false,
        incidentStatus: 'INICIAL',
        premierOneDate: [],
        regulationSelectedIndex: 1,
        confirmedAtIp: resource?.incidentDB.confirmedAtIp
          ? resource?.incidentDB.confirmedAtIp.split(',')[0]
          : '',
        confirmedDate: [],
        confirmedAtId: '',
        modalTitle: modalTitle,
        formActionTouched: false,
        requestedSupport: [],
        requestedSupportTable: [],
        requestedResource: [],
        requestedResourceTable: [],
        causalities: promisesResults['causalities'],
        diagnosis: promisesResults['diagnosis'],
        supportFrom: promisesResults['supportFrom'],
        resourceRelations: promisesResults['resourceRelations'],
        closingCodes: promisesResults['closingCodes'].sort(sort),
        providerIPS: providersIps.sort(sort),
        extraClosingCodes: [],
        injuredPriorities: {},
        formAction2Touched: false,
        formAction3Touched: false,
        formAction4Touched: false,
        priority: '',
        formAction: new FormGroup({}),
        formAction2: new FormGroup({}),
        formAction3: new FormGroup({}),
        formAction4: new FormGroup({}),
        alertForm: new FormGroup({}),
        alertIPSIsActive: false,
        noTransferReasonHasPriority: false,
        noResourceReasonHasPriority: false,
        managementIndicationsHasPriority: false,
        recommendationsHasPriority: false,
        maxAgeLength: 3,
        maxEvolutionTimeLength: 2,
        maxVascularEvolutionTimeLength: 2,
        noTransferCauses: promisesResults['noTransferCauses'],
        emergencyHasPriority: false,
        typificationHasPriority: false,
        clinicalPictureHasPriority: false,
        medicalHistoryHasPriority: false,
        selectedIncident: resource?.incidentDB,
        incident: options?.incident,
        patient: promisesResults['patient'],
        selectedService: false,
        isAnonymous: false,
        selectedIndex: options?.step ? options.step : 0,
        incidentTypeCode: '',
        mentalProblemsSelected: false,
        addedPhones: [],
        addedSecondaryCodes: [],
        hasRegulationId: undefined,
        incidentDistribution: [],
        closureBrands: [],
        hasPriority: false,
        priorities: promisesResults['priorities'],
        zones: promisesResults['zones'],
        attention: promisesResults['attention'],
        southRoomIncidencesType: promisesResults['southRoomIncidencesType'],
        modifyingCircumstances: promisesResults['modifyingCircumstances'],
        isEditing: isEditing,
        closureSecondaryCodes: [],
        //602
        fallApproximateDropHeightAsPriority: false,
        fallAmputatedOrWoundBodySiteAsPriority: false,
        fallVisibleBleedingObservationsAsPriority: false,
        fallDisplacementDifficultyObservationsAsPriority: false,
        //603
        isCurrentlyConvulsingHasPriority: false,
        hasIngestedMedsOrAlcoholHasPriority: false,
        hasEpilepsyDiabetesOrHypertensionHasPriority: false,
        //604
        hasOtherIllnessesHasPriority: false,
        machineCharacteristicsHasPriority: false,
        //605
        chestPainKindOfPainObservationsAsPriority: false,
        chestPainRadiatingPainObservationsAsPriority: false,
        chestPainAssociatedSymptomsObservationsAsPriority: false,
        chestPainActivityAtTimeOfPainObservationsAsPriority: false,
        chestPainHeartOrDiabetesAssociatedDiseaseObservationsAsPriority: false,
        chestPainSmokerOrObeseObservationsAsPriority: false,
        chestPainPainIntensityAsPriority: false,
        //606
        electrocutionOtherInjuryObservationHasPriority: false,
        //607
        //EXTRAHOSPITALARY
        gynecobstetricPathologyNewbornIsBreathingObservationsAsPriority: false,
        gynecobstetricPathologyNewbornHasCriedSinceBirthObservationsAsPriority: false,
        //NOT EXTRAHOSPITALARY
        gynecobstetricPathologyAbdominalPainLumbarPainOrContractionsObservationsAsPriority: false,
        gynecobstetricPathologyBleedingOrExpelledAnyFluidFromVaginaObservationsAsPriority: false,
        gynecobstetricPathologyHeadacheBlurredVisionSwollenLegsObservationsAsPriority: false,
        gynecobstetricPathologyFeverOrConvulsionsObservationsAsPriority: false,
        //609
        thoughtsOfSuicidePatientCompanionObservationsAsPriority: false,
        thoughtsOfSuicideReasonSuicidalThoughtsObservationsAsPriority: false,
        thoughtsOfSuicideWayToCommitSuicideObservationsAsPriority: false,
        thoughtsOfSuicideAlcoholOrPsychoactiveSubstancesObservationsAsPriority: false,
        thoughtsOfSuicideDiagnosedMentalIllnessObservationsAsPriority: false,
        thoughtsOfSuicideMentalIllnessTreatmentObservationsAsPriority: false,
        thoughtsOfSuicideRecentSignificantLossObservationsAsPriority: false,
        //610
        substanceCausingIntoxicationHasPriority: false,
        //611
        mistreatmentEventObservationsAsPriority: false,
        mistreatmentInjuredVictimObservationsAsPriority: false,
        mistreatmentVictimIsDisabledObservationsAsPriority: false,
        mistreatmentIsEventHappeningRightNowObservationsAsPriority: false,
        mistreatmentMaxAgeLength: 3,
        //611-CM
        mistreatmentWomenEventObservationsAsPriority: false,
        mistreatmentWomenIsEventHappeningRightNowObservationsAsPriority: false,
        mistreatmentWomenInjuredWomenObservationsAsPriority: false,
        mistreatmentWomenIsPregnantObservationsAsPriority: false,
        mistreatmentWomenIsDisabledObservationsAsPriority: false,
        //613
        medicationOrSubstanceObservationsHasPriority: false,
        otherInjuryObservationsHasPriority: false,
        haveAnyIllnessHasPriority: false,
        //615
        burnsCauseBurnObservationHasPriority: false,
        burnsAffectedBodyAreaObservationHasPriority: false,
        burnsOtherInjuryObservationHasPriority: false,
        //616
        vaginalBleedingIsPregnantObservationHasPriority: false,
        //715
        naturalEventEventObservationHasPriority: false,
        //906
        sexualViolenceEventObservationsAsPriority: false,
        sexualViolenceTimeSinceEventObservationsAsPriority: false,
        sexualViolenceInjuredVictimObservationsAsPriority: false,
        sexualViolenceVictimIsDisabledObservationsAsPriority: false,
        sexualViolenceIsVictimPregnantObservationAsPriority: false,
        sexualViolenceReceivedSupportFromInstitutionObservationAsPriority: false,
        //918
        suicideAttemptTryingToHurtHimselfObservationAsPriority: false,
        suicideAttemptPatientAtRiskObservationAsPriority: false,
        suicideAttemptPatientCompanionObservationsAsPriority: false,
        suicideAttemptTriedToCommitSuicideBeforeObservationsAsPriority: false,
        suicideAttemptConsumesAlcoholOrOtherSubstancesObservationsAsPriority: false,
        suicideAttemptRecentSignificantLossObservationsAsPriority: false,
        suicideAttemptHasACaptivePersonObservationsAsPriority: false,
        //924
        sickSymptomObservationHasPriority: false,
        sickPatientCompanionObservationHasPriority: false,
        sickMedicalHistoryObservationHasPriority: false,
        //941
        mentalDisorderCompanionObservationAsPriority: false,
        mentalDisorderAggressivePatientObservationAsPriority: false,
        mentalDisorderArmedPatientObservationAsPriority: false,
        mentalDisorderPeopleAtRiskOrAttackedObservationAsPriority: false,
        mentalDisorderDiagnosedMentalIllnessObservationsAsPriority: false,
        mentalDisorderMentalIllnessTreatmentObservationsAsPriority: false,
        mentalDisorderConsumesAlcoholOrOtherSubstancesObservationsAsPriority: false,
        //28
        supportSymptomObservationHasPriority: false,
        isFirstFetch: false,
      },
      actionType: actionType,
      title: text,
      keysSubscriptionName: '',
    };
    await this.locationService.getStates();
    this.generateForms(tabData);
    if (open) {
      this._tabs.push(tabData);
    }
    await this.selectTab(tabData.id);
    this.loadingService.setIncidentLoading(false);
    this.openingNewTab = false;
  }

  /**
   *
   * @param id
   */
  override async selectTab(id: number): Promise<void> {
    if (this.selectedTab && this.selectedTab.id === id) {
      const tabData = this.selectedTab;
      this.incidentsService.tabExists.emit(tabData);
      return;
    }
    super.selectTab(id);
    const tabData = this.selectedTab;
    this.incidentsService.tabExists.emit(tabData);
    await this.router.navigateByUrl('/home', { skipLocationChange: true }).then(() => {
      if (this._selectedTab?.actionType === TabActionType.CREATE) {
        this.router.navigateByUrl('/home/incidents/create/' + id);
      } else if (this._selectedTab?.actionType === TabActionType.EDIT) {
        this.router.navigateByUrl(
          '/home/incidents/edit/' + this._selectedTab.tabData.selectedIncident?.id
        );
      }
    });
  }

  /**
   * GENERATES ALL FORMS
   **/
  private generateForms(tabData: TabData<IncidentTabData>) {
    //FIRST STEP FORM
    tabData.tabData.formAction = new FormGroup({
      incidentNumber: new FormControl('', Validators.required),
      premierOneDate: new FormControl('', [
        Validators.required,
        CustomValidators.minDate(this.getDate(false) ?? ''),
        CustomValidators.maxDate(this.getDate() ?? ''),
      ]),
      premierOneHour: new FormControl('', Validators.required),
      incidentStatus: new FormControl({
        value: 'INICIAL',
        disabled: true,
      }),
      incidentTypeSouthRoom: new FormControl('', Validators.required),
      modifyingCircumstance: new FormControl({
        value: '',
        disabled: true,
      }),
      givenPriority: new FormControl({ value: '', disabled: true }),
      incidentAddress: new FormControl(''),
      addressComplement: new FormControl(''),
      locality: new FormControl('', Validators.required),
      attentionPlace: new FormControl(''),
      referencePoint: new FormControl('', [Validators.required]),
      zonesDh: new FormControl(''),
      neighborhood: new FormControl(''),
      zone: new FormControl(1, Validators.required),
      callerFirstName: new FormControl('', [
        Validators.required,
        Validators.pattern(this.validatorsService.alphabeticWithAccentsValidator),
      ]),
      callerSecondName: new FormControl('', [
        Validators.pattern(this.validatorsService.alphabeticWithAccentsValidator),
      ]),
      callerLastName: new FormControl('', [
        Validators.pattern(this.validatorsService.alphabeticWithAccentsValidator),
      ]),
      callerSecondLastName: new FormControl('', [
        Validators.pattern(this.validatorsService.alphabeticWithAccentsValidator),
      ]),
      relationshipWithPatient: new FormControl({
        value: '',
        disabled: true,
      }),
      otherRelation: new FormControl({ value: '', disabled: true }),
      callerPhoneNumber: new FormControl('', [
        Validators.minLength(7),
        Validators.maxLength(10),
        Validators.pattern(this.validatorsService.numberValidator),
      ]),
      creationComments: new FormControl(''),
      distributeIncidentTo: new FormControl(''),
    });

    //SECOND STEP FORM
    tabData.tabData.formAction2 = new FormGroup({
      incidentTypeCRUE: new FormControl(''),
      modifyingCircumstance: new FormControl(''),
      gender: new FormControl('', [Validators.required]),
      age: new FormControl('', [
        Validators.required,
        Validators.max(120),
        Validators.maxLength(3),
        Validators.pattern(this.validatorsService.numberValidator),
      ]),
      ageType: new FormControl('', [Validators.required]),
      emergency: new FormControl('', []),
      consciousnessState: new FormControl('', []),
      breathingState: new FormControl('', []),
      stateAgitated: new FormControl(false, []),
      stateNasalFlaring: new FormControl(false, []),
      stateCyanosis: new FormControl(false, []),
      stateIntercostalAndSupraclavicular: new FormControl(false, []),
      stateSlow: new FormControl(false, []),
      stateFast: new FormControl(false, []),
      dizziness: new FormControl('', []),
      coldSweat: new FormControl('', []),
      paleness: new FormControl('', []),

      //HU33
      isPregnant: new FormControl('', []),
      pregnancyAbdominalPain: new FormControl('', []),
      pregnancyVaginalBleeding: new FormControl('', []),
      pregnancyHeadache: new FormControl('', []),
      pregnancyStomachache: new FormControl('', []),
      pregnancySwollenLegs: new FormControl('', []),
      pregnancyConvulsed: new FormControl('', []),
      pregnancyFever: new FormControl('', []),
      pregnancyTime: new FormControl('', []),
      pregnancyTimeType: new FormControl('', []),

      //601
      vascularAccidentDifficulties: new FormControl(''),
      vascularAccidentEvolutionTime: new FormControl({
        value: '',
        disabled: true,
      }),
      vascularAccidentEvolutionTimeType: new FormControl({
        value: '',
        disabled: true,
      }),
      vascularAccidentDifficultiesFaceDeviation: new FormControl({
        value: '',
        disabled: true,
      }),
      vascularAccidentDifficultiesSpeech: new FormControl({
        value: '',
        disabled: true,
      }),
      vascularAccidentDifficultiesMovementDifficulties: new FormControl({
        value: '',
        disabled: true,
      }),
      vascularAccidentLiquorIngested: new FormControl(''),
      vascularAccidentLiquorIngestedDescription: new FormControl(''),
      vascularAccidentLiquorIngestedQuantity: new FormControl(''),
      vascularAccidentPriorityHistory: new FormControl(''),

      //602
      fallApproximateDropHeight: new FormControl({ value: '', disabled: true }, [
        Validators.required,
      ]),
      fallAmputatedOrWoundBodySite: new FormControl({ value: '', disabled: true }, [
        Validators.required,
      ]),
      fallVisibleBleeding: new FormControl({ value: '', disabled: true }),
      fallVisibleBleedingObservations: new FormControl({ value: '', disabled: true }, [
        Validators.required,
      ]),
      fallDisplacementDifficulty: new FormControl({
        value: '',
        disabled: true,
      }),
      fallDisplacementDifficultyObservations: new FormControl({ value: '', disabled: true }, [
        Validators.required,
      ]),

      //603
      isCurrentlyConvulsing: new FormControl({ value: '', disabled: true }, [Validators.required]),
      hasIngestedMedsOrAlcohol: new FormControl({ value: '', disabled: true }, [
        Validators.required,
      ]),
      hasEpilepsyDiabetesOrHypertension: new FormControl({ value: '', disabled: true }, [
        Validators.required,
      ]),
      //604
      hasOtherIllnesses: new FormControl({ value: '', disabled: true }, [Validators.required]),
      usesOxygenAtHome: new FormControl({ value: '', disabled: true }, [Validators.required]),
      machineCharacteristics: new FormControl({ value: '', disabled: true }, [Validators.required]),
      //605
      chestPainPresentsPainSince: new FormControl({ value: '', disabled: true }, [
        Validators.required,
      ]),
      chestPainPresentsPainSinceUnit: new FormControl({ value: '', disabled: true }, [
        Validators.required,
      ]),
      chestPainKindOfPainObservations: new FormControl({ value: '', disabled: true }, [
        Validators.required,
      ]),
      chestPainPainIntensity: new FormControl({ value: 0, disabled: true }, [Validators.required]),
      chestPainRadiatingPainObservations: new FormControl({ value: '', disabled: true }, [
        Validators.required,
      ]),
      chestPainAssociatedSymptomsObservations: new FormControl({ value: '', disabled: true }, [
        Validators.required,
      ]),
      chestPainActivityAtTimeOfPainObservations: new FormControl({ value: '', disabled: true }, [
        Validators.required,
      ]),
      chestPainHeartOrDiabetesAssociatedDisease: new FormControl({
        value: '',
        disabled: true,
      }),
      chestPainHeartOrDiabetesAssociatedDiseaseObservations: new FormControl(
        {
          value: '',
          disabled: true,
        },
        [Validators.required]
      ),
      chestPainSmokerOrObeseObservations: new FormControl({ value: '', disabled: true }, [
        Validators.required,
      ]),

      //606
      inTouchWithSource: new FormControl({ value: '', disabled: true }, [Validators.required]),
      electrocutionOtherInjuryObservation: new FormControl({ value: '', disabled: true }, [
        Validators.required,
      ]),
      //607
      gynecobstetricPathologyOutOfHospitalDelivery: new FormControl({ value: '', disabled: true }, [
        Validators.required,
      ]),
      //EXTRAHOSPITALARY
      gynecobstetricPathologyNewbornIsBreathing: new FormControl({ value: '', disabled: true }, [
        Validators.required,
      ]),
      gynecobstetricPathologyNewbornIsBreathingObservations: new FormControl(
        {
          value: '',
          disabled: true,
        },
        [Validators.required]
      ),
      gynecobstetricPathologyVaginalBleedingAmountOfBleeding: new FormControl(
        {
          value: '',
          disabled: true,
        },
        [Validators.required]
      ),
      gynecobstetricPathologyAmountOfWeeksOrMonthsPregnantWasThePatient: new FormControl(
        {
          value: '',
          disabled: true,
        },
        [Validators.required, Validators.pattern(this.validatorsService.numberValidator)]
      ),
      gynecobstetricPathologyWeeksOrMonthsPregnantWasThePatient: new FormControl(
        {
          value: '',
          disabled: true,
        },
        [Validators.required]
      ),
      gynecobstetricPathologyAmountTimeSinceBirth: new FormControl(
        {
          value: '',
          disabled: true,
        },
        [Validators.required, Validators.pattern(this.validatorsService.numberValidator)]
      ),
      gynecobstetricPathologyTimeSinceBirth: new FormControl({ value: '', disabled: true }, [
        Validators.required,
      ]),
      gynecobstetricPathologyNewbornHasCriedSinceBirth: new FormControl(
        {
          value: '',
          disabled: true,
        },
        [Validators.required]
      ),
      gynecobstetricPathologyNewbornHasCriedSinceBirthObservations: new FormControl(
        {
          value: '',
          disabled: true,
        },
        [Validators.required]
      ),
      //NOT EXTRAHOSPITALARY
      gynecobstetricPathologyAmountOfWeeksOrMonthsPregnantIsThePatient: new FormControl(
        {
          value: '',
          disabled: true,
        },
        [Validators.required, Validators.pattern(this.validatorsService.numberValidator)]
      ),
      gynecobstetricPathologyWeeksOrMonthsPregnantIsThePatient: new FormControl(
        {
          value: '',
          disabled: true,
        },
        [Validators.required]
      ),
      gynecobstetricPathologyAbdominalPainLumbarPainOrContractionsObservations: new FormControl(
        {
          value: '',
          disabled: true,
        },
        [Validators.required]
      ),
      gynecobstetricPathologyBleedingOrExpelledAnyFluidFromVaginaObservations: new FormControl(
        {
          value: '',
          disabled: true,
        },
        [Validators.required]
      ),
      gynecobstetricPathologyHeadacheBlurredVisionSwollenLegsObservations: new FormControl(
        {
          value: '',
          disabled: true,
        },
        [Validators.required]
      ),
      gynecobstetricPathologyFeverOrConvulsionsObservations: new FormControl(
        {
          value: '',
          disabled: true,
        },
        [Validators.required]
      ),

      //609
      thoughtsOfSuicidePatientCompanionObservations: new FormControl(
        {
          value: '',
          disabled: true,
        },
        [Validators.required]
      ),
      thoughtsOfSuicideThoughtsSince: new FormControl(
        {
          value: '',
          disabled: true,
        },
        [Validators.required, Validators.pattern(this.validatorsService.numberValidator)]
      ),
      thoughtsOfSuicideThoughtsSinceUnit: new FormControl({ value: '', disabled: true }, [
        Validators.required,
      ]),
      thoughtsOfSuicideReasonSuicidalThoughtsObservations: new FormControl(
        {
          value: '',
          disabled: true,
        },
        [Validators.required]
      ),
      thoughtsOfSuicideWayToCommitSuicideObservations: new FormControl(
        {
          value: '',
          disabled: true,
        },
        [Validators.required]
      ),
      thoughtsOfSuicideAlcoholOrPsychoactiveSubstances: new FormControl({
        value: '',
        disabled: true,
      }),
      thoughtsOfSuicideAlcoholOrPsychoactiveSubstancesObservations: new FormControl(
        {
          value: '',
          disabled: true,
        },
        [Validators.required]
      ),
      thoughtsOfSuicideDiagnosedMentalIllnessObservations: new FormControl(
        {
          value: '',
          disabled: true,
        },
        [Validators.required]
      ),
      thoughtsOfSuicideMentalIllnessTreatment: new FormControl({
        value: '',
        disabled: true,
      }),
      thoughtsOfSuicideMentalIllnessTreatmentObservations: new FormControl(
        {
          value: '',
          disabled: true,
        },
        [Validators.required]
      ),
      thoughtsOfSuicideRecentSignificantLossObservations: new FormControl(
        {
          value: '',
          disabled: true,
        },
        [Validators.required]
      ),
      //610
      substanceCausingIntoxication: new FormControl({ value: '', disabled: true }, [
        Validators.required,
      ]),
      withIntentToSelfHarm: new FormControl({ value: '', disabled: true }, [Validators.required]),
      //611
      mistreatmentEventObservations: new FormControl({ value: '', disabled: true }, []),
      mistreatmentIsEventHappeningRightNowObservations: new FormControl(
        { value: '', disabled: true },
        []
      ),
      mistreatmentInjuredVictim: new FormControl({ value: '', disabled: true }, []),
      mistreatmentInjuredVictimObservations: new FormControl({ value: '', disabled: true }, []),
      mistreatmentAmountOfMistreatmentPeople: new FormControl({ value: '', disabled: true }, []),
      mistreatmentAgeOfVictim: new FormControl({ value: '', disabled: true }, []),
      mistreatmentAgeOfVictimUnit: new FormControl({ value: '', disabled: true }, []),
      mistreatmentVictimIsDisabledObservations: new FormControl({ value: '', disabled: true }, []),
      //611-CM
      mistreatmentWomenEventObservations: new FormControl({ value: '', disabled: true }, []),
      mistreatmentWomenIsEventHappeningRightNowObservations: new FormControl(
        { value: '', disabled: true },
        []
      ),
      mistreatmentWomenInjured: new FormControl({ value: '', disabled: true }, []),
      mistreatmentWomenInjuredWomenObservations: new FormControl({ value: '', disabled: true }, []),
      mistreatmentWomenAmountOfMistreatmentWoman: new FormControl(
        { value: '', disabled: true },
        []
      ),
      mistreatmentWomenAgeOfVictim: new FormControl({ value: '', disabled: true }, []),
      mistreatmentWomenAgeOfVictimUnit: new FormControl({ value: '', disabled: true }, []),
      mistreatmentWomenIsPregnantObservations: new FormControl({ value: '', disabled: true }, []),
      mistreatmentWomenIsDisabledObservations: new FormControl({ value: '', disabled: true }, []),
      //613
      medicationOrSubstanceObservations: new FormControl({ value: '', disabled: true }, [
        Validators.required,
      ]),
      otherInjuryObservations: new FormControl({ value: '', disabled: true }, [
        Validators.required,
      ]),
      haveAnyIllness: new FormControl({ value: '', disabled: true }, [Validators.required]),
      //615
      burnsCauseBurnObservation: new FormControl({ value: '', disabled: true }, [
        Validators.required,
      ]),
      burnsAffectedBodyAreaObservation: new FormControl({ value: '', disabled: true }, [
        Validators.required,
      ]),
      burnsOtherInjuryObservation: new FormControl({ value: '', disabled: true }, [
        Validators.required,
      ]),
      //616
      vaginalBleedingPatientAge: new FormControl({ value: '', disabled: true }, [
        Validators.required,
        Validators.pattern(this.validatorsService.numberValidator),
        Validators.maxLength(2),
      ]),
      vaginalBleedingAmountOfBleeding: new FormControl({ value: '', disabled: true }, [
        Validators.required,
      ]),
      vaginalBleedingIsPregnant: new FormControl({ value: '', disabled: true }, [
        Validators.required,
      ]),
      vaginalBleedingIsPregnantObservation: new FormControl({ value: '', disabled: true }, [
        Validators.required,
      ]),
      //617
      gastrointestinalSymptomsVomiting: new FormControl({ value: '', disabled: true }, []),
      brown: new FormControl({ value: '', disabled: true }, []),
      withBlood: new FormControl({ value: '', disabled: true }, []),
      gastrointestinalSymptomsHowManyTimesVomited: new FormControl(
        { value: '', disabled: true },
        []
      ),
      gastrointestinalSymptomsDiarrhea: new FormControl({ value: '', disabled: true }, []),
      blackStools: new FormControl({ value: '', disabled: true }, []),
      bloodyStools: new FormControl({ value: '', disabled: true }, []),
      gastrointestinalSymptomsHowManyStools: new FormControl({ value: '', disabled: true }, []),
      gastrointestinalSymptomsToleratesOrally: new FormControl({ value: '', disabled: true }, []),
      //715
      naturalEventEventObservation: new FormControl({ value: '', disabled: true }, [
        Validators.required,
      ]),
      naturalEventAreTherePeopleTrapped: new FormControl({
        value: '',
        disabled: true,
      }),
      naturalEventAmountPeopleTrapped: new FormControl({ value: '', disabled: true }, [
        Validators.pattern(this.validatorsService.numberValidator),
        Validators.maxLength(3),
      ]),
      naturalEventAreTherePeopleInjured: new FormControl({
        value: '',
        disabled: true,
      }),
      naturalEventAmountPeopleInjured: new FormControl({ value: '', disabled: true }, [
        Validators.pattern(this.validatorsService.numberValidator),
        Validators.maxLength(3),
      ]),
      //906
      sexualViolenceEventObservations: new FormControl({ value: '', disabled: true }, [
        Validators.required,
      ]),
      sexualViolenceTimeSinceEventObservations: new FormControl({ value: '', disabled: true }, [
        Validators.required,
      ]),
      sexualViolenceAgeOfVictim: new FormControl({ value: '', disabled: true }, [
        Validators.required,
      ]),
      sexualViolenceAgeOfVictimUnit: new FormControl({ value: '', disabled: true }, [
        Validators.required,
      ]),
      sexualViolenceInjuredVictim: new FormControl({ value: '', disabled: true }, [
        Validators.required,
      ]),
      sexualViolenceInjuredVictimObservations: new FormControl({ value: '', disabled: true }, [
        Validators.required,
      ]),
      sexualViolenceVictimIsDisabledObservations: new FormControl({ value: '', disabled: true }, [
        Validators.required,
      ]),
      sexualViolenceIsVictimPregnantObservation: new FormControl({
        value: '',
        disabled: true,
      }),
      sexualViolenceReceivedSupportFromInstitutionObservation: new FormControl(
        {
          value: '',
          disabled: true,
        },
        [Validators.required]
      ),
      //918
      suicideAttemptTryingToHurtHimselfObservation: new FormControl({ value: '', disabled: true }, [
        Validators.required,
      ]),
      suicideAttemptPatientAtRiskObservation: new FormControl({ value: '', disabled: true }, [
        Validators.required,
      ]),
      suicideAttemptPatientCompanionObservations: new FormControl({ value: '', disabled: true }, [
        Validators.required,
      ]),
      suicideAttemptTriedToCommitSuicideBefore: new FormControl({ value: '', disabled: true }, [
        Validators.required,
      ]),
      suicideAttemptTriedToCommitSuicideBeforeObservations: new FormControl(
        {
          value: '',
          disabled: true,
        },
        []
      ),
      suicideAttemptConsumesAlcoholOrOtherSubstances: new FormControl(
        {
          value: '',
          disabled: true,
        },
        [Validators.required]
      ),
      suicideAttemptConsumesAlcoholOrOtherSubstancesObservations: new FormControl(
        {
          value: '',
          disabled: true,
        },
        []
      ),
      suicideAttemptRecentSignificantLossObservations: new FormControl(
        {
          value: '',
          disabled: true,
        },
        [Validators.required]
      ),
      suicideAttemptHasACaptivePersonObservations: new FormControl({ value: '', disabled: true }, [
        Validators.required,
      ]),
      //992
      aircraftAccidentInjuredPeople: new FormControl({ value: '', disabled: true }, [
        Validators.required,
      ]),
      aircraftAccidentAmountPeopleInjured: new FormControl({ value: '', disabled: true }, [
        Validators.pattern(this.validatorsService.numberValidator),
        Validators.maxLength(3),
      ]),
      aircraftAccidentTrappedPeople: new FormControl({ value: '', disabled: true }, [
        Validators.required,
      ]),
      aircraftAccidentAmountPeopleTrapped: new FormControl({ value: '', disabled: true }, [
        Validators.pattern(this.validatorsService.numberValidator),
        Validators.maxLength(3),
      ]),
      //924
      sickSymptomObservation: new FormControl({ value: '', disabled: true }, [Validators.required]),
      sickPatientCompanionObservation: new FormControl({ value: '', disabled: true }, [
        Validators.required,
      ]),
      sickMedicalHistoryObservation: new FormControl({ value: '', disabled: true }, [
        Validators.required,
      ]),
      //941
      mentalDisorderCompanionObservation: new FormControl({ value: '', disabled: true }, [
        Validators.required,
      ]),
      mentalDisorderAggressivePatientObservation: new FormControl({ value: '', disabled: true }, [
        Validators.required,
      ]),
      mentalDisorderArmedPatientObservation: new FormControl({ value: '', disabled: true }, [
        Validators.required,
      ]),
      mentalDisorderPeopleAtRiskOrAttacked: new FormControl({ value: '', disabled: true }, [
        Validators.required,
      ]),
      mentalDisorderPeopleAtRiskOrAttackedObservation: new FormControl(
        { value: '', disabled: true },
        []
      ),
      mentalDisorderDiagnosedMentalIllnessObservations: new FormControl(
        {
          value: '',
          disabled: true,
        },
        [Validators.required]
      ),
      mentalDisorderMentalIllnessTreatmentObservations: new FormControl(
        {
          value: '',
          disabled: true,
        },
        [Validators.required]
      ),
      mentalDisorderConsumesAlcoholOrOtherSubstances: new FormControl(
        {
          value: '',
          disabled: true,
        },
        [Validators.required]
      ),
      mentalDisorderConsumesAlcoholOrOtherSubstancesObservations: new FormControl(
        { value: '', disabled: true },
        []
      ),
      //28
      supportSymptomObservation: new FormControl({ value: '', disabled: true }, [
        Validators.required,
      ]),

      //608
      amountOfInjuredPeople: new FormControl('', [
        Validators.pattern(this.validatorsService.numberValidator),
        Validators.min(0),
        Validators.max(999),
        Validators.maxLength(3),
      ]),
      injuredBodyParts: new FormControl(''),
      hasAmputations: new FormControl(''),
      hasAmputationsObservations: new FormControl(''),
      isAnyoneBleeding: new FormControl(''),
      mobilityDifficulties: new FormControl(''),
      mobilityDifficultiesObservations: new FormControl(''),

      //MATPEL
      substanceName: new FormControl(''),
      injuriesPresented: new FormControl(''),
      //CHECKBOXES
      policeMatpel: new FormControl({ value: false, disabled: true }),
      noneMatpel: new FormControl(true),
      idigerMatpel: new FormControl({ value: false, disabled: true }),
      civilDefenseMatpel: new FormControl({
        value: false,
        disabled: true,
      }),
      fireFightersMatpel: new FormControl({
        value: false,
        disabled: true,
      }),
      sMobilityMatpel: new FormControl({ value: false, disabled: true }),
      redCrossMatpel: new FormControl({ value: false, disabled: true }),

      //RESCUE
      trappedOrLostLocation: new FormControl(''),
      trappedOrLostPeopleQuantity: new FormControl('', [
        Validators.pattern(this.validatorsService.numberValidator),
        Validators.min(0),
        Validators.max(99),
        Validators.maxLength(2),
      ]),
      trappedOrLostWithHealthIssues: new FormControl(''),
      trappedOrLostWithHealthIssuesObservations: new FormControl(''),

      //LESPER
      shortBluntType: new FormControl({ value: false, disabled: true }),
      noInformationType: new FormControl(true),
      bluntInstrumentType: new FormControl({
        value: false,
        disabled: true,
      }),
      gunfireType: new FormControl({ value: false, disabled: true }),
      sharpType: new FormControl({ value: false, disabled: true }),

      //EXPLOSION
      explosionCausedBy: new FormControl(''),
      injuriesPresentedExplosion: new FormControl(''),

      policeExplosion: new FormControl({ value: false, disabled: true }),
      noneExplosion: new FormControl(true),
      idigerExplosion: new FormControl({ value: false, disabled: true }),
      civilDefenseExplosion: new FormControl({
        value: false,
        disabled: true,
      }),
      fireFightersExplosion: new FormControl({
        value: false,
        disabled: true,
      }),
      sMobilityExplosion: new FormControl({
        value: false,
        disabled: true,
      }),
      redCrossExplosion: new FormControl({
        value: false,
        disabled: true,
      }),

      //FIRE
      trappedPeopleInFire: new FormControl('', [
        Validators.pattern(this.validatorsService.numberValidator),
        Validators.min(0),
        Validators.max(99),
        Validators.maxLength(2),
      ]),
      injuredPeopleByFire: new FormControl('', [
        Validators.pattern(this.validatorsService.numberValidator),
        Validators.min(0),
        Validators.max(99),
        Validators.maxLength(2),
      ]),

      //ACCTRA
      serviceBusType: new FormControl(false),
      scholarBusType: new FormControl(false),
      transmilenioBusType: new FormControl(false),
      otherBusType: new FormControl(false),

      dangerousMaterial: new FormControl(''),
      dangerousMaterialObservations: new FormControl(''),
      injuredPeopleQuantityAcctra: new FormControl('', [
        Validators.pattern(this.validatorsService.numberValidator),
        Validators.min(0),
        Validators.max(999),
        Validators.maxLength(3),
      ]),
      trappedPeopleQuantityAcctra: new FormControl('', [
        Validators.pattern(this.validatorsService.numberValidator),
        Validators.min(0),
        Validators.max(999),
        Validators.maxLength(3),
      ]),

      //ANIPEL
      hurtBodyParts: new FormControl(''),

      amputation: new FormControl(''),
      amputationObservations: new FormControl(''),

      bleedingQuantity: new FormControl(''),

      movementDifficulties: new FormControl(''),
      movementDifficultiesObservations: new FormControl(''),

      injuriesTypeAnipel: new FormControl(''),

      isAnimalKnown: new FormControl(''),
      isAnimalVaccinated: new FormControl(''),
      isAnimalCompletelyVaccinated: new FormControl(''),

      evolutionTime: new FormControl(''),
      evolutionTimeType: new FormControl(''),
      clinicalPicture: new FormControl('', [Validators.required]),
      typificationObservation: new FormControl('', [Validators.maxLength(500)]),
    });

    //THIRD STEP FORM
    tabData.tabData.formAction3 = new FormGroup({
      incidentType: new FormControl(''),
      validateIncidentType: new FormControl(''),
      modifyingCircumstance: new FormControl(''),
      validateCircumstance: new FormControl(''),
      incidentPriority: new FormControl(''),
      validateIncidentPriority: new FormControl(''),
      typificationObservations: new FormControl({
        value: '',
        disabled: true,
      }),
      tarmRecommendations: new FormControl(''),
      transferToMentalHealth: new FormControl(false),
      transferToTARM: new FormControl(false),
      informRiskManagement: new FormControl(false),
      informPublicHealth: new FormControl(false),
      requestSupport: new FormControl('', []),
      managementIndications: new FormControl(''),
      resourceRequired: new FormControl(2),
      requiredResourceAPH: new FormControl(''),
      ambulanceNoMeritReason: new FormControl(''),
      requestIncidentClosure: new FormControl(2),
      closureCode: new FormControl(''),
      closureBrand: new FormControl(''),
      distributeIncidentSocialImpact: new FormControl(false),
      distributeIncidentEmergency: new FormControl(false),
      distributeIncidentEvent: new FormControl(false),
      principalDiagnosis: new FormControl(''),
      validatePrincipalDiagnosis: new FormControl({
        value: '',
        disabled: true,
      }),
      optionalDiagnosis: new FormControl(''),
      validateOptionalDiagnosis: new FormControl(''),
      optionalDiagnosis2: new FormControl(''),
      validateOptionalDiagnosis2: new FormControl(''),
      transferRequired: new FormControl(2),
      destinationIPS: new FormControl(''),
      IPSAddress: new FormControl({ value: '', disabled: true }),
      contactPhone: new FormControl({ value: '', disabled: true }),
      noTransferReason: new FormControl(''),
      noTransferCause: new FormControl(''),
    });
    // FOURTH STEP FORM
    tabData.tabData.formAction4 = new FormGroup({
      primaryCode: new FormControl('', Validators.required),
      parentIncident: new FormControl('', [
        Validators.pattern(this.validatorsService.numberValidator),
      ]),
      year: new FormControl(''),
      secondaryCode: new FormControl('', Validators.required),
      sites: new FormControl(''),
      ethnicity: new FormControl(''),
      populationInterest: new FormControl(''),
      closingObservations: new FormControl(''),
      closingObservationsAsPriority: new FormControl(false),
      typeOfParentIncident: new FormControl({
        value: '',
        disabled: true,
      }),
      modifyingCircumstanceParent: new FormControl({
        value: '',
        disabled: true,
      }),
      addressParentIncident: new FormControl({
        value: '',
        disabled: true,
      }),
    });
    tabData.tabData.selectedService = false;
  }

  displaySuggestionMessage() {
    switch (this.selectedTab?.tabData?.selectedIncident?.modifyingCircumstance?.code) {
      case '1': //MATPEL
        this.alertService.showNotification(
          'Informe al médico regulador la sustancia que ocasionó el incidente'
        );
        break;
      case '4': //EXPLOSION
        this.alertService.showNotification(
          'En caso de que la situación no se haya controlado y la víctima se ' +
            'encuentre en el lugar, indique no ingresar al sitio y esperar la ayuda de Bomberos.'
        );
        break;
      case '5': //INCENDIO
        this.alertService.showNotification(
          'En caso de que la situación no se haya controlado y la víctima se ' +
            'encuentre en el lugar, indique no ingresar al sitio y esperar la ayuda de Bomberos.'
        );
        break;
    }
  }

  override getTabData(id: number, incidentId?: number): TabData<IncidentTabData> {
    let tabData: TabData<IncidentTabData> | undefined;
    if (incidentId) {
      tabData = this._tabs.find((value) => value.tabData.selectedIncident?.id === incidentId);
      return tabData ? tabData : this._tabs[0];
    }
    tabData = this._tabs.find((value) => value.id === id);
    return tabData ? tabData : this._tabs[0];
  }

  async createIncident(data: CreateIncidentData) {
    const body: CreateIncident = {
      auditData: {
        roleId: this.profileService.selectedRole?.id
          ? (this.profileService.selectedRole as unknown as number)
          : 1,
      },
      data: data,
    };
    try {
      const res = await lastValueFrom(
        this.http.post<ServerResponse<IncidentDB>>(
          `${environment.api}/incidents-ms/api/v1/incidents`,
          body
        )
      );
      return res.data;
    } catch (e) {
      throw e;
    }
  }

  setSubscription(subscriptionName: string) {
    this.setActiveSubscription(subscriptionName);
  }

  async closeOtherTab(id: number, forceClose: boolean = false) {
    let closeTab: boolean = forceClose;

    //Validate if form modified is from selected steep on incident
    if (
      this.selectedTab?.tabData.formAction.dirty &&
      this.selectedTab.tabData.selectedIndex !== 0
    ) {
      closeTab = true;
    } else if (
      this.selectedTab?.tabData.formAction2.dirty &&
      this.selectedTab.tabData.selectedIndex !== 1
    ) {
      closeTab = true;
    } else if (
      this.selectedTab?.tabData.formAction3.dirty &&
      this.selectedTab.tabData.selectedIndex !== 1
    ) {
      closeTab = true;
    } else if (
      this.selectedTab?.tabData.formAction4.dirty &&
      this.selectedTab.tabData.selectedIndex !== 3
    ) {
      closeTab = true;
    } else if (
      this.selectedTab?.tabData.alertForm.dirty &&
      this.selectedTab.tabData.selectedIndex !== 2
    ) {
      closeTab = true;
    }

    if (!forceClose) {
      if (this.isSafeToCloseTab(id)) {
        closeTab = true;
      } else {
        if (this.selectedTab?.tabData.selectedIndex !== 0) {
          if (this.profileService.selectedRole?.name !== KnownRoles.MEDIC_REGULATION) {
            if (
              this.profileService.selectedRole?.name === KnownRoles.TARM &&
              this.selectedTab?.tabData.selectedIndex === 2
            ) {
              this.incidentDispatchService.onCloseTab.emit(true);
            } else if (this.selectedTab?.tabData.selectedIndex === 3) {
              const closeModal = await this.dialogService.yesNoOptionModal(
                'CERRAR INCIDENTE',
                '¿Está seguro de cerrar la pestaña? Los cambios realizados serán descartados',
                'cancel'
              );
              if (closeModal === 'cancel') {
                closeTab = true;
                this.bSubjectService.bSubjectClosedTab.next(`CANCEL CLOSE-${id}`);
              } else {
                closeTab = false;
              }
            } else if (
              (this.profileService.selectedRole?.name === KnownRoles.TARM_EMERGENCY_NETWORK ||
                this.profileService.selectedRole?.name === KnownRoles.TARM_REFERENCE ||
                this.profileService.selectedRole?.name === KnownRoles.TARM) &&
              this.selectedTab?.tabData.selectedIndex === 2
            ) {
              const confirmModal = await this.dialogService.twoOptionsModal(
                '¿Está seguro de cerrar la pestaña? Los cambios realizados serán descartados',
                'ALERTAR IPS',
                { firstButton: 'SI', secondButton: 'NO' }
              );
              closeTab = !!(confirmModal && confirmModal !== 'cancelled');
            } else {
              const confirmModal = await this.dialogService.twoOptionsModal(
                '¿Está seguro de cerrar la pestaña? Los cambios realizados serán descartados',
                'CONFIRMAR INCIDENTE',
                { firstButton: 'SI', secondButton: 'NO' }
              );
              closeTab = !!(confirmModal && confirmModal !== 'cancelled');
            }
          } else {
            const response = await this.dialogService.twoOptionsModal(
              '¿Está seguro de cerrar la pestaña? Los cambios realizados serán descartados',
              'REGULACIÓN MÉDICA',
              { firstButton: 'SI', secondButton: 'NO' }
            );
            closeTab = !!(response && response !== 'cancelled');
          }
        } else {
          const tab = this.getTabData(id);
          const returnValue = await this.dialogService.twoOptionsModal(
            'El incidente no ha sido creado. ¿Desea crearlo?',
            tab.tabData.modalTitle + ' INCIDENTE',
            { firstButton: 'CREAR', secondButton: 'CANCELAR' }
          );
          if (returnValue && returnValue !== 'cancelled') {
            this.bSubjectService.bSubjectClosedTab.next(`CLOSED TAB-${id}`);
          } else if (returnValue === 'cancelled') {
            this.bSubjectService.bSubjectClosedTab.next(`CLOSE CREATE TAB-${id}`);
          }
        }
      }
    }
    if (closeTab) {
      this.closeTab(id);
      if (!this.selectedTab) {
        await this.selectMenu();
      }
    }
  }

  async getSupportFrom(): Promise<ParametricsSupportRequestedInterface[]> {
    return [
      {
        id: 1,
        title: 'Policía',
        code: 'police',
        createdAt: new Date().toDateString(),
        updatedAt: new Date().toDateString(),
      },
      {
        id: 2,
        title: 'Bomberos',
        code: 'firemen',
        createdAt: new Date().toDateString(),
        updatedAt: new Date().toDateString(),
      },
      {
        id: 3,
        title: 'S. Movilidad',
        code: 'mobilityS',
        createdAt: new Date().toDateString(),
        updatedAt: new Date().toDateString(),
      },
      {
        id: 4,
        title: 'IDIGER',
        code: 'idiger',
        createdAt: new Date().toDateString(),
        updatedAt: new Date().toDateString(),
      },
      {
        id: 5,
        title: 'S. Mujer',
        code: 'womenS',
        createdAt: new Date().toDateString(),
        updatedAt: new Date().toDateString(),
      },
    ];
  }

  async selectMenu(skipDeleteSubscription: boolean = false) {
    await this.router.navigateByUrl('/home/incidents/list');
    this.unselectTab(skipDeleteSubscription);
  }

  private getDate(max: boolean = true) {
    if (max) return this.datePipe.transform(new Date(), 'YYYY-MM-dd');
    else {
      const timeStamp = new Date().getTime();
      const yesterdayTimeStamp = timeStamp - 24 * 60 * 60 * 1000;
      return this.datePipe.transform(new Date(yesterdayTimeStamp), 'YYYY-MM-dd');
    }
  }
}
