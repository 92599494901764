import { HttpClient, HttpEvent, HttpHandler, HttpRequest } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { from, lastValueFrom, Observable } from 'rxjs';
import { ProfileService } from '../profile/profile.service';
import { AuthService } from './auth.service';
import { Router } from '@angular/router';
import { environment } from '../../../../environments/environment';
import { tempGetAccessToken } from './app-mobile.temp';

export const INACTIVE_ERROR = 'Request that need tokens are disallowed while user is inactive';

@Injectable()
export class CustomHttpInterceptor implements CustomHttpInterceptor {
  private profileService: ProfileService | undefined;
  private authService: AuthService | undefined;

  constructor(
    public router: Router,
    private injector: Injector,
    private httpClient: HttpClient
  ) {
    setTimeout(() => {
      this.profileService = this.injector.get(ProfileService);
      this.authService = this.injector.get(AuthService);
    }, 1);
  }

  /**
   * intercept interceptor to refresh token and errors control
   * @param request request to send
   * @param next next request step
   */
  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    this.authService = this.injector.get(AuthService);
    const skipIntercept = request.headers.has('skip');
    request = request.clone({
      headers: request.headers.delete('skip'),
    });
    if (
      skipIntercept ||
      request.url.includes('openstreetmap') ||
      request.url.includes('hereapi') ||
      (request.url.includes('geoserver') &&
        (request.url.includes('admin.') || request.url.includes('admin-'))) ||
      request.url.includes('.json')
    ) {
      return next.handle(request);
    }
    if (
      request.url.includes(environment.mobileUrl) &&
      (environment.api.includes('dev.') || environment.authService.includes('dev.'))
    ) {
      return from(this.validateTokenAppMobile(request, next));
    }
    return from(this.validateToken(request, next));
  }
  /**
   * MÉTODO TEMPORAL
   * @param req request to send
   * @param next next request step
   */
  public async validateTokenAppMobile(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Promise<HttpEvent<any>> {
    try {
      const headers = req.headers.append(
        'Authorization',
        `Bearer ${await tempGetAccessToken(this.httpClient)}`
      );
      req = req.clone({
        headers,
      });
      return await lastValueFrom(next.handle(req));
    } catch (error: any) {
      throw error;
    }
  }
  /**
   * handle validate refresh token and clone request with new access token
   * @param req request to send
   * @param next next request step
   */
  public async validateToken(
    req: HttpRequest<unknown>,
    next: HttpHandler
  ): Promise<HttpEvent<unknown>> {
    try {
      let success = false;
      if (this.profileService?.isInactive) {
        /**
         * This is needed to allow access token to expire
         */
        throw new Error(INACTIVE_ERROR);
      }
      const cloneRequest = () => {
        const headers = req.headers.append(
          'Authorization',
          `Bearer ${this.authService?.getToken('ACCESS')}`
        );
        req = req.clone({
          headers,
        });
        success = true;
      };
      if (!this.authService) {
        while (true) {
          await new Promise((resolve) => setTimeout(resolve, 10));
          if (this.authService) {
            break;
          }
        }
      }
      if (this.authService.checkToken('ACCESS')) {
        cloneRequest();
      } else if (this.authService.checkToken('REFRESH')) {
        const result = await this.authService.refreshToken();
        if (result) {
          cloneRequest();
        } else {
          return this.logout();
        }
      }
      if (success) {
        return await lastValueFrom(next.handle(req));
      } else {
        return this.logout();
      }
    } catch (error: unknown) {
      throw error;
    }
  }

  /**
   * error redirect to login and delete existing tokens
   */
  public logout() {
    if (this.profileService?.authenticatedActor) {
      this.authService?.showSessionExpired();
    }
    this.profileService?.clearProfileData();
    return lastValueFrom(new Observable<HttpEvent<unknown>>());
  }
}
