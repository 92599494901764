import { Injectable } from '@angular/core';
import { CanActivate, Router, UrlTree } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class ParticipantRegistrationPermitGuard implements CanActivate {
  constructor(private router: Router) {}
  canActivate(): boolean | UrlTree {
    if (localStorage.getItem('participantId')) {
      this.router.navigateByUrl('/register/supplementary');
      return false;
    } else {
      return true;
    }
  }
}
