import { GeneralKnownRouteValidator, NavItem } from './types-and-helpers.known.routes';

export enum FollowUpKnownItemId {
  FOLLOWUP_REFERENCES = '1',
  FOLLOWUP_PATIENTS_LIST = '2',
}

const simpleTransformer = (input: NavItem): NavItem & { type: 'followup' } => {
  return {
    ...input,
    type: 'followup',
  };
};

export const FollowUpKnownRoutes = (k: FollowUpKnownItemId): NavItem => {
  k = GeneralKnownRouteValidator(k);
  return JSON.parse(JSON.stringify(options[k]));
};

const options: {
  [k in FollowUpKnownItemId]: NavItem & { type: 'followup' };
} = {
  [FollowUpKnownItemId.FOLLOWUP_REFERENCES]: simpleTransformer({
    id: FollowUpKnownItemId.FOLLOWUP_REFERENCES,
    displayName: 'Seguimiento a referencias',
    route: 'home/follow-up',
    iconName: 'phone-forwarded',
    expanded: false,
    allowFetchWithInclude: true,
    permissions: ['patient_follow_up_general_q'],
    visibleOnSidebar: false,
  }),
  [FollowUpKnownItemId.FOLLOWUP_PATIENTS_LIST]: simpleTransformer({
    id: FollowUpKnownItemId.FOLLOWUP_PATIENTS_LIST,
    displayName: 'Listar pacientes para seguimiento',
    route: 'home/follow-up/follow-up-patients-list',
    iconName: 'recent-actors',
    parentModule: 'followUpReference',
    allowFetchWithInclude: true,
    permissions: ['patient_follow_up_general_q'],
    visibleOnSidebar: true,
  }),
};
