<div mat-dialog-content>
  <div class="flex-row-space-between">
    <div class="flex-row-start message-dialog dialog-title pl-0-5 pt-0-5 pb-0-5">
      <app-svg class="dialog-title-icon color-secondary-200" [name]="'ambulance'"></app-svg>
      <p class="text-b-14-white pr-2">{{ title }}</p>
    </div>
    <mat-icon
      data-cy="close-dialog"
      translate="no"
      class="dialog-title-icon button-close-dialog material-icons-tone"
      (click)="closeDialog('highlight_off')">
      highlight_off
    </mat-icon>
  </div>
  <div class="dialog-content-container">
    <div class="main-dialog-container">
      <div class="dialog-grid">
        <form
          class="input-form scrollbar overflow-auto"
          [formGroup]="registerManagementAddressingForm"
          autocomplete="off">
          <input type="text" class="hidden-input" #hiddenInput />
          <div class="mb-1">
            <app-autocomplete-input
              data-cy="principalDiagnosis"
              [isDisabled]="registerManagementAddressingForm.get('principalDiagnosis')!.disabled"
              [valuesToFilter]="diagnosis"
              [defaultValue]="
                findElement(
                  registerManagementAddressingForm.get('principalDiagnosis')!.value,
                  diagnosis
                )
              "
              [isInputEmpty]="registerManagementAddressingForm.get('principalDiagnosis')!.invalid"
              (sendValueSelected)="setPrincipalDiagnosis($event)"
              [wasFormTouched]="managementAddressingFormTouched"
              [showIndex]="false"
              [isInputRequired]="false"
              #diagnosticPrincipalValidation
              [inputLabel]="'Diagnóstico principal'">
              >
            </app-autocomplete-input>
          </div>
          <div class="mb-1">
            <app-autocomplete-input
              [isDisabled]="
                registerManagementAddressingForm.get('validatePrincipalDiagnosis')!.disabled
              "
              [valuesToFilter]="diagnosis"
              [defaultValue]="
                findElement(
                  registerManagementAddressingForm.get('validatePrincipalDiagnosis')!.value,
                  diagnosis
                )
              "
              [isInputEmpty]="
                registerManagementAddressingForm.get('validatePrincipalDiagnosis')!.invalid
              "
              (sendValueSelected)="setValidatePrincipalDiagnosis($event)"
              [wasFormTouched]="managementAddressingFormTouched"
              [showIndex]="false"
              [isInputRequired]="true"
              #diagnosticValidation
              [inputLabel]="'Validar diagnóstico principal'">
            </app-autocomplete-input>
          </div>
          <div class="mb-1">
            <app-autocomplete-input
              data-cy="optionalDiagnosis1"
              #optionalDiagnosticValidation1
              [isDisabled]="registerManagementAddressingForm.get('optionalDiagnosis1')!.disabled"
              [valuesToFilter]="diagnosis"
              [defaultValue]="
                findElement(
                  registerManagementAddressingForm.get('optionalDiagnosis1')!.value,
                  diagnosis
                )
              "
              [isInputEmpty]="registerManagementAddressingForm.get('optionalDiagnosis1')!.invalid"
              (sendValueSelected)="setOptionalDiagnosis1($event)"
              [wasFormTouched]="managementAddressingFormTouched"
              [showIndex]="false"
              [isInputRequired]="false"
              [inputLabel]="'Diagnóstico opcional 1'"></app-autocomplete-input>
          </div>
          <div class="mb-1">
            <app-autocomplete-input
              [isDisabled]="
                registerManagementAddressingForm.get('validateOptionalDiagnosis')!.disabled
              "
              [valuesToFilter]="diagnosis"
              [defaultValue]="
                findElement(
                  registerManagementAddressingForm.get('validateOptionalDiagnosis')!.value,
                  diagnosis
                )
              "
              [isInputEmpty]="
                registerManagementAddressingForm.get('validateOptionalDiagnosis')!.invalid
              "
              (sendValueSelected)="setValidateOptionalDiagnosis1($event)"
              [wasFormTouched]="managementAddressingFormTouched"
              [showIndex]="false"
              [isInputRequired]="false"
              [inputLabel]="'Validar diagnóstico opcional 1'">
            </app-autocomplete-input>
          </div>
          <div class="mb-1">
            <app-autocomplete-input
              data-cy="optionalDiagnosis2"
              #optionalDiagnosticValidation2
              [isDisabled]="registerManagementAddressingForm.get('optionalDiagnosis2')!.disabled"
              [valuesToFilter]="diagnosis"
              [defaultValue]="
                findElement(
                  registerManagementAddressingForm.get('optionalDiagnosis2')!.value,
                  diagnosis
                )
              "
              [isInputEmpty]="registerManagementAddressingForm.get('optionalDiagnosis2')!.invalid"
              (sendValueSelected)="setOptionalDiagnosis2($event)"
              [wasFormTouched]="managementAddressingFormTouched"
              [showIndex]="false"
              [isInputRequired]="false"
              [inputLabel]="'Diagnóstico opcional 2'"></app-autocomplete-input>
          </div>
          <div class="mb-1">
            <app-autocomplete-input
              [isDisabled]="
                registerManagementAddressingForm.get('validateOptionalDiagnosis2')!.disabled
              "
              [valuesToFilter]="diagnosis"
              [defaultValue]="
                findElement(
                  registerManagementAddressingForm.get('validateOptionalDiagnosis2')!.value,
                  diagnosis
                )
              "
              [isInputEmpty]="
                registerManagementAddressingForm.get('validateOptionalDiagnosis2')!.invalid
              "
              (sendValueSelected)="setValidateOptionalDiagnosis2($event)"
              [wasFormTouched]="managementAddressingFormTouched"
              [showIndex]="false"
              [isInputRequired]="false"
              [inputLabel]="'Validar diagnóstico opcional 2'">
            </app-autocomplete-input>
          </div>
          <div class="mb-0-5">
            <mat-form-field
              data-cy="management-indications"
              class="input-control width-100"
              appearance="outline">
              <mat-label>Indicaciones de manejo</mat-label>
              <textarea
                cdkTextareaAutosize
                cdkAutosizeMinRows="4"
                cdkAutosizeMaxRows="6"
                matInput
                class="background-transparent"
                formControlName="managementIndications"></textarea>
            </mat-form-field>
          </div>
          <div class="width-100 display-flex overflow-hidden justify-content-end">
            <div
              class="text-r-16-white pv-0-5 pr-1 radio-button-container special-radio-button-3 mb-1"
              (click)="priorityComment($event, 0)">
              <span>Comentario prioritario</span>
              <div
                data-cy="priority-comment-management-indications"
                class="radio-button-selector"
                [class]="hasPriority[0].priority ? 'radio-button-checked' : ''"></div>
            </div>
          </div>
          <div
            class="radio-options display-flex flex-row justify-content-space-between overflow-hidden mv-1"
            data-cy="transfer-required">
            <div [class]="'text-r-16-white'"> Amerita traslado</div>
            <mat-radio-group
              class="radio-group gap-2"
              formControlName="transferRequired"
              (change)="changedTransferRequired()">
              <mat-radio-button data-cy="yes-transfer-required" [value]="1">Sí</mat-radio-button>
              <mat-radio-button data-cy="no-transfer-required" [value]="2">No</mat-radio-button>
            </mat-radio-group>
          </div>
          <div
            *ngIf="
              this.registerManagementAddressingForm.get('transferRequired')?.value === 1;
              else notRequireRelocation
            ">
            <div class="mb-1">
              <app-autocomplete-input
                data-cy="destination-ips"
                [isDisabled]="registerManagementAddressingForm.get('destinationIPS')!.disabled"
                [valuesToFilter]="filteredIps"
                [defaultValue]="
                  findElement(
                    registerManagementAddressingForm.get('destinationIPS')!.value,
                    filteredIps
                  )
                "
                [isInputEmpty]="registerManagementAddressingForm.get('destinationIPS')!.invalid"
                (sendValueSelected)="setDestinationIPS($event)"
                [wasFormTouched]="managementAddressingFormTouched"
                [showIndex]="false"
                [isInputRequired]="false"
                [inputLabel]="'IPS destino'"></app-autocomplete-input>
            </div>
            <div
              *ngIf="registerManagementAddressingForm.get('destinationIPS')?.value !== ''"
              class="width-100 display-flex overflow-hidden justify-content-end">
              <div
                (click)="
                  registerManagementAddressingForm.get('destinationIPS')?.disabled
                    ? null
                    : alertIPS($event)
                "
                class="text-r-16-white pv-0-5 pr-1 radio-button-container mb-0-5 special-radio-button-2">
                <span>Alertar IPS</span>
                <div
                  data-cy="alert-ips"
                  class="radio-button-selector"
                  [class]="alertIPSActive ? 'radio-button-checked' : ''"></div>
              </div>
            </div>
            <div
              class="mb-1"
              *ngIf="registerManagementAddressingForm.get('destinationIPS')?.value !== ''">
              <mat-form-field class="input-control width-100" appearance="outline">
                <mat-label data-cy="ips-address">Dirección de la IPS</mat-label>
                <input type="text" formControlName="IPSAddress" matInput />
              </mat-form-field>
            </div>
            <div
              class="mb-1"
              *ngIf="registerManagementAddressingForm.get('destinationIPS')?.value !== ''">
              <mat-form-field class="input-control width-100" appearance="outline">
                <mat-label data-cy="ips-contact-phone">Teléfono de contacto de la IPS</mat-label>
                <input type="text" formControlName="contactPhone" matInput />
              </mat-form-field>
            </div>
            <div class="mb-1">
              <app-autocomplete-input
                data-cy="required-resource-aph"
                #requiresResource
                [isDisabled]="registerManagementAddressingForm.get('requiredResourceAPH')!.disabled"
                [valuesToFilter]="data.tabData.aphResourceType"
                [defaultValue]="
                  findElement(
                    registerManagementAddressingForm.get('requiredResourceAPH')!.value,
                    data.tabData.aphResourceType
                  )
                "
                [isInputEmpty]="
                  registerManagementAddressingForm.get('requiredResourceAPH')!.invalid
                "
                (sendValueSelected)="setResourceAphType($event)"
                [wasFormTouched]="managementAddressingFormTouched"
                [showIndex]="false"
                [isInputRequired]="true"
                [inputLabel]="'Tipo de recurso'"></app-autocomplete-input>
            </div>
          </div>
          <ng-template #notRequireRelocation>
            <div class="mb-0-5">
              <mat-form-field
                data-cy="no-transfer-reason"
                class="input-control width-100"
                appearance="outline">
                <mat-label>Razón de no mérito de traslado</mat-label>
                <textarea
                  cdkTextareaAutosize
                  cdkAutosizeMinRows="4"
                  cdkAutosizeMaxRows="6"
                  matInput
                  class="background-transparent"
                  formControlName="noTransferReason"></textarea>
              </mat-form-field>
              <div class="width-100 display-flex overflow-hidden justify-content-end">
                <div
                  class="text-r-16-white pv-0-5 pr-1 radio-button-container special-radio-button-3 mb-1"
                  (click)="priorityComment($event, 1)">
                  <span>Comentario prioritario</span>
                  <div
                    data-cy="priority-comment-no-transfer-reason"
                    class="radio-button-selector"
                    [class]="hasPriority[1].priority ? 'radio-button-checked' : ''"></div>
                </div>
              </div>
            </div>
            <div class="mb-1">
              <app-autocomplete-input
                data-cy="no-transfer-cause"
                [isDisabled]="registerManagementAddressingForm.get('noTransferCause')!.disabled"
                [valuesToFilter]="data.tabData.noTransferCauses"
                [defaultValue]="
                  findElement(
                    registerManagementAddressingForm.get('noTransferCause')!.value,
                    data.tabData.noTransferCauses
                  )
                "
                [isInputEmpty]="registerManagementAddressingForm.get('noTransferCause')!.invalid"
                (sendValueSelected)="setNoTransferCause($event)"
                [wasFormTouched]="managementAddressingFormTouched"
                [showIndex]="false"
                [isInputRequired]="false"
                [useSort]="true"
                [inputLabel]="'No mérito de traslado'"></app-autocomplete-input>
            </div>
          </ng-template>
        </form>
        <div class="width-100 distribute-incidents-edition pb-0-5">
          <button
            mat-button
            type="button"
            data-cy="generate-text-button"
            class="btn-accept min-width-100"
            *ngIf="data.patient"
            (click)="copyData(true)">
            GENERAR TEXTO
          </button>
          <button
            mat-button
            type="button"
            (click)="closeDialog()"
            data-cy="generate-text-cancel"
            class="btn-accept column-position-2 min-width-100">
            CANCELAR
          </button>
          <button
            mat-button
            type="submit"
            class="btn-accept column-position-3 min-width-100"
            (click)="saveHandlingRouting()"
            data-cy="generate-text-save">
            GUARDAR
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
