<!--Title-->
<div class="flex-row-space-between dialog-header" *ngIf="icon !== 'roles'">
  <div class="filter-touch-button"><span></span></div>
  <div class="flex-row-start message-dialog dialog-title pl-0-5 pt-0-5 pb-0-5">
    <app-svg class="dialog-title-icon color-secondary-200" [name]="titleIcon"></app-svg>
    <p
      *ngIf="titleMobileSize !== undefined; else normalTitle"
      class="text-b-14-white pr-2"
      [style.font-size.px]="titleMobileSize"
      >{{ title }}</p
    >
    <ng-template #normalTitle>
      <p class="text-b-14-white pr-2">{{ title }}</p>
    </ng-template>
  </div>
  <mat-icon
    translate="no"
    class="dialog-title-icon button-close-dialog material-icons-tone"
    *ngIf="!hideCloseDialog"
    (click)="close()"
    data-cy="dialog-close-btn">
    highlight_off
  </mat-icon>
</div>

<!--Message-->
<div class="dialog-content-container" *ngIf="icon !== 'NONE' && icon !== 'roles'" data-cy="dialog">
  <div mat-dialog-content>
    <div class="main-dialog-container flex-row-space-between" data-cy="dialog-message">
      <ng-container *ngIf="typeConfirm === 'alert'">
        <div class="icon-container message-dialog input-form">
          <app-svg [name]="icon" class="material-icons-dialog color-secondary-200"></app-svg>
        </div>
        <span class="text-r-18-white text-left message-container ml-1-5">{{ messages }}</span>
      </ng-container>
      <ng-container *ngIf="typeConfirm === 'cancel'">
        <div class="icon-container message-dialog">
          <app-svg class="material-icons-dialog color-secondary-200" [name]="icon"></app-svg>
        </div>
        <span class="text-r-18-white text-left message-container ml-1-5">{{ messages }}</span>
      </ng-container>
      <ng-container *ngIf="typeConfirm === 'password'">
        <div class="icon-container message-dialog">
          <app-svg class="material-icons-dialog color-secondary-200" [name]="icon"></app-svg>
        </div>
        <span class="text-m-16-white text-left message-container ml-1-5">{{ messages }}</span>
      </ng-container>
      <ng-container *ngIf="typeConfirm === 'info'">
        <div class="icon-container message-dialog mb-0-5">
          <app-svg class="material-icons-dialog color-secondary-200" [name]="icon"></app-svg>
        </div>
        <span class="text-r-18-white text-left message-container ml-0-5 mb-0-5">{{
          messages
        }}</span>
      </ng-container>
      <ng-container *ngIf="typeConfirm === 'info-subtitled'">
        <div class="icon-container message-dialog mb-0-5">
          <app-svg class="material-icons-dialog color-secondary-200" [name]="icon"></app-svg>
        </div>
        <div class="flex-column-center width-100 pl-2 mb-0-5">
          <span class="text-r-18-white text-left message-container pb-0-5">{{ messages }}</span>
          <span
            *ngFor="let message of subMessage"
            data-cy="message-subtitle"
            class="text-m-16-blue text-left message-container"
            >{{ message }}</span
          >
        </div>
      </ng-container>
      <ng-container *ngIf="typeConfirm === 'success'">
        <div class="icon-container icon-hide-scroll message-dialog mb-0-5">
          <mat-icon
            translate="no"
            class="material-icons-dialog material-icons-two-tone tone-green"
            >{{ icon }}</mat-icon
          >
        </div>
        <span class="text-r-18-white text-left message-container ml-1-5 mb-0-5">{{
          messages
        }}</span>
      </ng-container>
      <ng-container *ngIf="typeConfirm === 'success-icon'">
        <div class="icon-container message-dialog">
          <app-svg class="material-icons-dialog color-secondary-200" [name]="icon"></app-svg>
        </div>
        <span class="text-r-18-white text-left message-container ml-1-5">{{ messages }}</span>
      </ng-container>
      <ng-container *ngIf="typeConfirm === 'two-options'">
        <div class="icon-container message-dialog">
          <app-svg class="material-icons-dialog color-secondary-200" [name]="icon"></app-svg>
        </div>
        <span class="text-r-18-white text-left message-container ml-1-5">{{ messages }}</span>
      </ng-container>
      <ng-container *ngIf="typeConfirm === 'icon-two-colors'">
        <div class="icon-container message-dialog">
          <app-svg class="material-icons-dialog color-secondary-200" [name]="icon"></app-svg>
        </div>
        <span class="text-m-16-white text-left message-container ml-1-5">
          <span
            *ngFor="let message of twoColorsMessage"
            [class]="message === replaceValue ? 'text-m-16-blue' : ''">
            {{ message === replaceValue ? otherColorMessage : message }}
          </span>
        </span>
      </ng-container>
      <ng-container *ngIf="typeConfirm === 'icon-two-colors-contract'">
        <div class="icon-container message-dialog">
          <app-svg class="material-icons-dialog color-secondary-200" [name]="icon"></app-svg>
        </div>
        <span
          class="montserrat-medium text-montserrat-r-18-white text-left message-container ml-1-5">
          <span
            *ngFor="let message of twoColorsMessage"
            [class]="
              message === replaceValue
                ? 'text-m-16-blue'
                : 'montserrat-medium text-montserrat-r-18-white'
            ">
            {{ message === replaceValue ? otherColorMessage : message }}
          </span>
        </span>
      </ng-container>
      <ng-container *ngIf="typeConfirm === 'update-status'">
        <div class="flex-column-space-evenly mr-1 ml-1-5 width-100">
          <span
            class="text-r-18-white text-left message-container textarea-message text-ellipsis-wrap"
            >{{ messages }}</span
          >
          <ng-container>
            <textarea
              #statusUpdateDescription="cdkTextareaAutosize"
              cdkTextareaAutosize
              minlength="10"
              cdkAutosizeMinRows="{{ textareaRows }}"
              cdkAutosizeMaxRows="{{ textareaRows }}"
              data-cy="dialog-textarea"
              (keyup)="validateDescription()"
              [class]="validDescription ? 'border-color-white' : 'border-color-red'"
              [class.is-invalid]="!validDescription"
              class="text-m-14-white pv-0-5 ph-1 whitespace-preline background-transparent text-left scrollbar"
              [placeholder]="otherColorMessage"></textarea>
          </ng-container>
        </div>
      </ng-container>
      <ng-container *ngIf="typeConfirm === 'clear'">
        <div class="icon-container message-dialog mb-0-5">
          <app-svg class="material-icons-dialog color-secondary-200" [name]="icon"></app-svg>
        </div>
        <span class="text-r-18-white text-left message-container ml-1-5"
          >{{ messages }}
          <div class="text-r-18-white" *ngFor="let subM of subMessage">
            <br />
            {{ subM }}
          </div> </span
        ><br />
      </ng-container>
      <ng-container *ngIf="typeConfirm === 'viewPdf'">
        <p class="text-b-14-white">Visualizar archivo</p>
      </ng-container>
    </div>
  </div>

  <!-- Basic Actions-->
  <div mat-dialog-actions *ngIf="isActions" class="flex-row-end mr-2">
    <ng-container>
      <div class="flex-row-end overflow-unset button-container">
        <button
          *ngIf="
            typeConfirm === 'cancel' ||
            typeConfirm === 'icon-two-colors' ||
            typeConfirm === 'icon-two-colors-contract'
          "
          mat-button
          type="submit"
          class="btn-accept mr-1 min-width-75"
          (click)="cancel()">
          {{ buttonsMessageType === 'yes-no' ? 'NO' : 'CANCELAR' }}
        </button>
        <button
          mat-button
          type="submit"
          class="btn-accept"
          [class]="typeConfirm === 'password' ? 'min-width-min' : 'min-width-75'"
          (click)="onSubmit()">
          {{
            typeConfirm === 'password'
              ? 'CAMBIAR CONTRASEÑA'
              : (typeConfirm === 'cancel' ||
                    typeConfirm === 'icon-two-colors' ||
                    typeConfirm === 'icon-two-colors-contract') &&
                  buttonsMessageType === 'yes-no'
                ? 'SI'
                : 'ACEPTAR'
          }}
        </button>
      </div>
    </ng-container>
  </div>

  <!--  Two Actions-->
  <!--  This section is not used in the project -->
  <div mat-dialog-actions *ngIf="isTwoActions" class="flex-row-end mr-2">
    <ng-container>
      <div class="flex-row-space-between overflow-unset button-container mr-1">
        <button mat-button type="submit" class="btn-accept" (click)="onSubmit()">
          {{ typeConfirm === 'password' ? 'CAMBIAR CONTRASEÑA' : 'CANCELAR' }}
        </button>
        <button mat-button type="submit" class="btn-accept" (click)="onSubmit()">
          {{ typeConfirm === 'password' ? 'CAMBIAR CONTRASEÑA' : 'ACEPTAR' }}
        </button>
      </div>
    </ng-container>
  </div>

  <!-- Three actions -->
  <div mat-dialog-actions *ngIf="isThirdAction">
    <ng-container>
      <div class="flex-row-end width-100 padh-perc-5 overflow-hidden">
        <button mat-button type="submit" class="btn-accept" autofocus (click)="cancel()">
          CANCELAR
        </button>
        <button
          mat-button
          type="submit"
          class="btn-accept ml-2"
          autofocus
          (click)="onSubmit(description.nativeElement.value)">
          GUARDAR
        </button>
      </div>
    </ng-container>
  </div>

  <!--  Two Options Buttons-->
  <div mat-dialog-actions *ngIf="typeConfirm === 'two-options' && isButtons">
    <ng-container>
      <div class="flex-row-end width-100 padh-perc-5 button-container overflow-hidden">
        <button
          mat-button
          type="submit"
          class="btn-accept min-width-30"
          autofocus
          (click)="cancel()">
          {{ twoColorsMessage[1] }}
        </button>
        <button
          mat-button
          type="submit"
          class="btn-accept min-width-30 ml-2"
          autofocus
          (click)="onSubmit()">
          {{ twoColorsMessage[0] }}
        </button>
      </div>
    </ng-container>
  </div>
</div>

<!--No icon Dialog-->
<div class="dialog-content-container" *ngIf="icon === 'NONE'">
  <div mat-dialog-content>
    <div class="scrollable-container">
      <div class="main-dialog-container flex-row-space-between">
        <ng-container *ngIf="typeConfirm === 'terms'">
          <span class="text-r-18-white whitespace-preline terms-policy-message text-left ma-1-5">{{
            messages
          }}</span>
        </ng-container>
        <ng-container *ngIf="typeConfirm === 'policy'">
          <span class="text-r-18-white whitespace-preline terms-policy-message text-left ma-1-5">{{
            messages
          }}</span>
        </ng-container>
      </div>
    </div>
  </div>
  <div
    mat-dialog-actions
    *ngIf="isActions"
    class="display-flex flex-row align-items-center justify-content-end overflow-unset mr-2">
    <ng-container>
      <div class="display-flex overflow-unset button-container">
        <button
          mat-button
          type="submit"
          class="btn-accept min-width-75 ma-0"
          autofocus
          (click)="onSubmit()">
          ACEPTAR
        </button>
      </div>
    </ng-container>
  </div>

  <!--  This section is not used in the project -->
  <div mat-dialog-actions *ngIf="isTwoActions" class="flex-row-end mr-2">
    <ng-container>
      <div class="flex-row-space-between mr-1">
        <button
          mat-button
          type="submit"
          class="btn-accept min-width-75"
          autofocus
          (click)="onSubmit()">
          CANCELAR
        </button>
        <button
          mat-button
          type="submit"
          class="btn-accept min-width-75"
          autofocus
          (click)="onSubmit()">
          GUARDAR
        </button>
      </div>
    </ng-container>
  </div>
</div>

<!--Roles Dialog-->
<div class="overflow-auto width-100 height-100 scrollbar" *ngIf="icon === 'roles'">
  <div class="position-relative roles-dialog-size" data-cy="roles-dialog">
    <app-svg
      [name]="'cancel'"
      class="color-secondary-200 close-icon"
      *ngIf="profileService.selectedRole?.id"
      (click)="cancelSelectRole()"></app-svg>
    <div class="flex-row-space-between">
      <div class="flex-row-start message-dialog pt-0-5 pb-1-5">
        <p class="sub-title-white">{{ title }}</p>
      </div>
    </div>
    <div mat-dialog-content class="roles-container position-relative">
      <div class="main-dialog-container grid-space-between">
        <div
          *ngFor="let role of roles"
          class="position-relative"
          [class]="
            role.name === KnownRoles.DELIVERY ? 'flex-role' : 'grid-center container-dimensions'
          "
          (click)="selectRole(role)">
          <div class="svg-icon-container" *ngIf="role.name !== KnownRoles.DELIVERY">
            <app-svg class="color-secondary-200" [name]="role.attributes.icon?.[0] ?? ''"></app-svg>
          </div>
          <div
            class="svg-icon-container position-absolute"
            *ngIf="role.name === KnownRoles.DELIVERY">
            <app-svg class="color-secondary-200" [name]="role.attributes.icon?.[0] ?? ''"></app-svg>
            <!--<div class="zone-field" *ngIf="isSelectingZone">
              <div class="zone-button" (click)="selectRole(role, 1)"
                ><span class="medium-text-12">ZONA NORTE</span>
              </div>
              <div class="zone-button" (click)="selectRole(role, 2)"
                ><span class="medium-text-12">ZONA SUR</span>
              </div>
            </div>-->
          </div>
          <div
            class="flex-column-center text-center text-m-18-white role-title dispatch-title"
            *ngIf="role.name !== KnownRoles.RADIO_COMM"
            [class]="role.name === KnownRoles.DELIVERY ? 'margin-delivery' : ''"
            >{{ role.attributes.title[0] ?? '' }}</div
          >
          <div
            class="flex-column-center text-center text-m-18-white role-title dispatch-title line-break-anywhere"
            *ngIf="role.name === KnownRoles.RADIO_COMM"
            >{{ role.attributes.title[0] ?? '' }}</div
          >
        </div>
      </div>
    </div>
  </div>
</div>
