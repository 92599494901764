<div class="main-wrapper">
  <router-outlet></router-outlet>
  <ngx-loading
    [show]="loadingService.loading"
    [config]="{
      animationType: loadingService.ngxLoadingAnimationTypes.circleSwish,
      primaryColour: this.loadingService.loadingColor,
      backdropBorderRadius: '3px'
    }"></ngx-loading>
</div>

<div
  matTooltip="Refresca los permisos actuales del usuario"
  (click)="temp()"
  *ngIf="showIcon"
  class="tempIcon">
  <mat-icon>refresh</mat-icon>
</div>
