import { CachedCRUDService } from '../../types/cached-c-r-u-d.service';
import { HttpClient, HttpParams } from '@angular/common/http';
import { lastValueFrom } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class RoleService extends CachedCRUDService<Role, CreateRoleInput, EditRoleInput, string> {
  constructor(private http: HttpClient) {
    super();
  }

  protected override async _fetchNewData(): Promise<Role[]> {
    const params = new HttpParams().set('briefRepresentation', false);
    const data = await lastValueFrom(
      this.http.get<Role[]>(
        `${environment.authService}/admin/realms/${environment.keycloakRealm}/groups`,
        { params }
      )
    );
    for (const role of data) {
      if (!role.attributes?.title?.length) {
        console.error(`Role '${role.name}' does not have title, skipping`);
      }
      if (!role.attributes?.icon?.length) {
        console.error(`Role '${role.name}' does not have icon, setting default icon`);
        role.attributes.icon = ['person'];
      }
      if (!role.attributes?.colorClass?.length) {
        role.attributes.colorClass = ['N/A'];
      }
    }
    return data.filter((value) => value.attributes?.title?.length);
  }

  protected override async _getByIdData(id: string): Promise<Role> {
    const params = new HttpParams().set('briefRepresentation', false);
    return await lastValueFrom(
      this.http.get<Role>(
        `${environment.authService}/admin/realms/${environment.keycloakRealm}/groups/${id}`,
        { params }
      )
    );
  }
  async searchByName(name: string) {
    const params = new HttpParams().set('briefRepresentation', false).set('search', name);
    return await lastValueFrom(
      this.http.get<Role[]>(
        `${environment.authService}/admin/realms/${environment.keycloakRealm}/groups`,
        { params }
      )
    );
  }

  async getComposite(id: string): Promise<InheritPermission[]> {
    return lastValueFrom(
      this.http.get<InheritPermission[]>(
        `${environment.authService}/admin/realms/${environment.keycloakRealm}/groups/${id}/role-mappings/realm/composite`
      )
    );
  }

  protected override async _putData(id: string, body: EditRoleInput): Promise<Role> {
    return lastValueFrom(
      this.http.put<Role>(
        `${environment.authService}/admin/realms/${environment.keycloakRealm}/groups/${id}`,
        body
      )
    );
  }

  protected override async _postData(body: CreateRoleInput): Promise<Role> {
    return await lastValueFrom(
      this.http.post<Role>(
        `${environment.authService}/admin/realms/${environment.keycloakRealm}/groups`,
        body
      )
    );
  }

  async assignPermissions(id: string, body: AssignRolePermissionInput[]) {
    await lastValueFrom(
      this.http.post(
        `${environment.authService}/admin/realms/${environment.keycloakRealm}/groups/${id}/role-mappings/realm`,
        body
      )
    );
    this.flushCache();
    await this.warm();
  }

  async removePermissions(id: string, body: DeleteRolePermissionInput[]) {
    await lastValueFrom(
      this.http.delete(
        `${environment.authService}/admin/realms/${environment.keycloakRealm}/groups/${id}/role-mappings/realm/`,
        { body }
      )
    );
    this.flushCache();
    await this.warm();
  }

  /**
   *
   * @param userId assign keycloakUserName field from operator
   */
  public async getProfileRoles(userId: string) {
    const params = new HttpParams().set('briefRepresentation', false);
    return await lastValueFrom(
      this.http.get<Role[]>(
        `${environment.authService}/admin/realms/${environment.keycloakRealm}/users/${userId}/groups`,
        { params }
      )
    );
  }

  public async getUserListPerRol(id: string) {
    return await lastValueFrom(
      this.http.get<Array<object>>(
        `${environment.authService}/admin/realms/sisem/groups/${id}/members`
      )
    );
  }
}

export interface Role {
  id: string;
  name: string;
  realmRoles?: Array<string>;
  attributes: {
    title: string[];
    description?: string[];
    type?: string[];
    icon: string[];
    entity?: string[];
    colorClass: string[];
    isHidden?: string[];
  };
}

export interface InheritPermission {
  id: string;
  name: string;
  description: string;
  composite: boolean;
  clientRole: boolean;
  containerId: string;
}

export interface CreateRoleInput {
  name: string;
  attributes: {
    title: Array<string>;
    type: Array<string>;
    description: Array<string>;
    icon?: Array<string>;
  };
}

export interface EditRoleInput {
  name: string;
  attributes: {
    title: Array<string>;
    type: Array<string>;
  };
}

export interface AssignRolePermissionInput {
  id: string;
  name: string;
  composite: boolean;
  clientRole: boolean;
  containerId: string;
}

export type DeleteRolePermissionInput = AssignRolePermissionInput;
