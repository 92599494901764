<div mat-dialog-content>
  <div class="flex-row-space-between">
    <div class="flex-row-start message-dialog dialog-title pl-0-5 pt-0-5 pb-0-5">
      <app-svg class="dialog-title-icon color-secondary-200" [name]="titleIcon"></app-svg>
      <p class="text-b-14-white pr-2">{{ title }}</p>
    </div>
    <mat-icon
      translate="no"
      class="dialog-title-icon button-close-dialog material-icons-tone"
      (click)="cancelEdition(type, true)">
      highlight_off
    </mat-icon>
  </div>
  <div class="dialog-content-container">
    <div class="main-dialog-container">
      <div *ngIf="type === 'caller'" class="width-100">
        <form class="input-form" [formGroup]="callerForm" autocomplete="off">
          <div class="width-100 display-flex overflow-hidden justify-content-end">
            <div
              class="text-r-16-white pv-0-5 pr-1 radio-button-container mb-1 special-radio-button-1"
              (click)="setAnonymous($event)"
              [class.disabled-radio-btn]="data.disabled">
              <span>Anónimo</span>
              <div
                class="radio-button-selector"
                [class]="isAnonymous ? 'radio-button-checked' : ''"></div>
            </div>
          </div>
          <div class="mb-0-5">
            <mat-form-field class="input-control width-100" appearance="outline">
              <mat-label>Primer nombre</mat-label>
              <input type="text" matInput trim formControlName="callerFirstName" />
            </mat-form-field>
          </div>
          <div class="mb-0-5">
            <mat-form-field class="input-control width-100" appearance="outline">
              <mat-label>Segundo nombre</mat-label>
              <input type="text" matInput trim formControlName="callerSecondName" />
            </mat-form-field>
          </div>
          <div class="mb-0-5">
            <mat-form-field class="input-control width-100" appearance="outline">
              <mat-label>Primer apellido</mat-label>
              <input type="text" matInput trim formControlName="callerLastName" />
            </mat-form-field>
          </div>
          <div class="mb-0-5">
            <mat-form-field class="input-control width-100" appearance="outline">
              <mat-label>Segundo apellido</mat-label>
              <input type="text" matInput trim formControlName="callerSecondLastName" />
            </mat-form-field>
          </div>
          <div
            *ngIf="isMentalHealth"
            class="radio-options three-radio-options display-flex flex-row justify-content-space-between overflow-hidden mv-0-5">
            <div class="text-r-16-white">Relación con el paciente</div>
            <mat-radio-group class="radio-group gap-2" formControlName="relationshipWithPatient">
              <mat-radio-button [value]="1">Otro</mat-radio-button>
              <mat-radio-button [value]="2">Vecino</mat-radio-button>
              <mat-radio-button [value]="3">Familiar</mat-radio-button>
            </mat-radio-group>
          </div>
          <div class="table-with-input-container mb-0-5">
            <div class="input-with-button">
              <mat-form-field class="input-control width-100" appearance="outline">
                <mat-label>Teléfonos del llamante</mat-label>
                <input
                  type="text"
                  matInput
                  maxlength="10"
                  formControlName="callerPhoneNumber"
                  (paste)="validatorsService.numberOnly($event)"
                  (keydown)="validatorsService.numberOnly($event)"
                  (change)="validateNumber('callerPhoneNumber')"
                  integerNumber />
              </mat-form-field>
              <button
                mat-button
                type="button"
                class="min-width-100 justify-self-end"
                (click)="addPhoneNumber()"
                [class]="data.disabled ? 'btn-disabled' : 'btn-accept'">
                AGREGAR
              </button>
            </div>
            <div class="display-flex flex-row gap-1">
              <div *ngFor="let i of addedPhones; let ind = index" class="phone-chip">
                <div class="text-r-14-white"> {{ i }} </div>
                <mat-icon
                  aria-hidden="false"
                  aria-label="Close tab"
                  class="close-chip"
                  (click)="deletePhone(ind)">
                  close
                </mat-icon>
              </div>
            </div>
          </div>
          <div class="mb-0-5">
            <mat-form-field class="input-control width-100" appearance="outline">
              <mat-label>Observaciones de creación</mat-label>
              <textarea
                class="background-transparent"
                cdkTextareaAutosize
                cdkAutosizeMinRows="4"
                cdkAutosizeMaxRows="4"
                matInput
                trim
                formControlName="creationComments"></textarea>
            </mat-form-field>
          </div>
          <div class="width-100 display-flex overflow-hidden justify-content-end">
            <div
              class="text-r-16-white pv-0-5 pr-1 radio-button-container mb-0-5 special-radio-button-2"
              (click)="priorityComment($event)"
              [class.disabled-radio-btn]="data.disabled">
              <span>Comentario prioritario</span>
              <div
                class="radio-button-selector"
                [class]="hasPriority ? 'radio-button-checked' : ''"></div>
            </div>
          </div>
          <div class="width-100 distribute-incidents-edition pb-0-5">
            <div *ngIf="!callerIsUpdated"></div>
            <button
              mat-button
              type="button"
              (click)="generateText(type)"
              class="min-width-100"
              [class]="data.disabled ? 'btn-disabled' : 'btn-accept'"
              *ngIf="callerIsUpdated">
              GENERAR TEXTO
            </button>
            <button
              mat-button
              type="button"
              (click)="cancelEdition(type)"
              class="column-position-2 min-width-100"
              [class]="data.disabled ? 'btn-disabled' : 'btn-accept'">
              CANCELAR
            </button>
            <button
              mat-button
              type="submit"
              class="column-position-3 min-width-100"
              [class]="data.disabled ? 'btn-disabled' : 'btn-accept'"
              (click)="submitEdition(type)">
              GUARDAR
            </button>
          </div>
        </form>
      </div>
      <div *ngIf="type === 'distribution'" class="width-100">
        <div
          class="display-flex flex-row justify-content-space-between overflow-hidden input-form mv-1">
          <div class="text-r-16-white mb-1">Distribuir incidente a</div>
          <mat-checkbox
            *ngIf="currentRole !== KnownRoles.MENTAL"
            [disabled]="distribution[0].disabled"
            [checked]="distribution[0].state"
            [class]="!distribution[0].state && distributionError ? 'checked-error' : ''"
            (change)="changeDistribution(0)">
            Salud Mental
          </mat-checkbox>
          <mat-checkbox
            *ngIf="currentRole !== KnownRoles.DELIVERY"
            [disabled]="distribution[1].disabled"
            [checked]="distribution[1].state"
            [class]="!distribution[1].state && distributionError ? 'checked-error' : ''"
            (change)="changeDistribution(1)">
            Despacho
          </mat-checkbox>
          <mat-checkbox
            *ngIf="currentRole !== KnownRoles.TARM"
            [disabled]="distribution[2].disabled"
            [checked]="distribution[2].state"
            [class]="!distribution[2].state && distributionError ? 'checked-error' : ''"
            (change)="changeDistribution(2)"
            data-cy="distribution-tarm-checkbox">
            TARM
          </mat-checkbox>
        </div>
        <div class="width-100 distribute-incidents-edition pb-0-5">
          <button
            mat-button
            type="button"
            (click)="generateText(type)"
            [class]="hasSaved ? 'btn-disabled min-width-100' : 'btn-accept min-width-100'">
            GENERAR TEXTO
          </button>
          <button
            mat-button
            type="button"
            (click)="cancelEdition(type)"
            class="column-position-2 min-width-100"
            [class]="data.disabled ? 'btn-disabled' : 'btn-accept'">
            CANCELAR
          </button>
          <button
            mat-button
            type="submit"
            class="column-position-3 min-width-100"
            [class]="data.disabled ? 'btn-disabled' : 'btn-accept'"
            (click)="submitEdition(type)">
            GUARDAR
          </button>
        </div>
      </div>
      <div *ngIf="type === 'location'" class="width-100">
        <app-select-map-location
          *ngIf="this.showBigMap"
          [initialAddress]="selectMapLocationComponent.address"
          (buttonEvent)="onMapClose($event)"></app-select-map-location>
        <form class="input-form" [formGroup]="locationForm" autocomplete="off">
          <div [ngClass]="{ hidden: this.showBigMap }">
            <div class="location-field">
              <div class="width-100 overflow-hidden">
                <div class="mb-1">
                  <app-places-autocomplete
                    (blurInput)="placesAutocompleteService.hideAutocomplete()"
                    (getPlacePredictions)="
                      placesAutocompleteService.getCustomPlacePredictions($event)
                    "
                    (selectPlace)="placesAutocompleteService.selectPlaceAutoCompleteItem($event)"
                    [form]="locationForm"
                    [predictions]="placesAutocompleteService.predictions"
                    [showAutocomplete]="placesAutocompleteService.showAutocomplete"
                    controlName="incidentAddress"></app-places-autocomplete>
                </div>
                <div class="mb-1">
                  <mat-form-field class="input-control width-100" appearance="outline">
                    <mat-label>Complemento de dirección</mat-label>
                    <input type="text" matInput trim formControlName="addressComplement" />
                  </mat-form-field>
                </div>
                <div class="mb-1">
                  <mat-form-field class="input-control width-100" appearance="outline">
                    <mat-label>Barrio</mat-label>
                    <input type="text" matInput trim formControlName="neighborhood" />
                  </mat-form-field>
                </div>
                <div #locality>
                  <app-autocomplete-input
                    [isDisabled]="
                      data.disabled || !saveCanEdit || locationForm.get('locality')!.disabled
                    "
                    [valuesToFilter]="locations"
                    [defaultValue]="findElement(locationForm.get('locality')!.value, locations)"
                    [isInputEmpty]="locationForm.get('locality')!.invalid"
                    (sendValueSelected)="setLocality($event)"
                    [wasFormTouched]="locationFormTouched"
                    [showIndex]="false"
                    [isInputRequired]="true"
                    [inputLabel]="'Localidad'"></app-autocomplete-input>
                </div>
              </div>
              <div class="width-70">
                <div class="height-92">
                  <app-select-map-location
                    #selectMapLocationComponent
                    (updateAddress)="onMapUpdate($event)"
                    [mapOnly]="true"></app-select-map-location>
                </div>
                <span
                  class="text-m-14-white view-map cursor-pointer"
                  (click)="clickShowAddressModal()"
                  >Ampliar el mapa</span
                >
              </div>
            </div>
            <div
              class="radio-options display-flex flex-row justify-content-space-between overflow-hidden mv-1">
              <div class="text-r-16-white">Zonificación</div>
              <mat-radio-group class="radio-group gap-2" formControlName="zone">
                <mat-radio-button [value]="1">Norte</mat-radio-button>
                <mat-radio-button [value]="2">Sur</mat-radio-button>
              </mat-radio-group>
            </div>
            <div
              class="radio-options display-flex flex-row justify-content-space-between overflow-hidden mv-1">
              <div class="text-r-16-white">Entorno</div>
              <mat-radio-group class="radio-group gap-2" formControlName="zonesDh">
                <mat-radio-button [value]="'rural'">Rural</mat-radio-button>
                <mat-radio-button [value]="'urban'">Urbana</mat-radio-button>
              </mat-radio-group>
            </div>
            <div class="mb-1">
              <app-autocomplete-input
                [isDisabled]="data.disabled || !saveCanEdit"
                [valuesToFilter]="attention"
                [defaultValue]="findElement(locationForm.get('attentionPlace')!.value, attention)"
                [isInputEmpty]="locationForm.get('attentionPlace')!.invalid"
                (sendValueSelected)="setAttentionPlace($event)"
                [wasFormTouched]="locationFormTouched"
                [showIndex]="false"
                [isInputRequired]="false"
                [inputLabel]="'Atención en'">
              </app-autocomplete-input>
            </div>
            <div class="mb-1">
              <mat-form-field class="input-control width-100" appearance="outline">
                <mat-label>Punto de referencia</mat-label>
                <input type="text" matInput trim formControlName="referencePoint" #referencePoint />
              </mat-form-field>
            </div>
            <div class="width-100 distribute-incidents-edition pb-0-5">
              <div *ngIf="!locationIsUpdated"></div>
              <button
                mat-button
                type="button"
                (click)="generateText(type)"
                class="min-width-100"
                [class]="data.disabled ? 'btn-disabled' : 'btn-accept'"
                *ngIf="locationIsUpdated">
                GENERAR TEXTO
              </button>

              <button
                mat-button
                type="button"
                (click)="cancelEdition(type)"
                class="column-position-2 min-width-100"
                [class]="data.disabled ? 'btn-disabled' : 'btn-accept'">
                CANCELAR
              </button>
              <button
                mat-button
                type="submit"
                class="column-position-3 min-width-100"
                [class]="data.disabled || !saveCanEdit ? 'btn-disabled' : 'btn-accept'"
                (click)="submitEdition(type)">
                GUARDAR
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
