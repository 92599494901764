import { Component } from '@angular/core';
import { Clipboard } from '@angular/cdk/clipboard';
import { AlertService } from '../../services/alert.service';

@Component({
  selector: 'app-icons',
  templateUrl: './icons.component.html',
  styleUrls: ['./icons.component.scss'],
})
export class IconsComponent {
  icons: string[] = [
    /* A */
    'academic-cap',
    'account-box',
    'account-circle',
    'add-circle',
    'agencies',
    'alert',
    'ambulance',
    'ambulance-bubble',
    'amed',
    'amed-women',
    'aph',
    'assessment',
    'aph-provider',
    'assignment',
    'assistant-direction',
    'accessible',
    /* B */
    'back-hand',
    'base',
    'bases',
    'burger-menu',
    'bus',
    'broken-ambulance',
    'broken-ips',
    'broken-provider',
    /* C */
    'calendar',
    'calendar-2',
    'call',
    'camera',
    'cancel',
    'card',
    'check',
    'check-circle',
    'cellphone',
    'chronometer',
    'city-location',
    'close-incident',
    'computer',
    'compass',
    'consecutive',
    'consult',
    'consult-layer',
    'consult-news',
    'contracts',
    'corporate_fare',
    'corporate',
    'copy-data',
    'cups-codes',
    'custom-layer',
    'car-repair',
    /* D */
    'default-icon',
    'delete',
    'delete-forever',
    'description',
    'disaggregate',
    'distribute-incident',
    'doctor',
    'download',
    'download2',
    'drag',
    'diversity_2',
    'directions-car',
    'driver',
    /* E */
    'edit-outline',
    'edit-black',
    'email-filled',
    'expand-less',
    'expand-more',
    'expand-more-fill',
    'edit_location',
    /* F */
    'file-open',
    'filter',
    'final-conduct',
    /* G */
    'greater-than-24',
    'gender',
    'geolocation',
    'govco',
    'group-dots',
    'gps-arrow',
    'groups',
    /* H */
    'heat',
    'help',
    'help-circle',
    'history',
    'home',
    'hospital',
    'hospital-care',
    'human',
    /* I */
    'incident-active',
    'incident-close',
    'incident-group',
    'incident-initial',
    'incident-list',
    'incident-pendent',
    'indicators',
    'incident-table',
    'info',
    'info-opacity',
    'info-message',
    'inventory-black',
    'ips',
    'inventory-resource',
    'inventory-register',
    /* J */
    /* K */
    'key',
    /* L */
    'laptop',
    'layers',
    'list',
    'local-hospital',
    'local-library',
    'location',
    'layers-administration',
    'lock',
    'lock-clock',
    'log-out',
    'logo-sisem',
    'local-gas-station',
    /* M */
    'map-black',
    'mail-outline',
    'manage-accounts',
    'map',
    'mark-email-read',
    'masks',
    'medical-audit',
    'medical-dict',
    'medical-regulation',
    'medical-service',
    'minus',
    'monitor-heart',
    'more-options',
    /* N */
    'neighborhoods',
    'news',
    'news-small',
    'no-results',
    'note-add',
    'notifications',
    'nurse-svg',
    /* O */
    'orientation-sexual',
    /* P */
    'pathologies',
    'parametrics',
    'pause',
    'pdf',
    'pending',
    'people',
    'person',
    'personal-data',
    'personal-injury',
    'phone-forwarded',
    'pill',
    'pin',
    'pin-location',
    'play',
    'providers',
    'psychology',
    'person-search',
    'person-add-black',
    'public_black',
    /* Q */
    'qr-code',
    'quiz',
    /* R */
    'radio-com',
    'recent-actors',
    'redo',
    'resource',
    'rin',
    /* S */
    'search',
    'send',
    'settings',
    'settings-applications',
    'sites',
    'social-distance',
    'space-dashboard',
    'stretcher',
    'sync',
    'services',
    'speed',
    'school-black',
    'soat',
    'supervised_user',
    /* T */
    'tab',
    'tam',
    'task',
    'tariff-groups',
    'terms',
    'time',
    'toggle-off',
    'tracking',
    'transmilenio',
    'transmilenio-rail',
    'trasfer-station',
    /* U */
    'undo',
    'update',
    'updateStatus',
    'upload-file',
    'user-data',
    'user-outline',
    /* V */
    'validate-vehicle',
    'visibility',
    'visibility-off',
    'view',
    /* W */
    'warehouse',
    'warning',
    'warning-color',
    'warning-black',
    'warning-black-opacity',
    'work-bag',
    'without-transfer',
    /* X */
    'xls',
    /* Y */
    /* Z */
    'zoom-in',
    'zoom-out',
    'zoom-reset',
  ];

  constructor(
    private clipboard: Clipboard,
    private alertService: AlertService
  ) {}

  copyToClipboard(iconName: string) {
    this.alertService.showInformation('Copiado al portapapeles');
    this.clipboard.copy(iconName);
  }
}
