import { ChangeDetectorRef, Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { DialogComponent } from '../../../../../../shared/component/dialog/dialog.component';

@Component({
  selector: 'app-add-novelty-type-modal',
  templateUrl: './add-novelty-report-modal.component.html',
  styleUrls: ['./add-novelty-report-modal.component.scss'],
})
export class AddNoveltyReportModalComponent {
  constructor(
    public dialogRef: MatDialogRef<DialogComponent>,
    private changeDetectorRef: ChangeDetectorRef
  ) {}

  closeDialog(result: 'success' | 'cancel'): void {
    this.dialogRef.close(result);
    this.forceRefresh();
  }

  private forceRefresh() {
    this.changeDetectorRef.detectChanges();
    setTimeout(() => {
      this.changeDetectorRef.detectChanges();
    }, 1);
  }
}
