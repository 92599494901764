import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Router } from '@angular/router';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { lastValueFrom } from 'rxjs';
import {
  ParametricService,
  TabActionType,
  TabData,
} from '../../../types/parametric.service.abstract.class';
import { KeyPressedHandlerService } from '../../key-pressed-handler.service';
import { ProfileService } from '../../profile/profile.service';
import { CachedCRUDService } from '../../../types/cached-c-r-u-d.service';
import { environment } from '../../../../../environments/environment';
import { ServerResponse } from '../../../types/http.interfaces';
import { APHProvider, APHProviderCreateInput } from '@smartsoft-types/sisem-resources';

export interface ContactData {
  id?: number;
  jobTitle: string;
  email: string;
  name: string;
  phone: string;
  radioState: boolean;
}

export interface ProviderTabData {
  step1FormTouched: boolean;
  step1Form: FormGroup;
  selectedProvider?: APHProvider;
  selectedService: boolean;
  isEditing: boolean;
  contactData: ContactData[];
}

class ProviderServiceTabManager extends ParametricService<ProviderTabData, unknown> {
  constructor(
    private http: HttpClient,
    private router: Router,
    keypressHandlerService: KeyPressedHandlerService,
    public override profileService: ProfileService,
    private providerService: ProviderService
  ) {
    super(keypressHandlerService, profileService);
    super.newTabAction = {
      icon: '',
      toolTip: 'Agregar Prestador',
    };
  }

  /**
   *
   * @param actionType action to be performed register or edit
   * @param options id record
   */
  override async newTab(actionType: TabActionType, options?: OpenProviderTabOptions) {
    let text = '';
    // validate if already open
    if (options?.id) {
      const result = this.tabs.find((value) => value.tabData.selectedProvider?.id === options.id);
      if (result) {
        this.selectTab(result.id);
        return;
      }
    }
    //
    const resource = options?.id ? await this.providerService.getById(options.id) : undefined;
    const tabId = ++this._tabIdCounter;
    if (actionType === TabActionType.CREATE) {
      text = `NUEVO PRESTADOR (${tabId})`;
    } else if (actionType === TabActionType.EDIT) {
      text = 'EDITAR PRESTADOR ' + resource?.title;
    }
    const tabData: TabData<ProviderTabData> = {
      id: tabId,
      edition: false,
      createdDate: [],
      creationInfoExpanded: false,
      createdBy: '',
      createdByIp: '',
      tabData: {
        isEditing: false,
        step1FormTouched: false,
        step1Form: new FormGroup({}),
        selectedProvider: resource,
        selectedService: false,
        contactData: [],
      },
      actionType: actionType,
      title: text,
      keysSubscriptionName: '',
    };
    this.generateForms(tabData);
    this._tabs.push(tabData);
    this.selectTab(tabData.id);
  }

  override isSafeToCloseTab(id: number): boolean {
    const tab = this.getTabData(id);
    if (tab) {
      // validate if is safe to close
      if (tab.tabData.step1Form.dirty) {
        return false;
      }
    }
    return true;
  }

  /**
   *
   * @param id
   */
  override selectTab(id: number) {
    super.selectTab(id);
    this.router
      .navigateByUrl('/home/administration/providers/', {
        skipLocationChange: true,
      })
      .then(() => {
        if (this._selectedTab?.actionType === TabActionType.CREATE) {
          this.router.navigateByUrl('/home/administration/providers/create/' + id);
        } else if (this._selectedTab?.actionType === TabActionType.EDIT) {
          this.router.navigateByUrl(
            '/home/administration/providers/edit/' + this._selectedTab.tabData.selectedProvider?.id
          );
        }
      });
  }

  /**
   * GENERATES ALL FORMS
   **/
  private generateForms(tabData: TabData<ProviderTabData>) {
    tabData.tabData.step1Form = new FormGroup({
      enableCode: new FormControl('', [Validators.required, Validators.minLength(10)]),
      companyType: new FormControl('', [Validators.required]),
      providerName: new FormControl('', [Validators.required]),
      nit: new FormControl('', [Validators.required, Validators.minLength(9)]),
      businessName: new FormControl('', [Validators.required]),
      taxRegime: new FormControl('', [Validators.required]),
      providerClass: new FormControl('', [Validators.required]),
      legalRepresentative: new FormControl('', [Validators.required]),
      headOfficeCode: new FormControl('', [Validators.required]),
      department: new FormControl('', [Validators.required]),
      city: new FormControl('', [Validators.required]),
      address: new FormControl('', [Validators.required]),
    });
    tabData.tabData.selectedService = false;
  }

  override getTabData(id: number, resourceId?: number): TabData<ProviderTabData> {
    if (resourceId) {
      return (
        this._tabs.find((value) => value.tabData.selectedProvider?.id === resourceId) ??
        this._tabs[0]
      );
    }
    return this._tabs.find((value) => value.id === id) ?? this._tabs[0];
  }
}

class OpenProviderTabOptions {
  id: number;
  icon?: string;
}

@Injectable({
  providedIn: 'root',
})
export class ProviderService extends CachedCRUDService<
  APHProvider,
  APHProviderCreateInput['data'],
  APHProviderCreateInput['data']
> {
  tabs: ProviderServiceTabManager;

  _providersURL = environment.api + '/resources-ms/api/v1/parametrics/aphProvider';

  constructor(
    private http: HttpClient,
    private router: Router,
    keypressHandlerService: KeyPressedHandlerService,
    private profileService: ProfileService
  ) {
    super();
    this.tabs = new ProviderServiceTabManager(
      http,
      router,
      keypressHandlerService,
      profileService,
      this
    );
  }

  protected override async _fetchNewData(): Promise<APHProvider[]> {
    const params = new HttpParams()
      .set('fetchProviders', true)
      .set('fetchContactData', true)
      .set('fetchProviderType', true);
    const res: ServerResponse<APHProvider[]> = await lastValueFrom(
      this.http.get<ServerResponse<APHProvider[]>>(this._providersURL, {
        params,
      })
    );
    return res.data;
  }

  protected override async _postData(body: APHProviderCreateInput['data']) {
    const res: ServerResponse<APHProvider> = await lastValueFrom(
      this.http.post<ServerResponse<APHProvider>>(this._providersURL, {
        data: body,
      })
    );
    const fixedRes = await this.getById(res.data.id, true);
    if (!fixedRes) {
      throw new Error('Could not fetch updated data, strange case, this should not happen');
    }
    this.removeId(res.data.id);
    return fixedRes;
  }

  async updateProviderState(id: number, data: Record<string, unknown>) {
    await lastValueFrom(
      this.http.post<ServerResponse<APHProvider>>(`${this._providersURL}s/${id}/changeStatus`, data)
    );
  }

  protected override async _getByIdData(id: number): Promise<APHProvider | undefined> {
    const params = new HttpParams().set('fetchProviderType', true).set('fetchContactData', true);
    const res: ServerResponse<APHProvider> = await lastValueFrom(
      this.http.get<ServerResponse<APHProvider>>(`${this._providersURL}/${id}`, { params })
    );
    return res.data;
  }

  protected override async _putData(id: number, body: APHProviderCreateInput['data']) {
    await lastValueFrom(
      this.http.patch<ServerResponse<APHProvider>>(`${this._providersURL}/${id}`, {
        data: body,
      })
    );
    const fixedRes = await this.getById(id, true);
    if (!fixedRes) {
      throw new Error('Could not fetch updated data, strange case, this should not happen');
    }
    return fixedRes;
  }
}
