import { AfterViewInit, ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { NgxExtendedPdfViewerService, ResponsiveVisibility } from 'ngx-extended-pdf-viewer';
import { PatientFilesService } from '../../services/reference/reference-trace-logs/patient-files.service';

@Component({
  selector: 'app-visualize-pdf',
  templateUrl: './visualize-pdf.component.html',
  styleUrls: ['./visualize-pdf.component.scss'],
  providers: [NgxExtendedPdfViewerService],
})
export class VisualizePdfComponent implements OnInit, AfterViewInit {
  public selectedFile: VisualizePdfFilesData;
  position: number = 0;
  public displayOptions: PdfDisplayOptions = {
    showPagingButtons: false,
    showRotateButton: false,
    showDownloadButton: false,
    showDrawEditor: false,
    showPresentationModeButton: false,
    showEditor: false,
    showFindButton: false,
    showOpenFileButton: false,
    showSecondaryToolbarButton: false,
    showPrintButton: false,
    showZoomButtons: false,
    showSidebarButton: false,
    showToolbar: false,
  };

  showContent: boolean = false;
  isLoading: boolean = true;
  file: Blob;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: VisualizePdfData,
    public dialogRef: MatDialogRef<VisualizePdfComponent>,
    private patientFilesService: PatientFilesService,
    private cdr: ChangeDetectorRef
  ) {}

  async ngOnInit() {
    this.dialogRef.disableClose = true;
    if (typeof this.data.index === 'number' && this.data.index >= 0)
      this.position = this.data.index;
    this.selectedFile = this.data.filesData[this.position];
    this.selectedFile.fileBlob = this.data.filesData[this.position].fileBlob;
    this.selectedFile.fileUrl = this.data.filesData[this.position].fileUrl;
    await this.getBlob();
    if (this.data.options) {
      const keys = Object.keys(this.data.options);
      for (const key of keys) {
        const selectedOptions = this.data.options[key as keyof typeof this.displayOptions];
        this.displayOptions[key as keyof typeof this.displayOptions] = selectedOptions as boolean;
      }
    }
  }

  ngAfterViewInit() {
    this.showContent = true;
    this.cdr.detectChanges();
  }

  async nextFile() {
    if (this.data.filesData.length > 1) {
      if (this.position === this.data.filesData.length - 1) {
        this.position = 0;
      } else {
        this.position++;
      }
      this.selectedFile = this.data.filesData[this.position];
      await this.getBlob();
    }
  }

  async previousFile() {
    if (this.data.filesData.length > 1) {
      if (this.position === 0) {
        this.position = this.data.filesData.length - 1;
      } else {
        this.position--;
      }
      this.selectedFile = this.data.filesData[this.position];
      await this.getBlob();
    }
  }

  async getBlob() {
    this.isLoading = true;
    const resp = await this.patientFilesService.getFileBlobByUrl(this.selectedFile.fileUrl!);
    this.selectedFile.fileBlob = new Blob([resp], {
      type: 'application/pdf',
    });
    this.isLoading = false;
  }
}

export interface VisualizePdfData {
  modalTitle: string;
  filesData: VisualizePdfFilesData[];
  options?: Partial<PdfDisplayOptions>;
  index?: number;
}

export interface VisualizePdfFilesData {
  fileName?: string;
  fileBlob?: Blob;
  fileUrl?: string;
  file?: File;
}

interface PdfDisplayOptions {
  showPagingButtons: ResponsiveVisibility;
  showDownloadButton: ResponsiveVisibility;
  showFindButton: ResponsiveVisibility;
  showPrintButton: ResponsiveVisibility;
  showRotateButton: ResponsiveVisibility;
  showZoomButtons: ResponsiveVisibility;
  showEditor: boolean;
  showDrawEditor: ResponsiveVisibility;
  showPresentationModeButton: ResponsiveVisibility;
  showOpenFileButton: ResponsiveVisibility;
  showSecondaryToolbarButton: ResponsiveVisibility;
  showSidebarButton: ResponsiveVisibility;
  showToolbar: boolean;
}
