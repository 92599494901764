import { AfterViewInit, Component, Inject, OnDestroy } from '@angular/core';
import { CanComponentDeactivate } from '../../guard/leave-page.guard';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { DialogService } from '../../services/dialog.service';
import {
  IsPreoperational,
  ManageVehicleService,
  TransformedResource,
} from '../../services/providers/manage-vehicle/manage-vehicle.service';
import moment from 'moment';
import { fromEvent, Subscription } from 'rxjs';
import { ManageDeviceResourceService } from '../../services/providers/manage-device-resource/manage-device-resource.service';
import { ErrorMobile } from '../../types/http.interfaces';

@Component({
  selector: 'app-preoperational-modal',
  templateUrl: './preoperational-modal.component.html',
  styleUrls: ['./preoperational-modal.component.scss'],
})
export class PreoperationalModalComponent
  implements CanComponentDeactivate, AfterViewInit, OnDestroy
{
  public BREAKPOINT_DESKTOP = 1024;
  form: FormGroup = new FormGroup({
    preoperational: new FormControl('', Validators.required),
  });
  changeOption = false;
  resource: TransformedResource;
  submitted: boolean = false;
  public enterPressed: Subscription;
  public saveDisable: boolean = false;

  constructor(
    public dialogRef: MatDialogRef<PreoperationalModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: TransformedResource,
    private fb: FormBuilder,
    private dialog: MatDialog,
    private dialogService: DialogService,
    private manageVehicleService: ManageVehicleService,
    private manageDeviceResourceService: ManageDeviceResourceService
  ) {
    this.resource = data;
  }

  ngOnDestroy(): void {
    this.enterPressed.unsubscribe();
  }

  isDesktop(): boolean {
    return window.innerWidth >= this.BREAKPOINT_DESKTOP;
  }

  async ngAfterViewInit() {
    await this.getPreopValue();
    this.activateKeyPressed();
  }

  private activateKeyPressed() {
    this.enterPressed = fromEvent<KeyboardEvent>(document, 'keyup').subscribe(async (event) => {
      if (event.code === 'F12' && !this.saveDisable) {
        await this.sendUpdate();
      }
    });
  }

  public transformDate() {
    if (this.resource?.resourceData?.provider?.cutoffDateOREPS) {
      const date = new Date(this.resource?.resourceData?.provider?.cutoffDateOREPS);
      const fechaCorregida = new Date(date);
      return moment(fechaCorregida).format('YYYY/MM/DD - HH:mm:ss');
    }
    return '-';
  }

  /**
   * Method by cancel
   */
  async cancel() {
    if (await this.canDeactivate()) {
      this.dialogRef.close();
    }
  }

  /**
   * Method by close de modal and send de information to father component
   */
  async sendUpdate() {
    this.submitted = true;
    if (this.form.valid) {
      this.saveDisable = true;
      if (this.changeOption) {
        const body: IsPreoperational = {
          preoperational: this.form.get('preoperational')?.value,
          resource_type: this.data.resourceData.resourceType?.title ?? '',
          license_plate: this.data.plate,
        };
        try {
          await this.manageVehicleService.savePreoperational(body);
          await this.dialogService.informativeResultModal(
            'La información ha sido guardada exitosamente',
            'success'
          );
          this.dialogRef.close(this.resource);
        } catch (e) {
          const error: ErrorMobile = e as ErrorMobile;
          let title: string = 'Ocurrió un error al guardar el preoperacional';
          if (error.error?.description.includes('No se encontro dispositivo.')) {
            title = 'Debe asociar el vehículo a un dispositivo';
          } else if (error.error?.description.includes('El preoperational ya se encuentra')) {
            title = error.error.description.replace('preoperational', 'preoperacional');
          } else if (error.error?.description) {
            title = error.error.description;
          }
          await this.dialogService.informativeResultModal(title, 'error');
        }
      } else {
        this.dialogRef.close();
      }
    } else {
      if (!this.dialog.getDialogById('info-subtitled-modal')) {
        await this.dialogService.infoSubtitledModal(
          'CONFIGURAR PREOPERACIONAL',
          'Faltan los siguientes campos por diligenciar',
          'Preoperacional'
        );
        this.form.get('preoperational')?.markAsTouched();
      }
    }
    this.saveDisable = false;
  }

  async canDeactivate(): Promise<boolean> {
    if (this.changeOption) {
      const result = await this.dialogService.yesNoOptionModal(
        'CANCELAR CONFIGURACIÓN PREOPERACIONAL',
        '¿Está seguro de cerrar la pestaña? Los cambios realizados serán descartados',
        'cancel'
      );
      if (result === 'cancelled' || result === undefined) {
        return false;
      }
    }
    return true;
  }

  onRadioChange(event: string) {
    if (event) {
      this.form.get('preoperational')?.setValue(event);
      this.changeOption = true;
    }
  }

  async getPreopValue() {
    const body = {
      id: null,
      plate: this.data.plate,
    };
    const res = (
      await this.manageDeviceResourceService.getManageDeviceResourceList(body, { page: 0, size: 1 })
    ).result;
    if (res && res.length > 0) {
      if (res[0].dataDB?.preoperational) {
        this.form.get('preoperational')?.setValue(res[0].dataDB?.preoperational);
        this.form.get('preoperational')?.markAsTouched();
      }
    }
  }

  getCompanyType(value: string) {
    const HASH_COMPANY_TYPE: { [key: string]: string } = {
      mixed: 'Mixta',
      private: 'Privada',
      public: 'Pública',
    };
    return HASH_COMPANY_TYPE[value] || '-';
  }
}
