import { GeneralKnownRouteValidator, NavItem } from './types-and-helpers.known.routes';

export enum PortalsKnownItemId {
  PORTALS = '1',
  LIST_PORTALS = '2',
}

const simpleTransformer = (input: NavItem): NavItem & { type: 'portals' } => {
  return {
    ...input,
    type: 'portals',
  };
};
export const PortalsKnownRoutes = (k: PortalsKnownItemId): NavItem => {
  k = GeneralKnownRouteValidator(k);
  return JSON.parse(JSON.stringify(options[k]));
};
const options: {
  [k in PortalsKnownItemId]: NavItem & { type: 'portals' };
} = {
  [PortalsKnownItemId.PORTALS]: simpleTransformer({
    id: PortalsKnownItemId.PORTALS,
    displayName: 'Valoración Portales de TM',
    route: '/home/portals',
    iconName: 'transmilenio',
    expanded: false,
    allowFetchWithInclude: false,
    permissions: ['incident_transmilenio_patient_management_w', 'transmilenio_patient_general_q'],
    visibleOnSidebar: false,
  }),
  [PortalsKnownItemId.LIST_PORTALS]: simpleTransformer({
    id: PortalsKnownItemId.LIST_PORTALS,
    displayName: 'Gestionar pacientes de Transmilenio',
    route: '/home/portals/list-portals',
    iconName: 'manage-accounts',
    parentModule: 'portals',
    allowFetchWithInclude: true,
    permissions: ['transmilenio_patient_particular_q', 'transmilenio_patient_general_q'],
    visibleOnSidebar: true,
  }),
};
