import { NgModule } from '@angular/core';
import { ExtraOptions, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './shared/guard/auth.guard';
import { IconsComponent } from './shared/component/icons/icons.component';
import { environment } from '../environments/environment';
import { ParticipantRegistrationPermitGuard } from './shared/guard/participant-registration-permit.guard';
import { PermissionGuard } from './shared/guard/permission-guard.service';

const routerOptions: ExtraOptions = {
  anchorScrolling: 'enabled',
  onSameUrlNavigation: 'reload',
  scrollPositionRestoration: 'enabled',
  scrollOffset: [100, 135],
  enableTracing: false,
  errorHandler: (error) => {
    console.error(error);
  },
};

const routes: Routes = [
  {
    path: 'auth',
    loadChildren: () => import('./auth/auth.module').then((m) => m.AuthModule),
    canActivate: [AuthGuard],
    data: { statusToEnter: 'not-logged' },
  },
  {
    path: 'home',
    loadChildren: () => import('./home/home.module').then((m) => m.HomeModule),
    canActivate: [AuthGuard, ParticipantRegistrationPermitGuard],
    data: { statusToEnter: 'logged' },
  },
  {
    path: 'svg-icons',
    component: IconsComponent,
  },
  {
    path: 'incidents-map',
    canActivate: [AuthGuard, PermissionGuard],
    loadChildren: () => import('./general-map/general-map.module').then((m) => m.GeneralMapModule),
  },
  {
    path: 'public',
    loadChildren: () => import('./public/public.module').then((m) => m.PublicModule),
  },
  {
    path: 'register',
    loadChildren: () =>
      import('./registration/registration.module').then((m) => m.RegistrationModule),
    canActivate: [AuthGuard],
  },
  { path: '', redirectTo: '/auth', pathMatch: 'full' },
];

if (environment.api.includes('dev')) {
  routes.push({
    path: 'sandbox',
    loadChildren: () => import('./sandbox/sandbox.module').then((m) => m.SandboxModule),
  });
}

routes.push({
  path: '**',
  redirectTo: '/auth',
  pathMatch: 'full',
});

@NgModule({
  imports: [RouterModule.forRoot(routes, routerOptions)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
