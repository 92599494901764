import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  Inject,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { FocusMonitor } from '@angular/cdk/a11y';
import { DialogInterface } from '../../types/dialog.interface';
import { ProfileService } from '../../services/profile/profile.service';
import { ActorsService } from '../../services/actors/actors.service';
import { DialogService } from '../../services/dialog.service';
import { TableColumn } from '../../types/table';
import { KnownRoles } from '../../services/roles-and-permissions/known-roles.data';
import { fromEvent, lastValueFrom, Subscription } from 'rxjs';
import { PermissionsValidatorService } from '../../services/roles-and-permissions/permissions-validator.service';
import { ZoneSelectorComponent } from '../header/component/zone-selector/zone-selector.component';
import { Role } from '../../services/roles-and-permissions/role.service';
import { AlertNotificationService } from '../../services/administration/alert-notification/alert-notification.service';

@Component({
  selector: 'app-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss'],
})
export class DialogComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('statusUpdateDescription', { read: ElementRef, static: false })
  description: ElementRef;
  public messages: string;
  public replaceValue: string;
  public otherColorMessage: string;
  public roles: Role[];
  public date: string;
  public title: string;
  public titleMobileSize?: number;
  public hideCloseDialog: boolean = false;
  public icon: string;
  public isButtons: boolean;
  public orientationIconTop: boolean;
  public subMessage: string[];
  public isActions: boolean;
  public isTwoActions: boolean;
  public isThirdAction: boolean;
  public typeConfirm: string;
  public buttonsMessageType: 'yes-no' | 'accept-cancel';
  public validDescription: boolean = true;
  public textareaRows: number;
  public twoColorsMessage: string[];
  public dataSource: any[] = [
    {
      reviewDate: '06/07/2022',
      finishDate: '06/07/2022',
      massClosure: 'Si',
      manager: 'Gestor 1',
      observations: '',
    },
  ];
  public tableDisplayedColumns: TableColumn[] = [
    { key: 'reviewDate', value: 'Fecha y hora de revisión' },
    { key: 'finishDate', value: 'Fecha y hora de cierre' },
    { key: 'massClosure', value: 'Cierre Masivo' },
    { key: 'observations', value: 'Observaciones' },
  ];

  public titleIcon: string = 'info-message';

  isSelectingZone: boolean = false;

  public messageFromMethod?: () => { message: string; subMessage: string[] };
  public messageFromMethodInterval?: number;

  private _internalTimer: NodeJS.Timer;

  readonly KnownRoles = KnownRoles;

  private enterPressed: Subscription = new Subscription();

  constructor(
    public profileService: ProfileService,
    public dialogService: DialogService,
    public dialogRef: MatDialogRef<DialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogInterface,
    private _focusMonitor: FocusMonitor,
    private actorsService: ActorsService,
    private changeDetectorRef: ChangeDetectorRef,
    private permissionsValidatorService: PermissionsValidatorService,
    private matDialog: MatDialog,
    private alertNotificationService: AlertNotificationService
  ) {
    this.title = data.title;
    this.titleMobileSize = data.titleMobileSize;
    this.roles = data.rolesInfo;
    this.icon = data.icon;
    this.messages = data.message;
    this.otherColorMessage = data.otherColorMessage ? data.otherColorMessage : '';
    this.replaceValue = data.replaceValue ? data.replaceValue : '';
    const transformedCommas = data.subMessage?.replace(/,/g, '[NEWLINE]');
    this.subMessage = transformedCommas?.replace(/(%044)/g, ',').split('[NEWLINE]') ?? [];
    this.textareaRows = data.textareaRows || 3;
    this.date = data.date;
    this.isButtons = data.isButtons;
    this.orientationIconTop = data.orientationIconTop;
    this.isActions = data.isActions;
    this.hideCloseDialog = data.hideCloseDialog;
    this.isTwoActions = data.isTwoActions;
    this.isThirdAction = data.isThirdAction;
    this.typeConfirm = data.typeConfirm;
    this.buttonsMessageType = data.buttonsMessageType || 'yes-no';
    this.messageFromMethod = data.messageFromMethod;
    this.messageFromMethodInterval = data.messageFromMethodInterval;
    if (this.typeConfirm == 'policy') {
      this.titleIcon = 'personal-data';
    } else if (this.typeConfirm == 'terms') {
      this.titleIcon = 'terms';
    }
    if (this.typeConfirm === 'icon-two-colors') {
      this.twoColorsMessage = this.messages.split(' ');
    } else if (this.typeConfirm === 'icon-two-colors-contract') {
      this.twoColorsMessage = this.messages.split(' ');
    } else if (this.typeConfirm === 'two-options') {
      this.twoColorsMessage = this.otherColorMessage.split(',');
    }
    //
    if (this.messageFromMethod) {
      const data = this.messageFromMethod!();
      this.messages = data.message;
      this.subMessage = data.subMessage;
      this._internalTimer = setInterval(() => {
        const data = this.messageFromMethod!();
        this.messages = data.message;
        this.subMessage = data.subMessage;
      }, this.messageFromMethodInterval || Number.MAX_VALUE);
    }
  }

  ngOnInit(): void {
    this.forceRefresh();
    if (this.typeConfirm.includes('success') || this.typeConfirm === 'info') {
      setTimeout(() => {
        this.close();
      }, 3000);
    }
    this.enterPressed.add(
      fromEvent<KeyboardEvent>(document, 'keyup').subscribe(async (event) => {
        if (this.data.saveWithKey && event.code === this.data.saveWithKey) {
          await this.onSubmit();
        }
      })
    );
  }

  ngOnDestroy(): void {
    if (this._internalTimer) {
      clearInterval(this._internalTimer);
    }
    if (this.enterPressed) this.enterPressed.unsubscribe();
  }

  ngAfterViewInit() {
    const element: any = document.getElementById('cancel');
    this._focusMonitor.stopMonitoring(element);
  }

  @HostListener('window:keydown.enter', ['$event'])
  private closeDialog(_event: any) {
    if (this.typeConfirm === 'info-subtitled' || this.typeConfirm === 'info') {
      this.close();
    }
  }

  close(data?: string | boolean) {
    this.dialogRef.close(data);
    this.forceRefresh();
  }

  private forceRefresh() {
    this.changeDetectorRef.detectChanges();
    setTimeout(() => {
      this.changeDetectorRef.detectChanges();
    }, 1);
  }

  // getSubmitText() {
  //
  // }
  //
  // getCancelText() {
  //   if(this.isActions){
  //
  //   }
  // }

  cancel() {
    this.close('cancelled');
  }

  validateDescription() {
    if (this.typeConfirm === 'update-status') {
      const description = this.description.nativeElement.value;
      description.trim() !== '' ? (this.validDescription = true) : (this.validDescription = false);
    }
  }

  private isDialogOpen: boolean = false;

  async onSubmit(submission?: string) {
    this.validateDescription();
    if (this.isThirdAction) {
      submission = submission?.trim();
      if ((!submission || submission?.length === 0) && !this.validDescription) {
        if (!this.isDialogOpen) {
          this.isDialogOpen = true;
          const msg =
            this.otherColorMessage === 'Observaciones*'
              ? 'Debe ingresar las '
              : 'Debe ingresar el ';
          await this.dialogService.errorModal(
            this.title,
            msg + this.otherColorMessage.replace('Ingrese el ', '').toLowerCase(),
            undefined,
            this.titleMobileSize
          );
          this.isDialogOpen = false;
        }
        return;
      }
    }
    this.close(submission ? submission : this.typeConfirm);
  }

  async validateZone() {}

  async selectRole(role: Role) {
    try {
      let zone: number | undefined;
      if (
        (await this.permissionsValidatorService.hasAccessToSection(
          ['incident_north_zone_q'],
          role.id
        )) &&
        (await this.permissionsValidatorService.hasAccessToSection(
          ['incident_south_zone_q'],
          role.id
        ))
      ) {
        const dialog = this.matDialog.open(ZoneSelectorComponent, {
          width: '40%',
          minHeight: '170px',
          minWidth: '170px',
          panelClass: 'add-contact-modal',
          data: role.id,
        });
        zone = await lastValueFrom(dialog.afterClosed());
      } else if (
        await this.permissionsValidatorService.hasAccessToSection(['incident_north_zone_q'])
      ) {
        zone = 1;
      } else if (
        await this.permissionsValidatorService.hasAccessToSection(['incident_south_zone_q'])
      ) {
        zone = 2;
      }
      await this.actorsService.registerActorEvent({
        eventType: 'roleChange',
        eventData: {
          currentRoleId: role.id,
          previousRoleId: this.profileService.selectedRole?.id || '-1',
          zoneId: zone,
          actorId: this.profileService.authenticatedActor?.id || -1,
        },
      });
      this.profileService.zone = zone;
    } catch (err) {
      console.error(err);
    }
    const update = this.profileService.selectedRole?.id !== role.id;
    this.profileService.selectedRole = role;
    this.alertNotificationService.getPendingReceivedCount(true);
    this.profileService.changeRole.emit(update);
    this.close(true);
  }

  async cancelSelectRole() {
    if (this.profileService.selectedRole?.id) {
      this.close();
    } else {
      if (this.profileService.authenticatedActor?.roles) {
        const role = this.profileService.authenticatedActor.roles[0];
        await this.selectRole(role);
      }
    }
  }
}
