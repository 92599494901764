import { CachedCRUDService } from '../../../types/cached-c-r-u-d.service';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { lastValueFrom } from 'rxjs';
import { ServerResponse } from '../../../types/http.interfaces';
import { environment } from '../../../../../environments/environment';
import { IncidentStatus } from '@smartsoft-types/sisem-incidents';

@Injectable({
  providedIn: 'root',
})
export class IncidentsStatusService extends CachedCRUDService<IncidentStatus, never, never> {
  constructor(private http: HttpClient) {
    super();
    this.options.cacheExpiration = -1; // should almost never change, set don't expire
  }

  protected override async _fetchNewData(): Promise<IncidentStatus[]> {
    const response = await lastValueFrom(
      this.http.get<ServerResponse<IncidentStatus[]>>(
        `${environment.api}/incidents-ms/api/v1/incidentStatus`
      )
    );
    return response.data;
  }
}
