import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { lastValueFrom, Subject } from 'rxjs';
import {
  AdditionalParticipantData,
  BasicRegistration,
  EmergencyTeams,
  EmploymentAcademicData,
  FileAuthFormatPdf,
  ParametricValues,
  ParticipantRegistration,
  PopulationCharacterizationLocation,
  PostParticipant,
  SupplementaryRegister,
} from '../../types/registration.interface';
import { environment } from '../../../../environments/environment';
import { TableKeyItem } from '../../types/table';

@Injectable({
  providedIn: 'root',
})
export class RegistrationService {
  private _headStep: HeadStep = {
    title: '',
    enumerator: 1,
    percentage: '20%',
    active: false,
  };
  private _dataBasicRegistration: BasicRegistration;
  private _authorizationPdf: File | undefined;
  private _authorizationPdfURL: string | undefined;
  private _personalDataSupplementary: SupplementaryRegister;
  private _populationCharacterizationLocation: PopulationCharacterizationLocation;
  private _employmentAcademicData: EmploymentAcademicData;
  private _emergencyTeams: EmergencyTeams;
  private _identificationType: TableKeyItem[] = [];
  private _typeOfGenres: TableKeyItem[] = [];
  private _publicCompanies: TableKeyItem[] = [];
  private _publicCompaniesEmergency: TableKeyItem[] = [];
  private _profession: TableKeyItem[] = [];
  private _levelOfTraining: TableKeyItem[] = [];
  private _participantId: number;
  private _formInitializationEvent: Subject<void> = new Subject<void>();
  private _bogotaSelected: Subject<boolean> = new Subject<boolean>();
  private _resetCompanyData: Subject<void> = new Subject<void>();
  private _redirectUrl: string | undefined = undefined;

  constructor(private http: HttpClient) {}

  get headStep(): HeadStep {
    return this._headStep;
  }

  set headStep(value: HeadStep) {
    this._headStep = value;
  }

  get dataBasicRegistration(): BasicRegistration {
    return this._dataBasicRegistration;
  }

  set dataBasicRegistration(value: BasicRegistration) {
    this._dataBasicRegistration = value;
  }

  get authorizationPdf(): File | undefined {
    return this._authorizationPdf;
  }

  set authorizationPdf(value: File | undefined) {
    this._authorizationPdf = value;
  }

  get authorizationPdfURL(): string | undefined {
    return this._authorizationPdfURL;
  }

  set authorizationPdfURL(value: string | undefined) {
    this._authorizationPdfURL = value;
  }

  get personalDataSupplementary(): SupplementaryRegister {
    return this._personalDataSupplementary;
  }

  set personalDataSupplementary(value: SupplementaryRegister) {
    this._personalDataSupplementary = value;
  }

  get populationCharacterizationLocation(): PopulationCharacterizationLocation {
    return this._populationCharacterizationLocation;
  }

  set populationCharacterizationLocation(value: PopulationCharacterizationLocation) {
    this._populationCharacterizationLocation = value;
  }

  get employmentAcademicData(): EmploymentAcademicData {
    return this._employmentAcademicData;
  }

  set employmentAcademicData(value: EmploymentAcademicData) {
    this._employmentAcademicData = value;
  }

  get emergencyTeams(): EmergencyTeams {
    return this._emergencyTeams;
  }

  set emergencyTeams(value: EmergencyTeams) {
    this._emergencyTeams = value;
  }

  get identificationType(): TableKeyItem[] {
    return this._identificationType;
  }

  set identificationType(value: TableKeyItem[]) {
    this._identificationType = value;
  }

  get publicCompanies(): TableKeyItem[] {
    return this._publicCompanies;
  }

  set publicCompanies(value: TableKeyItem[]) {
    this._publicCompanies = value;
  }

  get participantId(): number {
    return this._participantId;
  }

  set participantId(value: number) {
    this._participantId = value;
  }

  get formInitializationEvent(): Subject<void> {
    return this._formInitializationEvent;
  }

  formInitializationEventRun() {
    this._formInitializationEvent.next();
  }

  get resetCompanyDataEvent(): Subject<void> {
    return this._resetCompanyData;
  }

  resetCompanyData() {
    this._resetCompanyData.next();
  }

  get bogotaSelectedSubscription(): Subject<boolean> {
    return this._bogotaSelected;
  }

  bogotaSelected(isSelected: boolean) {
    this._bogotaSelected.next(isSelected);
  }

  get typeOfGenres(): TableKeyItem[] {
    return this._typeOfGenres;
  }

  set typeOfGenres(value: TableKeyItem[]) {
    this._typeOfGenres = value;
  }

  get levelOfTraining(): TableKeyItem[] {
    return this._levelOfTraining;
  }

  set levelOfTraining(value: TableKeyItem[]) {
    this._levelOfTraining = value;
  }

  get profession(): TableKeyItem[] {
    return this._profession;
  }

  set profession(value: TableKeyItem[]) {
    this._profession = value;
  }

  get publicCompaniesEmergency(): TableKeyItem[] {
    return this._publicCompaniesEmergency;
  }

  set publicCompaniesEmergency(value: TableKeyItem[]) {
    this._publicCompaniesEmergency = value;
  }

  get redirectUrl(): string | undefined {
    return this._redirectUrl;
  }

  set redirectUrl(value: string | undefined) {
    this._redirectUrl = value;
  }

  async postParticipants(data: ParticipantRegistration) {
    const formData = new FormData();
    formData.append('body', data.body, 'participant.json');
    if (data.authorizationPdf)
      formData.append(
        'authorizationPdf',
        data.authorizationPdf,
        'DOC DE AUTORIZACION MENORES DE EDAD.pdf'
      );

    let headers = new HttpHeaders();
    headers = headers.append('Accept', 'application/json');
    headers = headers.append('skip', 'true');

    return await lastValueFrom(
      this.http.post<PostParticipant>(
        `${environment.api}/learning-ms/api/v1/participants`,
        formData,
        { headers }
      )
    );
  }

  async getAuthFormatPdf() {
    let headers = new HttpHeaders();
    headers = headers.append('skip', 'true');

    return await lastValueFrom(
      this.http.get<FileAuthFormatPdf>(
        `${environment.api}/learning-ms/api/v1/participants/authorization-format`,
        { headers }
      )
    );
  }

  async getParticipantData(id: number, update: boolean = false) {
    const res = await lastValueFrom(
      this.http.get<AdditionalParticipantData>(
        `${environment.api}/learning-ms/api/v1/participants/actor-id/${id}`
      )
    );
    if (!this.participantId || update) {
      this.participantId = res.id;
    }
    return res;
  }

  getLocalData() {
    localStorage.getItem('');
  }

  async getParametricValuesMinAge() {
    let headers = new HttpHeaders();
    headers = headers.append('skip', 'true');
    return await lastValueFrom(
      this.http.get<ParametricValues>(
        `${environment.api}/learning-ms/api/v1/parametric-values/public`,
        { headers }
      )
    );
  }

  async putComplementaryDataParticipant(
    basicData: File,
    complementaryData: File,
    authorizationPdf: File | undefined
  ) {
    const formData = new FormData();
    formData.append('basicData', basicData, 'participant.json');
    formData.append('complementaryData', complementaryData, 'participantComplementary.json');
    if (authorizationPdf)
      formData.append(
        'authorizationPdf',
        authorizationPdf,
        'DOC DE AUTORIZACION MENORES DE EDAD.pdf'
      );
    return await lastValueFrom(
      this.http.put(
        `${environment.api}/learning-ms/api/v1/participants/${this.participantId}/complementary-data`,
        formData
      )
    );
  }

  async putDataParticipant(
    basicData: File,
    complementaryData: File,
    authorizationPdf: File | undefined
  ) {
    const formData = new FormData();
    formData.append('basicData', basicData, 'participant.json');
    formData.append('complementaryData', complementaryData, 'participantComplementary.json');
    if (authorizationPdf)
      formData.append(
        'authorizationPdf',
        authorizationPdf,
        'DOC DE AUTORIZACION MENORES DE EDAD.pdf'
      );
    return await lastValueFrom(
      this.http.put(
        `${environment.api}/learning-ms/api/v1/participants/${this.participantId}`,
        formData
      )
    );
  }

  async requestCodeToDropOut(id: number, force: boolean = false) {
    if (!this.participantId) {
      await this.getParticipantData(id, force);
    }
    await lastValueFrom(
      this.http.post(
        `${environment.api}/learning-ms/api/v1/participants/${this.participantId}/delete-account-request`,
        {}
      )
    );
  }

  async validateCodeToDropOut(id: number, body: { code: string }) {
    if (!this.participantId) {
      await this.getParticipantData(id);
    }
    await lastValueFrom(
      this.http.post(
        `${environment.api}/learning-ms/api/v1/participants/${this.participantId}/delete-account`,
        body
      )
    );
  }

  fileProcessing(pdfUrl: string) {
    let headers = new HttpHeaders();
    headers = headers.append('Accept', 'application/json');
    headers = headers.append('skip', 'true');
    this.http
      .get(pdfUrl, { responseType: 'arraybuffer', headers })
      .toPromise()
      .then((data: ArrayBuffer | undefined) => {
        if (data) {
          const blob = new Blob([data], { type: 'application/pdf' });
          this.authorizationPdf = new File([blob], 'DOC DE AUTORIZACIÓN MENORES DE EDAD.pdf', {
            type: 'application/pdf',
          });
        }
      })
      .catch((error) => {
        throw error;
      });
  }

  deleteLocalStorage() {
    const formsToDelete = [
      'academicData',
      'basicParticipantDataForm',
      'emergencyTeamsForm',
      'employmentAcademicForm',
      'institutionList',
      'participantId',
      'personalDataSupplementary',
      'populationLocationForm',
    ];

    for (const form of formsToDelete) {
      localStorage.removeItem(form);
    }
  }
}

export interface HeadStep {
  title: string;
  enumerator: number;
  percentage: string;
  active: boolean;
}
