import {
  AfterViewInit,
  Component,
  ElementRef,
  Inject,
  OnDestroy,
  OnInit,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DialogComponent } from '../dialog/dialog.component';
import { EditionDialogInterface } from '../../types/dialog.interface';
import { Clipboard } from '@angular/cdk/clipboard';
import { AlertService } from '../../services/alert.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ValidatorsService } from '../../services/validators.service';
import { TableKeyItem } from '../../types/table';
import { LocationsService } from '../../services/locations.service';
import { ParametricsInterface } from '../../types/parametrics.interface';
import { IncidentsService } from '../../services/incidents/incidents.service';
import { DialogService } from '../../services/dialog.service';
import { RequesterUpdateInput } from '@smartsoft-types/sisem-incidents/dist/src/entities/schemas/types/requester/requester.schema.entity';
import { IncidentDB, IncidentType } from '../../types/incident.interface';
import { ProfileService } from '../../services/profile/profile.service';
import {
  IncidentRoleRelation,
  IncidentRoleUpdateInput,
  Requester,
} from '@smartsoft-types/sisem-incidents';
import { IncidentLocalityUpdateInput } from '@smartsoft-types/sisem-incidents/dist/src/entities/schemas/types/incident/incident.schema.entity';
import { LoadingService } from '../../services/loading.service';
import { IncidentsTypesService } from '../../services/incidents/incidents-sub-resources/incidents.types.service';
import { IncidentsAttentionsService } from '../../services/incidents/incidents-sub-resources/incidents.attentions.service';
import { IncidentsZonesService } from '../../services/incidents/incidents-sub-resources/incidents.zones.service';
import { KnownRoles } from '../../services/roles-and-permissions/known-roles.data';
import { Subscription } from 'rxjs';
import { UserService } from '../../services/administration/user/user.service';
import { GeocoderService } from '../select-map-location/geocoder.service';
import {
  AddressResult,
  SelectMapLocationComponent,
} from '../select-map-location/select-map-location.component';
import { ServerError } from '../../types/http.interfaces';
import { PermissionsValidatorService } from '../../services/roles-and-permissions/permissions-validator.service';
import { RoleTabService } from '../../services/administration/roles/role-tab.service';
import { Role } from '../../services/roles-and-permissions/role.service';
import { PlacesAutocompleteService } from '../places-autocomplete/places-autocomplete.service';

@Component({
  selector: 'app-edition-modal',
  templateUrl: './edition-modal.component.html',
  styleUrls: ['./edition-modal.component.scss'],
})
export class EditionModalComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('locality') locality: ElementRef;
  @ViewChild('referencePoint') referencePoint: ElementRef;
  @ViewChild('selectMapLocationComponent') selectMapLocationComponent: SelectMapLocationComponent;
  public saveCanEdit: boolean = true;
  public canUpdateDirection: boolean = false;
  public readonly relationshipWithPatient = [
    { id: 1, name: 'Otro' },
    { id: 2, name: 'Vecino' },
    { id: 3, name: 'Familiar' },
  ];
  public incidents: IncidentType[];
  public incident: IncidentDB;
  public hasSaved: boolean = false;
  public isMentalHealth: boolean = false;
  public title: string;
  public type: string;
  public titleIcon: string = 'info-message';
  public distribution: Array<{
    id: string;
    value: string;
    code: string;
    state: boolean;
    disabled: boolean;
  }> = [];
  public changedDistribution: boolean = false;
  public locationForm: FormGroup;
  public locationFormTouched: boolean = false;
  public callerForm: FormGroup;
  public callerFormTouched: boolean = false;
  public roles: Role[];
  public locations: TableKeyItem[] = [];
  public zones: ParametricsInterface[] = [];
  public attention: ParametricsInterface[] = [];
  public isAnonymous: boolean = false;
  public addedPhones: string[] = [];
  public hasPriority: boolean = false;
  public distributionError: boolean = false;

  public locationIsUpdated: boolean = false;
  public callerIsUpdated: boolean = false;
  private openModal: boolean;
  private saving: boolean = false;
  public currentRole: string | undefined;
  private subscription: Subscription;
  private selectPlaceSubscription: Subscription;
  readonly KnownRoles = KnownRoles;
  public incidentLocationData?: { lat: number; lng: number } = undefined;
  public showBigMap = false;

  public subscriptionNameCallback: string;

  constructor(
    public clipboard: Clipboard,
    public validatorsService: ValidatorsService,
    public locationService: LocationsService,
    public dialogRef: MatDialogRef<DialogComponent>,
    public profileService: ProfileService,
    public alertService: AlertService,
    public dialogService: DialogService,
    public incidentService: IncidentsService,
    private loadingService: LoadingService,
    private userService: UserService,
    @Inject(MAT_DIALOG_DATA) public data: EditionDialogInterface,
    private incidentsTypesService: IncidentsTypesService,
    private incidentsAttentionsService: IncidentsAttentionsService,
    private incidentsZonesService: IncidentsZonesService,
    private permissionsValidator: PermissionsValidatorService,
    private geocoderService: GeocoderService,
    private renderer: Renderer2,
    private elementRef: ElementRef,
    private roleService: RoleTabService,
    public placesAutocompleteService: PlacesAutocompleteService
  ) {
    this.renderer.addClass(this.elementRef.nativeElement, 'edition-dialog-content');
    this.incident = data.incident;
    this.title = data.title;
    this.titleIcon = data.icon;
    this.type = data.type;
    this.subscriptionNameCallback = this.incidentService.tabs.addCallbackForKeys(
      (keyCode: number) => {
        if (keyCode === 123 && this.openModal) {
          this.submitEdition(this.type);
        }
      },
      true,
      false
    );
  }

  ngAfterViewInit(): void {
    this.buildAutocomplete();
  }

  buildAutocomplete() {
    this.selectPlaceSubscription = this.placesAutocompleteService.selectPlace.subscribe(
      (place: google.maps.places.PlaceResult) => {
        this.setMapLocation(place).then();
      }
    );
  }

  public onMapClose(saved: AddressResult | undefined) {
    this.showBigMap = false;
    if (saved) {
      this.selectMapLocationComponent.setLocationByAddress(saved).then();
    }
  }

  ngOnDestroy(): void {
    if (this.subscription) this.subscription.unsubscribe();
    if (this.selectPlaceSubscription) this.selectPlaceSubscription.unsubscribe();
    if (this.subscriptionNameCallback)
      this.incidentService.tabs.deleteCallbackForKeys(this.subscriptionNameCallback);
  }

  async ngOnInit(): Promise<void> {
    this.currentRole = this.profileService.selectedRole?.name;
    this.subscription = this.profileService.changeRole.subscribe(async () => {
      this.currentRole = this.profileService.selectedRole?.name;
    });
    this.initValues();
    this.dialogRef.disableClose = true;

    this.loadingService.setLoading(true);
    try {
      if (this.type === 'location') {
        this.locationForm.get('incidentAddress')?.setValue(this.incident.address ?? '');
        this.locationForm.get('addressComplement')?.setValue(this.incident.addressComplement ?? '');
        this.locationForm.get('locality')?.setValue(this.incident.localityId ?? '');
        this.locationForm.get('attentionPlace')?.setValue(this.incident.attendanceAt?.id ?? '');
        this.locationForm
          .get('referencePoint')
          ?.setValue(this.incident.addressReferencePoint ?? '');
        this.locationForm.get('zonesDh')?.setValue(this.incident.environment ?? '');
        this.locationForm.get('neighborhood')?.setValue(this.incident.district ?? '');
        this.locationForm.get('zone')?.setValue(this.incident.zone?.id ?? '');

        await this.retrieveDataLists();
        const canEdit = await this.incidentService.validateEditingLocation(
          this.data.incident.id,
          this.profileService.selectedRole?.id!
        );
        await this.incidentService.validateEditingLocation(
          this.data.incident.id,
          this.profileService.selectedRole?.id!
        );
        if (canEdit.activeUpdateLocality && canEdit.actorId !== null) {
          if (this.profileService.authenticatedActor?.id !== canEdit.actorId) {
            await this.disableFormEditing(canEdit.actorId);
          }
        } else {
          await this.incidentService.setUpdateLocalityStatus(
            this.data.incident.id,
            this.profileService.selectedRole?.id!
          );
        }
        const coordinates = this.incident.geolocation?.coordinates;
        await this.setLocationCoordinates(this.incident.address ?? '', coordinates);
      } else if (this.type === 'caller') {
        this.incidents = await this.incidentsTypesService.getAll();
        this.isMentalHealth =
          this.incident?.incidentType?.code === '941' ||
          this.incident?.incidentType?.code === '918' ||
          this.incident?.incidentType?.code === '611' ||
          this.incident?.incidentType?.code === '609';
        const requester = this.incident.requester;
        if (!requester) return;
        this.isAnonymous = requester.firstName === 'NN';
        const relationship = this.relationshipWithPatient.find(
          (relationship) =>
            relationship.name.toLowerCase() === requester.relationWithPatient?.toLowerCase()
        )?.id;
        this.callerForm
          .get('callerFirstName')
          ?.setValue(this.isAnonymous ? 'NN' : requester.firstName);
        if (this.isAnonymous) {
          this.callerForm.get('callerFirstName')?.disable();
          this.callerForm.get('callerSecondName')?.disable();
          this.callerForm.get('callerLastName')?.disable();
          this.callerForm.get('callerSecondLastName')?.disable();
        }
        this.callerForm.get('callerSecondName')?.setValue(requester.secondName ?? '');
        this.callerForm.get('callerLastName')?.setValue(requester.lastName ?? '');
        this.callerForm.get('callerSecondLastName')?.setValue(requester.secondLastName ?? '');
        this.callerForm.get('relationshipWithPatient')?.setValue(relationship ?? '');
        this.callerForm.get('creationComments')?.setValue(requester.observations ?? '');
        const cp = requester.contactPoints?.filter((phone) => phone.value !== undefined) ?? [];
        this.addedPhones = requester.contactPoints ? [...cp.map((phone) => phone.value)] : [];
        this.hasPriority = requester.observationIsPriority;
      } else {
        try {
          this.roles = await this.roleService.roleService.getAll();
          const mentalRole = this.roles.find((role) => role.name === (KnownRoles.MENTAL as string));
          const deliveryRole = this.roles.find(
            (role) => role.name === (KnownRoles.DELIVERY as string)
          );
          const tarmRole = this.roles.find((role) => role.name === (KnownRoles.TARM as string));
          if (!mentalRole) {
            throw new Error('Mental role not found');
          }
          if (!deliveryRole) {
            throw new Error('Delivery role not found');
          }
          if (!tarmRole) {
            throw new Error('TARM role not found');
          }
          this.distribution[0].id = mentalRole.id;
          this.distribution[0].value =
            mentalRole.attributes?.title[0] || this.distribution[0].value;
          //
          this.distribution[1].id = deliveryRole.id;
          this.distribution[1].value =
            deliveryRole.attributes?.title[0] || this.distribution[1].value;
          //
          this.distribution[2].id = tarmRole.id;
          this.distribution[2].value = tarmRole.attributes?.title[0] || this.distribution[2].value;
          //
          this.incident.roles?.forEach((roles: IncidentRoleRelation) => {
            for (let n = 0; n < 3; n++) {
              if (roles.roleId === this.distribution[n].id) {
                this.distribution[n].state = true;
              }
            }
          });
        } catch (e) {
          console.error(e);
          this.alertService.showError(
            'No se pudieron obtener los roles necesarios, contactar con soporte.'
          );
        }
      }
    } catch (e) {
      console.error(e);
    }
    this.loadingService.setLoading(false);
  }

  async setLocationCoordinates(address?: string, coordinates?: number[]) {
    await this.selectMapLocationComponent.setLocationByAddress({
      address,
      location: coordinates
        ? {
            lat: coordinates[1],
            lng: coordinates[0],
          }
        : undefined,
    });
  }

  public async disableFormEditing(id: number) {
    this.locationForm.get('incidentAddress')?.disable();
    this.locationForm.get('addressComplement')?.disable();
    this.locationForm.get('locality')?.disable();
    this.locationForm.get('attentionPlace')?.disable();
    this.locationForm.get('referencePoint')?.disable();
    this.locationForm.get('zonesDh')?.disable();
    this.locationForm.get('neighborhood')?.disable();
    this.locationForm.get('zone')?.disable();
    this.saveCanEdit = false;
    const actor = await this.userService.getById(id);
    const name =
      actor?.firstName.value +
      ' ' +
      actor?.secondName.value +
      ' ' +
      actor?.lastName.value +
      ' ' +
      actor?.secondLastName.value;
    await this.dialogService.informativeResultModal(
      `El usuario ${name} está editando la localización de este incidente`,
      'error'
    );
  }

  onMapUpdate(_address: AddressResult) {
    this.updateAddress().then();
  }

  public async setMapLocation(place: google.maps.places.PlaceResult) {
    if (this.locationForm) {
      if (place.name) {
        this.locationForm.get('address')?.setValue(place.name);
        if (place.geometry?.location) {
          const lng = place.geometry.location.lng();
          const lat = place.geometry.location.lat();
          this.incidentLocationData = { lat, lng };
          await this.setLocationCoordinates(place.name, [lng, lat]);
          return;
        }
      }
      if (!place?.geometry?.location) {
        await this.selectMapLocationComponent.setLocationByAddress(
          this.locationForm.get('incidentAddress')?.value
        );
      } else {
        await this.selectMapLocationComponent.setLocationByCoords(
          place.geometry.location,
          place.name
        );
      }
    }
  }

  async clickShowAddressModal() {
    if (!this.saveCanEdit) return;
    this.showBigMap = true;

    if (this.selectMapLocationComponent.address.address == undefined) {
      const textualAddress = this.locationForm.get('incidentAddress')?.value || '';
      this.selectMapLocationComponent.address = { address: textualAddress };
    }

    // await this.getLatLng();
  }

  private initValues() {
    this.openModal = true;
    this.locationForm = new FormGroup({
      incidentAddress: new FormControl({ value: '', disabled: this.data.disabled }),
      addressComplement: new FormControl({ value: '', disabled: this.data.disabled }),
      locality: new FormControl({ value: '', disabled: this.data.disabled }, Validators.required),
      attentionPlace: new FormControl({ value: '', disabled: this.data.disabled }),
      referencePoint: new FormControl(
        { value: '', disabled: this.data.disabled },
        Validators.required
      ),
      zonesDh: new FormControl({ value: '', disabled: this.data.disabled }),
      neighborhood: new FormControl({ value: '', disabled: this.data.disabled }),
      zone: new FormControl({ value: 1, disabled: this.data.disabled }),
    });
    this.callerForm = new FormGroup({
      callerFirstName: new FormControl({ value: '', disabled: this.data.disabled }, [
        Validators.required,
        Validators.pattern(this.validatorsService.alphabeticWithAccentsValidator),
      ]),
      callerSecondName: new FormControl({ value: '', disabled: this.data.disabled }, [
        Validators.pattern(this.validatorsService.alphabeticWithAccentsValidator),
      ]),
      callerLastName: new FormControl({ value: '', disabled: this.data.disabled }, [
        Validators.pattern(this.validatorsService.alphabeticWithAccentsValidator),
      ]),
      callerSecondLastName: new FormControl({ value: '', disabled: this.data.disabled }, [
        Validators.pattern(this.validatorsService.alphabeticWithAccentsValidator),
      ]),
      relationshipWithPatient: new FormControl({
        value: '',
        disabled: this.data.disabled,
      }),
      callerPhoneNumber: new FormControl({ value: '', disabled: this.data.disabled }, [
        Validators.minLength(7),
        Validators.maxLength(10),
        Validators.pattern(this.validatorsService.numberValidator),
      ]),
      creationComments: new FormControl({
        value: '',
        disabled: this.data.disabled,
      }),
    });
    this.distribution = [
      {
        id: 'X',
        value: 'Salud mental',
        code: 'psychologist',
        state: false,
        disabled: this.data.disabled,
      },
      {
        id: 'X',
        value: 'Despacho',
        code: 'dispatch',
        state: false,
        disabled: this.data.disabled,
      },
      {
        id: 'X',
        value: 'TARM',
        code: 'tarm',
        state: false,
        disabled: this.data.disabled,
      },
    ];
  }

  public async retrieveDataLists() {
    this.loadingService.setLoading(true);
    try {
      await this.locationService.getStates();
      this.locations = this.locationService.getDistricts('BOG').map((location) => ({
        id: location.id,
        title: location.name,
      }));
      this.zones = await this.incidentsZonesService.getAll();
      this.attention = await this.incidentsAttentionsService.getAll();
    } catch (e) {
      console.error(e);
    }
    this.loadingService.setLoading(false);
  }

  setLocality(localityId: number | string) {
    this.locationForm.get('locality')!.setValue(localityId);
    this.locationForm.markAsDirty();
  }

  setAttentionPlace(attentionPlaceId: number | string) {
    this.locationForm.get('attentionPlace')!.setValue(attentionPlaceId);
    this.locationForm.markAsDirty();
  }

  public findElement(filterId: number, list: TableKeyItem[]): TableKeyItem | undefined {
    return list.find((value) => value.id === filterId);
  }

  public setAnonymous(evt: any) {
    evt.stopPropagation();
    if (this.data.disabled) return;
    this.isAnonymous = !this.isAnonymous;
    if (this.isAnonymous) {
      this.callerForm.get('callerFirstName')?.setValue('NN');
      this.callerForm.get('callerFirstName')?.disable();
      this.callerForm.get('callerSecondName')?.disable();
      this.callerForm.get('callerLastName')?.disable();
      this.callerForm.get('callerSecondLastName')?.disable();
    } else {
      this.callerForm.get('callerFirstName')?.setValue('');
      this.callerForm.get('callerFirstName')?.enable();
      this.callerForm.get('callerSecondName')?.enable();
      this.callerForm.get('callerLastName')?.enable();
      this.callerForm.get('callerSecondLastName')?.enable();
    }
    this.callerForm.get('callerSecondName')?.setValue('');
    this.callerForm.get('callerLastName')?.setValue('');
    this.callerForm.get('callerSecondLastName')?.setValue('');
    this.callerForm.markAsDirty();
  }

  public async addPhoneNumber() {
    if (this.data.disabled) return;
    const phoneReference = this.callerForm.get('callerPhoneNumber')!;
    if (phoneReference.value === '') {
      await this.dialogService.errorModal('EDITAR LLAMANTE', 'El campo no puede estar vacío');
      return;
    }
    if (phoneReference.invalid) {
      await this.dialogService.errorModal(
        'EDITAR LLAMANTE',
        'El campo para agregar un teléfono debe tener entre 7 y 10 caracteres de longitud'
      );
      return;
    }
    if (this.addedPhones.length === 2) {
      await this.dialogService.errorModal(
        'EDITAR LLAMANTE',
        'Máximo se pueden agregar 2 teléfonos'
      );
      return;
    }
    this.callerForm.markAsDirty();
    this.addedPhones.push(phoneReference.value);
    this.callerForm.get('callerPhoneNumber')?.setValue('');
  }

  async generateText(type: string, fromButton: boolean = true) {
    if (this.data.disabled) return;
    switch (type) {
      case 'caller':
        const addedPhones: string = this.addedPhones.join(', ');
        const mentalHealth: boolean =
          this.incident.incidentType?.code === '941' ||
          this.incident.incidentType?.code === '918' ||
          this.incident.incidentType?.code === '611' ||
          this.incident.incidentType?.code === '609';
        const callerBody: {
          [k: string]: any;
        } = {
          LLAMANTE: {
            'PRIMER NOMBRE': this.callerForm.get('callerFirstName')?.value.toUpperCase(),
            'SEGUNDO NOMBRE': this.callerForm.get('callerSecondName')?.value.toUpperCase(),
            'PRIMER APELLIDO': this.callerForm.get('callerLastName')?.value.toUpperCase(),
            'SEGUNDO APELLIDO': this.callerForm.get('callerSecondLastName')?.value.toUpperCase(),
            'TELEFONOS DEL LLAMANTE': addedPhones,
            'OBSERVACIONES DE CREACION': this.callerForm
              .get('creationComments')
              ?.value.toUpperCase(),
            'COMENTARIO PRIORITARIO': this.hasPriority ? 'ES PRIORITARIO' : 'NO ES PRIORITARIO',
          },
        };
        if (mentalHealth) {
          const relationship =
            this.callerForm.get('relationshipWithPatient')?.value === 1
              ? 'Otro'
              : this.callerForm.get('relationshipWithPatient')?.value === 2
                ? 'Vecino'
                : 'Familiar';
          callerBody['LLAMANTE']['RELACION CON EL PACIENTE'] = relationship.toUpperCase();
        }
        this.clipboard.copy(JSON.stringify(callerBody));
        break;
      case 'distribution':
        const distributeTo: string[] = [];
        if (this.distribution[0].state) distributeTo.push(this.distribution[0].value);
        if (this.distribution[1].state) distributeTo.push(this.distribution[1].value);
        if (this.distribution[2].state) distributeTo.push(this.distribution[2].value);
        const body =
          'Distribuir a: ' +
          (distributeTo.length > 0 ? distributeTo.join(', ') : 'No ha seleccionado ninguna opción');
        this.clipboard.copy(body);
        break;
      case 'location':
        const location = {
          LOCALIZACION: {
            DIRECCION: '',
            'COMPLEMENTO DIRECCION': '',
            BARRIO: '',
            LOCALIDAD: '',
            ZONIFICACION: '',
            ENTORNO: '',
            'ATENCION EN': '',
            'PUNTO DE REFERENCIA': '',
          },
        };
        const locality: string | undefined = this.locations.find(
          (location) => location.id === this.locationForm.get('locality')?.value
        )?.title;
        const zoneName: string | undefined = this.zones.find(
          (zone) => zone.id === this.locationForm.get('zone')?.value
        )?.title;
        const zoneDhName: string =
          this.locationForm.get('zonesDh')?.value === 'rural'
            ? 'RURAL'
            : this.locationForm.get('zonesDh')?.value === 'urban'
              ? 'URBANO'
              : '';
        const attentionPlace: string | undefined = this.attention.find(
          (place) => place.id === this.locationForm.get('attentionPlace')?.value
        )?.title;

        location.LOCALIZACION.DIRECCION =
          this.locationForm.get('incidentAddress')?.value === ''
            ? 'SIN INFORMACIÓN'
            : this.locationForm.get('incidentAddress')?.value.toUpperCase();
        location.LOCALIZACION['COMPLEMENTO DIRECCION'] =
          this.locationForm.get('addressComplement')?.value === ''
            ? 'SIN INFORMACIÓN'
            : this.locationForm.get('addressComplement')?.value.toUpperCase();
        location.LOCALIZACION.LOCALIDAD = locality ? locality.toUpperCase() : 'SIN INFORMACIÓN';
        location.LOCALIZACION['ATENCION EN'] = attentionPlace
          ? attentionPlace.toUpperCase()
          : 'SIN INFORMACIÓN';
        location.LOCALIZACION['PUNTO DE REFERENCIA'] =
          this.locationForm.get('referencePoint')?.value === ''
            ? 'SIN INFORMACIÓN'
            : this.locationForm.get('referencePoint')?.value.toUpperCase();
        location.LOCALIZACION.ENTORNO = zoneDhName === '' ? 'SIN INFORMACIÓN' : zoneDhName;
        location.LOCALIZACION.BARRIO =
          this.locationForm.get('neighborhood')?.value === ''
            ? 'SIN INFORMACIÓN'
            : this.locationForm.get('neighborhood')?.value.toUpperCase();
        location.LOCALIZACION.ZONIFICACION = zoneName ? zoneName.toUpperCase() : 'SIN INFORMACIÓN';
        this.clipboard.copy(JSON.stringify(location));
        break;
    }
    if (fromButton)
      await this.dialogService.successModal(
        'Se ha copiado el texto en el portapapeles',
        'EDITAR ' +
          (type === 'distribution'
            ? 'DISTRIBUCIÓN'
            : type === 'caller'
              ? 'LLAMANTE'
              : 'LOCALIZACIÓN')
      );
  }

  async cancelEdition(type: string, force = false) {
    if (this.data.disabled && !force) return;
    let forceClose: boolean | string | undefined = true;
    if (!force) {
      switch (type) {
        case 'caller':
          if (this.callerForm.dirty) {
            forceClose = await this.dialogService.yesNoOptionModal(
              'EDITAR LLAMANTE',
              '¿Está seguro de cancelar? Los cambios realizados no serán guardados',
              'cancel'
            );
          }
          break;
        case 'distribution':
          if (this.changedDistribution) {
            forceClose = await this.dialogService.yesNoOptionModal(
              'EDITAR DISTRIBUCIÓN',
              '¿Está seguro de cancelar? Los cambios realizados no serán guardados',
              'cancel'
            );
          }
          break;
        case 'location':
          if (this.locationIsUpdated) {
            // TODO pending
          }

          if (this.locationForm.dirty) {
            forceClose = await this.dialogService.yesNoOptionModal(
              'EDITAR LOCALIZACIÓN',
              '¿Está seguro de cancelar? Los cambios realizados no serán guardados',
              'cancel'
            );
          }
          break;
      }
    }
    if (forceClose && forceClose !== 'cancelled') {
      if (type === 'location') {
        await this.incidentService.unlockUpdateLocality(
          this.data.incident.id,
          this.profileService.selectedRole?.id!
        );
      }
      this.incidentService.tabs.removeActiveMethod();
      this.openModal = false;
      this.dialogRef.close(this.incident);
    }
  }

  public async submitEdition(type: string) {
    if (!this.saveCanEdit) return;
    if (this.data.disabled || this.saving) return;
    this.saving = true;
    switch (type) {
      case 'caller':
        if (
          !(await this.permissionsValidator.hasAccessToSection(['incident_requester_confirm_u']))
        ) {
          await this.dialogService.unauthorizedErrorModal(this.profileService.selectedRole!);
          return;
        }
        this.callerFormTouched = true;
        if (this.callerForm.dirty) {
          if (this.callerForm.invalid) {
            if (
              this.callerForm.get('callerFirstName')?.hasError('pattern') ||
              this.callerForm.get('callerSecondName')?.hasError('pattern') ||
              this.callerForm.get('callerLastName')?.hasError('pattern') ||
              this.callerForm.get('callerSecondLastName')?.hasError('pattern')
            ) {
              await this.dialogService.errorModal(
                'EDITAR LLAMANTE',
                'Por favor ingrese una información válida, el campo no recibe números'
              );
            } else if (this.callerForm.get('callerPhoneNumber')?.invalid) {
              await this.dialogService.errorModal(
                'EDITAR LLAMANTE',
                'El campo para agregar un teléfono debe tener entre 7 y 10 caracteres de longitud'
              );
            } else {
              await this.dialogService.infoSubtitledModal(
                'EDITAR LLAMANTE',
                'Faltan los siguientes campos por diligenciar',
                'Primer nombre,'
              );
            }
          } else {
            if (
              this.addedPhones.length < 1 &&
              this.callerForm.get('callerPhoneNumber')?.value !== ''
            ) {
              await this.addPhoneNumber();
            }
            const callerFirstName = this.callerForm.get('callerFirstName')?.value;
            const callerSecondName = this.callerForm.get('callerSecondName')?.value;
            const callerLastName = this.callerForm.get('callerLastName')?.value;
            const callerSecondLastName = this.callerForm.get('callerSecondLastName')?.value;
            const relationshipWithPatient = this.callerForm.get('relationshipWithPatient')?.value;
            const creationComments = this.callerForm.get('creationComments')?.value;
            const body: RequesterUpdateInput['data'] = {
              firstName: callerFirstName,
              secondName: callerSecondName !== '' ? callerSecondName : '',
              lastName: callerLastName !== '' ? callerLastName : '',
              secondLastName: callerSecondLastName !== '' ? callerSecondLastName : '',
              relationWithPatient:
                relationshipWithPatient !== ''
                  ? this.relationshipWithPatient.find(
                      (relationship) =>
                        relationship.id === this.callerForm.get('relationshipWithPatient')!.value
                    )!.name
                  : undefined,
              observations: creationComments !== '' ? creationComments : undefined,
              observationIsPriority: this.hasPriority,
              cellPhone: this.addedPhones,
            };
            try {
              const updatedRequester: Requester = await this.incidentService.updateCaller(
                body,
                this.incident.id,
                this.incident.requester!.id
              );
              await this.generateText('caller', false);
              await this.dialogService.successModal(
                'La información fue actualizada exitosamente y guardada en el portapapeles',
                'EDITAR LLAMANTE'
              );
              this.incidentService.activeIncidentLogs?.getAllLogs();
              this.incident.requester = updatedRequester;
              this.incident.requester!.contactPoints = updatedRequester.contactPoints?.filter(
                (cp) => cp
              );
              this.callerIsUpdated = true;
              this.hasSaved = true;
              this.resetCallerForm();
              //this.dialogRef.close();
            } catch (e: any) {
              await this.dialogService.errorModal(
                'EDITAR LLAMANTE',
                'Ocurrió un error actualizando la información del llamante'
              );
              console.error(e);
            }
          }
        } else {
          await this.dialogService.successModal(
            'No se hizo ningún cambio',
            'EDITAR LLAMANTE',
            'info',
            true
          );
        }
        break;
      case 'distribution':
        if (
          !(await this.permissionsValidator.hasAccessToSection(['incident_distribution_confirm_u']))
        ) {
          await this.dialogService.unauthorizedErrorModal(this.profileService.selectedRole!);
          return;
        }
        this.distributionError =
          this.distribution.filter((dist) => dist.state).map((dist) => dist.id).length === 0;
        if (this.distributionError) {
          this.saving = false;
          return;
        }
        if (this.changedDistribution) {
          try {
            const body: IncidentRoleUpdateInput['data'] = {
              rolesIds: this.distribution.filter((dist) => dist.state).map((dist) => dist.id),
            };
            this.incident = await this.incidentService.updateDistribution(body, this.incident.id);
            // await this.generateText('distribution', false);
            await this.dialogService.successModal(
              'La información incidente ha sido actualizado',
              'EDITAR DISTRIBUCIÓN'
            );
            this.incidentService.tabs.removeActiveMethod();
            this.hasSaved = true;
            this.dialogRef.close(this.incident);
          } catch (e) {
            await this.dialogService.errorModal(
              'EDITAR DISTRIBUCIÓN',
              'Ocurrió un error al editar la distribución'
            );
            console.error(e);
          }
        } else {
          await this.dialogService.successModal(
            'No se hizo ningún cambio',
            'EDITAR DISTRIBUCIÓN',
            'info',
            true
          );
        }
        break;
      case 'location':
        this.locationFormTouched = true;
        if (this.locationForm.dirty) {
          if (this.locationForm.invalid) {
            await this.validateRequired();
          } else {
            if (this.locationForm.get('incidentAddress')?.value === '') {
              this.incidentLocationData = undefined;
            }
            const incidentAddress =
              this.locationForm.get('incidentAddress')?.value === ''
                ? null
                : this.locationForm.get('incidentAddress')?.value;
            const addressComplement =
              this.locationForm.get('addressComplement')?.value === ''
                ? null
                : this.locationForm.get('addressComplement')?.value;
            const locality =
              this.locationForm.get('locality')?.value === ''
                ? null
                : this.locationForm.get('locality')?.value;
            const attentionPlace =
              this.locationForm.get('attentionPlace')?.value === ''
                ? null
                : this.locationForm.get('attentionPlace')?.value;
            const referencePoint =
              this.locationForm.get('referencePoint')?.value === ''
                ? null
                : this.locationForm.get('referencePoint')?.value;
            const zonesDh =
              this.locationForm.get('zonesDh')?.value === ''
                ? undefined
                : this.locationForm.get('zonesDh')?.value;
            const neighborhood =
              this.locationForm.get('neighborhood')?.value === ''
                ? null
                : this.locationForm.get('neighborhood')?.value;
            const zone =
              this.locationForm.get('zone')?.value === ''
                ? null
                : this.locationForm.get('zone')?.value;
            const body: IncidentLocalityUpdateInput['data'] = {
              address: incidentAddress,
              addressComplement: addressComplement,
              attendanceAtId: attentionPlace,
              environment: zonesDh,
              addressReferencePoint: referencePoint,
              district: neighborhood,
              zoneId: zone,
              localityId: locality,
              geolocation: this.incidentLocationData
                ? [this.incidentLocationData.lng, this.incidentLocationData.lat]
                : undefined,
            };
            try {
              this.incident = await this.incidentService.updateLocality(body, this.incident.id);
              await this.generateText('location', false);
              await this.dialogService.successModal(
                'La información fue actualizada exitosamente y guardada en el portapapeles',
                'EDITAR LOCALIZACIÓN'
              );
              this.locationIsUpdated = true;
              this.hasSaved = true;
              this.resetLocationForm();
              //this.dialogRef.close();
            } catch (e: any) {
              const transformedError: ServerError = e as ServerError;
              const error: string = transformedError.error.error;
              let errorMessage: string =
                'Ocurrió un error actualizando la información de la localización';
              if (error) {
                if (error === 'cannot create incidents outside bogota dc') {
                  errorMessage = 'No se pueden crear incidentes fuera de Bogotá DC';
                }
              }
              await this.dialogService.errorModal('EDITAR LOCALIZACIÓN', errorMessage);
              console.error(e);
            }
          }
        } else if (this.locationForm.invalid) {
          await this.validateRequired();
        } else {
          await this.dialogService.successModal(
            'No se hizo ningún cambio',
            'EDITAR LOCALIZACIÓN',
            'info',
            true
          );
        }
        break;
    }
    this.saving = false;
  }

  public async validateRequired() {
    let uncompletedList: string = '';
    if (this.locationForm.invalid) {
      if (
        this.locationForm.get('referencePoint')!.invalid &&
        this.locationForm.get('locality')!.invalid
      ) {
        uncompletedList = 'Localidad, Punto de referencia';
      } else if (this.locationForm.get('locality')!.invalid) {
        uncompletedList = 'Localidad';
      } else if (this.locationForm.get('referencePoint')!.invalid) {
        uncompletedList = 'Punto de referencia';
      }
    }
    if (uncompletedList.length > 0) {
      await this.dialogService.infoSubtitledModal(
        'EDITAR LOCALIZACIÓN',
        'Hay errores en los campos',
        uncompletedList
      );
      this.focusRequiredField(uncompletedList);
    }
  }

  private focusRequiredField(field: string) {
    switch (field) {
      case 'Localidad':
        this.locality.nativeElement.focus();
        break;
      case 'Punto de referencia':
        this.referencePoint.nativeElement.focus();
        break;
    }
  }

  deletePhone(ind: number) {
    this.addedPhones.splice(ind, 1);
    this.callerForm.markAsDirty();
  }

  public priorityComment(evt: any) {
    evt.stopPropagation();
    if (this.data.disabled) return;
    this.hasPriority = !this.hasPriority;
    this.callerForm.markAsDirty();
  }

  changeDistribution(i: number) {
    this.distribution[i].state = !this.distribution[i].state;
    this.changedDistribution = true;
  }

  validateNumber(field: string) {
    const input = this.callerForm.get(field);
    input?.setValue(input?.value.replace(/\D/g, ''));
  }

  resetCallerForm() {
    this.callerForm = new FormGroup({
      callerFirstName: new FormControl('', [
        Validators.required,
        Validators.pattern(this.validatorsService.alphabeticWithAccentsValidator),
      ]),
      callerSecondName: new FormControl('', [
        Validators.pattern(this.validatorsService.alphabeticWithAccentsValidator),
      ]),
      callerLastName: new FormControl('', [
        Validators.pattern(this.validatorsService.alphabeticWithAccentsValidator),
      ]),
      callerSecondLastName: new FormControl('', [
        Validators.pattern(this.validatorsService.alphabeticWithAccentsValidator),
      ]),
      relationshipWithPatient: new FormControl({
        value: '',
        disabled: true,
      }),
      callerPhoneNumber: new FormControl('', [
        Validators.minLength(7),
        Validators.maxLength(10),
        Validators.pattern(this.validatorsService.numberValidator),
      ]),
      creationComments: new FormControl(''),
    });
    const requester = this.incident.requester;
    if (!requester) return;
    this.isAnonymous = requester.firstName === 'NN';
    const relationship = this.relationshipWithPatient.find(
      (relationship) =>
        relationship.name.toLowerCase() === requester.relationWithPatient?.toLowerCase()
    )?.id;
    this.callerForm.get('callerFirstName')?.setValue(this.isAnonymous ? 'NN' : requester.firstName);
    if (this.isAnonymous) {
      this.callerForm.get('callerFirstName')?.disable();
      this.callerForm.get('callerSecondName')?.disable();
      this.callerForm.get('callerLastName')?.disable();
      this.callerForm.get('callerSecondLastName')?.disable();
    }
    this.callerForm.get('callerSecondName')?.setValue(requester.secondName ?? '');
    this.callerForm.get('callerLastName')?.setValue(requester.lastName ?? '');
    this.callerForm.get('callerSecondLastName')?.setValue(requester.secondLastName ?? '');
    this.callerForm.get('relationshipWithPatient')?.setValue(relationship ?? '');
    this.callerForm.get('creationComments')?.setValue(requester.observations ?? '');
    const cp = requester.contactPoints?.filter((phone) => phone.value !== undefined) ?? [];
    this.addedPhones = requester.contactPoints ? [...cp.map((phone) => phone.value)] : [];
    this.hasPriority = requester.observationIsPriority;
  }

  resetLocationForm() {
    this.locationForm = new FormGroup({
      incidentAddress: new FormControl(''),
      addressComplement: new FormControl(''),
      locality: new FormControl('', Validators.required),
      attentionPlace: new FormControl(''),
      referencePoint: new FormControl('', Validators.required),
      zonesDh: new FormControl(''),
      neighborhood: new FormControl(''),
      zone: new FormControl(1),
    });
    if (!this.selectMapLocationComponent.address.isBogota) {
      this.locationForm.get('locality')?.disable();
      this.locationForm.get('zone')?.setValue(1);
    } else {
      this.locationForm.get('locality')?.enable();
    }
    this.locationForm.get('incidentAddress')?.setValue(this.incident.address ?? '');
    this.locationForm.get('addressComplement')?.setValue(this.incident.addressComplement ?? '');
    this.locationForm.get('locality')?.setValue(this.incident.localityId ?? '');
    this.locationForm.get('attentionPlace')?.setValue(this.incident.attendanceAt?.id ?? '');
    this.locationForm.get('referencePoint')?.setValue(this.incident.addressReferencePoint ?? '');
    this.locationForm.get('zonesDh')?.setValue(this.incident.environment ?? '');
    this.locationForm.get('neighborhood')?.setValue(this.incident.district ?? '');
    this.locationForm.get('zone')?.setValue(this.incident.zone?.id ?? '');
  }

  async updateAddress() {
    try {
      if (!this.canUpdateDirection) {
        this.canUpdateDirection = true;
        return;
      }
      if (!this.saveCanEdit) return;
      if (this.locationForm) {
        this.incidentLocationData = undefined;
        this.locationForm
          .get('incidentAddress')
          ?.setValue(this.selectMapLocationComponent.address.address);
        this.locationForm.markAsDirty();
        if (
          this.selectMapLocationComponent.address.location?.lat &&
          this.selectMapLocationComponent.address.location?.lng
        ) {
          this.incidentLocationData = {
            lat: this.selectMapLocationComponent.address.location.lat,
            lng: this.selectMapLocationComponent.address.location.lng,
          };
          const info = await this.geocoderService.getLocationCompleteInfo(
            this.selectMapLocationComponent.address.location.lng,
            this.selectMapLocationComponent.address.location.lat
          );

          this.locationForm
            .get('neighborhood')
            ?.setValue(info.neighborhood !== null ? info.neighborhood : '');

          this.locationForm.get('zone')?.setValue('');

          if (info.zone === 'N') this.locationForm.get('zone')?.setValue(1);
          if (info.zone === 'S') this.locationForm.get('zone')?.setValue(2);

          if (info.localityId !== null) {
            const locality = this.locations.find(
              (element) => element.id === parseInt(info.localityId)
            );
            if (locality) this.locationForm.get('locality')?.setValue(locality.id);
          } else {
            this.locationForm.get('locality')?.setValue('');
          }
          if (!this.selectMapLocationComponent.address.isBogota) {
            this.locationForm.get('locality')?.disable();
          } else {
            this.locationForm.get('locality')?.enable();
          }
        } else {
          console.error('No se encontraron resultados para la dirección.');
        }
      }
    } catch (error) {
      console.error(error);
    }
  }
}
