interface CacheCrudServiceOptions<T> {
  debug: boolean;
  /**
   * perform CRON operation to fetch data before expiration, will perform at 90% of cache expiration
   */
  autoRefresh: boolean;
  /**
   * Value in MS. Negative expiration value means no expiration
   */
  cacheExpiration: number;
  /**
   * false by default, if true, will sort data in getAll() based in its id, can also receive a custom sort function
   */
  useSort: boolean;
  /**
   * custom sort function
   */
  sortRoutine: (a: T, b: T) => number;
}

/**
 * Generic service implementation for standard CRUD
 * T type represents Model handled by the service
 * C type represents input interface for POST operation
 * U type represents input interface for PUT/PATCH operation
 */
export abstract class CachedCRUDService<
  T extends { id: I },
  C,
  U,
  I extends number | string = number,
> {
  private _fetchPromise: Promise<T[]> | null;

  private readonly _id: number;

  private _options: CacheCrudServiceOptions<T> = {
    debug: false,
    autoRefresh: false,
    cacheExpiration: 1000 * 60 * 10, // in ms
    useSort: false,
    sortRoutine: this._defaultSort,
  };

  private _internalCronData = {
    timer: <NodeJS.Timer | null>null,
    running: false,
  };

  /**
   * data stored in service
   * @private
   */
  private _data: {
    data: T[];
    dataAsObject: Record<I, T>;
    lastDataTime: number;
  } = { data: [], dataAsObject: {} as Record<I, T>, lastDataTime: 0 };

  protected constructor() {
    this._id = Date.now();
  }

  public isFetching(): boolean {
    return !!this._fetchPromise;
  }

  private async internalCronRoutine() {
    if (!this.isCacheValid(true) && !this._internalCronData.running) {
      this._internalCronData.running = true;
      await this.fillCache();
      this._internalCronData.running = false;
    }
  }

  private validateCronStatus() {
    let shouldAutoRefresh = false;
    if (this.options.cacheExpiration > 0 && this.options.autoRefresh) {
      shouldAutoRefresh = true;
    }
    if (!shouldAutoRefresh && this._internalCronData.timer) {
      clearInterval(this._internalCronData.timer);
      this._internalCronData.timer = null;
    } else if (shouldAutoRefresh && !this._internalCronData.timer) {
      this._internalCronData.timer = setInterval(this.internalCronRoutine, 1000 * 30);
    }
  }

  public get options(): CacheCrudServiceOptions<T> {
    this.debugLog('get options:', this._options);
    return this._options;
  }

  public set options(input: Partial<CacheCrudServiceOptions<T>>) {
    if (input.debug) {
      this.debugLog('Changed debug to:', input.debug);
      this._options.debug = input.debug;
    }
    if (input.cacheExpiration) {
      if (input.cacheExpiration < 1000 * 60) {
        console.warn('Cache lower than 1 min not allowed, forcing to 1min');
        input.cacheExpiration = 1000 * 60;
      }
      this.debugLog('Changed cacheExpiration to:', input.cacheExpiration);
      this._options.cacheExpiration = input.cacheExpiration;
    }
    if (input.autoRefresh) {
      this.debugLog('Changed autoRefresh to:', input.autoRefresh);
      this._options.autoRefresh = input.autoRefresh;
    }
    if (input.useSort !== undefined) {
      this.debugLog('Changed useSort to:', input.useSort);
      this._options.useSort = input.useSort;
    }
    if (input.sortRoutine) {
      this.debugLog('Changed sortRoutine to:', input.sortRoutine);
      this._options.sortRoutine = input.sortRoutine;
    }
    this.validateCronStatus();
  }

  /**
   * Restores default sort routine
   */
  public restoreDefaultSort() {
    this._options.sortRoutine = this._defaultSort;
  }

  private _defaultSort(a: T, b: T) {
    if (a.id < b.id) {
      return -1;
    }
    if (a.id > b.id) {
      return 1;
    }
    // names must be equal
    return 0;
  }

  /**
   * returns true if cache valid
   * @param isCRON pass true to perform validation using CRON time, which is 90% of expiration
   */
  public isCacheValid(isCRON = false) {
    let validation: boolean;
    if (this.options.cacheExpiration < 0) {
      validation = this._data.lastDataTime !== 0;
    } else {
      validation =
        Date.now() - this._data.lastDataTime <
        (isCRON ? this.options.cacheExpiration * 0.9 : this.options.cacheExpiration); // validation for non expiring cache to allow first time
    }
    this.debugLog('isCacheValid:', validation);
    this.debugLog('lastDataTime:', this._data.lastDataTime);
    this.debugLog('cacheExpiration:', this.options.cacheExpiration);
    return validation;
  }

  public flushCache() {
    this.debugLog('clearCache:');
    this._data = { data: [], dataAsObject: {} as Record<I, T>, lastDataTime: 0 };
  }

  /**
   * removes id from array, only removes from data array, will not perform delete api, useful for invalidating specific item
   * @param id
   */
  public removeId(id: I) {
    this.debugLog('removeId:', id);
    this._data.data = this._data.data.filter((value) => value.id !== id);
  }

  protected async _fetchNewData(): Promise<T[]> {
    throw new Error(
      "Not Implemented, must override class member '_fetchNewData' with fetch data api implementation (HTTP, WebSockets, etc)"
    );
  }

  private async fillCache() {
    const response = await this._fetchNewData();
    this.debugLog('fillCache: response', response, '_data');
    this._data.data = [];
    this._data.lastDataTime = Date.now();
    response.forEach((data) => this._data.data.push(data));
    for (const item of response) {
      this._data.dataAsObject[item.id] = item;
    }
  }

  /**
   * return cached data as Record<id (number), T>, must call warm/getAll to populate
   */
  public returnDataAsObject() {
    return this._data.dataAsObject;
  }

  public async getAll(options?: { overrideSortRoutine: (a: T, b: T) => number }): Promise<T[]> {
    this.debugLog('getAll: _fetchPromise', this._fetchPromise, '_data', this._data);
    if (!this._fetchPromise) {
      this._fetchPromise = new Promise(async (resolve, reject) => {
        try {
          if (!this.isCacheValid()) {
            await this.fillCache();
          }
          // returns copy to keep data integrity
          const copy: T[] = JSON.parse(JSON.stringify(this._data.data));
          if (options?.overrideSortRoutine || this._options.useSort) {
            copy.sort(options?.overrideSortRoutine || this._options.sortRoutine);
          }
          resolve(copy);
        } catch (e) {
          reject(e);
        }
        setTimeout(() => {
          this._fetchPromise = null;
        }, 1);
      });
    }
    return this._fetchPromise;
  }

  protected async _getByIdData(_id: I): Promise<T | undefined> {
    throw new Error(
      "Not Implemented, must override class member '_getByIdData' with get by id data api implementation (HTTP, WebSockets, etc)"
    );
  }

  /**
   * returns element by its id
   * @param id
   * @param ignoreCache the default behaviour will search the element in cache data, if not found, will perform _getByIdData to find id, if want to ignore cache, or re fetch the element because _getByIdData have more data set to true
   */
  public async getById(id: I, ignoreCache = false): Promise<T | undefined> {
    this.debugLog('getById:', id);
    if (!ignoreCache) {
      if (!this.isCacheValid()) {
        await this.getAll(); // update cache
      }
      const result: T | undefined = this._data.data.find((value) => value.id === id);
      if (result) {
        return result;
      }
    }
    // if not found, let's attempt to fetch it from API
    try {
      const fetchAttempt = await this._getByIdData(id);
      if (fetchAttempt) {
        if (ignoreCache) {
          // if ignoreCache true, maybe possible to be in _data, remove to avoid duplication
          this.removeId(id);
        }
        this._data.data.push(fetchAttempt);
        return fetchAttempt;
      }
    } catch (e: unknown) {
      if ((<{ status: number | undefined }>e)?.status !== 404) {
        console.error(e);
      }
    }
    //
    return undefined;
  }

  protected async _putData(_id: I, _input: U): Promise<T> {
    throw new Error(
      "Not Implemented, must override class member '_putData' with put data api implementation (HTTP, WebSockets, etc)"
    );
  }

  public async put(id: I, input: U): Promise<T> {
    this.debugLog('put: id', id, 'input', input);
    const result = await this._putData(id, input);
    this._data.data = this._data.data.map((value) => {
      if (value.id === id) {
        return result;
      } else {
        return value;
      }
    });
    return result;
  }

  protected async _postData(_input: C): Promise<T> {
    throw new Error(
      "Not Implemented, must override class member '_postData' with post data api implementation (HTTP, WebSockets, etc)"
    );
  }

  public async post(input: C): Promise<T> {
    this.debugLog('post:', input);
    const result = await this._postData(input);
    this._data.data.push(result);
    return result;
  }

  protected _deleteData(_id: I): Promise<void> {
    throw new Error(
      "Not Implemented, must override class member '_deleteData' with delete data api implementation (HTTP, WebSockets, etc)"
    );
  }

  public async delete(id: I): Promise<void> {
    this.debugLog('delete:', id);
    await this._deleteData(id);
    this._data.data = this._data.data.filter((value) => value.id !== id);
  }

  protected async _warm(): Promise<void> {
    await this.getAll();
  }

  /**
   * Warms cache, by default will perform getAll to warm cache. Can be overwritten by overwriting _warm method
   */
  public async warm(): Promise<void> {
    this.debugLog('warm');
    await this._warm();
  }

  private debugLog(...data: unknown[]) {
    if (this._options.debug) {
      console.warn(`[(ID: ${this._id}) CacheService DEBUG]`, data);
    }
  }
}
