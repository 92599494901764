import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { SearchResult } from '@smartsoft-types/sisem-incidents';
import { environment } from '../../../../../environments/environment';
import { lastValueFrom, map } from 'rxjs';
import { DatePipe } from '@angular/common';
import { ServerResponse } from 'src/app/shared/types/http.interfaces';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {
  AssociateVehicle,
  IAssociateHistoricResourceModelBack,
  IConfigSisem,
  IHistoricManageResource,
  IManageDeviceBodyFilter,
  IManageDeviceTabData,
  ImanageResourceModelBack,
  IResourceConfig,
  IResourceDevice,
} from '../../../types/providers/manage-device-resource.interfaces';
import {
  ParametricService,
  TabActionType,
  TabData,
} from '../../../types/parametric.service.abstract.class';
import { ProfileService } from '../../profile/profile.service';
import { KeyPressedHandlerService } from '../../key-pressed-handler.service';
import { Router } from '@angular/router';
import { LoadingService } from '../../loading.service';
import { TableKeyItem } from '../../../types/table';

@Injectable({
  providedIn: 'root',
})
export class ManageDeviceResourceService extends ParametricService<ManageDeviceTabData, unknown> {
  dataForEdit: IManageDeviceTabData = {} as IManageDeviceTabData;
  resourceManageTable: TableKeyItem[] = [];

  constructor(
    private http: HttpClient,
    private datePipe: DatePipe,
    private router: Router,
    private loadingService: LoadingService,
    profileService: ProfileService,
    keyPressedHandlerService: KeyPressedHandlerService
  ) {
    super(keyPressedHandlerService, profileService);
  }

  async getManageDeviceResourceList(
    body: IManageDeviceBodyFilter,
    query?: { [p: string]: string | number | boolean | undefined }
  ): Promise<SearchResult<ManageDeviceResourceAdapter[]>> {
    const url = `${environment.mobileUrl}sisem-core-api/v1/device`;

    const response = await lastValueFrom(
      this.http
        .post<SearchResult<ImanageResourceModelBack[]>>(url, body, {
          params: query ? this.getParams(query) : undefined,
        })
        .pipe(
          map((value: any) => ({
            result: value.data,
            pagination: {
              totalItems: value.total_items,
              itemsPerPage: query?.['size'],
              totalPages: value.total_pages,
              currentPage: value.curren_page,
            },
          }))
        )
    );
    const adapterData: ManageDeviceResourceAdapter[] = response?.result.map(
      (value: any) =>
        new ManageDeviceResourceAdapter(value as ImanageResourceModelBack, this.datePipe)
    );
    return { ...response, result: adapterData } as SearchResult<ManageDeviceResourceAdapter[]>;
  }

  getParams(queries: { [p: string]: string | number | boolean | undefined }): HttpParams {
    const items = Object.keys(queries);
    let params = new HttpParams();
    items.forEach((p) => {
      const query = queries[p];
      if (query !== undefined && query !== '') {
        params = params.set(p, query);
      }
    });
    return params;
  }

  async saveDate(body: ImanageResourceModelBack) {
    return await lastValueFrom(
      this.http.put(`${environment.mobileUrl}/sisem-core-api/v1/device`, body)
    );
  }

  async associateVehicle(body: AssociateVehicle) {
    return await lastValueFrom(
      this.http.post(`${environment.mobileUrl}/sisem-core-api/v1/vehicle`, body)
    );
  }

  async updateDate(idResource: number, id: number) {
    return await lastValueFrom(
      this.http.put(`${environment.mobileUrl}/sisem-core-api/v1/device/${id}/${idResource}`, {})
    );
  }

  async getResources(): Promise<IResourceConfig[]> {
    const res: ServerResponse<IConfigSisem[]> = await lastValueFrom(
      this.http.get<ServerResponse<IConfigSisem[]>>(
        `${environment.mobileUrl}/sisem-core-api/v1/config?page=0&size=99999&order=asc&sortBy=id`
      )
    );
    return (
      res.data
        ?.filter((resource: IConfigSisem) => resource.status)
        .map((resource: IConfigSisem) => ({
          idResource: resource?.id,
          typeResource: resource?.type_resource,
          status: resource.status,
        })) || []
    );
  }

  async getResourceById(id: string): Promise<IResourceConfig[]> {
    const res: ServerResponse<IConfigSisem[]> = await lastValueFrom(
      this.http.get<ServerResponse<IConfigSisem[]>>(
        `${environment.mobileUrl}/sisem-core-api/v1/config?page=0&size=99999&order=asc&sortBy=id&id=${id}`
      )
    );
    return (
      res.data
        ?.filter((resource: IConfigSisem) => resource.status)
        .map((resource: IConfigSisem) => ({
          idResource: resource.id,
          typeResource: resource.type_resource,
          status: resource.status,
        })) || []
    );
  }

  async getResourceByType(resourceType: string): Promise<IResourceConfig[]> {
    const res: ServerResponse<IConfigSisem[]> = await lastValueFrom(
      this.http.get<ServerResponse<IConfigSisem[]>>(
        `${environment.mobileUrl}/sisem-core-api/v1/config?page=0&size=99999&order=asc&sortBy=typeResource&id=${resourceType}`
      )
    );
    return (
      res.data
        ?.filter((resource: IConfigSisem) => resource.status)
        .map((resource: IConfigSisem) => ({
          idResource: resource.id,
          typeResource: resource.type_resource,
          status: resource.status,
        })) || []
    );
  }

  async changeStatus(body: { status: boolean; id: number }) {
    return await lastValueFrom(
      this.http.post(`${environment.mobileUrl}/sisem-core-api/v1/device/status`, body)
    );
  }

  override async newTab(
    actionType: TabActionType,
    options?: {
      id: number;
      data?: {
        resourcesManage: IResourceDevice[];
        resourcesManageTable: TableKeyItem[];
        data: ImanageResourceModelBack | undefined;
        formManageDevice: FormGroup;
      };
    }
  ): Promise<void> {
    this.loadingService.setParametricLoading(true);
    let title: string = '';
    let icon = '';
    if (actionType === TabActionType.CREATE) {
      title = 'NUEVO';
      icon = 'add-circle';
    } else if (actionType === TabActionType.EDIT) {
      title = 'EDITAR';
      icon = 'edit-outline';
    }
    const tabId = ++this._tabIdCounter;
    if (options) {
      const tabData: TabData<ManageDeviceTabData> = {
        id: tabId,
        edition: false,
        createdByIp: '',
        createdDate: [''],
        creationInfoExpanded: false,
        createdBy: '',
        actionType,
        title,
        keysSubscriptionName: '',
        icon,
        tabData: {
          id: options.id,
          data: options.data,
          form: this.dataForEdit.formManageDevice,
        },
      };
      this._tabs.push(tabData);
      this.selectTab(tabData.id);
    }
    this.loadingService.setParametricLoading(false);
  }

  override selectTab(id: number) {
    super.selectTab(id);
    this.router
      .navigateByUrl('/home/providers/manage-device-resource', {
        skipLocationChange: true,
      })
      .then(() => {
        if (this.selectedTab?.actionType === TabActionType.EDIT) {
          this.router.navigateByUrl(
            `/home/providers/manage-device-resource/form/${this.selectedTab?.tabData.id}`
          );
        } else {
          this.router.navigateByUrl('/home/providers/manage-device-resource/form');
        }
      });
  }

  override async closeTab(id: number) {
    super.closeTab(id);
    await this.router.navigateByUrl('/home/providers/manage-device-resource');
  }

  canChangeSection() {
    return this.isSafeToCloseTab(-1);
  }

  override isSafeToCloseTab(_id: number): boolean {
    return true;
  }

  initializeForms() {
    this.dataForEdit['formManageDevice'] = new FormGroup({
      id: new FormControl(null),
      typeDevice: new FormControl(null, Validators.required),
      codeResource: new FormControl('', Validators.required),
      plate: new FormControl({ value: null, disabled: true }),
      status: new FormControl({ value: null, disabled: true }),
      initialDate: new FormControl({
        value: this.datePipe.transform(new Date(), 'dd/MM/YYYY') ?? '',
        disabled: true,
      }),
      initialDateMobile: new FormControl({
        value: new Date(),
        disabled: true,
      }),
      serial: new FormControl(null, [Validators.required]),
    });
  }
}

export class ManageDeviceResourceAdapter {
  id: number;
  state?: string;
  status?: boolean;
  codeResource: string;
  idResource: number;
  plate: string;
  typeDevice: string;
  serial: string;
  initialDate: string;
  initialDateMobile: Date;
  statusColor?: string;
  dataDB?: ImanageResourceModelBack;
  actionDisable?: string[];
  preoperational?: string;
  overrideTooltip?: {
    action: string;
    message: string;
  };
  isBeingUsed?: boolean = false;
  historic?: IHistoricManageResource[];
  associate_history?: IAssociateHistoricResourceModelBack[];

  constructor(
    manageResourceAdapter: ImanageResourceModelBack,
    private datePipe: DatePipe
  ) {
    ({
      id: this.id,
      status: this.status,
      id_resource: this.idResource,
      plate: this.plate,
      type_device: this.typeDevice,
      preoperational: this.preoperational,
      serial: this.serial,
    } = manageResourceAdapter);
    this.dataDB = manageResourceAdapter;
    this.statusColor = `${this.status ? 'Activo' : 'Inactivo'}|-|${
      this.status ? 'circle-active' : 'circle-error'
    }`;
    this.codeResource = manageResourceAdapter?.code_vehicle || '-';
    this.state = `${this.status ? 'Activo' : 'Inactivo'}`;
    this.overrideTooltip = undefined;
    this.initialDate =
      this.datePipe.transform(manageResourceAdapter?.initial_date, 'dd/MM/YYYY') ?? '';
    this.initialDateMobile = new Date(manageResourceAdapter?.initial_date);
    if (manageResourceAdapter.historial?.length) {
      const historicList: IHistoricManageResource[] = [];
      manageResourceAdapter.historial.forEach((value) => {
        historicList.push({
          codeResource: value.code_vehicle,
          idResource: value.id_resource,
          date: this.datePipe.transform(value.date, 'dd/MM/YYYY') ?? '',
        });
      });
      this.historic = historicList?.slice(0, 3);
    }
    if (manageResourceAdapter.associate_history?.length) {
      this.associate_history = manageResourceAdapter.associate_history;
    }
  }
}

export interface ManageDeviceTabData {
  id?: number;
  data?: {
    resourcesManage: IResourceDevice[];
    resourcesManageTable: TableKeyItem[];
    data: ImanageResourceModelBack | undefined;
    formManageDevice: FormGroup;
  };
  form: FormGroup;
}
