<div class="custom-error-dialog" [class]="data.type === 'success' ? 'green-border' : 'red-border'">
  <div class="filter-touch-button" (click)="dialogRef.close()"><span></span></div>
  <app-svg
    name="cancel"
    class="close-icon-sd modal-icon cursor-pointer color-secondary-200"
    (click)="dialogRef.close()"
    data-cy="error-close-sd"></app-svg>

  <div class="error-data">
    <app-svg
      name="{{ data.type === 'success' ? 'check-circle' : 'cancel' }}"
      class="error-icon"
      [class]="data.type === 'success' ? 'green-icon' : 'red-icon'"></app-svg>
    <span
      class="text-m-18-white dialog-message"
      [class]="data.type === 'success' ? '' : 'message-padding'"
      data-cy="error-msg">
      <p *ngIf="!data.boldActive">{{ data.message }}</p>
      <p *ngIf="data.boldActive">
        <ng-container *ngTemplateOutlet="boldText"></ng-container>
      </p>
    </span>
  </div>
  <mat-icon
    *ngIf="data.type === 'error'"
    class="{{ data.type === 'error' ? 'close-icon' : '' }}"
    (click)="dialogRef.close()"
    data-cy="error-close"
    >close</mat-icon
  >
</div>

<ng-template #boldText>
  <span
    *ngFor="let item of data.textToDisplay"
    [class]="item.type === 'bold' ? 'text-b-18-white' : 'text-m-18-white'"
    >{{ item.text }}</span
  >
</ng-template>
