import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-geometry-properties-dialog',
  templateUrl: './geometry-properties-dialog.component.html',
  styleUrls: ['./geometry-properties-dialog.component.scss'],
})
export class GeometryPropertiesDialogComponent implements OnInit {
  dataList: { title: string; value: string }[] = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: GeometryPropertiesDialogComponent,
    private dialogRef: MatDialogRef<GeometryPropertiesDialogComponent>
  ) {}

  ngOnInit(): void {
    Object.keys(this.data).forEach((key) => {
      const data = this.data as { [k: string]: any };
      this.dataList.push({ title: key, value: data[key] || '' });
    });
  }

  closeDialog() {
    this.dialogRef.close();
  }
}
