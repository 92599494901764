<div class="flex-row-space-between dialog-header">
  <div class="filter-touch-button"><span></span></div>
  <div
    class="flex-row-start message-dialog dialog-title pl-0-5 pt-0-5 pb-0-5"
    data-cy="title-modal">
    <app-svg class="dialog-title-icon color-secondary-200" [name]="'info-message'"></app-svg>
    <p class="text-b-14-white pr-2">{{ data.title }}</p>
  </div>
  <mat-icon
    translate="no"
    class="dialog-title-icon button-close-dialog material-icons-tone"
    (click)="close()"
    data-cy="dialog-close-btn">
    highlight_off
  </mat-icon>
</div>
<div class="dialog-content-container pb-2" data-cy="table-modal">
  <div mat-dialog-content class="scrollbar">
    <app-table
      [displayedColumns]="data.displayedColumns"
      [data]="data.data"
      [pageSizeOptions]="data.pageSizeOptions"
      [pageSize]="10"
      [hidePageSize]="false"
      [txtHeaderClass]="txtHeaderClass"
      [boldBodyText]="boldBodyText"
      [showDownloadExcel]="false"
      [showDownloadPDF]="false"></app-table>
  </div>
</div>
