import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { lastValueFrom } from 'rxjs';
import { environment } from '../../../../../environments/environment';
import { ServerResponse } from '../../../types/http.interfaces';
import { CachedCRUDService } from '../../../types/cached-c-r-u-d.service';
import { ParametricsInterface } from '../../../types/parametrics.interface';

@Injectable({
  providedIn: 'root',
})
export class ResourcesSoaEntityService extends CachedCRUDService<
  ParametricsInterface,
  { title: string; description: string },
  never
> {
  constructor(private http: HttpClient) {
    super();
    this.options.cacheExpiration = -1; // should almost never change, set don't expire
  }

  readonly api = `${environment.api}/resources-ms/api/v1/parametrics/soat`;

  protected override async _fetchNewData() {
    const res: ServerResponse<ParametricsInterface[]> = await lastValueFrom(
      this.http.get<ServerResponse<ParametricsInterface[]>>(this.api)
    );
    return res.data;
  }
  protected override async _postData(input: { title: string; description: string }) {
    const resp = await lastValueFrom(
      this.http.post<ServerResponse<ParametricsInterface>>(this.api, input)
    );
    return resp.data;
  }

  async changeStatus(id: number, data: { changeStatusObservation: string; active: boolean }) {
    return await lastValueFrom(this.http.post(`${this.api}/${id}/status`, data));
  }

  protected override async _deleteData(id: number) {
    await lastValueFrom(this.http.delete(`${this.api}/${id}`));
  }
}
