import { HttpClient } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '../../../../environments/environment';
import { AuthService } from '../auth/auth.service';
import { DialogService } from '../dialog.service';
import { ServerError, ServerResponse } from '../../types/http.interfaces';
import { fromEvent, lastValueFrom } from 'rxjs';
import { Operator } from '@smartsoft-types/sisem-actors';
import { DialogInterface } from '../../types/dialog.interface';
import { MatDialogRef } from '@angular/material/dialog';
import { Role, RoleService } from '../roles-and-permissions/role.service';

export enum AccountStatus {
  IDLE = 0,
  RECOVERING = 1,
  READY = 2,
  ERROR = 3,
}

@Injectable({
  providedIn: 'root',
})
export class ProfileService {
  private _authenticatedActor?: Operator & { roles: Role[] };
  private _authenticatedActorPhotoUrl: string = '';
  private _ready: AccountStatus = AccountStatus.IDLE;
  private _ip: string;
  public selectedRole?: Role;
  public isloginLogSaved: boolean = false;
  public loggedByAzure: boolean = false;
  public isHospitalDataComplete: boolean = true;
  public isProviderContactDataComfirmed: boolean = true;

  public zone: number | undefined = undefined;

  /**
   * Return true if role changed
   */
  public changeRole: EventEmitter<boolean> = new EventEmitter<boolean>();
  public onLogout: EventEmitter<boolean> = new EventEmitter<boolean>();

  public get ip() {
    return this._ip;
  }
  public get ready() {
    return this._ready;
  }

  public get authenticatedActor() {
    return this._authenticatedActor;
  }

  getProviderId(): number {
    const regEx = /(\[|\])/g;
    if (this.authenticatedActor?.providerIds) {
      return Number(this.authenticatedActor?.providerIds.value.replaceAll(regEx, ''));
    }
    return -1;
  }

  private _lastActivity: Date = new Date();
  private _inactivityToShowModal: number = 1000 * 300; // ms 1000 * SECONDS
  private _inactivityToLogOut: number = 1000 * 600; // ms 1000 * SECONDS
  private _inactivityModal?: MatDialogRef<DialogInterface>;
  private _inactivityShowModalTime?: Date;

  get isInactive(): boolean {
    return this._inactivityModal !== undefined;
  }

  private resetInactivity(refreshAccess = true) {
    this._lastActivity = new Date();
    if (this._inactivityModal) {
      this._inactivityModal.close();
    }
    this._inactivityModal = undefined;
    this._inactivityShowModalTime = undefined;
    // keep access token active
    if (
      refreshAccess &&
      !this.authService.checkToken('ACCESS') &&
      this.authService.checkToken('REFRESH')
    ) {
      this.authService.refreshToken();
    }
  }

  private validateIfShouldLogout() {
    if (!this.authService.checkToken('REFRESH')) {
      if (this.router.url?.includes('home')) {
        console.info('Detected expired session, logging out');
        this.logOut().catch((e: unknown) => {
          console.error(e);
          localStorage.clear();
          this.router.navigateByUrl('/auth');
        });
      }
    }
  }

  public detectActivity() {
    if (!this._inactivityModal) {
      if (Date.now() - this._lastActivity.getTime() < 2000) {
        return;
      }
      this.resetInactivity();
    }
  }

  constructor(
    private http: HttpClient,
    public router: Router,
    private dialogService: DialogService,
    private authService: AuthService,
    private roleService: RoleService
  ) {
    this.init().then();
    if (!environment.isTesting) {
      fromEvent(document, 'mousemove').subscribe(() => this.detectActivity());
      fromEvent(document, 'touchstart').subscribe(() => this.detectActivity());
      fromEvent(document, 'keydown').subscribe(() => this.detectActivity());
      setInterval(() => {
        if (!this._authenticatedActor) {
          return;
        }
        if (Date.now() - this._lastActivity.getTime() > this._inactivityToLogOut) {
          if (this._authenticatedActor) this.logOut();
        } else if (Date.now() - this._lastActivity.getTime() > this._inactivityToShowModal) {
          this.showInactivityModal();
        }
      }, 1000);
      // detects expired session
      setInterval(() => {
        this.validateIfShouldLogout();
      }, 1000);
    }
  }

  private showInactivityModal() {
    if (this._inactivityModal) {
      return;
    }
    this._inactivityShowModalTime = new Date();
    const data = {
      messageFromMethod: () => {
        if (!this._inactivityShowModalTime) {
          return {
            message: '',
            subMessage: [],
          };
        }
        let remainTime =
          this._inactivityToLogOut -
          this._inactivityToShowModal -
          (Date.now() - this._inactivityShowModalTime.getTime());
        if (remainTime < 0) {
          remainTime = 0;
        }
        const fixZero = (input: string): string => {
          if (input.length === 1) {
            return '0' + input;
          }
          return input;
        };
        return {
          message: `Se ha detectado que lleva ${parseInt(
            this._inactivityToShowModal / 1000 / 60 + ''
          )} minutos de inactividad, en '${fixZero(
            parseInt((remainTime / 1000 / 60).toString()).toString()
          )}:${fixZero(
            parseInt(((remainTime / 1000) % 60).toString()).toString()
          )}' minutos se cerrará su sesión.`,
          subMessage: ['Cierre el modal para evitarlo.'],
        };
      },
      messageFromMethodInterval: 1000,
      title: 'Alerta inactividad',
      message: '',
      icon: 'info',
      isButtons: true,
      orientationIconTop: true,
      isActions: true,
      typeConfirm: 'clear',
    } as DialogInterface;
    this._inactivityModal = this.dialogService.openDialog(data, 'my-dialog-alert-class');
    lastValueFrom(this._inactivityModal.afterClosed()).then(() => {
      this.resetInactivity();
    });
  }

  public async init() {
    try {
      this._ready = AccountStatus.RECOVERING;
      await this.recoverUserData();
      this._ready = AccountStatus.READY;
    } catch (tryError: unknown) {
      const e: ServerError = tryError as ServerError;
      if (e.error?.error) {
        if (e.error.httpCode === 403 || e.error.httpCode === 401) {
          // invalid token, force login
          this.dialogService.errorModal('INGRESO', 'Sus credenciales no son válidas');
          this.clearProfileData();
          await this.router.navigateByUrl('/auth');
        }
      }
      this._ready = AccountStatus.ERROR;
    }
  }

  /**
   * restore user data if authenticated
   */
  public async recoverUserData() {
    //
    let success = false;
    if (this.authService.checkToken('ACCESS')) {
      success = true;
    } else if (this.authService.checkToken('REFRESH')) {
      success = await this.authService.refreshToken();
      if (!success) {
        this.authService.showSessionExpired();
      }
    }
    if (success) {
      const apiUrl =
        environment.api + '/actors-ms/api/v1/actors/operators/' + this.authService.getActorId();
      this._ip = this.authService.getActorIp();
      const userInfo = await lastValueFrom(
        this.http.get<ServerResponse<Omit<Operator, 'roles'> & { roles: Role[] }>>(apiUrl)
      );
      this._authenticatedActor = userInfo?.data;
      this.isloginLogSaved = false;
      this.authenticatedActor!.roles = await this.getUserRoles();
      if (this.authenticatedActor!.roles.length === 1) {
        this.selectedRole = this.authenticatedActor!.roles[0];
      }
    } else {
      this._authenticatedActor = undefined;
    }
  }

  async getUserRoles(): Promise<Role[]> {
    const userRoles = await lastValueFrom(
      this.http.get<Role[]>(
        `${environment.authService}/admin/realms/${environment.keycloakRealm}/users/${this._authenticatedActor?.keycloakUserId}/groups`
      )
    );
    const allRoles = await this.roleService.getAll();
    return allRoles.filter((systemRole) =>
      userRoles.find((userRole) => userRole.id === systemRole.id)
    );
  }

  /**
   * logout sign out and delete keycloak session
   */
  public async logOut() {
    try {
      this.resetInactivity(false);
      this.dialogService.closeAll();
      await this.authService.logOut();
    } catch (e) {
      console.error(e);
    }
    this.clearProfileData();
    await this.router.navigateByUrl('/auth');
    this.selectedRole = undefined;
    this.zone = undefined;
  }

  /**
   * clears all saved data
   */
  public clearProfileData() {
    this._authenticatedActor = undefined;
    this.selectedRole = undefined;
    this._authenticatedActorPhotoUrl = '';
  }

  public getNames(names: {
    firstName?: boolean;
    secondName?: boolean;
    lastName?: boolean;
    secondLastName?: boolean;
  }) {
    const output: string[] = [];
    if (!this._authenticatedActor) {
      return '';
    }
    if (names.firstName) {
      output.push(ProfileService.validateName(this._authenticatedActor?.firstName.value ?? ''));
    }
    if (names.secondName) {
      output.push(ProfileService.validateName(this._authenticatedActor.secondName.value));
    }
    if (names.lastName) {
      output.push(ProfileService.validateName(this._authenticatedActor.lastName.value));
    }
    if (names.secondLastName) {
      output.push(ProfileService.validateName(this._authenticatedActor.secondLastName.value));
    }
    return output.join(' ');
  }

  static validateName(name: string) {
    if (!name || name === 'null' || name === 'undefined') {
      return '';
    }
    return name;
  }
}
