import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { KeyPressedHandlerService } from '../services/key-pressed-handler.service';
import { ProfileService } from '../services/profile/profile.service';

export abstract class ParametricService<T, Q> {
  public newTabAction: NewTabAction | undefined;
  protected _tabs: Array<TabData<T>> = [];
  protected _selectedTab: TabData<T> | undefined;
  protected _tabIdCounter: number = 0;

  showModifiedMark: boolean = true;

  protected constructor(
    protected keyPressedHandlerService: KeyPressedHandlerService,
    public profileService: ProfileService
  ) {
    setTimeout(() => {
      profileService.onLogout.subscribe((isLoggedOut) => {
        if (isLoggedOut) {
          this.closeAllTabs();
        }
      });
    });
  }

  get tabs() {
    return this._tabs;
  }

  get selectedTab() {
    return this._selectedTab;
  }

  isSafeToCloseAllTabs(): boolean {
    return this._tabs.length > 0;
  }

  isSafeToCloseTab(_id: number): boolean {
    throw new Error('Unimplemented');
  }

  newTab(_actionType: TabActionType, _options?: Q): void {
    throw new Error('Unimplemented');
  }

  closeAllTabs(): void {
    const currentTabs = [...this.tabs];
    for (const tab of currentTabs) {
      this.closeTab(tab.id);
    }
  }

  closeTab(id: number): void {
    const tab = this.getTabData(id);
    if (!tab) {
      return;
    }
    const subscriptionName: string = tab.keysSubscriptionName;
    if (subscriptionName) {
      this.keyPressedHandlerService.deleteMethod(subscriptionName);
    }
    // validate if is safe to close
    if (!this.isSafeToCloseTab(id)) {
      // ask to close
    }
    // delete from array and memory
    if (this._selectedTab?.id === id) {
      this._selectedTab = undefined;
    }
    this._tabs = this._tabs.filter((value) => value.id !== tab.id);
  }

  getTabData(id: number): TabData<T> {
    const tabData = this._tabs.find((value) => value.id === id);
    if (tabData) {
      return tabData;
    } else {
      throw new Error(`tabData for id: [${id}] was not found`);
    }
  }

  selectTab(id: number): void {
    const tab = this.getTabData(id);
    if (!tab) {
      return;
    }
    const tabSubscriptionName = this._tabs.find((tab) => tab.id === id)?.keysSubscriptionName;
    if (tabSubscriptionName) {
      if (tabSubscriptionName.length > 0) {
        try {
          this.keyPressedHandlerService.setActiveSubscription(tabSubscriptionName);
        } catch (e) {
          console.error(e);
        }
      } else {
        throw new Error(`an error has occurred when trying to select tab with id: [${id}]`);
      }
    }
    this._selectedTab = tab;
  }

  unselectTab(skipDeleteSubscription: boolean = false): void {
    this._selectedTab = undefined;
    this.keyPressedHandlerService.removeActiveSubscription(skipDeleteSubscription);
  }

  removeActiveMethod() {
    this.keyPressedHandlerService.removeActiveSubscription();
  }

  setActiveSubscription(subscriptionName: string) {
    this.keyPressedHandlerService.setActiveSubscription(subscriptionName);
  }

  addCallbackForKeys(
    cb: (keyPressedValue: number) => void,
    setActive: boolean = true,
    alwaysActive = true
  ) {
    return this.keyPressedHandlerService.addMethod(cb, setActive, alwaysActive);
  }

  deleteCallbackForKeys(subscriptionName: string) {
    return this.keyPressedHandlerService.deleteMethod(subscriptionName);
  }

  moveTab(event: CdkDragDrop<Array<TabData<T>>>) {
    moveItemInArray(this._tabs, event.previousIndex, event.currentIndex);
  }

  changeTabData(newData: TabData<T>): void {
    this._selectedTab = newData;
  }
}

export enum TabActionType {
  CREATE = 'create',
  EDIT = 'edit',
  VIEW = 'view',
  ACTION = 'action',
  FOLLOWUP = 'followUp',
  OTHER = 'other',
  INDICATOR = 'indicator',
  MASSIVE = 'massive',
  UPDATE = 'update',
}

export interface TabData<T> {
  createdByIp: string;
  id: number;
  title: string;
  createdDate: string[];
  creationInfoExpanded: boolean;
  createdBy: string;
  edition: boolean;
  actionType: TabActionType;
  keysSubscriptionName: string;
  tabData: T;
  confirmClose?: boolean;
  icon?: string;
  section?: string;
}

export interface NewTabAction {
  icon: string;
  toolTip: string;
}
