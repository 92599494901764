import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class BehaviorSubjectService {
  public bSubjectSizeScreenGlobal: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  public screenHeight: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  public bSubjectSizeScreen: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  public bSubjectUpdateRole: BehaviorSubject<string> = new BehaviorSubject<string>('');
  public bSubjectClosedTab: BehaviorSubject<string> = new BehaviorSubject<string>('');
  public bSubjectIncidentsCounter: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  public bSubjectDisplayDialog: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public bSubjectDialogVehicleReference: BehaviorSubject<number> = new BehaviorSubject<number>(-1);

  constructor() {}
}
