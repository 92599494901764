import { GeneralKnownRouteValidator, NavItem } from './types-and-helpers.known.routes';

/**
 * DON'T EVER CHANGE NUMERATION
 */
export enum IncidentsKnownItemId {
  LIST = '1',
  ACTION = '2',
  EDIT = '3',
}

const simpleTransformer = (input: NavItem): NavItem & { type: 'incidents' } => {
  return {
    ...input,
    type: 'incidents',
  };
};

export const IncidentsKnownRoutes = (k: IncidentsKnownItemId): NavItem => {
  k = GeneralKnownRouteValidator(k);
  return JSON.parse(JSON.stringify(options[k]));
};
const options: {
  [k in IncidentsKnownItemId]: NavItem & { type: 'incidents' };
} = {
  [IncidentsKnownItemId.LIST]: simpleTransformer({
    id: IncidentsKnownItemId.LIST,
    displayName: 'Incidentes',
    iconName: 'alert',
    route: '/home/incidents',
    permissions: [
      'incident_north_zone_q',
      'incident_south_zone_q',
      'incident_medical_regulation_q',
      'incident_tarm_q',
      'incident_mental_health_q',
      'incident_dispatch_q',
    ],
    visibleOnSidebar: true,
  }),
  [IncidentsKnownItemId.ACTION]: simpleTransformer({
    id: IncidentsKnownItemId.ACTION,
    displayName: 'Incidentes',
    iconName: 'alert',
    route: '/home/incidents/create',
    permissions: ['incident_w'],
    visibleOnSidebar: false,
  }),
  [IncidentsKnownItemId.EDIT]: simpleTransformer({
    id: IncidentsKnownItemId.EDIT,
    displayName: 'Incidentes',
    iconName: 'alert',
    route: '/home/incidents/list',
    permissions: [
      'incident_north_zone_q',
      'incident_south_zone_q',
      'incident_medical_regulation_q',
      'incident_tarm_q',
      'incident_mental_health_q',
      'incident_dispatch_q',
    ],
    visibleOnSidebar: false,
  }),
};
