import { Injectable } from '@angular/core';
import { ParametricService } from '../../../types/parametric.service.abstract.class';
import { KeyPressedHandlerService } from '../../key-pressed-handler.service';
import { ProfileService } from '../../profile/profile.service';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { lastValueFrom } from 'rxjs';
import { environment } from '../../../../../environments/environment';
import { ServerResponse } from '../../../types/http.interfaces';

import {
  SearchResult,
  Validator,
  ValidatorUpdateValidatorsInput,
} from '@smartsoft-types/sisem-incidents';
import { Config, ConfigUpdateInput } from '@smartsoft-types/sisem-actors';
import {
  SircInteroperability,
  SircInteroperabilityUpdateInput,
} from '@smartsoft-types/sisem-resources';

@Injectable({
  providedIn: 'root',
})
export class InteroperabilityService extends ParametricService<unknown, unknown> {
  private cachedData: ServerResponse<SIRCInteroperabilityData> | null = null;
  private cacheTimestamp: number = 0;
  private readonly cacheTimeInMs: number = 2 * 60 * 1000;

  constructor(
    private keyPressHandleService: KeyPressedHandlerService,
    public override profileService: ProfileService,
    private http: HttpClient
  ) {
    super(keyPressHandleService, profileService);
  }

  async getInteroperabilityData() {
    const resp = await lastValueFrom(
      this.http.get<ServerResponse<Validator[]>>(
        `${environment.api}/incidents-ms/api/v1/validators?fetchValidatorField=true`
      )
    );
    return resp.data;
  }

  async updateInteroperabilityData(data: ValidatorUpdateValidatorsInput['data']) {
    const body = {
      auditData: {
        actorId: this.profileService.authenticatedActor?.id ?? -1,
        roleId: this.profileService.selectedRole?.id ?? -1,
      },
      data,
    };
    await lastValueFrom(
      this.http.post(`${environment.api}/incidents-ms/api/v1/validators/updateValidators`, body)
    );
  }

  async getActiveDirectoryData(publicReq?: boolean) {
    let httpHeaders = new HttpHeaders();
    if (publicReq) {
      httpHeaders = httpHeaders.set('skip', 'true');
    }
    const resp = await lastValueFrom(
      this.http.get<ServerResponse<Config[]>>(
        `${environment.api}/actors-ms/api/v1/activeDirectories`,
        {
          headers: httpHeaders,
        }
      )
    );
    return resp.data;
  }

  async updateActiveDirectory(id: number, data: ConfigUpdateInput['data']) {
    const body = {
      auditData: {
        actorId: this.profileService.authenticatedActor?.id ?? -1,
        roleId: this.profileService.selectedRole?.id ?? -1,
      },
      data,
    };
    await lastValueFrom(
      this.http.patch(`${environment.api}/actors-ms/api/v1/activeDirectories/${id}`, body)
    );
  }

  async getSIRCInteroperability() {
    const resp = await lastValueFrom(
      this.http.get<ServerResponse<SearchResult<SircInteroperability[]>>>(
        `${environment.api}/resources-ms/api/v1/parametrics/sircInteroperabilities`
      )
    );
    return resp.data.result[0];
  }

  async updateSICRInteroperability(id: number, body: SircInteroperabilityUpdateInput['data']) {
    await lastValueFrom(
      this.http.patch(
        `${environment.api}/resources-ms/api/v1/parametrics/sircInteroperabilities/${id}`,
        body
      )
    );
  }

  async fetchAll(idNumber: string, documentType: string) {
    const params = new HttpParams().set('idNumber', idNumber).set('documentType', documentType);

    const reps: ServerResponse<Interoperability> = await lastValueFrom(
      this.http.get<ServerResponse<Interoperability>>(
        `${environment.api}/incidents-ms/api/v1/validators/fetch`,
        { params }
      )
    );
    return reps.data;
  }

  async getAllSIRCData(): Promise<ServerResponse<SIRCInteroperabilityData>> {
    if (this.cachedData && Date.now() - this.cacheTimestamp < this.cacheTimeInMs) {
      return Promise.resolve(this.cachedData);
    }

    const response = await lastValueFrom(
      this.http.get<ServerResponse<SIRCInteroperabilityData>>(
        `${environment.api}/resources-ms/api/v1/parametrics/sircInteroperabilities/execute`
      )
    );

    this.cachedData = response;
    this.cacheTimestamp = Date.now();

    return response;
  }

  async fetchSIRCData(name: string) {
    const params = new HttpParams().set('ipsName', name);
    return await lastValueFrom(
      this.http.get<ServerResponse<SIRCInteroperabilityData>>(
        `${environment.api}/resources-ms/api/v1/parametrics/sircInteroperabilities/execute`,
        { params }
      )
    );
  }

  public readonly ValidatorFieldTranslate: { [key: string]: string } = {
    documentType: 'Tipo de documento',
    documentNumber: 'Número de documento',
    firstName: 'Primer nombre',
    secondName: 'Segundo nombre',
    firstLastName: 'Primer apellido',
    secondLastName: 'Segundo apellido',
    birthDate: 'Fecha de nacimiento',
    membershipStatus: 'Estado de afiliación',
    dateStatus: 'Estado fecha',
    apiDateStatus: 'Estado fecha',
    apiAffiliateType: 'Tipo afiliado',
    affiliateType: 'Tipo afiliado',
    affiliationDateEntity: 'Fecha afiliación entidad',
    apiAffiliationDateEntity: 'Fecha afiliación entidad',
    apiDepartment: 'Departamento',
    department: 'Departamento',
    municipality: 'Municipio',
    apiMunicipality: 'Municipio',
    serial: 'Serial',
    apiSerial: 'Serial',
    effectiveAffiliationDate: 'Fecha de afiliación efectiva',
    apiEffectiveAffiliationDate: 'Fecha de afiliación efectiva',
    finalAffiliationDate: 'Fecha final afiliación',
    apiFinalAffiliationDate: 'Fecha final afiliación',
    quantityFamilyGroup: 'Cantidad grupo familiar',
    apiQuantityFamilyGroup: 'Cantidad grupo familiar',
    EPSCode: 'Código EPS',
    apiEPSCode: 'Código EPS',
    EPSName: 'Nombre EPS',
    apiEPSName: 'Nombre EPS',
    nameTypeHealthScheme: 'Nombre tipo régimen salud',
    apiNameTypeHealthScheme: 'Nombre tipo régimen salud',
    sex: 'Sexo',
    score: 'Puntaje',
    coPayment: 'Copago',
    dateInstrument: 'Fecha instrumento',
    institution: 'Institución',
    populationType: 'Tipo población',
    populationDescription: 'Descripción población',
    address: 'Dirección',
    state: 'Estado',
    stateDescription: 'Descripción estado',
    departmentCode: 'Código departamento',
    municipalityCode: 'Código municipio',
    affiliationStatus: 'Estado afiliación',
    fileSISBEN: 'Ficha SISBÉN',
    levelSISBEN: 'nivel SISBÉN',
    coreSISBEN: 'Núcleo SISBÉN',
    scoreSISBEN: 'Puntaje SISBÉN',
    affiliationDateSISBEN: 'Fecha afiliación SISBÉN',
    subsidizedAffiliationStatus: 'Estado afiliación',
    affiliationDescriptionStatus: 'Descripción estado afiliación',
    insuranceCode: 'Código aseguradora',
    insuranceName: 'Nombre aseguradora',
    departmentCodeSubsidizedRegime: 'Código departamento',
    departmentSubsidizedRegime: 'Departamento',
    municipalityCodeSubsidizedRegime: 'Código municipio',
    municipalitySubsidizedRegime: 'Municipio',
    locality: 'Localidad',
    addressSubsidizedRegime: 'Dirección',
    email: 'Correo electrónico',
    cellphone: 'Celular',
    phone: 'Teléfono',
    uploadDate: 'Fecha cargue',
    sisbenFileSISBEN: 'Ficha SISBÉN',
    sisbenLevelSISBEN: 'Nivel SISBÉN',
    sisbenCoreSISBEN: 'Núcleo SISBÉN',
    sisbenScoreSISBEN: 'Puntaje SISBÉN',
    sisbenSurveyDate: 'Fecha encuesta SISBÉN',
    sisbenLocalityCode: 'Código localidad',
    sisbenLocality: 'Localidad',
    sisbenAddress: 'Dirección',
    sisbenPhone: 'Teléfono',
    sisbenSex: 'Sexo',
    apiDocumentType: 'Tipo de documento',
    apiDocumentNumber: 'Número de documento',
    apiFirstName: 'Primer nombre',
    apiSecondName: 'Segundo nombre',
    apiFirstLastName: 'Primer apellido',
    apiSecondLastName: 'Segundo apellido',
    apiBirthDate: 'Fecha nacimiento',
    apiMembershipStatus: 'Estado de afiliación',
  };

  async activeInteroperability() {
    let headers = new HttpHeaders();
    headers = headers.append('skip', 'true');
    const resp = await lastValueFrom(
      this.http.get<ServerResponse<Validator[]>>(
        `${environment.api}/incidents-ms/api/v1/validators?title=Adres`,
        { headers }
      )
    );
    return resp.data;
  }

  async informationProvided(idNumber: number, documentType: string) {
    let headers = new HttpHeaders();
    const params = new HttpParams().set('idNumber', idNumber).set('documentType', documentType);
    headers = headers.append('skip', 'true');
    const reps: ServerResponse<Interoperability> = await lastValueFrom(
      this.http.get<ServerResponse<Interoperability>>(
        `${environment.api}/incidents-ms/api/v1/validators/fetchAdres`,
        { headers, params }
      )
    );
    return reps.data;
  }
}
//TO DELETE
export enum ActiveDirectory {
  DIRECTORY_STATUS = 2,
  FIRST_DIRECTORY_TO_VALIDATE = 3,
  TIME_OUT = 4,
}

export interface Interoperability {
  validators: PatientValidator[];
}

export interface PatientValidator {
  filteredFields: { [key: string]: string | number };
  order: number;
  timeoutExceeded: boolean;
  validator: string;
  activeInteroperability: {
    selector: 'adres' | 'rightsChecker';
    title: string;
    mobileTitle: string;
  }[];
}

export interface SIRCInteroperabilityData {
  data: IPSSIRCData[];
  fetchDate: Date;
}

export interface IPSSIRCData {
  ipsName: string;
  occupation: SIRCOccupation[];
}

export interface SIRCOccupation {
  group: string;
  totalFunctionalBeds: number;
  totalOccupiedBeds: number;
  totalAvailableBeds?: number;
  occupationPercentage: string;
  overOccupied: boolean;
}
