import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TextHasBold } from '../../types/dialog.interface';

@Component({
  selector: 'app-custom-msg',
  templateUrl: './custom-msg.component.html',
  styleUrls: ['./custom-msg.component.scss'],
})
export class CustomMsgComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<CustomMsgComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      message: string;
      type: 'success' | 'error';
      boldActive: boolean;
      textToDisplay: TextHasBold[];
    }
  ) {}

  ngOnInit(): void {
    this.dialogRef.disableClose = true;
  }
}
