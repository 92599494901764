<div class="layer-properties-container">
  <div class="data-container">
    <div *ngFor="let property of properties">
      <p class="text-r-12-white">
        <span class="text-m-12-white"> {{ property.key.toUpperCase() }}:</span>
        {{ property.value }}</p
      >
    </div>
  </div>
</div>
