import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ServerResponse } from '../types/http.interfaces';
import { LocationInfo, LocationType } from '../types/location.interface';
import { lastValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LocationsService {
  private _locations: LocationInfo[] = [];
  private _states: LocationInfo[] = [];
  private _departmentsAsObject?: { [k: number]: LocationInfo };
  private _citiesAsObject?: { [k: number]: LocationInfo };

  public get locations() {
    return this._locations;
  }
  constructor(private http: HttpClient) {}

  public async getLocations(): Promise<LocationInfo[]> {
    if (this._locations.length !== 0) {
      return this._locations;
    }
    const res = await lastValueFrom(
      this.http.get<ServerResponse<LocationInfo[]>>(
        `${environment.api}/general-services-ms/api/v1/locations`
      )
    );
    this._locations = res.data || [];
    return this._locations;
  }

  async getLocationType() {
    const res = await lastValueFrom(
      this.http.get<ServerResponse<LocationType[]>>(
        `${environment.api}/general-services-ms/api/v1/locations/types`
      )
    );
    return res.data;
  }

  public async getStates(): Promise<LocationInfo[]> {
    if (this._states.length !== 0) {
      return this._states;
    }
    if (this._locations.length === 0) {
      await this.getLocations();
    }
    this._states = this._locations.filter((location) => {
      if (location.type === undefined) {
        return;
      } else {
        return location.type?.name === 'STATE';
      }
    });
    return this._states;
  }

  public async getAllCities(): Promise<LocationInfo[]> {
    if (this._locations.length === 0) {
      await this.getLocations();
    }
    return this._locations.filter((location) => location.type!.name === 'CITY');
  }

  public getCities(id: number): LocationInfo[] {
    return this._locations.filter((location) => location.parent?.id === id);
  }
  public getDistricts(code: string): LocationInfo[] {
    return this._locations.filter(
      (location) => location.parent?.code === code && location.type!.name === 'DISTRICT'
    );
  }
  public getCountries(): LocationInfo[] {
    return this._locations.filter((location) => location.type?.name === 'COUNTRY');
  }

  public async getDepartmentsAsObject(): Promise<{ [k: number]: LocationInfo }> {
    if (this._departmentsAsObject) {
      return this._departmentsAsObject;
    }
    const departmentsAsObject: { [k: number]: LocationInfo } = {};
    const departments: LocationInfo[] = await this.getStates();
    for (const department of departments) {
      departmentsAsObject[department.id] = department;
    }
    this._departmentsAsObject = departmentsAsObject;
    return this._departmentsAsObject;
  }

  public async getCitiesAsObject(): Promise<{ [k: number]: LocationInfo }> {
    if (this._citiesAsObject) {
      return this._citiesAsObject;
    }
    const citiesAsObject: { [k: number]: LocationInfo } = {};
    const cities: LocationInfo[] = await this.getAllCities();
    for (const city of cities) {
      citiesAsObject[city.id] = city;
    }
    this._citiesAsObject = citiesAsObject;
    return this._citiesAsObject;
  }
}
