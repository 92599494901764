import { EventEmitter, Injectable } from '@angular/core';
import {
  IncidentResourceRelation,
  IncidentResourceRelationAlertIPSInput,
  IncidentResourceRelationRequestSupportFromEntitiesInput,
  IncidentResourceRelationTransmilenioRailAuthorizationInput,
  IncidentResourceRelationTransmilenioRailRequestInput,
  Patient,
} from '@smartsoft-types/sisem-incidents';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ProfileService } from '../profile/profile.service';
import { ServerResponse } from '../../types/http.interfaces';
import { lastValueFrom } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { DialogService } from '../dialog.service';
import { Resource } from '@smartsoft-types/sisem-resources/dist/src/entities/models/resource/resource.model.entity';
import { IncidentDB } from '../../types/incident.interface';

@Injectable({
  providedIn: 'root',
})
export class IncidentDispatchService {
  onCloseTab: EventEmitter<boolean> = new EventEmitter<boolean>();

  readonly url = environment.api + '/incidents-ms/api/v1/incidents';

  constructor(
    private http: HttpClient,
    private profileService: ProfileService,
    private dialogService: DialogService
  ) {}

  async getAllResources(incidentId: number, fetchResource: boolean = false) {
    let params = new HttpParams();
    if (fetchResource) params = params.set('fetchResource', true);
    try {
      const resources: ServerResponse<IncidentResourceRelation[]> = await lastValueFrom(
        this.http.get<ServerResponse<IncidentResourceRelation[]>>(
          `${this.url}/${incidentId}/resourceRelations`,
          { params: params }
        )
      );
      return resources.data;
    } catch (e) {
      console.error(e);
      throw e;
    }
  }

  async AlertIPS(
    data?: IncidentResourceRelationAlertIPSInput['data'],
    incidentId?: number,
    relationId?: number
  ) {
    const body = {
      data,
      auditData: { roleId: this.profileService.selectedRole?.id },
    };
    try {
      await lastValueFrom(
        this.http.post(`${this.url}/${incidentId}/resourceRelations/${relationId}/alertIPS`, body)
      );
      return true;
    } catch (e) {
      console.error(e);
      this.dialogService.errorModal('ALERTAR IPS', 'Ha ocurrido un error en el sistema');
      return false;
    }
  }

  async getAllPatients(incidentId: number) {
    try {
      let params = new HttpParams();
      params = params.set('fetchDiagnosis', true);
      params = params.set('fetchCauseFailed', true);
      params = params.set('fetchPaymentResponsible', true);
      params = params.set('fetchNoTransferCause', true);
      params = params.set('fetchCausality', true);
      params = params.set('fetchEthnicity', true);
      params = params.set('fetchInterestPopulation', true);
      params = params.set('fetchNoTransferCause', true);
      params = params.set('fetchPatientTransfer', true);
      params = params.set('fetchPupilShape', true);
      const res: ServerResponse<Patient[]> = await lastValueFrom(
        this.http.get<ServerResponse<Patient[]>>(`${this.url}/${incidentId}/patientEvaluations`, {
          params: params,
        })
      );
      return res.data;
    } catch (e) {
      throw e;
    }
  }

  async requestSupport(
    data: IncidentResourceRelationRequestSupportFromEntitiesInput['data'],
    incidentId: number,
    resourceRelationId: number,
    roleId: string
  ) {
    const body = {
      data,
      auditData: {
        roleId,
      },
    };
    try {
      await lastValueFrom(
        this.http.post(
          `${this.url}/${incidentId}/resourceRelations/${resourceRelationId}/requestSupportFromEntities`,
          body
        )
      );
      return true;
    } catch (e) {
      console.error(e);
      return false;
    }
  }

  async registerCancelSupport(incidentId: number, data: Record<string, unknown>) {
    const body = {
      data: {
        eventType: 'cancelledRequestSupportFromEntities',
        eventData: data,
        operatorCurrentRoleId: this.profileService.selectedRole?.id,
      },
      auditData: {
        roleId: this.profileService.selectedRole?.id ?? 1,
      },
    };

    await lastValueFrom(this.http.post(`${this.url}/${incidentId}/registerEvent`, body));
  }

  async requestTransmilenioRail(
    data: IncidentResourceRelationTransmilenioRailRequestInput['data'],
    id: number,
    relationId: number
  ) {
    const body = {
      auditData: {
        roleId: this.profileService.selectedRole?.id ?? 1,
      },
      data: data,
    };
    await lastValueFrom(
      this.http.post(
        `${this.url}/${id}/resourceRelations/${relationId}/transmilenioRailRequests`,
        body
      )
    );
  }

  async authorizeTransmilenioRail(
    data: IncidentResourceRelationTransmilenioRailAuthorizationInput['data'],
    id: number,
    relationId: number
  ) {
    const body = {
      auditData: {
        roleId: this.profileService.selectedRole?.id ?? 1,
      },
      data: data,
    };
    await lastValueFrom(
      this.http.post(
        `${this.url}/${id}/resourceRelations/${relationId}/transmilenioRailAuthorizations`,
        body
      )
    );
  }

  async cancelTransmilenioAction(
    action:
      | 'cancelledTransmilenioRailRequest'
      | 'cancelledTransmilenioRailAuthorizationConfirmation',
    data:
      | IncidentResourceRelationTransmilenioRailAuthorizationInput['data']
      | IncidentResourceRelationTransmilenioRailRequestInput['data'],
    id: number
  ) {
    const body = {
      data: {
        eventType: action,
        eventData: data,
        operatorCurrentRoleId: this.profileService.selectedRole?.id,
      },
      auditData: {
        roleId: this.profileService.selectedRole?.id ?? 1,
      },
    };
    await lastValueFrom(
      this.http.post(`${environment.api}/incidents-ms/api/v1/incidents/${id}/registerEvent`, body)
    );
  }

  async cancelIPSAlerting(data: IncidentResourceRelationAlertIPSInput['data'], id: number) {
    const body = {
      data: {
        eventType: 'cancelledAlertIPS',
        eventData: data,
        operatorCurrentRoleId: this.profileService.selectedRole?.id,
      },
      auditData: {
        roleId: this.profileService.selectedRole?.id ?? 1,
      },
    };
    await lastValueFrom(
      this.http.post(`${environment.api}/incidents-ms/api/v1/incidents/${id}/registerEvent`, body)
    );
  }

  getStatusName(selectedIncident?: IncidentDB): string {
    if (!selectedIncident) return '';
    switch (Number(selectedIncident.status?.code)) {
      case 1:
        return 'INICIAL';
      case 2:
        return 'PENDIENTE';
      case 3:
        return 'ACTIVO';
      case 4:
        return 'CERRADO';
      default:
        return '';
    }
  }
}

export interface ResourcesData {
  type: string;
  status: string;
  resourceRelationData: IncidentResourceRelation;
  patient?: PatientTableData[];
  resourceData?: Resource;
  codeColor: string;
  boxColor: string;
  resourceFull?: string;
}

export interface PatientTableData {
  destinationIPSId?: number;
  resourceColor: string;
  name: string;
  destination?: string;
  diagnosis: string;
  patientData: Patient;
  overrideTooltip?: {
    action: string;
    message: string;
  };
}
