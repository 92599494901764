<!--Title-->
<div class="flex-row-space-between dialog-header">
  <div class="filter-touch-button"><span></span></div>
  <div class="flex-row-start message-dialog dialog-title pl-0-5 pt-0-5 pb-0-5">
    <app-svg class="dialog-title-icon color-secondary-200" [name]="titleIcon"></app-svg>
    <p class="text-b-14-white pr-2">{{ title }}</p>
  </div>
  <mat-icon
    translate="no"
    class="dialog-title-icon button-close-dialog material-icons-tone"
    (click)="close()"
    data-cy="dialog-close-btn">
    highlight_off
  </mat-icon>
</div>

<!--Main Content-->
<ng-container>
  <div mat-dialog-content class="dialog-content-container">
    <div class="main-dialog-container">
      <ng-template *ngIf="html" [ngComponentOutlet]="html"></ng-template>
    </div>
  </div>
</ng-container>
