import { EventEmitter, Injectable } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, NavigationEnd, Router } from '@angular/router';
import { distinctUntilChanged, filter, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class BreadcrumbService {
  public canChangeSection:
    | (() => {
        title: string;
        message: string;
        icon: string;
        result: boolean;
      })
    | undefined = undefined;
  private _breadcrumbData: BreadcrumbItem[] = [];
  private _backButtonLimit: number = 3;

  buttonClicked: boolean = false;
  moduleClicked: boolean = false;

  updateBreadcrumb: EventEmitter<boolean> = new EventEmitter<boolean>();
  routerSubscription: Subscription;
  subscriptionActive: boolean = false;
  hospitalsBreadcrumb: EventEmitter<{
    previous: BreadcrumbItem[];
    current: BreadcrumbItem[];
  }> = new EventEmitter<{
    previous: BreadcrumbItem[];
    current: BreadcrumbItem[];
  }>();

  constructor(
    private route: ActivatedRoute,
    private router: Router
  ) {}

  buildBreadcrumb(
    route: ActivatedRouteSnapshot,
    url: string = '',
    breadcrumbs: BreadcrumbItem[] = []
  ): BreadcrumbItem[] {
    const label =
      route.routeConfig?.data && route.routeConfig.data['section']?.label
        ? route.routeConfig.data['section'].label
        : '';
    let path = route.routeConfig ? route.routeConfig.path : '';
    const parentRoute: BreadcrumbItem | BreadcrumbItem[] =
      route.routeConfig?.data && route.routeConfig.data['section']?.parentRoute
        ? route.routeConfig.data['section'].parentRoute
        : undefined;
    const params = path?.match(/:[a-zA-Z]*/g);
    if (path) {
      if (params) {
        for (let param of params) {
          param = param.replace(':', '');
          const paramData = route.paramMap.get(param);
          path = path?.replace(`:${param}`, `${paramData}`);
        }
      }
    }

    const nextUrl = `${url}${path}/`;
    const breadcrumbItem: BreadcrumbItem = {
      label: label,
      url: `${nextUrl.replace(/\/\//g, '/')}`,
    };
    breadcrumbItem.url =
      breadcrumbItem.url && breadcrumbItem.url[breadcrumbItem.url.length - 1] === '/'
        ? breadcrumbItem.url.slice(0, -1)
        : breadcrumbItem.url;
    let newBreadcrumbs: BreadcrumbItem[] = breadcrumbs ?? [];

    if (label) {
      if (parentRoute) {
        if (Array.isArray(parentRoute)) {
          newBreadcrumbs = [...newBreadcrumbs, ...parentRoute];
        } else {
          const auxBreadcrumb: BreadcrumbItem = {
            ...parentRoute,
          };
          newBreadcrumbs.push(auxBreadcrumb);
        }
      }
      newBreadcrumbs = [...newBreadcrumbs, breadcrumbItem];
    }

    if (route.firstChild) {
      return this.buildBreadcrumb(route.firstChild, nextUrl, newBreadcrumbs);
    }
    return newBreadcrumbs;
  }

  public get breadcrumbData() {
    return this._breadcrumbData;
  }

  public get backButtonLimit() {
    const module = this.breadcrumbData.find((b) => b.isModule);
    this._backButtonLimit = module?.hasMenu ? 2 : 3;
    return this._backButtonLimit;
  }

  initializeSubscription() {
    if (!this.routerSubscription || !this.subscriptionActive) {
      this.routerSubscription = this.router.events
        .pipe(
          filter((event) => event instanceof NavigationEnd),
          distinctUntilChanged(),
          map(() => this.route.snapshot),
          map((route) => this.buildBreadcrumb(route))
        )
        .subscribe((breadcrumbItems) => {
          if (this.buttonClicked) {
            this.hospitalsBreadcrumb.emit({
              previous: this._breadcrumbData,
              current: breadcrumbItems,
            });
            this.updateBreadcrumb.emit(true);
          }
          this.buttonClicked = false;
          if (breadcrumbItems.length > 0) {
            breadcrumbItems[0].isModule = true;
            breadcrumbItems[breadcrumbItems.length - 1].url = undefined;
          }
          this._breadcrumbData = breadcrumbItems;
        });
      this.subscriptionActive = true;
    }
  }

  destroySubscription() {
    if (this.routerSubscription || this.subscriptionActive) {
      this.routerSubscription.unsubscribe();
      this.subscriptionActive = false;
    }
  }
}

export interface BreadcrumbItem {
  label: string;
  url?: string;
  isModule?: boolean;
  hasMenu?: boolean;
}
