import { Injectable } from '@angular/core';
import { CachedCRUDService } from '../types/cached-c-r-u-d.service';
import {
  Office,
  OfficeCreateInput,
  OfficeUpdateInput,
  OfficeWFSResponse,
} from '@smartsoft-types/sisem-resources';
import { HttpClient, HttpParams } from '@angular/common/http';
import { lastValueFrom } from 'rxjs';
import { ServerResponse } from '../types/http.interfaces';
import { environment } from '../../../environments/environment';
import { ProviderTypeService } from './administration/provider/provider.type.service';

@Injectable({
  providedIn: 'root',
})
export class OfficeService extends CachedCRUDService<
  Office,
  OfficeCreateInput['data'],
  OfficeUpdateInput['data']
> {
  capacityTypes: CapacityType[] | undefined;

  constructor(
    private http: HttpClient,
    private providerTypeService: ProviderTypeService
  ) {
    super();
    this.getCapacityTypes();
  }

  public override async _fetchNewData(
    cityOfficeId?: number,
    retained?: boolean,
    contactData?: boolean,
    capacityGroupIds?: number[],
    status?: 'active' | 'inactive'
  ): Promise<Office[]> {
    let params = new HttpParams();
    if (cityOfficeId) {
      params = params.append('cityOfficeId', cityOfficeId);
    }
    if (contactData) {
      params = params.append('fetchContactData', true);
    }
    if (retained) {
      params = params.set('fetchContactData', true);
      params = params.set('hasActiveVehicleRetentions', true);
    }
    if (capacityGroupIds) {
      capacityGroupIds.forEach((ctId) => {
        params = params.append('capacityGroupId', JSON.stringify(ctId));
      });
    }
    if (status) {
      params = params.append('status', status);
    }
    const res = await lastValueFrom(
      this.http.get<ServerResponse<Office[]>>(`${environment.api}/resources-ms/api/v1/offices`, {
        params,
      })
    );
    return res.data;
  }

  protected override async _getByIdData(id: number): Promise<Office> {
    const res = await lastValueFrom(
      this.http.get<ServerResponse<Office>>(`${environment.api}/resources-ms/api/v1/offices/${id}`)
    );
    return res.data;
  }

  async getIpsById(id: number, contactData: boolean = true) {
    const params = new HttpParams().set('fetchContactData', contactData);
    const res = await lastValueFrom(
      this.http.get<ServerResponse<Office>>(
        `${environment.api}/resources-ms/api/v1/offices/${id}`,
        { params }
      )
    );
    return res.data;
  }

  async getIpsOffice(
    cityOfficeId?: number,
    retained?: boolean,
    contactData?: boolean,
    capacityGroupIds?: number[],
    status?: 'active' | 'inactive'
  ): Promise<Office[]> {
    const res = await this._fetchNewData(
      cityOfficeId,
      retained,
      contactData,
      capacityGroupIds,
      status
    );
    const providerTypes = await this.providerTypeService.getAll();
    const IPSTypeId = providerTypes.find((type) => type.title === 'IPS')?.id;
    return res.filter(
      (office) => office.providerTypeId === IPSTypeId && office.primaryOffice === false
    );
  }

  async getCapacityTypes() {
    if (!this.capacityTypes) {
      const res = await lastValueFrom(
        this.http.get<ServerResponse<CapacityType[]>>(
          `${environment.api}/resources-ms/api/v1/capacityGroups`
        )
      );
      this.capacityTypes = res.data;
    }
    return this.capacityTypes;
  }

  async getGeoServerData(params: HttpParams) {
    const res = await lastValueFrom(
      this.http.get<ServerResponse<OfficeWFSResponse>>(
        `${environment.api}/resources-ms/api/v1/offices/geoserver`,
        { params }
      )
    );
    return res.data;
  }
}

export interface CapacityType {
  id: number;
  code: 'ambulances' | 'urgencies' | 'outpatientServices';
  description: string;
  title: string;
  active: boolean;
  createdAt: Date | string;
  updatedAt: Date | string;
  deletedAt: Date | string;
  createdAtIp: Date | string;
  updatedAtIp: Date | string;
  createdById: number;
  updatedById: number;
}
