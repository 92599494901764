import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: 'input[integerNumber]',
})
export class IntegerDirective {
  constructor(private _el: ElementRef) {}

  @HostListener('input', ['$event']) onInputChange(event: KeyboardEvent) {
    const initialValue = this._el.nativeElement.value + '';
    this._el.nativeElement.value = initialValue.replaceAll(/\D/g, '');
    if (initialValue !== this._el.nativeElement.value) {
      try {
        event.preventDefault();
      } catch (e) {
        // eslint-disable-next-line no-console
        console.trace(e);
        throw e;
      }
    }
  }
}
