import { Injectable } from '@angular/core';
import { fromEvent, Subscription } from 'rxjs';
import { DialogService } from './dialog.service';
import { randomNumber } from '../utils/random.generators';

@Injectable({
  providedIn: 'root',
})
export class KeyPressedHandlerService {
  public keyPressed$: Subscription;
  private subscribedMethods: {
    [key: string]: {
      name: string;
      method: (keyPressedValue: number) => void;
    };
  } = {};
  private currentlyActive?: string;
  private alwaysActive: string[] = [];

  constructor(private dialogService: DialogService) {}

  initHostListener(): void {
    this.keyPressed$ = fromEvent(document, 'keydown').subscribe((e: Event) => {
      const key: KeyPressedAction = e as unknown as KeyPressedAction;
      if (key.keyCode === 123 || key.keyCode === 114 || key.keyCode === 118) e.preventDefault();
      this.keyPressedHandler(key.keyCode);
    });
  }

  addMethod(
    newMethod: (keyPressedValue: number) => void,
    setActive: boolean = false,
    alwaysActive: boolean = false
  ): string {
    const subscriptionName: string =
      'SUBSCRIPTION' + randomNumber().toString(36).substring(2, 8) + Date.now();
    if (!this.subscribedMethods.hasOwnProperty(subscriptionName)) {
      if (setActive) this.currentlyActive = subscriptionName;
      this.subscribedMethods[subscriptionName] = {
        name: subscriptionName,
        method: newMethod,
      };
      if (alwaysActive) {
        this.alwaysActive.push(subscriptionName);
      }
      return subscriptionName;
    } else {
      console.error('Unable to add new subscription');
      return '';
    }
  }

  deleteMethod(subscriptionName: string): void {
    if (this.subscribedMethods.hasOwnProperty(subscriptionName)) {
      if (subscriptionName === this.currentlyActive) this.removeActiveSubscription();
      delete this.subscribedMethods[subscriptionName];
      this.alwaysActive = this.alwaysActive.filter(
        (value: string): boolean => value !== subscriptionName
      );
    } else {
      console.error('No subscriptions with that name');
    }
  }

  setActiveSubscription(subscriptionName: string): void {
    if (this.subscribedMethods.hasOwnProperty(subscriptionName)) {
      this.currentlyActive = subscriptionName;
    } else {
      console.error('Subscription not found');
    }
  }

  removeActiveSubscription(skipDeleteSubscription: boolean = false): void {
    if (this.currentlyActive) {
      if (!skipDeleteSubscription) delete this.subscribedMethods[this.currentlyActive];
      this.currentlyActive = undefined;
    }
  }

  keyPressedHandler(keyPressedValue: number): void {
    if (this.currentlyActive) {
      if (!this.dialogService.activeModal) {
        this.subscribedMethods[this.currentlyActive]?.method(keyPressedValue);
        for (const subscriptionName of this.alwaysActive) {
          if (subscriptionName === this.currentlyActive) {
            continue;
          }
          this.subscribedMethods[subscriptionName]?.method(keyPressedValue);
        }
      }
    }
  }

  clearSubscriptions(): void {
    this.subscribedMethods = {};
  }
}

export interface KeyPressedAction {
  keyCode: number;
  preventDefault: void;
}
