import { EventEmitter, Injectable } from '@angular/core';
import { PaginatedCrudService } from '../../paginated-crud.service.abstract.class';
import { HttpClient } from '@angular/common/http';
import { FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { TabActionType, TabData } from 'src/app/shared/types/parametric.service.abstract.class';
import { KeyPressedHandlerService } from '../../key-pressed-handler.service';
import { ProfileService } from '../../profile/profile.service';
import {
  Alert,
  AlertNotificationRecord,
  AlertNotificationRecordSearchInput,
  AlertUpdateStatusBatchInput,
  AlertUpdateStatusInput,
  Notification,
  NotificationUpdateStatusBatchInput,
  NotificationUpdateStatusInput,
} from '@smartsoft-types/sisem-alerts';
import { environment } from '../../../../../environments/environment';
import { lastValueFrom, Observable, Subject } from 'rxjs';
import { ServerResponse } from '../../../types/http.interfaces';
import { SocketService } from '../../web-sockets/web-sockets.service';
import { TransformedAlertAdmin } from 'src/app/administration/components/alert-notifications/alert/alert.component';
import { TransformedNotificationAdmin } from 'src/app/administration/components/alert-notifications/notification/notification.component';
import { DialogService } from '../../dialog.service';
import { AlertTypeReceptor } from '@smartsoft-types/sisem-alerts/dist/src/entities/models/alert-type-receptor/alert-type-receptor.model.entity';

@Injectable({
  providedIn: 'root',
})
export class AlertNotificationService extends PaginatedCrudService<
  AlertNotificationRecord,
  any,
  any,
  ALertNotificationsTabData,
  AlertNotificationTabOptions,
  any
> {
  public actionRoute: EventEmitter<{
    route: string;
    id: number;
    action: 'openIncident' | 'registerFollowUp' | 'editReference' | 'reportIndicators';
    step?: number;
  }> = new EventEmitter<{
    route: string;
    id: number;
    action: 'openIncident' | 'registerFollowUp' | 'editReference' | 'reportIndicators';
    step?: number;
  }>();

  public updateAlertNotificationData: EventEmitter<void> = new EventEmitter<void>();

  public incidentPendingToOpen: number | null;
  public followUpPending: number | null;
  public editReferencePending: { id: number; step: number } | null;
  public reportIndicatorsPending: number | null;

  private readonly serverURL = environment.api + '/alerts-ms';
  private readonly baseURL = this.serverURL + '/api/v1/alertNotificationRecords';

  private readonly alertRecordBaseURL = this.serverURL + '/api/v1/alerts';
  private readonly notificationRecordBaseURL = this.serverURL + '/api/v1/notifications';

  private _pendingReceivedCount: number = 0;
  private _pendingReceivedCountInterval: any;
  private _pendingReceivedCountIntervalTime: number = 10000;
  private _pendingReceivedCurrentFetchPromise: Promise<ServerResponse<number>> | undefined;

  private _pendingReceivedCountSubject = new Subject<number>();

  public get pendingReceivedCountObservable(): Observable<number> {
    return this._pendingReceivedCountSubject.asObservable();
  }

  public get pendingReceivedCount(): number {
    return this._pendingReceivedCount;
  }

  constructor(
    private handleInputService: KeyPressedHandlerService,
    public override profileService: ProfileService,
    private http: HttpClient,
    public router: Router,
    private socketService: SocketService,
    private dialogService: DialogService
  ) {
    super(http, handleInputService, profileService);
  }

  override async newTab(actionType: TabActionType, options?: AlertNotificationTabOptions) {
    const idTab = this._tabs.find((item) => item.actionType === actionType);
    if (idTab && (options?.icon === 'alert' || options?.icon === 'notifications')) {
      this.selectTab(idTab.id);
      return;
    }

    const item = this._tabs.find(
      (i) => i.tabData.id && i.tabData.id === options?.id && i.actionType === actionType
    );
    if (item) {
      this.selectTab(item.id);
      return;
    }

    let title = '';
    if (actionType === TabActionType.ACTION) {
      title = 'ALERTAS';
    } else if (actionType === TabActionType.VIEW) {
      title = 'NOTIFICACIONES';
    } else if (actionType === TabActionType.UPDATE) {
      title = 'EDITAR ALERTA';
    } else if (actionType === TabActionType.EDIT) {
      title = 'EDITAR NOTIFICACIÓN';
    } else if (actionType === TabActionType.CREATE) {
      title = 'CREAR NOTIFICACIÓN';
    }

    const tabId = ++this._tabIdCounter;
    const tabData: TabData<ALertNotificationsTabData> = {
      id: tabId,
      edition: false,
      createdDate: [],
      creationInfoExpanded: false,
      createdBy: '',
      createdByIp: '',
      tabData: {
        form: new FormGroup({}),
        id:
          options?.icon === 'alert' || options?.icon === 'notifications' ? undefined : options?.id,
        editId: options?.data?.id,
        formInitialized: false,
      },
      actionType: actionType,
      title,
      keysSubscriptionName: '',
      icon: options?.icon || 'settings-applications',
    };
    this._tabs.push(tabData);
    this.selectTab(tabData.id);
  }

  override selectTab(id: number) {
    super.selectTab(id);
    const editId = this.selectedTab?.tabData.editId;
    this.router
      .navigateByUrl('/home/administration/alert-notifications/alert', { skipLocationChange: true })
      .then(() => {
        if (this._selectedTab?.actionType === TabActionType.ACTION) {
          this.router.navigateByUrl('/home/administration/alert-notifications/alert');
        } else if (this._selectedTab?.actionType === TabActionType.VIEW) {
          this.router.navigateByUrl('/home/administration/alert-notifications/notification');
        } else if (this._selectedTab?.actionType === TabActionType.UPDATE) {
          this.router.navigateByUrl(
            `/home/administration/alert-notifications/alert/edit/${editId}`
          );
        } else if (this._selectedTab?.actionType === TabActionType.CREATE) {
          this.router.navigateByUrl('/home/administration/alert-notifications/notification/create');
        } else if (this._selectedTab?.actionType === TabActionType.EDIT) {
          this.router.navigateByUrl(
            `/home/administration/alert-notifications/notification/edit/${editId}`
          );
        }
      });
  }

  canChangeSection() {
    return true;
  }

  override isSafeToCloseTab(_event: number): boolean {
    const tab = this.getTabData(_event);
    if (!tab) {
      return true;
    } else {
      return !tab.tabData.form.dirty;
    }
  }

  override closeTab(id: number, _noConfirm: boolean = false) {
    super.closeTab(id);
  }

  async deleteTab(id: number, force: boolean = false) {
    const tab = this.getTabData(id);
    if (!tab) return;
    if (force) {
      this.closeTab(id);
      await this.selectDefaultReturn(tab.actionType);
    }
    if (!this.isSafeToCloseTab(id)) {
      let message = '';
      switch (tab.actionType) {
        case TabActionType.UPDATE:
          message = '¿Está seguro de cancelar la edición de la alerta?';
          break;
        case TabActionType.CREATE:
          message = '¿Está seguro de cancelar la creación de la notificación?';
          break;
        case TabActionType.EDIT:
          message = '¿Está seguro de cancelar la edición de la notificación?';
      }
      const resp = await this.dialogService.yesNoOptionModal(tab.title, message, 'cancel');
      if (resp !== 'cancel') {
        return;
      }
    }
    this.closeTab(id);
    await this.selectDefaultReturn(tab.actionType);
  }

  public async selectDefaultReturn(type: TabActionType) {
    switch (type) {
      case TabActionType.CREATE:
      case TabActionType.EDIT:
        const notificationTab = this._tabs.find((item) => item.actionType === TabActionType.VIEW);
        if (notificationTab) this.selectTab(notificationTab.id);
        else await this.router.navigateByUrl('/home/administration/alert-notifications');
        break;
      case TabActionType.UPDATE:
        const alertTab = this._tabs.find((item) => item.actionType === TabActionType.ACTION);
        if (alertTab) this.selectTab(alertTab.id);
        else await this.router.navigateByUrl('/home/administration/alert-notifications');
        break;
    }
  }

  async getRecords(
    input: AlertNotificationRecordSearchInput & {
      options?: { fetchClosingEventActorInformation?: boolean };
    }
  ) {
    input.searchBy ??= {};
    input.searchBy.actorId = this.profileService.authenticatedActor?.id;
    const searchBy = {
      ...input.searchBy,
      receptorRoleId: '' + this.profileService.selectedRole?.id,
      removedFromReceived: false,
      channels: 'INTERNAL',
    };
    return await this.search(`${this.baseURL}`, { ...input, searchBy });
  }

  async updateAlertStatusBatch(input: AlertUpdateStatusBatchInput['data']): Promise<Alert[]> {
    const result = await lastValueFrom(
      this.http.post<ServerResponse<Alert[]>>(`${this.alertRecordBaseURL}/updateStatus`, input)
    );
    return result.data;
  }

  async updateNotificationStatusBatch(
    input: NotificationUpdateStatusBatchInput['data']
  ): Promise<Notification[]> {
    const result = await lastValueFrom(
      this.http.post<ServerResponse<Notification[]>>(
        `${this.notificationRecordBaseURL}/updateStatus`,
        input
      )
    );
    return result.data;
  }

  async updateAlertStatus(input: AlertUpdateStatusInput): Promise<Alert> {
    const result = await lastValueFrom(
      this.http.post<ServerResponse<Alert>>(
        `${this.alertRecordBaseURL}/${input.searchBy.id}/updateStatus`,
        input.data
      )
    );
    return result.data;
  }

  async updateNotificationStatus(input: NotificationUpdateStatusInput): Promise<Notification> {
    const result = await lastValueFrom(
      this.http.post<ServerResponse<Notification>>(
        `${this.notificationRecordBaseURL}/${input.searchBy.id}/updateStatus`,
        input.data
      )
    );
    return result.data;
  }

  async getPendingReceivedCount(forceFetch?: boolean): Promise<number> {
    if (!this._pendingReceivedCurrentFetchPromise || forceFetch) {
      this.updateAlertNotificationData.emit();
      this._pendingReceivedCurrentFetchPromise = lastValueFrom(
        this.http.get<ServerResponse<number>>(
          `${this.baseURL}/countPendingReceived?actorId=${this.profileService.authenticatedActor?.id}&receptorRoleId=${this.profileService.selectedRole?.id}&removedFromReceived=false&channels=INTERNAL`
        )
      );
    }
    const result = await this._pendingReceivedCurrentFetchPromise;
    this._pendingReceivedCount = result.data;
    this._pendingReceivedCountSubject.next(this._pendingReceivedCount);
    return result.data;
  }

  setPendingReceivedCountInterval(time: number = this._pendingReceivedCountIntervalTime) {
    this._pendingReceivedCountIntervalTime = time;
    this.socketService.onEvent('notificationMessage', () => {
      this.getPendingReceivedCount(true);
    });
    this._pendingReceivedCountInterval = setInterval(() => {
      this.getPendingReceivedCount();
    }, this._pendingReceivedCountIntervalTime);
    this.getPendingReceivedCount(true);
  }

  clearPendingReceivedCountInterval() {
    if (this._pendingReceivedCountInterval) {
      clearInterval(this._pendingReceivedCountInterval);
    }
  }
}

export interface ALertNotificationsTabData {
  form: FormGroup;
  id?: number;
  editId?: number;
  formInitialized?: boolean;
  alertReceptors?: AlertTypeReceptor[];
}

export interface AlertNotificationTabOptions {
  id: number;
  icon?: string;
  data?: TransformedNotificationAdmin | TransformedAlertAdmin;
}
