import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { lastValueFrom } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import { AlertService } from '../../services/alert.service';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import {} from 'google.maps';

@Injectable({
  providedIn: 'root',
})
export class GeocoderService {
  private environment = environment.api;

  private isWorking = false;

  constructor(
    private http: HttpClient,
    private alertService: AlertService
  ) {}

  async getLocationCompleteInfo(lng: number, lat: number) {
    const params = new HttpParams().append('latitude', lat).append('longitude', lng);
    return await lastValueFrom(
      this.http.get<LocationAllData>(`${this.environment}/maps-ms/api/v1/layers/incident/locate`, {
        params,
      })
    );
  }

  async testApiKey() {
    try {
      this.isWorking = false;
      // Wait for the google object to become available
      while (typeof google === 'undefined') {
        await new Promise((resolve) => setTimeout(resolve, 10));
      }
      const mapUrl = `https://maps.googleapis.com/maps/api/staticmap?center=Paris&zoom=12&size=400x400&key=${environment.googleApiKey}`;
      const response = await fetch(mapUrl);
      if (!response.ok) throw new Error('Failed to load Static Map');
      this.isWorking = true;
    } catch (e) {
      console.error(e);
      this.alertService.showError('Google API not working, check api key and reload page');
    }
  }

  public async getLatLng(address: string): Promise<
    | {
        lat: number;
        lng: number;
      }
    | string
  > {
    console.info('Fetching geolocation with google api');
    if (!this.isWorking) {
      this.alertService.showError('Google API not working, check api key and reload page');
      return '';
    }
    return new Promise(async (resolve, reject) => {
      try {
        const geocoder = new google.maps.Geocoder();
        await geocoder.geocode({ address: address }, async (results, status) => {
          if (status === google.maps.GeocoderStatus.OK && results![0]) {
            const location = results![0].geometry.location;
            const lat = location.lat();
            const lng = location.lng();
            resolve({
              lat,
              lng,
            });
          } else {
            console.error('Geocoder failed due to ' + status);
            resolve(status);
          }
        });
      } catch (e) {
        console.error(e);
        reject(e);
      }
    });
  }

  public async geocode(latLng: google.maps.LatLng): Promise<google.maps.GeocoderResult[] | string> {
    if (!this.isWorking) {
      this.alertService.showError('Google API not working, check api key and reload page');
      return '';
    }
    return new Promise(async (resolve, reject) => {
      try {
        const geocoder = new google.maps.Geocoder();
        await geocoder.geocode({ location: latLng }, (results, status) => {
          if (status === google.maps.GeocoderStatus.OK && results && results[0]) {
            resolve(results);
          } else {
            console.error('Geocoder failed due to ' + status);
            resolve(status);
          }
        });
      } catch (e) {
        console.error(e);
        reject(e);
      }
    });
  }
}

export interface LocationAllData {
  neighborhood: string;
  localityId: string;
  zone: string;
}
