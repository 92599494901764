import { GeneralKnownRouteValidator, NavItem } from './types-and-helpers.known.routes';

/**
 * DON'T EVER CHANGE NUMERATION
 */
export enum HospitalsKnownItemId {
  HOSPITALS = '1',
  MANAGE_PATIENTS = '2',
  MANAGE_HOSPITALS = '3',
  REPORT_INDICATORS = '4',
  REPORT_NOVELTIES = '5',
  IPS_DIRECTORY = '6',
  UPDATE_INFORMATION = '7',
  UPLOAD_PATIENT = '8',
  MANAGE_PATIENTS_GENERAL = '9',
  UPLOAD_PATIENT_GENERAL = '10',
  OFFICE_CONTACT_DATA_UPDATE = '11',
  REPORT_INDICATORS_GENERAL = '12',
  BULK_REPORT_INDICATORS_GENERAL = '13',
  QUERY_NOVELTIES_GENERAL = '14',
  REPORT_NOVELTIES_GENERAL = '15',
}

const simpleTransformer = (input: NavItem): NavItem & { type: 'hospital' } => {
  return {
    ...input,
    type: 'hospital',
  };
};

export const HospitalsKnownRoutes = (k: HospitalsKnownItemId): NavItem => {
  k = GeneralKnownRouteValidator(k);
  return JSON.parse(JSON.stringify(options[k]));
};
const options: {
  [k in HospitalsKnownItemId]: NavItem & { type: 'hospital' };
} = {
  [HospitalsKnownItemId.HOSPITALS]: simpleTransformer({
    id: HospitalsKnownItemId.HOSPITALS,
    route: '/home/hospitals',
    iconName: 'hospital',
    displayName: 'Hospitales',
    permissions: [
      'patients_particular_q',
      'hospital_q',
      'hospital_indicators_particular_r',
      'hospital_novelties_particular_q',
      'offices_directory_q',
      'office_contact_data_particular_u',
    ],
    visibleOnSidebar: false,
  }),
  [HospitalsKnownItemId.MANAGE_PATIENTS]: simpleTransformer({
    id: HospitalsKnownItemId.MANAGE_PATIENTS,
    route: '/home/hospitals/managePatient',
    iconName: 'personal-injury',
    displayName: 'Gestionar Pacientes',
    parentModule: 'hospitals',
    permissions: ['patients_particular_q'],
    visibleOnSidebar: true,
  }),
  [HospitalsKnownItemId.MANAGE_PATIENTS_GENERAL]: simpleTransformer({
    id: HospitalsKnownItemId.MANAGE_PATIENTS,
    route: '/home/hospitals/manageHospitals/managePatient',
    iconName: 'personal-injury',
    displayName: 'Gestionar Pacientes',
    parentModule: 'hospitals',
    permissions: ['patients_general_q'],
    visibleOnSidebar: false,
  }),
  [HospitalsKnownItemId.MANAGE_HOSPITALS]: simpleTransformer({
    id: HospitalsKnownItemId.MANAGE_HOSPITALS,
    route: '/home/hospitals/manageHospitals',
    iconName: 'corporate_fare',
    displayName: 'Gestionar Hospitales',
    parentModule: 'hospitals',
    permissions: ['hospital_q'],
    visibleOnSidebar: true,
  }),
  [HospitalsKnownItemId.REPORT_INDICATORS]: simpleTransformer({
    id: HospitalsKnownItemId.REPORT_INDICATORS,
    route: '/home/hospitals/reportIndicators',
    iconName: 'indicators',
    displayName: 'Reportar Indicadores',
    parentModule: 'hospitals',
    permissions: ['hospital_indicators_particular_r'],
    visibleOnSidebar: true,
  }),
  [HospitalsKnownItemId.REPORT_NOVELTIES]: simpleTransformer({
    id: HospitalsKnownItemId.REPORT_NOVELTIES,
    route: '/home/hospitals/reportNovelty',
    iconName: 'news-small',
    displayName: 'Reportar Novedades',
    parentModule: 'hospitals',
    permissions: ['hospital_novelties_particular_q'],
    visibleOnSidebar: true,
  }),
  [HospitalsKnownItemId.QUERY_NOVELTIES_GENERAL]: simpleTransformer({
    id: HospitalsKnownItemId.QUERY_NOVELTIES_GENERAL,
    route: '/home/hospitals/manageHospitals/reportNovelty',
    iconName: 'news-small',
    displayName: 'Reportar Novedades',
    parentModule: 'hospitals',
    permissions: ['hospital_novelties_general_q'],
    visibleOnSidebar: false,
  }),
  [HospitalsKnownItemId.REPORT_NOVELTIES_GENERAL]: simpleTransformer({
    id: HospitalsKnownItemId.REPORT_NOVELTIES_GENERAL,
    route: '/home/hospitals/manageHospitals/reportNovelty/add',
    iconName: 'news-small',
    displayName: 'Reportar Novedades',
    parentModule: 'hospitals',
    permissions: ['hospital_novelties_general_w'],
    visibleOnSidebar: false,
  }),
  [HospitalsKnownItemId.IPS_DIRECTORY]: simpleTransformer({
    id: HospitalsKnownItemId.IPS_DIRECTORY,
    route: '/home/hospitals/ips-directory',
    iconName: 'news',
    displayName: 'Directorio de IPS',
    parentModule: 'hospitals',
    permissions: ['offices_directory_q'],
    visibleOnSidebar: true,
  }),
  [HospitalsKnownItemId.UPDATE_INFORMATION]: simpleTransformer({
    id: HospitalsKnownItemId.UPDATE_INFORMATION,
    route: '/home/hospitals/updateInformation',
    iconName: 'update',
    displayName: 'Actualizar Información',
    parentModule: 'hospitals',
    permissions: ['office_contact_data_particular_u'],
    visibleOnSidebar: true,
  }),
  [HospitalsKnownItemId.UPLOAD_PATIENT]: simpleTransformer({
    id: HospitalsKnownItemId.UPLOAD_PATIENT,
    route: '/home/hospitals/uploadPatient',
    iconName: 'update',
    displayName: 'CARGUE MASIVO',
    parentModule: 'hospitals',
    permissions: ['patients_particular_w'],
    visibleOnSidebar: false,
  }),
  [HospitalsKnownItemId.UPLOAD_PATIENT_GENERAL]: simpleTransformer({
    id: HospitalsKnownItemId.UPLOAD_PATIENT,
    route: '/home/hospitals/manageHospitals/editPatient',
    iconName: 'update',
    displayName: 'ATENCIÓN HOSPITALARIA',
    parentModule: 'managePatient',
    permissions: ['patients_general_w'],
    visibleOnSidebar: false,
  }),
  [HospitalsKnownItemId.OFFICE_CONTACT_DATA_UPDATE]: simpleTransformer({
    id: HospitalsKnownItemId.OFFICE_CONTACT_DATA_UPDATE,
    route: '/home/hospitals/manageHospitals/updateInformation',
    iconName: 'update',
    displayName: 'ACTUALIZAR INFORMACIÓN',
    parentModule: 'manageHospitals',
    permissions: ['office_contact_data_general_u'],
    visibleOnSidebar: false,
  }),
  [HospitalsKnownItemId.REPORT_INDICATORS_GENERAL]: simpleTransformer({
    id: HospitalsKnownItemId.REPORT_INDICATORS_GENERAL,
    route: '/home/hospitals/manageHospitals/reportIndicators',
    iconName: 'update',
    displayName: 'REPORTAR INDICADORES',
    parentModule: 'manageHospitals',
    permissions: ['hospital_indicators_general_q'],
    visibleOnSidebar: false,
  }),
  [HospitalsKnownItemId.BULK_REPORT_INDICATORS_GENERAL]: simpleTransformer({
    id: HospitalsKnownItemId.BULK_REPORT_INDICATORS_GENERAL,
    route: '/home/hospitals/manageHospitals/bulkUpload',
    iconName: 'update',
    displayName: 'CARGUE MASIVO DE INDICADORES',
    parentModule: 'manageHospitals',
    permissions: ['hospital_indicators_general_w'],
    visibleOnSidebar: false,
  }),
};
