import { CachedCRUDService } from '../../../types/cached-c-r-u-d.service';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { lastValueFrom } from 'rxjs';
import { ServerResponse } from '../../../types/http.interfaces';
import { environment } from '../../../../../environments/environment';
import { Priority } from '../../../types/incident.interface';

@Injectable({
  providedIn: 'root',
})
export class IncidentsPriorityService extends CachedCRUDService<Priority, never, never> {
  constructor(private http: HttpClient) {
    super();
    this.options.cacheExpiration = -1; // should almost never change, set don't expire
  }

  protected override async _fetchNewData(): Promise<Priority[]> {
    const response = await lastValueFrom(
      this.http.get<ServerResponse<Priority[]>>(`${environment.api}/incidents-ms/api/v1/priority`)
    );
    return response.data;
  }
}
