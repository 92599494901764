import { CachedCRUDService } from '../../../types/cached-c-r-u-d.service';
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { lastValueFrom } from 'rxjs';
import { ServerResponse } from '../../../types/http.interfaces';
import { environment } from '../../../../../environments/environment';
import { PaymentResponsible } from '@smartsoft-types/sisem-incidents';

@Injectable({
  providedIn: 'root',
})
export class IncidentsPaymentResponsibleService extends CachedCRUDService<
  PaymentResponsible,
  never,
  never
> {
  constructor(private http: HttpClient) {
    super();
    this.options.cacheExpiration = -1; // should almost never change, set don't expire
  }

  protected override async _fetchNewData(): Promise<PaymentResponsible[]> {
    const params = new HttpParams().set('fetchPaymentResponsibleType', true);
    const response = await lastValueFrom(
      this.http.get<ServerResponse<PaymentResponsible[]>>(
        `${environment.api}/incidents-ms/api/v1/paymentResponsible`,
        { params }
      )
    );
    return response.data;
  }
}
