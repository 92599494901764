<div>
  <div fxLayout="column" fxLayoutAlign="space-between center">
    <div fxLayout="row" fxLayoutAlign="space-between center" class="w-100">
      <div
        fxLayout="row"
        fxLayoutAlign="start center"
        class="message-dialog dialog-title pl-0-5 pt-0-5 pb-0-5">
        <app-svg class="dialog-title-icon color-secondary-200" [name]="'inventory-black'"></app-svg>
        <p class="text-b-14-white pr-2">CONFIGURAR PREOPERACIONAL</p>
      </div>
      <mat-icon
        translate="no"
        class="dialog-title-icon button-close-dialog material-icons-tone"
        (click)="cancel()">
        highlight_off
      </mat-icon>
    </div>
    <div class="dialog-content-container w-100">
      <div class="main-dialog-container">
        <form class="first-section search-form input-form" autocomplete="off" [formGroup]="form">
          <div class="title-section-main-container mt-1 mb-1">
            <span class="text-b-16-blue title-data" data-jas="title-section"
              >CONFIGURAR PREOPERACIONAL</span
            >
            <div class="blue-line-data"></div>
          </div>
          <div data-jas="cut-reps-date" class="text-b-17-white"> {{ transformDate() }} </div>
          <div class="text-data"> Fecha de corte REPS </div>
          <div class="columns-sections">
            <div class="section-data">
              <div data-jas="text-plate" class="text-b-17-white"> {{ resource?.plate }} </div>
              <div class="text-data"> Placa del vehículo </div>
            </div>
            <div class="section-data">
              <div data-jas="text-service" class="text-b-17-white">
                {{
                  resource?.resourceData?.resourceType?.code?.includes('tab')
                    ? 'Básico'
                    : 'Medicalizado'
                }}
              </div>
              <div class="text-data"> Tipos de Servicio </div>
            </div>
          </div>
          <div class="columns-sections">
            <div class="section-data">
              <div data-jas="text-company" class="text-b-17-white">
                {{ getCompanyType(resource?.resourceData?.provider?.companyType) }}
              </div>
              <div class="text-data"> Tipo de Empresa </div>
            </div>
            <div class="section-data">
              <div data-jas="text-provider" class="text-b-17-white">
                {{ resource?.resourceData?.provider?.businessName ?? '-' }}
              </div>
              <div class="text-b-17-white text-data"> Prestador </div>
            </div>
          </div>
          <div class="columns-sections margin-mobile">
            <div
              [class]="
                form.get('preoperational')!.invalid &&
                form.get('preoperational')!.touched &&
                submitted
                  ? 'text-data-error'
                  : 'text-data'
              "
              fxFlex="50%">
              Preoperacional*
            </div>
            <div class="text-data" fxFlex="50%">
              <div *ngIf="isDesktop()">
                <mat-radio-group
                  data-jas="group-options"
                  class="radio-group"
                  formControlName="preoperational">
                  <mat-radio-button
                    [class]="
                      form.get('preoperational')!.invalid &&
                      form.get('preoperational')!.touched &&
                      submitted
                        ? 'custom-radio'
                        : ''
                    "
                    (click)="onRadioChange('NO_APLICA')"
                    data-jas="first-option"
                    [value]="'NO_APLICA'"
                    >No Aplica</mat-radio-button
                  >
                  <mat-radio-button
                    [class]="
                      form.get('preoperational')!.invalid &&
                      form.get('preoperational')!.touched &&
                      submitted
                        ? 'custom-radio'
                        : ''
                    "
                    (click)="onRadioChange('NO')"
                    data-jas="second-option"
                    [value]="'NO'"
                    >No</mat-radio-button
                  >
                  <mat-radio-button
                    [class]="
                      form.get('preoperational')!.invalid &&
                      form.get('preoperational')!.touched &&
                      submitted
                        ? 'custom-radio'
                        : ''
                    "
                    (click)="onRadioChange('SI')"
                    data-jas="third-option"
                    [value]="'SI'"
                    >Si</mat-radio-button
                  >
                </mat-radio-group>
              </div>
            </div>
            <div *ngIf="!isDesktop()" class="capsule-container mt-0-5 display-flex">
              <div
                class="capsule"
                data-cy="status-capsule"
                [class]="
                  form.get('preoperational')?.value === 'NO_APLICA'
                    ? 'capsule-active'
                    : form.get('preoperational')!.invalid &&
                        form.get('preoperational')!.touched &&
                        submitted
                      ? 'capsule-error'
                      : ''
                "
                (click)="onRadioChange('NO_APLICA')"
                ><span>No Aplica</span></div
              >
              <div
                class="capsule"
                data-cy="status-capsule"
                [class]="
                  form.get('preoperational')?.value === 'NO'
                    ? 'capsule-active'
                    : form.get('preoperational')!.invalid &&
                        form.get('preoperational')!.touched &&
                        submitted
                      ? 'capsule-error'
                      : ''
                "
                (click)="onRadioChange('NO')"
                ><span>No</span></div
              >
              <div
                class="capsule"
                data-cy="status-capsule"
                [class]="
                  form.get('preoperational')?.value === 'SI'
                    ? 'capsule-active'
                    : form.get('preoperational')!.invalid &&
                        form.get('preoperational')!.touched &&
                        submitted
                      ? 'capsule-error'
                      : ''
                "
                (click)="onRadioChange('SI')"
                ><span>Si</span></div
              >
            </div>
          </div>
          <div fxLayout="row">
            <div class="container-buttons mb-2">
              <button
                mat-button
                class="btn-accept"
                type="button"
                data-cy="cancel"
                (click)="cancel()"
                >CANCELAR</button
              >
              <button
                mat-button
                class="btn-accept"
                type="button"
                data-cy="save"
                (click)="sendUpdate()"
                >GUARDAR</button
              >
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
