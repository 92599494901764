import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export function rangeValidator(min: number, max: number): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const value = control.value;
    if (
      value !== null &&
      value !== '' &&
      (isNaN(value) || parseInt(value) < min || parseInt(value) > max)
    ) {
      return { range: true };
    }
    return null;
  };
}

@Injectable({
  providedIn: 'root',
})
export class ValidatorsService {
  private _platePattern: string = '^[a-zA-Z0-9]{6}$';
  private _alphanumericPattern: string = '[a-zA-Z0-9]+';
  private _alphabeticPattern: string = '[a-zA-Z]+';
  private _alphabeticWithAccentsPattern: string = '[A-Za-zÀ-ÿ ]+';
  private _alphanumericWithAccentsPattern: string = '[A-Za-zÀ-ÿ0-9 ]+';
  private _numberPattern: string = '[0-9]+';
  private _numberDecimalPattern: string = '^[0-9]+([.][0-9]{1,1})?$';
  private _emailPattern: RegExp =
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  private _generalNamePattern: RegExp = /[a-zA-ZáéíóúÁÉÍÓÚ0-9\s\+]/;

  get plateValidator(): string {
    return this._platePattern;
  }

  get numberValidator(): string {
    return this._numberPattern;
  }

  get numberDecimalValidator(): string {
    return this._numberDecimalPattern;
  }

  get alphabeticValidator(): string {
    return this._alphabeticPattern;
  }

  get alphabeticWithAccentsValidator(): string {
    return this._alphabeticWithAccentsPattern;
  }

  get alphanumericWithAccentsValidator(): string {
    return this._alphanumericWithAccentsPattern;
  }

  get alphanumericValidator(): string {
    return this._alphanumericPattern;
  }

  get emailValidator(): RegExp {
    return this._emailPattern;
  }

  get generalNameValidator(): RegExp {
    return this._generalNamePattern;
  }

  constructor() {}

  /**
   * Verifies that the input only accept digits (0123456789) and Backspace
   */
  public numberOnly(event: any): boolean {
    return (
      /^\d+$/.test(event.key) ||
      ['Backspace', 'Delete', 'ArrowLeft', 'ArrowRight', 'ArrowDown', 'ArrowUp'].includes(event.key)
    );
  }

  /**
   * Verifies that input value only accepts a-z, acent mark, Backspace
   */
  public generalNameOnly(event: any): boolean {
    return (
      /[a-zA-ZáéíóúÁÉÍÓÚ0-9\s\+]/.test(event.key) ||
      event.key == 'Backspace' ||
      event.key === 'Tab' ||
      event.key === '+'
    );
  }

  /**
   * Verifies that the input only accept alphanumeric
   */
  public alphanumericOnly(event: any): boolean {
    const ARROWS_MIN = 36;
    const ARROWS_MAX = 41;
    const MAYUS_MIN = 64;
    const MAYUS_MAX = 91;
    const NUMBER_MIN = 47;
    const NUMBER_MAX = 58;
    const NUMPAD_MIN = 95;
    const NUMPAD_MAX = 106;
    const LETTER_27_MAY = 209;
    const LETTER_27_MIN = 241;
    const LETTER_27_SPEC = 192;
    const DELETE = 8;
    const TAB = 9;
    const SUPR = 46;
    const charCode = event.which ? event.which : event.keyCode;
    return (
      (charCode > MAYUS_MIN && charCode < MAYUS_MAX) ||
      (charCode > ARROWS_MIN && charCode < ARROWS_MAX) ||
      (charCode > NUMBER_MIN && charCode < NUMBER_MAX) ||
      (charCode > NUMPAD_MIN && charCode < NUMPAD_MAX) ||
      charCode === DELETE ||
      charCode === TAB ||
      charCode === SUPR ||
      charCode === LETTER_27_SPEC ||
      charCode === LETTER_27_MIN ||
      charCode === LETTER_27_MAY
    );
  }

  public strictAlphanumeric(event: any): boolean {
    return (
      /^[a-zA-ZáéíóúÁÉÍÓÚñÑ]+$/.test(event.key) ||
      /^\d+$/.test(event.key) ||
      event.key === 'Backspace' ||
      event.key === 'Tab' ||
      event.key === 'ArrowLeft' ||
      event.key === 'ArrowRight' ||
      event.key === 'ArrowUp' ||
      event.key === 'ArrowDown' ||
      event.key === ' '
    );
  }
  public alphanumericSlashInput(event: any): boolean {
    return (
      /[a-zA-ZáéíóúÁÉÍÓÚ0-9\s\+]/.test(event.key) ||
      event.key == 'Backspace' ||
      event.key === 'Tab' ||
      event.key === '+' ||
      event.key === '/' ||
      event.key === '(' ||
      event.key === ')'
    );
  }

  /**
   * Verifies that the input only accepts text
   */
  public textOnly(event: any): boolean {
    const ARROWS_MIN = 36;
    const ARROWS_MAX = 41;
    const MAYUS_MIN = 64;
    const MAYUS_MAX = 91;
    const LETTER_27_MAY = 209;
    const LETTER_27_MIN = 241;
    const LETTER_27_SPEC = 192;
    const DELETE = 8;
    const TAB = 9;
    const SUPR = 46;
    const SPACE = 32;
    const charCode = event.which ? event.which : event.keyCode;
    return (
      (charCode > MAYUS_MIN && charCode < MAYUS_MAX) ||
      (charCode > ARROWS_MIN && charCode < ARROWS_MAX) ||
      charCode === DELETE ||
      charCode === TAB ||
      charCode === SUPR ||
      charCode === SPACE ||
      charCode === LETTER_27_SPEC ||
      charCode === LETTER_27_MIN ||
      charCode === LETTER_27_MAY
    );
  }

  /**
   * Verifies that the input only accept numbers or numbers with decimal
   */
  public numberWithDecimalOnly(event: any): boolean {
    const MIN_CHARCODE = 57;
    const POINT = 190;
    const MIN_NUMPAD = 96;
    const MAX_NUMPAD = 105;
    const SPACE_NUMPAD = 32;
    const charCode = event.which ? event.which : event.keyCode;
    return !(
      (charCode > MIN_CHARCODE &&
        charCode !== POINT &&
        (charCode < MIN_NUMPAD || charCode > MAX_NUMPAD)) ||
      charCode === SPACE_NUMPAD
    );
  }
}

export class CustomValidators {
  static minDate(date: string): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (control.value == null) {
        return null;
      }

      const controlDate = moment.utc(control.value, 'YYYY-MM-DD');

      if (!controlDate.isValid()) {
        return null;
      }

      const validationDate = moment.utc(date);

      return controlDate.isSameOrAfter(validationDate, 'day')
        ? null
        : {
            'date-minimum': {
              'date-minimum': validationDate.format('YYYY-MM-DD'),
              actual: controlDate.format('YYYY-MM-DD'),
            },
          };
    };
  }
  static maxDate(date: string): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (control.value == null) {
        return null;
      }

      const controlDate = moment(control.value, 'YYYY-MM-DD');

      if (!controlDate.isValid()) {
        return null;
      }

      const validationDate = moment(date);

      return controlDate.isSameOrBefore(validationDate, 'day')
        ? null
        : {
            'date-maximum': {
              'date-maximum': validationDate.format('YYYY-MM-DD'),
              actual: controlDate.format('YYYY-MM-DD'),
            },
          };
    };
  }
}
