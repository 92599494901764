import { Injectable } from '@angular/core';
import { ResourcesService } from '../../administration/aph-resource/resources.service';
import moment from 'moment';
import { Resource } from '@smartsoft-types/sisem-resources/dist/src/entities/models/resource/resource.model.entity';
import {
  ParametricService,
  TabActionType,
  TabData,
} from '../../../types/parametric.service.abstract.class';
import { ProfileService } from '../../profile/profile.service';
import { KeyPressedHandlerService } from '../../key-pressed-handler.service';
import { Router } from '@angular/router';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { StepperData } from '../providers-tabs-manager.service';
import { DialogService } from '../../dialog.service';
import { MatDialog } from '@angular/material/dialog';
import { CancelDialogResponsiveComponent } from '../../../component/cancel-dialog-responsive/cancel-dialog-responsive.component';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { lastValueFrom } from 'rxjs';
import { environment } from '../../../../../environments/environment';
import { ServerResponse } from '../../../types/http.interfaces';
import { APHProvider } from '@smartsoft-types/sisem-resources';
import { BubbleData } from '../../../types/parametrics.interface';
import { LoadingService } from '../../loading.service';

@Injectable({ providedIn: 'root' })
export class ManageVehicleService extends ParametricService<ManageVehicleTabData, number> {
  isAddingNewTab: boolean = false;

  constructor(
    private resourcesService: ResourcesService,
    public override profileService: ProfileService,
    private keyPressHandlerService: KeyPressedHandlerService,
    private router: Router,
    private dialogService: DialogService,
    private dialog: MatDialog,
    private http: HttpClient,
    private loadingService: LoadingService
  ) {
    super(keyPressHandlerService, profileService);
  }

  async savePreoperational(data?: IsPreoperational) {
    try {
      return await lastValueFrom(
        this.http.put(
          `${environment.mobileUrl}sisem-core-api/v1/device/configure-preoperational`,
          data
        )
      );
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  async getAllResources(providerId?: number) {
    try {
      let resources: Resource[] = await this.resourcesService.getAll();

      if (providerId) {
        resources = resources.filter((res) => res['provider']?.id === providerId);
      }
      resources = resources.filter(
        (res) =>
          res.resourceType?.code.toLowerCase() === 'tam' ||
          res.resourceType?.code.toLowerCase() === 'tab'
      );
      const transformedResources: TransformedResource[] = [];
      resources.forEach((res) => {
        transformedResources.push(this.transformResource(res));
      });
      return transformedResources;
    } catch (e) {
      console.error(e);
      throw e;
    }
  }

  transformResource(resource: Resource): TransformedResource {
    return {
      id: resource.id,
      date: moment(new Date(resource['characterizationDate'] ?? resource.createdAt)).format(
        'DD/MM/YYYY-HH:mm:ss'
      ),
      plate: resource['licensePlate'],
      resourceData: resource,
      statusColor: `${
        resource.administrativeStatus?.title.toLowerCase() === 'activo'
          ? 'Activo'
          : resource.administrativeStatus?.title.toLowerCase() === 'inactivo'
            ? 'Inactivo'
            : resource.administrativeStatus?.title.toLowerCase() === 'baja'
              ? 'Baja'
              : 'En proceso'
      }|-|${this.transformColor(resource.administrativeStatus?.title ?? 'Inactivo')}`,
      type: resource.resourceType?.code.toLowerCase() === 'tam' ? 'Medicalizada' : 'Básica',
      code: resource.code,
      status:
        resource.administrativeStatus?.code === 'active'
          ? 'Activo'
          : resource.administrativeStatus?.code === 'inactive'
            ? 'Inactivo'
            : resource.administrativeStatus?.code === 'withdrawn'
              ? 'Baja'
              : 'En proceso',
    };
  }

  transformColor(state: string) {
    if (state === 'Baja') {
      return 'circle-error';
    } else if (state === 'Activo') {
      return 'circle-active';
    } else if (state === 'Inactivo') {
      return 'circle-error';
    } else {
      return 'circle-inactive';
    }
  }

  override moveTab() {}

  override isSafeToCloseTab(event: number): boolean {
    const tab = this._tabs.find((tab) => tab.id === event);
    if (!tab) {
      return true;
    }
    return !tab.tabData.wasChange;
  }

  canChangeSection() {
    return true;
  }

  override async closeTab(id: number, force?: boolean) {
    const tabId = this.selectedTab?.id;
    if (force) {
      if (tabId) {
        super.closeTab(tabId);
      }
      await this.router.navigateByUrl('/home/providers/manageVehicle');
      return;
    }

    if (tabId) {
      if (!this.isSafeToCloseTab(tabId)) {
        if (window.innerWidth >= 1024) {
          const title = 'cancelar caracterización del vehículo'.toUpperCase();

          const closeModal = await this.dialogService.yesNoOptionModal(
            title,
            '¿Está seguro de cerrar la pestaña? Los cambios realizados serán descartados',
            'cancel'
          );
          if (closeModal === 'cancel') {
            super.closeTab(tabId);
            await this.router.navigateByUrl('/home/providers/manageVehicle');
          }
        } else {
          const dialog = this.dialog.open(CancelDialogResponsiveComponent, {
            data: {
              mainTitle: 'CANCELAR',
              secondTitle: '¿Está seguro de cerrar la pestaña?',
              message: 'Los cambios realizados serán descartados',
            },
            panelClass: 'add-contact-modal',
            width: '100vw',
            maxWidth: '100vw',
            position: {
              bottom: '0px',
            },
          });
          dialog.afterClosed().subscribe(async (action: string) => {
            if (action === 'yes') {
              super.closeTab(tabId);
              await this.router.navigateByUrl('/home/providers/manageVehicle');
            }
          });
        }
      } else {
        super.closeTab(tabId);
        await this.router.navigateByUrl('/home/providers/manageVehicle');
      }
    }
  }

  override async newTab(actionType: TabActionType, options?: number) {
    if (this.isAddingNewTab) return;

    this.isAddingNewTab = true;
    const tab = this.tabs.find((t) => t.tabData.selectedResource?.id === options);
    if (tab) {
      await this.selectTab(tab.id);
      this.isAddingNewTab = false;
      return;
    }

    const tabId = ++this._tabIdCounter;
    let title = '';
    let resource: Resource | undefined;

    if (options) {
      try {
        this.loadingService.setLoading(true);
        resource = await this.getResourceById(options);
      } catch (error) {
        console.error(error);
        this.dialogService.informativeResultModal('Ocurrió un error inesperado', 'error');
      }
      this.loadingService.setLoading(false);
    }

    if (!resource) {
      await this.router.navigateByUrl('/home/providers/manageVehicle');
      this.isAddingNewTab = false;
      return;
    }

    if (actionType === TabActionType.EDIT) {
      title = `CARACTERIZAR VEHÍCULO ${resource?.code}`;
    } else if (actionType === TabActionType.VIEW) {
      title = 'VALIDAR CARACTERIZACIÓN';
    }

    const tabData: TabData<ManageVehicleTabData> = {
      id: tabId,
      edition: false,
      createdByIp: '',
      title,
      createdDate: [],
      creationInfoExpanded: false,
      createdBy: '',
      actionType: actionType,
      keysSubscriptionName: '',
      tabData: {
        step1Form: new FormGroup({}),
        selectedResource: resource,
        step2Form: new FormGroup({}),
        step3Form: new FormGroup({}),
        isEditing: actionType === TabActionType.EDIT,
        selectedIndex: 0,
        stepperData: {
          stepsData: [
            {
              currentStep: 'Datos Generales',
              nextStep: 'Datos Técnicos',
              index: 0,
            },
            {
              currentStep: 'Datos Técnicos',
              nextStep: 'Datos Complementarios',
              index: 1,
            },
            {
              currentStep: 'Datos Complementarios',
              nextStep: '',
              index: 2,
            },
          ],
          selectedStep: 0,
        },
        showRegistrationExpandables: 'none',
        progressBar: [
          { show: false, progress: 0 },
          { show: false, progress: 0 },
          { show: false, progress: 0 },
        ],
        filesArray: [
          { file: undefined, fileUrl: undefined },
          { file: undefined, fileUrl: undefined },
          { file: undefined, fileUrl: undefined },
        ],
        showTechnoFields: false,
        form3Initialized: false,
        form2Initialized: false,
        form1Initialized: false,
      },
      icon: 'ambulance',
    };
    this.initializeForm(tabData);
    this._tabs.push(tabData);
    await this.selectTab(tabData.id);
    this.isAddingNewTab = false;
  }

  override async selectTab(id: number) {
    await this.router.navigateByUrl('/home/providers/manageVehicle');
    super.selectTab(id);
    if (this.selectedTab?.actionType === TabActionType.EDIT) {
      await this.router.navigateByUrl(
        `/home/providers/manageVehicle/edit/${this.selectedTab?.tabData.selectedResource.id}`
      );
    } else {
      await this.router.navigateByUrl(
        `/home/providers/manageVehicle/confirm/${this.selectedTab?.tabData.selectedResource.id}`
      );
    }
  }

  initializeForm(currentData: TabData<ManageVehicleTabData>) {
    currentData.tabData.step1Form = new FormGroup({
      code: new FormControl('', Validators.required),
      vehicleClass: new FormControl('', Validators.required),
      propertyCardNumber: new FormControl('', Validators.required),
      licenseExpeditionDate: new FormControl('', Validators.required),
      vehicleBrand: new FormControl('', Validators.required),
      vehicleModelYear: new FormControl('', Validators.required),
      vehicleFuelType: new FormControl(''),
      vehicleCylinder: new FormControl('', Validators.max(9999)),
      vehiclePower: new FormControl('', Validators.max(9999)),
      vehicleLine: new FormControl(''),
      vehicleTraction: new FormControl(''),
      vehicleRim: new FormControl(''),
      registration: new FormControl('', Validators.required),
      transitAgency: new FormControl(''),
      licensePlateDepartmentId: new FormControl('', [Validators.required]),
      licensePlateMunicipalityId: new FormControl('', [Validators.required]),
    });

    currentData.tabData.step2Form = new FormGroup({
      soatNumber: new FormControl('', [Validators.required]),
      soaEntity: new FormControl('', [Validators.required]),
      soatStartDate: new FormControl('', Validators.required),
      soatEndDate: new FormControl(''),
      technoReview: new FormControl('', Validators.required),
      technoMechanicalNumber: new FormControl(''),
      ministryCDA: new FormControl(''),
      technoMechanicalStartDate: new FormControl(''),
      technoMechanicalEndDate: new FormControl(''),
      base: new FormControl(''),
    });
    currentData.tabData.step2Form.get('soatEndDate')?.disable();
    currentData.tabData.step2Form.get('technoMechanicalEndDate')?.disable();

    currentData.tabData.step3Form = new FormGroup({
      contract: new FormControl('', Validators.required),
      vehicleModality: new FormControl('', Validators.required),
      isAPH: new FormControl(undefined, Validators.required),
      contractSince: new FormControl(''),
      contractTo: new FormControl(''),
    });
    currentData.tabData.step3Form.get('contractSince')?.disable();
    currentData.tabData.step3Form.get('contractTo')?.disable();
  }

  async characterizeVehicle(data: FormData, id: number) {
    await lastValueFrom(
      this.http.patch(
        `${environment.api}/resources-ms/api/v1/resources/aphResources/${id}/characterization`,
        data
      )
    );
    await this.resourcesService.getById(id, true);
  }

  async getProvider(id: number) {
    const params = new HttpParams()
      .set('fetchContactData', true)
      .set('fetchProviderType', true)
      .set('fetchOffice', true)
      .set('fetchPrincipalHeadquarter', true);

    const response = await lastValueFrom(
      this.http.get<ServerResponse<APHProvider>>(
        `${environment.api}/resources-ms/api/v1/parametrics/aphProvider/${id}`,
        { params }
      )
    );

    return response.data;
  }

  async validateCharacterization(body: CharacterizationValidate, id: number) {
    await lastValueFrom(
      this.http.patch(
        `${environment.api}/resources-ms/api/v1/resources/aphResources/${id}/validateCharacterization`,
        { data: body }
      )
    );
    this.resourcesService.flushCache();
    await this.resourcesService.getById(id, true);
  }

  async getResourceById(id: number, fetchImages = true) {
    let params = new HttpParams();
    if (fetchImages) params = params.set('fetchImages', true);

    const resp = await lastValueFrom(
      this.http.get<ServerResponse<Resource>>(
        `${environment.api}/resources-ms/api/v1/resources/aphResources/${id}`,
        { params }
      )
    );
    return resp.data;
  }

  removeWhiteSpace(form: FormGroup, control: string) {
    form.get(control)?.setValue(form.get(control)?.value.trim());
  }

  getImageFormat(url: string): Promise<string | undefined> {
    return new Promise((resolve, reject) => {
      const headers = new HttpHeaders({
        'Content-Type': 'application/octet-stream',
        Accept: 'image/*',
        skip: 'true',
      });

      this.http.get(url, { headers, responseType: 'blob' }).subscribe(
        (response: Blob) => {
          const reader = new FileReader();
          reader.onloadend = function () {
            const arr = new Uint8Array(reader.result as ArrayBuffer).subarray(0, 4);
            let header = '';
            // Convierte los bytes en una cadena hexadecimal
            for (let i = 0; i < arr.length; i++) {
              header += arr[i].toString(16);
            }
            // Compara los bytes iniciales con las cabeceras de formatos de imagen conocidos
            if (header.startsWith('89504e47')) {
              resolve('png');
            } else if (header === '47494638') {
              resolve('gif');
            } else if (header.startsWith('ffd8ff')) {
              resolve('jpg');
            } else if (header.startsWith('ffd8')) {
              resolve('jpeg');
            } else if (header.startsWith('424d')) {
              resolve('bmp');
            } else {
              resolve('png');
            }
          };
          reader.onerror = function (error) {
            reject(error);
          };
          reader.readAsArrayBuffer(response);
        },
        (error: HttpErrorResponse) => {
          reject(error);
        }
      );
    });
  }
}

export interface ManageVehicleTabData {
  step1Form: FormGroup;
  step2Form: FormGroup;
  step3Form: FormGroup;
  isEditing: boolean;
  selectedResource: Resource;
  selectedIndex: number;
  stepperData: StepperData;
  showRegistrationExpandables: 'none' | 'location' | 'transitAgency';
  progressBar: Array<{ show: boolean; progress: number }>;
  filesArray: Array<{ file?: File; fileUrl?: string; fileName?: string }>;
  providerId?: number;
  showTechnoFields: boolean;
  wasChange?: boolean;
  form1Initialized: boolean;
  form2Initialized: boolean;
  form3Initialized: boolean;
}

export interface TransformedResource {
  id: number;
  resourceData: Resource;
  statusColor: string;
  plate: string;
  type: string;
  date: string;
  code: string;
  status: string;
  preoperational?: string;
}

export interface Rin {
  id: number;
  title: string;
}
export interface CharacterizationValues {
  name: string;
  value: string;
}

export interface ManageVehicleFilterBubble {
  from: BubbleData;
  to: BubbleData;

  plate: BubbleData;
  vehicleCode: BubbleData;
  providerName: BubbleData;

  basic: BubbleData;
  medicalized: BubbleData;

  inProgress: BubbleData;
  active: BubbleData;
  inactive: BubbleData;
  toValidate: BubbleData;
  returned: BubbleData;
  characterized: BubbleData;

  [k: string]: BubbleData;
}

export interface CharacterizationValidate {
  approveCharacterization: boolean;
  observations?: string;
}

export interface IsPreoperational {
  preoperational: string;
  resource_type: string;
  license_plate: string;
}
