/**
 * @deprecated Se va a eliminar en un futuro, ya que rompe el propósito de ver el sistema en permisos y no roles
 * Evitar su uso al máximo, utilizar directamente los permisos
 */
export enum KnownRoles {
  TARM = 'tarm',
  MEDIC_REGULATION = 'medic_regulation',
  MENTAL = 'mental',
  DELIVERY = 'delivery',
  NURSE = 'nurse',
  MEDIAL_AUDIT = 'medical_audit',
  TARM_REFERENCE = 'tarm_reference',
  TARM_EMERGENCY_NETWORK = 'tarm_emergency_network',
  ADMINISTRATOR = 'administrador',
  AUDITOR = 'auditor',
  LEAD_MEDIC = 'lead-medic',
  APH_PROVIDER = 'aph-provider',
  TRANSPORT_MANAGER = 'transport_manager',
  TRANSPORT_COORDINATOR = 'transport_coordinator',
  APH_REFERENT = 'aph_referent',
  INFORMATION_MANAGER = 'information_manager',
  MANAGERS_COORDINATOR = 'managers_coordinator',
  RADIO_COMM = 'radio-comm',
  HOSPITAL = 'hospital',
  LEAD_PORTAL_NURSE = 'lead_portal_nurse',
  PORTAL_AUXILIAR = 'portal_auxiliar',
  PHARM_AUXILIAR = 'medicine_dispenser_auxiliary',
  MECHANICAL_TECHNICAL = 'mechanical_technical',
  COURSE_PARTICIPANT = 'course_participant',
  BIOMEDIC = 'biomedic_engineer',
  LEAD_APH = 'lead_aph',
  DRIVER = 'driver',
  LINK_PROFESSIONAL = 'link_professional',
}
/**
 * @deprecated Se va a refactorizar en un futuro, ya que se debe cambiar a utilizar la API real, limitar su uso
 */
export const RolesToSpanishHash: { [type in KnownRoles]: string } = {
  [KnownRoles.ADMINISTRATOR]: 'Administrador',
  [KnownRoles.TARM]: 'TARM',
  [KnownRoles.TARM_REFERENCE]: 'TARM Referencia',
  [KnownRoles.TARM_EMERGENCY_NETWORK]: 'TARM Red Emergencias',
  [KnownRoles.DELIVERY]: 'Despacho',
  [KnownRoles.MEDIC_REGULATION]: 'Regulación Médica',
  [KnownRoles.MEDIAL_AUDIT]: 'Auditoría Médica',
  [KnownRoles.MENTAL]: 'Salud Mental',
  [KnownRoles.NURSE]: 'Enfermero',
  [KnownRoles.LEAD_MEDIC]: 'Médico Líder',
  [KnownRoles.AUDITOR]: 'Auditoría',
  [KnownRoles.APH_PROVIDER]: 'Prestador',
  [KnownRoles.TRANSPORT_MANAGER]: 'Gestor de transporte',
  [KnownRoles.TRANSPORT_COORDINATOR]: 'Coordinador de transporte',
  [KnownRoles.APH_REFERENT]: 'Referente APH',
  [KnownRoles.INFORMATION_MANAGER]: 'Gestor de Información',
  [KnownRoles.MANAGERS_COORDINATOR]: 'Coordinador de Gestores',
  [KnownRoles.RADIO_COMM]: 'Radiocomunicaciones',
  [KnownRoles.HOSPITAL]: 'Hospital',
  [KnownRoles.LEAD_PORTAL_NURSE]: 'Enfermero lider portal',
  [KnownRoles.PORTAL_AUXILIAR]: 'Auxiliar portal',
  [KnownRoles.PHARM_AUXILIAR]: 'Auxiliar de farmacia',
  [KnownRoles.MECHANICAL_TECHNICAL]: 'Técnico Automotriz',
  [KnownRoles.COURSE_PARTICIPANT]: 'Participante a cursos',
  [KnownRoles.BIOMEDIC]: 'Ingeniero Biomédico',
  [KnownRoles.LEAD_APH]: 'Líder aph',
  [KnownRoles.DRIVER]: 'Conductor',
  [KnownRoles.LINK_PROFESSIONAL]: 'Profesional de Enlace',
};
