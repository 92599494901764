import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-cancel-dialog-responsive',
  templateUrl: './cancel-dialog-responsive.component.html',
  styleUrls: ['./cancel-dialog-responsive.component.scss'],
})
export class CancelDialogResponsiveComponent implements OnInit {
  touchStartPos: number = 0;
  touchEndPos: number = 0;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      mainTitle: string;
      secondTitle: string;
      message: string;
    },
    public dialogRef: MatDialogRef<CancelDialogResponsiveComponent>
  ) {}

  ngOnInit(): void {
    this.dialogRef.disableClose = true;
  }

  onTouchStart(event: TouchEvent) {
    this.touchStartPos = event.changedTouches[0].screenY;
  }

  onTouchEnd(event: TouchEvent) {
    this.touchEndPos = event.changedTouches[0].screenY;

    if (this.touchEndPos > this.touchStartPos + 100) {
      this.dialogRef.close('no');
    }

    setTimeout(() => {
      this.touchEndPos = this.touchStartPos = 0;
    }, 10);
  }
}
