import { Component, Inject, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ComponentType } from '@angular/cdk/portal';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss'],
})
export class ModalComponent {
  public title: string;
  public titleIcon: string;
  public additionalInfo: any;
  public html: ComponentType<any>;
  @ViewChild('htmlComponent') htmlComponent: any;
  constructor(
    public dialogRef: MatDialogRef<ModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.title = this.data.modalConfig.title || '';
    this.titleIcon = this.data.modalConfig.titleIcon || 'info-message';
    this.html = this.data.html;
    this.additionalInfo = this.data.additionalInfo;
  }

  close(data?: string | boolean) {
    this.dialogRef.close(data);
    // this.forceRefresh();
  }
}
