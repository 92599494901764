import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { BehaviorSubjectService } from './shared/services/behaviorSubject.service';
import { LoadingService } from './shared/services/loading.service';
import { environment } from '../environments/environment';
import { KeyPressedHandlerService } from './shared/services/key-pressed-handler.service';
import { BreadcrumbService } from './shared/services/breadcrumb/breadcrumb.service';
import { GeocoderService } from './shared/component/select-map-location/geocoder.service';
import { AuthService } from './shared/services/auth/auth.service';
import { AlertService } from './shared/services/alert.service';
import { PermissionsValidatorService } from './shared/services/roles-and-permissions/permissions-validator.service';
import { SidebarService } from './shared/services/sidebar/sidebar.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, AfterViewInit, OnDestroy {
  title = 'SISEM-FrontWeb';
  private lastVH = -1;
  private lastVW = -1;

  public readonly showIcon =
    environment.authService.includes('dev.') ||
    environment.authService.includes('qa.') ||
    environment.authService.includes('qa2.');

  constructor(
    public loadingService: LoadingService,
    private behaviorSubjectService: BehaviorSubjectService,
    private keyPressedHandlerService: KeyPressedHandlerService,
    private breadcrumbService: BreadcrumbService,
    private geocoderService: GeocoderService,
    private authService: AuthService,
    private toast: AlertService,
    private loading: LoadingService,
    private permissionsValidatorService: PermissionsValidatorService,
    private sidebarService: SidebarService
  ) {}

  ngAfterViewInit(): void {
    this.keyPressedHandlerService.initHostListener();
    this.geocoderService.testApiKey().then();
  }

  async temp() {
    /**
     * Nota para sergio, restaurar tiempo de token y expiración sesión
     */
    this.loading.setLoading(true);
    try {
      const result = await this.authService.refreshToken();
      if (result) {
        await this.permissionsValidatorService.refreshCachedRole();
        await this.sidebarService.getSidebarItems();
      }
      this.toast.showInformation('Actualizados permisos');
    } catch (e) {
      console.error(e);
      this.toast.showError(e as string);
    }
    this.loading.setLoading(false);
  }

  private calculateViewport = () => {
    // We execute the same script as before
    const vh = window.innerHeight * 0.01;
    const vw = window.innerWidth * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
    document.documentElement.style.setProperty('--vw', `${vw}px`);
    this.behaviorSubjectService.bSubjectSizeScreen.next(vw);
    this.behaviorSubjectService.bSubjectSizeScreenGlobal.next(vw);
    this.behaviorSubjectService.screenHeight.next(vh);
    return { vh, vw };
  };

  ngOnInit() {
    this.breadcrumbService.initializeSubscription();
    /**
     *capture the size of the viewport height
     *calculate the equivalence to 1vh and
     *save the value creating a css variable
     */

    window.addEventListener('resize', this.calculateViewport);
    const eventFired = false;
    window.addEventListener('orientationchange', () => this.onChangeOrientation(eventFired));
    const { vh, vw } = this.calculateViewport();
    this.lastVH = vh;
    this.lastVW = vw;
    if ('serviceWorker' in navigator) {
      navigator.serviceWorker.getRegistrations().then(function (registrations) {
        for (const registration of registrations) {
          // unregister service worker
          registration.unregister();
        }
      });
    }
    if (environment.api.includes('dev')) {
      const htmlReference = document.getElementById('main-application');
      if (localStorage.getItem('theme')) {
        htmlReference?.classList.add(localStorage.getItem('theme')!);
        // }else if(!window.matchMedia('(prefers-color-scheme: dark)').matches) {
        //   htmlReference!.classList.add('light-mode');
      }
    }
  }

  onChangeOrientation(eventFired: boolean) {
    if (eventFired) {
      return;
    }
    eventFired = true;
    let running = true;
    for (const delay of [50, 100, 200, 300, 500, 800, 1000]) {
      setTimeout(() => {
        if (running) {
          const { vh, vw } = this.calculateViewport();
          if (vh !== this.lastVH || vw !== this.lastVW) {
            this.lastVH = vh;
            this.lastVW = vw;
            running = false;
          }
        }
      }, delay);
    }
    eventFired = false;
  }

  ngOnDestroy(): void {
    this.breadcrumbService.destroySubscription();
  }

  protected readonly window = window;
  protected readonly environment = environment;
}
