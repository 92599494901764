import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import {
  ParametricService,
  TabActionType,
  TabData,
} from '../../../types/parametric.service.abstract.class';
import { OpenTabOptions } from '../aph-resource/resources.service';
import { KeyPressedHandlerService } from '../../key-pressed-handler.service';
import { ServerResponse } from '../../../types/http.interfaces';
import { lastValueFrom } from 'rxjs';
import { environment } from '../../../../../environments/environment';
import { Router } from '@angular/router';
import { DialogService } from '../../dialog.service';
import {
  APHProvider,
  Contract,
  ContractCreateInput,
  ContractUpdateInput,
} from '@smartsoft-types/sisem-resources';
import { DatePipe } from '@angular/common';
import { TableKeyItem } from '../../../types/table';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ValidatorsService } from '../../validators.service';
import { ParametricsInterface } from '../../../types/parametrics.interface';
import { ServiceInfo } from '../../../types/contract.interface';
import { Attachment } from '@smartsoft-types/sisem-resources/dist/src/entities/models/attachment/attachment.model.entity';
import { CachedCRUDService } from '../../../types/cached-c-r-u-d.service';
import { ProfileService } from '../../profile/profile.service';
import { ProviderService } from '../provider/provider.service';
import { ProviderTypeService } from '../provider/provider.type.service';
import { ResourcesAssociationTypesService } from '../aph-resource/resources.association.types.service';
import { ResourcesServiceTypeService } from '../aph-resource/resources.service.type.service';

export interface ContractTabData {
  selectedService: boolean;
  touched: boolean;
  modalTitle: string;
  step1Form: FormGroup;
  selectedContract?: Contract;
  uniqueIdentifier?: number;
  service: ServiceInfo[];
  files: Array<{ file?: File; nameFile: string; id?: number }>;
  associationTypes: ParametricsInterface[];
  provider: APHProvider[];
  filteredProviders: APHProvider[];
  serviceType: ParametricsInterface[];
  aphProviderType: ParametricsInterface[];
  contractForm: boolean;
  isEditing: boolean;
}

export const STATE: Array<{
  id: number;
  title: string;
  label: 'on_study' | 'cancelled' | 'on_execution' | 'finished';
}> = [
  {
    id: 2,
    title: 'En Estudio',
    label: 'on_study',
  },
  {
    id: 1,
    title: 'En Ejecución',
    label: 'on_execution',
  },
  {
    id: 3,
    title: 'Finalizado',
    label: 'finished',
  },
  {
    id: 4,
    title: 'Cancelado',
    label: 'cancelled',
  },
];

export const UNIT: Array<{
  id: number;
  title: string;
  label: 'day' | 'hour' | 'week' | 'month';
}> = [
  {
    id: 1,
    title: 'Hora',
    label: 'hour',
  },
  {
    id: 2,
    title: 'Día',
    label: 'day',
  },
  {
    id: 3,
    title: 'Semana',
    label: 'week',
  },
  {
    id: 4,
    title: 'Mes',
    label: 'month',
  },
];

class ContractsServiceTabManager extends ParametricService<ContractTabData, OpenTabOptions> {
  constructor(
    private keyPressHandleService: KeyPressedHandlerService,
    private validatorsService: ValidatorsService,
    private router: Router,
    private contractsService: ContractsService,
    public override profileService: ProfileService,
    private providerService: ProviderService,
    private providerTypeService: ProviderTypeService,
    private resourcesAssociationTypesService: ResourcesAssociationTypesService,
    private resourcesServiceTypeService: ResourcesServiceTypeService
  ) {
    super(keyPressHandleService, profileService);
    super.newTabAction = {
      icon: '',
      toolTip: 'Nuevo Contrato o Convenio',
    };
  }

  /**
   *
   * @param actionType action to be performed register or edit
   * @param options id record
   */
  override async newTab(actionType: TabActionType, options?: OpenTabOptions) {
    let text = '';
    // validate if already open
    if (options?.id) {
      const result = this.tabs.find((value) => value.tabData.selectedContract?.id === options.id);
      if (result) {
        this.selectTab(result.id);
        return;
      }
    }
    //
    const resource = options?.id
      ? await this.contractsService.getById(options.id, true)
      : undefined;
    const tabId = ++this._tabIdCounter;
    const contracts = (await this.contractsService.getAll()).sort((a, b) => {
      return b.id - a.id;
    });
    if (actionType === TabActionType.CREATE) {
      text = `NUEVO CONTRATO O CONVENIO (${tabId})`;
    } else if (actionType === TabActionType.EDIT) {
      if (!resource) {
        return;
      }
      text = 'EDITAR CONTRATO O CONVENIO ' + resource.id;
    }
    const tabData: TabData<ContractTabData> = {
      id: tabId,
      edition: false,
      createdDate: [],
      creationInfoExpanded: false,
      createdBy: '',
      createdByIp: '',
      tabData: {
        isEditing: false,
        touched: false,
        step1Form: new FormGroup({}),
        selectedContract: resource ? resource.ContractDB : undefined,
        service: [],
        files: [],
        selectedService: false,
        uniqueIdentifier: (contracts[0]?.ContractDB?.id || 0) + 1,
        modalTitle: actionType === TabActionType.CREATE ? 'CREAR' : 'EDITAR',
        contractForm: false,
        associationTypes: (await this.resourcesAssociationTypesService.getAll())
          .map((associationType) => {
            return {
              ...associationType,
              title: this.capitalizeFirstLetter(associationType.title.toLowerCase()),
            };
          })
          .filter((type) => type.title !== 'Ninguna'),
        provider: (await this.providerService.getAll()).map((provider) => {
          return {
            ...provider,
            title: this.capitalizeFirstLetter(provider.title.toLowerCase()),
          };
        }),
        filteredProviders: [],
        serviceType: (await this.resourcesServiceTypeService.getAll()).map((serviceType) => {
          return {
            ...serviceType,
            title: this.capitalizeFirstLetter(serviceType.title),
          };
        }),
        aphProviderType: (await this.providerTypeService.getAll()).map((aphType) => {
          return {
            ...aphType,
            title: this.capitalizeFirstLetter(aphType.title.toLowerCase()),
          };
        }),
      },
      actionType: actionType,
      title: text,
      keysSubscriptionName: '',
    };
    if (this._tabs.length > 0) {
      const tabFound = this._tabs[this._tabs.length - 1];
      tabData.tabData.uniqueIdentifier = (tabFound.tabData.uniqueIdentifier ?? 0) + 1;
    }
    this.generateForms(tabData);
    this._tabs.push(tabData);
    this.selectTab(tabData.id);
  }

  /**
   *
   * @param id
   */
  override selectTab(id: number) {
    super.selectTab(id);
    this.router
      .navigateByUrl('/home/administration/contracts/list', {
        skipLocationChange: true,
      })
      .then(() => {
        if (this._selectedTab?.actionType === TabActionType.CREATE) {
          this.router.navigateByUrl('/home/administration/contracts/create/' + id);
        } else if (this._selectedTab?.actionType === TabActionType.EDIT) {
          this.router.navigateByUrl(
            '/home/administration/contracts/edit/' + this._selectedTab.tabData.selectedContract?.id
          );
        }
      });
  }

  override isSafeToCloseTab(id: number): boolean {
    const tab = this.getTabData(id);
    if (tab) {
      // validate if is safe to close
      if (tab.tabData.step1Form.dirty) {
        return false;
      }
    }
    return true;
  }

  override getTabData(id: number, resourceId?: number): TabData<ContractTabData> {
    if (resourceId)
      return (
        this._tabs.find(
          (value) => value.tabData.selectedContract?.id.toString() === resourceId.toString()
        ) ?? this._tabs[0]
      );
    return this._tabs.find((value) => value.id === id) ?? this._tabs[0];
  }

  private generateForms(tabData: TabData<ContractTabData>) {
    tabData.tabData.step1Form = new FormGroup({
      uniqueIdentifier: new FormControl({ value: '', disabled: true }, [
        Validators.required,
        Validators.pattern(this.validatorsService.numberValidator),
        Validators.maxLength(30),
      ]),
      type: new FormControl('', [Validators.required]),
      number: new FormControl('', [
        Validators.required,
        Validators.pattern(this.validatorsService.alphanumericValidator),
      ]),
      object: new FormControl('', [
        Validators.required,
        Validators.pattern(this.validatorsService.alphabeticWithAccentsValidator),
      ]),
      typeProvider: new FormControl('', [Validators.required]),
      provider: new FormControl({ value: '', disabled: true }, [Validators.required]),
      nit: new FormControl({ value: '', disabled: true }, [Validators.required]),
      startDate: new FormControl('', [Validators.required]),
      dateEnd: new FormControl('', [Validators.required]),
      totalValue: new FormControl('', [Validators.required]),
      state: new FormControl('', [Validators.required]),
      typeService: new FormControl(''),
      value: new FormControl(''),
      unit: new FormControl(''),
      documentName: new FormControl(
        '',
        Validators.pattern(this.validatorsService.alphabeticWithAccentsValidator)
      ),
      executedValue: new FormControl(''),
    });
    tabData.tabData.selectedService = false;
  }

  private capitalizeFirstLetter(str: string) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }
}

@Injectable({
  providedIn: 'root',
})
export class ContractsService extends CachedCRUDService<
  ContractData,
  {
    body: ContractCreateInput;
    files: Array<{ file?: File; nameFile: string; id?: number }>;
  },
  {
    body: ContractUpdateInput;
    files: Array<{ file?: File; nameFile: string; id?: number }>;
    filesContract: Attachment[];
    filesToDelete?: Array<{ file?: File; nameFile: string; id?: number }>;
  }
> {
  public tabs: ContractsServiceTabManager;
  _contractUrl = `${environment.api}/resources-ms/api/v1/contracts`;

  searchForm: FormGroup = new FormGroup({
    searchCriteria: new FormControl(''),
    since: new FormControl(''),
    until: new FormControl(''),
    textSearch: new FormControl(''),
    predictiveField: new FormControl(''),
  });

  filteredContracts: ContractData[];

  dateFilter: boolean = false;
  predictiveFilter: boolean = false;
  textFilter: boolean = false;

  textTitle: string = '';
  predictiveTitle: string = '';

  sinceMaxDate: Date;
  untilMaxDate: Date;

  predictiveOptions: TableKeyItem[] = [];

  constructor(
    private http: HttpClient,
    private keyPressHandleService: KeyPressedHandlerService,
    private router: Router,
    private dialogService: DialogService,
    private datePipe: DatePipe,
    private validatorsService: ValidatorsService,
    public profileService: ProfileService,
    private providerService: ProviderService,
    private providerTypeService: ProviderTypeService,
    private resourcesAssociationTypesService: ResourcesAssociationTypesService,
    private resourcesServiceTypeService: ResourcesServiceTypeService
  ) {
    super();
    this.tabs = new ContractsServiceTabManager(
      keyPressHandleService,
      validatorsService,
      router,
      this,
      profileService,
      providerService,
      providerTypeService,
      resourcesAssociationTypesService,
      resourcesServiceTypeService
    );
  }

  // private override _fe

  protected override async _fetchNewData(): Promise<ContractData[]> {
    const res: ServerResponse<Contract[]> = await lastValueFrom(
      this.http.get<ServerResponse<Contract[]>>(this._contractUrl)
    );
    const contracts: ContractData[] = [];
    res.data.forEach((contract) => contracts.push(this.transformContract(contract)));
    return contracts;
  }

  protected override async _getByIdData(id: number): Promise<ContractData | undefined> {
    const params = new HttpParams().set('fetchAttachments', true);
    const res = await lastValueFrom(
      this.http.get<ServerResponse<Contract>>(`${this._contractUrl}/${id}`, { params })
    );
    return this.transformContract(res.data);
  }

  protected override async _postData(input: {
    body: ContractCreateInput;
    files: Array<{ file?: File; nameFile: string; id?: number }>;
  }): Promise<ContractData> {
    const formData = this.getSaveUpdateBody(input.body, input.files);
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'multipart/form-data');
    const res = await lastValueFrom(
      this.http.post<ServerResponse<Contract>>(this._contractUrl, formData, {
        headers,
      })
    );
    const fixedRes = await this.getById(res.data.id, true);
    if (!fixedRes) {
      throw new Error('Could not fetch updated data, strange case, this should not happen');
    }
    this.removeId(res.data.id); // get by id adds to cache, do this to avoid duplication
    return fixedRes;
  }

  private getSaveUpdateBody(
    body: ContractCreateInput | ContractUpdateInput,
    files: Array<{ file?: File; nameFile: string; id?: number }>,
    _filesContract?: Attachment[],
    filesToDelete?: Array<{ file?: File; nameFile: string; id?: number }>
  ) {
    const formData = new FormData();
    formData.set('auditData', JSON.stringify(body.auditData));
    const attachments: Array<{
      data: { givenName: string; id?: number; remove?: boolean };
    }> = [];
    let count: number = 0;
    files.forEach((file) => {
      if (file.file !== undefined) {
        attachments[count] = {
          data: {
            givenName: file.nameFile,
          },
        };
        formData.set(`file-${count}`, file.file);
        count++;
      }
    });
    if (filesToDelete) {
      filesToDelete.forEach((file) => {
        attachments.push({
          data: {
            givenName: file.nameFile,
            remove: true,
            id: file.id,
          },
        });
      });
    }
    formData.set(
      'data',
      JSON.stringify({
        ...body.data,
        attachments,
      })
    );
    return formData;
  }

  protected override async _putData(
    id: number,
    input: {
      body: ContractUpdateInput;
      files: Array<{ file?: File; nameFile: string; id?: number }>;
      filesContract: Attachment[];
      filesToDelete?: Array<{
        file?: File;
        nameFile: string;
        id?: number;
      }>;
    }
  ): Promise<ContractData> {
    const formData = this.getSaveUpdateBody(
      input.body,
      input.files,
      input.filesContract,
      input.filesToDelete
    );
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'multipart/form-data');
    try {
      await lastValueFrom(
        this.http.patch<ServerResponse<Contract>>(`${this._contractUrl}/${id}`, formData, {
          headers,
        })
      );
      const fixedRes = await this.getById(id, true);
      if (!fixedRes) {
        throw new Error('Could not fetch updated data, strange case, this should not happen');
      }
      return fixedRes;
    } catch (e: unknown) {
      throw e;
    }
  }

  transformContract(contract: Contract): ContractData {
    const transformTitle = (title: string) => title.charAt(0).toUpperCase() + title.substring(1);
    const changedTitles: string[] = contract.status?.title
      .split(' ')
      .map((t) => transformTitle(t)) ?? ['-'];
    const typeTitle = contract.associationType?.title ?? '-';
    return {
      ContractDB: contract,
      id: contract.id,
      lender: contract.aphProvider?.title
        ? this.capitalizeFirstLetter(contract.aphProvider.title.toLowerCase())
        : '',
      lenderClass: contract.aphProviderType?.title
        ? this.capitalizeFirstLetter(contract.aphProviderType.title.toLowerCase())
        : '',
      startD: this.datePipe.transform(contract.startDate, 'dd/MM/YYYY') as string,
      statusColor: `${changedTitles.join(' ')}|-|${this.getContractStatusColor(contract)}`,
      type: typeTitle === '-' ? typeTitle : this.capitalizeFirstLetter(typeTitle.toLowerCase()),
      number: contract.code,
      object: contract.title,
    };
  }

  getContractStatusColor(data: Contract): string {
    switch (data.status?.code) {
      case 'finished':
        return 'circle-gray';
      case 'on_execution':
        return 'circle-success';
      case 'on_study':
        return 'circle-inactive';
      case 'cancelled':
        return 'circle-error';
      default:
        return 'display-none';
    }
  }

  private capitalizeFirstLetter(str: string) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }
}

export interface ContractData {
  statusColor: string;
  id: number;
  type: string;
  number: string;
  lenderClass: string;
  lender: string;
  object: string;
  startD?: string;
  ContractDB: Contract;
}
