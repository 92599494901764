// Env loaded from pod start, more info https://medium.com/codex/update-angular-react-environment-dynamically-reading-variables-from-kubernetes-configmaps-ae32b8d4021c

import { EnvironmentDefinition } from './types';

const data = (window as unknown as { env: EnvironmentDefinition }).env;

export const environment: EnvironmentDefinition = {
  production: data.production,
  keycloakClient: data.keycloakClient,
  api: data.api,
  authService: data.authService,
  azureRedirectUri: data.azureRedirectUri,
  azureClientId: data.azureClientId,
  azureAuthorityUrl: data.azureAuthorityUrl,
  mobileUrl: data.mobileUrl,
  mobileUrl_core: data.mobileUrl_core,
  socketServer: data.socketServer,
  googleApiKey: data.googleApiKey,
  geoserver: data.geoserver,
  geoserverWorkspace: data.geoserverWorkspace,
  keycloakRealm: data.keycloakRealm,
  mapBoxTemplate: data.mapBoxTemplate,
  isTesting: data.isTesting ?? false,
};
if (!environment.api) {
  alert('Error desconocido, no se pudo obtener variables de entorno');
}
