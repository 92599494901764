import { GeneralKnownRouteValidator, NavItem } from './types-and-helpers.known.routes';

/**
 * DON'T EVER CHANGE NUMERATION
 */
export enum StretcherKnownItemId {
  STRETCHER = '1',
  RETAINED_VEHICLE = '2',
  MANAGE_STRETCHER = '3',
}

const simpleTransformer = (input: NavItem): NavItem & { type: 'stretcher' } => {
  return {
    ...input,
    type: 'stretcher',
  };
};
export const StretcherKnownRoutes = (k: StretcherKnownItemId): NavItem => {
  k = GeneralKnownRouteValidator(k);
  return JSON.parse(JSON.stringify(options[k]));
};
const options: {
  [k in StretcherKnownItemId]: NavItem & { type: 'stretcher' };
} = {
  [StretcherKnownItemId.STRETCHER]: simpleTransformer({
    id: StretcherKnownItemId.STRETCHER,
    route: '/home/stretcher',
    iconName: 'ambulance',
    displayName: 'Vehículos retenidos',
    permissions: ['resources_retention_general'],
    visibleOnSidebar: true,
  }),
  [StretcherKnownItemId.RETAINED_VEHICLE]: simpleTransformer({
    id: StretcherKnownItemId.RETAINED_VEHICLE,
    route: '/home/stretcher/retainedVehicles',
    iconName: 'burger-menu',
    displayName: 'Vehículos retenidos',
    parentModule: 'stretcher',
    permissions: ['resources_retention_general'],
    visibleOnSidebar: true,
  }),
  [StretcherKnownItemId.MANAGE_STRETCHER]: simpleTransformer({
    id: StretcherKnownItemId.MANAGE_STRETCHER,
    route: '/home/stretcher/manageStretcher',
    iconName: 'ambulance',
    displayName: 'Consultar vehículos retenidos en IPS',
    parentModule: 'stretcher',
    permissions: ['resources_retention_general'],
    visibleOnSidebar: true,
  }),
};
