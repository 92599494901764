import { EventEmitter, Injectable } from '@angular/core';
import { lastValueFrom, Observable, Subject, Subscription } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import { MapsModuleService } from '../../maps/maps-module.service';
import { environment } from '../../../../../environments/environment';
import { Heatmap, HeatmapParams } from '../../../types/maps/heatmap.interface';
import { ServerResponse } from '../../../types/http.interfaces';
import { BaseLayer } from '../../../types/maps/bases-layer.interface';
import { OfficeService } from '../../office.service';
import { MapData } from '../../../types/maps/incident-maps';
import { ResourceTracking } from '@smartsoft-types/sisem-resources';

@Injectable({
  providedIn: 'root',
})
export class IncidentsMapService {
  navigateToMain: EventEmitter<void> = new EventEmitter();

  URL_DATA = `${environment.geoserver}/${environment.geoserverWorkspace}/wfs`;
  URL_TRACKING = `${environment.api}/resources-ms/api/v1/resourceTracking`;

  // Filter Params Variables
  lastFilter: string;
  params: { [key: string]: any }[] = [];
  private paramsSubject = new Subject<{ [key: string]: any }[]>();
  private heatmapSubject = new Subject<string>();
  private incidentSubject = new Subject<number>();
  private incidentData = new Subject<MapData>();
  private openIncident = new Subject<number>();
  public currentSubscription: Subscription | null = null;

  wfsParams = new HttpParams()
    .set('service', 'WFS')
    .set('version', '2.0.0')
    .set('request', 'GetFeature')
    .set('outputFormat', 'application/json');

  // Map information Variables
  bbox: { minX: number; minY: number; maxX: number; maxY: number } = {
    minX: -74.13676183986206,
    minY: 4.607963038710561,
    maxX: -74.03045075646777,
    maxY: 4.69350756732087,
  };
  public proximities: number;
  public filterInCoreIndex: number;

  resourceData: any[] = [];

  constructor(
    private http: HttpClient,
    private officeService: OfficeService,
    private mapsModuleService: MapsModuleService
  ) {}

  setIncidentId(id: number) {
    this.updateIncident(id);
  }

  setFilterParam(newParams: { [key: string]: any }) {
    const key = Object.keys(newParams)[0];
    const existingParam = this.params.find((param) => param.hasOwnProperty(key));
    if (existingParam) {
      existingParam[key] = newParams[key];
    } else {
      this.params.push(newParams);
    }
    this.lastFilter = key;
    this.updateParams(key);
  }

  openHeatmapDialog(id: string) {
    this.heatmapSubject.next(id);
  }

  openHeatmap$() {
    return this.heatmapSubject.asObservable();
  }

  updateParams(_key?: string) {
    this.paramsSubject.next(this.params);
  }

  getParams$() {
    return this.paramsSubject.asObservable();
  }

  updateIncident(id: number) {
    this.incidentSubject.next(id);
  }

  getIncident$() {
    return this.incidentSubject.asObservable();
  }

  openIncidentById(id: number) {
    this.openIncident.next(id);
  }

  getOpenIncident$() {
    return this.openIncident.asObservable();
  }

  updateIncidentData(data: MapData) {
    this.incidentData.next(data);
  }

  getIncidentData() {
    return this.incidentData.asObservable();
  }

  deleteFilterParam(param: string) {
    const index = this.params.findIndex((item) => item.hasOwnProperty(param));
    if (index !== -1) this.params.splice(index, 1);
    this.lastFilter = param;
    this.updateParams();
  }

  deleteMultipleFilters(params: string[]) {
    for (const param of params) {
      const index = this.params.findIndex((item) => item.hasOwnProperty(param));
      if (index !== -1) this.params.splice(index, 1);
    }
    this.updateParams();
  }

  hasActiveParam(param: string) {
    return this.params.some((item) => item.hasOwnProperty(param));
  }

  clearFilterParams(navigateToMain: boolean = false) {
    if (this.params.length) {
      // Avoid unnecessary map updates
      this.params = [];
      this.lastFilter = 'all';
      this.updateParams();
      if (navigateToMain) {
        this.navigateToMain.emit();
      }
    }
  }

  getIncidentsMapData(id?: number) {
    if (this.currentSubscription) {
      this.currentSubscription.unsubscribe();
    }

    const zones = this.params.find((param) => param.hasOwnProperty('zoneId'))?.['zoneId'] || [];
    const incidentTypes =
      this.params.find((param) => param.hasOwnProperty('incidentTypeId'))?.['incidentTypeId'] || [];
    const status =
      this.params.find((param) => param.hasOwnProperty('incidentStatus'))?.['incidentStatus'] || [];
    const priorities =
      this.params.find((param) => param.hasOwnProperty('priority'))?.['priority'] || [];
    const since = this.params.find((param) => param.hasOwnProperty('since'))?.['since'] || '';
    const until = this.params.find((param) => param.hasOwnProperty('until'))?.['until'] || '';

    const incidentTypeFilter =
      incidentTypes.length > 0 ? `AND incidentTypeId IN (${incidentTypes.join(',')})` : '';
    const priorityFilter =
      priorities.length > 0 ? `AND priorityId IN (${priorities.join(',')})` : '';
    const createdAt = since && until ? `AND createdAt >= ${since} AND createdAt <= ${until}` : '';
    const zonesFilter = zones.length > 0 ? `AND zoneId IN (${zones.join(',')})` : '';

    const statusFilter =
      status.length > 0 ? `AND statusId IN (${status.join(',')})` : 'AND statusId IN (1,2,3)';

    let params = new HttpParams({ fromString: this.wfsParams.toString() }).set(
      'typeNames',
      'incident_entity'
    );

    const bbox = this.bbox
      ? `AND bbox(geolocation, ${this.bbox.minX},${this.bbox.minY},${this.bbox.maxX},${this.bbox.maxY},'EPSG:4326')`
      : '';

    if (id) {
      params = params.set(
        'CQL_FILTER',
        `geolocation IS NOT NULL AND deletedAt IS NULL AND IN (${id})`
      );
    } else {
      params = params.set(
        'CQL_FILTER',
        `geolocation IS NOT NULL AND deletedAt IS NULL ${priorityFilter} ${statusFilter} ${createdAt} ${incidentTypeFilter} ${zonesFilter} ${bbox}`
      );
    }

    return new Observable<MapData>((observer) => {
      this.currentSubscription = this.http.get<MapData>(this.URL_DATA, { params }).subscribe({
        next: (data) => {
          this.updateIncidentData(data);
          observer.next(data);
        },
        error: (err) => observer.error(err),
        complete: () => observer.complete(),
      });

      return () => {
        if (this.currentSubscription) {
          this.currentSubscription.unsubscribe();
        }
      };
    });
  }

  async getTrackingIncidentsData(id: number) {
    const result = await lastValueFrom(
      this.http.get<ServerResponse<ResourceTracking[]>>(`${this.URL_TRACKING}/${id}`)
    );
    return result.data;
  }

  async getIncidentsDataInHeatmap(incidentsId: number[]) {
    const params = new HttpParams({ fromString: this.wfsParams.toString() })
      .set(
        'CQL_FILTER',
        `geolocation IS NOT NULL AND deletedAt IS NULL AND id IN (${incidentsId.join(',')})`
      )
      .set('typeNames', 'incident_entity');

    return await lastValueFrom(this.http.get(this.URL_DATA, { params }));
  }

  async getResourcesMapData(id?: number[]) {
    if (id?.length) {
      let params = new HttpParams();

      id.forEach((resourceId) => {
        params = params.append('id', resourceId);
      });

      const data = await lastValueFrom(
        this.http.get<ServerResponse<any>>(this.URL_TRACKING, { params })
      );

      data.data.forEach((item: any) => item.lastLocation.reverse());
      return data.data;
    } else {
      const types: string[] =
        this.params.find((param) => param.hasOwnProperty('vehicleType'))?.['vehicleType'] || [];

      const status: string[] =
        this.params.find((param) => param.hasOwnProperty('vehicleStatus'))?.['vehicleStatus'] || [];

      let params = new HttpParams();

      types.forEach((type) => {
        params = params.append('resourceTypeId', type);
      });

      status.forEach((status) => {
        params = params.append('statusId', status);
      });

      const data = await lastValueFrom(
        this.http.get<ServerResponse<any>>(this.URL_TRACKING, { params })
      );

      data.data.forEach((item: any) => item.lastLocation.reverse());
      this.resourceData = data.data;
      return data.data;
    }
  }

  async getIPSMapData(id?: number[]) {
    if (id?.length) {
      let params = new HttpParams().set('fetchVehicleRetentionInfo', 'true');
      for (let i = 0; i < id.length; i++) {
        params = params.append('officeId', id[i]);
      }
      const data = await this.officeService.getGeoServerData(params);
      return data;
    } else {
      const complexity: number[] =
        this.params.find((param) => param.hasOwnProperty('complexity'))?.['complexity'] || [];
      const occupationFrom: number = this.params.find((param) =>
        param.hasOwnProperty('occupationFrom')
      )?.['occupationFrom'];
      const occupationTo: number = this.params.find((param) =>
        param.hasOwnProperty('occupationTo')
      )?.['occupationTo'];

      let params = new HttpParams().set('fetchVehicleRetentionInfo', 'true');

      for (const level of complexity) {
        params = params.append('attentionLevel', level.toString());
      }

      if (occupationFrom !== undefined && occupationTo !== undefined) {
        params = params.append('occupationFrom', occupationFrom);
        params = params.append('occupationTo', occupationTo);
      }

      const data = await this.officeService.getGeoServerData(params);
      return data;
    }
  }

  async getBasesMapData(id?: number[]): Promise<BaseLayer> {
    if (id?.length) {
      const params = new HttpParams({ fromString: this.wfsParams.toString() })
        .set('CQL_FILTER', `geolocation IS NOT NULL AND "id" IN (${id.join(',')})`)
        .set('typeNames', 'base_resource_related_view');
      return await lastValueFrom(this.http.get<BaseLayer>(this.URL_DATA, { params }));
    } else {
      const bases: string[] =
        this.params.find((param) => param.hasOwnProperty('bases'))?.['bases'] || [];

      const statusBases =
        bases.length > 0
          ? `AND status IN ('${bases.map((status) => status).join("','")}')`
          : "AND status IN ('active')";

      const params = new HttpParams({ fromString: this.wfsParams.toString() })
        .set('CQL_FILTER', `geolocation IS NOT NULL ${statusBases}`)
        .set('typeNames', 'base_resource_related_view');

      return await lastValueFrom(this.http.get<BaseLayer>(this.URL_DATA, { params }));
    }
  }

  async getUPZLayerData() {
    const upzLayer = await this.mapsModuleService.findActiveLayer('UPZ');
    if (upzLayer.length) {
      return await this.mapsModuleService.getLayerById(upzLayer[0].id);
    }
    return undefined;
  }

  async getPolygonCount(id: number | string) {
    let params = new HttpParams().set('layerDataHistoryId', id);

    const priorities: string[] =
      this.params.find((param) => param.hasOwnProperty('priority'))?.['priority'] || [];

    const status: string[] =
      this.params.find((param) => param.hasOwnProperty('incidentStatus'))?.['incidentStatus'] || [];

    const zones: string[] =
      this.params.find((param) => param.hasOwnProperty('zoneId'))?.['zoneId'] || [];

    const incidentTypes: string[] =
      this.params.find((param) => param.hasOwnProperty('incidentTypeId'))?.['incidentTypeId'] || [];

    const since = this.params.find((param) => param.hasOwnProperty('since'))?.['since'] || '';
    const until = this.params.find((param) => param.hasOwnProperty('until'))?.['until'] || '';

    priorities.forEach((prority) => {
      params = params.append('priorityId', prority);
    });

    status.forEach((state) => {
      params = params.append('statusId', state);
    });

    zones.forEach((zone) => {
      params = params.append('zoneId', zone);
    });

    incidentTypes.forEach((type) => {
      params = params.append('incidentTypeId', type);
    });

    if (since) {
      params = params.append('since', since);
    }

    if (until) {
      params = params.append('until', until);
    }

    const result = await lastValueFrom(
      this.http.get<ServerResponse<IncidentCounter[]>>(
        `${environment.api}/incidents-ms/api/v1/incidents/polygonCount`,
        { params }
      )
    );

    return result.data;
  }

  public setBbox(data: [number, number, number, number]) {
    this.bbox = { minX: data[0], minY: data[1], maxX: data[2], maxY: data[3] };
    return this.bbox;
  }

  async getHeatmapData(data: HeatmapParams): Promise<Heatmap> {
    const params: any = data;
    try {
      const resp = await lastValueFrom(
        this.http.get<ServerResponse<Heatmap>>(`${environment.api}/incidents-ms/api/v1/heatmap`, {
          params,
        })
      );
      return resp.data;
    } catch (e) {
      throw e;
    }
  }
}

export interface IncidentCounter {
  incidentcount: string;
  layerdatafeatureid: number;
}
