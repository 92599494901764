import { ProviderKnownItemId } from './providers.known.routes';
import { AdministrationKnownItemId } from './administration.known.routes';
import { IncidentsKnownItemId } from './incidents.known.routes';
import { HospitalsKnownItemId } from './hospitals.known.routes';
import { PowerBiKnownItemId } from './power-bi.known.routes';
import { HumanTalentTrainingKnownItemId } from './human-talent-training.known.routes';
import { ParametricKnownItemId } from './parametric.known.router';
import { ReferenceKnownItemId } from './reference.known.routes';
import { FollowUpKnownItemId } from './followup.known.routes';
import { StretcherKnownItemId } from './stretcher.known.routes';
import { PortalsKnownItemId } from './portals.known.routes';
import { MapsKnownItemId } from './maps.known.routes';

/**
 * ¡IMPORTANTE, LEER!
 * Es necesario que cada enum que se agregue en EnumsUnion se agregue en EnumsArray
 * No es posible automatizar la validación por lo que es necesario escribir manualmente 2 veces el enum
 * De no hacerlo se omite una validación que puede generar bugs con los ids de las rutas
 * Ya que son string-based enum numbers
 */
export type EnumsUnion =
  | ProviderKnownItemId
  | AdministrationKnownItemId
  | IncidentsKnownItemId
  | HospitalsKnownItemId
  | PowerBiKnownItemId
  | HumanTalentTrainingKnownItemId
  | ParametricKnownItemId
  | ReferenceKnownItemId
  | FollowUpKnownItemId
  | StretcherKnownItemId
  | PortalsKnownItemId
  | MapsKnownItemId;

export interface NavItem {
  displayName: string;
  tabName?: string;
  disabled?: boolean;
  id: EnumsUnion;
  iconName: string;
  route: string;
  children?: NavItem[];
  parentModule?: string;
  expanded?: boolean;
  allowFetchWithInclude?: boolean;
  permissions: string[];
  parentSection?: string; //Only for parametric module
  visibleOnSidebar: boolean;
}

export enum SidebarMenus {
  Providers = '1',
  Administration = '2',
  Incidents = '3',
  Hospitals = '4',
  PowerBi = '5',
  HumanTalent = '6',
  Parametric = '7',
  Reference = '8',
  FollowUp = '9',
  Stretcher = '10',
  Portals = '11',
  Maps = '12',
}

export const GeneralKnownRouteValidator = <T extends string | number>(id: T): T => {
  if (typeof id === 'number') {
    // eslint-disable-next-line no-console
    console.trace(
      'Detected number-based route id instead of string-bases, converting to string but must be fixed'
    );
    id = (id + '') as T;
  }
  return id;
};
