import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root',
})
export class AlertService {
  constructor(private toast: ToastrService) {}

  private _toastrOptions: any = {
    closeButton: false,
    debug: false,
    newestOnTop: true,
    progressBar: false,
    timeOut: '3500',
    showMethod: 'fadeIn',
    hideMethod: 'fadeOut',
  };

  public validateToastMessage(message: string) {
    return this.toast.findDuplicate('', message, false, false);
  }

  public showSuccess(text: string, title?: string): void {
    const successOptions = { ...this._toastrOptions };
    this.toast.success(text, title, successOptions);
  }

  public showWarning(text: string, title?: string): void {
    const showOptions = { ...this._toastrOptions };
    this.toast.warning(text, title, showOptions);
  }

  public showInformation(text: string, title?: string): void {
    const infoOptions = { ...this._toastrOptions };
    infoOptions.timeOut = '5000';
    this.toast.info(text, title, infoOptions);
  }

  public showError(text: string, title?: string): void {
    const errorOptions = { ...this._toastrOptions };
    errorOptions.timeOut = 0;
    errorOptions.extendedTimeOut = 0;
    errorOptions.closeButton = true;
    this.toast.error(text, title, errorOptions);
  }

  public showNotification(text: string, title?: string, action?: () => void): void {
    const _notificationOptions: any = {
      closeButton: false,
      newestOnTop: false,
      progressBar: false,
      timeOut: '3500',
      extendedTimeOut: '3500',
      positionClass: 'toast-bottom-right',
      showMethod: 'fadeIn',
      hideMethod: 'fadeOut',
    };
    const toast = this.toast.show(text, title, _notificationOptions);
    if (action) {
      const subscription = toast.onTap.subscribe(() => {
        action();
        subscription.unsubscribe();
      });
    }
  }

  public showNotificationWithIcon(text: string, title?: string): void {
    const _notificationOptions: any = {
      closeButton: false,
      newestOnTop: false,
      progressBar: false,
      timeOut: '3500',
      extendedTimeOut: '3500',
      toastClass: 'ngx-toastr special-success-message',
      positionClass: 'toast-bottom-right',
      showMethod: 'fadeIn',
      hideMethod: 'fadeOut',
    };
    this.toast.show(text, title, _notificationOptions);
  }

  public closeNotifications() {
    this.toast.clear();
  }
}
