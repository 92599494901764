import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}

let found = false;
for (const child of document.getElementsByTagName('head')[0].children as unknown as Array<{
  id: string;
}>) {
  if (child.id === 'placesApi') {
    found = true;
  }
}

if (!found) {
  let node = document.createElement('script');

  function handleApiLoad(e: unknown) {
    console.log('Google maps API loaded');
  } // Define the global callback that will run when the Google Maps API is loaded
  (window as unknown as { handleApiLoad: unknown })['handleApiLoad'] = handleApiLoad;
  function handleApiError(e: ErrorEvent) {
    console.error(e);
    if (e.message.indexOf('Google Maps JavaScript API error') !== -1) {
      // Handle the Google Maps API error
    }
  }
  window.addEventListener('error', handleApiError, { once: true });
  node.src = `https://maps.googleapis.com/maps/api/js?libraries=places&key=${environment.googleApiKey}&callback=handleApiLoad`;
  node.type = 'text/javascript';
  node.id = 'placesApi';
  node.async = true;
  node.defer = true;
  document.getElementsByTagName('head')[0].appendChild(node);
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));
