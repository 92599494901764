import { GeneralKnownRouteValidator, NavItem } from './types-and-helpers.known.routes';

export enum ReferenceKnownItemId {
  LIST_REFERENCE = '1',
  REFERENCE = '2',
}

const simpleTransformer = (input: NavItem): NavItem & { type: 'reference' } => {
  return {
    ...input,
    type: 'reference',
  };
};

export const ReferenceKnownRoutes = (k: ReferenceKnownItemId): NavItem => {
  k = GeneralKnownRouteValidator(k);
  return JSON.parse(JSON.stringify(options[k]));
};

const options: {
  [k in ReferenceKnownItemId]: NavItem & { type: 'reference' };
} = {
  [ReferenceKnownItemId.LIST_REFERENCE]: simpleTransformer({
    id: ReferenceKnownItemId.LIST_REFERENCE,
    displayName: 'Listar referencias',
    route: '/home/reference/list-reference',
    iconName: 'reference',
    parentModule: 'reference',
    allowFetchWithInclude: true,
    permissions: ['reference_patient_q'],
    visibleOnSidebar: true,
  }),
  [ReferenceKnownItemId.REFERENCE]: simpleTransformer({
    id: ReferenceKnownItemId.REFERENCE,
    displayName: 'Referencia y contrarreferencia',
    route: 'home/reference',
    iconName: 'sync',
    expanded: false,
    allowFetchWithInclude: false,
    permissions: ['Referencia'],
    visibleOnSidebar: false,
  }),
};
