import { environment } from '../../../../environments/environment';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { lastValueFrom } from 'rxjs';
import { ActorEventInput } from '@smartsoft-types/sisem-actors';
import {
  AcceptTnCEventInput,
  GenericEventInput,
  LoginAdIntegrationEventInput,
  RoleChangeEventInput,
} from '@smartsoft-types/sisem-actors/dist/src/entities/schemas/types/actors/actor-events.schema.entity';

export type ActorEventInputType = Omit<
  ActorEventInput['data'],
  'eventData' | 'ipAddress' | 'username'
> & {
  eventData:
    | RoleChangeEventInput['data']
    | (LoginAdIntegrationEventInput['data'] & { roleId?: string })
    | GenericEventInput['data']
    | AcceptTnCEventInput['data'];
};

@Injectable({
  providedIn: 'root',
})
export class ActorsService {
  private baseUrl = environment.api + '/actors-ms/api/v1/actors';

  constructor(private readonly http: HttpClient) {}
  public async registerActorEvent(input: ActorEventInputType): Promise<void> {
    await lastValueFrom(this.http.post(this.baseUrl + '/registerEvent', input));
  }
}
