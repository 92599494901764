<div class="width-100 height-100 position-relative">
  <app-svg
    name="cancel"
    class="color-secondary-200 close-icon"
    *ngIf="profileService.selectedRole && profileService.zone"
    (click)="close()"></app-svg>
  <span class="sub-title-white">Seleccione su zona</span>
  <div class="zones-dialog-container">
    <div class="svg-icon-container cursor-pointer" (click)="selectZone(1)">
      <div class="zone-icon-container">
        <app-svg name="compass" class="color-secondary-200"></app-svg>
      </div>
      <span class="text-m-18-white">ZONA NORTE</span>
    </div>
    <div class="svg-icon-container cursor-pointer" (click)="selectZone(2)">
      <div class="zone-icon-container">
        <app-svg name="compass" class="color-secondary-200"></app-svg>
      </div>
      <span class="text-m-18-white">ZONA SUR</span>
    </div>
  </div>
</div>
