import { GeneralKnownRouteValidator, NavItem } from './types-and-helpers.known.routes';

/**
 * DON'T EVER CHANGE NUMERATION
 */
export enum PowerBiKnownItemId {
  POWER_BI = '1',
  VIEW_BOARDS = '2',
  INCIDENT_TYPE_ANALYSIS = '3',
}

const simpleTransformer = (input: NavItem): NavItem & { type: 'power-bi' } => {
  return {
    ...input,
    type: 'power-bi',
  };
};
export const PowerBiKnownRoutes = (k: PowerBiKnownItemId): NavItem => {
  k = GeneralKnownRouteValidator(k);
  return JSON.parse(JSON.stringify(options[k]));
};
const options: {
  [k in PowerBiKnownItemId]: NavItem & { type: 'power-bi' };
} = {
  [PowerBiKnownItemId.POWER_BI]: simpleTransformer({
    id: PowerBiKnownItemId.POWER_BI,
    route: '/home/power-bi',
    iconName: 'space-dashboard',
    displayName: 'Power BI',
    permissions: ['power_bi_general_r'],
    visibleOnSidebar: true,
  }),
  [PowerBiKnownItemId.VIEW_BOARDS]: simpleTransformer({
    id: PowerBiKnownItemId.VIEW_BOARDS,
    route: '/home/power-bi/preview-boards-list/preview-boards',
    iconName: 'burger-menu',
    displayName: 'Visualizar tableros',
    permissions: ['power_bi_general_r'],
    visibleOnSidebar: false,
  }),
  [PowerBiKnownItemId.INCIDENT_TYPE_ANALYSIS]: simpleTransformer({
    id: PowerBiKnownItemId.INCIDENT_TYPE_ANALYSIS,
    route: '/home/power-bi/preview-boards-list/preview-boards',
    iconName: 'burger-menu',
    displayName: 'Análisis tipos de incidente',
    permissions: ['power_bi_general_r'],
    visibleOnSidebar: false,
  }),
};
