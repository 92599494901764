import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { lastValueFrom } from 'rxjs';
import { environment } from '../../../../../environments/environment';
import { ServerResponse } from '../../../types/http.interfaces';
import { ResourceTemplateStatus, ResourceType } from '@smartsoft-types/sisem-resources';
import { CachedCRUDService } from '../../../types/cached-c-r-u-d.service';

// create class for sub-resource of resourceType, don't expose to public due it's logic limitations that are managed by ResourcesTypesService
class ResourcesTypesStatusesWrapper extends CachedCRUDService<
  ResourceTemplateStatus,
  never,
  never
> {
  constructor(
    private http: HttpClient,
    readonly resourceTypeId: number
  ) {
    super();
    this.options.cacheExpiration = -1; // should almost never change, set don't expire
  }

  protected override async _fetchNewData(): Promise<ResourceTemplateStatus[]> {
    let httpOptions: HttpParams = new HttpParams();
    httpOptions = httpOptions.set('fetchAllowedChangeToStatus', true);
    httpOptions = httpOptions.set('fetchStatusChangeCauses', true);
    const headers = new HttpHeaders();
    const res = await lastValueFrom(
      this.http.get<ServerResponse<ResourceTemplateStatus[]>>(
        `${environment.api}/resources-ms/api/v1/resourceTypes/${this.resourceTypeId}/statuses`,
        { headers: headers, params: httpOptions }
      )
    );
    return res.data;
  }
}

@Injectable({
  providedIn: 'root',
})
export class ResourcesTypesService extends CachedCRUDService<ResourceType, never, never> {
  private _resourceTypeStatusesClasses: Record<number, ResourcesTypesStatusesWrapper | undefined> =
    {};

  constructor(private http: HttpClient) {
    super();
    this.options.cacheExpiration = -1; // should almost never change, set don't expire
  }

  protected override async _fetchNewData(): Promise<ResourceType[]> {
    const res = await lastValueFrom(
      this.http.get<ServerResponse<ResourceType[]>>(
        `${environment.api}/resources-ms/api/v1/resourceTypes`
      )
    );
    return res.data;
  }

  protected override async _getByIdData(id: number): Promise<ResourceType | undefined> {
    const res = await lastValueFrom(
      this.http.get<ServerResponse<ResourceType>>(
        `${environment.api}/resources-ms/api/v1/resourceTypes/${id}`
      )
    );
    return res.data;
  }

  public async statusesByResourceType(
    typeId: number,
    filterBy = {
      isAdministrative: false,
    }
  ): Promise<ResourceTemplateStatus[]> {
    let resourceTypeStatusClass = this._resourceTypeStatusesClasses[typeId];
    if (!resourceTypeStatusClass) {
      resourceTypeStatusClass = new ResourcesTypesStatusesWrapper(this.http, typeId);
      this._resourceTypeStatusesClasses[typeId] = resourceTypeStatusClass;
    }
    let result = await resourceTypeStatusClass.getAll();
    if (filterBy?.isAdministrative !== undefined) {
      result = result.filter((value) => value.isAdministrative === filterBy.isAdministrative);
    }
    return result;
  }
}
