import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { lastValueFrom } from 'rxjs';
import { environment } from '../../../../../environments/environment';
import { ServerResponse } from '../../../types/http.interfaces';
import { CachedCRUDService } from '../../../types/cached-c-r-u-d.service';
import { ParametricsInterface } from '../../../types/parametrics.interface';

@Injectable({
  providedIn: 'root',
})
export class ResourcesVehicleFuelTypeService extends CachedCRUDService<
  ParametricsInterface,
  never,
  never
> {
  constructor(private http: HttpClient) {
    super();
    this.options.cacheExpiration = -1; // should almost never change, set don't expire
    this.options.useSort = true;
    this.options.sortRoutine = (a, b) => a.title.toLowerCase().localeCompare(b.title.toLowerCase());
  }

  protected override async _fetchNewData(): Promise<ParametricsInterface[]> {
    const res: ServerResponse<ParametricsInterface[]> = await lastValueFrom(
      this.http.get<ServerResponse<ParametricsInterface[]>>(
        `${environment.api}/resources-ms/api/v1/parametrics/vehicleFuelType`
      )
    );
    return res.data;
  }
}
