import { EventEmitter, Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { ServerResponse } from '../../types/http.interfaces';
import { lastValueFrom } from 'rxjs';
import {
  ConfirmIncident,
  CreateIncident,
  CreateIncidentData,
  Incident,
  IncidentDB,
  PatientEvaluation,
  RegulateIncident,
  UpdateCaller,
  UpdateDistribution,
  UpdateIncidentRegulation,
  UpdateLocality,
} from '../../types/incident.interface';
import { DatePipe } from '@angular/common';
import { KeyPressedHandlerService } from '../key-pressed-handler.service';
import { ValidatorsService } from '../validators.service';
import { Router } from '@angular/router';
import { DialogService } from '../dialog.service';
import { BehaviorSubjectService } from '../behaviorSubject.service';
import { ProfileService } from '../profile/profile.service';
import { AlertService } from '../alert.service';
import {
  Incident as IncidentExportedType,
  IncidentAssignResourcesInput,
  IncidentConfirmActionInput,
  IncidentReOpenInput,
  IncidentResourceRelation,
  IncidentRoleUpdateInput,
  MedicalRegulation,
  MedicalRegulationCreateInput,
  MedicalRegulationUpdateInput,
  Patient,
  PatientCreateInput,
  PatientHandlingAndManagementInput,
  PatientTransfer,
  PatientTransferCreateInput,
  Requester,
  SavedSearch,
} from '@smartsoft-types/sisem-incidents';
import { RequesterUpdateInput } from '@smartsoft-types/sisem-incidents/dist/src/entities/schemas/types/requester/requester.schema.entity';
import {
  IncidentCloseIncidentInput,
  IncidentLocalityUpdateInput,
} from '@smartsoft-types/sisem-incidents/dist/src/entities/schemas/types/incident/incident.schema.entity';
import { TableKeyItem, TableKeyItemWithCode, TableKeyItemWithCodeActive } from '../../types/table';
import { LocationsService } from '../locations.service';
import { IncidentDispatchService } from './incident-dispatch.service';
import { PatientUpdateInput } from '@smartsoft-types/sisem-incidents/dist/src/entities/schemas/types/patient/patient.schema.entity';
import { IncidentLogsComponent } from '../../../core/incidents/shared/incident-logs/incident-logs.component';
import { LoadingService } from '../loading.service';
import { ResourcesService } from '../administration/aph-resource/resources.service';
import { IncidentsConsciousnessStatesService } from './incidents-sub-resources/incidents.consciousness.states.service';
import { IncidentsDiagnosisService } from './incidents-sub-resources/incidents.diagnosis.service';
import { IncidentsClosingCodesService } from './incidents-sub-resources/incidents.closing.codes.service';
import { IncidentsSitesService } from './incidents-sub-resources/incidents.sites.service';
import { IncidentsNoTransferCausesService } from './incidents-sub-resources/incidents.no.transfer.causes.service';
import { IncidentsPriorityService } from './incidents-sub-resources/incidents.priority.service';
import { IncidentsZonesService } from './incidents-sub-resources/incidents.zones.service';
import { IncidentsAttentionsService } from './incidents-sub-resources/incidents.attentions.service';
import { IncidentsTypesService } from './incidents-sub-resources/incidents.types.service';
import { ResourcesTypesService } from '../administration/aph-resource/resources.types.service';
import { IncidentsTabManagerService } from './incidents.tab.manager.service';
import { IncidentsStatusService } from './incidents-sub-resources/incidents.status.service';
import { ProviderService } from '../administration/provider/provider.service';
import { IncidentsCausalitiesService } from './incidents-sub-resources/incidents.causalities.service';
import { IncidentsPaginationService } from './incidents-pagination.service';
import { PaginationInterface } from '../../types/pagination.interface';
import { OfficeService } from '../office.service';
import { UpzStats } from '../../types/maps/upz-stats.interface';
import { IncidentTabData } from '../../types/tabs.interface';
import { TabData } from '../../types/parametric.service.abstract.class';
import { PermissionsValidatorService } from '../roles-and-permissions/permissions-validator.service';
import { RoleTabService } from '../administration/roles/role-tab.service';
import { NotificationPatient } from '../../types/app-ambulance/message/notification-patient.interface';

interface GetIncidentsInterface {
  pagination: PaginationInterface;
  result: IncidentExportedType[];
}

@Injectable({
  providedIn: 'root',
})
export class IncidentsService {
  public tabs: IncidentsTabManagerService;

  private incidentsURL = environment.api + '/incidents-ms/api/v1/incidents';
  incidents: Incident[];
  public tabInfo: { displayName: string; iconName: string } = {
    displayName: 'MAPA',
    iconName: 'pin-location',
  };
  public anyoneBleeding: TableKeyItemWithCode[] = [
    {
      id: 1,
      title: 'Ninguna está sangrando',
      code: 'no',
    },
    {
      id: 2,
      title: 'Sangrado escaso',
      code: 'scarceBleeding',
    },
    {
      id: 3,
      title: 'Sangrado moderado',
      code: 'moderateBleeding',
    },
    {
      id: 4,
      title: 'Sangrado abundante',
      code: 'abundantBleeding',
    },
  ];
  public readonly causeOfFailure: TableKeyItemWithCodeActive[] = [
    { id: 1, title: 'CANCELADO', code: 'cancelled', active: false },
    { id: 2, title: 'DESISTIMIENTO', code: 'withdrawal', active: true },
    { id: 3, title: 'FALLECIDO', code: 'deceased', active: true },
    { id: 4, title: 'FALSA ALARMA', code: 'false alarm', active: false },
    {
      id: 5,
      title: 'NO AMERITA TRASLADO',
      code: 'does not deserve transfer',
      active: true,
    },
    { id: 6, title: 'NO UBICA', code: 'not located', active: false },
    {
      id: 7,
      title: 'TRASLADADO POR OTRO RECURSO',
      code: 'transferred by another resource',
      active: true,
    },
  ];

  updateList: EventEmitter<boolean> = new EventEmitter<boolean>();
  tabExists: EventEmitter<TabData<IncidentTabData>> = new EventEmitter<TabData<IncidentTabData>>();

  private _activeIncidentLogs: IncidentLogsComponent | null;

  //filters data
  filteredIncidents: [Incident[]] = [[]];

  constructor(
    private http: HttpClient,
    private datePipe: DatePipe,
    private bSubjectService: BehaviorSubjectService,
    private profileService: ProfileService,
    private locationService: LocationsService,
    private alertService: AlertService,
    private dialogService: DialogService,
    private validatorsService: ValidatorsService,
    private router: Router,
    private keyPressedHandlerService: KeyPressedHandlerService,
    private incidentDispatchService: IncidentDispatchService,
    private loadingService: LoadingService,
    private resourcesService: ResourcesService,
    private incidentsConsciousnessStatesService: IncidentsConsciousnessStatesService,
    private incidentsDiagnosisService: IncidentsDiagnosisService,
    private incidentsClosingCodesService: IncidentsClosingCodesService,
    private incidentsSitesService: IncidentsSitesService,
    private incidentsNoTransferCausesService: IncidentsNoTransferCausesService,
    private incidentsPriorityService: IncidentsPriorityService,
    private incidentsCausalitiesService: IncidentsCausalitiesService,
    private incidentsZonesService: IncidentsZonesService,
    private incidentsAttentionsService: IncidentsAttentionsService,
    private incidentsTypesService: IncidentsTypesService,
    private resourcesTypesService: ResourcesTypesService,
    private providerService: ProviderService,
    private incidentsStatusService: IncidentsStatusService,
    private incidentsPaginationService: IncidentsPaginationService,
    private officeServices: OfficeService,
    private permissionValidatorService: PermissionsValidatorService,
    private roleService: RoleTabService
  ) {
    this.tabs = new IncidentsTabManagerService(
      http,
      datePipe,
      bSubjectService,
      profileService,
      locationService,
      alertService,
      dialogService,
      validatorsService,
      router,
      keyPressedHandlerService,
      incidentDispatchService,
      loadingService,
      this,
      incidentsConsciousnessStatesService,
      incidentsDiagnosisService,
      incidentsClosingCodesService,
      incidentsSitesService,
      incidentsNoTransferCausesService,
      incidentsPriorityService,
      incidentsZonesService,
      incidentsAttentionsService,
      incidentsTypesService,
      resourcesTypesService,
      incidentsCausalitiesService,
      officeServices,
      permissionValidatorService,
      roleService
    );
  }

  get activeIncidentLogs(): IncidentLogsComponent | null {
    return this._activeIncidentLogs;
  }

  set activeIncidentLogs(input: IncidentLogsComponent | null) {
    this._activeIncidentLogs = input;
  }

  async getIncidentById(
    data: { incidentId: number; isFirstFetch: boolean },
    resentSearch: boolean
  ): Promise<Incident> {
    let httpOptions = new HttpParams();
    httpOptions = httpOptions.set('fetchAttributes', true);
    httpOptions = httpOptions.set('fetchPatient', true);
    httpOptions = httpOptions.set('fetchRequester', true);
    httpOptions = httpOptions.set('fetchResourceRelations', true);
    httpOptions = httpOptions.set('fetchPatientEvaluations', true);
    //httpOptions = httpOptions.set('fetchModCircumstance', true);
    httpOptions = httpOptions.set('fetchClosingCodes', true);
    //httpOptions = httpOptions.set('fetchPriority', true);
    //httpOptions = httpOptions.set('fetchStatus', true);
    //httpOptions = httpOptions.set('fetchAttendanceAt', true);
    //httpOptions = httpOptions.set('fetchZone', true);
    httpOptions = httpOptions.set('currentRoleId', this.profileService.selectedRole?.id ?? 1);
    httpOptions = httpOptions.set('saveLogRecord', data.isFirstFetch);

    httpOptions = httpOptions.set('saveSearch', resentSearch);

    try {
      const parametricData = await this.getParametricData();
      const res: ServerResponse<IncidentExportedType> = await lastValueFrom(
        this.http.get<ServerResponse<IncidentExportedType>>(
          this.incidentsURL + `/${data.incidentId}`,
          { params: httpOptions }
        )
      );
      const incident = res.data;
      if (!incident.priority && incident.priorityId) {
        incident.priority = <any>parametricData.priorities[incident.priorityId];
      }
      if (!incident.status && incident.statusId) {
        incident.status = parametricData.status[incident.statusId];
      }
      if (!incident.modifyingCircumstance && incident.modifyingCircumstanceId) {
        incident.modifyingCircumstance =
          parametricData.modCircumstance[incident.incidentTypeId][incident.modifyingCircumstanceId];
      }
      if (!incident.zone && incident.zoneId) {
        incident.zone = <any>parametricData.zones[incident.zoneId];
      }
      if (!incident.mainClosingCode && incident.mainClosingCodeId) {
        incident.mainClosingCode = parametricData.closingCodes[incident.mainClosingCodeId];
      }
      if (!incident.incidentType && incident.incidentTypeId) {
        incident.incidentType = <any>parametricData.types[incident.incidentTypeId];
      }
      if (!incident.attendanceAt && incident.attendanceAtId) {
        incident.attendanceAt = <any>parametricData.attentions[incident.attendanceAtId];
      }
      return this.transformIncidents(<any>incident);
    } catch (e) {
      throw e;
    }
  }

  async regulateIncident(data: MedicalRegulationCreateInput['data'], incidentId: number) {
    const body: RegulateIncident = {
      auditData: {
        roleId: this.profileService.selectedRole?.id
          ? (this.profileService.selectedRole.id as unknown as number)
          : 1,
      },
      data: data,
    };
    try {
      await lastValueFrom(
        this.http.post(
          `${environment.api}/incidents-ms/api/v1/incidents/${incidentId}/medicalRegulations`,
          body
        )
      );
      this.updateList.emit(true);
    } catch (e) {
      throw e;
    }
  }

  async updateIncidentRegulation(
    data: MedicalRegulationUpdateInput['data'],
    incidentId: number,
    medicalRegulationId: number
  ) {
    const body: UpdateIncidentRegulation = {
      auditData: {
        roleId: this.profileService.selectedRole?.id
          ? (this.profileService.selectedRole.id as unknown as number)
          : 1,
      },
      data: data,
    };
    try {
      await lastValueFrom(
        this.http.patch(
          `${environment.api}/incidents-ms/api/v1/incidents/${incidentId}/medicalRegulations/${medicalRegulationId}`,
          body
        )
      );
      this.updateList.emit(true);
    } catch (e) {
      throw e;
    }
  }

  async getMedicalRegulation(id: number): Promise<MedicalRegulation[]> {
    let params = new HttpParams();
    params = params
      .set('fetchDiagnosis', true)
      .set('fetchIncident', true)
      .set('fetchClosingCode', true)
      .set('fetchNoTransferCause', true)
      .set('currentRoleId', this.profileService.selectedRole?.id ?? 1)
      .set('fetchMedicalRegulationResourceTypeRelation', true);
    try {
      const res: ServerResponse<MedicalRegulation[]> = await lastValueFrom(
        this.http.get<ServerResponse<MedicalRegulation[]>>(
          `${environment.api}/incidents-ms/api/v1/incidents/${id}/medicalRegulations`,
          { params: params }
        )
      );
      return res.data;
    } catch (e) {
      throw e;
    }
  }

  async createIncident(data: CreateIncidentData) {
    const body: CreateIncident = {
      auditData: {
        roleId: this.profileService.selectedRole?.id
          ? (this.profileService.selectedRole.id as unknown as number)
          : 1,
      },
      data: data,
    };
    try {
      const res = await lastValueFrom(
        this.http.post<ServerResponse<IncidentDB>>(
          `${environment.api}/incidents-ms/api/v1/incidents`,
          body
        )
      );
      return res.data;
    } catch (e) {
      throw e;
    }
  }

  async updateCaller(
    data: RequesterUpdateInput['data'],
    id: number,
    requesterId: number
  ): Promise<Requester> {
    const body: UpdateCaller = {
      auditData: {
        roleId: this.profileService.selectedRole?.id
          ? (this.profileService.selectedRole.id as unknown as number)
          : 1,
      },
      data: data,
    };
    try {
      const res: ServerResponse<Requester> = await lastValueFrom(
        this.http.patch<ServerResponse<Requester>>(
          `${environment.api}/incidents-ms/api/v1/incidents/${id}/requesters/${requesterId}`,
          body
        )
      );
      return res.data;
    } catch (e) {
      throw e;
    }
  }

  async updateLocality(data: IncidentLocalityUpdateInput['data'], id: number) {
    const body: UpdateLocality = {
      auditData: {
        roleId: this.profileService.selectedRole?.id
          ? (this.profileService.selectedRole.id as unknown as number)
          : 1,
      },
      data: data,
    };
    try {
      const res = await lastValueFrom(
        this.http.post<ServerResponse<IncidentDB>>(
          `${environment.api}/incidents-ms/api/v1/incidents/${id}/locality`,
          body
        )
      );
      return res.data;
    } catch (e) {
      throw e;
    }
  }

  async updateDistribution(data: IncidentRoleUpdateInput['data'], id: number): Promise<IncidentDB> {
    const body: UpdateDistribution = {
      auditData: {
        roleId: this.profileService.selectedRole?.id
          ? (this.profileService.selectedRole.id as unknown as number)
          : 1,
      },
      data: data,
    };
    try {
      const res = await lastValueFrom(
        this.http.post<ServerResponse<IncidentDB>>(
          `${environment.api}/incidents-ms/api/v1/incidents/${id}/changeRolDistribution`,
          body
        )
      );
      return res.data;
    } catch (e) {
      throw e;
    }
  }

  async confirmIncident(id: number, data: IncidentConfirmActionInput['data']): Promise<IncidentDB> {
    const body: ConfirmIncident = {
      auditData: {
        roleId: this.profileService.selectedRole?.id
          ? (this.profileService.selectedRole.id as unknown as number)
          : 1,
      },
      data: data,
    };
    const res = await lastValueFrom(
      this.http.post<ServerResponse<IncidentDB>>(
        `${environment.api}/incidents-ms/api/v1/incidents/${id}/confirm`,
        body
      )
    );
    this.updateList.emit(true);
    return res.data;
  }

  public async closeIncident(id: number, body: IncidentCloseIncidentInput['data']) {
    try {
      const data = {
        data: body,
        auditData: {
          roleId: this.profileService.selectedRole?.id ?? 1,
        },
      };
      await lastValueFrom(
        this.http.post(
          `${environment.api}/incidents-ms/api/v1/incidents/${id}/closeIncidents`,
          data
        )
      );
    } catch (e) {
      throw e;
    }
  }

  public async registerPatient(incidentId: number, body: PatientCreateInput['data']) {
    try {
      const data = {
        data: body,
        auditData: {
          roleId: this.profileService.selectedRole?.id ?? 1,
        },
      };
      await lastValueFrom(
        this.http.post(
          `${environment.api}/incidents-ms/api/v1/incidents/${incidentId}/patientEvaluations`,
          data
        )
      );
    } catch (e) {
      throw e;
    }
  }

  public async registerManagementAddressing(
    id: number,
    patientId: number,
    body: PatientHandlingAndManagementInput['data']
  ) {
    try {
      const data = {
        data: body,
        auditData: {
          roleId: this.profileService.selectedRole?.id ?? 1,
        },
      };
      await lastValueFrom(
        this.http.post(
          `${environment.api}/incidents-ms/api/v1/incidents/${id}/patientEvaluations/${patientId}/handlingAndManagement`,
          data
        )
      );
    } catch (e) {
      throw e;
    }
  }

  public async updatePatient(
    incidentId: number,
    patientId: number,
    body: PatientUpdateInput['data']
  ) {
    try {
      const data = {
        data: body,
        auditData: {
          roleId: this.profileService.selectedRole?.id ?? 1,
        },
      };
      await lastValueFrom(
        this.http.patch(
          `${environment.api}/incidents-ms/api/v1/incidents/${incidentId}/patientEvaluations/${patientId}`,
          data
        )
      );
    } catch (e) {
      throw e;
    }
  }

  public async getIncidentsResourceRelations(
    incidentId: number
  ): Promise<IncidentResourceRelation[]> {
    let params = new HttpParams();
    params = params.set('fetchResource', true);
    params = params.set('fetchRelatedPatients', true);
    params = params.set('fetchRelatedPatientsPaymentResponsible', true);
    params = params.set('fetchRelatedPatientsDiagnosis', true);
    try {
      const res = await lastValueFrom(
        this.http.get<ServerResponse<IncidentResourceRelation[]>>(
          `${environment.api}/incidents-ms/api/v1/incidents/${incidentId}/resourceRelations`,
          { params: params }
        )
      );
      return res.data;
    } catch (e) {
      throw e;
    }
  }

  public async reopenIncident(data: IncidentReOpenInput['data'], incidentId: number) {
    const body = {
      data,
      auditData: {
        roleId: this.profileService.selectedRole?.id,
      },
      searchBy: {
        id: incidentId,
      },
    };
    try {
      await lastValueFrom(
        this.http.post<ServerResponse<any>>(`${this.incidentsURL}/${incidentId}/reOpen`, body)
      );
      return true;
    } catch (e) {
      console.error(e);
      return false;
    }
  }

  /**
   * used for dispatcher role to assign an ambulance
   * @param data ambulance info
   * @param incidentId
   */
  async assignResource(data: IncidentAssignResourcesInput['data'], incidentId: number) {
    const body = {
      auditData: {
        roleId: this.profileService.selectedRole?.id ?? 1,
      },
      data,
    };
    await lastValueFrom(
      this.http.post(
        `${environment.api}/incidents-ms/api/v1/incidents/${incidentId}/assignResources`,
        body
      )
    );
    for (const item of data.resourceRelations) {
      const fixedRes = await this.resourcesService.getById(item.data.resourceId, true); // update cached data
      if (!fixedRes) {
        throw new Error('Could not fetch updated data, strange case, this should not happen');
      }
    }
  }

  async getIncidentByCode(code: string) {
    const params = new HttpParams().set('incidentCode', code);

    const resp = await lastValueFrom(
      this.http.get<ServerResponse<GetIncidentsInterface>>(
        `${environment.api}/incidents-ms/api/v1/incidents`,
        { params }
      )
    );

    return this.transformIncidents(<any>resp.data.result[0]);
  }

  async getAllIncidents(params: HttpParams): Promise<GetIncidentsInterface> {
    const parametricData = await this.getParametricData();
    const response = await lastValueFrom(
      this.http.get<ServerResponse<GetIncidentsInterface>>(
        `${environment.api}/incidents-ms/api/v1/incidents`,
        { params }
      )
    );

    for (const incident of response.data.result) {
      if (!incident.status && incident.statusId) {
        incident.status = parametricData.status[incident.statusId];
      }
      if (!incident.priority && incident.priorityId) {
        incident.priority = <any>parametricData.priorities[incident.priorityId];
      }
      if (!incident.zone && incident.zoneId) {
        incident.zone = <any>parametricData.zones[incident.zoneId];
      }
      if (!incident.modifyingCircumstance && incident.modifyingCircumstanceId) {
        incident.modifyingCircumstance =
          parametricData.modCircumstance[incident.incidentTypeId][incident.modifyingCircumstanceId];
      }
      if (!incident.incidentType && incident.incidentTypeId) {
        incident.incidentType = <any>parametricData.types[incident.incidentTypeId];
      }
      if (!incident.mainClosingCode && incident.mainClosingCodeId) {
        incident.mainClosingCode = parametricData.closingCodes[incident.mainClosingCodeId];
      }
      if (!incident.attendanceAt && incident.attendanceAtId) {
        incident.attendanceAt = <any>parametricData.attentions[incident.attendanceAtId];
      }
    }
    return response.data;
  }

  async transferPatientToIPS(
    data: PatientTransferCreateInput['data'],
    incidentId: number,
    patientId: number
  ) {
    const body = {
      auditData: {
        roleId: this.profileService.selectedRole?.id,
      },
      data,
    };
    await lastValueFrom(
      this.http.post(
        `${environment.api}/incidents-ms/api/v1/incidents/${incidentId}/patientEvaluations/${patientId}/patientTransfers`,
        body
      )
    );
  }

  /**
   * HELPERS
   */

  private getPriorityColor(priority: string): string {
    switch (priority) {
      case 'Critica': {
        return 'circle-priority-critical';
      }
      case 'Alta': {
        return 'circle-priority-high';
      }
      case 'Media': {
        return 'circle-priority-medium';
      }
      case 'Baja': {
        return 'circle-priority-low';
      }
      default: {
        return 'display-hidden';
      }
    }
  }

  async transformIncidents(data: IncidentDB): Promise<Incident> {
    const date = new Date(data.createdAt);
    const locations = await this.locationService.getLocations();
    const auxData: Incident = {
      incidentDB: data,
      incidentNumber: data.code,
      APHResource: '',
      hourAndDate: {
        hour: this.datePipe.transform(date, 'HH:mm:ss'),
        date: this.datePipe.transform(date, 'd/MM/y'),
      },
      incidentAddress: data.address,
      incidentType: data.incidentType?.code ? `${data.incidentType.code}` : 'N/A',
      locality: data.localityId ? locations.find((loc) => loc.id === data.localityId)?.name : '',
      state: data.status?.code ? this.transformIncidentStatus(data.status.id + '') : 'N/A',
      priorityColor: {
        value: data.priority?.code ? this.transformIncidentPriority(data.priority.code) : 'N/A',
        color: '',
      },
      incidentIcon: {
        svgFile: this.getIncidentIcon(data),
        svgColor: 'color-black',
        tooltip: data.priority?.title ? data.priority.title : 'undefined',
        color: '',
      },
      zone: data.zone?.title,
      neighborhood: data.district ? data.district : '',
    };
    auxData.priorityColor.color = this.getPriorityColor(auxData.priorityColor.value);
    auxData.incidentIcon.color = this.getPriorityColor(auxData.priorityColor.value);
    return auxData;
  }

  private getIncidentIcon(data: IncidentDB): string {
    const code = data.status?.code as unknown as string;
    switch (code) {
      case '1': {
        return 'incident-initial';
      }
      case '2': {
        return 'incident-pendent';
      }
      case '3': {
        return 'incident-active';
      }
      case '4': {
        return 'incident-close';
      }
      default: {
        return '';
      }
    }
  }

  transformIncidentStatus(status: string): string {
    let value: string;
    switch (status) {
      case '1':
        {
          value = 'INICIAL';
        }
        break;
      case '2':
        {
          value = 'PENDIENTE';
        }
        break;
      case '3':
        {
          value = 'ACTIVO';
        }
        break;
      case '4':
        {
          value = 'CERRADO';
        }
        break;
      default: {
        value = 'INDEFINIDO';
      }
    }
    return value;
  }

  public transformIncidentPriority(priority: number): string {
    const code = priority.toString();
    switch (code) {
      case '1': {
        return 'Critica';
      }
      case '2': {
        return 'Alta';
      }
      case '3': {
        return 'Media';
      }
      case '4': {
        return 'Baja';
      }
      default: {
        return 'Indefinido';
      }
    }
  }

  private async getParametricData() {
    const status = this.incidentsStatusService.returnDataAsObject();
    const closingCodes = this.incidentsClosingCodesService.returnDataAsObject();
    const types = this.incidentsTypesService.returnDataAsObject();
    const zones = this.incidentsZonesService.returnDataAsObject();
    const attentions = this.incidentsAttentionsService.returnDataAsObject();
    const priorities = this.incidentsPriorityService.returnDataAsObject();
    const modCircumstance: any = {};
    for (const type of Object.keys(types)) {
      modCircumstance[type] = await this.incidentsTypesService.getModifyingCircumstanceAsObject(
        parseInt(type + '')
      );
    }
    return {
      status,
      closingCodes,
      types,
      zones,
      attentions,
      priorities,
      modCircumstance,
    };
  }

  getTabPriorityAndColor(priority: number | undefined) {
    let tab = '';
    let circle = '';
    if (priority) {
      switch (priority) {
        case 1:
          {
            tab = 'circle-priority-critical';
            circle = 'circle-priority-critical';
          }
          break;
        case 2:
          {
            tab = 'circle-priority-high';
            circle = 'circle-priority-high';
          }
          break;
        case 3:
          {
            tab = 'circle-priority-medium';
            circle = 'circle-priority-medium';
          }
          break;
        case 4: {
          tab = 'circle-priority-low';
          circle = 'circle-priority-low';
        }
      }
    }
    return { tab, circle };
  }

  async getPatientEvaluations(
    incidentId: number,
    patientId: number
  ): Promise<PatientEvaluation | undefined> {
    let params: HttpParams = new HttpParams();
    params = params.set('fetchPatient', true);
    const res = await lastValueFrom(
      this.http.get<ServerResponse<PatientTransfer[]>>(
        `${environment.api}/incidents-ms/api/v1/incidents/${incidentId}/patientEvaluations/${patientId}/patientTransfers`,
        { params }
      )
    );
    if (res.data.length === 0) return undefined;
    return {
      id: res.data[0].patient?.id ?? -1,
      name: res.data[0].patient ? this.getPatientName(res.data[0].patient) : 'NN',
      acceptedOnIPS: res.data[0].acceptedOnIPS,
    };
  }

  getPatientName(patient: Patient): string {
    const names: string[] = [];
    names.push(patient.firstName ?? '');
    if (patient.secondName) {
      names.push(patient.secondName);
    }
    if (patient.firstLastName) {
      names.push(patient.firstLastName);
    }
    if (patient.secondLastName) {
      names.push(patient.secondLastName);
    }
    return names.join(' ');
  }

  async getCausesFailed(): Promise<TableKeyItem[]> {
    const res = await lastValueFrom(
      this.http.get<ServerResponse<TableKeyItem[]>>(
        `${environment.api}/resources-ms/api/v1/parametrics/causesFailed`
      )
    );
    return res.data.map((data) => ({
      ...data,
      title: data.title.toUpperCase(),
    }));
  }

  async getResentIncidentSearch() {
    const params = new HttpParams().set('last', 20);
    try {
      const response: ServerResponse<SavedSearch[]> = await lastValueFrom(
        this.http.get<ServerResponse<SavedSearch[]>>(this.incidentsURL + '/recentSearches', {
          params,
        })
      );
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  async getUPZlayerStats(id: string) {
    const stats = await lastValueFrom(
      this.http.get<ServerResponse<UpzStats>>(this.incidentsURL + '/upzLayerStats/' + id)
    );
    return stats.data;
  }

  public async unlockUpdateLocality(incidentId: number, roleId: string) {
    const body = {
      auditData: {
        roleId: roleId,
      },
    };
    try {
      return await lastValueFrom(
        this.http.post(
          `${environment.api}/incidents-ms/api/v1/incidents/${incidentId}/unlockUpdateLocality`,
          body
        )
      );
    } catch (e) {
      throw e;
    }
  }

  public async setUpdateLocalityStatus(incidentId: number, roleId: string) {
    const body = {
      auditData: {
        roleId: roleId,
      },
    };
    try {
      return await lastValueFrom(
        this.http.post(
          `${environment.api}/incidents-ms/api/v1/incidents/${incidentId}/setUpdateLocalityStatus`,
          body
        )
      );
    } catch (e) {
      throw e;
    }
  }

  public async postPatientNotification(data: NotificationPatient) {
    const res = await lastValueFrom(
      this.http.post(`${environment.mobileUrl}sisem-notification-api/v1/message`, data)
    );
    return res;
  }

  public async validateEditingLocation(
    incidentId: number,
    roleId: string
  ): Promise<IsActiveEditing> {
    const body = {
      auditData: {
        roleId: roleId,
      },
    };
    try {
      const res = await lastValueFrom(
        this.http.post<ServerResponse<IsActiveEditing>>(
          `${environment.api}/incidents-ms/api/v1/incidents/${incidentId}/updateLocalityStatus`,
          body
        )
      );
      return res.data;
    } catch (e) {
      throw e;
    }
  }
}
export interface IsActiveEditing {
  activeUpdateLocality: boolean;
  actorId: number;
}

export interface RetrospectiveNote {
  eventData: any;
  eventTag?: string;
  isPriority: boolean;
}

export const PriorityColor: { [type: string]: string } = {
  BAJA: 'circle-priority-low',
  MEDIA: 'circle-priority-medium',
  ALTA: 'circle-priority-high',
  CRITICA: 'circle-priority-critical',
  '': 'circle-error',
};
export const PriorityTabColor: { [type: string]: string } = {
  BAJA: 'circle-priority-low',
  MEDIA: 'circle-priority-medium',
  ALTA: 'circle-priority-high',
  CRITICA: 'circle-priority-critical',
  '': 'red-tab-circle',
};
export const StatusName: {
  [type: number]: string;
} = {
  0: 'INDEFINIDO',
  1: 'INICIAL',
  2: 'PENDIENTE',
  3: 'ACTIVO',
};
