import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DialogComponent } from '../component/dialog/dialog.component';
import { lastValueFrom } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { RegisterPatientModalComponent } from '../../core/incidents/shared/register-patient-modal/register-patient-modal.component';
import { Patient } from '@smartsoft-types/sisem-incidents';
import { IncidentTabData } from '../types/tabs.interface';
import { LoadingService } from './loading.service';
import { ManagementAddressingComponent } from '../../core/incidents/components/incident-action/components/medical-regulation/management-addressing/management-addressing.component';
import { DialogInterface, TableDialogInterface, TextHasBold } from '../types/dialog.interface';
import { EditionModalComponent } from '../component/edition-modal/edition-modal.component';
import { IncidentDB } from '../types/incident.interface';
import { TableDialogComponent } from '../component/table-dialog/table-dialog.component';
import { CustomMsgComponent } from '../component/custom-msg/custom-msg.component';
import { AddNoveltyReportModalComponent } from '../../hospitals/components/manage-hospitals/components/add-novelty-report/add-novelty-report-modal/add-novelty-report-modal.component';
import {
  VisualizePdfComponent,
  VisualizePdfData,
} from '../component/visualize-pdf/visualize-pdf.component';

import { ComponentType } from '@angular/cdk/portal';
import { ModalComponent } from '../component/modal/modal.component';
import { TransformedResource } from './providers/manage-vehicle/manage-vehicle.service';
import { PreoperationalModalComponent } from '../component/preoperational-modal/preoperational-modal.component';
import { LayerPropertiesComponent } from '../component/layer-properties/layer-properties.component';
import { GeometryPropertiesDialogComponent } from '../component/maps/component/geometry-properties-dialog/geometry-properties-dialog.component';
import { IncidentsNoTransferCausesService } from './incidents/incidents-sub-resources/incidents.no.transfer.causes.service';
import { ParametricsInterface } from '../types/parametrics.interface';
import { AutoFocusTarget } from '@angular/material/dialog/dialog-config';
import { Role } from './roles-and-permissions/role.service';

interface TwoButtons {
  firstButton: string;
  secondButton: string;
}

@Injectable({
  providedIn: 'root',
})
export class DialogService {
  private _policy: string = 'Cargando';
  private _termsAndConditions: string = 'Cargando';
  private _activeModal: boolean = false;

  public get activeModal() {
    return this._activeModal;
  }

  constructor(
    public dialog: MatDialog,
    private matDialogRef: MatDialog,
    private http: HttpClient,
    private loadingService: LoadingService,
    private noTransferCausesServices: IncidentsNoTransferCausesService
  ) {
    setTimeout(() => {
      this.loadPolicies().then();
    }, 10);
  }

  private async loadPolicies() {
    const httpOptions = {
      headers: new HttpHeaders({
        skip: 'true',
      }),
      responseType: <any>'text',
    };
    this._policy = await lastValueFrom(
      this.http.get<string>('assets/texts/_policy.txt', httpOptions)
    );
    this._termsAndConditions = await lastValueFrom(
      this.http.get<string>('assets/texts/_terms.txt', httpOptions)
    );
  }

  public openDialog(data: DialogInterface, panelClass?: string, id?: string): MatDialogRef<any> {
    return this.dialog.open(DialogComponent, {
      id,
      maxWidth: window.innerWidth > 1024 ? '' : '90%',
      panelClass: panelClass ? panelClass : 'my-dialog-register-class',
      data: data,
      disableClose:
        data['typeConfirm'] === 'password' ||
        data['typeConfirm'] === 'roles' ||
        data['typeConfirm'] === 'zones' ||
        data['typeConfirm'] === 'success-icon',
    });
  }

  public errorModal(
    title: string,
    message: string,
    icon: string = 'cancel',
    titleMobileSize?: number
  ) {
    const dialog = this.openDialog(
      {
        title: title,
        message: message,
        icon: icon,
        isButtons: true,
        orientationIconTop: true,
        isActions: true,
        typeConfirm: 'alert',
        titleMobileSize: titleMobileSize,
      } as DialogInterface,
      'my-dialog-alert-class',
      'error-modal-dialog'
    );
    return lastValueFrom(dialog.afterClosed());
  }

  public unauthorizedErrorModal(selectedRole: Role) {
    return this.errorModal(
      'No autorizado',
      `El rol ${selectedRole.attributes.title[0]}, no tiene permiso para realizar esta acción`
    );
  }

  informativeResultModal(
    message: string,
    type: 'error' | 'success',
    size?: { maxWidth?: string; minWidth?: string; width?: string },
    timeOut: number = 3000,
    boldActive: boolean = false,
    textToDisplay: TextHasBold[] = []
  ): Promise<void> {
    return new Promise((resolve) => {
      if (this.dialog.getDialogById('informative-result-modal')) {
        return;
      }
      const error = this.dialog.open(CustomMsgComponent, {
        id: 'informative-result-modal',
        data: {
          message,
          type,
          boldActive,
          textToDisplay,
        },
        maxWidth: window.innerWidth >= 1024 ? size?.maxWidth || '580px' : 'unset',
        minWidth: window.innerWidth >= 1024 ? size?.minWidth || '280px' : 'unset',
        width: window.innerWidth >= 1024 ? size?.width || '50%' : '100%',
        position: { bottom: window.innerWidth >= 1024 ? undefined : '0' },
        panelClass: window.innerWidth >= 1024 ? 'add-contact-modal' : 'add-contact-modal-animation',
        height: 'auto',
      });

      const closedByDefault = lastValueFrom(error.afterClosed());

      const closedAutomatically = new Promise<void>((res) => {
        //close modal automatically when type is success and timeOut is out
        if (type === 'success') {
          setTimeout(() => {
            error.close();
            res();
          }, timeOut);
        } else if (type === 'error' && this.loadingService.loading) {
          this.loadingService.setLoading(false);
        }
      });

      Promise.race([closedByDefault, closedAutomatically]).then(() => resolve());
    });
  }

  openLayerProperties(properties: object, position: { top: number; left: number }): Promise<void> {
    return new Promise((_resolve) => {
      if (this.dialog.getDialogById('layer-properties')) return;
      const error = this.dialog.open(LayerPropertiesComponent, {
        id: 'layer-properties',
        data: properties,
        position: { top: `${position.top}px`, left: `${position.left}px` },
        panelClass: window.innerWidth >= 1024 ? 'add-contact-modal' : 'add-contact-modal-animation',
        height: 'auto',
      });
      lastValueFrom(error.afterClosed());
    });
  }

  public passwordModal(title: string, message: string) {
    const dialog = this.openDialog(
      {
        title: title,
        message: message,
        icon: 'key',
        isButtons: true,
        orientationIconTop: true,
        isActions: true,
        typeConfirm: 'password',
      } as DialogInterface,
      'my-dialog-alert-class-password'
    );
    return lastValueFrom(dialog.afterClosed());
  }

  public yesNoOptionModal(
    title: string,
    message: string,
    icon: string,
    buttonsMessageType?: 'yes-no' | 'accept-cancel'
  ) {
    const dialog = this.openDialog(
      {
        title: title,
        message: message,
        icon: icon,
        isButtons: true,
        orientationIconTop: true,
        isActions: true,
        typeConfirm: 'cancel',
        buttonsMessageType: buttonsMessageType || 'yes-no',
      } as DialogInterface,
      'my-dialog-alert-class'
    );
    return lastValueFrom(dialog.afterClosed());
  }

  public async termsAndConditions() {
    if (this._termsAndConditions === 'Cargando') {
      await this.loadPolicies();
    }
    const dialog = this.openDialog(
      {
        title: 'TÉRMINOS Y CONDICIONES',
        message: this._termsAndConditions,
        icon: 'NONE',
        isButtons: true,
        orientationIconTop: true,
        isActions: true,
        typeConfirm: 'terms',
      } as DialogInterface,
      'my-dialog-info-class'
    );
    return lastValueFrom(dialog.afterClosed());
  }

  public async dataProtectionPolicy(title: string = 'POLÍTICA DE PROTECCIÓN DE DATOS PERSONALES') {
    if (this._policy === 'Cargando') {
      await this.loadPolicies();
    }
    const dialog = this.openDialog(
      {
        title: title,
        message: this._policy,
        icon: 'NONE',
        isButtons: true,
        orientationIconTop: true,
        isActions: true,
        typeConfirm: 'policy',
      } as DialogInterface,
      'my-dialog-info-class'
    );
    return lastValueFrom(dialog.afterClosed());
  }

  public rolesModal(username: string, roles: Role[]) {
    const auxRoles = roles;

    auxRoles.sort((a, b) =>
      a.attributes.title[0].toLowerCase().localeCompare(b.attributes.title[0].toLowerCase())
    );
    this.loadingService.setLoading(false);
    const dialog = this.openDialog(
      {
        title: `Bienvenido/a ${username}`,
        message: '',
        icon: 'roles',
        rolesInfo: auxRoles,
        isButtons: true,
        orientationIconTop: true,
        isActions: true,
        typeConfirm: 'roles',
      } as DialogInterface,
      'my-dialog-roles-class'
    );
    return lastValueFrom(dialog.afterClosed());
  }

  public openIncidentEditModal(
    incident: IncidentDB,
    type: 'caller' | 'distribution' | 'location',
    title: string,
    icon: string,
    disabled: boolean
  ) {
    const dialogClass: string =
      type === 'caller'
        ? 'dialog-caller-class'
        : type === 'distribution'
          ? 'dialog-distribute-class'
          : 'dialog-location-class';
    const dialog = this.dialog.open(EditionModalComponent, {
      panelClass: dialogClass,
      data: {
        incident: incident,
        title: title,
        icon: icon,
        type: type,
        disabled: disabled,
      },
    });
    return lastValueFrom(dialog.afterClosed());
  }

  public async registerPatientModal(
    tabData: IncidentTabData,
    title: string,
    icon: string,
    edit: boolean,
    view: boolean,
    patient?: Patient
  ) {
    const response = await this._updateDataNoTransferCauses();
    tabData.noTransferCauses = response.activeRegister;
    tabData.noTransferCausesAll = response.allRegistes;
    const dialog = this.openPatientDialog(
      {
        tabData: tabData,
        title: title,
        icon: icon,
        edit: edit,
        view,
        patient: patient,
      },
      'dialog-caller-class'
    );
    return lastValueFrom(dialog.afterClosed());
  }

  public async registerManagementAndAddressingModal(
    tabData: IncidentTabData,
    title: string,
    icon: string,
    edit: boolean,
    patient?: Patient
  ) {
    const response = await this._updateDataNoTransferCauses();
    tabData.noTransferCauses = response.activeRegister;
    tabData.noTransferCausesAll = response.allRegistes;
    const dialog = this.openManagementAndAddressDialog(
      {
        tabData: tabData,
        title: title,
        icon: icon,
        edit: edit,
        patient: patient,
      },
      'dialog-caller-class'
    );
    return lastValueFrom(dialog.afterClosed());
  }

  public openPatientDialog(data: any, panelClass: string): MatDialogRef<any> {
    return this.dialog.open(RegisterPatientModalComponent, {
      panelClass: panelClass,
      data: data,
      width: '80%',
      maxWidth: 720,
      height: '80%',
      maxHeight: 820,
    });
  }

  public openManagementAndAddressDialog(data: any, panelClass: string): MatDialogRef<any> {
    return this.dialog.open(ManagementAddressingComponent, {
      panelClass: panelClass,
      data: data,
      width: '80%',
      maxWidth: 720,
      height: '80%',
      maxHeight: 820,
    });
  }

  public infoModal(title: string, message: string, showButton: boolean = false) {
    const dialog = this.openDialog(
      {
        title: title,
        message: message,
        icon: 'info',
        isButtons: showButton,
        orientationIconTop: true,
        isActions: showButton,
        typeConfirm: 'info',
      } as DialogInterface,
      'my-dialog-short-alert-class',
      'info-modal-dialog'
    );
    return lastValueFrom(dialog.afterClosed());
  }

  public async infoSubtitledModal(title: string, message: string, subMessage: string) {
    this._activeModal = true;
    const dialog = this.openDialog(
      {
        title: title,
        message: message,
        subMessage: subMessage,
        icon: 'warning',
        isButtons: false,
        orientationIconTop: true,
        isActions: false,
        typeConfirm: 'info-subtitled',
      } as DialogInterface,
      'my-dialog-information-class',
      'info-subtitled-modal'
    );
    const returnValue = await lastValueFrom(dialog.afterClosed());
    this._activeModal = false;
    return returnValue;
  }

  public mailModal(title: string, message: string) {
    const dialog = this.openDialog(
      {
        title: title,
        message: message,
        icon: 'mail-outline',
        isButtons: true,
        orientationIconTop: true,
        isActions: true,
        typeConfirm: 'info',
      } as DialogInterface,
      'my-dialog-alert-class'
    );
    return lastValueFrom(dialog.afterClosed());
  }

  public successModal(
    message: string,
    title: string,
    icon?: string,
    showButton: boolean = true,
    hideCloseDialog: boolean = false
  ) {
    const dialog = this.openDialog(
      {
        title: title,
        message: message,
        icon: icon ? icon : 'check-circle',
        isButtons: showButton,
        orientationIconTop: true,
        isActions: showButton,
        typeConfirm: 'success-icon',
        hideCloseDialog,
      } as DialogInterface,
      showButton ? 'my-dialog-alert-class' : 'my-dialog-short-alert-class'
    );
    return lastValueFrom(dialog.afterClosed());
  }

  public twoOptionsModal(
    message: string,
    title: string,
    buttons: TwoButtons,
    icon?: string,
    showButton: boolean = true
  ) {
    const dialog = this.openDialog(
      {
        title: title,
        message: message,
        icon: icon ? icon : 'check-circle',
        isButtons: showButton,
        orientationIconTop: true,
        otherColorMessage: `${buttons.firstButton},${buttons.secondButton}`,
        typeConfirm: 'two-options',
      } as DialogInterface,
      showButton ? 'my-dialog-alert-class' : 'my-dialog-short-alert-class'
    );
    return lastValueFrom(dialog.afterClosed());
  }

  public async twoColorsModal(
    message: string,
    otherColorMessage: string,
    replaceValue: string,
    title: string,
    showButtons: boolean = true,
    icon: string = 'check-circle'
  ) {
    this._activeModal = true;
    const dialog = this.openDialog(
      {
        title: title,
        message: message,
        icon,
        isButtons: showButtons,
        orientationIconTop: true,
        isActions: showButtons,
        replaceValue: replaceValue,
        otherColorMessage: otherColorMessage,
        typeConfirm: 'icon-two-colors',
      } as DialogInterface,
      'my-dialog-alert-class'
    );
    const returnValue = await lastValueFrom(dialog.afterClosed());
    this._activeModal = false;
    return returnValue;
  }

  public async twoColorsModalContract(
    message: string,
    otherColorMessage: string,
    replaceValue: string,
    title: string,
    showButtons: boolean = true
  ) {
    this._activeModal = true;
    const dialog = this.openDialog(
      {
        title: title,
        message: message,
        icon: 'check-circle',
        isButtons: showButtons,
        orientationIconTop: true,
        isActions: showButtons,
        replaceValue: replaceValue,
        otherColorMessage: otherColorMessage,
        typeConfirm: 'icon-two-colors-contract',
      } as DialogInterface,
      'my-dialog-alert-class'
    );
    const returnValue = await lastValueFrom(dialog.afterClosed());
    this._activeModal = false;
    return returnValue;
  }

  public modalWithDescription(
    message: string,
    title: string,
    placeholder: string,
    panelClass: string = 'my-dialog-textfield-class',
    textareaRows: number = 3,
    saveWithKey?: string,
    titleMobileSize?: number
  ) {
    const dialog = this.openDialog(
      {
        title: title,
        message: message,
        icon: '',
        isButtons: true,
        otherColorMessage: placeholder,
        orientationIconTop: true,
        isActions: false,
        isTwoActions: false,
        isThirdAction: true,
        typeConfirm: 'update-status',
        textareaRows: textareaRows,
        saveWithKey: saveWithKey,
        titleMobileSize: titleMobileSize,
      } as DialogInterface,
      panelClass
    );
    return lastValueFrom(dialog.afterClosed());
  }

  public clearModal(message: string, subMessage: string) {
    const dialog = this.openDialog(
      {
        title: 'BUSCAR APH',
        message: message,
        subMessage: subMessage,
        icon: 'highlight_off',
        isButtons: true,
        orientationIconTop: true,
        isTwoActions: true,
        typeConfirm: 'clear',
      } as DialogInterface,
      'my-dialog-alert-class'
    );
    return lastValueFrom(dialog.afterClosed());
  }

  public openTableDialog(data: TableDialogInterface, panelClass: string): MatDialogRef<any> {
    return this.dialog.open(TableDialogComponent, {
      panelClass: panelClass,
      data: data,
    });
  }

  public openAddNoveltyReportDialog(officeId: number) {
    const dialog = this.dialog.open(AddNoveltyReportModalComponent, {
      panelClass: 'my-dialog-no-style',
      autoFocus: false,
      data: officeId,
    });
    return lastValueFrom(dialog.afterClosed());
  }

  public openViewPdfDialog(data: VisualizePdfData) {
    const dialog = this.dialog.open(VisualizePdfComponent, {
      panelClass: 'my-dialog-no-style',
      data,
    });
    return lastValueFrom(dialog.afterClosed());
  }

  public openAddParamDialog<T>(component: ComponentType<T>, data?: any) {
    const dialog = this.dialog.open(component, {
      panelClass: 'my-dialog-no-style',
      autoFocus: false,
      data,
    });
    return lastValueFrom(dialog.afterClosed());
  }

  public openCustomModal<T>(
    component: ComponentType<T>,
    modalConfig: { titleIcon?: string; title: string },
    additionalInfo?: any,
    noAutoFocus?: boolean | AutoFocusTarget | string
  ) {
    const modal = this.dialog.open(ModalComponent, {
      panelClass: 'my-dialog-no-style',
      maxWidth: window.innerWidth > 1024 ? '80vw' : '95vw',
      data: { html: component, additionalInfo, modalConfig },
      autoFocus: !noAutoFocus,
    });
    return lastValueFrom(modal.afterClosed());
  }

  public closeAll() {
    return this.dialog.closeAll();
  }

  public preoperationalModal(data: TransformedResource) {
    const modal = this.dialog.open(PreoperationalModalComponent, {
      width: screen.width < 1024 ? '90%' : '800px',
      maxWidth: screen.width < 1024 ? 'unset' : '60vw',
      minWidth: '300px',
      maxHeight: '747px',
      panelClass: 'add-contact-modal',
      data: data,
    });
    return lastValueFrom(modal.afterClosed());
  }

  public layerMapData(data: { [k: string]: any }[]) {
    const modal = this.dialog.open(GeometryPropertiesDialogComponent, {
      data: data,
      position: { bottom: '0' },
      panelClass: 'my-dialog-no-style',
      maxHeight: '50vh',
      maxWidth: 'unset',
      minWidth: 'unset',
      width: '100%',
    });
    return lastValueFrom(modal.afterClosed());
  }

  private async _updateDataNoTransferCauses(): Promise<{
    allRegistes: ParametricsInterface[];
    activeRegister: ParametricsInterface[];
  }> {
    this.noTransferCausesServices.flushCache();
    await this.noTransferCausesServices.warm();
    const response = await this.noTransferCausesServices.getAll();
    return {
      allRegistes: response,
      activeRegister: response?.filter((value) => value?.active),
    };
  }
}
