import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { TableKeyItem } from '../../types/table';
import {
  MAT_AUTOCOMPLETE_DEFAULT_OPTIONS,
  MatAutocomplete,
  MatAutocompleteActivatedEvent,
  MatAutocompleteTrigger,
} from '@angular/material/autocomplete';
import { ValidatorsService } from '../../services/validators.service';
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';

@Component({
  selector: 'app-autocomplete-input',
  templateUrl: './autocomplete-input.component.html',
  styleUrls: ['./autocomplete-input.component.scss'],
  providers: [
    {
      provide: MAT_AUTOCOMPLETE_DEFAULT_OPTIONS,
      useValue: {
        overlayPanelClass: 'autocomplete-overlay-pane',
      },
    },
  ],
})
export class AutocompleteInputComponent implements OnInit, AfterViewInit, OnDestroy, OnChanges {
  @Output() sendValueSelected = new EventEmitter<number | string>();

  @Input() isDisabled: boolean = true;
  /**
   * warning: don't use unless you know what you are doing
   * Will disable field locking allowing to write text in selection, unexpected behaviour may occur.
   */
  @Input() dontLockInput: boolean = false;
  @Input() showIndex: boolean = false;
  @Input() inputLabel: string;
  @Input() defaultValue: TableKeyItem<string | number> | undefined = undefined;
  @Input() valuesToFilter: TableKeyItem<string | number>[] = [];
  @Input() isInputRequired: boolean = false;
  @Input() hideRequiredMarker?: boolean = false;
  @Input() isInputEmpty: boolean = false;
  @Input() wasFormTouched: boolean = false;
  @Input() onlyNumbers: boolean = false;
  @Input() numericInput: boolean = false;
  @Input() alphanumericPlusInput: boolean = false;
  @Input() alphanumericInput: boolean = false;
  @Input() alphanumericSlashInput: boolean = false;
  @Input() alphanumericOnlyInput: boolean = false;
  @Input() maxLength: number;
  @Input() inputMaxLength?: number;
  @Input() showExpandArrow: boolean = true;
  @Input() autoCompleteChart: string;
  @Input() autoMatch: boolean = false;
  @Input() incidentYear?: number;
  @Input() otherActionCode: number;
  @Input() returnInputValue: boolean = false;
  @Input() useSort: boolean = false;
  @Input() detectParentSize: boolean = true;
  @Input() closeOnScroll: boolean = true;
  public searchForm: FormGroup; // Form Search
  public placeFilteredOptions: TableKeyItem<string | number>[];
  public height: string = '48px';
  public subscription$: Subscription;
  private lastDefaultValue: TableKeyItem<string | number> | undefined = undefined;
  private optionIndex: number = -1;
  private autocompleteIndex: number = 0;
  private clickFlag: boolean = false;
  private useSmoothScroll: boolean = true;
  private focusSubs: Subscription | undefined;

  public get selectedValue(): TableKeyItem | string | undefined {
    return this.searchForm.get('value')?.value;
  }

  @ViewChild('input') input: ElementRef;
  @ViewChild('auto') auto: MatAutocomplete;
  @ViewChild('input', { read: MatAutocompleteTrigger })
  autoComplete!: MatAutocompleteTrigger;
  @ViewChild('origin') position: ElementRef;
  @ViewChild('CdkVirtualScrollViewport')
  cdkVirtualScrollViewport: CdkVirtualScrollViewport;
  private eventListener: () => void;

  constructor(
    private formBuilder: FormBuilder,
    public validatorsService: ValidatorsService
  ) {}

  async ngOnInit(): Promise<void> {
    this.lastDefaultValue = this.defaultValue;
    this.buildForm();
    this.initAutocomplete();
    if (this.useSort) {
      this.sortList();
    }
  }

  ngAfterViewInit() {
    this.eventListener = () => {
      if (!this.autoComplete.panelOpen) {
        return;
      }
      if (this.clickFlag) this.optionIndex = -1;
      if (this.detectParentSize) {
        const component = this.position;
        if (component.nativeElement.parentElement) {
          let parentNode: Element | null = component.nativeElement.parentElement;
          let scrollableParent: Element | undefined = undefined;
          while (parentNode) {
            if (parentNode.scrollHeight > parentNode.clientHeight) {
              if (
                window.navigator.userAgent.toLowerCase().includes('firefox') &&
                parentNode.clientHeight === 0
              ) {
                parentNode = parentNode.parentElement;
              } else {
                scrollableParent = parentNode;
                break;
              }
            } else {
              parentNode = parentNode.parentElement;
            }
          }

          if (scrollableParent) {
            const scrollableRects: DOMRect = scrollableParent.getBoundingClientRect();
            const originRects: DOMRect = component.nativeElement.getBoundingClientRect();
            if (this.autoComplete.panelOpen) {
              if (
                (originRects.top > scrollableRects.top &&
                  originRects.bottom < scrollableRects.bottom) ||
                !this.closeOnScroll
              ) {
                this.autoComplete.updatePosition();
              } else {
                this.autoComplete.closePanel();
              }
            }
          }
        }
      }
    };
  }

  onOpenPanel() {
    document.addEventListener('scroll', this.eventListener, true);
    document.addEventListener('wheel', this.eventListener2.bind(this), true);
    document.addEventListener('mousedown', this.eventListener2.bind(this), true);
    const index = this.defaultValue ? this.valuesToFilter.indexOf(this.defaultValue) : 0;
    this.cdkVirtualScrollViewport.scrollToIndex(index);
    this.cdkVirtualScrollViewport.checkViewportSize();
  }

  eventListener2() {
    this.optionIndex = -1;
  }

  onFocusEvent() {
    this.focusSubs = this.searchForm.get('value')?.valueChanges.subscribe((event) => {
      if (!this.auto.isOpen && event && typeof event !== 'object') {
        this.autoComplete.openPanel();
      }
      if (!event) {
        this.placeFilteredOptions = this.valuesToFilter;
        this.height =
          (this.placeFilteredOptions.length >= 5 ? 240 : this.placeFilteredOptions.length * 48) +
          'px';
      }
    });
    this.placeFilteredOptions = this.valuesToFilter;
    this.height =
      (this.placeFilteredOptions.length >= 5 ? 240 : this.placeFilteredOptions.length * 48) + 'px';
    this.autoComplete.openPanel();
  }

  focusInput() {
    this.input.nativeElement?.click();
    this.input.nativeElement?.focus();
  }

  /**
   * for scrollbar functionality
   */
  setClick() {
    this.clickFlag = true;
  }

  onClosePanel() {
    document.removeEventListener('scroll', this.eventListener, true);
    document.removeEventListener('wheel', this.eventListener2.bind(this), true);
    document.removeEventListener('mousedown', this.setClick.bind(this), true);
    this.optionIndex = -1;
  }

  ngOnDestroy(): void {
    this.onClosePanel();
    this.subscription$?.unsubscribe();
  }

  ngOnChanges(): void {
    this.buildForm();
    if (this.useSort) {
      this.sortList();
    }
  }

  /**
   * Function to build form
   */
  buildForm() {
    if (!this.searchForm) {
      this.searchForm = this.formBuilder.group({
        value: new FormControl({
          value: this.defaultValue,
          disabled: this.isDisabled,
        }),
      });
    } else {
      //
      if (this.isDisabled) this.searchForm.controls['value'].disable();
      else this.searchForm.controls['value'].enable();
      //
      if (this.lastDefaultValue !== this.defaultValue) {
        this.searchForm.controls['value'].setValue(this.defaultValue);
        this.lastDefaultValue = this.defaultValue;
      }
    }
    if (this.isInputRequired) {
      this.searchForm.get('value')!.setValidators(Validators.required);
    }
  }

  /**
   * initialize the autocomplete inputs
   */
  public initAutocomplete(): void {
    this.subscription$ = this.searchForm.controls['value'].valueChanges
      .pipe(
        startWith(''),
        map((value: string | TableKeyItem) => {
          const filteredValues = this._filter(value);
          this.height = (filteredValues.length >= 5 ? 240 : filteredValues.length * 48) + 'px';
          this.placeFilteredOptions = filteredValues;
        })
      )
      .subscribe();
  }

  private _filter(value: string | TableKeyItem): TableKeyItem<string | number>[] {
    let filterValue: string = '';
    if (typeof value === 'string') {
      filterValue = value;
    } else {
      filterValue = value?.title || '';
    }
    filterValue = filterValue === '' && this.defaultValue ? this.defaultValue.title : filterValue;
    let filteredList = this.valuesToFilter.filter((option: TableKeyItem<string | number>) =>
      this.replaceAccents(option.title ?? '').includes(this.replaceAccents(filterValue ?? ''))
    );
    if (this.incidentYear) {
      filteredList = filteredList.filter(
        (item: TableKeyItem<string | number>) => item.year === this.incidentYear
      );
    }
    return filteredList;
  }

  /**
   * display function to autocomplete input
   * @param param
   */
  displayFn(param: TableKeyItem): string {
    return param && param.title ? param.title : '';
  }

  otherAction(event: KeyboardEvent) {
    this.keyboardEvent(event);
    if (!this.otherActionCode) return;
    if (event.keyCode === 13) {
      this.tryMatchOption();
    }
    if (this.autoMatch && this.otherActionCode) {
      if (event.keyCode === this.otherActionCode) {
        this.tryMatchOption();

        setTimeout(() => {
          return;
        });
      }
    }
  }

  /**
   * emits selected value
   */
  valueChange(): void {
    if (this.selectedValue && typeof this.selectedValue !== 'string') {
      this.sendValueSelected.emit(this.selectedValue.id as number);
      this.searchForm.get('value')?.enable();
    } else if (typeof this.selectedValue === 'string' && this.returnInputValue) {
      this.sendValueSelected.emit(this.selectedValue);
    }
  }

  tryMatchOption(event?: FocusEvent) {
    if (this.focusSubs) {
      this.focusSubs.unsubscribe();
    }

    if (event && event.relatedTarget) {
      const target = <HTMLElement>(<unknown>event.relatedTarget);
      if (target.classList.contains("don't-delete-keep-focus")) {
        return;
      }
    }
    if (typeof this.selectedValue === 'string') {
      const valueExist = this.valueExist();
      if (valueExist && typeof valueExist !== 'string') {
        this.searchForm.get('value')?.setValue(valueExist);
        // fix because depends of mat select hooks
        this.valueChange();
      } else {
        if (this.returnInputValue) {
          this.valueChange();
        } else {
          this.clearInput();
        }
      }
      this.autoComplete.closePanel();
    }
  }

  valueExist(): TableKeyItem<string | number> | undefined | string {
    if (this.selectedValue && typeof this.selectedValue === 'string') {
      const valueExist: TableKeyItem<string | number> | undefined = this.valuesToFilter.find(
        (value: TableKeyItem<string | number>) => {
          return this.incidentYear
            ? this.replaceAccents(value.title) ===
                this.replaceAccents(<string>this.selectedValue) && value.year === this.incidentYear
            : this.replaceAccents(value.title) === this.replaceAccents(<string>this.selectedValue);
        }
      );
      if (valueExist) {
        return valueExist;
      } else if (!valueExist && this.returnInputValue) {
        return this.selectedValue;
      }
    }
    return undefined;
  }

  replaceAccents(text: string): string {
    return text
      .toLowerCase()
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '');
  }

  clearInput() {
    if (this.isDisabled) return;
    this.searchForm.get('value')?.setValue(undefined);
    this.searchForm.get('value')?.enable();
    this.sendValueSelected.emit('');
  }

  closePanel() {
    if (this.autoComplete.panelOpen) {
      setTimeout(() => {
        this.autoComplete.closePanel();
      }, 20);
    } else {
      this.autoComplete.openPanel();
    }
  }

  keyboardEvent($event: KeyboardEvent) {
    if (this.alphanumericOnlyInput) {
      return this.validatorsService.alphanumericOnly($event);
    }
    if (!this.onlyNumbers) {
      if (
        this.valuesToFilter.length <= 10 &&
        !(
          $event.code === 'ArrowUp' ||
          $event.code === 'ArrowDown' ||
          $event.code === 'Tab' ||
          $event.code === 'Enter'
        )
      ) {
        $event.preventDefault();
      }
    }
    const keyCode = +($event.code || $event.which);

    if (
      (keyCode >= 48 && keyCode <= 57) ||
      (keyCode >= 65 && keyCode <= 90) ||
      keyCode >= 97 ||
      keyCode <= 122
    ) {
      if (!this.auto.isOpen) {
        this.autoComplete.closePanel();
      }
    }

    if ($event.code === 'Enter' && this.auto.isOpen) {
      this.autoComplete.closePanel();
    }

    this.clickFlag = false;
    if (
      $event.code === 'ArrowUp' &&
      this.optionIndex > 0 &&
      this.optionIndex < this.placeFilteredOptions.length
    ) {
      this.optionIndex = this.optionIndex - 1;
    } else if (
      $event.code === 'ArrowDown' &&
      this.optionIndex < this.placeFilteredOptions.length - 1
    ) {
      this.optionIndex = this.optionIndex + 1;
    }
    if (this.alphanumericPlusInput) {
      return this.validatorsService.generalNameOnly($event);
    } else if (this.alphanumericInput) {
      return this.validatorsService.strictAlphanumeric($event);
    } else if (this.alphanumericSlashInput) {
      return this.validatorsService.alphanumericSlashInput($event);
    } else {
      return this.numericInput ? this.validatorsService.numberOnly($event) : null;
    }
  }

  /**
   * search the index of the option to be activated
   */
  setActiveOption($event: MatAutocompleteActivatedEvent) {
    if ($event.option && this.optionIndex !== -1) {
      setTimeout(() => {
        const valuesWithIndex = [
          ...this.placeFilteredOptions.map((value, index) => ({
            ...value,
            index,
          })),
        ];
        let indexO = 0;
        const activeOption = valuesWithIndex.find((j) => j.index === this.optionIndex);
        if (activeOption) {
          this.auto.options.find((option, index) => {
            const validation = option.value.id === activeOption.id;
            if (validation) indexO = index;
            return validation;
          });
        } else {
          this.optionIndex = 0;
        }
        this.autocompleteIndex = indexO;
        this.cdkVirtualScrollViewport.checkViewportSize();
        this.cdkVirtualScrollViewport.scrollToIndex(
          this.optionIndex,
          this.useSmoothScroll ? 'smooth' : undefined
        );

        if (!this.useSmoothScroll) return;
        setTimeout(() => {
          this.useSmoothScroll = true;
        }, 100);
        this.useSmoothScroll = false;
      }, 0);
    }
    setTimeout(() => {
      if (this.optionIndex !== -1) this.auto._keyManager.setActiveItem(this.autocompleteIndex);
    }, 0);
  }

  sortList() {
    const allTitlesAreNumber = this.valuesToFilter.every(
      (value: TableKeyItem<string | number>) => !isNaN(Number(value.title))
    );

    if (allTitlesAreNumber) {
      this.valuesToFilter.sort((a, b) => Number(a.title) - Number(b.title));
    } else {
      this.valuesToFilter.sort((a, b) =>
        a.title.toLowerCase().localeCompare(b.title.toLowerCase())
      );
    }
  }
}
