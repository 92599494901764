import { ProviderKnownItemId, ProvidersKnownRoutes } from './providers.known.routes';
import { IncidentsKnownItemId, IncidentsKnownRoutes } from './incidents.known.routes';
import { HospitalsKnownItemId, HospitalsKnownRoutes } from './hospitals.known.routes';
import {
  AdministrationKnownItemId,
  AdministrationKnownRoutes,
} from './administration.known.routes';
import { ReferenceKnownItemId, ReferenceKnownRoutes } from './reference.known.routes';
import { FollowUpKnownItemId, FollowUpKnownRoutes } from './followup.known.routes';
import { StretcherKnownItemId, StretcherKnownRoutes } from './stretcher.known.routes';
import { PortalsKnownItemId, PortalsKnownRoutes } from './portals.known.routes';
import { MapsKnownItemId, MapsKnownRoutes } from './maps.known.routes';
import { PowerBiKnownItemId, PowerBiKnownRoutes } from './power-bi.known.routes';
import {
  HumanTalentTrainingKnownItemId,
  HumanTalentTrainingKnownRoutes,
} from './human-talent-training.known.routes';
import { ParametricKnownItemId, ParametricKnownRoutes } from './parametric.known.router';
import { EnumsUnion, NavItem, SidebarMenus } from './types-and-helpers.known.routes';

/**
 * ¡IMPORTANTE, LEER!
 * Es necesario que cada enum que se agregue en EnumsUnion se agregue en EnumsArray
 * No es posible automatizar la validación por lo que es necesario escribir manualmente 2 veces el enum
 * De no hacerlo se omite una validación que puede generar bugs con los ids de las rutas
 * Ya que son string-based enum numbers
 * Se crea la variable _LinkToEnumsUnion como un acceso directo a EnumsUnion
 */

type _LinkToEnumsUnion = EnumsUnion;

const EnumsArray = [
  ProviderKnownItemId,
  AdministrationKnownItemId,
  IncidentsKnownItemId,
  HospitalsKnownItemId,
  PowerBiKnownItemId,
  HumanTalentTrainingKnownItemId,
  ParametricKnownItemId,
  ReferenceKnownItemId,
  FollowUpKnownItemId,
  StretcherKnownItemId,
  PortalsKnownItemId,
  MapsKnownItemId,
];
const isKarma = typeof window !== 'undefined' && '__karma__' in window;
if (!isKarma) {
  ((array = EnumsArray) => {
    for (let n = 0; n < array.length; n++) {
      const ids = array[n];
      const usedNumbers: Record<number, boolean> = {};
      for (const id of Object.values(ids)) {
        const parsedId = parseInt(id);
        if (isNaN(parsedId)) {
          // todos los ids, así sean string, deben poder convertirse a number de forma exitosa
          throw new Error(`El id ${id} en el arreglo del index ${n} genera un id inválido`);
        }
        if (usedNumbers[parsedId]) {
          // el id no se debe repetir. la repetición solo se valida por enum, no entre todos.
          // se debe validar con el id en numbero, no string!
          throw new Error(`El id ${id} en el arreglo del index ${n} es repetido`);
        }
        usedNumbers[parsedId] = true;
      }
    }
  })();
}

const ConvertEnum = (input: Record<string, string>, method: (input: any) => NavItem): NavItem[] => {
  if (isKarma && !input) {
    return [];
  } else {
    return Object.values(input).map(method);
  }
};

export const SidebarTreeMap: Record<SidebarMenus, NavItem[]> = {
  [SidebarMenus.Providers]: ConvertEnum(ProviderKnownItemId, ProvidersKnownRoutes),
  [SidebarMenus.Administration]: ConvertEnum(AdministrationKnownItemId, AdministrationKnownRoutes),
  [SidebarMenus.Incidents]: ConvertEnum(IncidentsKnownItemId, IncidentsKnownRoutes),
  [SidebarMenus.Hospitals]: ConvertEnum(HospitalsKnownItemId, HospitalsKnownRoutes),
  [SidebarMenus.PowerBi]: ConvertEnum(PowerBiKnownItemId, PowerBiKnownRoutes),
  [SidebarMenus.HumanTalent]: ConvertEnum(
    HumanTalentTrainingKnownItemId,
    HumanTalentTrainingKnownRoutes
  ),
  [SidebarMenus.Parametric]: ConvertEnum(ParametricKnownItemId, ParametricKnownRoutes),
  [SidebarMenus.Reference]: ConvertEnum(ReferenceKnownItemId, ReferenceKnownRoutes),
  [SidebarMenus.FollowUp]: ConvertEnum(FollowUpKnownItemId, FollowUpKnownRoutes),
  [SidebarMenus.Stretcher]: ConvertEnum(StretcherKnownItemId, StretcherKnownRoutes),
  [SidebarMenus.Portals]: ConvertEnum(PortalsKnownItemId, PortalsKnownRoutes),
  [SidebarMenus.Maps]: ConvertEnum(MapsKnownItemId, MapsKnownRoutes),
};

const getPermissions = (array: NavItem[]) => {
  const set = new Set<string>();
  for (const item of array) {
    if (item.permissions) {
      for (const permission of item.permissions) {
        set.add(permission);
      }
    }
  }
  return Array.from(set);
};

/**
 * Returns all permissions inside each section
 */
export const SidebarTreeMapPermissions = {} as Record<SidebarMenus, string[]>;

setTimeout(() => {
  for (const value of Object.values(SidebarMenus)) {
    SidebarTreeMapPermissions[value] = getPermissions(SidebarTreeMap[value]);
  }
}, 10);
