import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { AuthService } from '../services/auth/auth.service';
import { AccountStatus, ProfileService } from '../services/profile/profile.service';
import { SocketService } from '../services/web-sockets/web-sockets.service';
import { RegistrationService } from '../services/registration/registration.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  constructor(
    private profileService: ProfileService,
    private router: Router,
    private authService: AuthService,
    private socketService: SocketService,
    private registrationService: RegistrationService
  ) {}
  public async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const { statusToEnter } = route.data;
    if (
      this.profileService.ready === AccountStatus.IDLE ||
      this.profileService.ready === AccountStatus.RECOVERING
    ) {
      while (true) {
        await new Promise((resolve) => setTimeout(resolve, 10));
        if (
          this.profileService.ready !== AccountStatus.IDLE &&
          this.profileService.ready !== AccountStatus.RECOVERING
        ) {
          break;
        }
      }
    }
    if (statusToEnter === 'not-logged') {
      if (this.authService.checkToken('REFRESH')) {
        await this.router.navigateByUrl('/home');
        return false;
      }
    } else if (statusToEnter === 'logged') {
      if (!this.authService.checkToken('REFRESH')) {
        this.registrationService.redirectUrl = state.url;
        await this.router.navigateByUrl('/auth');
        return false;
      }
      this.socketService.connect({
        actorId: this.profileService.authenticatedActor?.id || -1,
      });
    }
    return true;
  }
}
