import { Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
  selector: 'input[incidentsAutoComplete]',
})
export class IncidentsAutoCompleteDirective {
  @Input()
  incidentsAutoComplete: boolean = false;

  @Input()
  incidentsAutoCompleteChart = '*';

  @Input()
  incidentsAutoCompleteMaxLength: number = 0;

  constructor(private _el: ElementRef) {}

  @HostListener('input', ['$event']) onInputChange(event: any) {
    const initialValue = this._el.nativeElement.value;
    let regexValue = initialValue.replace(/^[0]*(.*?)[0]*/g, '');

    this._el.nativeElement.value = regexValue;

    if (regexValue.length > this.incidentsAutoCompleteMaxLength) {
      regexValue = regexValue.slice(0, 8);
      this._el.nativeElement.value = regexValue;
      if (initialValue !== this._el.nativeElement.value) {
        event.stopPropagation();
      }
    }

    if (this.incidentsAutoComplete && this.incidentsAutoCompleteMaxLength > 0) {
      if (regexValue.length > 8) {
        initialValue.slice(0, -1);
        this._el.nativeElement.value = initialValue;
        event.stopPropagation();
      } else {
        const maxLength = this.incidentsAutoCompleteMaxLength - regexValue.length;
        let newValue = '';
        for (let i = 0; i < maxLength; i++) {
          newValue = newValue + this.incidentsAutoCompleteChart;
        }
        newValue += regexValue;
        this._el.nativeElement.value = newValue;
      }
    }
  }
}
