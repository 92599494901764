/* eslint-disable no-console */
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { AccountStatus, ProfileService } from '../services/profile/profile.service';
import { DialogService } from '../services/dialog.service';
import { PermissionsValidatorService } from '../services/roles-and-permissions/permissions-validator.service';
import { RoleTabService } from '../services/administration/roles/role-tab.service';
import { KnownRoles } from '../services/roles-and-permissions/known-roles.data';
import { Role } from '../services/roles-and-permissions/role.service';
import { AlertService } from '../services/alert.service';
import { ZoneSelectorComponent } from '../component/header/component/zone-selector/zone-selector.component';
import { MatDialog } from '@angular/material/dialog';
import { lastValueFrom } from 'rxjs';
import { AlertNotificationService } from '../services/administration/alert-notification/alert-notification.service';

export interface PermissionGuardData {
  permissions?: string[];
  validateInherited?: boolean;
  /**
   * @deprecated Evitar en lo posible este campo ya que será eliminado en un futuro ya que rompe el proposito de los permisos
   * se mantiene con fines de retrocompatibilidad. Utilizar el campo permissions en su lugar.
   */
  roles?: KnownRoles[];
  section?: {
    label: string;
    parentRoute: unknown | unknown[];
  };
}

@Injectable({
  providedIn: 'root',
})
export class PermissionGuard implements CanActivate {
  constructor(
    private profileService: ProfileService,
    private router: Router,
    private dialogService: DialogService,
    private permissionsValidatorService: PermissionsValidatorService,
    private roleAndPermissionsService: RoleTabService,
    private alertService: AlertService,
    private matDialog: MatDialog,
    private alertNotificationService: AlertNotificationService
  ) {}

  public async canActivate(
    route: ActivatedRouteSnapshot,
    _state: RouterStateSnapshot
  ): Promise<boolean> {
    const routeData = route.data as PermissionGuardData;

    if (!routeData || !routeData.permissions) {
      return true;
    }

    if (
      this.profileService.ready === AccountStatus.IDLE ||
      this.profileService.ready === AccountStatus.RECOVERING
    ) {
      while (true) {
        await new Promise((resolve) => setTimeout(resolve, 10));
        if (
          this.profileService.ready !== AccountStatus.IDLE &&
          this.profileService.ready !== AccountStatus.RECOVERING
        ) {
          break;
        }
      }
    }

    try {
      if (!this.profileService.selectedRole) {
        await this.profileService.recoverUserData();
      }

      if (!this.profileService.authenticatedActor?.roles) {
        return false;
      }

      if (this.profileService.authenticatedActor.roles.length === 1) {
        this.profileService.selectedRole = this.profileService.authenticatedActor.roles[0];
        this.alertNotificationService.getPendingReceivedCount(true);
      }

      if (!this.profileService.selectedRole) {
        await this.dialogService.rolesModal(
          this.profileService.getNames({
            firstName: true,
            lastName: true,
          }),
          this.profileService.authenticatedActor.roles
        );
        while (true) {
          await new Promise((resolve) => setTimeout(resolve));
          if (this.profileService.selectedRole) {
            break;
          }
        }
        const role = await this.roleAndPermissionsService.roleService.searchByName(
          this.profileService.selectedRole
        );
        if (!role) {
          this.permissionsValidatorService.resetLastLanding();
          this.router.navigateByUrl('auth');
          return false;
        }
        if (role.length > 1) {
          const exactRole = role.find(
            (role) => role.name === (this.profileService.selectedRole as Role).name
          );
          if (!exactRole) {
            this.permissionsValidatorService.resetLastLanding();
            this.router.navigateByUrl('auth');
            return false;
          }
        }
      }
      //Validate zone when only have one role
      if (!this.profileService.zone) {
        if (
          (await this.permissionsValidatorService.hasAccessToSection(['incident_south_zone_q'])) &&
          (await this.permissionsValidatorService.hasAccessToSection(['incident_north_zone_q']))
        ) {
          const dialog = this.matDialog.open(ZoneSelectorComponent, {
            width: '40%',
            minHeight: '170px',
            minWidth: '170px',
            panelClass: 'add-contact-modal',
            data: this.profileService.selectedRole.id,
          });
          this.profileService.zone = await lastValueFrom(dialog.afterClosed());
        } else if (
          await this.permissionsValidatorService.hasAccessToSection(['incident_south_zone_q'])
        ) {
          this.profileService.zone = 2;
        } else if (
          await this.permissionsValidatorService.hasAccessToSection(['incident_north_zone_q'])
        ) {
          this.profileService.zone = 1;
        }
      }
      //
      const result = await this.permissionsValidatorService.hasAccessToSection(
        routeData.permissions,
        undefined,
        routeData.validateInherited
      );
      console.log(result ? 'Allowed access to' : 'Not allowed access to:', route.url);
      console.log('Data:', routeData);
      console.log('Available permissions:', this.permissionsValidatorService.getCachedRoleData());
      //
      if (result) {
        return true;
      } else if (
        routeData.roles?.length &&
        routeData.roles.includes(this.profileService.selectedRole.name as KnownRoles)
      ) {
        return true;
      } else {
        await this.loadDefaultRoute();
        return false;
      }
    } catch (e) {
      console.error(e);
      await this.profileService.logOut();
      return false;
    }
  }

  async loadDefaultRoute() {
    const maxCount = this.permissionsValidatorService.maxRouteCount;
    const maxTime = this.permissionsValidatorService.maxRoutingTime;
    const route = await this.permissionsValidatorService.defaultLandingRoute();
    if (route) {
      console.info('defaultLanding', route);
      const inLoopByCount = this.permissionsValidatorService.lastLandingFetch.some(
        (item) => item.count > maxCount
      );
      const maxTimeExceeded =
        Date.now() - this.permissionsValidatorService.lastLandingDate > maxTime;
      if (!maxTimeExceeded && inLoopByCount) {
        console.error('Detected infinite loop routing');
        console.info('route', route);
        console.info(
          'lastLandingFetchCount',
          this.permissionsValidatorService.lastLandingFetch.find((item) => item.count > maxCount)
        );
        console.info('lastLandingFetch', this.permissionsValidatorService.lastLandingFetch);
        await this.profileService.logOut();
        this.alertService.showError(
          'La configuración de permisos es inválida, contacte con soporte.'
        );
        this.permissionsValidatorService.resetLastLanding();
        await this.router.navigateByUrl('auth');
      } else {
        if (maxTimeExceeded) this.permissionsValidatorService.resetLastLanding();
        await this.router.navigateByUrl(route.route);
      }
    } else {
      await this.profileService.logOut();
    }
  }
}
