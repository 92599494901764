import { HttpClient, HttpParams } from '@angular/common/http';
import { ServerResponse } from '../types/http.interfaces';
import { SearchResult } from '../types/actor/actor.model.entity';
import { lastValueFrom } from 'rxjs';
import { NewTabAction, TabActionType, TabData } from '../types/parametric.service.abstract.class';
import { KeyPressedHandlerService } from './key-pressed-handler.service';
import { ProfileService } from './profile/profile.service';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';

interface CacheCrudServiceOptions {
  debug: boolean;
}

export type GetOptions = Omit<Parameters<HttpClient['get']>[1], 'params'>;
export type PostOptions = Omit<Parameters<HttpClient['post']>[1], 'params'>;
export type PatchOptions = Omit<Parameters<HttpClient['patch']>[1], 'params'>;
export type DeleteOptions = Omit<Parameters<HttpClient['delete']>[1], 'params'>;

/**
 * Generic service implementation for standard CRUD
 * T type represents Model handled by the service
 * C type represents input interface for POST operation
 * U type represents input interface for PUT/PATCH operation
 * S type represents input interface for SEARCH operation
 * P type represents input interface for tab data
 * Q type represents input interface for tab options
 */
export abstract class PaginatedCrudService<
  T extends { id: number },
  C,
  U,
  P,
  Q,
  S extends {
    searchBy: Record<string, T>;
    options?: { limit?: number; skip?: number };
  },
> {
  private readonly _id: number;
  //
  private httpClientRef: HttpClient;
  //
  protected readonly defaultLimit = 20;
  protected readonly defaultSkip = 0;

  private _options: CacheCrudServiceOptions = {
    debug: false,
  };

  public newTabAction: NewTabAction | undefined;
  protected _tabs: Array<TabData<P>> = [];
  protected _selectedTab: TabData<P> | undefined;
  protected _tabIdCounter: number = 0;

  showModifiedMark: boolean = true;

  protected constructor(
    http: HttpClient,
    private keyPressedHandlerService: KeyPressedHandlerService,
    public profileService: ProfileService
  ) {
    //Subscribe to logout event for close all tabs
    setTimeout(() => {
      profileService.onLogout.subscribe((isLoggedOut) => {
        if (isLoggedOut) {
          this.closeAllTabs();
        }
      });
    });

    this._id = Date.now();
    this.httpClientRef = http;
  }

  get tabs() {
    return this._tabs;
  }

  get selectedTab() {
    return this._selectedTab;
  }

  isSafeToCloseAllTabs(): boolean {
    return this._tabs.length > 0;
  }

  isSafeToCloseTab(_id: number): boolean {
    throw new Error('Unimplemented');
  }

  newTab(_actionType: TabActionType, _options?: Q): void {
    throw new Error('Unimplemented');
  }

  closeAllTabs(): void {
    const currentTabs = [...this.tabs];
    for (const tab of currentTabs) {
      this.closeTab(tab.id);
    }
  }

  closeTab(id: number): void {
    const tab = this.getTabData(id);
    if (!tab) return;
    const subscriptionName: string = tab.keysSubscriptionName;
    if (subscriptionName) {
      this.keyPressedHandlerService.deleteMethod(subscriptionName);
    }
    if (!this.isSafeToCloseTab(id)) {
      // todo pending
    }

    if (this._selectedTab?.id === id) {
      this._selectedTab = undefined;
    }
    this._tabs = this._tabs.filter((value) => value.id !== tab.id);
  }

  getTabData(id: number): TabData<P> | undefined {
    return this._tabs.find((value) => value.id === id);
  }

  selectTab(id: number): void {
    const tab = this.getTabData(id);
    if (!tab) {
      return;
    }
    const tabSubscriptionName = this._tabs.find((tab) => tab.id === id)?.keysSubscriptionName;
    if (tabSubscriptionName) {
      if (tabSubscriptionName.length > 0) {
        this.keyPressedHandlerService.setActiveSubscription(tabSubscriptionName);
      }
    }
    this._selectedTab = tab;
  }

  unselectTab(skipDeleteSubscription: boolean = false): void {
    this._selectedTab = undefined;
    this.keyPressedHandlerService.removeActiveSubscription(skipDeleteSubscription);
  }

  removeActiveMethod() {
    this.keyPressedHandlerService.removeActiveSubscription();
  }

  setActiveSubscription(subscriptionName: string) {
    this.keyPressedHandlerService.setActiveSubscription(subscriptionName);
  }

  addCallbackForKeys(
    cb: (keyPressedValue: number) => void,
    setActive: boolean = true,
    alwaysActive: boolean = true
  ) {
    return this.keyPressedHandlerService.addMethod(cb, setActive, alwaysActive);
  }

  deleteCallbackForKeys(subscriptionName: string) {
    return this.keyPressedHandlerService.deleteMethod(subscriptionName);
  }

  moveTab(event: CdkDragDrop<Array<TabData<P>>>) {
    moveItemInArray(this._tabs, event.previousIndex, event.currentIndex);
  }

  changeTabData(newData: TabData<P>) {
    this._selectedTab = newData;
  }

  public get options(): Required<CacheCrudServiceOptions> {
    this.debugLog('get options:', this._options);
    return this._options;
  }

  public set options(input: Partial<CacheCrudServiceOptions>) {
    if (input.debug) {
      this.debugLog('Changed debug to:', input.debug);
      this._options.debug = input.debug;
    }
  }

  protected async _queryData(
    url: string,
    query: S,
    options?: GetOptions
  ): Promise<SearchResult<T[]>> {
    let params = new HttpParams();
    for (const key of Object.keys(query.searchBy)) {
      const data = query.searchBy[key];
      if (Array.isArray(data)) {
        data.forEach((item) => {
          params = params.append(key, typeof item === 'object' ? JSON.stringify(item) : item);
        });
      } else {
        params = params.set(key, typeof data === 'object' ? JSON.stringify(data) : data);
      }
    }
    if (query.options) {
      for (const key of Object.keys(query.options)) {
        if (query.options && key in query.options) {
          const data = query.options[key as keyof typeof query.options];
          if (data) {
            if (Array.isArray(data)) {
              data.forEach((item) => {
                params = params.append(key, typeof item === 'object' ? JSON.stringify(item) : item);
              });
            } else {
              params = params.set(key, typeof data === 'object' ? JSON.stringify(data) : data);
            }
          }
        }
      }
    }
    params = params.set('skip', params.get('skip') ?? this.defaultSkip);
    params = params.set('limit', params.get('limit') ?? this.defaultLimit);
    //
    const fetchRoutine = (): Promise<SearchResult<T[]>> => {
      return lastValueFrom(
        this.httpClientRef.get<ServerResponse<SearchResult<T[]>>>(`${url}`, {
          params: params,
          ...options,
        })
      ).then((value) => value.data);
    };

    if (params.get('limit') === '-1') {
      const output: T[] = [];
      params = params.set('limit', this.defaultLimit);
      let currentSkip = 0;
      while (true) {
        params = params.set('skip', currentSkip);
        const result = await fetchRoutine();
        output.push(...result.result);
        if (result.pagination.currentPage >= result.pagination.totalPages) {
          break;
        }
        currentSkip += this.defaultLimit;
      }

      return {
        result: output,
        pagination: {
          totalPages: 1,
          currentPage: 1,
          totalItems: output.length,
          itemsPerPage: output.length,
        },
      };
    } else {
      return fetchRoutine();
    }
  }

  /**
   *
   * @param query if pass in limit -1 will query data until there are no more data (fetch all data), this must be avoided and in future will be deprecated
   * @param options
   * @param url
   */
  public async search(url: string, query: S, options?: GetOptions): Promise<SearchResult<T[]>> {
    this.debugLog('search: ', query, 'options:', options);
    return this._queryData(url, query, options);
  }

  protected async _getByIdData(url: string, options?: GetOptions): Promise<T | undefined> {
    const response = await lastValueFrom(
      this.httpClientRef.get<ServerResponse<T>>(url, { ...options })
    );
    if (response.data) {
      return response.data;
    }
    return undefined;
  }

  /**
   * returns element by its id
   * @param url
   * @param options
   */
  public async getById(url: string, options?: GetOptions): Promise<T | undefined> {
    this.debugLog('getById:', url, 'options:', options);
    try {
      return await this._getByIdData(url, options);
    } catch (e: unknown) {
      if ((<{ status: number | undefined }>e)?.status !== 404) {
        console.error(e);
      }
    }
    //
    return undefined;
  }

  protected async _patchData(url: string, input: U, options?: PatchOptions): Promise<T> {
    const response = await lastValueFrom(
      this.httpClientRef.patch<ServerResponse<T>>(url, input, { ...options })
    );
    return response.data;
  }

  public async patch(url: string, input: U, options?: PatchOptions): Promise<T> {
    this.debugLog('put: id', url, 'input', input, 'options:', options);
    return this._patchData(url, input, options);
  }

  protected async _postData(url: string, input: C, options?: PostOptions): Promise<T> {
    const response = await lastValueFrom(
      this.httpClientRef.post<ServerResponse<T>>(url, input, { ...options })
    );
    return response.data;
  }

  public async post(url: string, input: C, options?: PostOptions): Promise<T> {
    this.debugLog('post:', input, 'options:', options);
    return this._postData(url, input, options);
  }

  protected async _deleteData(url: string, options?: DeleteOptions): Promise<void> {
    await this.httpClientRef
      .delete<ServerResponse<T>>('url', {
        ...options,
      })
      .toPromise();
  }

  public async delete(url: string, options?: DeleteOptions): Promise<void> {
    this.debugLog('delete:', url, 'options:', options);
    return this._deleteData(url, options);
  }

  private debugLog(...data: unknown[]) {
    if (this._options.debug) {
      console.warn(`[(ID: ${this._id}) CacheService DEBUG]`, data);
    }
  }
}
