import { Injectable } from '@angular/core';
import { CachedCRUDService } from '../../../types/cached-c-r-u-d.service';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../../environments/environment';
import { lastValueFrom } from 'rxjs';
import { ServerResponse } from '../../../types/http.interfaces';

@Injectable({
  providedIn: 'root',
})
export class PupilShapeService extends CachedCRUDService<
  PupilShape,
  { name: string; description: string },
  any
> {
  readonly url = `${environment.api}/incidents-ms/api/v1/pupilShapes`;
  constructor(private http: HttpClient) {
    super();
  }

  protected override async _fetchNewData() {
    const resp = await lastValueFrom(this.http.get<ServerResponse<PupilShape[]>>(this.url));
    return resp.data;
  }

  protected override async _deleteData(id: number) {
    await lastValueFrom(this.http.delete<ServerResponse<PupilShape>>(`${this.url}/${id}`));
  }

  protected override async _postData(data: { name: string; description: string }) {
    const resp = await lastValueFrom(this.http.post<ServerResponse<PupilShape>>(this.url, data));
    return resp.data;
  }

  public async changeState(
    id: number,
    data: { isActive: boolean; changeStatusObservations: string }
  ) {
    await lastValueFrom(
      this.http.post<ServerResponse<PupilShape>>(`${this.url}/${id}/changeStatus`, data)
    );
  }
}

export interface PupilShape {
  createdAt: string;
  id: number;
  name: string;
  description: string;
  isActive: boolean;
  isBeingUsed: boolean;
}
