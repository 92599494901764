import { Injectable, Injector } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import jwt_decode from 'jwt-decode';
import {
  RecoveryMethod,
  TokenContent,
  TokenResponseContent,
} from '../../types/keycloak.interfaces';
import { Router } from '@angular/router';
import { DialogService } from '../dialog.service';
import { ContactPointSearchInput } from '../../types/actor/contact-point/contact-point.model.entity';
import { ServerError, ServerResponse } from '../../types/http.interfaces';
import { AuthStatusInterface } from '../../types/auth-status.interface';
import { LoadingService } from '../loading.service';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { ProfileService } from '../profile/profile.service';
import { ActorsService } from '../actors/actors.service';
import { lastValueFrom } from 'rxjs';
import { AuthenticationResult, EventType } from '@azure/msal-browser';
import { SocketService } from '../web-sockets/web-sockets.service';
import { AlertService } from '../alert.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private static sessionExpiredLimiter = 0;

  private _requestingAccessToken: Promise<boolean> | null;

  private profileService: ProfileService | undefined;

  static staticErrors = {
    wrong_data:
      'Los datos de nombre de usuario y/o contraseña son incorrectos. Por favor verifíquelos.',
    disabled: 'El usuario se encuentra INACTIVO, comuníquese con el administrador',
    expired_password: 'Su contraseña expiró, genere una nueva contraseña',
  };

  constructor(
    private http: HttpClient,
    public router: Router,
    private dialogService: DialogService,
    private alertService: AlertService,
    private loadingService: LoadingService,
    private msalService: MsalService,
    private actorService: ActorsService,
    private msalBroadcastService: MsalBroadcastService,
    private injector: Injector,
    private socketService: SocketService
  ) {
    this.msalBroadcastService.msalSubject$.subscribe((event) => {
      if (event.eventType === EventType.LOGIN_SUCCESS) {
        this.exchangeAzureWithKeycloakToken(event.payload as AuthenticationResult);
        // Handle tokens as needed in your application
      } else if (event.eventType === EventType.LOGIN_FAILURE) {
        const error = event.payload;
        // Handle authentication error
        console.error(error);
        this.dialogService.errorModal('INGRESO', 'Ocurrió un error inesperado, vuelva a intentar');
      }
    });
    this.msalService.handleRedirectObservable();
  }

  private getProfileService(): ProfileService {
    if (!this.profileService) {
      this.profileService = this.injector.get(ProfileService);
    }
    return this.profileService;
  }

  private async exchangeAzureWithKeycloakToken(payload: AuthenticationResult) {
    try {
      this.loadingService.setLoading(true);
      const authenticationResult = payload;
      // Tokens are available in the authentication result
      const idToken = authenticationResult.idToken;
      await this.loginExchangeTokenFlow(idToken);
      // ...
      const result = await this.validateAuthStatus();
      if (result) {
        await this.getProfileService()?.recoverUserData();
        this.loadingService.setLoading(false);
        if (!this.getProfileService()?.authenticatedActor?.acceptedTnCAt) {
          const result = await this.dialogService.termsAndConditions();
          this.loadingService.setLoading(true);
          if (result) {
            await this.actorService.registerActorEvent({
              eventType: 'acceptTnC',
              eventData: {
                actorId: this.getProfileService()?.authenticatedActor?.id || -1,
              },
            });
            this.loadingService.setLoading(false);
          } else {
            await this.getProfileService()?.logOut();
            this.loadingService.setLoading(false);
            return;
          }
        }
        if (!this.getProfileService()?.authenticatedActor?.acceptedPrivacyPolicies) {
          const result = await this.dialogService.dataProtectionPolicy();
          if (result) {
            await this.loadingService.setLoading(true);
            await this.actorService.registerActorEvent({
              eventType: 'acceptPrivacyPolicies',
              eventData: {
                actorId: this.getProfileService()?.authenticatedActor?.id || -1,
              },
            });
            await this.loadingService.setLoading(false);
          } else {
            await this.getProfileService()?.logOut();
            await this.loadingService.setLoading(false);
            return;
          }
        }
        if (this.profileService) this.profileService.loggedByAzure = true;
        this.loadingService.setLoading(false);
        this.socketService.connect({
          actorId: this.getProfileService()?.authenticatedActor?.id || -1,
        });
        await this.router.navigateByUrl('/home/incidents/list');
      }
    } catch (e) {
      console.error(e);
      const rawErrorMessage: string = (<Error>e).message || 'error';
      this.loadingService.setLoading(false);
      let message = 'Ocurrió un error en el intercambio de tokens, contacte a soporte';
      if (
        rawErrorMessage === 'El usuario se encuentra INACTIVO, comuníquese con el administrador'
      ) {
        message = rawErrorMessage;
      }
      await this.dialogService.errorModal('INGRESO', message);
    } finally {
      this.loadingService.setLoading(false);
    }
  }

  async validateAuthStatus(username?: string): Promise<boolean> {
    try {
      this.loadingService.setLoading(true);
      const status = await this.checkAuthStatus(
        username ? (username.includes('@') ? { email: username } : { username }) : undefined
      );
      if (status.status === 'account_not_created') {
        this.loadingService.setLoading(false);
        this.dialogService.errorModal(
          'INGRESO',
          'Usted no es un usuario válido para ingresar al sistema'
        );
        return false;
      }
      this.loadingService.setLoading(false);
      return true;
    } catch (e: any) {
      console.error(e);
      this.loadingService.setLoading(false);
      if (e.message === AuthService.staticErrors.wrong_data) {
        const response = await this.checkAuthStatus(
          username ? (username.includes('@') ? { email: username } : { username }) : undefined,
          true
        );
        if (response.statusReason === 'Locked due to failed logins attempts') {
          this.dialogService.errorModal(
            'INGRESO',
            'Ha superado el número de intentos de ingreso a nuestra plataforma, Su contraseña será bloqueada solicite una nueva contraseña por favor.'
          );
          return false;
        } else if (response.statusReason === 'Temporally locked due to failed logins attempts') {
          this.dialogService.errorModal(
            'INGRESO',
            'Ha superado el intento de ingresos en nuestra plataforma. ' +
              'Espere un minuto e intente de nuevo.'
          );
          return false;
        } else if (response.statusReason === 'Actor account not created') {
          this.dialogService.errorModal(
            'INGRESO',
            'Usted no es un usuario válido para ingresar al sistema'
          );
          return false;
        }
      }
      this.dialogService.errorModal('INGRESO', e.message);
    }
    this.loadingService.setLoading(false);
    return false;
  }

  /**
   * validates if recovery token is valid
   * @param code
   * @param recoveryMethod
   * @param contactPoint
   */
  public async validateRecoveryToken(
    code: string,
    recoveryMethod: RecoveryMethod,
    contactPoint?: ContactPointSearchInput
  ) {
    const httpOptions = {
      headers: new HttpHeaders({
        skip: 'true',
      }),
    };
    try {
      const decoded = <{ body: string | number }>this.decodeJwt(code);
      await lastValueFrom(
        this.http.post(
          environment.api + '/actors-ms/api/v1/auth/passwordRecovery/validate',
          {
            recoveryMethod: recoveryMethod,
            code: code,
            contactPoint: contactPoint,
            keycloakUserId: decoded?.body,
          },
          httpOptions
        )
      );
    } catch (error: unknown) {
      const e: ServerError = error as ServerError;
      let message = 'Error desconocido, vuelva a intentar';
      if (e?.error?.data) {
        const data = e.error.data;
        if (data === 'Invalid Code/User') {
          message = 'Código no válido';
        }
      }
      throw new Error(message);
    }
  }

  public async loginAzureAdB2C() {
    await this.msalService.instance.handleRedirectPromise();
    return this.msalService.loginRedirect({
      scopes: [`${environment.azureClientId}/.default`],
    });
  }

  /**
   * login using password flow
   * @param username user email
   * @param password user password
   */
  public async loginPasswordFlow(username: string, password: string) {
    try {
      const body = new HttpParams()
        .set('grant_type', 'password')
        .set('client_id', environment.keycloakClient)
        .set('client_secret', '')
        .set('username', username)
        .set('password', password)
        .set('scope', 'offline_access');
      const httpOptions = {
        headers: new HttpHeaders({
          skip: 'true',
          'Content-Type': 'application/x-www-form-urlencoded',
        }),
      };
      const result: TokenResponseContent = await lastValueFrom(
        this.http.post<TokenResponseContent>(
          environment.authService +
            '/realms/' +
            environment.keycloakRealm +
            '/protocol/openid-connect/token',
          body.toString(),
          httpOptions
        )
      );
      this.saveTokens(result.access_token, result.refresh_token);
    } catch (e: unknown) {
      console.error(e);
      throw new Error(AuthService.validateLoginErrors(e as ServerError));
    }
  }

  public async loginExchangeTokenFlow(idToken: string): Promise<string> {
    try {
      const body = new HttpParams()
        .set('grant_type', 'urn:ietf:params:oauth:grant-type:token-exchange')
        .set('client_id', environment.keycloakClient)
        .set('client_secret', '')
        .set('requested_token_type', 'urn:ietf:params:oauth:token-type:refresh_token')
        .set('subject_token_type', 'urn:ietf:params:oauth:token-type:id_token')
        .set('scope', 'offline_access')
        .set('subject_issuer', 'oidc-azure_ad_b2c')
        .set('subject_token', idToken);
      const httpOptions = {
        headers: new HttpHeaders({
          skip: 'true',
          'Content-Type': 'application/x-www-form-urlencoded',
        }),
      };
      const result = await lastValueFrom(
        this.http.post<any>(
          environment.authService +
            '/realms/' +
            environment.keycloakRealm +
            '/protocol/openid-connect/token',
          body.toString(),
          httpOptions
        )
      );
      this.saveTokens(result.access_token, result.refresh_token);
      return result.access_token;
    } catch (e: unknown) {
      console.error(e);
      throw new Error(AuthService.validateLoginErrors(e as ServerError));
    }
  }

  /**
   * validates errors coming from API
   * */
  private static validateLoginErrors(e: ServerError): string {
    let message = 'Error desconocido, vuelva a intentar';
    if (e?.error?.error_description) {
      const error_description = e.error.error_description;
      if (error_description === 'Invalid user credentials') {
        message = this.staticErrors.wrong_data;
      } else if (
        error_description === 'Account disabled' ||
        error_description === 'Invalid Token'
      ) {
        message = this.staticErrors.disabled;
      } else if (error_description === 'Account is not fully set up') {
        message = this.staticErrors.expired_password;
      }
    }
    return message;
  }

  /**
   * returns user actor id from token
   */
  public getActorId(): string {
    const result = this.getDecodedToken('ACCESS');
    if (result) {
      return result.actorId;
    } else {
      throw new Error('Invalid access token');
    }
  }

  /**
   * returns user actor id from token
   */
  public getActorIp(): string {
    const result = this.getDecodedToken('ACCESS');
    if (result) {
      return result.lastIp;
    } else {
      throw new Error('Invalid access token');
    }
  }

  /**
   * passRecovery send request to password recovery
   * @param recoveryMethod
   * @param sendChannel
   * @param contactPoint
   */
  public async requestPasswordRecoveryCodeWithContactPoint(
    recoveryMethod: RecoveryMethod,
    sendChannel: 'EMAIL',
    contactPoint: ContactPointSearchInput
  ) {
    const httpOptions = {
      headers: new HttpHeaders({
        skip: 'true',
      }),
    };
    try {
      await lastValueFrom(
        this.http.post(
          environment.api + '/actors-ms/api/v1/auth/passwordRecovery/request',
          {
            recoveryMethod: recoveryMethod,
            sendChannel: sendChannel,
            contactPoint: contactPoint,
            searchBy: 'CONTACT_POINT',
          },
          httpOptions
        )
      );
    } catch (error: unknown) {
      const e: ServerError = error as ServerError;
      let message = 'Error desconocido, vuelva a intentar';
      if (e?.error?.data) {
        const data = e.error.data;
        if (data === 'No actor found' || data === 'No valid email to send link') {
          message = 'Correo no registrado en el sistema';
        } else if (data === 'given actor is not active') {
          message = 'El usuario no se encuentra activo';
        }
      }
      throw new Error(message);
    }
  }

  /**
   * passRecovery send request to password recovery
   * @param recoveryMethod
   * @param sendChannel
   * @param document
   * @param documentTypeId
   */
  public async requestPasswordRecoveryCodeWithDocument(
    recoveryMethod: RecoveryMethod,
    sendChannel: 'EMAIL',
    document: string,
    documentTypeId: number
  ) {
    const httpOptions = {
      headers: new HttpHeaders({
        skip: 'true',
      }),
    };
    try {
      await lastValueFrom(
        this.http.post(
          environment.api + '/actors-ms/api/v1/auth/passwordRecovery/request',
          {
            recoveryMethod: recoveryMethod,
            sendChannel: sendChannel,
            document,
            documentTypeId,
            searchBy: 'DOCUMENT',
          },
          httpOptions
        )
      );
    } catch (error: unknown) {
      const e: ServerError = error as ServerError;
      console.error(e);
      let message = 'Error desconocido, vuelva a intentar';
      if (e?.error?.data) {
        const data = e.error.data;
        if (data === 'No actor found' || data === 'No valid email to send link') {
          message = 'Correo/documento no registrado en el sistema';
        } else if (data === 'given actor is not active') {
          message = 'El usuario no se encuentra activo';
        }
      }
      throw new Error(message);
    }
  }

  /**
   * Return account status, and dates before password expires
   * */
  public async checkAuthStatus(
    options?: { email?: string; username?: string },
    loggedUser: boolean = false
  ): Promise<AuthStatusInterface> {
    if (!options?.email && !options?.username && loggedUser) {
      throw new Error('No valid option');
    }
    const httpOptions = {
      headers: new HttpHeaders({
        skip: 'true',
      }),
    };
    try {
      const res: ServerResponse<AuthStatusInterface> = await lastValueFrom(
        this.http.get<ServerResponse<AuthStatusInterface>>(
          `${environment.api}/actors-ms/api/v1/auth/userAuthStatus?${options ? (options.email ? 'email' : 'username') + '=' + (options.email || options.username) : ''}`,
          loggedUser ? httpOptions : undefined
        )
      );
      return res.data;
    } catch (e) {
      throw e;
    }
  }

  public decodeJwt(token: string) {
    return jwt_decode(token);
  }

  /**
   * changePass change user password
   * @param code
   * @param recoveryMethod
   * @param contactPoint
   * @param newPassword
   */
  public async usePasswordRecoveryCode(
    code: string,
    recoveryMethod: RecoveryMethod,
    newPassword: string,
    contactPoint?: ContactPointSearchInput
  ) {
    const httpOptions = {
      headers: new HttpHeaders({
        skip: 'true',
      }),
    };
    try {
      const decoded = <{ body: string }>this.decodeJwt(code);
      await lastValueFrom(
        this.http.post(
          environment.api + '/actors-ms/api/v1/auth/passwordRecovery/use',
          {
            recoveryMethod: recoveryMethod,
            code: code,
            newPassword: newPassword,
            contactPoint: contactPoint,
            keycloakUserId: decoded?.body,
          },
          httpOptions
        )
      );
    } catch (error: unknown) {
      const e: ServerError = error as ServerError;
      let message = 'Error desconocido, vuelva a intentar';
      if (e?.error?.data) {
        const data = e.error.data;
        if (data === 'Invalid Code/User') {
          message = 'Código no válido';
        }
      }
      throw new Error(message);
    }
  }

  /**
   * logout sign out and delete keycloak session
   * NOTE: MUST NOT DIRECTLY USE, intended to be used by profile service
   */
  public async logOut() {
    try {
      let body: HttpParams;
      const token = this.getToken('REFRESH');
      const test: string | number | boolean = token ? token : '';
      body = new HttpParams()
        .set('client_id', environment.keycloakClient)
        .set('refresh_token', test);
      body = body.set('scope', 'offline_access');
      const httpOptions = {
        headers: new HttpHeaders({
          skip: 'true',
          'Content-Type': 'application/x-www-form-urlencoded',
        }),
      };
      await lastValueFrom(
        this.http.post(
          environment.authService +
            '/realms/' +
            environment.keycloakRealm +
            '/protocol/openid-connect/logout',
          body.toString(),
          httpOptions
        )
      );
      this.deleteTokens();
    } catch (error: unknown) {
      this.deleteTokens();
      const e: ServerError = error as ServerError;
      if (e.error?.error !== 'Session not found') {
        throw e;
      }
    }
  }

  /**
   * deletes access and refresh token from local storage
   */
  public deleteTokens() {
    localStorage.removeItem('atoken');
    localStorage.removeItem('rtoken');
    localStorage.clear();
  }

  /**
   * validate if access or refresh token is valid
   */
  public checkToken(type: 'ACCESS' | 'REFRESH'): boolean {
    const token: TokenContent | boolean = this.getDecodedToken(type);
    if (type === 'ACCESS') {
      if (token) {
        return Date.now() < token.exp * 1000 - 30000;
      }
    } else if (type === 'REFRESH') {
      if (token) {
        if (!token.exp) {
          return true;
        }
        return Date.now() < token.exp * 1000;
      }
    }
    return false;
  }

  /**
   * return decoded access or refresh token or false if not valid/present
   */
  public getDecodedToken(type: 'ACCESS' | 'REFRESH'): TokenContent | false {
    try {
      const token = this.getToken(type);
      return jwt_decode(token);
    } catch (error) {
      return false;
    }
  }

  /**
   * returns access or refresh token
   */
  public getToken(type: 'ACCESS' | 'REFRESH'): string {
    if (type === 'ACCESS') {
      return localStorage.getItem('atoken') || '';
    } else if (type === 'REFRESH') {
      return localStorage.getItem('rtoken') || '';
    }
    return '';
  }

  /**
   * refreshToken generate new access token from refresh token
   */
  public async refreshToken() {
    if (this._requestingAccessToken) {
      return this._requestingAccessToken;
    }
    this._requestingAccessToken = this.doRefreshToken();
    this._requestingAccessToken.then(() => {
      this._requestingAccessToken = null; // DONT DELETE
    });
    this._requestingAccessToken.catch(() => {
      this._requestingAccessToken = null; // DONT DELETE
    });
    return this._requestingAccessToken;
  }

  /**
   * refreshes the given tokens
   * */
  private async doRefreshToken(attempt = 0): Promise<boolean> {
    try {
      await new Promise((resolve) => setTimeout(resolve, attempt * 100 + 1));
      const refreshToken = this.getToken('REFRESH');
      if (refreshToken) {
        const body = new HttpParams()
          .set('grant_type', 'refresh_token')
          .set('client_id', environment.keycloakClient)
          .set('client_secret', '')
          .set('refresh_token', refreshToken);
        const httpOptions = {
          headers: new HttpHeaders({
            skip: 'true',
            'Content-Type': 'application/x-www-form-urlencoded',
          }),
        };
        const result = await lastValueFrom(
          this.http.post<TokenResponseContent>(
            environment.authService +
              '/realms/' +
              environment.keycloakRealm +
              '/protocol/openid-connect/token',
            body.toString(),
            httpOptions
          )
        );
        this.saveTokens(result.access_token, result.refresh_token);
        return true;
      } else {
        console.warn(`[${Date.now()}]`, 'Invalid refresh token');
        return false;
      }
    } catch (error: unknown) {
      const e: ServerError = error as ServerError;
      if (
        e.error.error === 'invalid_grant' &&
        (e.error.error_description === 'Invalid refresh token' ||
          e.error.error_description === 'Offline user session not found' ||
          e.error.error_description === 'Stale token')
      ) {
        console.warn(`[${Date.now()}]`, 'Expired/revoked session');
        // known error for expired/revoked refresh tokens
        this.deleteTokens();
        return false;
      } else {
        if (attempt < 5) {
          return this.doRefreshToken(attempt + 1);
        } else {
          console.error(`[${Date.now()}]`, e);
          return false;
        }
      }
    }
  }

  /**
   * saveTokens save tokens into local storage
   * @param access access token
   * @param refresh refresh token
   */
  public saveTokens(access: string, refresh: string) {
    localStorage.setItem('atoken', access);
    localStorage.setItem('rtoken', refresh);
  }

  /**
   * behaviour when refresh token expired
   */
  public showSessionExpired() {
    this.loadingService.setLoading(false);
    if (AuthService.sessionExpiredLimiter === 0) {
      this.dialogService
        .errorModal('INGRESO', 'Su sesión ha caducado, por favor ingrese nuevamente.')
        .then(() => {
          this.deleteTokens();
          this.dialogService.closeAll();
          this.alertService.closeNotifications();
          this.router.navigate(['/']);
        });
      AuthService.sessionExpiredLimiter = Date.now();
      setTimeout(() => {
        this.loadingService.setLoading(false);
        AuthService.sessionExpiredLimiter = 0;
      }, 1000);
    }
  }
}
