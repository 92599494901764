<div
  class="cancel-dialog-responsive-container"
  (touchstart)="onTouchStart($event)"
  (touchend)="onTouchEnd($event)">
  <div class="dialog-header-button"><span></span></div>
  <app-svg
    name="cancel"
    class="color-secondary-200 dialog-close-icon cursor-pointer"
    (click)="dialogRef.close('no')"></app-svg>
  <div class="cancel-dialog-body">
    <span class="cancel-dialog-main-title">{{ data.mainTitle }}</span>
    <span class="text-b-16-white">{{ data.secondTitle }}</span>
    <span class="text-m-16-white">{{ data.message }}</span>
    <div class="responsive-dialog-buttons-field">
      <button mat-button class="btn-accept padding" (click)="dialogRef.close('no')">NO</button>
      <button mat-button class="btn-accept" (click)="dialogRef.close('yes')">SI</button>
    </div>
  </div>
</div>
