import { Injectable } from '@angular/core';

export enum LoadingColors {
  BLUE = '#42A4FA',
  CHARCOAL = '#233D4D',
  WHITE = 'white',
}

@Injectable({
  providedIn: 'root',
})
export class LoadingService {
  get loading(): boolean {
    return this._loading;
  }

  get incidentsLoading(): boolean {
    return this._incidentsLoading;
  }

  get parametricLoading(): boolean {
    return this._parametricLoading;
  }

  get loadingColor(): string {
    return this._loadingColor;
  }

  public readonly ngxLoadingAnimationTypes = {
    chasingDots: 'chasing-dots',
    circle: 'sk-circle',
    circleSwish: 'circleSwish',
    cubeGrid: 'sk-cube-grid',
    doubleBounce: 'double-bounce',
    none: 'none',
    pulse: 'pulse',
    rectangleBounce: 'rectangle-bounce',
    rotatingPlane: 'rotating-plane',
    threeBounce: 'three-bounce',
    wanderingCubes: 'wandering-cubes',
  };

  constructor() {}

  private _loading = false;
  private _incidentsLoading = false;
  private _parametricLoading = false;
  private _loadingColor = LoadingColors.BLUE;

  public setLoading(status: boolean, color: LoadingColors = LoadingColors.BLUE) {
    setTimeout(() => {
      this._loading = status;
      this._loadingColor = color;
    }, 1);
  }

  private _mapLoading = false;

  public setMapLoading(status: boolean) {
    setTimeout(() => {
      this._mapLoading = status;
    }, 1);
  }

  get mapLoading(): boolean {
    return this._mapLoading;
  }

  public setIncidentLoading(status: boolean) {
    setTimeout(() => {
      this._incidentsLoading = status;
    }, 1);
  }

  public setParametricLoading(status: boolean) {
    setTimeout(() => {
      this._parametricLoading = status;
    }, 1);
  }
}
