<div class="visualize-pdf-modal" *ngIf="showContent">
  <div class="modal-header">
    <div class="header-tab">
      <app-svg name="info-message" class="tab-icon mr-0-5"></app-svg>
      <span class="text-b-14-white">{{ data.modalTitle }}</span>
    </div>
    <app-svg name="cancel" class="tab-icon" (click)="dialogRef.close()"></app-svg>
  </div>
  <div class="body-container">
    <div class="modal-body" [appLoading]="isLoading">
      <div class="body-image-title text-m-18-white">{{ selectedFile.fileName }}</div>
      <ngx-extended-pdf-viewer
        *ngIf="selectedFile.fileBlob"
        [src]="selectedFile.fileBlob"
        [showPagingButtons]="displayOptions.showPagingButtons"
        [showDownloadButton]="displayOptions.showDownloadButton"
        [showFindButton]="displayOptions.showFindButton"
        height="80%"
        [showDrawEditor]="displayOptions.showDrawEditor"
        [showPrintButton]="displayOptions.showPrintButton"
        [showPresentationModeButton]="displayOptions.showPresentationModeButton"
        [showRotateButton]="displayOptions.showRotateButton"
        [showOpenFileButton]="displayOptions.showOpenFileButton"
        [showSecondaryToolbarButton]="displayOptions.showToolbar"
        [showZoomButtons]="displayOptions.showZoomButtons"
        [showSidebarButton]="displayOptions.showSidebarButton"
        [showToolbar]="displayOptions.showToolbar"></ngx-extended-pdf-viewer>
      <div class="width-100 display-flex mt-1">
        <div class="body-buttons">
          <app-svg name="expand-less" class="rotated-icon" (click)="previousFile()"></app-svg>
          <p class="text-m-14-blue">{{ this.position + 1 }} / {{ this.data.filesData.length }}</p>
          <app-svg name="expand-more" class="rotated-icon" (click)="nextFile()"></app-svg>
        </div>
      </div>
    </div>
  </div>
</div>
