import { randomBytes } from 'crypto';

export const randomString = (num: number) => {
  const charset = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const randomBytesArray = randomBytes(num);
  let result = '';
  for (let i = 0; i < num; i++) {
    result += charset.charAt(randomBytesArray[i] % charset.length);
  }
  return result;
};

/**
 * Generates a random number between min (inclusive) and max (exclusive)
 * @param min - The minimum value (inclusive)
 * @param max - The maximum value (exclusive)
 * @returns A random number between min and max
 */
export const randomNumber = (min: number = 0, max: number = 1): number => {
  if (min >= max) {
    throw new Error('Max must be greater than min');
  }

  const randomBuffer = randomBytes(6); // 6 bytes to get enough precision
  const randomNumber = parseInt(randomBuffer.toString('hex'), 16);
  const normalized = randomNumber / (0xffffffffffff + 1); // Normalize to range [0, 1)
  return normalized * (max - min) + min;
};
