<div class="geometry-properties-container">
  <app-svg
    name="cancel"
    class="color-secondary-200 close-dialog-icon"
    (click)="closeDialog()"></app-svg>
  <div class="info-container">
    <div *ngFor="let item of dataList">
      <p class="text-item">
        <span class="text-sb-14-white">{{ item.title + ': ' | uppercase }}</span>
        <span class="text-r-14-white">{{ item.value }}</span>
      </p>
    </div>
  </div>
</div>
