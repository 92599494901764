import { Injectable } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Router } from '@angular/router';
import { Operator, OperatorCreateInput, OperatorUpdateInput } from '@smartsoft-types/sisem-actors';
import { lastValueFrom } from 'rxjs';
import {
  ParametricService,
  TabActionType,
  TabData,
} from '../../../types/parametric.service.abstract.class';
import { DialogService } from '../../dialog.service';
import { ValidatorsService } from '../../validators.service';
import { ProfileService } from '../../profile/profile.service';
import { KeyPressedHandlerService } from '../../key-pressed-handler.service';
import { CachedCRUDService } from '../../../types/cached-c-r-u-d.service';
import { ServerError, ServerResponse } from '../../../types/http.interfaces';
import { environment } from '../../../../../environments/environment';
import {
  AvailableLoginRetrieved,
  RetrieveLogin,
  SearchResult,
} from '../../../types/actor/actor.model.entity';
import { RoleTabService } from '../roles/role-tab.service';
import { Role } from '../../roles-and-permissions/role.service';

export interface UserTabData {
  modalTitle: string;
  selectedRoles: Role[]; //Roles assigned to current user
  roles: Role[]; //list of all roles
  formActionTouched: boolean;
  formAction: FormGroup;
  selectedProfile?: Operator;
  selectedService: boolean;
  isEditing: boolean;
}

interface UserOpenTabOptions {
  id: number;
}

class UserServiceTabManager extends ParametricService<UserTabData, UserOpenTabOptions> {
  constructor(
    private http: HttpClient,
    private dialogService: DialogService,
    private validatorsService: ValidatorsService,
    private router: Router,
    public override profileService: ProfileService,
    keyPressedHandlerService: KeyPressedHandlerService,
    private userService: UserService,
    private rolesService: RoleTabService
  ) {
    super(keyPressedHandlerService, profileService);
    super.newTabAction = {
      icon: '',
      toolTip: 'Agregar Usuario',
    };
  }

  override isSafeToCloseTab(id: number): boolean {
    const tab = this.getTabData(id);
    if (tab) {
      // validate if is safe to close
      if (tab.tabData.formAction.dirty) {
        return false;
      }
    }
    return true;
  }

  /**
   * @param id
   */
  override async selectTab(id: number) {
    if (this.selectedTab && this.selectedTab.id === id) return;
    super.selectTab(id);
    await this.router
      .navigateByUrl('/home/administration/users', {
        skipLocationChange: true,
      })
      .then(() => {
        if (this._selectedTab?.actionType === TabActionType.CREATE) {
          this.router.navigateByUrl('/home/administration/users/create/' + id);
        } else if (this._selectedTab?.actionType === TabActionType.EDIT) {
          this.router.navigateByUrl(
            '/home/administration/users/edit/' + this._selectedTab.tabData.selectedProfile?.id
          );
        }
      });
  }

  /**
   *
   * @param actionType action to be performed register or edit
   * @param options id record
   */
  override async newTab(actionType: TabActionType, options?: UserOpenTabOptions) {
    let text = '';
    // validate if already open
    if (options?.id) {
      const result = this.tabs.find((value) => value.tabData.selectedProfile?.id === options.id);
      if (result) {
        await this.selectTab(result.id);
        return;
      }
    }
    //
    const user = options?.id ? await this.userService.getById(options.id) : undefined;
    const tabId = ++this._tabIdCounter;
    if (actionType === TabActionType.CREATE) {
      text = `NUEVO USUARIO (${tabId})`;
    } else if (actionType === TabActionType.EDIT) {
      text = 'EDITAR USUARIO ' + user?.id;
      const userIsBeingUpdated = this._tabs.find(
        (tab) => tab.tabData.selectedProfile?.id === user?.id
      );
      if (userIsBeingUpdated) {
        await this.dialogService.infoModal(
          'EDITAR USUARIO',
          'El usuario seleccionado ya está siendo editado'
        );
        await this.selectTab(userIsBeingUpdated.id);
        return;
      }
    }
    const roles = await this.rolesService.roleService.getAll();

    let selectedRoles: Role[] = [];

    try {
      if (user && user.keycloakUserId) {
        selectedRoles = await this.rolesService.roleService.getProfileRoles(user.keycloakUserId);
      }
    } catch (error) {
      console.warn(error);
    }

    const tabData: TabData<UserTabData> = {
      id: tabId,
      edition: false,
      createdDate: [],
      creationInfoExpanded: false,
      createdBy: '',
      createdByIp: '',
      tabData: {
        selectedRoles: selectedRoles,
        roles: roles,
        isEditing: false,
        formActionTouched: false,
        formAction: new FormGroup({}),
        selectedProfile: user,
        selectedService: false,
        modalTitle: actionType === TabActionType.CREATE ? 'CREAR' : 'EDITAR',
      },
      actionType: actionType,
      title: text,
      keysSubscriptionName: '',
    };
    this.generateForms(tabData);

    this._tabs.push(tabData);
    await this.selectTab(tabData.id);
  }

  override getTabData(id: number, userId?: number): TabData<UserTabData> {
    if (userId) {
      return (
        this._tabs.find((value) => value.tabData.selectedProfile?.id === userId) ?? this._tabs[0]
      );
    }
    return this._tabs.find((value) => value.id === id) ?? this._tabs[0];
  }

  /**
   * GENERATES USER FORM
   **/
  private generateForms(tabData: TabData<UserTabData>) {
    tabData.tabData.formAction = new FormGroup({
      documentType: new FormControl('', Validators.required),
      documentNumber: new FormControl('', [
        Validators.required,
        Validators.maxLength(10),
        Validators.pattern(this.validatorsService.numberValidator),
      ]),
      firstName: new FormControl('', [
        Validators.required,
        Validators.pattern(this.validatorsService.alphabeticWithAccentsValidator),
      ]),
      secondName: new FormControl(
        '',
        Validators.pattern(this.validatorsService.alphabeticWithAccentsValidator)
      ),
      lastName: new FormControl('', [
        Validators.required,
        Validators.pattern(this.validatorsService.alphabeticWithAccentsValidator),
      ]),
      secondLastName: new FormControl(
        '',
        Validators.pattern(this.validatorsService.alphabeticWithAccentsValidator)
      ),
      gender: new FormControl('', Validators.required),
      birthDate: new FormControl(''),
      email: new FormControl('', [
        Validators.required,
        Validators.pattern(this.validatorsService.emailValidator),
      ]),
      phoneNumber: new FormControl('', [
        Validators.required,
        Validators.maxLength(10),
        Validators.pattern(this.validatorsService.numberValidator),
      ]),
      loginInfo: new FormControl({ value: '', disabled: true }),
      profile: new FormControl(''),
      provider: new FormControl(''),
      hospital: new FormControl(''),
    });
    tabData.tabData.selectedService = false;
  }
}

@Injectable({
  providedIn: 'root',
})
export class UserService extends CachedCRUDService<
  Operator,
  OperatorCreateInput['data'],
  OperatorUpdateInput['data']
> {
  tabs: UserServiceTabManager;

  constructor(
    private http: HttpClient,
    private dialogService: DialogService,
    private validatorsService: ValidatorsService,
    private router: Router,
    private profileService: ProfileService,
    keyPressedHandlerService: KeyPressedHandlerService,
    private roleService: RoleTabService
  ) {
    super();
    this.tabs = new UserServiceTabManager(
      http,
      dialogService,
      validatorsService,
      router,
      profileService,
      keyPressedHandlerService,
      this,
      roleService
    );
  }

  protected override async _postData(body: OperatorCreateInput['data']): Promise<Operator> {
    const res = await lastValueFrom(
      this.http.post<ServerResponse<Operator>>(
        `${environment.api}/actors-ms/api/v1/actors/operators`,
        body
      )
    );
    return res.data;
  }

  protected override async _fetchNewData(): Promise<Operator[]> {
    const param = new HttpParams().set('fetchContactPoints', true);
    const response = await lastValueFrom(
      this.http.get<ServerResponse<SearchResult<Operator[]>>>(
        `${environment.api}/actors-ms/api/v1/actors/operators`,
        { params: param }
      )
    );
    return response.data.result;
  }

  public async validateAvailableUsername(
    data: string,
    actorId?: number
  ): Promise<AvailableLoginRetrieved> {
    const body: RetrieveLogin = {
      baseLogin: data,
      ignoreActorId: actorId,
    };
    try {
      const response = await lastValueFrom(
        this.http.post<ServerResponse<AvailableLoginRetrieved>>(
          `${environment.api}/actors-ms/api/v1/actors/operators/retrieveAvailableUsername`,
          body
        )
      );
      return response.data;
    } catch (error: unknown) {
      const e: ServerError = error as ServerError;
      throw e.error.data;
    }
  }

  public async fetchKeycloakUsersData(): Promise<KeycloakUserRoleData[]> {
    const data = await lastValueFrom(
      this.http.get<KeycloakUserRoleData[]>(
        `${environment.authService}/realms/${environment.keycloakRealm}/userManagement`
      )
    );
    return data;
  }

  protected override async _getByIdData(id: number): Promise<Operator | undefined> {
    const param = new HttpParams().set('fetchContactPoints', true);
    const response = await lastValueFrom(
      this.http.get<ServerResponse<Operator>>(
        `${environment.api}/actors-ms/api/v1/actors/operators/${id}`,
        {
          params: param,
        }
      )
    );
    return response.data;
  }

  public async changeUserStatus(userId: number, body: { reason: string }): Promise<void> {
    await lastValueFrom(
      this.http.post<ServerResponse<Operator>>(
        `${environment.api}/actors-ms/api/v1/actors/operators/${userId}/activateDeactivate`,
        body
      )
    );
    await this.getById(userId, true);
  }

  protected override async _putData(
    userId: number,
    body: OperatorUpdateInput['data']
  ): Promise<Operator> {
    await lastValueFrom(
      this.http.patch<ServerResponse<Operator>>(
        `${environment.api}/actors-ms/api/v1/actors/operators/${userId}`,
        body
      )
    );
    const fixedRes = await this.getById(userId, true);
    if (!fixedRes) {
      throw new Error('Could not fetch updated data, strange case, this should not happen');
    }
    return fixedRes;
  }

  public getNameFromOperator(actor: Operator) {
    const output = [];
    if (actor.firstName) {
      output.push(ProfileService.validateName(actor.firstName.value));
    }
    if (actor.secondName) {
      output.push(ProfileService.validateName(actor.secondName.value));
    }
    if (actor.lastName) {
      output.push(ProfileService.validateName(actor.lastName.value));
    }
    if (actor.secondLastName) {
      output.push(ProfileService.validateName(actor.secondLastName.value));
    }
    return output.join(' ');
  }

  /**
   * return possible available user name for user, not real
   * @param actorInfo
   */
  public async getCodeFromActor(actorInfo: Operator) {
    try {
      const names = [];
      if (actorInfo.firstName.value) {
        names.push(ProfileService.validateName(actorInfo.firstName.value).charAt(0));
      }
      if (actorInfo.secondName.value) {
        names.push(ProfileService.validateName(actorInfo.secondName.value).charAt(0));
      }
      return (names.join('') + actorInfo.lastName.value).toUpperCase();
    } catch (error: unknown) {
      const e: ServerError = error as ServerError;
      throw e.message;
    }
  }

  /**
   * performs password reset flow
   * @param id
   */
  public async resetPassword(id: number) {
    await lastValueFrom(
      this.http.post(
        `${environment.api}/actors-ms/api/v1/actors/operators/${id}/resetPassword`,
        undefined
      )
    );
  }
}

export interface KeycloakUserRoleData {
  firstName: string;
  lastName: string;
  roles: string[];
  id: string;
  email: string;
  username: string;
}
