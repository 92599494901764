import { Component, Inject, OnInit } from '@angular/core';
import {
  Interoperability,
  InteroperabilityService,
  PatientValidator,
} from '../../services/administration/interoperability/interoperability.service';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { LoadingService } from '../../services/loading.service';
import { Validator } from '@smartsoft-types/sisem-incidents';
import moment from 'moment';
import { IncidentsPaymentResponsibleService } from '../../services/incidents/incidents-sub-resources/incidents.payment.responsible.service';
import { IncidentsPaymentResponsibleTypeService } from '../../services/incidents/incidents-sub-resources/incidents.payment.responsible.type.service';
import { DialogService } from '../../services/dialog.service';

@Component({
  selector: 'app-patient-interoperability',
  templateUrl: './patient-interoperability.component.html',
  styleUrls: ['./patient-interoperability.component.scss'],
})
export class PatientInteroperabilityComponent implements OnInit {
  activeInteroperability: {
    selector: 'adres' | 'rightsChecker';
    title: string;
    mobileTitle: string;
  }[] = [];

  selectedValidator: 'adres' | 'rightsChecker';

  adresValidatorField: { title: string; info: string }[] = [];

  checkerValidatorField: { title: string; info: string }[] = [];

  checkerValidatorTimeOut: boolean = false;

  adresValidatorTimeOut: boolean = false;

  interoperabilityData: Interoperability;

  isLoading: boolean = false;

  readonly adresTranslatedKeys: { [key: string]: string } = {
    tipoDocumento: 'Tipo documento',
    numDocumento: 'Número de Documento',
    primerNombre: 'Primer Nombre',
    segundoNombre: 'Segundo Nombre',
    primerApellido: 'Primer Apellido',
    segundoApellido: 'Segundo Apellido',
    fechaNacimiento: 'Fecha Nacimiento',
    estadoAfiliacion: 'Estado Afiliación',
    estadoFecha: 'Estado Fecha',
    tipoAfiliado: 'Tipo Afiliado',
    fechaAfiliacionEntidad: 'Fecha Afiliación Entidad',
    nomDepartamento: 'Departamento',
    nomMunicipio: 'Municipio',
    serial: 'Serial',
    fechaAfiliacionEfectiva: 'Fecha Afiliación Efectiva',
    fechaFinalAfiliacion: 'Fecha Final Afiliación',
    cantidadGrpFml: 'Cantidad Grupo Familiar',
    codEps: 'Código EPS',
    epsNameApi: 'Nombre EPS',
    nomTipoRegimenSalud: 'Nombre Tipo Régimen Salud',
  };

  checkerTranslatedKeys: { [key: string]: string } = {
    Celular: 'Celular',
    CodigoAseguradora: 'Código Aseguradora',
    CodigoDepartamento: 'Código Departamento',
    CodigoEPS: 'Código EPS',
    CodigoLocalidad: 'Código Localidad',
    CodigoMunicipio: 'Código Municipio',
    CorreoElectronico: 'Correo Electrónico',
    Departamento: 'Departamento',
    DescripcionPoblacion: 'Descripción Población',
    Direccion: 'Dirección',
    FechaAfiliacionSisben: 'Fecha Afiliación SISBÉN',
    FechaEncuestaSisben: 'Fecha Encuesta SISBÉN',
    FechaNacimiento: 'Fecha Nacimiento',
    FichaSisben: 'Ficha SISBÉN',
    Localidad: 'Localidad',
    Municipio: 'Municipio',
    NivelSisben: 'Nivel SISBÉN',
    NombreAseguradora: 'Nombre Aseguradora',
    NombreEPS: 'Nombre EPS',
    NucleoSisben: 'Núcleo SISBÉN',
    PrimerApellido: 'Primer Apellido',
    PrimerNombre: 'Primer Nombre',
    PuntajeSisben: 'Puntaje SISBÉN',
    SegundoApellido: 'Segundo Apellido',
    SegundoNombre: 'Segundo Nombre',
    Sexo: 'Sexo',
    Telefono: 'Teléfono',
    TipoPoblacion: 'Tipo Población',
    'regimenSisben.CodigoLocalidad': 'Código Localidad',
    'regimenSisben.Direccion': 'Dirección',
    'regimenSisben.FechaEncuestaSisben': 'Fecha Encuesta SISBÉN',
    'regimenSisben.FichaSisben': 'Ficha SISBÉN',
    'regimenSisben.Localidad': 'Localidad',
    'regimenSisben.NivelSisben': 'Nivel SISBÉN',
    'regimenSisben.NucleoSisben': 'Núcleo SISBÉN',
    'regimenSisben.PuntajeSisben': 'Puntaje SISBÉN',
    'regimenSisben.Sexo': 'Sexo',
    'regimenSisben.Telefono': 'Teléfono',
    'regimenSubsidiado.CodigoDepartamento': 'Código Departamento',
    'regimenSubsidiado.CodigoMunicipio': 'Código Municipio',
    'regimenSubsidiado.Departamento': 'Departamento',
    'regimenSubsidiado.Direccion': 'Dirección',
    'regimenSubsidiado.Municipio': 'Municipio',
    NumeroIdentificacion: 'Número Identificación',
    TipoIdentificacion: 'Tipo Identificación',
  };

  adresOrderedKey: string[] = [
    'tipoDocumento',
    'numDocumento',
    'primerNombre',
    'segundoNombre',
    'primerApellido',
    'segundoApellido',
    'fechaNacimiento',
    'estadoAfiliacion',
    'estadoFecha',
    'tipoAfiliado',
    'fechaAfiliacionEntidad',
    'nomDepartamento',
    'nomMunicipio',
    'serial',
    'fechaAfiliacionEfectiva',
    'fechaFinalAfiliacion',
    'cantidadGrpFml',
    'codEps',
    'epsNameApi',
    'nomTipoRegimenSalud',
  ];

  adresDates = [
    'fechaNacimiento',
    'fechaAfiliacionEntidad',
    'fechaAfiliacionEfectiva',
    'fechaFinalAfiliacion',
  ];

  checkerOrderedKeys: string[] = [
    'TipoIdentificacion',
    'NumeroIdentificacion',
    'PrimerNombre',
    'SegundoNombre',
    'PrimerApellido',
    'SegundoApellido',
    'FechaNacimiento',
    'Sexo',
    'TipoPoblacion',
    'DescripcionPoblacion',
    'Direccion',

    'CodigoEPS',
    'NombreEPS',
    'CodigoDepartamento',
    'Departamento',
    'CodigoMunicipio',
    'Municipio',

    'FichaSisben',
    'NivelSisben',
    'NucleoSisben',
    'PuntajeSisben',
    'FechaAfiliacionSisben',
    'CodigoAseguradora',
    'NombreAseguradora',
    'regimenSubsidiado.CodigoDepartamento',
    'regimenSubsidiado.Departamento',
    'regimenSubsidiado.CodigoMunicipio',
    'regimenSubsidiado.Municipio',
    'Localidad',
    'regimenSubsidiado.Direccion',
    'CorreoElectronico',
    'Celular',
    'Telefono',

    'regimenSisben.FichaSisben',
    'regimenSisben.NivelSisben',
    'regimenSisben.NucleoSisben',
    'regimenSisben.PuntajeSisben',
    'regimenSisben.FechaEncuestaSisben',
    'regimenSisben.CodigoLocalidad',
    'regimenSisben.Localidad',
    'regimenSisben.Direccion',
    'regimenSisben.Telefono',
    'regimenSisben.Sexo',
  ];

  readonly checkerDates = [
    'FechaNacimiento',
    'FechaAfiliacionSisben',
    'FechaEncuestaSisben',
    'regimenSisben.FechaEncuestaSisben',
  ];

  timeOut: number;

  constructor(
    private interoperabilityService: InteroperabilityService,
    public dialogRef: MatDialogRef<PatientInteroperabilityComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogData: PatientInteroperabilityData,
    private loadingService: LoadingService,
    private paymentResponsibleService: IncidentsPaymentResponsibleService,
    private paymentResponsibleType: IncidentsPaymentResponsibleTypeService,
    private dialog: MatDialog,
    private dialogService: DialogService
  ) {}

  async ngOnInit() {
    this.isLoading = true;
    this.dialogRef.disableClose = true;
    await this.fetchData();
    await this.getInteroperabilityData();
    this.isLoading = false;
  }

  async fetchData() {
    try {
      const resp = await this.interoperabilityService.getInteroperabilityData();

      this.timeOut = resp[0].timeOut;

      if (resp.find((validator) => validator.code === '2')?.active === true) {
        this.activeInteroperability.push({
          selector: 'rightsChecker',
          title: 'COMPROBADOR DE DERECHOS',
          mobileTitle: 'Comprobador de derechos',
        });
      }

      if (resp.find((validator) => validator.code.toLowerCase() === '1')?.active === true) {
        this.activeInteroperability.push({
          selector: 'adres',
          title: 'ADRES',
          mobileTitle: 'ADRES',
        });
      }
      this.checkFirstValidator(resp);
    } catch (e) {
      console.error(e);
    }
  }

  private checkFirstValidator(data: Validator[]) {
    data.sort((a, b) => a.order - b.order);
    for (const validator of data) {
      if (validator.active) {
        if (validator.code === '1') {
          this.selectedValidator = 'adres';
        } else if (validator.code === '2') {
          this.selectedValidator = 'rightsChecker';
        }
        break;
      }
    }
  }

  async getInteroperabilityData() {
    let noDataAdres = false;
    let notDataChecker = false;
    this.adresValidatorTimeOut = false;
    this.checkerValidatorTimeOut = false;
    try {
      this.interoperabilityData = await this.interoperabilityService.fetchAll(
        this.dialogData.idNumber,
        this.dialogData.documentType
      );

      const dataAdres = this.interoperabilityData.validators.find(
        (val) => val.validator === 'Adres'
      );
      if (dataAdres) {
        if (dataAdres.timeoutExceeded) {
          this.adresValidatorTimeOut = true;
        }
        if (Object.keys(dataAdres.filteredFields).length === 0) {
          noDataAdres = true;
          this.activeInteroperability.splice(
            this.activeInteroperability.findIndex((val) => val.selector === 'adres'),
            1
          );
        } else {
          this.adresValidatorField = await this.transformData(dataAdres, 'adres');
        }
      }

      const dataValidator = this.interoperabilityData.validators.find(
        (val) => val.validator === 'Rights validator'
      );
      if (dataValidator) {
        if (dataValidator.timeoutExceeded) {
          this.checkerValidatorTimeOut = true;
        }
        if (Object.keys(dataValidator.filteredFields).length === 0) {
          notDataChecker = true;
          this.activeInteroperability.splice(
            this.activeInteroperability.findIndex((val) => val.selector === 'rightsChecker'),
            1
          );
        } else {
          this.checkerValidatorField = await this.transformData(dataValidator, 'Rights validator');
        }
      }

      let showNotFoundDocument = !(this.adresValidatorTimeOut || this.checkerValidatorTimeOut);
      if (noDataAdres === false && dataAdres) showNotFoundDocument = false;
      if (notDataChecker === false && dataValidator) showNotFoundDocument = false;

      if (showNotFoundDocument) {
        this.isLoading = false;
        await this.dialogService.informativeResultModal(
          'El documento del paciente no se encuentra en el Comprobador de derechos de la Secretaría Distrital de Salud ni en el comprobador ADRES',
          'error',
          {
            width: window.innerWidth < 1024 ? '100%' : '',
            maxWidth: window.innerWidth >= 1024 ? '42vw' : 'unset',
          }
        );
      }

      if (this.adresValidatorTimeOut && this.checkerValidatorTimeOut) {
        this.isLoading = false;
        await this.dialogService.informativeResultModal(
          `El tiempo de respuesta de los comprobadores de datos se ha excedido (${this.timeOut} segundos), por favor diríjase a la página oficial del comprobador y consulte la información del paciente desde allí.`,
          'error',
          {
            width: window.innerWidth < 1024 ? '100%' : '',
            maxWidth: window.innerWidth < 1024 ? '100%' : '80%',
          }
        );
      } else if (this.adresValidatorTimeOut) {
        this.isLoading = false;
        await this.dialogService.informativeResultModal(
          `El tiempo de respuesta de ADRES se ha excedido (${this.timeOut} segundos), por favor diríjase a la página oficial del comprobador y consulte la información del paciente desde allí.`,
          'error',
          {
            width: window.innerWidth < 1024 ? '100%' : '',
            maxWidth: window.innerWidth < 1024 ? '100%' : '80%',
          }
        );
      } else if (this.checkerValidatorTimeOut) {
        this.isLoading = false;
        await this.dialogService.informativeResultModal(
          `El tiempo de respuesta del comprobador de derechos se ha excedido (${this.timeOut} segundos), por favor diríjase a la página oficial del comprobador y consulte la información del paciente desde allí.`,
          'error',
          {
            width: window.innerWidth < 1024 ? '100%' : '',
            maxWidth: window.innerWidth < 1024 ? '100%' : '80%',
          }
        );
      }
      if (
        !this.activeInteroperability.find((val) => val.selector === this.selectedValidator) &&
        this.activeInteroperability.length > 0
      ) {
        this.selectedValidator = this.activeInteroperability[0].selector;
      }
    } catch (e) {
      console.error('Sucedió un error inesperado', e);
    }
  }

  close() {
    this.dialogRef.close('not-data');
  }

  changeSelectedValidator(selector: 'adres' | 'rightsChecker') {
    if (this.selectedValidator !== selector) {
      this.selectedValidator = selector;
    }
  }

  async transformData(data: PatientValidator, validator: string) {
    const keys = Object.keys(data.filteredFields);
    let auxData: { title: string; info: string }[] = [];
    if (keys.length === 0) {
      return auxData;
    }

    const orderedKeys = validator === 'adres' ? this.adresOrderedKey : this.checkerOrderedKeys;

    const interoperabilityOrderedKeys: string[] = [];

    orderedKeys.forEach((key) => {
      if (keys.includes(key)) {
        interoperabilityOrderedKeys.push(key);
      }
    });

    let isNameAdded = false;

    let genderAdded: boolean = false;

    const namesFilter = [
      'primerNombre',
      'PrimerNombre',
      'segundoNombre',
      'SegundoNombre',
      'primerApellido',
      'PrimerApellido',
      'segundoApellido',
      'SegundoApellido',
    ];

    interoperabilityOrderedKeys.forEach((key) => {
      if (namesFilter.includes(key)) {
        if (!isNameAdded) {
          const firstName =
            data.filteredFields['primerNombre'] || data.filteredFields['PrimerNombre'];
          const secondName =
            data.filteredFields['segundoNombre'] || data.filteredFields['SegundoNombre'];
          const firstLastName =
            data.filteredFields['primerApellido'] || data.filteredFields['PrimerApellido'];
          const secondLastName =
            data.filteredFields['segundoApellido'] || data.filteredFields['SegundoApellido'];
          const name = `${firstName} ${secondName ? secondName + ' ' : ''}${
            firstLastName ? firstLastName + ' ' : ''
          }${secondLastName ? secondLastName : ''}`;
          auxData.push({
            title: 'Nombres y apellidos',
            info: name.toLowerCase() === 'undefined' ? 'N/A' : name,
          });
          isNameAdded = true;
        }
      } else if (validator !== 'adres' && this.checkerDates.includes(key)) {
        auxData.push({
          title: this.checkerTranslatedKeys[key],
          info:
            data.filteredFields[key] !== 'N/A'
              ? moment(data.filteredFields[key]).format('DD/MM/YYYY')
              : 'N/A',
        });
      } else if (validator === 'adres' && this.adresDates.includes(key)) {
        auxData.push({
          title: this.adresTranslatedKeys[key],
          info:
            data.filteredFields[key] !== null && data.filteredFields[key] !== undefined
              ? moment(data.filteredFields[key]).format('DD/MM/YYYY')
              : 'N/A',
        });
      } else if (`${key}` === 'Sexo' && !genderAdded) {
        auxData.push({
          title: 'Sexo',
          info:
            data.filteredFields[key] !== 'N/A'
              ? data.filteredFields[key] === 'M'
                ? 'Masculino'
                : 'Femenino'
              : 'N/A',
        });
        genderAdded = true;
      } else if (`${key}` === 'regimenSisben.Sexo') {
        auxData.push({
          title: 'Sexo',
          info:
            data.filteredFields[key] !== 'N/A'
              ? data.filteredFields[key] === 'M'
                ? 'Masculino'
                : 'Femenino'
              : 'N/A',
        });
      } else {
        auxData.push({
          title:
            validator === 'adres' ? this.adresTranslatedKeys[key] : this.checkerTranslatedKeys[key],
          info:
            data.filteredFields[key] || typeof data.filteredFields[key] === 'number'
              ? `${data.filteredFields[key]}`
              : 'N/A',
        });
      }
    });

    if (keys.includes('paymentResponsibleTypeId') && keys.includes('paymentResponsibleId')) {
      const paymentResponsibleType = await this.paymentResponsibleType.getAll();
      const paymentResponsible = await this.paymentResponsibleService.getAll();

      const type = paymentResponsibleType.find(
        (type) => type.id === parseInt(data.filteredFields['paymentResponsibleTypeId'] as string)
      );

      if (type) {
        const responsible = paymentResponsible.find(
          (payer) => payer.id === parseInt(data.filteredFields['paymentResponsibleId'] as string)
        );

        if (responsible) {
          auxData.push({
            title: 'Pagador',
            info: `${responsible.title}`,
          });
        }
      }
    }

    auxData = [...new Set(auxData)];

    return auxData;
  }

  save() {
    if (this.selectedValidator === 'adres' && this.adresValidatorField.length > 0) {
      this.dialogRef.close({
        ...this.interoperabilityData.validators.find((val) => val.validator === 'Adres'),
        activeInteroperability: this.activeInteroperability,
      });
    } else if (
      this.selectedValidator === 'rightsChecker' &&
      this.checkerValidatorField.length > 0
    ) {
      this.dialogRef.close({
        ...this.interoperabilityData.validators.find((val) => val.validator === 'Rights validator'),
        activeInteroperability: this.activeInteroperability,
      });
    } else {
      this.dialogRef.close('not-data');
    }
  }

  validateClass(selector: 'adres' | 'rightsChecker', index: number) {
    let classes = '';
    if (this.selectedValidator === selector) {
      classes = 'active-bar';
    }

    if (index === 0) {
      classes = `${classes} negative-left`;
    }

    return classes;
  }
}

export interface PatientInteroperabilityData {
  idNumber: string;
  documentType: string;
  modalTitle: string;
  icon: string;
}
