type Await<T> = T extends Promise<infer U> ? U : T;
type AwaitProps<T> = { [P in keyof T]: Await<T[P]> };

/**
 * Receives an object with promise containing properties and returns a promise that resolves to an object
 * with the same properties containing the resolved values
 * @param  {PromisesMap<T>} promisesMap  the input object with a promise in each property
 * @return {Promise<T>}  a promise that resolved to an object with the same properties containing the resolved values
 */
export const PromisedProperties = <T>(promisesMap: T): Promise<AwaitProps<T>> => {
  const promisedProperties: any[] = [];
  const objectKeys = Object.keys(promisesMap);

  objectKeys.forEach((key: string) => promisedProperties.push((<any>promisesMap)[key]));

  return Promise.all(promisedProperties).then((resolvedValues) => {
    return resolvedValues.reduce((resolvedObject, property, index) => {
      resolvedObject[objectKeys[index]] = property;
      return resolvedObject;
    }, promisesMap);
  });
};
