import { EventEmitter, Injectable } from '@angular/core';
import { Sort } from '@angular/material/sort';
import { FormControl, FormGroup } from '@angular/forms';
import { PageEvent } from '@angular/material/paginator';
import { HttpParams } from '@angular/common/http';
import { IncidentsMapService } from './incidents-map/incidents-map.service';

@Injectable({
  providedIn: 'root',
})
export class IncidentsPaginationService {
  itemsPerPage = 12;
  offset = 12;
  sortBy: IncidentOrderOptions = IncidentOrderOptions.CREATED_AT;
  sortMethod: 'asc' | 'desc' = 'desc';

  mainFilter: FilterTabs;

  filters: FilterTabs[] = [];

  selectedFilter: 'main' | number = 'main';

  selectedForm: FormGroup;

  formHasCircumstance: boolean;

  changePage: EventEmitter<any> = new EventEmitter<any>();

  showFiltersForm: boolean = false;

  private incidentOrderOptionsMap = {
    hourAndDate: IncidentOrderOptions.CREATED_AT,
    incidentNumber: IncidentOrderOptions.CODE,
    incidentType: IncidentOrderOptions.INCIDENT_TYPE,
    locality: IncidentOrderOptions.LOCALITY_ID,
    incidentIcon: IncidentOrderOptions.PRIORITY,
    incidentAddress: IncidentOrderOptions.ADDRESS,
    neighborhood: IncidentOrderOptions.DISTRICT,
  };

  constructor(private incidentsMapService: IncidentsMapService) {
    this.mainFilter = this.initializeFilterTab(true);
    this.selectedForm = this.initializeForm();
    this.formHasCircumstance = false;
  }

  handleChangePage(event: PageEvent) {
    if (this.selectedFilter === 'main' || !this.filters[this.selectedFilter].isFiltered) {
      this.mainFilter.currentPage = event.pageIndex;
      this.mainFilter.offset = event.pageIndex * this.itemsPerPage;
    } else {
      this.filters[this.selectedFilter].currentPage = event.pageIndex;
      this.filters[this.selectedFilter].offset = event.pageIndex * this.itemsPerPage;
    }
    this.changePage.emit();
  }

  changeSort(event: Sort) {
    const sortBy =
      this.incidentOrderOptionsMap[
        <
          | 'hourAndDate'
          | 'locality'
          | 'neighborhood'
          | 'incidentAddress'
          | 'incidentNumber'
          | 'incidentType'
          | 'incidentIcon'
        >event.active
      ];
    if (sortBy) {
      const sortMethod = event.direction === '' ? 'desc' : event.direction;
      if (this.selectedFilter === 'main') {
        this.mainFilter.sortMethod = sortMethod;
        this.mainFilter.sortBy = sortBy;
      } else {
        this.filters[this.selectedFilter].sortMethod = sortMethod;
        this.filters[this.selectedFilter].sortBy = sortBy;
      }
      this.changePage.emit();
    }
  }

  removeFilter(index: number | 'main') {
    if (index !== 'main') {
      this.filters.splice(<number>index, 1);
      this.selectedForm = this.mainFilter.filterForm;
      this.selectedFilter = 'main';
      this.selectedForm = this.initializeForm();
      this.incidentsMapService.clearFilterParams();
    } else {
      this.selectedForm = this.initializeForm();
    }
    this.formHasCircumstance = false;
    this.changePage.emit();
  }

  addFilter(disabledAction: boolean = false) {
    if (disabledAction) return;

    if (this.filters.length < 3) {
      this.filters.push(this.initializeFilterTab(false, false));
      this.formHasCircumstance = false;
      this.selectedFilter = this.filters.length - 1;
      this.selectedForm = this.initializeForm();
      this.filters[this.filters.length - 1].totalItems = this.mainFilter.totalItems;
    }
  }

  filter() {
    if (this.selectedFilter === 'main' && this.filters.length < 3) {
      const formValues = this.selectedForm.value;
      const newFilter: FilterTabs = {
        offset: 0,
        isFiltered: true,
        totalItems: 0,
        sortMethod: 'desc',
        sortBy: IncidentOrderOptions.CREATED_AT,
        currentPage: 0,
        title: 'Búsqueda',
        totalPages: 0,
        id: this.filters.length + 1,
        filterForm: this.initializeForm(formValues),
        hasCircumstance: this.formHasCircumstance,
      };
      this.filters.push(newFilter);
      this.selectedFilter = this.filters.length - 1;
      this.filters[this.selectedFilter].title = this.getTabTitle();
    } else {
      const formValues = this.selectedForm.value;
      this.filters[<number>this.selectedFilter].filterForm = this.initializeForm(formValues);
      this.filters[<number>this.selectedFilter].hasCircumstance = this.formHasCircumstance;
      this.filters[<number>this.selectedFilter].title = this.getTabTitle();
      this.filters[<number>this.selectedFilter].isFiltered = true;
      this.filters[<number>this.selectedFilter].offset = 0;
      this.filters[<number>this.selectedFilter].currentPage = 0;
    }
    this.changePage.emit();
    this.showFiltersForm = false;
  }

  getTabTitle(): string {
    const filterForm = this.filters[<number>this.selectedFilter].filterForm;

    let title = '';
    let counter = 0;

    if (filterForm.get('state')?.value && filterForm.get('state')?.value !== '') {
      title = 'ESTADO';
      counter++;
    }

    if (filterForm.get('closingCode')?.value && filterForm.get('closingCode')?.value !== '') {
      title = 'Código de cierre';
      counter++;
    }

    if (filterForm.get('incidentType')?.value && filterForm.get('incidentType')?.value !== '') {
      title = 'TIPO INCIDENTE';
      counter++;
    }

    if (filterForm.get('circumstance')?.value && filterForm.get('circumstance')?.value !== '') {
      title = 'CIRCUNSTANCIA MODIFICADORA';
      counter++;
    }

    if (filterForm.get('priority')?.value && filterForm.get('priority')?.value !== '') {
      title = 'PRIORIDAD';
      counter++;
    }
    if (filterForm.get('neighborhood')?.value && filterForm.get('neighborhood')?.value !== '') {
      title = 'BARRIO';
      counter++;
    }
    if (filterForm.get('locality')?.value && filterForm.get('locality')?.value !== '') {
      title = 'LOCALIDAD';
      counter++;
    }
    if (filterForm.get('since')?.value && filterForm.get('since')?.value !== '') {
      title = 'FECHA';
      counter++;
    }
    if (filterForm.get('until')?.value && filterForm.get('until')?.value !== '') {
      title = 'FECHA';
      counter++;
    }

    if (counter === 0) {
      title = 'BÚSQUEDA';
    } else if (counter > 1) {
      title = 'MÚLTIPLE';
    }
    return title;
  }

  changeFilterTab(id: 'main' | number) {
    if (id === this.selectedFilter) return;
    this.saveFilterValues(
      this.selectedForm.value,
      this.selectedFilter !== 'main' ? <number>this.selectedFilter : -1
    );
    if (id === 'main') {
      this.selectedForm = this.initializeForm();
      this.incidentsMapService.clearFilterParams();
      this.formHasCircumstance = false;
    } else {
      this.selectedForm = this.initializeForm(this.filters[id].filterForm.value);
      this.formHasCircumstance = this.filters[id].hasCircumstance;
    }
    this.selectedFilter = id;
    this.changePage.emit();
  }

  private saveFilterValues(formValues: FilterFormValue, index: number) {
    if (index >= 0) {
      this.filters[index].filterForm = this.initializeForm(formValues);
      this.filters[index].hasCircumstance = this.formHasCircumstance;
    }
  }

  initializeForm(form?: FilterFormValue) {
    return new FormGroup({
      state: new FormControl(form?.state ?? ''),
      closingCode: new FormControl(form?.closingCode ?? ''),
      priority: new FormControl(form?.priority ?? ''),
      since: new FormControl(form?.since ?? ''),
      until: new FormControl(form?.until ?? ''),
      locality: new FormControl(form?.locality ?? ''),
      zoneId: new FormControl(form?.zoneId ?? ''),
      neighborhood: new FormControl(form?.neighborhood ?? ''),
      incidentType: new FormControl(form?.incidentType ?? ''),
      circumstance: new FormControl(form?.circumstance ?? ''),
    });
  }

  private initializeFilterTab(isMain: boolean = false, isFiltered: boolean = false): FilterTabs {
    return {
      id: isMain ? 'main' : this.filters.length + 1,
      currentPage: 0,
      sortMethod: 'desc',
      filterForm: this.initializeForm(),
      totalPages: 0,
      offset: 0,
      sortBy: IncidentOrderOptions.CREATED_AT,
      title: 'BÚSQUEDA',
      isFiltered: isFiltered,
      hasCircumstance: false,
      totalItems: 0,
    };
  }

  getParamsValue(params: HttpParams, updateMapParams: boolean = false): HttpParams {
    const formValues = this.filters[<number>this.selectedFilter].filterForm.value;

    if (formValues['state'] && formValues['state'] !== '') {
      params = params.set('statusId', formValues['state']);
      if (updateMapParams) {
        this.incidentsMapService.setFilterParam({ incidentStatus: [String(formValues['state'])] });
      }
    }
    if (formValues['closingCode'] && formValues['closingCode'] !== '') {
      params = params.set('closingCodeId', formValues['closingCode']);
    }
    if (formValues['priority'] && formValues['priority'] !== '') {
      params = params.set('priorityId', formValues['priority']);
      if (updateMapParams) {
        this.incidentsMapService.setFilterParam({ priority: [String(formValues['priority'])] });
      }
    }
    if (formValues['since'] && formValues['since'] !== '') {
      params = params.set('from', new Date(formValues['since']).toJSON());
      if (updateMapParams) {
        this.incidentsMapService.setFilterParam({
          since: new Date(formValues['since']).toISOString(),
        });
      }
    }
    if (formValues['until'] && formValues['until'] !== '') {
      params = params.set('to', new Date(formValues['until']).toJSON());
      if (updateMapParams) {
        this.incidentsMapService.setFilterParam({
          until: new Date(formValues['until']).toISOString(),
        });
      }
    }
    if (formValues['zoneId'] && formValues['zoneId'] !== '') {
      params = params.set('zoneId', formValues['zoneId']);
      if (updateMapParams) {
        this.incidentsMapService.setFilterParam({ zoneId: [String(formValues['zoneId'])] });
      }
    }
    if (formValues['locality'] && formValues['locality'] !== '') {
      params = params.set('localityId', formValues['locality']);
    }
    if (formValues['neighborhood'] && formValues['neighborhood'] !== '') {
      params = params.set('district', formValues['neighborhood']);
    }
    if (formValues['circumstance'] && formValues['circumstance'] !== '') {
      params = params.set('modifyingCircumstanceId', formValues['circumstance']);
    }
    if (formValues['incidentType'] && formValues['incidentType'] !== '') {
      params = params.set('incidentTypeId', formValues['incidentType']);
      if (updateMapParams) {
        this.incidentsMapService.setFilterParam({
          incidentTypeId: [String(formValues['incidentType'])],
        });
      }
    }
    return params;
  }
}

export enum IncidentOrderOptions {
  CODE = 'code',
  CREATED_AT = 'createdAt',
  PRIORITY = 'priority',
  INCIDENT_TYPE = 'incidentType',
  LOCALITY_ID = 'localityId',
  ADDRESS = 'address',
  DISTRICT = 'district',
}

export interface FilterTabs {
  filterForm: FormGroup;
  id: 'main' | number;
  totalPages: number;
  currentPage: number;
  sortMethod: 'asc' | 'desc';
  offset: number;
  sortBy: IncidentOrderOptions;
  title: string;
  isFiltered?: boolean;
  hasCircumstance: boolean;
  totalItems: number;
}

interface FilterFormValue {
  state: number | undefined;
  closingCode: number | undefined;
  priority: number | undefined;
  since: Date | string | undefined;
  until: Date | string | undefined;
  locality: number | undefined;
  zoneId: number | undefined;
  neighborhood: string | undefined;
  circumstance: string | undefined;
  incidentType: number | undefined;
}
