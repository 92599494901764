import { Injectable } from '@angular/core';
import { ProfileService } from '../profile/profile.service';
import { RoleTabService } from '../administration/roles/role-tab.service';
import { SidebarTreeMapPermissions } from '../../types/known-routes/general.known.routes';
import {
  IncidentsKnownItemId,
  IncidentsKnownRoutes,
} from '../../types/known-routes/incidents.known.routes';
import {
  AdministrationKnownItemId,
  AdministrationKnownRoutes,
} from '../../types/known-routes/administration.known.routes';
import {
  ParametricKnownItemId,
  ParametricKnownRoutes,
} from '../../types/known-routes/parametric.known.router';
import {
  ProviderKnownItemId,
  ProvidersKnownRoutes,
} from '../../types/known-routes/providers.known.routes';
import {
  HospitalsKnownItemId,
  HospitalsKnownRoutes,
} from '../../types/known-routes/hospitals.known.routes';
import {
  ReferenceKnownItemId,
  ReferenceKnownRoutes,
} from '../../types/known-routes/reference.known.routes';
import {
  FollowUpKnownItemId,
  FollowUpKnownRoutes,
} from '../../types/known-routes/followup.known.routes';
import {
  PortalsKnownItemId,
  PortalsKnownRoutes,
} from '../../types/known-routes/portals.known.routes';
import {
  StretcherKnownItemId,
  StretcherKnownRoutes,
} from '../../types/known-routes/stretcher.known.routes';
import {
  PowerBiKnownItemId,
  PowerBiKnownRoutes,
} from '../../types/known-routes/power-bi.known.routes';
import {
  HumanTalentTrainingKnownItemId,
  HumanTalentTrainingKnownRoutes,
} from '../../types/known-routes/human-talent-training.known.routes';
import { MapsKnownItemId, MapsKnownRoutes } from '../../types/known-routes/maps.known.routes';
import { Role } from './role.service';
import { NavItem, SidebarMenus } from '../../types/known-routes/types-and-helpers.known.routes';

@Injectable({
  providedIn: 'root',
})
export class PermissionsValidatorService {
  private cachedRoleData: (Role & { composite: string[] }) | undefined;

  // variables utilizadas para detectar routing infinito
  private _lastLandingFetch: { navItem: NavItem; count: number }[] = [];
  private _lastLandingDate = 0;
  private _maxRouteCount = 20;
  private _maxRoutingTime = 20;

  public get lastLandingFetch() {
    return this._lastLandingFetch;
  }

  public get lastLandingDate() {
    return this._lastLandingDate;
  }

  public get maxRouteCount() {
    return this._maxRouteCount;
  }

  public get maxRoutingTime() {
    return this._maxRoutingTime;
  }

  public resetLastLanding() {
    this._lastLandingFetch = [];
  }

  public getCachedRoleData() {
    return this.cachedRoleData;
  }

  public refreshCachedRole() {
    return this.fillRoleCache(
      this.cachedRoleData?.name || this.profileService.selectedRole?.name || ''
    );
  }

  constructor(
    private profileService: ProfileService,
    private roleService: RoleTabService
  ) {}

  /**
   * Validates if has at least one permission to authorize the user
   * @param permission
   * @param roleId override the selected role in order to use other to perform the validation (not recommended in normal use)
   */

  /*
  TODO: Cambiar input de permissions de strings a enum
   */
  async hasAccessToSection(
    permission: string[],
    roleId?: string,
    validateInherited?: boolean
  ): Promise<boolean> {
    let selectedRole: Role | undefined = undefined;

    if (roleId) {
      selectedRole = (await this.roleService.roleService.getAll()).find(
        (role: Role) => role.id === roleId
      );
    }

    const role = selectedRole || this.profileService.selectedRole;

    if (!role) {
      return false;
    }
    if (!permission || permission.length === 0) return true;

    if (!this.cachedRoleData || this.cachedRoleData.name !== role.name) {
      await this.fillRoleCache(role.name);
      if (!this.cachedRoleData) {
        this.cachedRoleData = undefined;
        return false;
      }
    }
    let result = permission.some((permission) =>
      this.cachedRoleData!.realmRoles?.includes(permission)
    );
    if (!result && validateInherited && this.cachedRoleData!.composite.length) {
      result = permission.some((permission) =>
        this.cachedRoleData!.composite?.includes(permission)
      );
    }
    return result;
  }

  public async fillRoleCache(name: string) {
    const data = (await this.roleService.roleService.searchByName(name)).find(
      (rol) => rol.name === name
    );
    if (data) {
      this.cachedRoleData = {
        ...data,
        composite: [],
      };
      const inherit = await this.roleService.roleService.getComposite(data.id);
      this.cachedRoleData.composite = inherit
        .filter((value) => !value.clientRole)
        .map((value) => value.name);
    } else {
      this.cachedRoleData = undefined;
    }
  }

  //dado que cada módulo cuenta con una ruta por defecto se redirige a esa sección.
  async defaultLandingRoute(): Promise<NavItem | undefined> {
    let landing: NavItem | undefined;
    if (await this.hasAccessToSection(SidebarTreeMapPermissions[SidebarMenus.Incidents])) {
      landing = IncidentsKnownRoutes(IncidentsKnownItemId.LIST);
    } else if (
      await this.hasAccessToSection(SidebarTreeMapPermissions[SidebarMenus.Administration])
    ) {
      landing = AdministrationKnownRoutes(AdministrationKnownItemId.ADMIN_PAGE);
    } else if (await this.hasAccessToSection(SidebarTreeMapPermissions[SidebarMenus.Parametric])) {
      landing = ParametricKnownRoutes(ParametricKnownItemId.PARAMETRIC_LIST);
    } else if (await this.hasAccessToSection(SidebarTreeMapPermissions[SidebarMenus.Providers])) {
      landing = ProvidersKnownRoutes(ProviderKnownItemId.PROVIDERS);
    } else if (await this.hasAccessToSection(SidebarTreeMapPermissions[SidebarMenus.Hospitals])) {
      //Validar si tiene permisos generales o permisos unicos
      if (await this.hasAccessToSection(['hospital_q'])) {
        landing = HospitalsKnownRoutes(HospitalsKnownItemId.MANAGE_HOSPITALS);
      }
      const hospitalsIds = Object.values(HospitalsKnownItemId);

      let item: NavItem | undefined = undefined;
      for (const id of hospitalsIds) {
        const navItem = HospitalsKnownRoutes(id as HospitalsKnownItemId);
        if (await this.hasAccessToSection(navItem.permissions)) {
          item = navItem;
          break;
        }
      }
      landing = item;
    } else if (await this.hasAccessToSection(SidebarTreeMapPermissions[SidebarMenus.Reference])) {
      landing = ReferenceKnownRoutes(ReferenceKnownItemId.LIST_REFERENCE);
    } else if (await this.hasAccessToSection(SidebarTreeMapPermissions[SidebarMenus.FollowUp])) {
      landing = FollowUpKnownRoutes(FollowUpKnownItemId.FOLLOWUP_PATIENTS_LIST);
    } else if (await this.hasAccessToSection(SidebarTreeMapPermissions[SidebarMenus.Portals])) {
      landing = PortalsKnownRoutes(PortalsKnownItemId.LIST_PORTALS);
    } else if (await this.hasAccessToSection(SidebarTreeMapPermissions[SidebarMenus.Stretcher])) {
      landing = StretcherKnownRoutes(StretcherKnownItemId.RETAINED_VEHICLE);
    } else if (await this.hasAccessToSection(SidebarTreeMapPermissions[SidebarMenus.HumanTalent])) {
      landing = HumanTalentTrainingKnownRoutes(
        HumanTalentTrainingKnownItemId.HUMAN_TALENT_TRAINING_LIST
      );
    } else if (await this.hasAccessToSection(SidebarTreeMapPermissions[SidebarMenus.PowerBi])) {
      landing = PowerBiKnownRoutes(PowerBiKnownItemId.POWER_BI);
    } else if (await this.hasAccessToSection(SidebarTreeMapPermissions[SidebarMenus.Maps])) {
      landing = MapsKnownRoutes(MapsKnownItemId.MAPS_MODULE);
    }

    if (landing) this.pushLanding(landing);
    this._lastLandingDate = Date.now();

    return landing;
  }

  pushLanding(landing: NavItem) {
    const foundLanding = this._lastLandingFetch.find(
      (item) => item.navItem.route === landing.route
    );

    if (!foundLanding) {
      const newNavItem = { navItem: landing, count: 0 };
      this._lastLandingFetch.length === 10
        ? this._lastLandingFetch.unshift(newNavItem)
        : this._lastLandingFetch.push(newNavItem);
    } else {
      foundLanding.count++;
    }
  }
}
