import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { TableKeyItem, TableKeyItemWithCode } from '../../../../shared/types/table';
import { ParametricsInterface } from '../../../../shared/types/parametrics.interface';
import { Clipboard } from '@angular/cdk/clipboard';
import { ValidatorsService } from '../../../../shared/services/validators.service';
import { LocationsService } from '../../../../shared/services/locations.service';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DialogComponent } from '../../../../shared/component/dialog/dialog.component';
import { ProfileService } from '../../../../shared/services/profile/profile.service';
import { AlertService } from '../../../../shared/services/alert.service';
import { DialogService } from '../../../../shared/services/dialog.service';
import { IncidentsService } from '../../../../shared/services/incidents/incidents.service';
import { EditionPatientDialogInterface } from '../../../../shared/types/dialog.interface';
import { LoadingService } from '../../../../shared/services/loading.service';
import { IncidentsPaymentResponsibleService } from '../../../../shared/services/incidents/incidents-sub-resources/incidents.payment.responsible.service';
import { IncidentsEventsService } from '../../../../shared/services/incidents/incidents-sub-resources/incidents.events.service';
import { IncidentsPaymentResponsibleTypeService } from '../../../../shared/services/incidents/incidents-sub-resources/incidents.payment.responsible.type.service';
import { LocationInfo } from '../../../../shared/types/location.interface';
import { OfficeKeyItem } from '../../../../shared/types/tabs.interface';
import { environment } from '../../../../../environments/environment';
import {
  InteroperabilityService,
  PatientValidator,
} from '../../../../shared/services/administration/interoperability/interoperability.service';
import {
  PatientInteroperabilityComponent,
  PatientInteroperabilityData,
} from '../../../../shared/component/patient-interoperability/patient-interoperability.component';
import { lastValueFrom } from 'rxjs';
import { MatRadioChange } from '@angular/material/radio';
import moment from 'moment';
import { DocumentTypeService } from '../../../../shared/services/document-type.service';
import { PupilShapeService } from '../../../../shared/services/parametric/core/pupil-shape.service';
import { IncidentsInterestPopulationsService } from '../../../../shared/services/incidents/incidents-sub-resources/incidents.interest.populations.service';
import { IncidentsEthnicitiesService } from '../../../../shared/services/incidents/incidents-sub-resources/incidents.ethnicities.service';
import {
  PatientCreateInput,
  PaymentResponsible,
  Validator,
} from '@smartsoft-types/sisem-incidents';
import { GendersService } from '../../../../shared/services/parametric/training-human-talent/genders/genders.service';
import { NotificationPatient } from '../../../../shared/types/app-ambulance/message/notification-patient.interface';
import { DatePipe } from '@angular/common';
import { Role } from '../../../../shared/services/roles-and-permissions/role.service';

@Component({
  selector: 'app-register-patient-modal',
  templateUrl: './register-patient-modal.component.html',
  styleUrls: ['./register-patient-modal.component.scss'],
})
export class RegisterPatientModalComponent implements OnInit {
  @ViewChild('assignmentResourceId', { read: ElementRef })
  assignmentResourceId: ElementRef;
  @ViewChild('dispatchType', { read: ElementRef }) dispatchType: ElementRef;
  @ViewChild('causeFailed', { read: ElementRef }) causeFailed: ElementRef;
  @ViewChild('gender', { read: ElementRef }) gender: ElementRef;
  @ViewChild('age', { read: ElementRef }) age: ElementRef;
  @ViewChild('time', { read: ElementRef }) time: ElementRef;
  @ViewChild('evolutionTimeObservations', { read: ElementRef })
  evolutionTimeObservations: ElementRef;
  @ViewChild('symptomObservations', { read: ElementRef })
  symptomObservations: ElementRef;
  @ViewChild('backgroundObservations', { read: ElementRef })
  backgroundObservations: ElementRef;
  @ViewChild('traumaVictim', { read: ElementRef }) traumaVictim: ElementRef;
  @ViewChild('heartRate', { read: ElementRef }) heartRate: ElementRef;
  @ViewChild('systolicBP', { read: ElementRef }) systolicBP: ElementRef;
  @ViewChild('diastolicBP', { read: ElementRef }) diastolicBP: ElementRef;
  @ViewChild('MAT', { read: ElementRef }) MAT: ElementRef;
  @ViewChild('breathingFrequency', { read: ElementRef })
  breathingFrequency: ElementRef;
  @ViewChild('temperature', { read: ElementRef }) temperature: ElementRef;
  @ViewChild('oximetry', { read: ElementRef }) oximetry: ElementRef;
  @ViewChild('glasgowBER', { read: ElementRef }) glasgowBER: ElementRef;
  @ViewChild('glasgowBVB', { read: ElementRef }) glasgowBVB: ElementRef;
  @ViewChild('glasgowBMB', { read: ElementRef }) glasgowBMB: ElementRef;
  @ViewChild('glasglowValue', { read: ElementRef }) glasglowValue: ElementRef;
  @ViewChild('glucometer', { read: ElementRef }) glucometer: ElementRef;
  @ViewChild('RTS', { read: ElementRef }) RTS: ElementRef;
  @ViewChild('pupilSize', { read: ElementRef }) pupilSize: ElementRef;
  @ViewChild('pupilShapeInput', { read: ElementRef })
  pupilShapeInput: ElementRef;
  @ViewChild('assessmentFindings', { read: ElementRef })
  assessmentFindings: ElementRef;
  @ViewChild('dx', { read: ElementRef }) dx: ElementRef;
  @ViewChild('secondDiagnosis', { read: ElementRef })
  secondDiagnosis: ElementRef;
  @ViewChild('thirdDiagnosis', { read: ElementRef })
  thirdDiagnosis: ElementRef;
  @ViewChild('causality', { read: ElementRef }) causality: ElementRef;
  @ViewChild('firstName', { read: ElementRef }) firstName: ElementRef;
  @ViewChild('secondName', { read: ElementRef }) secondName: ElementRef;
  @ViewChild('firstLastName', { read: ElementRef }) firstLastName: ElementRef;
  @ViewChild('secondLastName', { read: ElementRef })
  secondLastName: ElementRef;
  @ViewChild('idTypeId', { read: ElementRef }) idTypeId: ElementRef;
  @ViewChild('idNumber', { read: ElementRef }) idNumber: ElementRef;
  @ViewChild('paymentResponsibleType', { read: ElementRef })
  paymentResponsibleType: ElementRef;
  @ViewChild('paymentResponsible', { read: ElementRef })
  paymentResponsible: ElementRef;
  @ViewChild('nationalityId', { read: ElementRef }) nationalityId: ElementRef;
  @ViewChild('populationInterest', { read: ElementRef })
  populationInterest: ElementRef;
  @ViewChild('ethnicity', { read: ElementRef }) ethnicity: ElementRef;
  @ViewChild('clinicalHistoryIn', { read: ElementRef })
  clinicalHistoryIn: ElementRef;
  @ViewChild('IPSOfBogotaListId', { read: ElementRef })
  IPSOfBogotaListId: ElementRef;
  @ViewChild('consultedInADRES', { read: ElementRef })
  consultedInADRES: ElementRef;
  @ViewChild('consultedInRightsChecker', { read: ElementRef })
  consultedInRightsChecker: ElementRef;
  @ViewChild('consultedInDPNOrSISBEN', { read: ElementRef })
  consultedInDPNOrSISBEN: ElementRef;
  @ViewChild('observations', { read: ElementRef }) observations: ElementRef;
  // titles
  @ViewChild('dispatchData', { read: ElementRef }) dispatchData: ElementRef;
  @ViewChild('valoration', { read: ElementRef }) valoration: ElementRef;
  @ViewChild('patient', { read: ElementRef }) patient: ElementRef;
  interoperabilityIsActive: boolean = false;
  public hasError: boolean = false;
  public patientSaved: boolean = false;
  public incidentsResourceRelations: TableKeyItem[];
  public associatedResources: TableKeyItem[];
  public country: TableKeyItem[] = [];
  public payers: PaymentResponsible[] = [];
  public filteredPayers: PaymentResponsible[] = [];
  public typePaymentEntity: ParametricsInterface[] = [];
  public inputRequired: Array<{ nameInput: string; required: boolean }> = [
    {
      nameInput: 'assignmentResourceId',
      required: true,
    },
    {
      nameInput: 'causeFailed',
      required: false,
    },
    {
      nameInput: 'pupilShape',
      required: true,
    },
    {
      nameInput: 'dx',
      required: true,
    },
    {
      nameInput: 'secondDiagnosis',
      required: false,
    },
    {
      nameInput: 'thirdDiagnosis',
      required: false,
    },
    {
      nameInput: 'idTypeId',
      required: true,
    },
    {
      nameInput: 'causality',
      required: true,
    },
    {
      nameInput: 'paymentResponsibleType',
      required: true,
    },
    {
      nameInput: 'paymentResponsible',
      required: true,
    },
    {
      nameInput: 'nationalityId',
      required: true,
    },
    {
      nameInput: 'IPSOfBogota',
      required: false,
    },
    {
      nameInput: 'ethnicity',
      required: true,
    },
  ];
  public readonly formNames: any = {
    assignmentResourceId: 'Recurso que valora',
    dispatchType: 'Datos del despacho',
    causeFailed: 'Motivo de no traslado',
    gender: 'Género',
    age: 'Edad',
    time: 'Unidad de edad',
    evolutionTimeObservations: 'Tiempo de evolución',
    symptomObservations: 'Síntomas',
    backgroundObservations: 'Antecedentes',
    traumaVictim: 'Víctima de trauma',
    heartRate: 'Frecuencia cardíaca (entre 0 y 300)',
    systolicBP: 'TA sistólica (entre 0 y 300)',
    diastolicBP: 'TA diastólica (entre 0 y 150)',
    MAT: 'TAM',
    breathingFrequency: 'Frecuencia respiratoria (entre 0 y 99)',
    temperature: 'Temperatura (entre 10 y 45)',
    oximetry: 'Oximetría (entre 0 y 100)',
    glasgowBER: 'Glasgow MRO (entre 1 y 4)',
    glasgowBVB: 'Glasgow MRV (entre 1 y 5)',
    glasgowBMB: 'Glasgow MRM (entre 1 y 6)',
    glasglowValue: 'Valor Glasgow: formula',
    glucometer: 'Glucometría',
    pupilSize: 'Tamaño pupila entre 1 y 10mm',
    pupilShape: 'Forma pupila',
    assessmentFindings: 'Hallazgo de valoración',
    dx: 'Impresión DX',
    secondDiagnosis: 'Segundo diagnóstico',
    thirdDiagnosis: 'Tercer diagnóstico',
    causality: 'Causalidad',
    firstName: 'Primer nombre',
    secondName: 'Segundo nombre',
    firstLastName: 'Primer apellido',
    secondLastName: 'Segundo apellido',
    idTypeId: 'Tipo de identificación',
    idNumber: 'Número de identificación',
    paymentResponsibleType: 'Tipo de entidad responsable del pago',
    paymentResponsible: 'Pagador',
    nationalityId: 'Nacionalidad',
    ethnicity: 'Pertenencia étnica',
    populationInterest: 'Población de interés',
    clinicalHistoryIn: 'Historia Clínica en',
    IPSOfBogotaListId: 'Lista de IPS de Bogotá',
    consultedInADRES: 'Consultado en ADRES',
    consultedInRightsChecker: 'Consultado en comprobador de derechos',
    consultedInDPNOrSISBEN: 'Consultado en DNP o SISBEN',
    observations: 'Observaciones',
  };
  isDataValidated: boolean = false;
  documents: TableKeyItemWithCode[] = [];
  filteredDocuments: TableKeyItemWithCode[] = [];
  loadingData: boolean = false;

  genderList: TableKeyItem[] = [];

  environment = environment.api;
  public pupilShape: TableKeyItem[] = [];
  public diagnosis: TableKeyItemWithCode[] = [];
  public title: string;
  public type: string;
  public registerPatientForm: FormGroup;
  public patientFormTouched: boolean = false;
  public roles: Role[];
  public locations: TableKeyItem[] = [];
  public zones: ParametricsInterface[] = [];
  public isAnonymous: boolean = false;
  public hasPriority: Array<{ name: string; priority: boolean }> = [
    {
      name: 'evolutionTimeObservationsAsPriority',
      priority: false,
    },
    {
      name: 'symptomObservationsAsPriority',
      priority: false,
    },
    {
      name: 'backgroundObservationsAsPriority',
      priority: false,
    },
    {
      name: 'assessmentFindingsObservationsAsPriority',
      priority: false,
    },
    {
      name: 'observationsAsPriority',
      priority: false,
    },
  ];

  payerCapsules: { title: string; id: number; typeId?: number }[] = [];

  interestPopulation: TableKeyItem[] = [];

  readonly interoperabilityTransformObject: { [key: string]: string } = {
    PrimerNombre: 'firstName',
    primerNombre: 'firstName',
    segundoNombre: 'secondName',
    SegundoNombre: 'secondName',
    PrimerApellido: 'firstLastName',
    primerApellido: 'firstLastName',
    segundoApellido: 'secondLastName',
    SegundoApellido: 'secondLastName',
    apiDepartment: 'department',
    apiEPSName: 'ips',
    apiMunicipality: 'city',
    fechaNacimiento: 'age',
    FechaNacimiento: 'age',
    Sexo: 'gender',
  };

  ethnicityList: TableKeyItem[] = [];
  initialGenders: { male?: number; female?: number; nobinary?: number };

  constructor(
    public clipboard: Clipboard,
    public validatorsService: ValidatorsService,
    public locationService: LocationsService,
    public dialogRef: MatDialogRef<DialogComponent>,
    public profileService: ProfileService,
    public alertService: AlertService,
    public dialogService: DialogService,
    public incidentService: IncidentsService,
    private loadingService: LoadingService,
    private incidentsPaymentResponsibleService: IncidentsPaymentResponsibleService,
    @Inject(MAT_DIALOG_DATA) public data: EditionPatientDialogInterface,
    private incidentsEventsService: IncidentsEventsService,
    private incidentsPaymentResponsibleTypeService: IncidentsPaymentResponsibleTypeService,
    private interoperabilityService: InteroperabilityService,
    private dialog: MatDialog,
    private documentTypesService: DocumentTypeService,
    private pupilShapeService: PupilShapeService,
    private interestPopulationService: IncidentsInterestPopulationsService,
    private incidentsEthnicitiesService: IncidentsEthnicitiesService,
    private genderService: GendersService,
    private datePipe: DatePipe
  ) {
    this.title = data.title;
    this.type = data.type;
  }

  interoperabilityData: Validator[];

  async ngOnInit(): Promise<void> {
    this.loadingData = true;
    this.loadingService.setLoading(true);
    await this.getDocumentTypes();
    await this.getPayers();
    await this.initData();
    await this.validateInteroperability();
    this.initForm();
    if (this.data.edit) {
      await this.fillForm();
      setTimeout(() => {
        this.clearInputs();
      }, 30);
    }
    await this.getInteroperabilityData();
    this.loadingService.setLoading(false);
    await this.getGenders();
    await this.getPupilShape();
    await this.getInterestPopulations();
    await this.getEthnicity();
    this.dialogRef.disableClose = true;
    this.loadingData = false;
  }

  async getGenders() {
    try {
      this.genderService.flushCache();
      const list = await this.genderService.getAll();
      this.initialGenders = {
        male: list.find((gender) => gender.code === 'MALE')?.id,
        female: list.find((gender) => gender.code === 'FEMALE')?.id,
        nobinary: list.find((gender) => gender.code === 'NONE')?.id,
      };
      this.genderList = list.filter((item) => item.active === true);
      const selected = list.find((item) => item.id === this.data.patient?.genderId);
      if (selected && selected.active === false) {
        this.genderList.push(selected);
      }
      if (!selected) {
        this.registerPatientForm.get('gender')?.setValue('');
      }
      this.genderList.sort((a, b) => a.title.toLowerCase().localeCompare(b.title.toLowerCase()));
    } catch (e) {
      console.error(e);
    }
  }

  async getEthnicity() {
    try {
      this.incidentsEthnicitiesService.flushCache();
      const resp = await this.incidentsEthnicitiesService.getAll();
      const filtered = resp.filter((item) => item.active);
      const selected = resp.find(
        (item) => this.registerPatientForm.get('ethnicity')?.value === item.id
      );
      if (selected && !selected.active) {
        filtered.push(selected);
      }
      if (!selected) {
        this.registerPatientForm.get('ethnicity')?.setValue('');
      }
      filtered.sort((a, b) => a.title.toLowerCase().localeCompare(b.title.toLowerCase()));
      this.ethnicityList = filtered;
    } catch (e) {
      console.error(e);
    }
  }

  async getInterestPopulations() {
    try {
      this.interestPopulationService.flushCache();
      const resp = await this.interestPopulationService.getAll();
      const filtered = resp.filter((item) => item.active);
      const selected = resp.find(
        (item) => item.id === this.registerPatientForm.get('populationInterest')?.value
      );
      if (selected && !selected.active) {
        filtered.push(selected);
      }
      filtered.sort((a, b) => a.title.toLowerCase().localeCompare(b.title.toLowerCase()));
      this.interestPopulation = filtered;
    } catch (e) {
      console.error(e);
    }
  }

  async getPupilShape() {
    try {
      this.pupilShapeService.flushCache();
      const resp = await this.pupilShapeService.getAll();
      const filtered = resp.filter((item) => item.isActive);
      if (this.data.patient) {
        const selected = filtered.find((item) => item.id === this.data.patient?.pupilShape?.id);
        if (selected && !selected.isActive) {
          filtered.push(selected);
        }
        if (!selected) {
          this.registerPatientForm.get('pupilShape')?.setValue('');
        }
      }
      filtered.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));
      this.pupilShape = filtered.map((item) => ({
        id: item.id,
        title: item.name,
      }));
    } catch (e) {
      console.error(e);
    }
  }

  clearInputs() {
    if (this.payerCapsules.length > 0) {
      this.registerPatientForm.get('paymentResponsible')?.setValue('');
      this.registerPatientForm.get('paymentResponsible')?.removeValidators(Validators.required);
      this.registerPatientForm.get('paymentResponsible')?.updateValueAndValidity();
    }
  }

  async validateInteroperability() {
    try {
      const resp = await this.interoperabilityService.getInteroperabilityData();
      if (resp.some((validator) => validator.active)) {
        this.interoperabilityIsActive = true;
      }
    } catch (e) {
      console.error(e);
    }
  }

  async getInteroperabilityData() {
    try {
      this.interoperabilityData = await this.interoperabilityService.getInteroperabilityData();
    } catch (e) {
      console.error(e);
    }
  }

  async getDocumentTypes() {
    try {
      const allowed = ['ASI', 'MSI'];
      this.documents = (await this.documentTypesService.getPersonDocumentTypes()).filter(
        (type) => !allowed.includes(type.code)
      );

      this.documents.push({
        code: 'SI',
        id: -1,
        title: 'SIN INFORMACIÓN',
      });
      this.documents.sort((a, b) => a.title.toLowerCase().localeCompare(b.title.toLowerCase()));
      this.filteredDocuments = this.documents.filter((document) => document.id !== -1);
    } catch (e) {
      console.error(e);
    }
  }

  public changeSection(section: string) {
    if (section === 'dispatchData') {
      this.dispatchData.nativeElement.scrollIntoView();
    } else if (section === 'valoration') {
      this.valoration.nativeElement.scrollIntoView();
    } else {
      this.patient.nativeElement.scrollIntoView();
    }
  }

  initForm() {
    this.registerPatientForm = new FormGroup({
      assignmentResourceId: new FormControl('', [Validators.required]),
      dispatchType: new FormControl('', [Validators.required]),
      causeFailed: new FormControl('', []),
      gender: new FormControl('', [Validators.required]),
      age: new FormControl('', [
        Validators.required,
        Validators.pattern(this.validatorsService.numberValidator),
        Validators.maxLength(3),
      ]),
      time: new FormControl('', [Validators.required]),
      evolutionTimeObservations: new FormControl('', [Validators.required]),
      evolutionTimeObservationsAsPriority: new FormControl('', []),
      symptomObservations: new FormControl('', [Validators.required]),
      symptomObservationsAsPriority: new FormControl('', []),
      backgroundObservations: new FormControl('', [Validators.required]),
      backgroundObservationsAsPriority: new FormControl('', []),
      traumaVictim: new FormControl('', [Validators.required]),
      heartRate: new FormControl('', [
        Validators.required,
        Validators.min(0),
        Validators.max(300),
        Validators.maxLength(3),
        Validators.pattern(this.validatorsService.numberValidator),
      ]),
      systolicBP: new FormControl('', [
        Validators.required,
        Validators.min(0),
        Validators.max(300),
        Validators.maxLength(3),
        Validators.pattern(this.validatorsService.numberValidator),
      ]),
      diastolicBP: new FormControl('', [
        Validators.required,
        Validators.min(0),
        Validators.max(150),
        Validators.maxLength(3),
        Validators.pattern(this.validatorsService.numberValidator),
      ]),
      MAT: new FormControl({ value: '', disabled: true }, [Validators.required]),
      breathingFrequency: new FormControl('', [
        Validators.required,
        Validators.min(0),
        Validators.max(99),
        Validators.maxLength(3),
        Validators.pattern(this.validatorsService.numberValidator),
      ]),
      temperature: new FormControl('', [
        Validators.required,
        Validators.min(10),
        Validators.max(45),
        Validators.maxLength(4),
        Validators.pattern(this.validatorsService.numberDecimalValidator),
      ]),
      oximetry: new FormControl('', [
        Validators.required,
        Validators.min(0),
        Validators.max(100),
        Validators.maxLength(3),
        Validators.pattern(this.validatorsService.numberValidator),
      ]),
      glasgowBER: new FormControl('', [
        Validators.required,
        Validators.min(1),
        Validators.max(4),
        Validators.maxLength(1),
        Validators.pattern(this.validatorsService.numberValidator),
      ]),
      glasgowBVB: new FormControl('', [
        Validators.required,
        Validators.min(1),
        Validators.max(5),
        Validators.maxLength(1),
        Validators.pattern(this.validatorsService.numberValidator),
      ]),
      glasgowBMB: new FormControl('', [
        Validators.required,
        Validators.min(1),
        Validators.max(6),
        Validators.maxLength(1),
        Validators.pattern(this.validatorsService.numberValidator),
      ]),
      glasglowValue: new FormControl({ value: '', disabled: true }, [Validators.required]),
      glucometer: new FormControl('', [
        Validators.required,
        Validators.maxLength(4),
        Validators.min(10),
        Validators.max(500),
        Validators.pattern(this.validatorsService.alphanumericValidator),
      ]),
      RTS: new FormControl({ value: '', disabled: true }, []),
      pupilSize: new FormControl('', [
        Validators.required,
        Validators.maxLength(2),
        Validators.min(1),
        Validators.max(10),
        Validators.pattern(this.validatorsService.numberValidator),
      ]),
      pupilShape: new FormControl('', [Validators.required]),
      assessmentFindings: new FormControl('', []),
      assessmentFindingsObservationsAsPriority: new FormControl('', []),
      dx: new FormControl('', [Validators.required]),
      secondDiagnosis: new FormControl('', [Validators.required]),
      thirdDiagnosis: new FormControl('', [Validators.required]),
      causality: new FormControl('', [Validators.required]),
      NN: new FormControl('', []),
      firstName: new FormControl('', [
        Validators.required,
        Validators.pattern(this.validatorsService.alphabeticWithAccentsValidator),
        Validators.maxLength(50),
      ]),
      secondName: new FormControl('', [
        Validators.pattern(this.validatorsService.alphabeticWithAccentsValidator),
        Validators.maxLength(50),
      ]),
      firstLastName: new FormControl('', [
        Validators.required,
        Validators.pattern(this.validatorsService.alphabeticWithAccentsValidator),
        Validators.maxLength(50),
      ]),
      secondLastName: new FormControl('', [
        Validators.pattern(this.validatorsService.alphabeticWithAccentsValidator),
        Validators.maxLength(50),
      ]),
      idTypeId: new FormControl('', [Validators.required, Validators.maxLength(20)]),
      idNumber: new FormControl('', [
        Validators.required,
        Validators.pattern(this.validatorsService.alphanumericValidator),
      ]),
      paymentResponsibleType: new FormControl('', [Validators.required]),
      paymentResponsible: new FormControl('', [Validators.required]),
      nationalityId: new FormControl('', [Validators.required]),
      populationInterest: new FormControl('', []),
      ethnicity: new FormControl('', [Validators.required]),
      clinicalHistoryIn: new FormControl('', [Validators.required]),
      IPSOfBogotaListId: new FormControl('', []),
      consultedInADRES: new FormControl('', [Validators.required]),
      consultedInRightsChecker: new FormControl('', [Validators.required]),
      consultedInDPNOrSISBEN: new FormControl('', [Validators.required]),
      observations: new FormControl('', []),
      observationsAsPriority: new FormControl('', []),
    });
  }

  private disableForm() {
    const controls = this.registerPatientForm.controls;
    for (const property in controls) {
      this.registerPatientForm.get(property)!.disable();
    }
  }

  private async fillForm() {
    this.data.patient = this.data.tabData.patient.find(
      (patient) => patient.id === this.data.patient?.id
    );
    this.isAnonymous =
      this.data.patient?.NN === true
        ? true
        : this.data.patient?.NN === false
          ? false
          : this.data.patient?.firstName === null;
    this.hasPriority[0].priority = this.data.patient?.evolutionTimeObservationsAsPriority!;
    this.hasPriority[1].priority = this.data.patient?.symptomObservationsAsPriority!;
    this.hasPriority[2].priority = this.data.patient?.backgroundObservationsAsPriority!;
    this.hasPriority[3].priority = this.data.patient?.assessmentFindingsObservationsAsPriority!;
    this.hasPriority[4].priority = this.data.patient?.observationsAsPriority!;
    await this.setAnonymous();
    const { noTransferCauses, noTransferCausesAll } = this.data.tabData;

    const isInclude = noTransferCauses.some(
      (item) => item.id === this.data.patient?.noTransferCause?.id
    );

    if (!isInclude && this.data.patient?.noTransferCause?.id) {
      const itemInactiveNoTransferCause = noTransferCausesAll?.find(
        (item) => item.id === this.data.patient?.noTransferCause?.id
      );

      if (itemInactiveNoTransferCause) {
        noTransferCauses.push(itemInactiveNoTransferCause);
      }
    }
    this.registerPatientForm.patchValue({
      assignmentResourceId: this.data.patient?.assignmentResourceId,
      dispatchType: this.data.patient?.transferRequired
        ? 'patientAllowTransfer'
        : 'patientDoesNotAllowTransfer',
      causeFailed: this.data.patient?.noTransferCause?.id,
      gender: this.data.patient?.genderId,
      age: this.data.patient?.age,
      time: this.data.patient?.ageType,
      evolutionTimeObservations: this.data.patient?.evolutionTimeObservations,
      evolutionTimeObservationsAsPriority: this.data.patient?.evolutionTimeObservationsAsPriority,
      symptomObservations: this.data.patient?.symptomObservations,
      symptomObservationsAsPriority: this.data.patient?.symptomObservationsAsPriority,
      backgroundObservations: this.data.patient?.backgroundObservations,
      backgroundObservationsAsPriority: this.data.patient?.backgroundObservationsAsPriority,
      traumaVictim: this.data.patient?.traumaVictim.toString(),
      heartRate: this.data.patient?.heartRate,
      systolicBP: this.data.patient?.systolicBP,
      diastolicBP: this.data.patient?.diastolicBP,
      MAT: this.data.patient?.MAT,
      dx: this.data.patient?.diagnosticImpression?.id,
      breathingFrequency: this.data.patient?.breathingFrequency,
      temperature: this.data.patient?.temperature,
      oximetry: this.data.patient?.oximetry,
      glasgowBER: this.data.patient?.glasgowBER,
      glasgowBVB: this.data.patient?.glasgowBVR,
      glasgowBMB: this.data.patient?.glasgowBMR,
      glasglowValue: this.data.patient?.glasgowValue,
      glucometer: this.data.patient?.glucometer,
      RTS: this.data.patient?.RTS,
      pupilSize: this.data.patient?.pupilSize,
      pupilShape: Number(this.data.patient?.pupilShape?.id),
      assessmentFindings: this.data.patient?.assessmentFindings,
      assessmentFindingsObservationsAsPriority:
        this.data.patient?.assessmentFindingsObservationsAsPriority,
      secondDiagnosis: this.data.patient?.secondDiagnosis?.id,
      thirdDiagnosis: this.data.patient?.thirdDiagnosis?.id,
      NN: this.data.patient?.NN,
      firstName: this.data.patient?.firstName,
      secondName: this.isAnonymous ? '' : this.data.patient?.secondName,
      firstLastName: this.isAnonymous ? '' : this.data.patient?.firstLastName,
      secondLastName: this.isAnonymous ? '' : this.data.patient?.secondLastName,
      idTypeId: this.data.patient?.idTypeId,
      idNumber:
        (this.isAnonymous && this.data.patient?.idNumber?.toUpperCase().includes('MSI')) ||
        this.data.patient?.idNumber?.toUpperCase().includes('ASI')
          ? ''
          : this.data.patient?.idNumber,
      paymentResponsibleType: this.data.patient?.paymentResponsibleType?.id,
      paymentResponsible: '',
      nationalityId: this.data.patient?.nationalityId,
      clinicalHistoryIn: this.data.patient?.clinicalHistoryIn,
      IPSOfBogotaListId: this.data.patient?.IPSOfBogotaListId,
      consultedInADRES:
        this.data.patient?.consultedInADRES !== undefined &&
        this.data.patient?.consultedInADRES !== null
          ? this.data.patient?.consultedInADRES.toString()
          : '',
      consultedInRightsChecker:
        this.data.patient?.consultedInRightsChecker !== undefined &&
        this.data.patient?.consultedInRightsChecker !== null
          ? this.data.patient?.consultedInRightsChecker.toString()
          : '',
      consultedInDPNOrSISBEN:
        this.data.patient?.consultedInDPNOrSISBEN !== undefined &&
        this.data.patient?.consultedInDPNOrSISBEN !== null
          ? this.data.patient?.consultedInDPNOrSISBEN.toString()
          : '',
      observations: this.data.patient?.observations,
      observationsAsPriority: this.data.patient?.observationsAsPriority,
      populationInterest: this.data.patient?.interestPopulation?.id,
      ethnicity: this.data.patient?.ethnicity?.id,
      causality: this.data.patient?.causality?.id,
    });

    await this.setDispatchType();
    if (this.data.patient?.idNumber?.includes('MSI')) {
      const consecutive = this.data.patient?.idNumber?.toString().split('I');
      this.registerPatientForm.get('idNumber')?.setValue(`MSI-${consecutive[1]}`);
      setTimeout(() => {
        this.registerPatientForm.get('idNumber')?.setValue(`MSI-${consecutive[1]}`);
      }, 500);
    } else if (this.data.patient?.idNumber?.includes('ASI')) {
      const consecutive = this.data.patient?.idNumber?.toString().split('I');
      setTimeout(() => {
        this.registerPatientForm.get('idNumber')?.setValue(`ASI-${consecutive[1]}`);
      }, 500);
    }
    this.registerPatientForm.get('age')?.setValue(this.data.patient?.age);
    this.registerPatientForm.get('time')?.setValue(this.data.patient?.ageType);
    this.registerPatientForm.get('idTypeId')?.setValue(this.data.patient?.idTypeId);
    this.registerPatientForm.markAsPristine();
    const auxCapsules: { title: string; id: number; typeId?: number }[] = [];
    if (this.data.patient?.paymentResponsible) {
      auxCapsules.push({
        title: `${this.data.patient.paymentResponsible.title}-${
          this.data.patient.paymentResponsibleType?.title ?? ''
        }`,
        id: this.data.patient.paymentResponsible.id,
        typeId: this.data.patient.paymentResponsibleType?.id,
      });
    }
    if (this.data.patient?.secondPaymentResponsible) {
      auxCapsules.push({
        title: `${this.data.patient.secondPaymentResponsible.title}-${
          this.data.patient.secondPaymentResponsibleType?.title ?? ''
        }`,
        id: this.data.patient.secondPaymentResponsible.id,
        typeId: this.data.patient.secondPaymentResponsibleType?.id,
      });
    }
    if (this.data.patient?.thirdPaymentResponsible) {
      auxCapsules.push({
        title: `${this.data.patient.thirdPaymentResponsible.title}-${
          this.data.patient.thirdPaymentResponsibleType?.title ?? ''
        }`,
        id: this.data.patient.thirdPaymentResponsible.id,
        typeId: this.data.patient.thirdPaymentResponsibleType?.id,
      });
    }

    this.payerCapsules = auxCapsules;

    this.isDataValidated = this.data.patient?.validatedOnInteroperability === true;

    if (this.isDataValidated) {
      this.disableInputsOnInit(Object.keys(this.interoperabilityTransformObject));
    }

    this.validateIdTypeId();
  }

  private async initData() {
    this.loadingService.setLoading(true);
    await this.getPaymentResponsibleType();
    await this.getPayers();
    this.getCountries();
    await this.getIncidentsResourceRelations(this.data.tabData.selectedIncident?.id as number);
    this.getDiagnosis();
    this.loadingService.setLoading(false);
  }

  async setDispatchType() {
    if (this.registerPatientForm.get('dispatchType')?.value === 'patientDoesNotAllowTransfer') {
      // no required
      const noRequired = [
        'gender',
        'time',
        'evolutionTimeObservations',
        'symptomObservations',
        'backgroundObservations',
        'traumaVictim',
        'MAT',
        'glasglowValue',
        'pupilShape',
        'assessmentFindings',
        'dx',
        'secondDiagnosis',
        'thirdDiagnosis',
        'paymentResponsibleType',
        'paymentResponsible',
        'nationalityId',
        'clinicalHistoryIn',
        'consultedInADRES',
        'consultedInRightsChecker',
        'consultedInDPNOrSISBEN',
        'observations',
        'causality',
        'populationInterest',
        'ethnicity',
      ];

      noRequired.forEach((field) => this.clearValidators(field));

      this.registerPatientForm
        .get('age')
        ?.setValidators([
          Validators.pattern(this.validatorsService.numberValidator),
          Validators.maxLength(3),
        ]);
      this.registerPatientForm.get('age')?.updateValueAndValidity();
      await this.setAgeValidation();
      this.setCustomValidator('heartRate', 0, 300, 3);
      this.setCustomValidator('systolicBP', 0, 300, 3);
      this.setCustomValidator('diastolicBP', 0, 150, 3);
      this.setCustomValidator('breathingFrequency', 0, 99, 3);
      this.setCustomValidator('oximetry', 0, 100, 3);
      this.setCustomValidator('glasgowBER', 1, 4, 1);
      this.setCustomValidator('glasgowBVB', 1, 5, 1);
      this.setCustomValidator('glasgowBMB', 1, 6, 1);
      this.setCustomValidator('pupilSize', 1, 10, 2);

      this.registerPatientForm
        .get('temperature')
        ?.setValidators([
          Validators.min(10),
          Validators.max(45),
          Validators.maxLength(4),
          Validators.pattern(this.validatorsService.numberDecimalValidator),
        ]);
      this.registerPatientForm.get('temperature')?.updateValueAndValidity();
      this.registerPatientForm
        .get('glucometer')
        ?.setValidators([
          Validators.maxLength(4),
          Validators.min(10),
          Validators.max(500),
          Validators.pattern(this.validatorsService.alphanumericValidator),
        ]);
      this.registerPatientForm.get('glucometer')?.updateValueAndValidity();
      this.registerPatientForm
        .get('firstName')
        ?.setValidators([
          Validators.pattern(this.validatorsService.alphabeticWithAccentsValidator),
          Validators.maxLength(50),
        ]);
      this.registerPatientForm.get('firstName')?.updateValueAndValidity();
      this.registerPatientForm
        .get('secondName')
        ?.setValidators([
          Validators.pattern(this.validatorsService.alphabeticWithAccentsValidator),
          Validators.maxLength(50),
        ]);
      this.registerPatientForm.get('secondName')?.updateValueAndValidity();
      this.registerPatientForm
        .get('firstLastName')
        ?.setValidators([
          Validators.pattern(this.validatorsService.alphabeticWithAccentsValidator),
          Validators.maxLength(50),
        ]);
      this.registerPatientForm.get('firstLastName')?.updateValueAndValidity();
      this.registerPatientForm
        .get('secondLastName')
        ?.setValidators([
          Validators.pattern(this.validatorsService.alphabeticWithAccentsValidator),
          Validators.maxLength(50),
        ]);
      this.registerPatientForm.get('secondLastName')?.updateValueAndValidity();

      this.registerPatientForm
        .get('idTypeId')
        ?.setValidators([Validators.maxLength(20), Validators.required]);
      this.registerPatientForm
        .get('idNumber')
        ?.setValidators([
          Validators.pattern(this.validatorsService.alphanumericValidator),
          Validators.required,
        ]);

      this.registerPatientForm.get('idNumber')?.updateValueAndValidity();
      this.registerPatientForm.get('idTypeId')?.updateValueAndValidity();

      // inputs required
      const required = ['assignmentResourceId', 'dispatchType', 'causeFailed', 'age', 'time'];
      required.forEach((field) => this.setRequiredValidator(field));

      this.inputRequired.forEach((input) => {
        input.required =
          input.nameInput === 'assignmentResourceId' ||
          input.nameInput === 'dispatchType' ||
          input.nameInput === 'causeFailed' ||
          input.nameInput === 'age' ||
          input.nameInput === 'time';
      });
      this.hasError = false;
    } else {
      // required
      const required = [
        'gender',
        'time',
        'evolutionTimeObservations',
        'symptomObservations',
        'backgroundObservations',
        'traumaVictim',
        'MAT',
        'glasglowValue',
        'pupilShape',
        'assessmentFindings',
        'dx',
        'paymentResponsibleType',
        'paymentResponsible',
        'nationalityId',
        'clinicalHistoryIn',
        'consultedInADRES',
        'consultedInRightsChecker',
        'consultedInDPNOrSISBEN',
        'causality',
        'populationInterest',
        'ethnicity',
      ];
      required.forEach((field) => this.setRequiredValidator(field));

      this.registerPatientForm
        .get('age')
        ?.setValidators([
          Validators.required,
          Validators.pattern(this.validatorsService.numberValidator),
          Validators.maxLength(3),
        ]);
      this.registerPatientForm.get('age')?.updateValueAndValidity();
      await this.setAgeValidation();

      this.setCustomValidator('heartRate', 0, 300, 3, true);
      this.setCustomValidator('systolicBP', 0, 300, 3, true);
      this.setCustomValidator('diastolicBP', 0, 150, 3, true);
      this.setCustomValidator('breathingFrequency', 0, 99, 3, true);
      this.setCustomValidator('oximetry', 0, 100, 3, true);
      this.setCustomValidator('glasgowBER', 1, 4, 1, true);
      this.setCustomValidator('glasgowBVB', 1, 5, 1, true);
      this.setCustomValidator('glasgowBMB', 1, 6, 1, true);
      this.setCustomValidator('pupilSize', 1, 10, 2, true);

      this.registerPatientForm
        .get('temperature')
        ?.setValidators([
          Validators.required,
          Validators.min(10),
          Validators.max(45),
          Validators.maxLength(4),
          Validators.pattern(this.validatorsService.numberDecimalValidator),
        ]);
      this.registerPatientForm.get('temperature')?.updateValueAndValidity();
      this.registerPatientForm
        .get('glucometer')
        ?.setValidators([
          Validators.required,
          Validators.maxLength(4),
          Validators.min(10),
          Validators.max(500),
          Validators.pattern(this.validatorsService.alphanumericValidator),
        ]);
      this.registerPatientForm.get('glucometer')?.updateValueAndValidity();
      this.registerPatientForm
        .get('firstName')
        ?.setValidators([
          Validators.required,
          Validators.pattern(this.validatorsService.alphabeticWithAccentsValidator),
          Validators.maxLength(50),
        ]);
      this.registerPatientForm.get('firstName')?.updateValueAndValidity();
      this.registerPatientForm
        .get('firstLastName')
        ?.setValidators([
          Validators.required,
          Validators.pattern(this.validatorsService.alphabeticWithAccentsValidator),
          Validators.maxLength(50),
        ]);
      this.registerPatientForm.get('firstLastName')?.updateValueAndValidity();
      this.registerPatientForm
        .get('idTypeId')
        ?.setValidators([Validators.required, Validators.maxLength(20)]);
      this.registerPatientForm.get('idTypeId')?.updateValueAndValidity();
      this.registerPatientForm
        .get('idNumber')
        ?.setValidators([
          Validators.required,
          Validators.pattern(this.validatorsService.alphanumericValidator),
        ]);
      this.registerPatientForm.get('idNumber')?.updateValueAndValidity();

      this.inputRequired.forEach((input) => {
        if (
          input.nameInput === 'secondDiagnosis' ||
          input.nameInput === 'thirdDiagnosis' ||
          input.nameInput === 'secondName' ||
          input.nameInput === 'secondLastName' ||
          input.nameInput === 'observations'
        ) {
          input.required = false;
        } else {
          input.required = true;
        }
      });
      // no required
      const noRequired = ['secondDiagnosis', 'thirdDiagnosis', 'observations', 'causeFailed'];
      noRequired.forEach((field) => this.clearValidators(field));
      this.registerPatientForm.get('secondName')?.clearValidators();
      this.registerPatientForm
        .get('secondName')
        ?.setValidators([
          Validators.pattern(this.validatorsService.alphabeticWithAccentsValidator),
        ]);
      this.registerPatientForm.get('secondName')?.updateValueAndValidity();
      this.registerPatientForm.get('secondLastName')?.clearValidators();
      this.registerPatientForm
        .get('secondLastName')
        ?.setValidators([
          Validators.pattern(this.validatorsService.alphabeticWithAccentsValidator),
        ]);
      this.registerPatientForm.get('secondLastName')?.updateValueAndValidity();
    }
  }

  setThirdDiagnosis(thirdDiagnosis: number | string) {
    this.registerPatientForm.get('thirdDiagnosis')!.setValue(thirdDiagnosis);
    this.registerPatientForm.markAsDirty();
  }

  setCausality(causality: number | string) {
    this.registerPatientForm.get('causality')!.setValue(causality);
    this.registerPatientForm.markAsDirty();
  }

  setSecondDiagnosis(secondDiagnosis: number | string) {
    this.registerPatientForm.get('secondDiagnosis')!.setValue(secondDiagnosis);
    this.registerPatientForm.markAsDirty();
  }

  setDx(dx: number | string) {
    this.registerPatientForm.get('dx')!.setValue(dx);
    this.registerPatientForm.markAsDirty();
  }

  setNationalityId(nationalityId: number | string) {
    this.registerPatientForm.get('nationalityId')!.setValue(nationalityId);
    this.registerPatientForm.markAsDirty();
  }

  setPopulationInterest(populationInterest: number | string) {
    this.registerPatientForm.get('populationInterest')!.setValue(populationInterest);
    this.registerPatientForm.markAsDirty();
  }

  setEthnicity(ethnicity: number | string) {
    this.registerPatientForm.get('ethnicity')!.setValue(ethnicity);
    this.registerPatientForm.markAsDirty();
  }

  setAssignedResource(assignedResource: number | string) {
    this.registerPatientForm.get('assignmentResourceId')!.setValue(assignedResource);
    this.registerPatientForm.markAsDirty();
  }

  setIPSOfBogotaListId(IPSOfBogotaListId: number | string) {
    this.registerPatientForm.get('IPSOfBogotaListId')!.setValue(IPSOfBogotaListId);
    this.registerPatientForm.markAsDirty();
  }

  setPaymentResponsible(paymentResponsible: number | string) {
    this.registerPatientForm.get('paymentResponsible')!.setValue(paymentResponsible);
    this.registerPatientForm.markAsDirty();
  }

  setPaymentResponsibleType(paymentResponsibleType: number | string) {
    this.registerPatientForm.get('paymentResponsible')!.setValue('');
    this.registerPatientForm.get('paymentResponsibleType')!.setValue(paymentResponsibleType);
    this.registerPatientForm.markAsDirty();
    this.filteredPayers = [];
    if (paymentResponsibleType) {
      this.filteredPayers = this.payers
        .filter(
          (payer) =>
            payer.paymentResponsibleType?.id ===
            this.registerPatientForm.get('paymentResponsibleType')?.value
        )
        .sort((a, b) =>
          a.title.toLowerCase().localeCompare(b.title.toLowerCase(), undefined, {
            sensitivity: 'base',
          })
        );
    }
  }

  setGender(value: string | number) {
    if (value === this.registerPatientForm.get('gender')?.value) return;
    this.registerPatientForm.get('gender')?.setValue(value);
  }

  async validateAge() {
    const input = this.registerPatientForm.get('age');
    input?.setValue(input?.value.replace(/\D/g, ''));
    if (input?.value === '') {
      this.registerPatientForm.get('time')?.setValue('');
    }
    //await this.setAnonymous();
  }

  validateTraumaVictim() {
    if (this.registerPatientForm.get('traumaVictim')?.value === 'false') {
      this.registerPatientForm.get('RTS')?.setValue('');
    } else if (
      this.registerPatientForm.get('systolicBP')?.valid &&
      this.registerPatientForm.get('breathingFrequency')?.valid &&
      this.registerPatientForm.get('glasglowValue')?.value != ''
    ) {
      this.calculateRTS();
    }
    this.registerPatientForm.get('traumaVictim')!.markAsUntouched();
  }

  setTypeDocument(typeDocument: number | string) {
    this.registerPatientForm.markAsDirty();
    this.registerPatientForm.get('idTypeId')!.setValue(typeDocument);
    this.registerPatientForm.markAsDirty();
    if (
      Number(this.registerPatientForm.get('idTypeId')?.value) !== 5 &&
      Number(this.registerPatientForm.get('idTypeId')?.value) !== 2 &&
      Number(this.registerPatientForm.get('idTypeId')?.value) !== 6
    ) {
      this.registerPatientForm.get('nationalityId')?.setValue(1);
    } else {
      this.registerPatientForm.get('nationalityId')?.setValue('');
    }
    if (Number(this.registerPatientForm.get('age')?.value) < 18 && Number(typeDocument) === 1) {
      this.registerPatientForm.get('age')?.clearValidators();
      this.registerPatientForm
        .get('age')
        ?.setValidators([
          Validators.required,
          Validators.min(18),
          Validators.pattern(this.validatorsService.numberValidator),
        ]);
      this.registerPatientForm.get('age')?.updateValueAndValidity();
    } else {
      const validators = [Validators.pattern(this.validatorsService.numberValidator)];
      if (this.registerPatientForm.get('dispatchType')!.value === 'patientAllowTransfer')
        validators.push(Validators.required);
      this.registerPatientForm.get('age')?.addValidators(validators);
      this.registerPatientForm.get('age')?.updateValueAndValidity();
    }
    this.validateIdTypeId();
  }

  validateIdTypeId() {
    this.registerPatientForm.get('idNumber')?.clearValidators();
    const numberValidator = ['CC', 'TI', 'RC', 'NUIP'];
    const validators: ValidatorFn[] = [];
    const docType = this.documents.find(
      (type) => type.id === this.registerPatientForm.get('idTypeId')?.value
    );
    if (docType && numberValidator.includes(docType.code)) {
      validators.push(Validators.pattern(this.validatorsService.numberValidator));
    } else {
      validators.push(Validators.pattern(this.validatorsService.alphanumericValidator));
    }
    validators.push(Validators.required, Validators.minLength(3), Validators.maxLength(20));
    this.registerPatientForm.get('idNumber')?.setValidators(validators);
    this.registerPatientForm.get('idNumber')?.updateValueAndValidity();
    this.registerPatientForm.get('idNumber')?.markAsTouched();
  }

  setPupilShape(pupilShape: number | string) {
    this.registerPatientForm.get('pupilShape')!.setValue(pupilShape);
    this.registerPatientForm.markAsDirty();
  }

  setCauseFailed(causeFailed: number | string) {
    this.registerPatientForm.get('causeFailed')!.setValue(causeFailed);
    this.registerPatientForm.markAsDirty();
  }

  public findElement(
    filterId: number,
    list: TableKeyItem[]
  ): TableKeyItem | OfficeKeyItem | undefined {
    return list.find((value) => value.id === filterId);
  }

  onChangeAgeTypeAndValue() {
    if (
      this.registerPatientForm.get('age')?.value >= 18 &&
      this.registerPatientForm.get('time')?.value === 'years'
    ) {
      const allowed = ['CC', 'CE', 'TI', 'NUIP', 'PA', 'PEP', 'RC', 'SI'];
      this.filteredDocuments = this.documents.filter((type) => allowed.includes(type.code));
    } else {
      const allowed = ['CC', 'TI', 'NUIP', 'PA', 'PEP', 'RC', 'SI'];
      this.filteredDocuments = this.documents.filter((type) => allowed.includes(type.code));
    }
    this.filteredDocuments.sort((a, b) => a.title.localeCompare(b.title));
  }

  public async setAnonymous(evt?: any, clearInputs: boolean = false) {
    if (!this.isAnonymous) {
      this.isDataValidated = false;
    }

    if (evt !== undefined) {
      this.isAnonymous = !this.isAnonymous;
    }
    const paymentResponsible = this.payers.find((payers) => payers.code.toString() === '1153');
    if (this.isAnonymous) {
      const controlsToReset = ['age', 'time', 'secondName', 'secondLastName', 'gender'];
      controlsToReset.forEach((control) => {
        this.registerPatientForm.get(control)?.enable();
        if (['secondName', 'secondLastName'].includes(control))
          this.registerPatientForm.get(control)?.setValue('');
      });

      this.payerCapsules = [];
      const disableFields = [
        'firstName',
        'secondName',
        'firstLastName',
        'secondLastName',
        'idTypeId',
        'idTypeId',
        'idNumber',
        'paymentResponsibleType',
        'paymentResponsible',
        'nationalityId',
        'consultedInADRES',
        'consultedInRightsChecker',
        'consultedInDPNOrSISBEN',
        'paymentResponsible',
        'paymentResponsibleType',
        'IPSOfBogotaListId',
        'clinicalHistoryIn',
        'ethnicity',
      ];
      disableFields.forEach((field) => this.registerPatientForm.get(field)?.disable());
      const emptyF = ['secondName', 'firstLastName', 'secondLastName', 'ethnicity'];
      emptyF.forEach((field) => this.registerPatientForm.get(field)?.setValue(''));
      this.registerPatientForm.get('firstName')?.setValue('NN');
      this.registerPatientForm.get('nationalityId')?.setValue(0);
      const paymentResponsibleType = this.typePaymentEntity.find(
        (typePayment) => typePayment.code.toString() === '71'
      );
      const typeDocument = this.documents.find((document) => document.id === -1);
      //await wait(500);
      this.setTypeDocument(typeDocument?.id || '');
      this.setPaymentResponsibleType(paymentResponsibleType?.id || '');
      this.setPaymentResponsible(paymentResponsible?.id || '');
      this.registerPatientForm.get('idNumber')?.setValue('');
      this.registerPatientForm.get('nationalityId')?.setValue(0);
      this.registerPatientForm.get('consultedInADRES')?.setValue('false');
      this.registerPatientForm.get('consultedInRightsChecker')?.setValue('false');
      this.registerPatientForm.get('consultedInDPNOrSISBEN')?.setValue('false');
      this.addPaymentResponsible();
    } else {
      const enableFields = [
        'firstName',
        'secondName',
        'firstLastName',
        'secondLastName',
        'idTypeId',
        'idNumber',
        'consultedInADRES',
        'consultedInRightsChecker',
        'consultedInDPNOrSISBEN',
        'clinicalHistoryIn',
        'ethnicity',
        'IPSOfBogotaListId',
      ];
      enableFields.forEach((field) => this.registerPatientForm.get(field)?.enable());

      const emptyF = [
        'firstName',
        'idNumber',
        'idTypeId',
        'consultedInADRES',
        'consultedInRightsChecker',
        'consultedInDPNOrSISBEN',
      ];
      emptyF.forEach((field) => this.registerPatientForm.get(field)?.setValue(''));

      this.registerPatientForm.get('paymentResponsible')?.enable();
      this.registerPatientForm.get('paymentResponsibleType')?.enable();
      this.registerPatientForm.get('nationalityId')?.enable();
      this.setPaymentResponsibleType(
        clearInputs ? '' : this.data.patient?.paymentResponsibleType?.id || ''
      );
      this.setPaymentResponsible(
        clearInputs ? '' : this.data.patient?.paymentResponsible?.id || ''
      );
      this.registerPatientForm
        .get('nationalityId')
        ?.setValue(clearInputs ? '' : this.data.patient?.nationalityId || '');
      if (paymentResponsible) this.removePayer(paymentResponsible?.id);
      this.setTypeDocument(clearInputs ? '' : this.data.patient?.idTypeId || '');
    }
    this.registerPatientForm.get('secondName')?.setValue('');
    this.registerPatientForm.get('firstLastName')?.setValue('');
    this.registerPatientForm.get('secondLastName')?.setValue('');
    this.registerPatientForm.markAsDirty();
  }

  async cancelEdition() {
    let forceClose: string = '';
    if (this.registerPatientForm.dirty) {
      forceClose = await this.dialogService.yesNoOptionModal(
        this.title,
        '¿Está seguro de cerrar la pestaña? Los cambios realizados serán descartados',
        'cancel'
      );
    }
    if (forceClose === 'cancel') {
      this.incidentService.tabs.removeActiveMethod();
      const data: PatientCreateInput['data'] = this.assignValuesToSent();
      this.dialogRef.close();
      await this.incidentsEventsService.cancelCreateOrUpdatePatient(
        data,
        this.data.tabData.selectedIncident?.id as number
      );
      await this.incidentService.activeIncidentLogs?.getAllLogs();
    } else if (forceClose === '') {
      this.dialogRef.close();
    }
  }

  public priorityComment(evt: any, position: number) {
    evt.stopPropagation();
    this.hasPriority[position].priority = !this.hasPriority[position].priority;
  }

  async setAgeValidation(validateAnonymous: boolean = false) {
    const time = this.registerPatientForm.get('time')?.value?.toString()?.toLowerCase();
    this.onChangeAgeTypeAndValue();
    switch (time) {
      case 'days':
        this.setRequiredValidator('age', 0, 29);
        break;
      case 'months':
        this.setRequiredValidator('age', 1, 11);
        break;
      case 'years':
        this.setRequiredValidator('age', 1, 120);
        break;
      default:
        this.setAgeValidator();
        break;
    }
    if (this.isAnonymous && validateAnonymous) {
      await this.setAnonymous();
    }
  }

  private setAgeValidator() {
    if (this.registerPatientForm.get('dispatchType')?.value === 'patientDoesNotAllowTransfer') {
      this.registerPatientForm
        .get('age')
        ?.setValidators([
          Validators.pattern(this.validatorsService.numberValidator),
          Validators.maxLength(3),
        ]);
      this.registerPatientForm.get('age')?.updateValueAndValidity();
    } else {
      this.registerPatientForm
        .get('age')
        ?.setValidators([
          Validators.required,
          Validators.pattern(this.validatorsService.numberValidator),
          Validators.maxLength(3),
        ]);
      this.registerPatientForm.get('age')?.updateValueAndValidity();
    }
  }

  private assignValuesToSent(): PatientCreateInput['data'] {
    const formValues = this.registerPatientForm.value;
    let consultedInADRES =
      formValues.consultedInADRES === 'true'
        ? true
        : formValues.consultedInADRES === 'false'
          ? false
          : undefined;
    let consultedInDPNOrSISBEN =
      formValues.consultedInDPNOrSISBEN === 'true'
        ? true
        : formValues.consultedInDPNOrSISBEN === 'false'
          ? false
          : undefined;
    let consultedInRightsChecker =
      formValues.consultedInRightsChecker === 'true'
        ? true
        : formValues.consultedInRightsChecker === 'false'
          ? false
          : undefined;
    if (consultedInADRES) {
      consultedInDPNOrSISBEN = false;
      consultedInRightsChecker = false;
    } else if (consultedInDPNOrSISBEN) {
      consultedInADRES = false;
      consultedInRightsChecker = false;
    } else if (consultedInRightsChecker) {
      consultedInADRES = false;
      consultedInDPNOrSISBEN = false;
    }
    return {
      evolutionTimeObservationsAsPriority: this.hasPriority[0].priority,
      symptomObservationsAsPriority: this.hasPriority[1].priority,
      backgroundObservationsAsPriority: this.hasPriority[2].priority,
      assessmentFindingsObservationsAsPriority: this.hasPriority[3].priority,
      observationsAsPriority: this.hasPriority[4].priority,
      NN: this.isAnonymous ? this.isAnonymous : !this.isAnonymous ? this.isAnonymous : undefined,
      MAT:
        this.registerPatientForm.get('MAT')?.value === '' ||
        this.registerPatientForm.get('MAT')?.value === null ||
        this.registerPatientForm.get('MAT')?.value === undefined
          ? undefined
          : Number(this.registerPatientForm.get('MAT')?.value),
      age:
        formValues.age === '' || formValues.age === null || formValues.age === undefined
          ? undefined
          : formValues.age,
      ageType:
        formValues.time === '' || formValues.time === null || formValues.time === undefined
          ? undefined
          : formValues.time,
      incidentId: this.data.tabData.selectedIncident?.id as number,
      assessmentFindings:
        formValues.assessmentFindings === '' ||
        formValues.assessmentFindings === null ||
        formValues.assessmentFindings === undefined
          ? undefined
          : formValues.assessmentFindings,
      backgroundObservations:
        formValues.backgroundObservations === '' ||
        formValues.backgroundObservations === null ||
        formValues.backgroundObservations === undefined
          ? undefined
          : formValues.backgroundObservations,
      breathingFrequency:
        formValues.breathingFrequency === '' ||
        formValues.breathingFrequency === null ||
        formValues.breathingFrequency === undefined
          ? undefined
          : Number(formValues.breathingFrequency),
      transferRequired: !formValues.dispatchType
        ? undefined
        : formValues.dispatchType !== 'patientDoesNotAllowTransfer',
      diastolicBP:
        formValues.diastolicBP === '' ||
        formValues.diastolicBP === null ||
        formValues.diastolicBP === undefined
          ? undefined
          : Number(formValues.diastolicBP),
      evolutionTimeObservations:
        formValues.evolutionTimeObservations === '' ||
        formValues.evolutionTimeObservations === null ||
        formValues.evolutionTimeObservations === undefined
          ? undefined
          : formValues.evolutionTimeObservations,
      firstName:
        this.registerPatientForm.get('firstName')?.value === '' ||
        this.registerPatientForm.get('firstName')?.value === null ||
        this.registerPatientForm.get('firstName')?.value === undefined
          ? undefined
          : this.registerPatientForm.get('firstName')?.value,
      genderId: formValues.gender,
      diagnosticImpressionId:
        this.registerPatientForm.get('dx')?.value === '' ||
        this.registerPatientForm.get('dx')?.value === null ||
        this.registerPatientForm.get('dx')?.value === undefined
          ? undefined
          : Number(this.registerPatientForm.get('dx')?.value),
      assignmentResourceId:
        this.registerPatientForm.get('assignmentResourceId')?.value?.toString() === ''
          ? undefined
          : this.registerPatientForm.get('assignmentResourceId')?.value,
      glasgowBER:
        formValues.glasgowBER === '' ||
        formValues.glasgowBER === undefined ||
        formValues.glasgowBER === null
          ? undefined
          : Number(formValues.glasgowBER),
      glasgowBMR:
        formValues.glasgowBMB === '' ||
        formValues.glasgowBMB === undefined ||
        formValues.glasgowBMB === null
          ? undefined
          : Number(formValues.glasgowBMB),
      glasgowBVR:
        formValues.glasgowBVB === '' ||
        formValues.glasgowBVB === undefined ||
        formValues.glasgowBVB === null
          ? undefined
          : Number(formValues.glasgowBVB),
      glasgowValue:
        this.registerPatientForm.get('glasglowValue')?.value === '' ||
        this.registerPatientForm.get('glasglowValue')?.value === undefined ||
        this.registerPatientForm.get('glasglowValue')?.value === null
          ? undefined
          : Number(this.registerPatientForm.get('glasglowValue')?.value),
      glucometer:
        formValues.glucometer === null ||
        formValues.glucometer === undefined ||
        formValues.glucometer === ''
          ? undefined
          : formValues.glucometer.toString().toUpperCase() === 'HI'
            ? 'hi'
            : formValues.glucometer.toString().toUpperCase() === 'LO'
              ? 'lo'
              : formValues.glucometer.toString() === ''
                ? undefined
                : Number(formValues.glucometer),
      heartRate:
        formValues.heartRate === '' ||
        formValues.heartRate === null ||
        formValues.heartRate === undefined
          ? undefined
          : Number(formValues.heartRate),
      idNumber:
        this.registerPatientForm.get('idNumber')?.value === '' ||
        this.registerPatientForm.get('idNumber')?.value === null ||
        this.registerPatientForm.get('idNumber')?.value === undefined
          ? undefined
          : this.registerPatientForm.get('idNumber')?.value,
      observations:
        formValues.observations === '' ||
        formValues.observations === undefined ||
        formValues.observations === null
          ? undefined
          : formValues.observations,
      oximetry:
        formValues.oximetry === '' ||
        formValues.oximetry === undefined ||
        formValues.oximetry === null
          ? undefined
          : Number(formValues.oximetry),
      pupilShapeId:
        formValues.pupilShape === '' ||
        formValues.pupilShape === undefined ||
        formValues.pupilShape === null
          ? undefined
          : formValues.pupilShape,
      pupilSize:
        formValues.pupilSize === '' ||
        formValues.pupilSize === undefined ||
        formValues.pupilSize === null
          ? undefined
          : Number(formValues.pupilSize),
      symptomObservations:
        formValues.symptomObservations === '' ||
        formValues.symptomObservations === undefined ||
        formValues.symptomObservations === null
          ? undefined
          : formValues.symptomObservations,
      systolicBP:
        formValues.systolicBP === '' ||
        formValues.systolicBP === null ||
        formValues.systolicBP === undefined
          ? undefined
          : Number(formValues.systolicBP),
      temperature:
        formValues.temperature === '' ||
        formValues.temperature === null ||
        formValues.temperature === undefined
          ? undefined
          : Number(formValues.temperature),
      clinicalHistoryIn:
        formValues.clinicalHistoryIn === '' ||
        formValues.clinicalHistoryIn === null ||
        formValues.clinicalHistoryIn === undefined
          ? undefined
          : formValues.clinicalHistoryIn,
      traumaVictim:
        formValues.traumaVictim === 'true'
          ? true
          : formValues.traumaVictim === 'false'
            ? false
            : undefined,
      noTransferCauseId:
        formValues.causeFailed === '' ||
        formValues.causeFailed === null ||
        formValues.causeFailed === undefined
          ? undefined
          : formValues.causeFailed,
      causalityId:
        formValues.causality === '' ||
        formValues.causality === undefined ||
        formValues.causality === null
          ? undefined
          : Number(formValues.causality),
      ethnicityId:
        formValues.ethnicity === '' ||
        formValues.ethnicity === undefined ||
        formValues.ethnicity === null
          ? undefined
          : Number(formValues.ethnicity),
      interestPopulationId:
        formValues.populationInterest === '' ||
        formValues.populationInterest === undefined ||
        formValues.populationInterest === null
          ? undefined
          : Number(formValues.populationInterest),
      secondDiagnosisId:
        this.registerPatientForm.get('secondDiagnosis')?.value === '' ||
        this.registerPatientForm.get('secondDiagnosis')?.value === undefined ||
        this.registerPatientForm.get('secondDiagnosis')?.value === null
          ? undefined
          : Number(this.registerPatientForm.get('secondDiagnosis')?.value),
      thirdDiagnosis:
        this.registerPatientForm.get('thirdDiagnosis')?.value === '' ||
        this.registerPatientForm.get('thirdDiagnosis')?.value === null ||
        this.registerPatientForm.get('thirdDiagnosis')?.value === undefined
          ? undefined
          : Number(this.registerPatientForm.get('thirdDiagnosis')?.value),
      RTS:
        formValues.traumaVictim === 'true'
          ? Number(this.registerPatientForm.get('RTS')?.value)
          : undefined,
      IPSOfBogotaListId:
        this.registerPatientForm.get('IPSOfBogotaListId')?.value === '' ||
        this.registerPatientForm.get('IPSOfBogotaListId')?.value === null ||
        this.registerPatientForm.get('IPSOfBogotaListId')?.value === undefined
          ? undefined
          : Number(this.registerPatientForm.get('IPSOfBogotaListId')?.value),
      consultedInADRES: consultedInADRES,
      consultedInDPNOrSISBEN: consultedInDPNOrSISBEN,
      consultedInRightsChecker: consultedInRightsChecker,
      paymentResponsible:
        formValues.paymentResponsible === '' ||
        formValues.paymentResponsible === undefined ||
        formValues.paymentResponsible === null
          ? undefined
          : Number(formValues.paymentResponsible),
      paymentResponsibleType:
        formValues.paymentResponsibleType === '' ||
        formValues.paymentResponsibleType === undefined ||
        formValues.paymentResponsibleType === null
          ? undefined
          : Number(formValues.paymentResponsibleType),
      nationalityId:
        formValues.nationalityId === '' ||
        formValues.nationalityId === undefined ||
        formValues.nationalityId === null
          ? undefined
          : Number(formValues.nationalityId),
      idTypeId:
        formValues.idTypeId === '' ||
        formValues.idTypeId === undefined ||
        formValues.idTypeId === null
          ? undefined
          : Number(formValues.idTypeId),
      secondName:
        this.registerPatientForm.get('secondName')?.value === '' ||
        this.registerPatientForm.get('secondName')?.value === null ||
        this.registerPatientForm.get('secondName')?.value === undefined
          ? undefined
          : this.registerPatientForm.get('secondName')?.value,
      secondLastName:
        this.registerPatientForm.get('secondLastName')?.value === '' ||
        this.registerPatientForm.get('secondLastName')?.value === null ||
        this.registerPatientForm.get('secondLastName')?.value === undefined
          ? undefined
          : this.registerPatientForm.get('secondLastName')?.value,
      firstLastName:
        this.registerPatientForm.get('firstLastName')?.value === '' ||
        this.registerPatientForm.get('firstLastName')?.value === null ||
        this.registerPatientForm.get('firstLastName')?.value === undefined
          ? undefined
          : this.registerPatientForm.get('firstLastName')?.value,
      validatedOnInteroperability: this.isDataValidated,
    };
  }

  public async cancelRegistration() {
    let forceClose: string = '';
    if (this.registerPatientForm.dirty) {
      forceClose = await this.dialogService.yesNoOptionModal(
        'CERRAR PESTAÑA',
        '¿Está seguro de cancelar? Los cambios realizados serán descartados',
        'cancel'
      );
    }
    if (forceClose === 'cancel') {
      this.incidentService.tabs.removeActiveMethod();
      const data: PatientCreateInput['data'] = this.assignValuesToSent();
      this.dialogRef.close();
      await this.incidentsEventsService.cancelCreateOrUpdatePatient(
        data,
        this.data.tabData.selectedIncident?.id as number
      );
      await this.incidentService.activeIncidentLogs?.getAllLogs();
    } else if (forceClose === '') {
      this.dialogRef.close();
    }
  }

  private focusField(nameInput: string) {
    switch (nameInput) {
      case 'assignmentResourceId':
        this.assignmentResourceId.nativeElement.scrollIntoView();
        break;
      case 'dispatchType':
        this.dispatchType.nativeElement.scrollIntoView();
        break;
      case 'causeFailed':
        this.causeFailed.nativeElement.scrollIntoView();
        break;
      case 'gender':
        this.gender.nativeElement.scrollIntoView();
        break;
      case 'age':
        this.gender.nativeElement.scrollIntoView();
        break;
      case 'time':
        this.time.nativeElement.scrollIntoView();
        break;
      case 'evolutionTimeObservations':
        this.evolutionTimeObservations.nativeElement.focus();
        break;
      case 'symptomObservations':
        this.symptomObservations.nativeElement.focus();
        break;
      case 'backgroundObservations':
        this.backgroundObservations.nativeElement.focus();
        break;
      case 'traumaVictim':
        this.traumaVictim.nativeElement.scrollIntoView();
        break;
      case 'heartRate':
        this.heartRate.nativeElement.focus();
        break;
      case 'systolicBP':
        this.systolicBP.nativeElement.focus();
        break;
      case 'diastolicBP':
        this.diastolicBP.nativeElement.focus();
        break;
      case 'MAT':
        this.MAT.nativeElement.focus();
        break;
      case 'breathingFrequency':
        this.breathingFrequency.nativeElement.focus();
        break;
      case 'temperature':
        this.temperature.nativeElement.focus();
        break;
      case 'oximetry':
        this.oximetry.nativeElement.focus();
        break;
      case 'glasgowBER':
        this.glasgowBER.nativeElement.focus();
        break;
      case 'glasgowBVB':
        this.glasgowBVB.nativeElement.focus();
        break;
      case 'glasgowBMB':
        this.glasgowBMB.nativeElement.focus();
        break;
      case 'glasglowValue':
        this.glasglowValue.nativeElement.focus();
        break;
      case 'glucometer':
        this.glucometer.nativeElement.focus();
        break;
      case 'RTS':
        if (this.registerPatientForm.get('traumaVictim')!.value === 'true') {
          this.RTS.nativeElement.focus();
        }
        break;
      case 'pupilSize':
        this.pupilSize.nativeElement.focus();
        break;
      case 'pupilShape':
        this.pupilShapeInput.nativeElement.scrollIntoView();
        break;
      case 'assessmentFindings':
        this.assessmentFindings.nativeElement.focus();
        break;
      case 'dx':
        this.dx.nativeElement.scrollIntoView();
        break;
      case 'secondDiagnosis':
        this.secondDiagnosis.nativeElement.scrollIntoView();
        break;
      case 'thirdDiagnosis':
        this.thirdDiagnosis.nativeElement.scrollIntoView();
        break;
      case 'firstName':
        this.firstName.nativeElement.focus();
        break;
      case 'secondName':
        this.secondName.nativeElement.focus();
        break;
      case 'firstLastName':
        this.firstLastName.nativeElement.focus();
        break;
      case 'secondLastName':
        this.secondLastName.nativeElement.focus();
        break;
      case 'idTypeId':
        this.idTypeId.nativeElement.scrollIntoView();
        break;
      case 'idNumber':
        this.idNumber.nativeElement.focus();
        break;
      case 'paymentResponsibleType':
        this.paymentResponsibleType.nativeElement.scrollIntoView();
        break;
      case 'paymentResponsible':
        this.paymentResponsible.nativeElement.scrollIntoView();
        break;
      case 'nationalityId':
        this.nationalityId.nativeElement.scrollIntoView();
        break;
      case 'clinicalHistoryIn':
        this.clinicalHistoryIn.nativeElement.scrollIntoView();
        break;
      case 'IPSOfBogotaListId':
        if (this.registerPatientForm.get('clinicalHistoryIn')?.value === 'IPSOfBogota') {
          this.IPSOfBogotaListId.nativeElement.scrollIntoView();
        }
        break;
      case 'consultedInADRES':
        this.consultedInADRES.nativeElement.scrollIntoView();
        break;
      case 'consultedInRightsChecker':
        this.consultedInRightsChecker.nativeElement.scrollIntoView();
        break;
      case 'consultedInDPNOrSISBEN':
        this.consultedInDPNOrSISBEN.nativeElement.scrollIntoView();
        break;
      case 'observations':
        this.observations.nativeElement.scrollIntoView();
        break;
      case 'causality':
        this.causality.nativeElement.scrollIntoView();
        break;
      case 'ethnicity':
        this.ethnicity.nativeElement.scrollIntoView();
        break;
      case 'populationInterest':
        this.populationInterest.nativeElement.scrollIntoView();
        break;
    }
  }

  public async validateRequired(): Promise<string | undefined> {
    this.registerPatientForm.markAllAsTouched();
    let uncompletedList: string = '';
    if (this.registerPatientForm.invalid || this.payerCapsules.length === 0) {
      const step1List = this.registerPatientForm.controls;
      const invalidList = [];
      for (const property in step1List) {
        const controlState = this.registerPatientForm.get(property)!.invalid;
        if (controlState) {
          uncompletedList = uncompletedList + this.formNames[property] + ',';
          invalidList.push(property);
          this.registerPatientForm.get(property)!.markAsTouched();
          this.patientFormTouched = true;
        }
      }
      if (
        invalidList.includes(
          'consultedInADRES' || 'consultedInRightsChecker' || 'consultedInDPNOrSISBEN'
        )
      ) {
        this.hasError = true;
      }
      for (const property in step1List) {
        const controlState = this.registerPatientForm.get(property)!.invalid;
        if (controlState) {
          this.focusField(property.toString());
          break;
        }
      }
      if (uncompletedList.length > 0) {
        await this.dialogService.infoSubtitledModal(
          'AGREGAR PACIENTE',
          'Faltan los siguientes campos por diligenciar',
          uncompletedList
        );
      } else if (
        this.payerCapsules.length === 0 &&
        this.registerPatientForm.get('dispatchType')?.value === 'patientAllowTransfer'
      ) {
        await this.dialogService.infoSubtitledModal(
          'AGREGAR PACIENTE',
          'Se debe agregar un pagador',
          ''
        );
        this.focusField('paymentResponsible');
        invalidList.push('paymentResponsible');
      }
      return invalidList[0];
    }
    return undefined;
  }

  public async registerPatient() {
    const validation = await this.validateRequired();
    if (!validation) {
      const formValues = this.registerPatientForm.value;
      const consultedInADRES =
        formValues.consultedInADRES === 'true'
          ? true
          : formValues.consultedInADRES === 'false'
            ? false
            : undefined;
      const consultedInDPNOrSISBEN =
        formValues.consultedInDPNOrSISBEN === 'true'
          ? true
          : formValues.consultedInDPNOrSISBEN === 'false'
            ? false
            : undefined;
      const consultedInRightsChecker =
        formValues.consultedInRightsChecker === 'true'
          ? true
          : formValues.consultedInRightsChecker === 'false'
            ? false
            : undefined;
      const data: PatientCreateInput['data'] = {
        evolutionTimeObservationsAsPriority: this.hasPriority[0].priority,
        symptomObservationsAsPriority: this.hasPriority[1].priority,
        backgroundObservationsAsPriority: this.hasPriority[2].priority,
        assessmentFindingsObservationsAsPriority: this.hasPriority[3].priority,
        observationsAsPriority: this.hasPriority[4].priority,
        NN: this.isAnonymous ? this.isAnonymous : !this.isAnonymous ? this.isAnonymous : undefined,
        MAT:
          this.registerPatientForm.get('MAT')?.value === '' ||
          this.registerPatientForm.get('MAT')?.value === null ||
          this.registerPatientForm.get('MAT')?.value === undefined
            ? undefined
            : Number(this.registerPatientForm.get('MAT')?.value),
        age:
          this.registerPatientForm.get('age')?.value === '' ||
          this.registerPatientForm.get('age')?.value === null ||
          this.registerPatientForm.get('age')?.value === undefined
            ? undefined
            : this.registerPatientForm.get('age')?.value,
        ageType:
          this.registerPatientForm.get('time')?.value === '' ||
          this.registerPatientForm.get('time')?.value === null ||
          this.registerPatientForm.get('time')?.value === undefined
            ? undefined
            : this.registerPatientForm.get('time')?.value,
        incidentId: this.data.tabData.selectedIncident?.id as number,
        assessmentFindings:
          formValues.assessmentFindings === '' ||
          formValues.assessmentFindings === null ||
          formValues.assessmentFindings === undefined
            ? undefined
            : formValues.assessmentFindings,
        backgroundObservations:
          formValues.backgroundObservations === '' ||
          formValues.backgroundObservations === null ||
          formValues.backgroundObservations === undefined
            ? undefined
            : formValues.backgroundObservations,
        breathingFrequency:
          formValues.breathingFrequency === '' ||
          formValues.breathingFrequency === null ||
          formValues.breathingFrequency === undefined
            ? undefined
            : Number(formValues.breathingFrequency),
        transferRequired: !formValues.dispatchType
          ? undefined
          : formValues.dispatchType !== 'patientDoesNotAllowTransfer',
        diastolicBP:
          formValues.diastolicBP === '' ||
          formValues.diastolicBP === null ||
          formValues.diastolicBP === undefined
            ? undefined
            : Number(formValues.diastolicBP),
        evolutionTimeObservations:
          formValues.evolutionTimeObservations === '' ||
          formValues.evolutionTimeObservations === null ||
          formValues.evolutionTimeObservations === undefined
            ? undefined
            : formValues.evolutionTimeObservations,
        firstName:
          this.registerPatientForm.get('firstName')?.value === '' ||
          this.registerPatientForm.get('firstName')?.value === null ||
          this.registerPatientForm.get('firstName')?.value === undefined
            ? undefined
            : this.registerPatientForm.get('firstName')?.value,
        genderId: this.registerPatientForm.get('gender')?.value,
        diagnosticImpressionId:
          this.registerPatientForm.get('dx')?.value === '' ||
          this.registerPatientForm.get('dx')?.value === null ||
          this.registerPatientForm.get('dx')?.value === undefined
            ? undefined
            : Number(this.registerPatientForm.get('dx')?.value),
        assignmentResourceId:
          this.registerPatientForm.get('assignmentResourceId')?.value.toString() === ''
            ? undefined
            : this.registerPatientForm.get('assignmentResourceId')?.value,
        glasgowBER:
          formValues.glasgowBER === '' ||
          formValues.glasgowBER === undefined ||
          formValues.glasgowBER === null
            ? undefined
            : Number(formValues.glasgowBER),
        glasgowBMR:
          formValues.glasgowBMB === '' ||
          formValues.glasgowBMB === undefined ||
          formValues.glasgowBMB === null
            ? undefined
            : Number(formValues.glasgowBMB),
        glasgowBVR:
          formValues.glasgowBVB === '' ||
          formValues.glasgowBVB === undefined ||
          formValues.glasgowBVB === null
            ? undefined
            : Number(formValues.glasgowBVB),
        glasgowValue:
          this.registerPatientForm.get('glasglowValue')?.value === '' ||
          this.registerPatientForm.get('glasglowValue')?.value === undefined ||
          this.registerPatientForm.get('glasglowValue')?.value === null
            ? undefined
            : Number(this.registerPatientForm.get('glasglowValue')?.value),
        glucometer:
          formValues.glucometer === null ||
          formValues.glucometer === undefined ||
          formValues.glucometer === ''
            ? undefined
            : formValues.glucometer.toString().toUpperCase() === 'HI'
              ? 'hi'
              : formValues.glucometer.toString().toUpperCase() === 'LO'
                ? 'lo'
                : formValues.glucometer.toString() === ''
                  ? undefined
                  : formValues.glucometer.toString(),
        heartRate:
          formValues.heartRate === '' ||
          formValues.heartRate === null ||
          formValues.heartRate === undefined
            ? undefined
            : Number(formValues.heartRate),
        idNumber:
          this.registerPatientForm.get('idNumber')?.value === '' ||
          this.registerPatientForm.get('idNumber')?.value === null ||
          this.registerPatientForm.get('idNumber')?.value === undefined
            ? undefined
            : this.registerPatientForm.get('idNumber')?.value.replace('-', ''),
        observations:
          formValues.observations === '' ||
          formValues.observations === undefined ||
          formValues.observations === null
            ? undefined
            : formValues.observations,
        oximetry:
          formValues.oximetry === '' ||
          formValues.oximetry === undefined ||
          formValues.oximetry === null
            ? undefined
            : Number(formValues.oximetry),
        pupilShapeId:
          formValues.pupilShape === '' ||
          formValues.pupilShape === undefined ||
          formValues.pupilShape === null
            ? undefined
            : formValues.pupilShape,
        pupilSize:
          formValues.pupilSize === '' ||
          formValues.pupilSize === undefined ||
          formValues.pupilSize === null
            ? undefined
            : Number(formValues.pupilSize),
        symptomObservations:
          formValues.symptomObservations === '' ||
          formValues.symptomObservations === undefined ||
          formValues.symptomObservations === null
            ? undefined
            : formValues.symptomObservations,
        systolicBP:
          formValues.systolicBP === '' ||
          formValues.systolicBP === null ||
          formValues.systolicBP === undefined
            ? undefined
            : Number(formValues.systolicBP),
        temperature:
          formValues.temperature === '' ||
          formValues.temperature === null ||
          formValues.temperature === undefined
            ? undefined
            : Number(formValues.temperature),
        clinicalHistoryIn:
          formValues.clinicalHistoryIn === '' ||
          formValues.clinicalHistoryIn === null ||
          formValues.clinicalHistoryIn === undefined
            ? undefined
            : formValues.clinicalHistoryIn,
        traumaVictim:
          formValues.traumaVictim === 'true'
            ? true
            : formValues.traumaVictim === 'false'
              ? false
              : undefined,
        noTransferCauseId:
          formValues.causeFailed === '' ||
          formValues.causeFailed === null ||
          formValues.causeFailed === undefined
            ? undefined
            : formValues.causeFailed,
        secondDiagnosisId:
          this.registerPatientForm.get('secondDiagnosis')?.value === '' ||
          this.registerPatientForm.get('secondDiagnosis')?.value === undefined ||
          this.registerPatientForm.get('secondDiagnosis')?.value === null
            ? undefined
            : Number(this.registerPatientForm.get('secondDiagnosis')?.value),
        thirdDiagnosis:
          this.registerPatientForm.get('thirdDiagnosis')?.value === '' ||
          this.registerPatientForm.get('thirdDiagnosis')?.value === null ||
          this.registerPatientForm.get('thirdDiagnosis')?.value === undefined
            ? undefined
            : Number(this.registerPatientForm.get('thirdDiagnosis')?.value),
        RTS:
          formValues.traumaVictim === 'true'
            ? Number(this.registerPatientForm.get('RTS')?.value)
            : undefined,
        IPSOfBogotaListId:
          this.registerPatientForm.get('IPSOfBogotaListId')?.value === '' ||
          this.registerPatientForm.get('IPSOfBogotaListId')?.value === null ||
          this.registerPatientForm.get('IPSOfBogotaListId')?.value === undefined
            ? undefined
            : Number(this.registerPatientForm.get('IPSOfBogotaListId')?.value),
        consultedInADRES: consultedInADRES,
        consultedInDPNOrSISBEN: consultedInDPNOrSISBEN,
        consultedInRightsChecker: consultedInRightsChecker,
        paymentResponsible:
          !this.isAnonymous && this.payerCapsules.length > 0
            ? this.payerCapsules[0].id
            : this.payers.find((resp) => resp.code === '1153')?.id,
        thirdPaymentResponsible:
          !this.isAnonymous && this.payerCapsules.length > 0
            ? this.payerCapsules[2]
              ? this.payerCapsules[2].id
              : undefined
            : undefined,
        secondPaymentResponsible:
          !this.isAnonymous && this.payerCapsules.length > 0
            ? this.payerCapsules[1]
              ? this.payerCapsules[1].id
              : undefined
            : undefined,
        secondPaymentResponsibleType:
          !this.isAnonymous && this.payerCapsules.length > 0
            ? this.payerCapsules[1]
              ? this.payerCapsules[1].typeId
              : undefined
            : undefined,
        thirdPaymentResponsibleType:
          !this.isAnonymous && this.payerCapsules.length > 0
            ? this.payerCapsules[2]
              ? this.payerCapsules[2].typeId
              : undefined
            : undefined,
        paymentResponsibleType:
          !this.isAnonymous && this.payerCapsules.length > 0
            ? this.payerCapsules[0]
              ? this.payerCapsules[0].typeId
              : undefined
            : this.typePaymentEntity.find((type) => type.code === '71')?.id,
        nationalityId:
          formValues.nationalityId === '' ||
          formValues.nationalityId === undefined ||
          formValues.nationalityId === null
            ? undefined
            : Number(formValues.nationalityId),
        causalityId:
          formValues.causality === '' ||
          formValues.causality === undefined ||
          formValues.causality === null
            ? undefined
            : Number(formValues.causality),
        ethnicityId:
          formValues.ethnicity === '' ||
          formValues.ethnicity === undefined ||
          formValues.ethnicity === null
            ? undefined
            : Number(formValues.ethnicity),
        interestPopulationId:
          formValues.populationInterest === '' ||
          formValues.populationInterest === undefined ||
          formValues.populationInterest === null
            ? undefined
            : Number(formValues.populationInterest),
        idTypeId:
          formValues.idTypeId === '' ||
          formValues.idTypeId === undefined ||
          formValues.idTypeId === null
            ? undefined
            : Number(formValues.idTypeId),
        secondName:
          this.registerPatientForm.get('secondName')?.value === '' ||
          this.registerPatientForm.get('secondName')?.value === null ||
          this.registerPatientForm.get('secondName')?.value === undefined
            ? undefined
            : this.registerPatientForm.get('secondName')?.value,
        secondLastName:
          this.registerPatientForm.get('secondLastName')?.value === '' ||
          this.registerPatientForm.get('secondLastName')?.value === null ||
          this.registerPatientForm.get('secondLastName')?.value === undefined
            ? undefined
            : this.registerPatientForm.get('secondLastName')?.value,
        firstLastName:
          this.registerPatientForm.get('firstLastName')?.value === '' ||
          this.registerPatientForm.get('firstLastName')?.value === null ||
          this.registerPatientForm.get('firstLastName')?.value === undefined
            ? undefined
            : this.registerPatientForm.get('firstLastName')?.value,
        validatedOnInteroperability: this.isDataValidated,
      };
      this.loadingService.setLoading(true);
      try {
        this.patientSaved = true;
        if (!this.data.edit) {
          await this.incidentService.registerPatient(
            this.data.tabData.selectedIncident?.id as number,
            data
          );
        } else {
          await this.incidentService.updatePatient(
            this.data.tabData.selectedIncident?.id as number,
            this.data.patient?.id!,
            data
          );
          this.closeModal(true);
        }
        await this.copyCloseIncidentConfirm(false, data);
        this.loadingService.setLoading(false);
        await this.dialogService.successModal(
          'La información ha sido guardada exitosamente y se encuentra disponible en el portapapeles',
          this.title
        );
        try {
          this.loadingService.setLoading(true);
          await this.postResourceMessage();
        } catch (e) {
          console.error(e);
          this.loadingService.setLoading(false);
          await this.dialogService.informativeResultModal(
            'Falló el envío de la notificación del paciente asignado',
            'error'
          );
        } finally {
          this.loadingService.setLoading(false);
        }

        this.registerPatientForm.markAsPristine();
        this.incidentService.activeIncidentLogs?.getAllLogs();
        this.dialogRef.close();
      } catch (e: any) {
        console.error(e);
        this.loadingService.setLoading(false);
        if (e.error.error.includes('missing required peer')) {
          await this.dialogService.errorModal(this.title, 'Falta diligenciar el tipo de edad');
        } else if (
          e.error.error.includes('given resource is not assigned to given incident') ||
          e.error.error.includes('no relation with given id was found')
        ) {
          await this.dialogService.errorModal(
            this.title,
            'No puede actualizar el paciente, el recurso no está asignado a el incidente'
          );
        } else if (e.error.error === 'no pupil shape with given id was found') {
          this.registerPatientForm.get('pupilShape')?.setValue('');
          await this.getPupilShape();
          await this.dialogService.errorModal(
            this.title,
            'La paramétrica "Forma de Pupila" no se encuentra registrada en el sistema.'
          );
          this.focusField('pupilShape');
        } else if (e.error.error === 'no interestPopulation with given id was found') {
          this.registerPatientForm.get('populationInterest')?.setValue('');
          await this.getInterestPopulations();
          await this.dialogService.errorModal(
            this.title,
            'La paramétrica "Población de Interés" no se encuentra registrada en el sistema.'
          );
        } else if (e.error.error === 'no ethnicity with given id was found') {
          await this.getEthnicity();
          await this.dialogService.errorModal(
            this.title,
            'La paramétrica "Pertenencia Étnica" no se encuentra registrada en el sistema.'
          );
        } else if (e?.error?.error?.includes('noTransferCause')) {
          await this.dialogService.errorModal(
            this.title,
            'La paramétrica "Motivo de No Traslado" no se encuentra registrada en el sistema.'
          );
        } else {
          await this.dialogService.errorModal(
            this.title,
            'Ocurrió un error al agregar el paciente'
          );
        }
        this.patientSaved = false;
      }
      this.loadingService.setLoading(false);
    }
  }

  async postResourceMessage() {
    const incident = this.data.tabData.selectedIncident;
    if (incident) {
      const date = this.datePipe.transform(new Date(incident.createdAt), 'dd/MM/yyyy');
      const hour = this.datePipe.transform(new Date(incident.createdAt), 'HH:mm:ss');
      const year = new Date(incident.createdAt).getFullYear().toString().slice(-2);
      const incidentCode = `CRU-${incident.code}-${year}`;

      const postData: NotificationPatient = {
        data: {
          CRU: incidentCode,
          address: incident.address,
          hour: hour ?? '',
          geolocation: incident.geolocation?.coordinates.join(',') ?? '',
          incident_date: date ?? '',
          incident_number: `${incident.id}`,
          incident_priority: incident.priority?.title ?? '',
          incident_type: incident.incidentType?.code ?? '',
          notification_type: 'INCIDENT_ASSIGNED',
        },
        resources_codes: this.associatedResources.map((resource) => resource.title),
      };
      await this.incidentService.postPatientNotification(postData);
    }
  }

  closeModal(addOrEditPatient: boolean = false) {
    this.dialogRef.close(addOrEditPatient);
  }

  private async getIncidentsResourceRelations(incidentId: number) {
    this.incidentsResourceRelations = [];
    this.associatedResources = [];
    const incident = await this.incidentService.getIncidentsResourceRelations(incidentId);

    incident.forEach((res) => {
      if (
        (res.active && res.resource?.status?.code === '523') ||
        (this.data.edit && this.data.patient?.assignmentResourceId === res.resource?.id)
      ) {
        this.incidentsResourceRelations.push({
          id: res.resourceId,
          title: res.resource?.code || '',
        });
      }
      this.associatedResources.push({
        id: res.resourceId,
        title: res.resource?.code || '',
      });
    });

    if (this.incidentsResourceRelations.length === 0 && !this.data.edit) {
      this.loadingService.setLoading(false);
      await this.dialogService.errorModal(
        `${this.data.edit ? 'EDITAR' : 'AGREGAR'} PACIENTE`,
        'No se encontraron recursos asociados'
      );
      this.dialogRef.close();
    }
  }

  private getCountries() {
    let countries = this.locationService.getCountries();
    let countryCol: LocationInfo | undefined;
    let countryVen: LocationInfo | undefined;
    countries = countries.filter((el) => {
      if (el.name.includes('Colombia')) {
        countryCol = el;
        return false;
      }
      if (el.name.includes('Venezuela')) {
        countryVen = el;
        return false;
      }
      return true;
    });
    if (countries.length > 0) {
      this.country = countries.map((country) => {
        return {
          id: country.id,
          title: country?.name,
        };
      });
    }
    this.country.unshift(
      {
        id: countryCol?.id || 0,
        title: countryCol?.name || '',
      },
      {
        id: countryVen?.id || 0,
        title: countryVen?.name || '',
      },
      {
        id: 0,
        title: 'SIN INFORMACIÓN',
      }
    );
  }

  private async getPaymentResponsibleType() {
    try {
      this.typePaymentEntity = (await this.incidentsPaymentResponsibleTypeService.getAll()).sort(
        (a, b) =>
          a.title.toLowerCase().localeCompare(b.title.toLowerCase(), undefined, {
            sensitivity: 'base',
          })
      );
    } catch (e) {
      console.error(e);
    }
  }

  private async getPayers() {
    try {
      this.payers = await this.incidentsPaymentResponsibleService.getAll();
    } catch (e) {
      console.error(e);
    }
  }

  calculateTAM() {
    if (
      this.registerPatientForm.get('systolicBP')?.valid &&
      this.registerPatientForm.get('diastolicBP')?.valid
    ) {
      const tad = Number(this.registerPatientForm.get('diastolicBP')?.value);
      const tas = Number(this.registerPatientForm.get('systolicBP')?.value);
      const tam = (tad + (tas - tad) / 3).toFixed(2);
      this.registerPatientForm.get('MAT')?.setValue(tam);
      this.calculateRTS();
    } else {
      this.registerPatientForm.get('MAT')?.setValue('');
      this.calculateRTS();
    }
  }

  calculateGlasglow() {
    if (
      this.registerPatientForm.get('glasgowBER')?.value !== undefined &&
      this.registerPatientForm.get('glasgowBER')?.value !== '' &&
      this.registerPatientForm.get('glasgowBVB')?.value !== undefined &&
      this.registerPatientForm.get('glasgowBVB')?.value !== '' &&
      this.registerPatientForm.get('glasgowBMB')?.value !== undefined &&
      this.registerPatientForm.get('glasgowBMB')?.value !== ''
    ) {
      const glasgowBER = Number(this.registerPatientForm.get('glasgowBER')?.value);
      const glasgowBVB = Number(this.registerPatientForm.get('glasgowBVB')?.value);
      const glasgowBMB = Number(this.registerPatientForm.get('glasgowBMB')?.value);
      const glasglowValue = glasgowBMB + glasgowBVB + glasgowBER;
      this.registerPatientForm.get('glasglowValue')?.setValue(glasglowValue);
      this.calculateRTS();
    } else {
      this.registerPatientForm.get('glasglowValue')?.setValue('');
      this.calculateRTS();
    }
  }

  validateGlucometer() {
    if (
      this.registerPatientForm.get('glucometer')?.value.toString().toUpperCase() === 'HI' ||
      this.registerPatientForm.get('glucometer')?.value.toString().toUpperCase() === 'LO'
    ) {
      this.registerPatientForm.get('glucometer')?.clearValidators();
      this.registerPatientForm
        .get('glucometer')
        ?.setValidators([
          Validators.required,
          Validators.maxLength(2),
          Validators.pattern(this.validatorsService.alphabeticValidator),
        ]);
      this.registerPatientForm.get('glucometer')?.updateValueAndValidity();
    } else {
      this.setCustomValidator('glucometer', 10, 500, 5, true);
    }
  }

  calculateRTS() {
    if (
      this.registerPatientForm.get('systolicBP')?.valid &&
      this.registerPatientForm.get('breathingFrequency')?.valid &&
      this.registerPatientForm.get('glasglowValue')?.value != ''
    ) {
      const breathingFrequency = Number(this.registerPatientForm.get('breathingFrequency')?.value);
      const systolic = Number(this.registerPatientForm.get('systolicBP')?.value);
      const glasgow = Number(this.registerPatientForm.get('glasglowValue')?.value);
      const ecg =
        glasgow >= 13 && glasgow <= 15
          ? 4
          : glasgow >= 9 && glasgow <= 12
            ? 3
            : glasgow >= 6 && glasgow <= 8
              ? 2
              : glasgow >= 4 && glasgow <= 5
                ? 1
                : 0;
      const fr =
        breathingFrequency >= 10 && breathingFrequency <= 29
          ? 4
          : breathingFrequency > 29
            ? 3
            : breathingFrequency >= 6 && breathingFrequency <= 9
              ? 2
              : breathingFrequency >= 1 && breathingFrequency <= 5
                ? 1
                : 0;
      const tas =
        systolic > 89
          ? 4
          : systolic >= 76 && systolic <= 89
            ? 3
            : systolic >= 50 && systolic <= 75
              ? 2
              : systolic >= 1 && systolic <= 49
                ? 1
                : 0;
      const res = ecg + fr + tas;
      this.registerPatientForm.get('RTS')?.setValue(res);
    } else {
      this.registerPatientForm.get('RTS')?.setValue('');
    }
  }

  changeValidation() {
    if (this.registerPatientForm.get('gender')?.valid) {
      this.registerPatientForm.get('gender')!.markAsTouched();
    } else {
      this.registerPatientForm.get('gender')!.markAsUntouched();
    }
  }

  changeMedicalValidation() {
    if (this.registerPatientForm.get('clinicalHistoryIn')?.valid) {
      this.registerPatientForm.get('clinicalHistoryIn')!.markAsTouched();
    } else {
      this.registerPatientForm.get('clinicalHistoryIn')!.markAsUntouched();
    }
  }

  /**
   * copy text generated to clipboard
   * @param fromButton
   * @param _patient
   */
  public async copyCloseIncidentConfirm(
    fromButton: boolean = false,
    _patient?: PatientCreateInput['data']
  ) {
    const dx =
      this.data.tabData.diagnosis.find(
        (diagnosis) => Number(diagnosis.id) === Number(this.registerPatientForm.get('dx')?.value)
      )?.title || '';
    const secondDiagnosis =
      this.data.tabData.diagnosis.find(
        (diagnosis) =>
          Number(diagnosis.id) === Number(this.registerPatientForm.get('secondDiagnosis')?.value)
      )?.title || '';
    const thirdDiagnosis =
      this.data.tabData.diagnosis.find(
        (diagnosis) =>
          Number(diagnosis.id) === Number(this.registerPatientForm.get('thirdDiagnosis')?.value)
      )?.title || '';
    const causality = this.data.tabData.causalities.find(
      (causality) => causality.id === Number(this.registerPatientForm.get('causality')?.value)
    );
    const ethnicity = this.ethnicityList.find(
      (ethnicities) => ethnicities.id === Number(this.registerPatientForm.get('ethnicity')?.value)
    );
    const populationInterest = this.interestPopulation.find(
      (item) => item.id === Number(this.registerPatientForm.get('populationInterest')?.value)
    );
    const firstName =
      this.registerPatientForm.get('firstName')?.value !== null &&
      this.registerPatientForm.get('firstName')?.value !== undefined
        ? this.registerPatientForm.get('firstName')?.value
        : '';
    const secondName =
      this.registerPatientForm.get('secondName')?.value !== null &&
      this.registerPatientForm.get('secondName')?.value !== undefined
        ? this.registerPatientForm.get('secondName')?.value
        : '';
    const firstLastName =
      this.registerPatientForm.get('firstLastName')?.value !== null &&
      this.registerPatientForm.get('firstLastName')?.value !== undefined
        ? this.registerPatientForm.get('firstLastName')?.value
        : '';
    const secondLastName =
      this.registerPatientForm.get('secondLastName')?.value !== null &&
      this.registerPatientForm.get('secondLastName')?.value !== undefined
        ? this.registerPatientForm.get('secondLastName')?.value
        : '';
    const namePatient = firstName + ' ' + secondName + ' ' + firstLastName + ' ' + secondLastName;
    const systolic =
      this.registerPatientForm.get('systolicBP')?.value !== null &&
      this.registerPatientForm.get('systolicBP')?.value !== undefined
        ? this.registerPatientForm.get('systolicBP')?.value
        : 'Sin datos';
    const diastolic =
      this.registerPatientForm.get('diastolicBP')?.value !== null &&
      this.registerPatientForm.get('diastolicBP')?.value !== undefined
        ? this.registerPatientForm.get('diastolicBP')?.value
        : 'Sin datos';
    let nacionality: TableKeyItem = { id: 0, title: '' };
    let ips: TableKeyItem = { id: 0, title: '' };
    let idTypeId: TableKeyItem = { id: 0, title: '' };
    let pupilShape = '';
    if (
      this.registerPatientForm.get('IPSOfBogotaListId')?.value !== null &&
      this.registerPatientForm.get('IPSOfBogotaListId')?.value !== undefined
    ) {
      ips = this.data.tabData.providerIPS.find(
        (provider) =>
          Number(provider.id) === Number(this.registerPatientForm.get('IPSOfBogotaListId')?.value)
      )!;
    }
    if (
      this.registerPatientForm.get('nationalityId')!.value !== null &&
      this.registerPatientForm.get('nationalityId')!.value !== undefined
    ) {
      nacionality = this.country.find(
        (country) =>
          Number(country.id) === Number(this.registerPatientForm.get('nationalityId')!.value)
      )!;
    }
    if (
      this.registerPatientForm.get('idTypeId')?.value !== null &&
      this.registerPatientForm.get('idTypeId')?.value !== undefined
    ) {
      idTypeId = this.documents.find(
        (document) =>
          Number(document.id) === Number(this.registerPatientForm.get('idTypeId')?.value)
      )!;
    }
    if (
      this.registerPatientForm.get('pupilShape')?.value !== null &&
      this.registerPatientForm.get('pupilShape')?.value !== undefined
    ) {
      pupilShape =
        this.pupilShape.find(
          (pupil) => pupil.id === this.registerPatientForm.get('pupilShape')?.value
        )?.title || '';
    }
    const resourceCode =
      this.incidentsResourceRelations.find(
        (resource) => resource.id === this.registerPatientForm.get('assignmentResourceId')?.value
      )?.title || '';
    const allowsTransfer =
      this.registerPatientForm.get('dispatchType')?.value === 'patientAllowTransfer'
        ? {
            Etq_permite_traslado: 'Si',
          }
        : {
            Etq_permite_traslado: 'No',
            Etq_motivo_no_traslado:
              this.data.tabData.noTransferCauses.find(
                (cause) => cause.id === this.registerPatientForm.get('causeFailed')?.value
              )?.title ?? '',
          };
    const confirmCopyBody: {
      [k: string]: any;
    } = {
      Inicio:
        this.registerPatientForm.get('firstName')?.value === null ||
        this.registerPatientForm.get('firstName')?.value === 'NN'
          ? 'NN'
          : namePatient,
      Etq_Veh: resourceCode,
      ...allowsTransfer,
      Etq_Sexo:
        this.genderList.find((item) => item.id === this.registerPatientForm.get('gender')?.value)
          ?.title ?? '',
      Etq_Edad:
        this.registerPatientForm.get('age')?.value !== undefined &&
        this.registerPatientForm.get('age')?.value !== null
          ? this.registerPatientForm.get('age')?.value
          : '',
      Etq_Unidad:
        this.registerPatientForm.get('time')?.value === 'years'
          ? 'AÑOS'
          : this.registerPatientForm.get('time')?.value === 'months'
            ? 'MESES'
            : this.registerPatientForm.get('time')?.value === 'days'
              ? 'DÍAS'
              : '',
      TiempoEvolución:
        this.registerPatientForm.get('evolutionTimeObservations')?.value !== null &&
        this.registerPatientForm.get('evolutionTimeObservations')?.value !== undefined
          ? this.registerPatientForm.get('evolutionTimeObservations')?.value
          : '',
      Etq_Síntomas:
        this.registerPatientForm.get('symptomObservations')?.value !== null &&
        this.registerPatientForm.get('symptomObservations')?.value !== undefined
          ? this.registerPatientForm.get('symptomObservations')?.value
          : '',
      Etq_Anteced:
        this.registerPatientForm.get('backgroundObservations')?.value !== null &&
        this.registerPatientForm.get('backgroundObservations')?.value !== undefined
          ? this.registerPatientForm.get('backgroundObservations')?.value
          : '',
      Etq_Victima_Trauma:
        this.registerPatientForm.get('traumaVictim')?.value === 'true' ? 'Si' : 'No',
      Etq_FC:
        this.registerPatientForm.get('heartRate')?.value !== null &&
        this.registerPatientForm.get('heartRate')?.value !== undefined
          ? this.registerPatientForm.get('heartRate')?.value
          : '',
      'Etq_TA Sist/Diast': systolic + '/' + diastolic,
      Etq_TAM:
        this.registerPatientForm.get('MAT')?.value !== null &&
        this.registerPatientForm.get('MAT')?.value !== undefined
          ? this.registerPatientForm.get('MAT')?.value
          : '',
      Etq_FR:
        this.registerPatientForm.get('breathingFrequency')?.value !== null &&
        this.registerPatientForm.get('breathingFrequency')?.value !== undefined
          ? this.registerPatientForm.get('breathingFrequency')?.value
          : '',
      Etq_Temperatura:
        this.registerPatientForm.get('temperature')?.value !== null &&
        this.registerPatientForm.get('temperature')?.value !== undefined
          ? this.registerPatientForm.get('temperature')?.value
          : '',
      Etq_Oximetría:
        this.registerPatientForm.get('oximetry')?.value !== null &&
        this.registerPatientForm.get('oximetry')?.value !== undefined
          ? this.registerPatientForm.get('oximetry')?.value
          : '',
      Etq_Glasgow_MRO:
        this.registerPatientForm.get('glasgowBER')?.value !== null &&
        this.registerPatientForm.get('glasgowBER')?.value !== undefined
          ? this.registerPatientForm.get('glasgowBER')?.value
          : '',
      Etq_Glasgow_MRV:
        this.registerPatientForm.get('glasgowBVB')?.value !== null &&
        this.registerPatientForm.get('glasgowBVB')?.value !== undefined
          ? this.registerPatientForm.get('glasgowBVB')?.value
          : '',
      Etq_Glasgow_MRM:
        this.registerPatientForm.get('glasgowBMB')?.value !== null &&
        this.registerPatientForm.get('glasgowBMB')?.value !== undefined
          ? this.registerPatientForm.get('glasgowBMB')?.value
          : '',
      Etq_Glasgow:
        this.registerPatientForm.get('glasglowValue')?.value !== null &&
        this.registerPatientForm.get('glasglowValue')?.value !== undefined
          ? this.registerPatientForm.get('glasglowValue')?.value
          : '',
      Etq_Glucometría:
        this.registerPatientForm.get('glucometer')?.value !== null &&
        this.registerPatientForm.get('glucometer')?.value !== undefined
          ? this.registerPatientForm.get('glucometer')?.value
          : '',
      Etq_RTS:
        this.registerPatientForm.get('RTS')?.value &&
        this.registerPatientForm.get('traumaVictim')?.value === 'true'
          ? this.registerPatientForm.get('RTS')?.value
          : 'NA',
      Etq_Tamaño_pupilar:
        this.registerPatientForm.get('pupilSize')?.value !== null &&
        this.registerPatientForm.get('pupilSize')?.value !== undefined
          ? this.registerPatientForm.get('pupilSize')?.value
          : '',
      Etq_Forma_Pupila: pupilShape,
      Etq_Hallazgos_valoración:
        this.registerPatientForm.get('assessmentFindings')?.value !== null &&
        this.registerPatientForm.get('assessmentFindings')?.value !== undefined
          ? this.registerPatientForm.get('assessmentFindings')?.value
          : '',
      Etq_DX_Principal: dx,
      Etq_Segundo_DX: secondDiagnosis,
      Etq_Tercer_DX: thirdDiagnosis,
      Etq_causalidad: causality?.title || 'Sin datos',
      Etq_Nombre_Paciente: firstName === 'NN' ? 'NN' : namePatient,
      Etq_TipoId: idTypeId?.title || '',
      Etq_N_Identificación: this.registerPatientForm.get('idNumber')?.value || '',
      Etq_Tipo_Seg_Social: `${
        this.payerCapsules[0]
          ? this.typePaymentEntity.find((ent) => ent.id === this.payerCapsules[0].typeId)!.title +
            ', '
          : ''
      }${
        this.payerCapsules[1]
          ? this.typePaymentEntity.find((ent) => ent.id === this.payerCapsules[1].typeId)!.title +
            ' ,'
          : ', '
      }${
        this.payerCapsules[2]
          ? this.typePaymentEntity.find((ent) => ent.id === this.payerCapsules[2].typeId)!.title
          : ''
      }`,
      Etq_Pagador: `${
        this.payerCapsules[0]
          ? this.payers.find((ent) => ent.id === this.payerCapsules[0].id)!.title + ', '
          : ''
      }${
        this.payerCapsules[1]
          ? this.payers.find((ent) => ent.id === this.payerCapsules[1].id)!.title + ' ,'
          : ', '
      }${
        this.payerCapsules[2]
          ? this.payers.find((ent) => ent.id === this.payerCapsules[2].id)!.title
          : ''
      }`,
      Etq_Nacionalidad: nacionality !== null && nacionality !== undefined ? nacionality?.title : '',
      Etq_población_interés: populationInterest?.title || 'Sin datos',
      Etq_pertenencia_étnica: ethnicity?.title || 'Sin datos',
      Etq_Institución_HC:
        this.registerPatientForm.get('clinicalHistoryIn')?.value === 'IPSOutsideBogota'
          ? 'IPS fuera de Bogotá'
          : ips?.title || '',
      Etq_Pag_Adres:
        this.registerPatientForm.get('consultedInADRES')?.value === 'true' ? 'SI' : 'NO',
      Etq_Pag_Comprobador:
        this.registerPatientForm.get('consultedInRightsChecker')?.value === 'true' ? 'SI' : 'NO',
      Etq_Pagina_DNP:
        this.registerPatientForm.get('consultedInDPNOrSISBEN')?.value === 'true' ? 'SI' : 'NO',
      Etq_Observ: this.registerPatientForm.get('observations')?.value || '',
      Final: '',
    };
    let finalText = '';
    Object.keys(confirmCopyBody).forEach((key) => {
      finalText += `${key}: ${confirmCopyBody[key]}\n`;
    });
    this.clipboard.copy(finalText);
    if (fromButton)
      await this.dialogService.successModal(
        'La información se encuentra disponible en el portapapeles',
        this.title
      );
  }

  private setRequiredValidator(field: string, min?: number, max?: number) {
    this.registerPatientForm.get(field)?.clearValidators();
    this.registerPatientForm.get(field)?.updateValueAndValidity();
    this.registerPatientForm.get(field)?.setValidators([Validators.required]);
    if ((max && min) || (min === 0 && max)) {
      this.registerPatientForm.controls[field]?.addValidators([
        Validators.min(min),
        Validators.max(max),
      ]);
      if (field === 'age' && this.registerPatientForm.get('time')?.value === '') {
        this.registerPatientForm.controls[field]?.setValidators([
          Validators.min(min),
          Validators.max(max),
        ]);
      }
    }
    this.registerPatientForm.get(field)?.updateValueAndValidity();
  }

  private setCustomValidator(
    field: string,
    min: number,
    max: number,
    maxLength: number,
    required = false
  ) {
    this.registerPatientForm
      .get(field)
      ?.setValidators([
        Validators.min(min),
        Validators.max(max),
        Validators.maxLength(maxLength),
        Validators.pattern(this.validatorsService.numberValidator),
      ]);
    if (required) this.registerPatientForm.get(field)?.addValidators([Validators.required]);
    this.registerPatientForm.get(field)?.updateValueAndValidity();
  }

  private clearValidators(field: string) {
    this.registerPatientForm.get(field)?.setValidators([]);
    this.registerPatientForm.get(field)?.updateValueAndValidity();
  }

  private getDiagnosis() {
    this.diagnosis = this.data.tabData.diagnosis.map((diagnostic) => ({
      id: diagnostic.id,
      code: diagnostic.code,
      title: `${diagnostic.code} - ${diagnostic.title}`,
    }));
  }

  async autofillForm() {
    this.registerPatientForm.patchValue({
      assignmentResourceId: this.incidentsResourceRelations[0].id,
      dispatchType: 'patientAllowTransfer',
      causeFailed: '',
      age: 22,
      time: 'years',
      evolutionTimeObservations: 'Observaciones',
      evolutionTimeObservationsAsPriority: false,
      symptomObservations: 'Observaciones',
      symptomObservationsAsPriority: false,
      backgroundObservations: 'Observaciones',
      backgroundObservationsAsPriority: false,
      traumaVictim: 'true',
      heartRate: 22,
      systolicBP: 22,
      diastolicBP: 22,
      MAT: 22,
      dx: this.diagnosis[5].id,
      breathingFrequency: 22,
      temperature: 22,
      oximetry: 22,
      gender: 1,
      glasgowBER: 2,
      glasgowBVB: 2,
      glasgowBMB: 2,
      glasglowValue: 6,
      glucometer: 22,
      RTS: 22,
      pupilSize: 3,
      pupilShape: this.pupilShape[0].id,
      assessmentFindings: 'Observaciones',
      assessmentFindingsObservationsAsPriority: false,
      secondDiagnosis: this.diagnosis[10].id,
      thirdDiagnosis: this.diagnosis[20].id,
      NN: this.data.patient?.NN,
      firstName: '',
      secondName: '',
      firstLastName: '',
      secondLastName: '',
      idTypeId: this.documents.find((doc) => doc.id === 8)?.id,
      idNumber: '',
      paymentResponsibleType: '',
      paymentResponsible: '',
      nationalityId: '',
      clinicalHistoryIn: '',
      IPSOfBogotaListId: '',
      consultedInADRES: false,
      consultedInRightsChecker: false,
      consultedInDPNOrSISBEN: false,
      observations: 'Observaciones',
      observationsAsPriority: false,
      populationInterest: this.interestPopulation[0].id,
      ethnicity: '',
      causality: this.data.tabData.causalities[0]?.id,
    });
    this.validateTraumaVictim();
    await this.setAnonymous(false, true);
  }

  async checkPatientData() {
    if (!this.interoperabilityData.some((data) => data.active) || this.isAnonymous) {
      return;
    }
    if (
      !this.isAnonymous &&
      this.registerPatientForm.get('idNumber')?.value &&
      this.registerPatientForm.get('idNumber')?.value !== '' &&
      this.registerPatientForm.get('idTypeId')?.value &&
      this.registerPatientForm.get('idTypeId')?.value !== ''
    ) {
      const docType = this.documents.find(
        (doc) => doc.id === this.registerPatientForm.get('idTypeId')?.value
      );
      if (!docType) return;
      const dialog = this.dialog.open(PatientInteroperabilityComponent, {
        data: <PatientInteroperabilityData>{
          idNumber: this.registerPatientForm.get('idNumber')?.value,
          documentType: docType.code,
          modalTitle: this.data.edit ? 'EDITAR PACIENTE' : 'AGREGAR PACIENTE',
          icon: 'personal-injury',
        },
        panelClass: 'add-contact-modal',
        width: '80%',
        height: '90%',
        maxWidth: '722px',
        maxHeight: '826px',
      });
      const resp: 'not-data' | PatientValidator = await lastValueFrom(dialog.afterClosed());
      if (resp === 'not-data') {
        this.isDataValidated = false;
        return;
      }
      this.isDataValidated = true;
      this.payerCapsules = [];
      await this.fillPatientDataAfterValidate(resp);
    } else {
      this.registerPatientForm.get('idTypeId')?.markAsTouched();
      this.registerPatientForm.get('idNumber')?.markAsTouched();
      const uncompletedList: string[] = [];

      if (
        this.registerPatientForm.get('idTypeId')?.value === '' ||
        !this.registerPatientForm.get('idTypeId')?.value
      ) {
        uncompletedList.push('Tipo de documento');
      }

      if (
        this.registerPatientForm.get('idNumber')?.value === '' ||
        !this.registerPatientForm.get('idNumber')?.value
      ) {
        uncompletedList.push('Número de documento');
      }

      await this.dialogService.infoSubtitledModal(
        this.data.edit ? 'EDITAR PACIENTE' : 'AGREGAR PACIENTE',
        'Es necesario completar los siguientes campos para realizar la comprobación de datos',
        uncompletedList.join(',')
      );
    }
  }

  private parseDate(fecha: string) {
    const dateSplit = fecha.split('/');
    if (dateSplit.length !== 3) {
      throw new Error('El formato de fecha no es válido. Utiliza el formato dd/mm/yyyy.');
    }
    const day = parseInt(dateSplit[0]);
    const month = parseInt(dateSplit[1]);
    const year = parseInt(dateSplit[2]);

    return new Date(year, month, day);
  }

  private interoperabilityDate(birthDate: any): number {
    const userBirthDate = new Date(this.parseDate(birthDate));
    const today = new Date();
    let edad = today.getFullYear() - userBirthDate.getFullYear();

    const actualMonth = today.getMonth();
    const actualDay = today.getDate();
    const birthMonth = userBirthDate.getMonth();
    const birthDay = userBirthDate.getDate();
    if (actualMonth < birthMonth || (actualMonth === birthMonth && actualDay < birthDay)) {
      edad--;
    }
    return edad;
  }

  calculateAge(birthDate: Date) {
    const actualDate = new Date();
    const age = actualDate.getFullYear() - birthDate.getFullYear();
    const month = actualDate.getMonth() - birthDate.getMonth();
    if (month < 0 || (month === 0 && actualDate.getDate() < birthDate.getDate())) {
      return age - 1;
    } else {
      return age;
    }
  }

  calculateAgeType(birthDate: Date) {
    const actualDate = new Date();

    const difference = actualDate.getTime() - birthDate.getTime();

    const days = difference / (1000 * 60 * 60 * 24);
    const months = days / 30.4167;
    const years = days / 365.25;

    if (years < 1) {
      if (months < 1) {
        return 'days';
      } else {
        return 'months';
      }
    } else {
      return 'years';
    }
  }

  async fillPatientDataAfterValidate(resp: PatientValidator) {
    const keys = Object.keys(resp.filteredFields);

    keys.forEach((key) => {
      const transformedKey = this.interoperabilityTransformObject[key];
      if (key === 'fechaNacimiento' || key === 'FechaNacimiento') {
        if (resp.filteredFields[key]) {
          const birthDate = moment(resp.filteredFields[key], 'MM/D/YYYY').toDate();
          this.registerPatientForm.get('age')?.setValue(this.calculateAge(birthDate));
          this.registerPatientForm.get('time')?.setValue(this.calculateAgeType(birthDate));
        }
      } else if (key === 'Sexo') {
        const genderId =
          resp.filteredFields[key] === 'M'
            ? this.initialGenders.male
            : resp.filteredFields[key] === 'F'
              ? this.initialGenders.female
              : this.initialGenders.nobinary;
        this.registerPatientForm.get('genderId')?.setValue(genderId);
      } else {
        this.registerPatientForm.get(transformedKey)?.setValue(resp.filteredFields[key]);
      }
    });

    if (resp.activeInteroperability.some((option) => option.selector === 'adres')) {
      this.registerPatientForm.get('consultedInADRES')?.setValue('true');
    }

    if (resp.activeInteroperability.some((option) => option.selector === 'rightsChecker')) {
      this.registerPatientForm.get('consultedInRightsChecker')?.setValue('true');
    }

    if (resp.filteredFields['paymentResponsibleTypeId']) {
      this.setPaymentResponsibleType(
        parseInt(resp.filteredFields['paymentResponsibleTypeId'] as string)
      );
      this.setPaymentResponsible(parseInt(resp.filteredFields['paymentResponsibleId'] as string));
      this.addPaymentResponsible();
    }

    if (keys.some((key) => key.toLowerCase().includes('sexo'))) {
      const gender = resp.filteredFields['Sexo'] || resp.filteredFields['sexo'];
      this.registerPatientForm
        .get('gender')
        ?.setValue(gender === 'M' ? this.initialGenders.male : this.initialGenders.female);
    }

    this.isDataValidated = true;
    this.disableInputs(keys, resp);
  }

  private disableInputsOnInit(keys: string[]) {
    keys.forEach((key) => {
      const transformedKey = this.interoperabilityTransformObject[key];
      if (transformedKey) {
        this.registerPatientForm.get(transformedKey)?.disable();
      }
    });

    this.registerPatientForm.get('paymentResponsibleType')?.disable();
    this.registerPatientForm.get('paymentResponsible')?.disable();
    this.registerPatientForm.get('age')?.disable();
    this.registerPatientForm.get('time')?.disable();
  }

  disableInputs(keys: string[], patientData: PatientValidator) {
    keys.forEach((key) => {
      const transformedKey = this.interoperabilityTransformObject[key];
      if (key === 'fechaNacimiento' || key === 'FechaNacimiento') {
        if (patientData.filteredFields[key]) {
          this.registerPatientForm.get('age')?.disable();
          this.registerPatientForm.get('time')?.disable();
        }
      } else if (key === 'Sexo' || key === 'sexo') {
        if (patientData.filteredFields[key]) {
          this.registerPatientForm.get('gender')?.disable();
        }
      } else {
        this.registerPatientForm.get(transformedKey)?.disable();
      }
    });
    if (
      patientData.filteredFields['paymentResponsibleId'] &&
      patientData.filteredFields['paymentResponsibleTypeId']
    ) {
      this.registerPatientForm.get('paymentResponsibleType')?.disable();
      this.registerPatientForm.get('paymentResponsible')?.disable();
    }
  }

  removePayer(id: number) {
    if (this.isAnonymous || this.isDataValidated) return;
    const payerIndex = this.payerCapsules.findIndex((payer) => payer.id === id);
    if (payerIndex >= 0) {
      this.payerCapsules.splice(payerIndex, 1);
    }
    if (this.payerCapsules.length === 0) {
      this.registerPatientForm.get('paymentResponsible')?.addValidators([Validators.required]);
      this.registerPatientForm.get('paymentResponsible')?.updateValueAndValidity();
    }
  }

  addPaymentResponsible() {
    if (!this.registerPatientForm.get('paymentResponsible')?.value) return;
    if (
      this.payerCapsules.find(
        (cap) => cap.id === this.registerPatientForm.get('paymentResponsible')?.value
      )
    ) {
      return;
    }
    this.registerPatientForm.get('paymentResponsible')?.removeValidators([Validators.required]);
    this.registerPatientForm.get('paymentResponsible')?.updateValueAndValidity();
    if (this.payerCapsules.length < 3) {
      this.payerCapsules.push({
        title: `${
          this.payers.find(
            (payer) => payer.id === this.registerPatientForm.get('paymentResponsible')?.value
          )?.title ?? ''
        } - ${
          this.typePaymentEntity.find(
            (entity) => entity.id === this.registerPatientForm.get('paymentResponsibleType')?.value
          )?.title
        }`,
        id: this.registerPatientForm.get('paymentResponsible')?.value,
        typeId: this.registerPatientForm.get('paymentResponsibleType')?.value,
      });
      this.registerPatientForm.get('paymentResponsible')?.setValue('');
    }
  }

  setConsultedOnRightsChecker(event: MatRadioChange) {
    this.registerPatientForm.get('consultedInRightsChecker')?.setValue(event.value);
  }

  setConsultedOnAddres(event: MatRadioChange) {
    this.registerPatientForm.get('consultedInADRES')?.setValue(event.value);
  }
}
