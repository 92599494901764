import { EventEmitter, Injectable } from '@angular/core';
import { PermissionsValidatorService } from '../roles-and-permissions/permissions-validator.service';
import { SidebarTreeMapPermissions } from '../../types/known-routes/general.known.routes';
import {
  IncidentsKnownItemId,
  IncidentsKnownRoutes,
} from '../../types/known-routes/incidents.known.routes';
import {
  AdministrationKnownItemId,
  AdministrationKnownRoutes,
} from '../../types/known-routes/administration.known.routes';
import {
  ParametricKnownItemId,
  ParametricKnownRoutes,
} from '../../types/known-routes/parametric.known.router';
import {
  ProviderKnownItemId,
  ProvidersKnownRoutes,
} from '../../types/known-routes/providers.known.routes';
import {
  HospitalsKnownItemId,
  HospitalsKnownRoutes,
} from '../../types/known-routes/hospitals.known.routes';
import {
  FollowUpKnownItemId,
  FollowUpKnownRoutes,
} from '../../types/known-routes/followup.known.routes';
import {
  ReferenceKnownItemId,
  ReferenceKnownRoutes,
} from '../../types/known-routes/reference.known.routes';
import {
  PortalsKnownItemId,
  PortalsKnownRoutes,
} from '../../types/known-routes/portals.known.routes';
import {
  StretcherKnownItemId,
  StretcherKnownRoutes,
} from '../../types/known-routes/stretcher.known.routes';
import { Router } from '@angular/router';
import { MapsKnownItemId, MapsKnownRoutes } from '../../types/known-routes/maps.known.routes';
import {
  PowerBiKnownItemId,
  PowerBiKnownRoutes,
} from '../../types/known-routes/power-bi.known.routes';
import {
  HumanTalentTrainingKnownItemId,
  HumanTalentTrainingKnownRoutes,
} from '../../types/known-routes/human-talent-training.known.routes';
import { NavItem, SidebarMenus } from '../../types/known-routes/types-and-helpers.known.routes';

@Injectable({
  providedIn: 'root',
})
export class SidebarService {
  isSidebarExpanded: boolean = false;
  sidebarOptions: NavItem[] = [];

  lastOptionSelected: NavItem | undefined;

  public updatedOptions: EventEmitter<void> = new EventEmitter<void>();
  public emitSidebarOption: EventEmitter<NavItem> = new EventEmitter<NavItem>();

  constructor(
    private validatorService: PermissionsValidatorService,
    private router: Router
  ) {}

  public async getSidebarItems() {
    const items: NavItem[] = [];
    if (
      await this.validatorService.hasAccessToSection(
        SidebarTreeMapPermissions[SidebarMenus.Incidents]
      )
    ) {
      items.push(IncidentsKnownRoutes(IncidentsKnownItemId.LIST));
    }
    if (
      await this.validatorService.hasAccessToSection(
        SidebarTreeMapPermissions[SidebarMenus.Administration]
      )
    ) {
      const administrationIds = Object.values(AdministrationKnownItemId);
      const item = AdministrationKnownRoutes(AdministrationKnownItemId.SUB_PARAMETRICS);
      item.children = [];
      for (const id of administrationIds) {
        if (
          (await this.validatorService.hasAccessToSection(
            AdministrationKnownRoutes(id).permissions
          )) &&
          AdministrationKnownRoutes(id).visibleOnSidebar
        ) {
          item.children.push(AdministrationKnownRoutes(id));
        }
      }
      if (item.children.length > 0) items.push(item);
    }
    if (
      await this.validatorService.hasAccessToSection(
        SidebarTreeMapPermissions[SidebarMenus.Parametric]
      )
    ) {
      const parametricIds = Object.values(ParametricKnownItemId);
      const item = ParametricKnownRoutes(ParametricKnownItemId.PARAMETRIC_LIST);
      item.children = [];
      for (const id of parametricIds) {
        if (
          (await this.validatorService.hasAccessToSection(ParametricKnownRoutes(id).permissions)) &&
          ParametricKnownRoutes(id).visibleOnSidebar
        ) {
          item.children.push(ParametricKnownRoutes(id));
        }
      }
      if (item.children.length > 0) items.push(item);
    }

    if (
      await this.validatorService.hasAccessToSection(
        SidebarTreeMapPermissions[SidebarMenus.Providers]
      )
    ) {
      const providersIds = Object.values(ProviderKnownItemId);
      const item = ProvidersKnownRoutes(ProviderKnownItemId.PROVIDERS);
      item.children = [];
      for (const id of providersIds) {
        if (
          (await this.validatorService.hasAccessToSection(ProvidersKnownRoutes(id).permissions)) &&
          ProvidersKnownRoutes(id).visibleOnSidebar
        ) {
          item.children.push(ProvidersKnownRoutes(id));
        }
      }
      if (item.children.length > 0) items.push(item);
    }

    if (
      await this.validatorService.hasAccessToSection(
        SidebarTreeMapPermissions[SidebarMenus.Hospitals]
      )
    ) {
      const mainItem = HospitalsKnownRoutes(HospitalsKnownItemId.HOSPITALS);
      mainItem.children = [];
      const referenceIds = Object.values(HospitalsKnownItemId);
      for (const id of referenceIds) {
        if (
          (await this.validatorService.hasAccessToSection(HospitalsKnownRoutes(id).permissions)) &&
          HospitalsKnownRoutes(id).visibleOnSidebar
        ) {
          mainItem.children.push(HospitalsKnownRoutes(id));
        }
      }
      if (mainItem.children.length > 0) items.push(mainItem);
    }

    if (
      await this.validatorService.hasAccessToSection(
        SidebarTreeMapPermissions[SidebarMenus.Reference]
      )
    ) {
      const mainItem = ReferenceKnownRoutes(ReferenceKnownItemId.REFERENCE);
      mainItem.children = [ReferenceKnownRoutes(ReferenceKnownItemId.LIST_REFERENCE)];
      items.push(mainItem);
    }

    if (
      await this.validatorService.hasAccessToSection(
        SidebarTreeMapPermissions[SidebarMenus.FollowUp]
      )
    ) {
      const mainItem = FollowUpKnownRoutes(FollowUpKnownItemId.FOLLOWUP_REFERENCES);
      mainItem.children = [FollowUpKnownRoutes(FollowUpKnownItemId.FOLLOWUP_PATIENTS_LIST)];
      items.push(mainItem);
    }

    if (
      await this.validatorService.hasAccessToSection(
        SidebarTreeMapPermissions[SidebarMenus.Portals]
      )
    ) {
      const mainItem = PortalsKnownRoutes(PortalsKnownItemId.PORTALS);
      mainItem.children = [PortalsKnownRoutes(PortalsKnownItemId.LIST_PORTALS)];
      items.push(mainItem);
    }

    if (
      await this.validatorService.hasAccessToSection(
        SidebarTreeMapPermissions[SidebarMenus.Stretcher]
      )
    ) {
      const mainItem = StretcherKnownRoutes(StretcherKnownItemId.STRETCHER);
      mainItem.children = [StretcherKnownRoutes(StretcherKnownItemId.MANAGE_STRETCHER)];
      items.push(mainItem);
    }
    if (await this.validatorService.hasAccessToSection(['m_layers_r'])) {
      const mainItem = MapsKnownRoutes(MapsKnownItemId.MAPS_MODULE);
      mainItem.children = [MapsKnownRoutes(MapsKnownItemId.MANAGE_LAYERS)];
      items.push(mainItem);
    }

    if (
      await this.validatorService.hasAccessToSection(
        SidebarTreeMapPermissions[SidebarMenus.HumanTalent]
      )
    ) {
      const humanTalentIds = Object.values(HumanTalentTrainingKnownItemId);
      const mainItem = HumanTalentTrainingKnownRoutes(
        HumanTalentTrainingKnownItemId.HUMAN_TALENT_TRAINING
      );
      mainItem.children = [];
      for (const id of humanTalentIds) {
        if (
          (await this.validatorService.hasAccessToSection(
            HumanTalentTrainingKnownRoutes(id).permissions
          )) &&
          HumanTalentTrainingKnownRoutes(id).visibleOnSidebar
        ) {
          mainItem.children.push(HumanTalentTrainingKnownRoutes(id));
        }
      }
      if (mainItem.children.length > 0) items.push(mainItem);
    }

    if (await this.validatorService.hasAccessToSection(['power_bi_general_r'])) {
      const mainItem = PowerBiKnownRoutes(PowerBiKnownItemId.POWER_BI);
      mainItem.children = [PowerBiKnownRoutes(PowerBiKnownItemId.VIEW_BOARDS)];
      items.push(mainItem);
    }

    this.sidebarOptions = items;
    this.updatedOptions.emit();
    await this.validateRouteAfterRoleChange();
  }

  /**
   * Returns true or false if the selected role has access to the section
   * @param section
   */
  hasAccess(section: SidebarMenus) {
    return this.validatorService.hasAccessToSection(SidebarTreeMapPermissions[section]);
  }

  async validateRouteAfterRoleChange() {
    const actualRoute = this.router.url;
    const flattedItems = this.flattenArray(this.sidebarOptions);
    const found = flattedItems.find(
      (item) => actualRoute.includes(item.route) || actualRoute === item.route
    );
    if (found) {
      this.lastOptionSelected = found;
    } else {
      this.lastOptionSelected = !this.sidebarOptions[0]?.children
        ? this.sidebarOptions[0]
        : this.sidebarOptions[0].children[0];
      if (
        this.router.url.includes('/home/incidents') &&
        (await this.validatorService.hasAccessToSection(
          SidebarTreeMapPermissions[SidebarMenus.Administration]
        ))
      ) {
        this.lastOptionSelected = IncidentsKnownRoutes(IncidentsKnownItemId.LIST);
      } else {
        try {
          await this.router.navigateByUrl(this.lastOptionSelected?.route);
        } catch (e) {
          console.error(e);
        }
        this.emitSidebarOption.emit(this.lastOptionSelected);
      }
    }
  }

  flattenArray(array: NavItem[]): NavItem[] {
    return array.flatMap((obj) => {
      if (obj.children && obj.children.length > 0) {
        return [obj, ...this.flattenArray(obj.children)];
      } else {
        return obj;
      }
    });
  }

  switchSidebarState() {
    this.isSidebarExpanded = !this.isSidebarExpanded;
  }
}
