import { CachedCRUDService } from '../../types/cached-c-r-u-d.service';
import { HttpClient, HttpParams } from '@angular/common/http';
import { lastValueFrom } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class PermissionService extends CachedCRUDService<Permission, never, never, string> {
  constructor(private http: HttpClient) {
    super();
  }

  protected override async _fetchNewData(): Promise<Permission[]> {
    const params = new HttpParams().set('briefRepresentation', false);
    return await lastValueFrom(
      this.http.get<Permission[]>(
        `${environment.authService}/admin/realms/${environment.keycloakRealm}/roles`,
        { params }
      )
    );
  }

  protected override async _getByIdData(id: string): Promise<Permission> {
    const params = new HttpParams().set('briefRepresentation', false);
    return await lastValueFrom(
      this.http.get<Permission>(
        `${environment.authService}/admin/realms/${environment.keycloakRealm}/roles/${id}`,
        { params }
      )
    );
  }
}

export interface Permission {
  attributes: { [key: string]: Array<string> };
  clientRole: boolean;
  composite: boolean;
  containerId: string;
  description: string;
  id: string;
  name: string;
}
