import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { lastValueFrom } from 'rxjs';
import { environment } from '../../../../../../environments/environment';
import { CachedCRUDService } from '../../../../types/cached-c-r-u-d.service';
import { ParametricsInterface } from '../../../../types/parametrics.interface';
import { ServerResponse } from '../../../../types/http.interfaces';
import { TableKeyItem } from '../../../../types/table';

@Injectable({
  providedIn: 'root',
})
export class GendersService extends CachedCRUDService<ParametricsInterface, never, never> {
  private headers: HttpHeaders = new HttpHeaders();

  constructor(private http: HttpClient) {
    super();
    this.options.cacheExpiration = 120000;
  }

  protected override async _fetchNewData(): Promise<ParametricsInterface[]> {
    this.headers = this.headers.append('skip', 'true');
    this.headers = this.headers.append('sortBy', 'name');
    const res = await lastValueFrom(
      this.http.get<ServerResponse<ParametricsInterface[]>>(
        `${environment.api}/actors-ms/api/v1/genders`,
        { headers: this.headers }
      )
    );
    return res.data;
  }

  async getGendersTableKeyItem(): Promise<TableKeyItem[]> {
    const resp = await this.getAll();
    const mappedData = resp
      .filter((item) => item.active)
      .map((item) => ({
        id: item.id,
        title: item.title,
      }));
    mappedData.sort((a, b) =>
      a.title.localeCompare(b.title, undefined, { numeric: true, sensitivity: 'base' })
    );
    return mappedData;
  }
}
