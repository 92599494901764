import { Component, Inject, OnInit } from '@angular/core';
import { ProfileService } from '../../../../services/profile/profile.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ActorsService } from '../../../../services/actors/actors.service';

@Component({
  selector: 'app-zone-selector',
  templateUrl: './zone-selector.component.html',
  styleUrls: ['./zone-selector.component.scss'],
})
export class ZoneSelectorComponent implements OnInit {
  constructor(
    public profileService: ProfileService,
    private dialogRef: MatDialogRef<ZoneSelectorComponent>,
    private actorService: ActorsService,
    @Inject(MAT_DIALOG_DATA) public data: number | undefined
  ) {}

  ngOnInit() {
    this.dialogRef.disableClose = true;
  }

  async selectZone(zone: 1 | 2) {
    try {
      if (!this.data) {
        this.profileService.zone = zone;
        this.profileService.changeRole.emit(false);
        await this.actorService.registerActorEvent({
          eventType: 'roleChange',
          eventData: {
            zoneId: zone,
            currentRoleId: this.profileService.selectedRole?.id || '-1',
            previousRoleId: this.profileService.selectedRole?.id || '-1',
            actorId: this.profileService.authenticatedActor?.id || -1,
          },
        });
        this.dialogRef.close();
      }
      this.dialogRef.close(zone);
    } catch (e) {
      console.error('[selectRole] [error] could not register event');
    }
  }

  close() {
    this.dialogRef.close();
  }
}
