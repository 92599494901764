import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-layer-properties',
  templateUrl: './layer-properties.component.html',
  styleUrls: ['./layer-properties.component.scss'],
})
export class LayerPropertiesComponent implements OnInit {
  properties: { key: string; value: any }[] = [];

  constructor(
    public dialogRef: MatDialogRef<LayerPropertiesComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { [key: string]: any }
  ) {}

  ngOnInit(): void {
    this.transformData();
  }

  transformData() {
    Object.keys(this.data).forEach((key) => {
      this.properties.push({ key: key, value: this.data[key] });
    });
  }
}
