import { Injectable } from '@angular/core';

import { HttpClient } from '@angular/common/http';
import {
  ParametricService,
  TabActionType,
  TabData,
} from '../../../types/parametric.service.abstract.class';
import { Router } from '@angular/router';
import { KeyPressedHandlerService } from '../../key-pressed-handler.service';
import { ProfileService } from '../../profile/profile.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Role, RoleService } from '../../roles-and-permissions/role.service';
import { PermissionService } from '../../roles-and-permissions/permission.service';

export interface RoleTabData {
  selectedRole?: Role;
  roleForm: FormGroup;
  roleFormInitialized: boolean;
  cloneRole: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class RoleTabService extends ParametricService<RoleTabData, RoleOpenTabOptions> {
  constructor(
    private http: HttpClient,
    private router: Router,
    keyPressHandleService: KeyPressedHandlerService,
    public override profileService: ProfileService,
    public roleService: RoleService,
    public permissionService: PermissionService
  ) {
    super(keyPressHandleService, profileService);
  }

  override async newTab(actionType: TabActionType, option?: RoleOpenTabOptions): Promise<void> {
    const id = ++this._tabIdCounter;

    if (this.tabs.find((tab) => tab.id === id && tab.actionType === actionType)) {
      await this.selectTab(id);
      return;
    }

    let role: undefined | Role;

    if (option?.uid) {
      role = await this.roleService.getById(option.uid);
    }

    const title =
      actionType === TabActionType.CREATE
        ? 'NUEVO ROL'
        : actionType === TabActionType.EDIT
          ? 'EDITAR ROL'
          : 'CLONAR ROL';

    const newTab: TabData<RoleTabData> = {
      id,
      title,
      createdByIp: '',
      createdDate: [],
      createdBy: '',
      creationInfoExpanded: false,
      edition: actionType === TabActionType.EDIT,
      actionType,
      keysSubscriptionName: '',
      icon: actionType === TabActionType.CREATE ? 'person-add-black' : 'edit-outline',
      tabData: {
        selectedRole: role,
        roleFormInitialized: false,
        roleForm: new FormGroup({}),
        cloneRole: actionType === TabActionType.OTHER,
      },
    };
    this.generateForms(newTab);
    this._tabs.push(newTab);
    await this.selectTab(id);
  }

  override async selectTab(id: number) {
    if (this.selectedTab && this.selectedTab?.id === id) return;
    super.selectTab(id);

    const actionType = this.selectedTab!.actionType;

    this.router
      .navigateByUrl('/home/administration/roles-and-permissions', { skipLocationChange: true })
      .then(() => {
        if (actionType === TabActionType.CREATE) {
          this.router.navigateByUrl('/home/administration/roles-and-permissions/create');
        } else if (actionType === TabActionType.EDIT) {
          this.router.navigateByUrl(`/home/administration/roles-and-permissions/edit/${id}`);
        } else if (actionType === TabActionType.OTHER) {
          this.router.navigateByUrl(`/home/administration/roles-and-permissions/clone/${id}`);
        }
      });
  }

  override isSafeToCloseTab(id: number): boolean {
    const tab = this.getTabData(id);
    return !tab.tabData.roleForm.dirty;
  }

  override async closeTab(id: number) {
    super.closeTab(id);
    await this.router.navigateByUrl('/home/administration/roles-and-permissions');
  }

  override getTabData(id: number): TabData<RoleTabData> {
    return super.getTabData(id);
  }

  private generateForms(tabData: TabData<RoleTabData>) {
    tabData.tabData.roleForm = new FormGroup({
      roleName: new FormControl('', [Validators.required]),
      description: new FormControl(''),
      permissions: new FormControl([], [Validators.required]),
      roleType: new FormControl('', [Validators.required]),
    });
  }
}

export interface RoleOpenTabOptions {
  id?: number;
  uid?: string;
  icon?: string;
}
