<!--Incidents autocomplete-->
<form
  class="input-form search-form position-relative"
  [formGroup]="searchForm"
  matAutocompleteOrigin
  #origin
  *ngIf="onlyNumbers"
  autocomplete="off">
  <mat-form-field
    data-cy="autocomplete-form"
    appearance="outline"
    class="width-100 input-control position-relative"
    (click)="focusInput()"
    [class.mat-form-field-invalid]="searchForm.touched || wasFormTouched ? isInputEmpty : false">
    <mat-label data-cy="autocomplete-label">{{ inputLabel }}</mat-label>
    <input
      data-cy="autocomplete-input"
      type="text"
      #input
      matInput
      [incidentsAutoComplete]="autoCompleteChart !== ''"
      (focusin)="onFocusEvent()"
      [incidentsAutoCompleteChart]="'0'"
      [incidentsAutoCompleteMaxLength]="maxLength"
      autocomplete="off"
      formControlName="value"
      class="autocomplete-input"
      integerNumber
      (ngModelChange)="valueChange()"
      [matAutocomplete]="auto"
      (keydown)="otherAction($event)"
      (focusout)="tryMatchOption($event)" />
    <mat-autocomplete
      #auto="matAutocomplete"
      [displayWith]="displayFn"
      class="search-options scrollbar"
      (optionSelected)="valueChange()"
      (optionActivated)="setActiveOption($event)"
      (opened)="onOpenPanel()"
      (closed)="onClosePanel()">
      <cdk-virtual-scroll-viewport
        itemSize="48"
        minBufferPx="250"
        maxBufferPx="500"
        class="viewport-class"
        [ngStyle]="{ height: height }"
        #CdkVirtualScrollViewport>
        <mat-option
          class="don't-delete-keep-focus autocomplete-item-size"
          *cdkVirtualFor="let option of placeFilteredOptions; let i = index"
          [value]="option">
          {{ (showIndex ? i + 1 + '. ' : '') + option.title || 'Cargando...'
          }}<div data-cy="mat-option-item" class="cypress-selector"></div>
        </mat-option>
      </cdk-virtual-scroll-viewport>
    </mat-autocomplete>
    <app-svg
      *ngIf="
        isDisabled ||
        (showExpandArrow &&
          (searchForm.get('value')?.value === '' || !searchForm.get('value')?.value))
      "
      [name]="auto.isOpen ? 'expand-less' : 'expand-more'"
      (click)="isDisabled ? $event.preventDefault() : closePanel()"
      class="icon-size expand-icon-position"
      [class]="
        isDisabled
          ? 'color-inactive'
          : (searchForm.touched || wasFormTouched) && isInputEmpty
            ? 'color-red'
            : 'color-secondary-200'
      "></app-svg>
    <app-svg
      *ngIf="!isDisabled && showExpandArrow && searchForm.get('value')?.value"
      name="incident-close"
      (click)="isDisabled ? $event.preventDefault() : clearInput()"
      [class]="isDisabled ? 'color-inactive' : 'color-secondary-200'"
      class="cursor-pointer icon-size expand-icon-position"></app-svg>
  </mat-form-field>
</form>

<!--general autocomplete-->
<form
  class="input-form search-form position-relative"
  [formGroup]="searchForm"
  matAutocompleteOrigin
  #origin
  *ngIf="!onlyNumbers">
  <mat-form-field
    data-cy="autocomplete-form"
    appearance="outline"
    class="width-100 input-control position-relative"
    (click)="focusInput()"
    [class.mat-form-field-invalid]="searchForm.touched || wasFormTouched ? isInputEmpty : false"
    [hideRequiredMarker]="hideRequiredMarker || false">
    <mat-label data-testId="autocomplete-label">{{ inputLabel }}</mat-label>
    <input
      data-cy="autocomplete-input"
      type="text"
      [maxLength]="inputMaxLength ?? 524288"
      [matAutocompleteDisabled]="true"
      autocomplete="off"
      (keydown)="keyboardEvent($event)"
      (click)="onFocusEvent()"
      matAutocompleteOrigin
      #input="matAutocompleteOrigin"
      matInput
      formControlName="value"
      class="autocomplete-input"
      (focusin)="onFocusEvent()"
      [matAutocomplete]="auto"
      (keydown.enter)="tryMatchOption()"
      (focusout)="tryMatchOption($event)" />
    <mat-autocomplete
      #auto="matAutocomplete"
      [displayWith]="displayFn"
      class="search-options scrollbar"
      (optionSelected)="valueChange()"
      (optionActivated)="setActiveOption($event)"
      (opened)="onOpenPanel()"
      (closed)="onClosePanel()">
      <cdk-virtual-scroll-viewport
        itemSize="48"
        minBufferPx="250"
        maxBufferPx="500"
        class="viewport-class"
        [ngStyle]="{ height: height }"
        #CdkVirtualScrollViewport>
        <mat-option
          class="don't-delete-keep-focus autocomplete-item-size"
          *cdkVirtualFor="let option of placeFilteredOptions; let i = index"
          [value]="option">
          {{ (showIndex ? i + 1 + '. ' : '') + option.title || 'Cargando...'
          }}<div data-cy="mat-option-item" class="cypress-selector"></div>
        </mat-option>
      </cdk-virtual-scroll-viewport>
    </mat-autocomplete>
    <app-svg
      [name]="auto.isOpen ? 'expand-less' : 'expand-more'"
      (click)="isDisabled ? $event.preventDefault() : closePanel()"
      class="icon-size expand-icon-position"
      [class]="
        isDisabled
          ? 'color-inactive'
          : (searchForm.touched || wasFormTouched) && isInputEmpty
            ? 'color-red'
            : 'color-secondary-200'
      "></app-svg>
  </mat-form-field>
</form>

<div #position class="position-fixed"></div>
