import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { lastValueFrom } from 'rxjs';
import { environment } from '../../../../../environments/environment';
import {
  IncidentReOpenInput,
  MedicalRegulationCreateInput,
  PatientCreateInput,
  PatientHandlingAndManagementInput,
} from '@smartsoft-types/sisem-incidents';
import { IncidentCloseIncidentInput } from '@smartsoft-types/sisem-incidents/dist/src/entities/schemas/types/incident/incident.schema.entity';
import { PatientUpdateInput } from '@smartsoft-types/sisem-incidents/dist/src/entities/schemas/types/patient/patient.schema.entity';
import { ProfileService } from '../../profile/profile.service';
import { CreateIncidentData } from '../../../types/incident.interface';

@Injectable({
  providedIn: 'root',
})
export class IncidentsEventsService {
  constructor(
    private http: HttpClient,
    private profileService: ProfileService
  ) {}

  async cancelIncidentCreation(data: CreateIncidentData) {
    const body = {
      data: {
        eventType: 'cancelledIncidentCreation',
        eventData: data,
        operatorCurrentRoleId: this.profileService.selectedRole?.id,
      },
      auditData: {
        roleId: this.profileService.selectedRole?.id
          ? (this.profileService.selectedRole.id as unknown as number)
          : 1,
      },
    };
    try {
      await lastValueFrom(
        this.http.post(
          environment.api + '/incidents-ms/api/v1/incidents/registerBeforeCreationEvent',
          body
        )
      );
    } catch (e) {
      throw e;
    }
  }

  async cancelIncidentRegulation(data: MedicalRegulationCreateInput['data'], id: number) {
    const body = {
      data: {
        eventType: 'cancelledMedicalRegulation',
        eventData: data,
        operatorCurrentRoleId: this.profileService.selectedRole?.id,
      },
      auditData: {
        roleId: this.profileService.selectedRole?.id ?? 1,
      },
    };
    try {
      await lastValueFrom(
        this.http.post(`${environment.api}/incidents-ms/api/v1/incidents/${id}/registerEvent`, body)
      );
    } catch (e) {
      throw e;
    }
  }

  async cancelLogs(dataS: { [key: string]: any }, eventType: string, id: number) {
    const body = {
      data: {
        eventType: eventType,
        eventData: dataS,
        operatorCurrentRoleId: this.profileService.selectedRole?.id,
      },
      auditData: {
        roleId: this.profileService.selectedRole?.id ?? 1,
      },
    };
    await lastValueFrom(
      this.http.post(`${environment.api}/incidents-ms/api/v1/incidents/${id}/registerEvent`, body)
    );
  }

  async cancelCloseIncident(id: number, data: IncidentCloseIncidentInput['data']) {
    const body = {
      data: {
        eventType: 'cancelledCloseIncident',
        operatorCurrentRoleId: this.profileService.selectedRole?.id,
        eventData: data,
      },
      auditData: {
        roleId: this.profileService.selectedRole?.id ?? 1,
      },
    };
    try {
      await lastValueFrom(
        this.http.post(`${environment.api}/incidents-ms/api/v1/incidents/${id}/registerEvent`, body)
      );
    } catch (e) {
      throw e;
    }
  }

  async cancelReopenIncident(data: IncidentReOpenInput['data'], id: number) {
    const body = {
      data: {
        eventType: 'cancelledIncidentReOpening',
        eventData: data,
        operatorCurrentRoleId: this.profileService.selectedRole?.id,
      },
      auditData: {
        roleId: this.profileService.selectedRole?.id ?? 1,
      },
    };
    try {
      await lastValueFrom(
        this.http.post(`${environment.api}/incidents-ms/api/v1/incidents/${id}/registerEvent`, body)
      );
    } catch (e) {
      throw e;
    }
  }

  async cancelCreateOrUpdatePatient(
    data: PatientCreateInput['data'] | PatientUpdateInput['data'],
    id: number
  ) {
    const body = {
      data: {
        eventType: 'cancelledPatientEvaluationRegistration',
        eventData: data,
        operatorCurrentRoleId: this.profileService.selectedRole?.id,
      },
      auditData: {
        roleId: this.profileService.selectedRole?.id ?? 1,
      },
    };
    try {
      await lastValueFrom(
        this.http.post(`${environment.api}/incidents-ms/api/v1/incidents/${id}/registerEvent`, body)
      );
    } catch (e) {
      throw e;
    }
  }

  async cancelPatientHandlingAndManagement(
    data: PatientHandlingAndManagementInput['data'],
    id: number
  ) {
    const body = {
      data: {
        eventType: 'cancelledPatientSetHandlingAndManaging',
        eventData: data,
        operatorCurrentRoleId: this.profileService.selectedRole?.id,
      },
      auditData: {
        roleId: this.profileService.selectedRole?.id ?? 1,
      },
    };
    try {
      await lastValueFrom(
        this.http.post(`${environment.api}/incidents-ms/api/v1/incidents/${id}/registerEvent`, body)
      );
    } catch (e) {
      throw e;
    }
  }
}
