import { CachedCRUDService } from '../../../types/cached-c-r-u-d.service';
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { lastValueFrom } from 'rxjs';
import { ServerResponse } from '../../../types/http.interfaces';
import { environment } from '../../../../../environments/environment';
import { ClosingCode } from '@smartsoft-types/sisem-incidents';
import { ProfileService } from '../../profile/profile.service';

@Injectable({
  providedIn: 'root',
})
export class IncidentsClosingCodesService extends CachedCRUDService<ClosingCode, never, never> {
  constructor(
    private http: HttpClient,
    private profileService: ProfileService
  ) {
    super();
    this.options.cacheExpiration = -1; // should almost never change, set don't expire
    this.profileService.changeRole?.subscribe(async () => {
      this.flushCache();
      this.warm();
    });
  }

  async getClosingCodes(): Promise<ClosingCode[]> {
    let params = new HttpParams();

    params = params.set('fetchChildren', true);

    const response = await lastValueFrom(
      this.http.get<ServerResponse<ClosingCode[]>>(
        environment.api + '/incidents-ms/api/v1/closingcodes',
        { params: params }
      )
    );
    return response.data;
  }
  protected override async _fetchNewData(): Promise<ClosingCode[]> {
    let params = new HttpParams();
    if (!this.profileService.selectedRole) {
      throw new Error('Missing selected role');
    }
    params = params.set('fetchChildren', true);
    params = params.set('roleId', this.profileService.selectedRole.id);
    params = params.set('currentRoleId', this.profileService.selectedRole.id);

    const response = await lastValueFrom(
      this.http.get<ServerResponse<ClosingCode[]>>(
        environment.api + '/incidents-ms/api/v1/closingcodes',
        { params: params }
      )
    );
    return response.data;
  }
}
