import { Injectable } from '@angular/core';

import { lastValueFrom } from 'rxjs';
import { ServerResponse } from '../../../types/http.interfaces';
import { ResultPagination } from '../../../types/pagination.interface';
import { NoveltyType } from '@smartsoft-types/sisem-resources';
import { environment } from '../../../../../environments/environment';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class PatientFilesService {
  public lastGeneratedBlob: Blob = new Blob();

  constructor(private http: HttpClient) {}

  public async getPatientDocuments(patientReferenceId: number): Promise<PatientDocuments[]> {
    try {
      const params = { patientReferenceId };
      const res = await lastValueFrom(
        this.http.get<ServerResponse<PatientDocuments[]>>(
          `${environment.api}/incidents-ms/api/v1/patientReferences/document`,
          { params }
        )
      );
      return res.data;
    } catch (e) {
      console.error(e);
      throw e;
    }
  }

  public async addPatientDocument(data: PatientFile, createdByRoleId: string) {
    try {
      const params = new HttpParams().set('currentRoleId', createdByRoleId);
      const type =
        parseInt(data.type) === 0
          ? PatientReferenceDocumentType.CLINIC_HISTORY
          : PatientReferenceDocumentType.OTHER;
      const formData = new FormData();
      formData.append('patientReferenceId', data.patientReferenceId.toString());
      formData.append('type', type);
      formData.append('file', data.file);
      const res = await lastValueFrom(
        this.http.post<ServerResponse<ResultPagination<NoveltyType>>>(
          `${environment.api}/incidents-ms/api/v1/patientReferences/document`,
          formData,
          { params }
        )
      );
      return res.data;
    } catch (e) {
      console.error(e);
      throw e;
    }
  }

  async getFileBlobByUrl(url: string): Promise<ArrayBuffer> {
    const headers = new HttpHeaders({
      skip: 'true',
    });
    try {
      const res = await lastValueFrom(
        this.http.get<ArrayBuffer>(url, {
          headers,
          responseType: 'arraybuffer' as 'json',
        })
      );
      return res;
    } catch (e) {
      console.error(e);
      throw e;
    }
  }
}

export interface PatientFile {
  patientReferenceId: number;
  type: string;
  file: File;
}
export interface PatientDocuments {
  id: number;
  documentType: PatientReferenceDocumentType;
  patientReferenceId: number;
  createdAt: Date;
  attachment: Attachment;
}

export interface Attachment {
  id: number;
  objectName: string;
  bucket: string;
  ownerId: number;
  ownerType: string;
  originalName: string;
  givenName: string;
  createdAt: Date;
  updatedAt: Date;
  publicUrl: string;
}
export enum PatientReferenceDocumentType {
  CLINIC_HISTORY = 'clinic_history',
  OTHER = 'other',
}
