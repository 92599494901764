import { GeneralKnownRouteValidator, NavItem } from './types-and-helpers.known.routes';

export enum ParametricKnownItemId {
  PARAMETRIC = '1',
  HOSPITALS = '2',
  KNOW_NOVELTIES = '3',
  PARAMETRIC_LIST = '4',
  REFERRAL_TRACKING = '5',
  EVENT_TYPES = '6',
  GENERAL_PARAMS = '7',
  FILE_SIZE = '8',
  REFERENCES_COUNTER_REFERENCES = '9',
  REASON_CANCELLATION = '10',
  EVENT_TYPES_REFERENCES = '11',
  PATHOLOGIES = '12',
  REFERRAL_REASON = '13',
  VALUATION_TM_PORTALS = '14',
  TM_PORTALS = '15',
  FINAL_CONDUCT = '16',
  INCIDENT_REASON = '17',
  CONDUCT = '18',
  ATTENTION = '19',
  CUTOFF_DATE = '20',
  RETENTION_TIME = '21',
  APP_AMBULANCES = '22',
  SUPPLIES = '23',
  RESOURCE_TYPE = '24',
  PREOP_SUPPLIES_MEDICATION = '25',
  BIOMEDICAL_EQUIPMENT = '26',
  PREOP_BIOMEDICAL_EQUIPMENT = '27',
  CUPS_CODES = '28',
  REFERENCES_SERVICES = '29',
  GENERAL_TIME = '30',
  PREOP_DRIVER = '31',
  HUMAN_TALENT_EDUCATION = '32',
  PUBLIC_ENTITY = '33',
  CREATE_PUBLIC_ENTITY = '34',
  EDIT_PUBLIC_ENTITY = '35',
  LEVEL_EDUCATION = '36',
  COUNTRIES = '37',
  SEXUAL_ORIENTATION = '38',
  CORE_PARAMETRIC = '39',
  ETHNICITY = '40',
  POPULATION_OF_INTEREST = '41',
  SITES = '42',
  SPECIAL_CONDITION = '43',
  ETNIA = '44',
  DISABILITY = '45',
  GENDER = '46',
  PUPIL_SHAPE = '47',
  PARAMETRIC_PROVIDER = '48',
  SOAT_ENTITY = '49',
  VEHICLE_BRAND = '50',
  FUEL = '51',
  VEHICLE_CLASS = '52',
  RIM = '53',
  AUTOMOTIVE_DIAGNOSTIC_CENTER = '54',
  PROFESSIONS_AND_OCCUPATIONS = '55',
  EDUCATION_PARAMETERS = '56',
  WITHOUT_TRANSFER = '57',
  MAP_INCIDENT_GROUP = '58',
  MAPS_PARAMETRIC = '59',
  VEHICLE_ELEMENTS = '60',
  INVENTORY_INIT_BIOMEDICAL_EQUIPMENT = '61',
  INVENTORY_INIT_VEHICLE = '62',
  INVENTORY_INIT_MEDICATIONS_SUPPLIES = '63',
  CONSECUTIVE_APH_REGISTER = '64',
}

const simpleTransformer = (input: NavItem): NavItem & { type: 'parametric' } => {
  return {
    ...input,
    type: 'parametric',
  };
};

export const ParametricKnownRoutes = (k: ParametricKnownItemId): NavItem => {
  k = GeneralKnownRouteValidator(k);
  return JSON.parse(JSON.stringify(options[k]));
};

const options: {
  [k in ParametricKnownItemId]: NavItem & { type: 'parametric' };
} = {
  [ParametricKnownItemId.PARAMETRIC]: simpleTransformer({
    id: ParametricKnownItemId.PARAMETRIC,
    displayName: 'Paramétricas',
    iconName: 'parametrics',
    route: '/home/parametric/hospitals',
    permissions: [],
    visibleOnSidebar: false,
  }),
  [ParametricKnownItemId.HOSPITALS]: simpleTransformer({
    id: ParametricKnownItemId.HOSPITALS,
    displayName: 'Hospitales',
    iconName: 'hospital',
    route: '/home/parametric/hospitals',
    parentModule: 'parametric',
    permissions: ['novelties_types_w', 'final_conduct_w'],
    visibleOnSidebar: true,
  }),
  [ParametricKnownItemId.KNOW_NOVELTIES]: simpleTransformer({
    id: ParametricKnownItemId.KNOW_NOVELTIES,
    displayName: 'Tipo de Novedades',
    iconName: 'hospital',
    route: '/home/parametric/hospitals/kinds-novelties',
    parentModule: 'parametric',
    permissions: ['novelties_types_w'],
    visibleOnSidebar: false,
  }),
  [ParametricKnownItemId.PARAMETRIC_LIST]: simpleTransformer({
    id: ParametricKnownItemId.PARAMETRIC_LIST,
    displayName: 'Paramétricas',
    iconName: 'parametrics',
    route: '/home/parametric/list',
    parentModule: 'parametric',
    permissions: [],
    visibleOnSidebar: false,
  }),
  [ParametricKnownItemId.REFERRAL_TRACKING]: simpleTransformer({
    id: ParametricKnownItemId.REFERRAL_TRACKING,
    displayName: 'Seguimiento de Referencias',
    iconName: 'phone-forwarded',
    route: '/home/parametric/referral-tracking',
    parentModule: 'parametric',
    permissions: ['event_type_parametric_pfu_w'],
    visibleOnSidebar: true,
  }),
  [ParametricKnownItemId.EVENT_TYPES]: simpleTransformer({
    id: ParametricKnownItemId.EVENT_TYPES,
    displayName: 'Tipo de Evento',
    iconName: 'news',
    route: '/home/parametric/referral-tracking/event-types',
    parentModule: 'parametric',
    permissions: ['event_type_parametric_pfu_w'],
    visibleOnSidebar: false,
  }),
  [ParametricKnownItemId.GENERAL_PARAMS]: simpleTransformer({
    id: ParametricKnownItemId.GENERAL_PARAMS,
    displayName: 'Parámetros Generales',
    iconName: 'settings-applications',
    route: '/home/parametric/general-params',
    parentModule: 'parametric',
    permissions: [
      'file_size_w',
      'retention_time_parametric_u',
      'educational_level_w',
      'location_w',
      'location_r',
      'special_condition_w',
      'ethnicity_w',
      'disability_w',
      'gender_w',
    ],
    visibleOnSidebar: true,
  }),
  [ParametricKnownItemId.FILE_SIZE]: simpleTransformer({
    id: ParametricKnownItemId.FILE_SIZE,
    displayName: 'Parámetros Generales',
    iconName: 'settings-applications',
    route: '/home/parametric/general-params/file-size',
    parentModule: 'parametric',
    permissions: ['file_size_w'],
    visibleOnSidebar: false,
  }),
  [ParametricKnownItemId.REFERENCES_COUNTER_REFERENCES]: simpleTransformer({
    id: ParametricKnownItemId.REFERENCES_COUNTER_REFERENCES,
    displayName: 'Referencia y Contrarreferencia',
    iconName: 'sync',
    route: '/home/parametric/references-counter-references',
    parentModule: 'parametric',
    permissions: [
      'cancel_w',
      'remission_w',
      'patologies_w',
      'cups_parametric_w',
      'services_parametric_w',
      'reference_event_types_w',
    ],
    visibleOnSidebar: true,
  }),
  [ParametricKnownItemId.REASON_CANCELLATION]: simpleTransformer({
    id: ParametricKnownItemId.REASON_CANCELLATION,
    displayName: 'Motivo de Cancelación',
    iconName: 'cancel',
    route: '/home/parametric/references-counter-references/reason-cancellation',
    parentModule: 'parametric',
    permissions: ['cancel_w'],
    visibleOnSidebar: false,
  }),
  [ParametricKnownItemId.EVENT_TYPES_REFERENCES]: simpleTransformer({
    id: ParametricKnownItemId.EVENT_TYPES_REFERENCES,
    displayName: 'Tipo de Evento',
    iconName: 'news',
    route: '/home/parametric/references-counter-references/event-types',
    parentModule: 'parametric',
    permissions: ['reference_event_types_w'],
    visibleOnSidebar: false,
  }),
  [ParametricKnownItemId.PATHOLOGIES]: simpleTransformer({
    id: ParametricKnownItemId.PATHOLOGIES,
    displayName: 'Patologías',
    iconName: 'pathologies',
    route: '/home/parametric/references-counter-references/pathologies',
    parentModule: 'parametric',
    permissions: ['patologies_w'],
    visibleOnSidebar: false,
  }),
  [ParametricKnownItemId.REFERRAL_REASON]: simpleTransformer({
    id: ParametricKnownItemId.REFERRAL_REASON,
    displayName: 'Motivo de la Remisión',
    iconName: 'trasfer-station',
    route: '/home/parametric/references-counter-references/referral-reason',
    parentModule: 'parametric',
    permissions: ['remission_w'],
    visibleOnSidebar: false,
  }),
  [ParametricKnownItemId.RETENTION_TIME]: simpleTransformer({
    id: ParametricKnownItemId.RETENTION_TIME,
    displayName: 'Parámetros Generales',
    iconName: 'settings-applications',
    route: '/home/parametric/general-params/retention-time',
    parentModule: 'parametric',
    permissions: ['retention_time_parametric_u'],
    visibleOnSidebar: false,
  }),
  [ParametricKnownItemId.VALUATION_TM_PORTALS]: simpleTransformer({
    id: ParametricKnownItemId.VALUATION_TM_PORTALS,
    displayName: 'Valoración Portales de TM',
    iconName: 'transmilenio',
    route: '/home/parametric/valuation-tm-portals',
    parentModule: 'parametric',
    permissions: [
      'conduct_parametric_w',
      'cutt_date_parametric_w',
      'care_provided_parametric_w',
      'incident_cause_parametric_w',
      'transmilenio_portal_parametric_w',
    ],
    visibleOnSidebar: true,
  }),
  [ParametricKnownItemId.TM_PORTALS]: simpleTransformer({
    id: ParametricKnownItemId.TM_PORTALS,
    displayName: 'Portales de Transmilenio',
    iconName: 'bus',
    route: '/home/parametric/valuation-tm-portals/tm-portals',
    parentModule: 'parametric',
    permissions: ['transmilenio_portal_parametric_w'],
    visibleOnSidebar: false,
  }),
  [ParametricKnownItemId.FINAL_CONDUCT]: simpleTransformer({
    id: ParametricKnownItemId.FINAL_CONDUCT,
    displayName: 'Conducta final',
    iconName: 'final-conduct',
    route: '/home/parametric/hospitals/final-conduct',
    parentModule: 'parametric',
    permissions: ['final_conduct_w'],
    visibleOnSidebar: false,
  }),
  [ParametricKnownItemId.HUMAN_TALENT_EDUCATION]: simpleTransformer({
    id: ParametricKnownItemId.HUMAN_TALENT_EDUCATION,
    displayName: 'Formación al talento humano',
    iconName: 'academic-cap',
    route: '/home/parametric/human-talent-education',
    parentModule: 'parametric',
    permissions: [
      'human_resource_training_module_params_w',
      'professions_occupations_parametric_w',
      'sexual_orientation_w',
      'public_entities_q',
      'public_entities_w',
    ],
    visibleOnSidebar: true,
  }),
  [ParametricKnownItemId.PUBLIC_ENTITY]: simpleTransformer({
    id: ParametricKnownItemId.PUBLIC_ENTITY,
    displayName: 'Entidades públicas',
    iconName: 'corporate',
    route: '/home/parametric/human-talent-education/public-entities',
    parentModule: 'parametric',
    permissions: ['public_entities_q'],
    visibleOnSidebar: false,
  }),
  [ParametricKnownItemId.CREATE_PUBLIC_ENTITY]: simpleTransformer({
    id: ParametricKnownItemId.CREATE_PUBLIC_ENTITY,
    displayName: 'Formación al talento humano',
    iconName: 'academic-cap',
    route: '/home/parametric/human-talent-education/public-entities/create',
    parentModule: 'parametric',
    permissions: ['public_entities_w'],
    visibleOnSidebar: false,
  }),
  [ParametricKnownItemId.EDIT_PUBLIC_ENTITY]: simpleTransformer({
    id: ParametricKnownItemId.EDIT_PUBLIC_ENTITY,
    displayName: 'Formación al talento humano',
    iconName: 'academic-cap',
    route: '/home/parametric/human-talent-education/public-entities/edit',
    parentModule: 'parametric',
    permissions: ['public_entities_u'],
    visibleOnSidebar: false,
  }),
  [ParametricKnownItemId.PROFESSIONS_AND_OCCUPATIONS]: simpleTransformer({
    id: ParametricKnownItemId.PROFESSIONS_AND_OCCUPATIONS,
    displayName: 'Profesiones y ocupaciones',
    iconName: 'work-bag',
    route: '/home/parametric/human-talent-education/professions-and-occupations',
    parentModule: 'parametric',
    permissions: ['professions_occupations_parametric_w'],
    visibleOnSidebar: false,
  }),
  [ParametricKnownItemId.EDUCATION_PARAMETERS]: simpleTransformer({
    id: ParametricKnownItemId.EDUCATION_PARAMETERS,
    displayName: 'Parámetros de formación',
    iconName: 'local-library',
    route: '/home/parametric/human-talent-education/education-parameters',
    parentModule: 'parametric',
    permissions: ['human_resource_training_module_params_w'],
    visibleOnSidebar: false,
  }),
  [ParametricKnownItemId.INCIDENT_REASON]: simpleTransformer({
    id: ParametricKnownItemId.INCIDENT_REASON,
    displayName: 'Causa del Incidente',
    iconName: 'alert',
    route: '/home/parametric/valuation-tm-portals/incident-cause',
    parentModule: 'parametric',
    permissions: ['incident_cause_parametric_w'],
    visibleOnSidebar: false,
  }),
  [ParametricKnownItemId.CONDUCT]: simpleTransformer({
    id: ParametricKnownItemId.CONDUCT,
    displayName: 'Conducta',
    iconName: 'trasfer-station',
    route: '/home/parametric/valuation-tm-portals/conduct',
    parentModule: 'parametric',
    permissions: ['conduct_parametric_w'],
    visibleOnSidebar: false,
  }),
  [ParametricKnownItemId.ATTENTION]: simpleTransformer({
    id: ParametricKnownItemId.ATTENTION,
    displayName: 'Atención Prestada',
    iconName: 'people',
    route: '/home/parametric/valuation-tm-portals/attention',
    parentModule: 'parametric',
    permissions: ['care_provided_parametric_w'],
    visibleOnSidebar: false,
  }),
  [ParametricKnownItemId.CUTOFF_DATE]: simpleTransformer({
    id: ParametricKnownItemId.CUTOFF_DATE,
    displayName: 'Fecha De Corte',
    iconName: 'calendar-2',
    route: '/home/parametric/valuation-tm-portals/cutoff-date',
    parentModule: 'parametric',
    permissions: ['cutt_date_parametric_w'],
    visibleOnSidebar: false,
  }),
  [ParametricKnownItemId.APP_AMBULANCES]: simpleTransformer({
    id: ParametricKnownItemId.APP_AMBULANCES,
    displayName: 'App Ambulancias',
    tabName: 'APP AMBULANCIAS',
    iconName: 'cellphone',
    route: '/home/parametric/app-ambulance',
    parentModule: 'parametric',
    permissions: [
      'supplies_equipment_parametric',
      'initial_inventory_supplies_equipment',
      'manage_supplies_equipment',
      'biomedic_equipments_parametric',
      'initial_inventory_biomedic_equipments',
      'manage_biomedic_equipments',
      'vehicle_tools_parametric',
      'initial_inventory_vehicle_tools',
      'manage_vehicle_tools',
      'config_per_resource_type',
      'supplies_equipments_codes',
      'biomedic_equipments_codes',
      'vehicle_tools_codes',
      'supplies_equipments_codes_r',
      'biomedic_equipments_codes_r',
      'vehicle_tools_codes_r',
      'app_config',
      'manage_devices',
      'set_aph_consecutive',
      'query_aph_consecutive',
    ],
    visibleOnSidebar: true,
  }),
  [ParametricKnownItemId.SUPPLIES]: simpleTransformer({
    id: ParametricKnownItemId.SUPPLIES,
    displayName: 'Homologación código Insumos y Medicamentos',
    tabName: 'HOMOLOGACIÓN CÓDIGO INSUMOS Y MEDICAMENTOS',
    iconName: 'burger-menu',
    route: '/home/parametric/app-ambulance/supplies',
    parentModule: 'parametric',
    permissions: ['supplies_equipments_codes', 'supplies_equipments_codes_r'],
    visibleOnSidebar: false,
  }),
  /*  [ParametricKnownItemId.CREATE_EDIT_SUPPLIES]: simpleTransformer({
      id: ParametricKnownItemId.CREATE_EDIT_SUPPLIES,
      displayName: 'Homologación código Insumos y Medicamentos',
      tabName: 'NUEVO',
      iconName: 'add-circle',
      route: '/home/parametric/app-ambulance/supplies/add',
      parentModule: 'parametric',
    }),*/
  [ParametricKnownItemId.RESOURCE_TYPE]: simpleTransformer({
    id: ParametricKnownItemId.RESOURCE_TYPE,
    displayName: 'Configuración general App por tipo de Recurso',
    tabName: 'CONFIGURACIÓN GENERAL APP POR TIPO DE RECURSO',
    iconName: 'burger-menu',
    route: '/home/parametric/app-ambulance/resource-type',
    parentModule: 'parametrics',
    permissions: ['config_per_resource_type'],
    visibleOnSidebar: false,
  }),
  [ParametricKnownItemId.PREOP_SUPPLIES_MEDICATION]: simpleTransformer({
    id: ParametricKnownItemId.PREOP_SUPPLIES_MEDICATION,
    displayName: 'Pre operacional Insumos y Medicamentos',
    tabName: 'PRE OPERACIONAL INSUMOS Y MEDICAMENTOS',
    iconName: 'burger-menu',
    route: '/home/parametric/app-ambulance/preop-supplies-medication',
    parentModule: 'parametrics',
    permissions: ['supplies_equipment_parametric', 'manage_supplies_equipment'],
    visibleOnSidebar: false,
  }),
  [ParametricKnownItemId.BIOMEDICAL_EQUIPMENT]: simpleTransformer({
    id: ParametricKnownItemId.BIOMEDICAL_EQUIPMENT,
    displayName: 'Homologación código Equipos Biomédicos',
    tabName: 'HOMOLOGACIÓN CÓDIGO EQUIPOS BIOMÉDICOS',
    iconName: 'burger-menu',
    route: '/home/parametric/app-ambulance/biomedical-equipment',
    parentModule: 'parametric',
    permissions: ['biomedic_equipments_codes', 'biomedic_equipments_codes_r'],
    visibleOnSidebar: false,
  }),
  [ParametricKnownItemId.VEHICLE_ELEMENTS]: simpleTransformer({
    id: ParametricKnownItemId.VEHICLE_ELEMENTS,
    displayName: 'Homologación código Elementos Vehículo',
    tabName: 'HOMOLOGACIÓN CÓDIGO ELEMENTOS VEHÍCULO',
    iconName: 'burger-menu',
    route: '/home/parametric/app-ambulance/vehicle-element',
    parentModule: 'parametric',
    permissions: ['vehicle_tools_codes', 'vehicle_tools_codes_r'],
    visibleOnSidebar: false,
  }),
  [ParametricKnownItemId.PREOP_BIOMEDICAL_EQUIPMENT]: simpleTransformer({
    id: ParametricKnownItemId.PREOP_BIOMEDICAL_EQUIPMENT,
    displayName: 'Pre operacional Equipos Biomédicos',
    tabName: 'PRE OPERACIONAL EQUIPOS BIOMÉDICOS',
    iconName: 'burger-menu',
    route: '/home/parametric/app-ambulance/preop-biomedical-equipment',
    parentModule: 'parametric',
    permissions: ['biomedic_equipments_parametric', 'manage_biomedic_equipments'],
    visibleOnSidebar: false,
  }),
  [ParametricKnownItemId.CUPS_CODES]: simpleTransformer({
    id: ParametricKnownItemId.CUPS_CODES,
    displayName: 'Códigos CUPS',
    iconName: 'cups-codes',
    route: 'home/parametric/references-counter-references/cups-codes',
    parentModule: 'parametric',
    permissions: ['cups_parametric_w'],
    visibleOnSidebar: false,
  }),
  [ParametricKnownItemId.REFERENCES_SERVICES]: simpleTransformer({
    id: ParametricKnownItemId.REFERENCES_SERVICES,
    displayName: 'Servicios',
    iconName: 'services',
    route: 'home/parametric/references-counter-references/references-services',
    parentModule: 'parametric',
    permissions: ['services_parametric_w'],
    visibleOnSidebar: false,
  }),
  [ParametricKnownItemId.GENERAL_TIME]: simpleTransformer({
    id: ParametricKnownItemId.GENERAL_TIME,
    displayName: 'configuración General Tiempos App',
    tabName: 'CONFIGURACIÓN GENERAL TIEMPOS APP',
    iconName: 'burger-menu',
    route: '/home/parametric/app-ambulance/general-time',
    parentModule: 'parametric',
    permissions: ['app_config'],
    visibleOnSidebar: false,
  }),
  [ParametricKnownItemId.PREOP_DRIVER]: simpleTransformer({
    id: ParametricKnownItemId.PREOP_DRIVER,
    displayName: 'Pre operacional Conductor',
    tabName: 'PRE OPERACIONAL CONDUCTOR',
    iconName: 'burger-menu',
    route: '/home/parametric/app-ambulance/preop-driver',
    parentModule: 'parametric',
    permissions: ['vehicle_tools_parametric', 'manage_vehicle_tools'],
    visibleOnSidebar: false,
  }),
  [ParametricKnownItemId.LEVEL_EDUCATION]: simpleTransformer({
    id: ParametricKnownItemId.LEVEL_EDUCATION,
    displayName: 'Nivel de formación',
    iconName: 'education',
    route: '/home/parametric/general-params/level-education',
    parentModule: 'parametric',
    permissions: ['educational_level_w'],
    visibleOnSidebar: false,
  }),
  [ParametricKnownItemId.COUNTRIES]: simpleTransformer({
    id: ParametricKnownItemId.COUNTRIES,
    displayName: 'Paises',
    iconName: 'public_black',
    route: '/home/parametric/general-params/countries',
    parentModule: 'parametric',
    permissions: ['location_w', 'location_r'],
    visibleOnSidebar: false,
  }),
  [ParametricKnownItemId.CORE_PARAMETRIC]: simpleTransformer({
    id: ParametricKnownItemId.CORE_PARAMETRIC,
    displayName: 'Core',
    iconName: 'alert',
    route: '/home/parametric/core',
    parentModule: 'parametric',
    permissions: [
      'ethnicityPatients_w',
      'interestPopulation_w',
      'sites_w',
      'pupilShape_w',
      'noTransferCause_w',
    ],
    visibleOnSidebar: true,
  }),
  [ParametricKnownItemId.ETHNICITY]: simpleTransformer({
    id: ParametricKnownItemId.ETHNICITY,
    displayName: 'Pertenencia Étnica',
    iconName: 'groups',
    route: '/home/parametric/core/ethnicity',
    parentModule: 'parametric',
    permissions: ['ethnicityPatients_w'],
    visibleOnSidebar: false,
  }),
  [ParametricKnownItemId.POPULATION_OF_INTEREST]: simpleTransformer({
    id: ParametricKnownItemId.POPULATION_OF_INTEREST,
    displayName: 'Población de Interés',
    iconName: 'supervised_user',
    route: '/home/parametric/core/population_interest',
    parentModule: 'parametric',
    permissions: ['interestPopulation_w'],
    visibleOnSidebar: false,
  }),
  [ParametricKnownItemId.SITES]: simpleTransformer({
    id: ParametricKnownItemId.SITES,
    displayName: 'Sitios',
    iconName: 'sites',
    route: '/home/parametric/core/sites',
    parentModule: 'parametric',
    permissions: ['sites_w'],
    visibleOnSidebar: false,
  }),
  [ParametricKnownItemId.PARAMETRIC_PROVIDER]: simpleTransformer({
    id: ParametricKnownItemId.PARAMETRIC_PROVIDER,
    displayName: 'Prestadores',
    iconName: 'providers',
    route: '/home/parametric/providers',
    parentModule: 'parametric',
    permissions: [
      'soat_w',
      'aphBrand_w',
      'fuel_w',
      'vehicleClass_w',
      'vehicleRim_w',
      'ministryCDA_w',
    ],
    visibleOnSidebar: true,
  }),
  [ParametricKnownItemId.SOAT_ENTITY]: simpleTransformer({
    id: ParametricKnownItemId.SOAT_ENTITY,
    displayName: 'Entidad que expide el SOAT',
    iconName: 'soat',
    route: '/home/parametric/providers/soat-entity',
    parentModule: 'parametric',
    permissions: ['soat_w'],
    visibleOnSidebar: false,
  }),
  [ParametricKnownItemId.VEHICLE_BRAND]: simpleTransformer({
    id: ParametricKnownItemId.VEHICLE_BRAND,
    displayName: 'Marca',
    iconName: 'vehicle-brand',
    route: '/home/parametric/providers/vehicle-brand',
    parentModule: 'parametric',
    permissions: ['aphBrand_w'],
    visibleOnSidebar: false,
  }),
  [ParametricKnownItemId.SEXUAL_ORIENTATION]: simpleTransformer({
    id: ParametricKnownItemId.SEXUAL_ORIENTATION,
    displayName: 'Orientación Sexual',
    tabName: 'ORIENTACIÓN SEXUAL',
    iconName: 'orientation-sexual',
    route: '/home/parametric/human-talent-education/sexual-orientation',
    parentModule: 'parametric',
    permissions: ['sexual_orientation_w'],
    visibleOnSidebar: false,
  }),
  [ParametricKnownItemId.SPECIAL_CONDITION]: simpleTransformer({
    id: ParametricKnownItemId.SPECIAL_CONDITION,
    displayName: 'Condición especial',
    iconName: 'diversity_2',
    route: '/home/parametric/general-params/special-condition',
    parentModule: 'parametric',
    permissions: ['special_condition_w'],
    visibleOnSidebar: false,
  }),
  [ParametricKnownItemId.ETNIA]: simpleTransformer({
    id: ParametricKnownItemId.ETNIA,
    displayName: 'Etnia',
    iconName: 'groups',
    route: '/home/parametric/general-params/etnia',
    parentModule: 'parametric',
    permissions: ['ethnicity_w'],
    visibleOnSidebar: false,
  }),
  [ParametricKnownItemId.DISABILITY]: simpleTransformer({
    id: ParametricKnownItemId.DISABILITY,
    displayName: 'Discapacidad',
    iconName: 'accessible',
    route: '/home/parametric/general-params/disability',
    parentModule: 'parametric',
    permissions: ['disability_w'],
    visibleOnSidebar: false,
  }),
  [ParametricKnownItemId.GENDER]: simpleTransformer({
    id: ParametricKnownItemId.GENDER,
    displayName: 'Género',
    iconName: 'gender',
    route: '/home/parametric/general-params/gender',
    parentModule: 'parametric',
    permissions: ['gender_w'],
    visibleOnSidebar: false,
  }),
  [ParametricKnownItemId.PUPIL_SHAPE]: simpleTransformer({
    id: ParametricKnownItemId.PUPIL_SHAPE,
    displayName: 'Forma de la pupila',
    iconName: 'visibility',
    route: '/home/parametric/core/pupil-shape',
    parentModule: 'parametric',
    permissions: ['pupilShape_w'],
    visibleOnSidebar: false,
  }),
  [ParametricKnownItemId.FUEL]: simpleTransformer({
    id: ParametricKnownItemId.FUEL,
    displayName: 'Combustible',
    iconName: 'local-gas-station',
    route: '/home/parametric/providers/fuel',
    parentModule: 'parametric',
    permissions: ['fuel_w'],
    visibleOnSidebar: false,
  }),
  [ParametricKnownItemId.VEHICLE_CLASS]: simpleTransformer({
    id: ParametricKnownItemId.VEHICLE_CLASS,
    displayName: 'Clase de Vehículo',
    iconName: 'directions-car',
    route: '/home/parametric/providers/vehicle-class',
    parentModule: 'parametric',
    permissions: ['vehicleClass_w'],
    visibleOnSidebar: false,
  }),
  [ParametricKnownItemId.RIM]: simpleTransformer({
    id: ParametricKnownItemId.RIM,
    displayName: 'Rin',
    iconName: 'rin',
    route: '/home/parametric/providers/rim',
    parentModule: 'parametric',
    permissions: ['vehicleRim_w'],
    visibleOnSidebar: false,
  }),
  [ParametricKnownItemId.AUTOMOTIVE_DIAGNOSTIC_CENTER]: simpleTransformer({
    id: ParametricKnownItemId.AUTOMOTIVE_DIAGNOSTIC_CENTER,
    displayName: 'Centro de Diagnóstico Automotor',
    iconName: 'car-repair',
    route: '/home/parametric/providers/automotive-diagnostic-center',
    parentModule: 'parametric',
    permissions: ['ministryCDA_w'],
    visibleOnSidebar: false,
  }),
  [ParametricKnownItemId.WITHOUT_TRANSFER]: simpleTransformer({
    id: ParametricKnownItemId.WITHOUT_TRANSFER,
    displayName: 'Motivo de No traslado',
    iconName: 'without-transfer',
    route: '/home/parametric/core/without-transfer',
    parentModule: 'parametric',
    permissions: ['noTransferCause_w'],
    visibleOnSidebar: false,
  }),
  [ParametricKnownItemId.MAP_INCIDENT_GROUP]: simpleTransformer({
    id: ParametricKnownItemId.MAP_INCIDENT_GROUP,
    displayName: 'Grupo de incidentes',
    iconName: 'incident-group',
    route: '/home/parametric/maps/incident-group',
    parentModule: 'parametric',
    permissions: ['incidents_groups_w'],
    visibleOnSidebar: false,
  }),
  [ParametricKnownItemId.MAPS_PARAMETRIC]: simpleTransformer({
    id: ParametricKnownItemId.MAPS_PARAMETRIC,
    displayName: 'Mapas',
    iconName: 'map',
    route: '/home/parametric/maps',
    parentModule: 'parametric',
    permissions: ['incidents_groups_w'],
    visibleOnSidebar: true,
  }),
  [ParametricKnownItemId.INVENTORY_INIT_BIOMEDICAL_EQUIPMENT]: simpleTransformer({
    id: ParametricKnownItemId.INVENTORY_INIT_BIOMEDICAL_EQUIPMENT,
    displayName: 'Saldo Inicial Equipos biomédicos',
    tabName: 'SALDO INICIAL EQUIPOS BIOMÉDICOS',
    iconName: 'burger-menu',
    route: '/home/parametric/app-ambulance/balance-init-biomedical-equipment',
    permissions: ['initial_inventory_biomedic_equipments', 'manage_biomedic_equipments'],
    visibleOnSidebar: false,
  }),
  [ParametricKnownItemId.INVENTORY_INIT_VEHICLE]: simpleTransformer({
    id: ParametricKnownItemId.INVENTORY_INIT_VEHICLE,
    displayName: 'Saldo Inicial Insumos Vehículo',
    tabName: 'SALDO INICIAL INSUMOS VEHÍCULO',
    iconName: 'burger-menu',
    route: '/home/parametric/app-ambulance/balance-init-vehicle',
    permissions: ['initial_inventory_vehicle_tools', 'manage_vehicle_tools'],
    visibleOnSidebar: false,
  }),
  [ParametricKnownItemId.INVENTORY_INIT_MEDICATIONS_SUPPLIES]: simpleTransformer({
    id: ParametricKnownItemId.INVENTORY_INIT_MEDICATIONS_SUPPLIES,
    displayName: 'Saldo Inicial Medicamentos e Insumos',
    tabName: 'SALDO INICIAL MEDICAMENTOS E INSUMOS',
    iconName: 'burger-menu',
    route: '/home/parametric/app-ambulance/balance-initial-medications-supplies',
    permissions: ['initial_inventory_supplies_equipment', 'manage_supplies_equipment'],
    visibleOnSidebar: false,
  }),
  [ParametricKnownItemId.CONSECUTIVE_APH_REGISTER]: simpleTransformer({
    id: ParametricKnownItemId.CONSECUTIVE_APH_REGISTER,
    displayName: 'CONSECUTIVO REGISTRO APH',
    tabName: 'CONSECUTIVO REGISTRO APH',
    iconName: 'consecutive',
    route: '/home/parametric/app-ambulance/consecutive-register',
    permissions: ['set_aph_consecutive', 'query_aph_consecutive'],
    visibleOnSidebar: false,
  }),
};
